import { App } from '../BMapsApp';
import { EventBroker } from '../eventbroker';
import { UserActions, UserAction, UserCmd } from './UserCmd';
import { setColorScheme as updateColorScheme, stepBindingSiteDistance } from '../redux/prefs/access';

export const DisplayCmds = {
    DumpWorkspace: new UserCmd('DumpWorkspace', dumpWorkspace),
    StepBindingSiteRadius: new UserCmd('StepBindingSiteRadius', stepBindingSiteRadius, { undoFn: undoStepBindingSiteRadius }),
    RemoveCase: new UserCmd('RemoveCase', removeCase),
    // These need undo functions that revert to the previous state.
    SetView: new UserCmd('SetView', setView),
    ResetView: new UserCmd('ResetView', resetView),
    SetDisplayStyle: new UserCmd('SetDisplayStyle', setDisplayStyle),
    SetHydrogenView: new UserCmd('SetHydrogenView', setHydrogenView),
    SetWaterView: new UserCmd('SetWaterView', setWaterView),
    SetHBonds: new UserCmd('SetHBonds', setHBonds),
    SetBindingSiteSurface: new UserCmd('SetBindingSiteSurface', setBindingSiteSurface),
    SetLigandSolvation: new UserCmd('SetLigandSolvation', setLigandSolvation),
    SetHotspots: new UserCmd('SetHotspots', setHotspots),
    SetFragmentEnergyFilter: new UserCmd('setFragmentEnergyFilter', setFragmentEnergyFilter),
    ShowBfactor: new UserCmd('ShowBfactor', showBfactor),

    SetColorScheme: new UserCmd('SetColorScheme', setColorScheme),
    ActivateCompound: new UserCmd('ActivateCompound', activateCompound),
    ActivateNextCompound: new UserCmd('ActivateNextCompound', activateNextCompound),
    ToggleVisibility: new UserCmd('ToggleVisibility', toggleVisibility),
    ToggleSelection: new UserCmd('ToggleSelection', toggleSelection),
    ToggleExpansion: new UserCmd('ToggleExpansion', toggleExpansion),

    MoveTreeItem: new UserCmd('MoveTreeItem', moveTreeItem),
    GroupCreate: new UserCmd('GroupCreate', groupCreate),
    GroupItems: new UserCmd('GroupItems', groupItems),
    GroupRename: new UserCmd('GroupRename', groupRename),
    GroupUngroup: new UserCmd('GroupUngroup', groupUngroup),
    GroupDelete: new UserCmd('GroupDelete', groupDelete),
    GroupSort: new UserCmd('GroupSort', groupSort),
    ChangeTreeSort: new UserCmd('ChangeTreeSort', changeTreeSort),
    ChangeHotspotThreshold: new UserCmd('ChangeHotspotThreshold', changeHotspotThreshold),
};

function dumpWorkspace() {
    console.log(App.Workspace.dumpState());
    UserAction.DumpQueues();
}

function removeCase(caseData) {
    const { connector } = App.getDataParents(caseData);
    return UserActions.RemoveConnection(connector);
}

function stepBindingSiteRadius(delta) {
    stepBindingSiteDistance(delta);
}

function undoStepBindingSiteRadius(delta) {
    stepBindingSiteRadius(-1 * delta);
}

// UserActions for updating view state
// Consider moving these to another file
function setView(view) {
    App.Workspace.displayStateController.setView(view);
}

function resetView() {
    App.Workspace.displayStateController.reset();
    // Right now a change in protein style only won't reset the display,
    // so need to force it here.
    EventBroker.publish('resetDisplayRequest');
}

function setDisplayStyle(style, selectorFn) {
    EventBroker.publish('setDisplayStyle', { style, selectorFn });
}

function setHydrogenView(hydrogens) {
    App.Workspace.displayStateController.setHydrogens(hydrogens);
}

function setWaterView(waters) {
    App.Workspace.displayStateController.setWaters(waters);
}

function setHBonds(hbondMode) {
    App.Workspace.displayStateController.setHBonds(hbondMode);
}

function setBindingSiteSurface(surfaceMode) {
    App.Workspace.displayStateController.setBindingSiteSurface(surfaceMode);
}

function setLigandSolvation(show) {
    App.Workspace.displayStateController.setLigandSolvation(show);
}

function setHotspots(show) {
    App.Workspace.displayStateController.setHotspots(show);
}

function showBfactor(show) {
    App.Workspace.displayStateController.setBfactor(show);
}

function setFragmentEnergyFilter(fragmentInfo, value) {
    App.Workspace.updateAtomGroupState(fragmentInfo, { energyFilterValue: value });
}

function setColorScheme(color) {
    updateColorScheme(color);
}

function activateCompound(compound) {
    App.Workspace.activateCompound(compound);
}

function activateNextCompound(step) {
    App.Workspace.activateNextCompound(step);
}

function toggleVisibility(component, forceValue) {
    App.Workspace.toggleVisibility(component, forceValue);
}

function toggleSelection(component, forceValue) {
    App.Workspace.toggleSelection(component, forceValue);
}

function toggleExpansion(groupItem, forceValue) {
    App.Workspace.toggleExpansion(groupItem, forceValue);
}

function moveTreeItem(treeName, item, fromIx, toIx) {
    App.Workspace.moveTreeItem(treeName, item, fromIx, toIx);
}

function groupCreate(groupName, treeName, path) {
    return App.Workspace.groupCreate(groupName, treeName, path);
}

function groupItems(items, treeName, path, groupInfo) {
    return App.Workspace.groupItems(items, treeName, path, groupInfo);
}

function groupRename(group, newName) {
    App.Workspace.groupRename(group, newName);
}

function groupUngroup(group, treeName) {
    App.Workspace.groupUngroup(group, treeName);
}

function groupDelete(group, treeName) {
    App.Workspace.groupDelete(group, treeName);
}

/**
 * groupSort() - Sort a group in the tree control
 * @param {TreeGroup} group
 * @param {{ sortType: "Alphabetical" | "EnergyScore" }} sortOptions
 */
function groupSort(group, sortOptions) {
    App.Workspace.groupSort(group, sortOptions);
}

function changeTreeSort(treeName, sortType) {
    App.Workspace.changeTreeSort(treeName, sortType);
}

function changeHotspotThreshold(threshold) {
    App.Workspace.changeHotspotThreshold(threshold);
}
