export const Parker = {
    name: '4zae_parker',
    mol2:
`@<TRIPOS>MOLECULE
4zae_parker
57   61    1
SMALL
USER_CHARGES


@<TRIPOS>ATOM
      1 C21       -14.6830  -32.5710  -16.1460 C.ar      1 4M1         0.0000 
      2 C22       -14.2980  -33.8210  -16.5770 C.ar      1 4M1         0.0000 
      3 C20       -15.0160  -32.3800  -14.8210 C.ar      1 4M1         0.0000 
      4 C23       -14.2420  -34.8820  -15.6860 C.ar      1 4M1         0.0000 
      5 C19       -14.9840  -33.4440  -13.9310 C.ar      1 4M1         0.0000 
      6 C2        -11.6620  -34.8970   -9.3320 C.ar      1 4M1         0.0000 
      7 C5        -14.2380  -34.1200   -8.6720 C.ar      1 4M1         0.0000 
      8 C12       -14.2300  -41.4300  -10.3090 C.ar      1 4M1         0.0000 
      9 C14       -16.3870  -42.1720  -11.0800 C.ar      1 4M1         0.0000 
     10 C17       -14.6950  -43.6200   -8.1840 C.ar      1 4M1         0.0000 
     11 C16       -15.6090  -44.7930   -9.7320 C.ar      1 4M1         0.0000 
     12 C10       -15.4460  -40.1100  -11.9490 C.ar      1 4M1         0.0000 
     13 C18       -14.5810  -34.7070  -14.3500 C.ar      1 4M1         0.0000 
     14 C1        -11.9110  -34.3660   -8.0700 C.ar      1 4M1         0.0000 
     15 C         -13.2230  -33.9720   -7.7320 C.ar      1 4M1         0.0000 
     16 C3        -12.6660  -35.0530  -10.2760 C.ar      1 4M1         0.0000 
     17 C13       -15.2600  -42.3690  -10.2840 C.ar      1 4M1         0.0000 
     18 C4        -13.9540  -34.6620   -9.9230 C.ar      1 4M1         0.0000 
     19 C11       -14.3300  -40.3280  -11.1400 C.ar      1 4M1         0.0000 
     20 C15       -16.4750  -41.0450  -11.8750 C.ar      1 4M1         0.0000 
     21 C6        -14.0300  -35.4560  -12.0210 C.ar      1 4M1         0.0000 
     22 C9        -15.4930  -38.9760  -12.9360 C.2       1 4M1         0.0000 
     23 C24       -10.7970  -34.3050   -7.0600 C.3       1 4M1         0.0000 
     24 C25       -13.5650  -33.5360   -6.3290 C.3       1 4M1         0.0000 
     25 C8        -15.8950  -36.5880  -13.2760 C.3       1 4M1         0.0000 
     26 C7        -14.5110  -35.8860  -13.3850 C.3       1 4M1         0.0000 
     27 N5        -14.8180  -44.8290   -7.7190 N.2       1 4M1         0.0000 
     28 N4        -15.3960  -45.5910   -8.7270 N.2       1 4M1         0.0000 
     29 N1        -12.7450  -35.5250  -11.5820 N.pl3     1 4M1         0.0000 
     30 N3        -15.1880  -43.5310   -9.4470 N.pl3     1 4M1         0.0000 
     31 N         -14.7580  -34.9290  -11.0130 N.2       1 4M1         0.0000 
     32 N2        -15.7590  -37.7670  -12.4370 N.am      1 4M1         0.0000 
     33 O         -15.3250  -39.1800  -14.1370 O.2       1 4M1         0.0000 
     34 CL1       -12.9960  -39.2290  -11.2150 Cl        1 4M1         0.0000 
     35 CL        -17.9450  -40.7660  -12.7510 Cl        1 4M1         0.0000 
     36 H21       -14.7228  -31.7477  -16.8439 H         1 4M1         0.0000 
     37 H22       -14.0379  -33.9785  -17.6133 H         1 4M1         0.0000 
     38 H20       -15.3032  -31.3990  -14.4724 H         1 4M1         0.0000 
     39 H23       -13.9321  -35.8585  -16.0279 H         1 4M1         0.0000 
     40 H19       -15.2739  -33.2956  -12.9013 H         1 4M1         0.0000 
     41 H2        -10.6618  -35.2024   -9.6016 H         1 4M1         0.0000 
     42 H5        -15.2456  -33.8146   -8.4314 H         1 4M1         0.0000 
     43 H12       -13.3608  -41.5650   -9.6823 H         1 4M1         0.0000 
     44 H14       -17.1863  -42.8983  -11.0736 H         1 4M1         0.0000 
     45 H17       -14.2464  -42.8499   -7.5740 H         1 4M1         0.0000 
     46 H16       -16.0656  -45.1714  -10.6346 H         1 4M1         0.0000 
     47 H241      -11.1682  -33.8635   -6.1351 H         1 4M1         0.0000 
     48 H242      -10.4315  -35.3123   -6.8601 H         1 4M1         0.0000 
     49 H243       -9.9833  -33.6950   -7.4522 H         1 4M1         0.0000 
     50 H251      -12.6533  -33.4738   -5.7349 H         1 4M1         0.0000 
     51 H252      -14.0460  -32.5583   -6.3589 H         1 4M1         0.0000 
     52 H253      -14.2432  -34.2604   -5.8780 H         1 4M1         0.0000 
     53 H81       -16.2301  -36.8861  -14.2695 H         1 4M1         0.0000 
     54 H82       -16.6148  -35.9038  -12.8267 H         1 4M1         0.0000 
     55 H7        -13.7970  -36.6068  -13.7834 H         1 4M1         0.0000 
     56 HN1       -11.9127  -35.8507  -12.0525 H         1 4M1         0.0000 
     57 HN2       -15.8737  -37.6459  -11.4409 H         1 4M1         0.0000 
@<TRIPOS>BOND
     1    1    2 ar   
     2    1    3 ar   
     3    1   36 1    
     4    2    4 ar   
     5    2   37 1    
     6    3    5 ar   
     7    3   38 1    
     8    4   13 ar   
     9    4   39 1    
    10    5   13 ar   
    11    5   40 1    
    12    6   14 ar   
    13    6   16 ar   
    14    6   41 1    
    15    7   15 ar   
    16    7   18 ar   
    17    7   42 1    
    18    8   17 ar   
    19    8   19 ar   
    20    8   43 1    
    21    9   17 ar   
    22    9   20 ar   
    23    9   44 1    
    24   10   27 ar   
    25   10   30 ar   
    26   10   45 1    
    27   11   28 ar   
    28   11   30 ar   
    29   11   46 1    
    30   12   19 ar   
    31   12   20 ar   
    32   12   22 1    
    33   13   26 1    
    34   14   15 ar   
    35   14   23 1    
    36   15   24 1    
    37   16   18 ar   
    38   16   29 ar   
    39   17   30 1    
    40   18   31 ar   
    41   19   34 1    
    42   20   35 1    
    43   21   26 1    
    44   21   29 ar   
    45   21   31 ar   
    46   22   32 am   BACKBONE|DICT|INTERRES
    47   22   33 2    
    48   23   47 1    
    49   23   48 1    
    50   23   49 1    
    51   24   50 1    
    52   24   51 1    
    53   24   52 1    
    54   25   26 1    
    55   25   32 1    
    56   25   53 1    
    57   25   54 1    
    58   26   55 1    
    59   27   28 ar   
    60   29   56 1    
    61   32   57 1    
@<TRIPOS>SUBSTRUCTURE
     1 4M1         1 GROUP             0 A     ****    0 ROOT    
`,
};

export const Benzene = {
    smi: 'c1ccccc1',
};

export const Acetamide = {
    name: 'acetamide',
    pdb:
`REMARK   4      COMPLIES WITH FORMAT V. 3.0, 1-DEC-2006
REMARK 888
REMARK 888 WRITTEN BY MAESTRO (A PRODUCT OF SCHRODINGER, LLC)
TITLE     acetamide
MODEL        1
HETATM    1  N1  UNK   900       0.990  -0.871   0.000  1.00  0.00           N  
HETATM    2  C1  UNK   900       0.080   0.153   0.000  1.00  0.00           C  
HETATM    3  O1  UNK   900       0.422   1.326   0.000  1.00  0.00           O  
HETATM    4  C2  UNK   900      -1.377  -0.291   0.000  1.00  0.00           C  
HETATM    5  H1  UNK   900       0.722  -1.843   0.000  1.00  0.00           H  
HETATM    6  H2  UNK   900       1.973  -0.639   0.001  1.00  0.00           H  
HETATM    7  H3  UNK   900      -2.012   0.596  -0.003  1.00  0.00           H  
HETATM    8  H4  UNK   900      -1.604  -0.894   0.888  1.00  0.00           H  
HETATM    9  H5  UNK   900      -1.603  -0.900  -0.884  1.00  0.00           H  
CONECT    1    2    5    6
CONECT    2    1    4    3
CONECT    2    3
CONECT    3    2
CONECT    3    2
CONECT    5    1
CONECT    6    1
CONECT    4    2    7    8    9
CONECT    7    4
CONECT    8    4
CONECT    9    4
ENDMDL
END   `,
};

export const Xylo = {
    name: 'xylo',
    sdf:
`xylo
JL     BFD1206182001
XYL:Q
 20 20  0  0  0  0  0  0  0  0999 V2000
    0.9128   -1.7395   -0.8111 O   0  0  0  0  0  0  0  0  0  1  0  0
    1.8088   -0.7225   -0.4371 C   0  0  0  0  0  0  0  0  0  2  0  0
    1.1828    0.6695   -0.6521 C   0  0  0  0  0  0  0  0  0  3  0  0
   -0.1722    0.7565    0.0409 C   0  0  0  0  0  0  0  0  0  4  0  0
   -1.0702   -0.3945   -0.3911 C   0  0  0  0  0  0  0  0  0  5  0  0
   -0.3372   -1.7135   -0.1181 C   0  0  0  0  0  0  0  0  0  6  0  0
    2.1878   -0.8755    0.9119 O   0  0  0  0  0  0  0  0  0  7  0  0
    2.6718   -0.8555   -1.1021 H   0  0  0  0  0  0  0  0  0  8  0  0
    2.0878    1.6275   -0.1091 O   0  0  0  0  0  0  0  0  0  9  0  0
    1.0428    0.8345   -1.7301 H   0  0  0  0  0  0  0  0  0 10  0  0
   -0.0142    0.6665    1.1269 H   0  0  0  0  0  0  0  0  0 11  0  0
   -0.7222    2.0325   -0.2701 O   0  0  0  0  0  0  0  0  0 12  0  0
   -2.2742   -0.2675    0.3669 O   0  0  0  0  0  0  0  0  0 13  0  0
   -1.2762   -0.2995   -1.4681 H   0  0  0  0  0  0  0  0  0 14  0  0
   -0.1922   -1.8405    0.9609 H   0  0  0  0  0  0  0  0  0 15  0  0
   -0.9032   -2.5695   -0.5001 H   0  0  0  0  0  0  0  0  0 16  0  0
   -2.9502   -0.8335   -0.0351 H   0  0  0  0  0  0  0  0  0 17  0  0
    1.5908    2.4605   -0.0411 H   0  0  0  0  0  0  0  0  0 18  0  0
    2.4948    0.0045    1.1969 H   0  0  0  0  0  0  0  0  0 19  0  0
   -1.6042    2.0515    0.1379 H   0  0  0  0  0  0  0  0  0 20  0  0
 13 17  1  0  0  0  0
  4  5  1  0  0  0  0
  4 12  1  0  0  0  0
  4 11  1  0  0  0  0
  9 18  1  0  0  0  0
  5  6  1  0  0  0  0
  5 13  1  0  0  0  0
  5 14  1  0  0  0  0
  1  2  1  0  0  0  0
  1  6  1  0  0  0  0
  6 15  1  0  0  0  0
  6 16  1  0  0  0  0
  2  3  1  0  0  0  0
  2  7  1  0  0  0  0
  2  8  1  0  0  0  0
 12 20  1  0  0  0  0
  7 19  1  0  0  0  0
  3  4  1  0  0  0  0
  3  9  1  0  0  0  0
  3 10  1  0  0  0  0
M  END
$$$$
`,
    smi: 'O[C]1OC[C](O)[C](O)[C]1O',
    mol2:
`@<TRIPOS>MOLECULE
xylo
20   20    1
SMALL
USER_CHARGES


@<TRIPOS>ATOM
      1 O1          0.9128   -1.7395   -0.8111 O.3       1 UNK         0.0000 
      2 C2          1.8088   -0.7225   -0.4371 C.3       1 UNK         0.0000 
      3 C3          1.1828    0.6695   -0.6521 C.3       1 UNK         0.0000 
      4 C4         -0.1722    0.7565    0.0409 C.3       1 UNK         0.0000 
      5 C5         -1.0702   -0.3945   -0.3911 C.3       1 UNK         0.0000 
      6 C6         -0.3372   -1.7135   -0.1181 C.3       1 UNK         0.0000 
      7 O7          2.1878   -0.8755    0.9119 O.3       1 UNK         0.0000 
      8 H8          2.6718   -0.8555   -1.1021 H         1 UNK         0.0000 
      9 O9          2.0878    1.6275   -0.1091 O.3       1 UNK         0.0000 
     10 H10         1.0428    0.8345   -1.7301 H         1 UNK         0.0000 
     11 H11        -0.0142    0.6665    1.1269 H         1 UNK         0.0000 
     12 O12        -0.7222    2.0325   -0.2701 O.3       1 UNK         0.0000 
     13 O13        -2.2742   -0.2675    0.3669 O.3       1 UNK         0.0000 
     14 H14        -1.2762   -0.2995   -1.4681 H         1 UNK         0.0000 
     15 H15        -0.1922   -1.8405    0.9609 H         1 UNK         0.0000 
     16 H16        -0.9032   -2.5695   -0.5001 H         1 UNK         0.0000 
     17 H17        -2.9502   -0.8335   -0.0351 H         1 UNK         0.0000 
     18 H18         1.5908    2.4605   -0.0411 H         1 UNK         0.0000 
     19 H19         2.4948    0.0045    1.1969 H         1 UNK         0.0000 
     20 H20        -1.6042    2.0515    0.1379 H         1 UNK         0.0000 
@<TRIPOS>BOND
     1    1    2 1    
     2    1    6 1    
     3    2    3 1    
     4    2    7 1    
     5    2    8 1    
     6    3    4 1    
     7    3    9 1    
     8    3   10 1    
     9    4    5 1    
    10    4   12 1    
    11    4   11 1    
    12    5    6 1    
    13    5   13 1    
    14    5   14 1    
    15    6   15 1    
    16    6   16 1    
    17    7   19 1    
    18    9   18 1    
    19   12   20 1    
    20   13   17 1    
@<TRIPOS>SUBSTRUCTURE
     1 UNK         1 GROUP             0       ****    0 ROOT    
`,
    pdb:
`REMARK   4      COMPLIES WITH FORMAT V. 3.0, 1-DEC-2006
REMARK 888
REMARK 888 WRITTEN BY MAESTRO (A PRODUCT OF SCHRODINGER, LLC)
TITLE     xylo
MODEL        1
HETATM    1  O1  UNK   900       0.913  -1.740  -0.811  1.00  0.00           O  
HETATM    2  C1  UNK   900       1.809  -0.723  -0.437  1.00  0.00           C  
HETATM    3  C2  UNK   900       1.183   0.670  -0.652  1.00  0.00           C  
HETATM    4  C3  UNK   900      -0.172   0.756   0.041  1.00  0.00           C  
HETATM    5  C4  UNK   900      -1.070  -0.395  -0.391  1.00  0.00           C  
HETATM    6  C5  UNK   900      -0.337  -1.714  -0.118  1.00  0.00           C  
HETATM    7  O2  UNK   900       2.188  -0.875   0.912  1.00  0.00           O  
HETATM    8  O3  UNK   900       2.088   1.628  -0.109  1.00  0.00           O  
HETATM    9  O4  UNK   900      -0.722   2.033  -0.270  1.00  0.00           O  
HETATM   10  O5  UNK   900      -2.274  -0.268   0.367  1.00  0.00           O  
HETATM   11  H1  UNK   900       2.672  -0.856  -1.102  1.00  0.00           H  
HETATM   12  H2  UNK   900       1.043   0.835  -1.730  1.00  0.00           H  
HETATM   13  H3  UNK   900      -0.014   0.667   1.127  1.00  0.00           H  
HETATM   14  H4  UNK   900      -1.276  -0.300  -1.468  1.00  0.00           H  
HETATM   15  H5  UNK   900      -0.192  -1.841   0.961  1.00  0.00           H  
HETATM   16  H6  UNK   900      -0.903  -2.570  -0.500  1.00  0.00           H  
HETATM   17  H7  UNK   900      -2.950  -0.834  -0.035  1.00  0.00           H  
HETATM   18  H8  UNK   900       1.591   2.461  -0.041  1.00  0.00           H  
HETATM   19  H9  UNK   900       2.495   0.005   1.197  1.00  0.00           H  
HETATM   20  H10 UNK   900      -1.604   2.052   0.138  1.00  0.00           H  
CONECT    1    2    6
CONECT    2    1    3    7   11
CONECT    3    2    4    8   12
CONECT    4    3    5    9   13
CONECT    5    4    6   10   14
CONECT    6    1    5   15   16
CONECT    7    2   19
CONECT   11    2
CONECT    8    3   18
CONECT   12    3
CONECT   13    4
CONECT    9    4   20
CONECT   10    5   17
CONECT   14    5
CONECT   15    6
CONECT   16    6
CONECT   17   10
CONECT   18    8
CONECT   19    7
CONECT   20    9
ENDMDL
END   
`,
    cdxml:
`<?xml version="1.0" encoding="UTF-8" ?>
<!DOCTYPE CDXML SYSTEM "http://www.cambridgesoft.com/xml/cdxml.dtd" ><CDXML
 CreationProgram="ChemDraw 16.0.1.4"
 Name="nbasis-less-ions.cdx"
 BoundingBox="88.50 30 451.50 -32768"
 WindowPosition="0 0"
 WindowSize="1717960704 858980352"
 WindowIsZoomed="yes"
 FractionalWidths="yes"
 InterpretChemically="yes"
 ShowAtomQuery="yes"
 ShowAtomStereo="no"
 ShowAtomEnhancedStereo="yes"
 ShowAtomNumber="no"
 ShowResidueID="no"
 ShowBondQuery="yes"
 ShowBondRxn="yes"
 ShowBondStereo="no"
 ShowTerminalCarbonLabels="no"
 ShowNonTerminalCarbonLabels="no"
 HideImplicitHydrogens="no"
 LabelFont="3"
 LabelSize="10"
 LabelFace="96"
 CaptionFont="4"
 CaptionSize="12"
 HashSpacing="2.70"
 MarginWidth="2"
 LineWidth="1"
 BoldWidth="4"
 BondLength="30"
 BondSpacing="12"
 ChainAngle="120"
 LabelJustification="Auto"
 CaptionJustification="Left"
 AminoAcidTermini="HOH"
 ShowSequenceTermini="yes"
 ShowSequenceBonds="yes"
 ResidueWrapCount="40"
 ResidueBlockCount="10"
 ResidueZigZag="yes"
 NumberResidueBlocks="no"
 PrintMargins="36 36 36 36"
 MacPrintInfo="000300000258025800000000193C1360FFBAFFBA198213A60367052803FC000200000258025800000000193C1360000100000064000000010001010100000001270F000100010000000000000000000000000002001901900000000000600000000000000000000100000000000000000000000000000000"
 ChemPropName=""
 ChemPropFormula="Chemical Formula: "
 ChemPropExactMass="Exact Mass: "
 ChemPropMolWt="Molecular Weight: "
 ChemPropMOverZ="m/z: "
 ChemPropAnalysis="Elemental Analysis: "
 ChemPropBoilingPt="Boiling Point: "
 ChemPropMeltingPt="Melting Point: "
 ChemPropCritTemp="Critical Temp: "
 ChemPropCritPres="Critical Pres: "
 ChemPropCritVol="Critical Vol: "
 ChemPropGibbs="Gibbs Energy: "
 ChemPropLogP="Log P: "
 ChemPropMR="MR: "
 ChemPropHenry="Henry&apos;s Law: "
 ChemPropEForm="Heat of Form: "
 ChemProptPSA="tPSA: "
 ChemPropCLogP="CLogP: "
 ChemPropCMR="CMR: "
 ChemPropLogS="LogS: "
 ChemPropPKa="pKa: "
 ChemPropID=""
 color="0"
 bgcolor="1"
 RxnAutonumberStart="1"
 RxnAutonumberConditions="no"
 RxnAutonumberStyle="Roman"
 RxnAutonumberFormat="(#)"
><page
 id="146913"
 BoundingBox="0 0 540 -32768"
 HeaderPosition="36"
 FooterPosition="36"
 PrintTrimMarks="yes"
 HeightPages="900"
 WidthPages="1"
><fragment
 id="1"
 BoundingBox="184.21 30 355.79 185.39"
 Z="1"
><n
 id="2"
 p="300.74 157.22"
 xyz="300.74 157.22 58.70"
 Z="2"
 Element="8"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="296.84 161.12"
 BoundingBox="296.84 152.61 304.64 161.22"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">O</s></t></n><n
 id="3"
 p="326.94 127.49"
 xyz="326.94 127.49 47.77"
 Z="3"
 Geometry="Tetrahedral"
 BondOrdering="30 34 35 36"
/><n
 id="4"
 p="308.64 86.80"
 xyz="308.64 86.80 54.05"
 Z="4"
 Geometry="Tetrahedral"
 BondOrdering="34 39 40 41"
/><n
 id="5"
 p="269.02 84.26"
 xyz="269.02 84.26 33.79"
 Z="5"
 Geometry="Tetrahedral"
 BondOrdering="39 23 25 24"
/><n
 id="6"
 p="242.77 117.90"
 xyz="242.77 117.90 46.42"
 Z="6"
 Geometry="Tetrahedral"
 BondOrdering="23 27 28 29"
/><n
 id="7"
 p="264.20 156.46"
 xyz="264.20 156.46 38.44"
 Z="7"
/><n
 id="8"
 p="338.02 131.97"
 xyz="338.02 131.97 8.33"
 Z="8"
 Element="8"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="334.12 135.87"
 BoundingBox="334.12 127.35 341.92 135.97"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">O</s></t></n><n
 id="9"
 p="352.17 131.38"
 xyz="352.17 131.38 67.21"
 Z="9"
 Element="1"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="348.57 135.28"
 BoundingBox="348.57 126.94 355.79 135.28"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">H</s></t></n><n
 id="10"
 p="335.09 58.79"
 xyz="335.09 58.79 38.18"
 Z="10"
 Element="8"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="331.19 62.69"
 BoundingBox="331.19 54.18 338.99 62.79"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">O</s></t></n><n
 id="11"
 p="304.54 81.98"
 xyz="304.54 81.98 85.57"
 Z="11"
 Element="1"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="300.94 85.88"
 BoundingBox="300.94 77.53 308.17 85.88"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">H</s></t></n><n
 id="12"
 p="273.64 86.89"
 xyz="273.64 86.89 2.05"
 Z="12"
 Element="1"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="270.04 90.79"
 BoundingBox="270.04 82.45 277.27 90.79"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">H</s></t></n><n
 id="13"
 p="252.95 46.95"
 xyz="252.95 46.95 42.89"
 Z="13"
 Element="8"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="249.05 50.85"
 BoundingBox="249.05 42.34 256.85 50.95"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">O</s></t></n><n
 id="14"
 p="207.57 114.19"
 xyz="207.57 114.19 24.26"
 Z="14"
 Element="8"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="203.67 118.09"
 BoundingBox="203.67 109.58 211.47 118.19"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">O</s></t></n><n
 id="15"
 p="236.75 115.13"
 xyz="236.75 115.13 77.91"
 Z="15"
 Element="1"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="233.15 119.03"
 BoundingBox="233.15 110.69 240.37 119.03"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">H</s></t></n><n
 id="16"
 p="268.44 160.18"
 xyz="268.44 160.18 6.90"
 Z="16"
 Element="1"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="264.84 164.08"
 BoundingBox="264.84 155.74 272.06 164.08"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">H</s></t></n><n
 id="17"
 p="247.65 181.49"
 xyz="247.65 181.49 49.61"
 Z="17"
 Element="1"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="244.05 185.39"
 BoundingBox="244.05 177.05 251.28 185.39"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">H</s></t></n><n
 id="18"
 p="187.81 130.74"
 xyz="187.81 130.74 36.02"
 Z="18"
 Element="1"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="184.21 134.64"
 BoundingBox="184.21 126.30 191.43 134.64"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">H</s></t></n><n
 id="19"
 p="320.56 34.44"
 xyz="320.56 34.44 36.19"
 Z="19"
 Element="1"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="316.96 38.34"
 BoundingBox="316.96 30 324.19 38.34"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">H</s></t></n><n
 id="20"
 p="346.99 106.24"
 Z="20"
 Element="1"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="343.39 110.14"
 BoundingBox="343.39 101.80 350.61 110.14"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">H</s></t></n><n
 id="21"
 p="227.16 46.40"
 xyz="227.16 46.40 30.96"
 Z="21"
 Element="1"
 NumHydrogens="0"
 NeedsClean="yes"
 AS="N"
><t
 p="223.56 50.30"
 BoundingBox="223.56 41.96 230.78 50.30"
 LabelJustification="Left"
><s font="3" size="10" color="0" face="96">H</s></t></n><b
 id="22"
 Z="22"
 B="14"
 E="18"
 BS="N"
/><b
 id="23"
 Z="23"
 B="5"
 E="6"
 BS="N"
/><b
 id="24"
 Z="24"
 B="5"
 E="13"
 BS="N"
/><b
 id="25"
 Z="25"
 B="5"
 E="12"
 BS="N"
/><b
 id="26"
 Z="26"
 B="10"
 E="19"
 BS="N"
/><b
 id="27"
 Z="27"
 B="6"
 E="7"
 BS="N"
/><b
 id="28"
 Z="28"
 B="6"
 E="14"
 BS="N"
/><b
 id="29"
 Z="29"
 B="6"
 E="15"
 BS="N"
/><b
 id="30"
 Z="30"
 B="2"
 E="3"
 BS="N"
/><b
 id="31"
 Z="31"
 B="2"
 E="7"
 BS="N"
/><b
 id="32"
 Z="32"
 B="7"
 E="16"
 BS="N"
/><b
 id="33"
 Z="33"
 B="7"
 E="17"
 BS="N"
/><b
 id="34"
 Z="34"
 B="3"
 E="4"
 BS="N"
/><b
 id="35"
 Z="35"
 B="3"
 E="8"
 BS="N"
/><b
 id="36"
 Z="36"
 B="3"
 E="9"
 BS="N"
 CrossingBonds="38"
/><b
 id="37"
 Z="37"
 B="13"
 E="21"
 BS="N"
/><b
 id="38"
 Z="38"
 B="8"
 E="20"
 BS="N"
 CrossingBonds="36"
/><b
 id="39"
 Z="39"
 B="4"
 E="5"
 BS="N"
/><b
 id="40"
 Z="40"
 B="4"
 E="10"
 BS="N"
/><b
 id="41"
 Z="41"
 B="4"
 E="11"
 BS="N"
/></fragment></page></CDXML>`,
};

export const OneXJ = {
    name: '1XJ',
    smi: 'c1c(C2=C(CC(CC2)(C)C)CN2CCN(CC2)c2ccc(C(=O)NS(=O)(=O)c3cc(S(=O)(=O)C(F)(F)F)c(N[C@@H](CSc4ccccc4)CCN4CCOCC4)cc3)cc2)ccc(Cl)c1',
};

export const JAK3Ligand = {
    name: '4ST',
    smi: 'CO[C@H]1[C@@]2(O[C@@H](n3c4CCCCc4c4c5C(=O)NCc5c5c(c34)n2c2c5cccc2)C[C@H]1NC)C',
};

export const FourK6_no_H = { // eslint-disable-line camelcase
    name: '4K6_no_H',
    mol:
`4K6
Ketcher 12 41822592D 1   1.00000     0.00000     0

 27 30  0     0  0            999 V2000
   -1.5000   -2.5981    0.0000 O   0  0  0  0  0  0  0  0  0  1  0  0
   -2.0000   -1.7321    0.0000 C   0  0  0  0  0  0  0  0  0  2  0  0
   -3.0000   -1.7321    0.0000 C   0  0  0  0  0  0  0  0  0  3  0  0
   -3.5000   -0.8660    0.0000 C   0  0  0  0  0  0  0  0  0  4  0  0
   -4.5000   -0.8660    0.0000 C   0  0  0  0  0  0  0  0  0  5  0  0
   -5.0000   -1.7321    0.0000 C   0  0  0  0  0  0  0  0  0  6  0  0
   -6.0000   -1.7321    0.0000 N   0  0  0  0  0  0  0  0  0  7  0  0
   -6.5878   -0.9231    0.0000 C   0  0  0  0  0  0  0  0  0  8  0  0
   -7.5388   -1.2321    0.0000 N   0  0  0  0  0  0  0  0  0  9  0  0
   -7.5388   -2.2321    0.0000 N   0  0  0  0  0  0  0  0  0 10  0  0
   -6.5878   -2.5411    0.0000 C   0  0  0  0  0  0  0  0  0 11  0  0
   -4.5000   -2.5981    0.0000 C   0  0  0  0  0  0  0  0  0 12  0  0
   -3.5000   -2.5981    0.0000 C   0  0  0  0  0  0  0  0  0 13  0  0
   -1.5000   -0.8660    0.0000 N   0  0  0  0  0  0  0  0  0 14  0  0
   -0.5000   -0.8660    0.0000 C   0  0  0  0  0  0  0  0  0 15  0  0
    0.0000    0.0000    0.0000 C   0  0  0  0  0  0  0  0  0 16  0  0
    1.0000    0.0000    0.0000 C   0  0  0  0  0  0  0  0  0 17  0  0
    1.5878    0.8090    0.0000 N   0  0  0  0  0  0  0  0  0 18  0  0
    2.5388    0.5000    0.0000 C   0  0  0  0  0  0  0  0  0 19  0  0
    3.4049    1.0000    0.0000 C   0  0  0  0  0  0  0  0  0 20  0  0
    1.5878   -0.8090    0.0000 N   0  0  0  0  0  0  0  0  0 21  0  0
    2.5388   -0.5000    0.0000 C   0  0  0  0  0  0  0  0  0 22  0  0
    3.4049   -1.0000    0.0000 C   0  0  0  0  0  0  0  0  0 23  0  0
    4.2709   -0.5000    0.0000 C   0  0  0  0  0  0  0  0  0 24  0  0
    5.1370   -1.0000    0.0000 C   0  0  0  0  0  0  0  0  0 25  0  0
    4.2709    0.5000    0.0000 C   0  0  0  0  0  0  0  0  0 26  0  0
    5.1370    1.0000    0.0000 C   0  0  0  0  0  0  0  0  0 27  0  0
 27 26  1  0  0  0  0
 26 20  1  0  0  0  0
 20 19  2  0  0  0  0
 19 22  1  0  0  0  0
 22 23  2  0  0  0  0
 23 24  1  0  0  0  0
 26 24  2  0  0  0  0
 24 25  1  0  0  0  0
 17 21  2  0  0  0  0
 17 18  1  0  0  0  0
 17 16  1  0  0  0  0
 16 15  1  0  0  0  0
  2 14  1  0  0  0  0
  2  3  1  0  0  0  0
  3 13  1  0  0  0  0
  3  4  2  0  0  0  0
 13 12  2  0  0  0  0
  6  7  1  0  0  0  0
 12  6  1  0  0  0  0
  6  5  2  0  0  0  0
  5  4  1  0  0  0  0
 11  7  1  0  0  0  0
 22 21  1  0  0  0  0
 19 18  1  0  0  0  0
 15 14  1  0  0  0  0
  8  7  1  0  0  0  0
 11 10  2  0  0  0  0
  8  9  2  0  0  0  0
 10  9  1  0  0  0  0
  2  1  2  0  0  0  0
M  END
`,
};

export const Case4YZU = {
    uri: 'factorIXa/4YZU_frags',
};

export const CaseA2ar = {
    uri: 'a2ar/4UHR_pr_op_min_frags',
};

export const CaseEBV = {
    uri: 'EBV/2c9n_pr_op_min_frags',
};

export const Case4RMG = {
    uri: 'sirt2/4RMG_pr_op_min_frags',
};

export const Case1G0Y = {
    uri: 'IL1R1/1G0Y_pr_op_min_frags',
};

export const Case2C9N = {
    uri: 'EBV/2c9n_pr_op_min_frags',
};
