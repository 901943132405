import { TreeUtils } from '@Conifer-Point/px-components';
import { AtomGroupTypes } from './atomgroups';

export class TreeItem {
    constructor(type, displayName, info) {
        this.type = type;
        this.displayName = displayName;
        this.info = info;
    }
}

export class TreeLeaf extends TreeItem {
    constructor(item, displayName, info) {
        super(TreeItem.TypeLeaf, displayName, info);
        this.item = item;
    }
}

export class TreeGroup extends TreeItem {
    constructor(displayName, children = [], sortType, info) {
        super(TreeItem.TypeGroup, displayName, info);
        this.children = [...children];
        this.sortType = sortType;
    }

    empty() {
        this.children = [];
    }

    insertItem(item, position=this.children.length) {
        if (typeof (position) === 'number') {
            this.children.splice(position, 0, item);
        } else if (position instanceof Array) { // path
            TreeUtils.insert(this.children, position, item);
        } else if (typeof (position) === 'object') { // group info obj
            const groupName = position.groupName;
            let parentGroup = this;
            if (position.groupParents) {
                for (const g of position.groupParents) {
                    parentGroup = parentGroup.createGroup(g);
                }
            }
            let group = parentGroup.findGroupByName(groupName);
            if (!group) {
                group = new TreeGroup(groupName, [], position.sortType, position.info);
                parentGroup.insertItem(group);
            }
            if (!group.hasChildItem(item.item)) { group.insertItem(item); }
        }

        this.sort();
    }

    createGroup({
        groupName, children=[], sortType, info, unique,
    }, path) {
        let group = this.findGroupByName(groupName);
        if (!group || !unique) {
            group = new TreeGroup(groupName, children, sortType, info);
            this.insertItem(group, path);
        }
        return group;
    }

    findGroupByName(groupName) {
        return this.children.find(
            (x) => x.type === TreeItem.TypeGroup && x.displayName === groupName
        );
    }

    replaceAllInstances(oldItem, newItem) {
        for (const treeItem of this.children) {
            if (treeItem.type === TreeItem.TypeLeaf) {
                if (treeItem.item === oldItem) {
                    treeItem.item = newItem;
                }
            } else if (treeItem.type === TreeItem.TypeGroup) {
                treeItem.replaceAllInstances(oldItem, newItem);
            }
        }
    }

    removeAllInstances(removing) {
        TreeUtils.extract(this.children, (item) => (item.item === removing));
    }

    hasChildItem(item) {
        return this.children.find((x) => x.item === item);
    }

    findLeavesForItems(items) {
        const result = [];
        TreeUtils.traverse(this.children, (node) => {
            if (node.type === 'leaf' && items.includes(node.item)) {
                result.push(node);
            }
        });
        return result;
    }

    sort() {
        let sortFn = null;

        switch (this.sortType) {
            case 'Chain':
                sortFn = this.chainGroupSort;
                break;
            case 'ProteinTree':
                sortFn = this.proteinTreeSort;
                break;
            case 'Hotspots':
                sortFn = (a, b) => {
                    const hsA = a.item;
                    const hsB = b.item;
                    const xcpA = hsA.exchemPotentialAvg;
                    const xcpB = hsB.exchemPotentialAvg;
                    if (xcpA === xcpB) {
                        if (hsA.fragmentGroupNumber < hsB.fragmentGroupNumber) {
                            return -1;
                        } else {
                            return 1;
                        }
                    } else if (xcpA < xcpB) {
                        return -1;
                    } else {
                        return 1;
                    }
                };
                break;
            case 'Alphabetical':
                sortFn = (a, b) => {
                    const aName = a.displayName || a.item?.key || '';
                    const bName = b.displayName || b.item?.key || '';
                    return aName.localeCompare(bName, undefined, { sensitivity: 'base' });
                };
                break;
            case 'EnergyScore':
                sortFn = ({ item: a }, { item: b }) => {
                    const [aHasEnergy, bHasEnergy] = [a?.energyInfo, b?.energyInfo];
                    if (aHasEnergy && bHasEnergy) {
                        return a.energyInfo.energyScore < b.energyInfo.energyScore ? -1 : 1;
                    } else if (aHasEnergy) {
                        return -1;
                    } else if (bHasEnergy) {
                        return 1;
                    } else {
                        return 0;
                    }
                };
                break;
            default:
                if (this.sortType) {
                    console.warn(`TreeData: unknown sort type: ${this.sortType}`);
                }
                // Otherwise, we won't sort
        }
        if (sortFn) {
            this.children.sort(sortFn);
        }
    }

    // Sort the groups in the Protein Tree
    proteinTreeSort(a, b) {
        const aGroup = a instanceof TreeGroup;
        const bGroup = b instanceof TreeGroup;
        if (!aGroup && !bGroup) return 0;
        if (!aGroup || !bGroup) return !aGroup ? -1 : 1;
        const aName = a.displayName;
        const bName = b.displayName;

        if (aName.startsWith('Chain') && bName.startsWith('Chain')) {
            const aHasTarget = a.children.find((x) => x.item.type === AtomGroupTypes.Protein);
            const bHasTarget = b.children.find((x) => x.item.type === AtomGroupTypes.Protein);
            if ((aHasTarget || bHasTarget) && !(aHasTarget && bHasTarget)) { // xor
                return aHasTarget ? -1 : 1;
            } else {
                return aName < bName ? -1 : 1;
            }
        }

        const order = ['Chain', 'Target', 'Polymers', 'Cofactors', 'Ions', 'Hotspots'];
        const aIndex = order.findIndex((x) => x === aName || (x === 'Chain' && aName.startsWith('Chain')));
        const bIndex = order.findIndex((x) => x === bName || (x === 'Chain' && bName.startsWith('Chain')));

        if (aIndex > -1) {
            if (bIndex > -1) {
                return aIndex - bIndex;
            } else {
                return -1; // sort after
            }
        } else if (bIndex > -1) {
            return 1;
        }

        // Otherwise preserve existing order
        return 0;
    }

    // Sort the items in a Chain group in the Protein Tree
    chainGroupSort(a, b) {
        const typePriorities = [
            AtomGroupTypes.Protein, AtomGroupTypes.Polymer,
            AtomGroupTypes.PeptideLigand, AtomGroupTypes.Cofactor,
            AtomGroupTypes.Ion,
        ];
        const aItem = a.item;
        const bItem = b.item;
        const aType = aItem.type;
        const bType = bItem.type;
        // Sort by type
        if (aType !== bType) {
            const aPriority = typePriorities.indexOf(aType);
            const bPriority = typePriorities.indexOf(bType);
            return aPriority - bPriority;
        }
        // Otherwise preserve original order
        return 0;
    }
    /*
    removeItem(item) {
        const position = this.getChildPosition(item);
        if (position > -1) {
            return this.children.splice(position, 1);
        } else {
            console.warn(`Unable to remove item; couldn't get a position.`);
            return null;
        }
    }

    getChildPosition(item) {
        let position = item.position;
         if (this.children[position] !== item) {
            position = this.children.findIndex(item);
            console.warn(`Item at position ${item.position} was` +
                position > -1 ? `found at position ${position}` : `not found`);
        }
        return position;
    }

    moveItemWithinGroup(item, newPosition) {
        this.removeItem(item);
        this.insertItem(item, newPosition);
    }
    */
}

TreeItem.TypeLeaf = 'leaf';
TreeItem.TypeGroup = 'group';

export class TreeData {
    constructor(triggerFn) {
        this.init();
        this.trigger = triggerFn || (() => {});
    }

    init(restoring) {
        this.compoundsTree = new TreeGroup('Compounds');
        if (!restoring) {
            this.fragmentsTree = new TreeGroup('Fragments');
            this.proteinTree = new TreeGroup('Protein', [], 'ProteinTree');
        }
    }

    addToCompoundsTree(ag) {
        this.compoundsTree.insertItem(new TreeLeaf(ag));
    }

    addToFragmentTree(f, groupInfo) {
        this.fragmentsTree.insertItem(new TreeLeaf(f), groupInfo);
    }

    addToProteinTree(p, groupInfo) {
        this.proteinTree.insertItem(new TreeLeaf(p), groupInfo);
    }

    getTree(name) {
        switch (name) {
            case 'Compounds': return this.compoundsTree;
            case 'Fragments': return this.fragmentsTree;
            case 'Protein': return this.proteinTree;
            default:
                console.warn(`Unknown top level tree: ${name}`);
                return null;
        }
    }

    createGroup(name, treeName, path) {
        const tree = this.getTree(treeName);
        if (!tree) return null;
        const group = tree.createGroup({ groupName: name }, path);
        this.trigger();
        return group;
    }

    createGroupFromItems(items, treeName, destPathin, groupInfo) {
        let destPath = destPathin;
        const tree = this.getTree(treeName);
        const grouped = TreeUtils.extract(tree.children, (node, indexPath) => {
            if (items.includes(node)) {
                // If the destination path is not defined, use path of first item
                if (destPath === undefined) destPath = indexPath;
                return true;
            } else {
                return false;
            }
        });
        const groupName = groupInfo ? groupInfo.groupName : 'Grouped Items';
        const group = new TreeGroup(groupName, grouped);
        tree.insertItem(group, destPath);
        this.trigger();
        return group;
    }

    ungroup({ treeItem, indexPath }, treeName) {
        const tree = this.getTree(treeName);
        TreeUtils.splice(tree.children, indexPath, 1, ...treeItem.children);
        this.trigger();
    }

    deleteGroup(group, treeName, options={}) {
        const tree = this.getTree(treeName);
        const groups = [].concat(group);
        for (const g of groups) {
            g.existing = TreeUtils.subscript(tree.children, g.indexPath);
            g.existing = g.existing && g.existing.node;
        }

        TreeUtils.extract(tree.children, (node) => groups.find((g) => g.existing === node));
        this.trigger();
    }

    sortGroup(group, { sortType }) {
        group.sortType = sortType;
        group.sort();
        this.trigger();
    }

    renameGroup(group, newName) {
        group.displayName = newName;
        this.trigger();
    }

    moveItem(treeName, item, fromIndexPath, toIndexPath) {
        const tree = this.getTree(treeName);
        console.log(`${treeName} moving ${item.displayName || (item.item && item.item.key)} from ${fromIndexPath} to ${toIndexPath}`);
        TreeUtils.splice(tree.children, fromIndexPath, 1);
        TreeUtils.splice(tree.children, toIndexPath, 0, item);
        this.trigger();
    }

    findLeavesForItems(items, treeName) {
        const tree = this.getTree(treeName);
        return tree.findLeavesForItems(items);
    }

    removeAllInstances(removing, treeName) {
        const tree = this.getTree(treeName);
        tree.removeAllInstances(removing);
        this.trigger();
    }

    /// ///////////////////////// SAVE / RESTORE //////////////////////////////
    saveState() {
        return {
            compoundsTree: this.captureItems(this.compoundsTree.children),
            fragmentsTree: this.captureItems(this.fragmentsTree.children),
            proteinTree: this.captureItems(this.proteinTree.children),
        };
    }

    restoreState(treeState, getObjFn) {
        if (!treeState) return;

        const { compoundsTree, fragmentsTree, proteinTree } = treeState;

        const doCompoundsOnly = true;
        this.init(doCompoundsOnly);
        this.restoreItems(this.compoundsTree, compoundsTree, getObjFn);
        if (!doCompoundsOnly) {
            this.restoreItems(this.fragmentsTree, fragmentsTree, getObjFn);
            this.restoreItems(this.proteinTree, proteinTree, getObjFn);
        }
    }

    captureGroup(group) {
        return {
            type: group.type,
            name: group.displayName,
            sortType: group.sortType,
            children: this.captureItems(group.children),
        };
    }

    captureItems(list) {
        const ret = [];
        for (const item of list) {
            if (item.type === TreeItem.TypeGroup) {
                ret.push(this.captureGroup(item));
            } else {
                ret.push(this.captureLeaf(item));
            }
        }
        return ret;
    }

    captureLeaf(item) {
        return {
            type: item.type, name: item.displayName, itemType: item.item.type, key: item.item.key,
        };
    }

    restoreItems(destGroup, listOfNewItems, getObjFn) {
        for (const elt of listOfNewItems) {
            if (elt.type === TreeItem.TypeGroup) {
                this.restoreGroup(destGroup, elt, getObjFn);
            } else {
                this.restoreLeaf(destGroup, elt, getObjFn);
            }
        }
    }

    restoreGroup(hostGroup, incomingGroup, getObjFn) {
        console.log(`Restoring group ${incomingGroup.name} into ${hostGroup.displayName}`);
        const group = hostGroup.createGroup({
            groupName: incomingGroup.name,
            sortType: incomingGroup.sortType,
        });
        this.restoreItems(group, incomingGroup.children, getObjFn);
    }

    restoreLeaf(destGroup, item, getObjFn) {
        const obj = getObjFn(item.itemType, item.key);
        console.log(`Restoring leaf ${item.itemType}:${item.key} = ${obj} into ${destGroup.displayName}`);
        if (obj) {
            const newTreeItem = new TreeLeaf(obj);
            destGroup.insertItem(newTreeItem);
        } else {
            console.warn(`Can't add ${item.itemType}:${item.key}: No object found`);
        }
    }
}
