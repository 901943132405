/* math functions copied from math.tcl */

export const FP_PRECISION = 10000000;
/**
 * Trim a float to a certain precision.
 * Not 100% reliable, consider using `floatEquals`
 *
 */
export function fixFloat(num, precision=FP_PRECISION) {
    return Math.round(num * precision) / precision;
}

/**
 * Equality check for floats using a tolerance for difference.
 */
export function floatEquals(a, b, precision=FP_PRECISION) {
    return Math.abs(a - b) < (1 / precision);
}

/**
 * @description Returns a random integer between includedMin and excludedMax.
 *
 * @param {*} includedMin
 * @param {*} excludedMax
 * @returns random integer
 *
 * @note Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(includedMinIn, excludedMaxIn) {
    const includedMin = Math.ceil(includedMinIn);
    const excludedMax = Math.floor(excludedMaxIn);
    return Math.floor(Math.random() * (excludedMax - includedMin)) + includedMin;
}

export function getRandomFloat(includedMin, excludedMax, precision) {
    const n = Math.random() * (excludedMax - includedMin) + includedMin;
    return fixFloat(n, precision);
}

export function Add3_3(v1, v2) {
    return [0, 1, 2].map((i) => v1[i] + v2[i]);
}

export function Sub3_3(v1, v2) {
    return [0, 1, 2].map((i) => v1[i] - v2[i]);
}

export function Length3(v) {
    const [x, y, z] = v;
    return Math.sqrt(x*x + y*y + z*z);
}

export function MagSq3(v) {
    const [x, y, z] = v;
    return x*x + y*y + z*z;
}

export function Mul3_1(vec, num) {
    return vec.map((x) => x * num);
}

export function Div3_1(vec, num) {
    return vec.map((x) => x / num);
}

export function Dot3(v1, v2) {
    const [x1, y1, z1] = v1;
    const [x2, y2, z2] = v2;
    return x1*x2 + y1*y2 + z1*z2;
}

export function Cross3(a, b) {
    const [a0, a1, a2] = a;
    const [b0, b1, b2] = b;
    return [
        a1*b2 - b1*a2,
        a2*b0 - b2*a0,
        a0*b1 - b0*a1,
    ];
}

export function Distance(p1, p2) {
    return Length3(Sub3_3(p2, p1));
}

export function Centroid(points) {
    return Avg3(points);
}

export function AngleBetweenPoints(p1, p2, p3) {
    // Find the vectors and distances from the center (p2) to the others
    const delta12 = Sub3_3(p1, p2);
    const delta32 = Sub3_3(p3, p2);
    const dist12 = Length3(delta12);
    const dist32 = Length3(delta32);

    // Find unit vectors
    const unit12 = Div3_1(delta12, dist12);
    const unit32 = Div3_1(delta32, dist32);

    // Find the axis of rotation from 12 to 32.
    // It is normal to the plane of the three atoms.
    const axis = Cross3(unit12, unit32);

    // Find the angle of rotation
    // sin(angle) is the cross-product of the unit vectors
    const axisdot = Dot3(unit12, unit32);
    let axismag = Length3(axis);

    let angleRadians;
    if (axismag < 1.0e-5) {
        // either colinear or anti-linear
        if (axisdot < 0) {
            angleRadians = -Math.PI;
        } else {
            angleRadians = 0;
        }
    } else {
        axismag = Math.min(axismag, 1.0);
        axismag = Math.max(axismag, -1.0);

        angleRadians = Math.asin(axismag);
        if (axisdot < 0) {
            angleRadians = Math.PI - angleRadians;
        }
    }
    return angleRadians;
}

/**
 * Apply a transform to a point, rotating first
 * @param {[number, number, number]} pos
 * @param {Array<[number, number, number]>} rotation
 * @param {[number, number, number]} translation
 * @returns {[number, number, number]}
 */
export function RotateThenTranslate(pos, rotation, translation) {
    let result = ApplyRotation(rotation, pos);
    result = ApplyTranslation(translation, result);
    return result;
}

/**
 * Apply a transform to a point, translating first
 * @param {[number, number, number]} pos
 * @param {[number, number, number]} translation
 * @param {Array<[number, number, number]>} rotation
* @returns {[number, number, number]}
 */
export function TranslateThenRotate(pos, translation, rotation) {
    let result = ApplyTranslation(translation, pos);
    result = ApplyRotation(rotation, result);
    return result;
}

/**
 *
 * Transpose a "rotate then translate" transform, making it "translate then rotate"
 * @param {{ translation: [number, number, number], rotation: Array<[number, number, number]> }}
 * @returns {{ translation: [number, number, number], rotation: Array<[number, number, number]> }}
 */
export function TransposeRTTransform({ translation: t, rotation: u }) {
    const u2 = [
        [u[0][0], u[1][0], u[2][0]],
        [u[0][1], u[1][1], u[2][1]],
        [u[0][2], u[1][2], u[2][2]],
    ];
    const t2 = ApplyRotation(u2, t);
    return { translation: t2, rotation: u };
}

/**
 * Transpose a "translate then rotate" transform, making it "rotate then translate"
 */
export function TransposeTRTransform({ translation: t, rotation: u }) {
    const t2 = ApplyRotation(u, t);
    return { translation: t2, rotation: u };
}

/**
 * Invert a "rotate then translate" transform
 * @param {{ translation, rotation }} param0
 * @returns {{ translation, rotation }}
 */
export function InvertRTTransform({ translation, rotation }) {
    // Undo the translation and rotation
    const translation2 = InvertTranslation(translation);
    const rotation2 = InvertMatrix(rotation);
    // These need to be done in reverse order (translate then rotate) from the original transform.
    // To keep the same order of operations for the client, transpose the matrix to swap the order.
    let transform2 = { translation: translation2, rotation: rotation2 };
    transform2 = TransposeTRTransform(transform2);
    return transform2;
}

/**
 * Invert a "translate then rotate" transform
 * @param {{ translation, rotation }} param0
 * @returns {{ translation, rotation }}
 */
export function InvertTRTransform({ translation, rotation }) {
    // Undo the translation and rotation
    const translation2 = InvertTranslation(translation);
    const rotation2 = InvertMatrix(rotation);
    // These need to be done in reverse order (rotate then translate) from the original transform.
    // To keep the same order of operations for the client, transpose the matrix to swap the order.
    let transform2 = { translation: translation2, rotation: rotation2 };
    transform2 = TransposeRTTransform(transform2);
    return transform2;
}

/** Convenience function for adding vectors */
export function ApplyTranslation(translation, pos) {
    const result = Add3_3(pos, translation);
    return result;
}

/** Convenience function for multiplying by -1 */
export function InvertTranslation(translation) {
    const result = Mul3_1(translation, -1);
    return result;
}

/**
 * Apply a rotation to a point
 * @param {Array<[number, number, number]>} rotationMatrix
 * @param {[number, number, number]} pos
 * @returns {[number, number, number]}
 */
export function ApplyRotation(rotationMatrix, pos) {
    const u = rotationMatrix;
    const [x, y, z] = pos;
    const x2 = u[0][0]*x + u[0][1]*y + u[0][2]*z;
    const y2 = u[1][0]*x + u[1][1]*y + u[1][2]*z;
    const z2 = u[2][0]*x + u[2][1]*y + u[2][2]*z;
    return [x2, y2, z2];
}

// from bgeom.elp
export function matrixToOrientation(matrix, offset) {
    // Convenience functions to minimize diff from c++ code
    function rot(i, j) { return matrix[i][j]; }
    function fabs(n) { return Math.abs(n); }
    function sqrt(n) { return Math.sqrt(n); }
    function square(n) { return n*n; }
    function signbit(n) { return fixFloat(n) < 0; }
    function acos(n) { return Math.acos(n); }
    const zeroThreshold = 1 / FP_PRECISION;

    // Default return values.
    let angle = 0;
    const vec = [0, 0, 0];

    // Sum of diags is ((1-c)*x^2+c) + ((1-c)*y^2+c) + ((1-c)*z^2+c),
    // (factored) (1-c)*(x^2+y^2+z^2) + 3*c = 1 + 2*c where
    // c=cos(theta), and x,y,z, is unit vector.
    const c = (rot(0, 0) + rot(1, 1) + rot(2, 2) - 1.0)*0.5;

    // Handle degenerate identity matrix case (zero rotation).
    // Note, c may be slightly bigger than FLT_EPSILON due to 0.5 above.
    if (fabs(c - 1.0) <= zeroThreshold || c > 1.0) return vec;

    // Each diagnoal term is (1-c)*x^2 + c,... so we we can check if any
    // of the unit vector components should be exactly zero.
    const xzero = fabs(rot(0, 0) - c) <= zeroThreshold;
    const yzero = fabs(rot(1, 1) - c) <= zeroThreshold;
    const zzero = fabs(rot(2, 2) - c) <= zeroThreshold;

    // To solve for x,y,z we need to divide by sin(theta), so we need to know if it is
    // zero before proceeding (already checked c=+1.0 above).
    const oMc2 = 1.0 - square(c); // take sqrt of this below

    if (fabs(c + 1.0) <= zeroThreshold || signbit(oMc2)) {
        angle = Math.PI;
        // Direction vector can be in either of two opposite direction for pi, since
        // either of them will cause a rotation to the same place.  Thus, the sign of the
        // first component (say, x) is arbitrary, as long as the sign for subsequent
        // components are consistent with the orignal choice, as dictated by the
        // off-diagonal rotation matrix entries.

        // Solve for the first non-zero component using the diagnonal term.
        if (!xzero) {
            vec[0] = sqrt((rot(0, 0)+1.0)*0.5);
            vec[1] = (rot(0, 1)/vec[0])*0.5;
            vec[2] = (rot(0, 2)/vec[0])*0.5;
        } else if (!yzero) {
            // x is zero, y from diagnoal
            vec[1] = sqrt((rot(1, 1)+1.0)*0.5);
            vec[2] = (rot(1, 2)/vec[1])*0.5;
        } else {
            // x, y zero only have z component. Better be 1!
            vec[2] = 1.0;
        }
    } else {
        // Now we can assume s is not zero
        const sinv2 = 1.0/(2.0*sqrt(oMc2)); // deal with sign below
        vec[0] = (rot(2, 1) - rot(1, 2))*sinv2;
        vec[1] = (rot(0, 2) - rot(2, 0))*sinv2;
        vec[2] = (rot(1, 0) - rot(0, 1))*sinv2;
        angle = acos(c); // returns 0,pi
    }
    // When the unit vector is multiplied by the angle, angle's near zero will cause the
    // resolution of the unit vector to be substantially dimminished. Adding an offset fixes
    // this.
    if (angle <= zeroThreshold) angle = 0.0;

    angle += offset;

    // Normalize
    const m = Length3(vec);
    // Avoid divide by zero when all matrix elements are zero (or almost zero). Why would
    // this ever happen? Report it?
    if (m <= zeroThreshold) return vec;

    vec[0] /= m;
    vec[1] /= m;
    vec[2] /= m;

    // Solve for the sign of sin(theta).  If negative, the angle should be negative, for
    // now.  Note, we could use signof() below but that's probably more expensive than
    // doing the divides (unless we had a better implementation of signof()).  Note, when
    // orientation vectors are converted back to matrices (above), the sign of the angle
    // is not retained, but left in the direction of the unit vector.
    if (!xzero) {
        if ((rot(2, 1) - (1.0-c)*vec[1]*vec[2])/vec[0] < 0) angle = -angle;
    } else if (!yzero) {
        if (rot(0, 2)/vec[1] < 0) angle = -angle; // x is zero
    } else if (!zzero) {
        if (rot(1, 0)/vec[2] < 0) angle = -angle; // x,y are zero
    }

    // Install the angle
    vec[0] *= angle;
    vec[1] *= angle;
    vec[2] *= angle;

    return vec;
    // Wasn't so bad, was it?
}

// Ported from bgeom.elp
/* eslint-disable comma-spacing */
export function InvertMatrix(matrix) {
    const result = [[0,0,0], [0,0,0], [0,0,0]];

    // Convenience functions to keep the same C++ code
    const m = (i, j) => matrix[i][j];

    // Could use L-U decomposition, or Gauss-Jordan elimination, or
    // something, but it's probably overkill here
    // For 3x3, just expand in cofactors.
    const i00 = m(1,1)*m(2,2) - m(1,2)*m(2,1);
    const i01 = m(0,2)*m(2,1) - m(0,1)*m(2,2);
    const i02 = m(0,1)*m(1,2) - m(0,2)*m(1,1);
    const i10 = m(1,2)*m(2,0) - m(1,0)*m(2,2);
    const i11 = m(0,0)*m(2,2) - m(0,2)*m(2,0);
    const i12 = m(0,2)*m(1,0) - m(0,0)*m(1,2);
    const i20 = m(1,0)*m(2,1) - m(1,1)*m(2,0);
    const i21 = m(0,1)*m(2,0) - m(0,0)*m(2,1);
    const i22 = m(0,0)*m(1,1) - m(0,1)*m(1,0);

    const det = i22*m(2,2)+i12*m(2,1) + i02*m(2,0);

    result[0][0] = i00/det;
    result[0][1] = i01/det;
    result[0][2] = i02/det;
    result[1][0] = i10/det;
    result[1][1] = i11/det;
    result[1][2] = i12/det;
    result[2][0] = i20/det;
    result[2][1] = i21/det;
    result[2][2] = i22/det;

    return result;
}
/* eslint-enable comma-spacing */

export function DegreesFromRadians(x) {
    return (x / Math.PI) * 180.0;
}

export function DegreesToRadians(x) {
    return (x / 180) * Math.PI;
}

export function Avg3(listV1) {
    const sum = [0, 0, 0];
    const len = listV1.length;
    if (len === 0) return sum;

    let xsum = 0;
    let ysum = 0;
    let zsum = 0;
    for (const v of listV1) {
        xsum += v[0];
        ysum += v[1];
        zsum += v[2];
    }
    return [xsum/len, ysum/len, zsum/len];
}

export function Normalize3(vec) {
    let magSq = Dot3(vec, vec);
    if (magSq <= 0.000001) magSq = 1;
    return Div3_1(vec, Math.sqrt(magSq));
}

/**
 * Warning: this has not been tested for actual use.
 * This is only currently used to generate test matrices in math.test.js.
 */
export function MakeRotationMatrixAboutAxis(normalizedAxis, theta) {
    const sintheta = Math.sin(theta);
    const costheta = Math.cos(theta);
    const ucos = 1 - costheta;
    const [axisX, axisY, axisZ] = normalizedAxis;

    const mat = [
        [
            costheta + ucos * axisX * axisX,
            ucos * axisX * axisY - axisZ * sintheta,
            ucos * axisX * axisZ + axisY * sintheta,
        ],
        [
            ucos * axisX * axisY + axisZ * sintheta,
            costheta + ucos * axisY * axisY,
            ucos * axisY * axisZ - axisX * sintheta,
        ],
        [
            ucos * axisX * axisZ - axisY * sintheta,
            ucos * axisY * axisZ + axisX * sintheta,
            costheta + ucos * axisZ * axisZ,
        ],
    ];
    return mat;
}

export function TorsionAngle(p1, p2, p3, p4) {
    let temp;

    // p2-p3 is the axis
    const delta32 = Sub3_3(p3, p2);
    const dist32 = Length3(delta32);
    const unit32 = Div3_1(delta32, dist32);

    // Find the midpoint of the axis.
    // Add half the axis length to p2.
    let pm = Mul3_1(delta32, 0.5);
    pm = Add3_3(p2, pm);

    // p1-p2 is the "front" vector.
    // Find a point p1z which is the projection of p1 into the plane
    // through p2, normal to the axis.  Simply add (the projection
    // of p21 onto unit32) from p1.
    const delta12 = Sub3_3(p2, p1);
    const p1dot = Dot3(delta12, unit32);
    let p1z = Mul3_1(unit32, p1dot);
    p1z = Add3_3(p1, p1z);
    // Find the distance p1z-p2 (which is normal to the axis)
    temp = Sub3_3(p1z, p2);
    const radius1 = Length3(temp);

    // p3-p4 is the "back" vector.
    // Find a point p4z which is the projection of p4 into the plane
    // through p3, normal to the axis.  Simply subtract (the projection
    // of p43 onto unit32) from p4.

    const delta43 = Sub3_3(p4, p3);
    const p4dot = Dot3(delta43, unit32);
    let p4z = Mul3_1(unit32, p4dot);
    p4z = Sub3_3(p4, p4z);
    // Find the distance p4z-p3 (which is normal to the axis)
    temp = Sub3_3(p4z, p3);
    const radius4 = Length3(temp);

    // find the radius to use
    let radius = Math.min(radius1, radius4);
    radius = Math.min(radius, 2.0); // limit

    // Find a point p1m which is in the bisector plane, on the line which
    // is the projection of p1-p2, at a distance of radius from pm
    // plane through the axis bisector, normal to the axis.
    // Add half of delta32 from p1z, then adjust length to radius.
    // Also need unit-length vector.
    let unit1m = Mul3_1(delta32, 0.5);
    unit1m = Add3_3(p1z, unit1m);
    unit1m = Sub3_3(unit1m, pm);
    unit1m = Mul3_1(unit1m, 1 / Length3(unit1m));
    let p1m = Mul3_1(unit1m, radius);
    p1m = Add3_3(p1m, pm);

    // Find a point p4m which is in the bisector plane, on the line which
    // is the projection of p3-p4, at a distance of radius from pm
    // plane through the axis bisector, normal to the axis.
    // Subtract half of delta43 from p4z, then adjust length to radius.
    // Also need unit-length vector.
    let unit4m = Mul3_1(delta32, 0.5);
    unit4m = Sub3_3(p4z, unit4m);
    unit4m = Sub3_3(unit4m, pm);
    unit4m = Mul3_1(unit4m, 1 / Length3(unit4m));
    let p4m = Mul3_1(unit4m, radius);
    p4m = Add3_3(p4m, pm);

    // Find a point p1x which is the projection of p1m back onto p1-p2
    // It is the fraction radius/radius1 of the way from p2 to p1
    let p1x = Mul3_1(delta12, radius / radius1);
    p1x = Sub3_3(p2, p1x);

    // Find a point p4x which is the projection of p4m back onto p3-p4
    // It is the fraction radius/radius4 of the way from p3 to p4
    let p4x = Mul3_1(delta43, radius / radius4);
    p4x= Add3_3(p3, p4x);

    return AngleBetweenPoints(p1m, pm, p4m);
}
