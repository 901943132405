import { useEffect } from 'react';
import styled from 'styled-components';
import { EventBroker } from '../../eventbroker';
import { useForceUpdate } from '../common/helpers';
import { validateRightControlPanelAction, doRightControlPanelAction } from './utils';
import { App } from '../../BMapsApp';
import DrawerButton from './base/DrawerButton';
import ActionsDrawer from './ActionsDrawer';
import { BindingSiteSurfaceStates, HBondStates } from '../../model/DisplayState';
import { withColorSchemeInfoStyled } from '../../redux/prefs/access';

/**
 * @todo Remove this call to withColorSchemeInfoStyled when we can figure out how to ensure
 * that the internal DrawerButton gets the $colorSchemeInfo
 */
const ToggleButton = withColorSchemeInfoStyled(styled(DrawerButton)`
    color: ${({ $checked }) => ($checked ? 'rgb(67, 242, 88)' : 'rgb(133, 145, 135)') }
`);

function processViewState() {
    const viewState = App.Workspace?.displayState;
    const updates = {};

    if (!viewState) {
        return updates;
    }

    updates.hbonds = viewState.hbonds !== HBondStates.none;
    updates.hotspots = !!viewState.hotspots;
    updates.ligandsolvation = !!viewState.functionalGroupHighlight;
    switch (viewState.bindingSiteSurfaceState) {
        case BindingSiteSurfaceStates.off:
            updates.protsolvation = false;
            updates.hydrophobic = false;
            break;
        case BindingSiteSurfaceStates.ddgs:
            updates.protsolvation = true;
            updates.hydrophobic = false;
            break;
        case BindingSiteSurfaceStates.hydrophobicity:
            updates.hydrophobic = true;
            updates.protsolvation = false;
            break;
        default:
            console.warn(`onViewStateChanged: unhandled binding site surface state: ${viewState.bindingSiteSurfaceState}`);
    }

    return updates;
}

// NOTE: Previous implementation of toggle buttons can accommodate more two states.
// However, this feature was not used so the logic below was simplied to accommodate
// only two states (ie. on/off)
export default function TogglesDrawer({ title, label, toggles }) {
    const forceUpdate = useForceUpdate();

    const updates = processViewState();
    const toggleButtons = toggles.map((tog) => (
        <ToggleButton
            key={tog.id}
            title={tog.desc}
            onClick={handleClick(tog.id, updates[tog.id])}
            $checked={updates[tog.id]}
        >
            {tog.label}
        </ToggleButton>
    ));

    function handleClick(id, checked) {
        return () => {
            // NOTE: When user clicks toggle button, the checked state will be inverted. So if
            // the current checked state is true, then next state should be false ('off').
            const styleId = `${id}${checked ? 'off' : 'on'}`;

            if (validateRightControlPanelAction(styleId)) {
                doRightControlPanelAction(styleId);
            }
        };
    }

    useEffect(() => {
        const handleViewStateChanged = EventBroker.subscribe('viewStateChanged', () => {
            forceUpdate();
        });

        return () => EventBroker.unsubscribe('viewStateChanged', handleViewStateChanged);
    }, []);

    return <ActionsDrawer title={title} label={label}>{toggleButtons}</ActionsDrawer>;
}
