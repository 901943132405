import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiCloseRoundedIcon from '@mui/icons-material/CloseRounded';
import styled from 'styled-components';

/**
 * Component for the title bar of a dialog.
 * Add children to add custom JSX to the left of the close button.
 *
 * Props: { title, handleClose }
 */
const DialogHeader = styled(({
    className, handleClose, title, children,
}) => (
    <DialogTitle className={className}>
        <Grid container wrap="nowrap">
            <Grid item container alignContent="center" flex="1 1 content">
                <Typography variant="h5" className="title">
                    { title }
                </Typography>
            </Grid>
            <Grid item container alignContent="center" justifyContent="flex-end" flex="1 1 content">
                {children}
            </Grid>
            <IconButton onClick={handleClose} size="small" aria-label="close modal">
                <CloseRoundedIcon />
            </IconButton>
        </Grid>
    </DialogTitle>
))`
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};

  & .title {
    font-weight: 700;
  }
`;

DialogHeader.defaultProps = {
    titlebarExtra: null,
};

const CloseRoundedIcon = styled(MuiCloseRoundedIcon)`
  color: white;
`;

export default DialogHeader;
