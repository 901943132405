import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import useEventBrokerSubscription from '../common/helpers/useEventBrokerSubscription';
import { EventBroker } from '../../eventbroker';
import { ensureArray } from '../../util/js_utils';
import { withColorSchemeInfoStyled } from '../../redux/prefs/access';

/**
 * SidePanel - class to manage side panels
 * Content panels can be temporary or long-lived. Temporary panels are unmounted when closed.
 * Long-lived panels are cloned when displayed, but preserve their state variables across clones.
 *
 * side: `left` | `Info`
 * The "default" page is optional - what will be displayed when the panel is "closed"
 * For example, the Selector in InfoDisplay
 *
 * @param {{
 *      side: string,
 *      defaultPage: { pageId: string, component: JSX.Element, props: object }?,
 *      defaultPageId: string?,
 *      children: JSX.Element[]?,
 * }} props
 *
 * @example `UserActions.OpenSidePanel('left', { pageId: 'docking' });`
 * @example `Eventbroker.publish(setInfoPanel, <jsx />)
*/
export default function SidePanel({
    side, defaultPage, defaultPageId, children,
}) {
    // pageInfoList - currently mounted pages
    // This can be initially populated with JSX children or a default page object.

    const [pageInfoList, setPageInfoList] = useState(() => {
        const pages = [];
        if (defaultPage) {
            pages.push({
                pageId: defaultPage.pageId,
                component: defaultPage.component,
                props: { ...defaultPage.props, open: true },
                longLived: true,
            });
        }
        if (children) {
            for (const child of ensureArray(children)) {
                pages.push({
                    pageId: child.props.pageId,
                    component: child,
                    props: { ...child.props, open: false },
                    longLived: true,
                });
            }
        }
        return pages;
    });

    // Resize needs to run after rendering, so needs to be in useEffect
    useEffect(() => {
        EventBroker.publish('resize');
    }, [pageInfoList]);

    const handleClose = useCallback(() => { EventBroker.publish(`set${side}Panel`); }, [side]);
    const handleSetEvents = useCallback((_, data) => {
        // There's a request to update the SidePanel
        // Possibilities:
        // 1. Close / reset to default, by passing no arguments
        // 2. Display an existing page, by passing a pageId, perhaps with optional props to update
        // 3. Add and display a new page, by passing a pageId, component, and optional props
        // The `longLived` option controls whether to keep the page mounted when it's not visible.

        // Remove temporary pages
        let updatedPages = pageInfoList.filter((page) => page.longLived);
        // Add new page if it doesn't exist
        if (data && !pageInfoList.find((page) => page.pageId === data.pageId)) {
            updatedPages.push(data);
        }
        const visiblePageId = data?.pageId || defaultPageId || defaultPage?.pageId;
        console.log(`Showing ${side} Panel with ${visiblePageId}. Requested: ${data?.pageId}`);
        // Update pages, especially `open` prop and maybe incoming props for the target page
        updatedPages = updatedPages.map((pageInfo) => ({
            ...pageInfo,
            props: {
                ...pageInfo.props,
                ...((pageInfo.pageId === visiblePageId) ? (data?.props) : {}),
                open: visiblePageId != null && pageInfo.pageId === visiblePageId,
                setOpen(open, props) {
                    if (!open) {
                        handleClose();
                    } else {
                        EventBroker.publish(`set${side}Panel`, { pageId: pageInfo.pageId, props: { ...props } });
                    }
                },
                handleClose,
            },
        }));
        setPageInfoList(updatedPages);
    }, [pageInfoList, defaultPageId, defaultPage?.pageId, side, handleClose]);

    useEventBrokerSubscription(`set${side}Panel`, handleSetEvents);
    useEventBrokerSubscription('zapAll', handleClose);

    return (
        <>
            {pageInfoList.map((pageInfo) => (
                // cloneElement is used because we need to pass `open` prop into the component.
                // Note: useState variables are maintained in the clone.
                // For example, the DockingPane has a dockingState useState. The state is
                // preserved across several calls to setSidePanel, because the useState hook
                // implementation returns consistent values and setter functions in the clones.
                <React.Fragment key={pageInfo.pageId}>
                    {React.cloneElement(pageInfo.component, pageInfo.props)}
                </React.Fragment>
            ))}
        </>
    );
}

export const SidePanelContainer = withColorSchemeInfoStyled(styled(({
    title, children, open, handleClose, width='18rem', className,
}) => (
    open != null && !!open && (
        <div className={className}>
            <div className="sidepanel-container" style={{ width }}>
                <div className="sidepanel-container-title">
                    <div>{title}</div>
                    <button type="button" onClick={() => handleClose()}>
                        <i className="fa fa-close" />
                    </button>
                </div>
                <div className="sidepanel-container-content">
                    {children}
                </div>
            </div>
        </div>
    )
))`
${({ $colorSchemeInfo: { css: color, textCss: textColor } }) => `
height: 100%;
& .sidepanel-container {
    background: ${color};
    height: 100%;
    display: flex;
    flex-direction: column;
}
& .sidepanel-container-content {
    margin: .5rem;
    overflow: auto;
    border-radius: .2rem;
    flex: 1;
    background: ${color};
    border: 1px solid ${textColor};
    color: ${textColor};
}
& .sidepanel-container-title {
    margin: .5rem .5rem 0 .5rem;
    display: flex;
    justify-content: space-between;
    padding: .5rem;
    border-radius: .2rem;
    fontSize: calc(1px + 2vmin);
    font-family: sans-serif;
    background: ${color};
    border: 1px solid ${textColor};
}
& .sidepanel-container-title button {
    fontSize: inherit;
    background-color: transparent;
    color: inherit;
    border: none;
    margin: 0;
    text-align: inherit;
    outline: none;
}
@media only screen and (max-width: 600px) {
    & .sidepanel-container-content, & .sidepanel-container-title {
        width: 100vw;
        z-index: 500;
        margin: 0rem;
    }
}
`}
// Close two template literals: inner for CSS with colors added; outer for styled-component input
`);

export function SidePanelSection({ children }) {
    return (
        <div style={{
            background: 'white',
            color: 'black',
            borderRadius: '.2rem',
            padding: '.5rem',
        }}
        >
            {children}
        </div>
    );
}
