import { WebServices } from '../WebServices';
import { ensureArray } from '../util/js_utils';
import { App } from '../BMapsApp';
/**
 *
 * @param {*} gifeGroups
 * @param {{ atoms: Atom[], atomGroup: MolAtomGroup[] }} gifeGroups
 * @returns {Promise<{GiFEDisplayResults:
 * { compound: string, protein: string, value: number, warning: string, error: string},
 *   error: string }>}
 * initiateGiFERequest takes gifeGroups (list atoms and atom groups) as an argument
 * and returns the GiFEDisplayObject
 *
 * The error returned in the GIFEDisplayObject represents a error from the
 * GiFE API (ex. wrong version, error in calculation etc.) and is formatted as
 * a string.
 *
 * The error returned alongside the GIFEDisplayObject in this function stores any non-API
 * handled errors that occur while making the request and is formatted
 * as a string.
 *
 * This function will return the final result.
 */
export async function initiateGiFERequest(gifeGroups) {
    let parts = [];
    const allNames = [];
    const pNames = [];
    const rNames = [];
    const cNames = [];

    for (const { atoms, atomGroup } of gifeGroups) {
        console.log(`One GiFE group with ${atoms.length} atoms from ${atomGroup.displayName}`);
        const isCompound = atoms.filter((atom) => App.Workspace.isCompoundAtom(atom)).length > 0;
        const unboundFlag = isProteinCompoundCase(gifeGroups);
        let list;
        if (isCompound === false) {
            const [rName, other] = atomGroup.displayName.split('.');
            rNames.push(rName);
            const protein = App.getDataParents(atomGroup).caseData.mapCase.pdbID;
            if (pNames.indexOf(protein) === -1) {
                pNames.push(protein);
                list = atomsToGiFEAtomList(protein, atoms, rNames, unboundFlag);
                allNames.push(protein);
                parts.push(list);
            } else {
                parts = addToProtein(protein, atoms, parts, rNames);
            }
        } else {
            if (cNames.indexOf(atomGroup.displayName) === -1) {
                cNames.push(atomGroup.displayName);
                list = atomsToGiFEAtomList(atomGroup.displayName, atoms, [], unboundFlag);
                allNames.push(atomGroup.displayName);
                parts.push(list);
            } else {
                // this case may not be necessary
                // since handlecalc will always feature the most recently selected compound atoms
                parts = addToCompound(atomGroup.displayName, atoms, parts);
            }
        }
    }
    const systems = {};
    cNames.forEach((cn) => {
        pNames.forEach((pn) => {
            const sysKey = `${pn}-${cn}`;
            systems[sysKey] = [pn, cn];
        });
    });
    const { GiFEDisplayResults, error } = await makeGiFEReq(parts, pNames, cNames, systems);
    return { GiFEDisplayResults, error };
}

/**
 *
 * @param {*} atomsIn
 * @param {*} atomGroupsIn
 * @returns { gifeGroups: { atoms: list, atomGroup: string } }
 * prepareGiFEGroups takes 1 argument either atomsIn or atomGroupsIn, never both.
 * These objects are then used to create the gifeGroups list. This function should
 * be called prior to initiate request to establish gifeGroups.
 */
export function prepareGiFEGroups(atomsIn, atomGroupsIn) {
    const gifeGroups = []; // { atoms, atomGroup }
    if (atomsIn && atomGroupsIn) {
        throw new Error('getGifeEnergies: cannot pass both atoms and atomGroups');
    } else if (atomsIn) {
        const atoms = ensureArray(atomsIn);
        const {
            primaryGroups, // Whole atom groups that may contain whole subgroups
            extraAtomsMap, // Map for atoms not in any whole group
        } = App.Workspace.partitionAtomsIntoGroups(atoms);
        // For Gife, we'll send:
        // Primary groups with all their atoms
        // Extra atom groups with their atoms
        // We're not dealing with "subsumed groups" which are child groups of whole primary groups
        for (const primaryGroup of primaryGroups) {
            gifeGroups.push({ atoms: primaryGroup.getAtoms(), atomGroup: primaryGroup });
        }
        for (const [atomGroup, extraAtoms] of extraAtomsMap.entries()) {
            gifeGroups.push({ atoms: extraAtoms, atomGroup });
        }
    } else if (atomGroupsIn) {
        const atomGroups = ensureArray(atomGroupsIn);
        for (const ag of atomGroups) {
            gifeGroups.push({ atoms: ag.getAtoms(), atomGroup: ag });
        }
    } else {
        throw new Error('getGifeEnergies: must pass either atoms or atomGroups');
    }
    return gifeGroups;
}

/**
 *
 * @param {*} resDict
 * @param {*} nameC
 * @param {*} nameP
 * @param {*} structure
 * @returns { result: number, warning: string, error: string }
 * getDisplayData uses the arguments given to determine if interactions
 * need to be calculated and creates a display object for each result
 * returned in the GiFE response stored in resDict.
 */
function getDisplayData(resDict, nameC, nameP, structure) {
    let error = '';
    let result = null;
    let warning = '';
    if (structure) {
        const value = resDict[structure].value;
        if (resDict[structure].error) {
            error = `Error computing structure value: ${error}`;
        } else {
            result = value;
        }
    } else {
        const cError = resDict[nameC].error;
        const pError = resDict[nameP].error;
        const sError = resDict[`${nameP}-${nameC}`].error;
        let hasError = false;
        if (cError) {
            error = `Error computing compound ${cError}`;
            hasError = true;
        } else if (pError) {
            error = `Error protein compound ${pError}`;
            hasError = true;
        } else if (sError) {
            error = `Error computing system ${sError}`;
            hasError = true;
        }
        if (hasError === false) {
            const compound = resDict[nameC].value;
            const protein = resDict[nameP].value;
            const system = resDict[`${nameP}-${nameC}`].value;
            result = system - (compound + protein);
            console.log('Equation: System Energy - (Compound Energy + Protein Energy) = Result');
            console.log(`${system} - (${compound} + ${protein}) = ${result}`);
        }
        warning = result > 150 ? 'This GiFE interaction value is unexpected. You may wish to check the chemistry geometry or contact support.' : '';
    }
    return { result, warning, error };
}

/**
 *
 * @param {*} parts
 * @param {*} pNames
 * @param {*} cNames
 * @param {*} systems
 * @returns { GiFEDisplayResults: list, error: string }
 * makeGiFEReq makes a web request using the GiFE request obj
 * and parses the display data to create the the final result
 * of the GiFE request.
 */

async function makeGiFEReq(parts, pNames, cNames, systems) {
    let error = '';
    let GiFEDisplayResults;
    const gifeReq = createGiFERequest(parts, systems);
    console.log('GiFE Req:', gifeReq);
    try {
        const url = '/services/gife/';
        const data = await WebServices.startWsRequestJson(url, gifeReq);
        const responseData = JSON.parse(data);
        if (responseData.kind === 'GiFE-response') {
            const response = responseData.results;
            console.log('Response from GiFE:', response);
            const namePairs = getCompProtPairs(cNames, pNames);
            const displayData = [];
            for (const pair of namePairs) {
                const prot = pair.length > 1 ? pair[0]: null;
                const comp = pair.length > 1 ? pair[1]: null;
                const struct = pair.length === 1 ? pair: null;
                const displayObj = getDisplayData(response, comp, prot, struct);
                const dataPoint = {
                    compound: comp || struct,
                    value: displayObj.result,
                    warning: displayObj.warning,
                    protein: prot,
                    error: displayObj.error,
                };
                displayData.push(dataPoint);
            }
            GiFEDisplayResults = displayData;
        } else {
            error = responseData.error;
        }
    } catch (errorCaught) {
        error = errorCaught;
    }
    return { GiFEDisplayResults, error };
}

function createGiFERequest(topParts, systems) {
    if (topParts.length > 1) {
        Object.entries(systems).map(([sysKey, proCompList]) => {
            const atomSet = {
                kind: 'atom-set',
                name: sysKey,
                parts: proCompList,
            };
            topParts.push(atomSet);
            return 1;
        });
    }
    const requestObj = {
        kind: 'GiFE',
        version: 1,
        parts: topParts,
    };
    return requestObj;
}

function atomsToGiFEAtomList(name, atoms, res, unboundFlag) {
    const unbound = atoms.map((atom) => atom.getPosition());
    const coords = unboundFlag ? atoms.map((atom) => atom.getPosition()) : unbound;
    const atomNames = atoms.flatMap((atom) => atom.atom);

    const list = {
        kind: 'atom-list',
        name,
        coords,
        unbound,
        // GiFE API accepts elements or atom names but key must be elements
        elements: atomNames, // (may want to use both in the future)
        // residues: res,
    };
    return list;
}

function addToProtein(proteinName, atoms, parts, res) {
    const coords = atoms.map((atom) => atom.getPosition());
    const atomNames = atoms.flatMap((atom) => atom.atom);
    const newList = parts.map((list) => {
        if (list.name === proteinName) {
            // list.residues = res;
            list.coords = list.coords.concat(coords);
            // GiFE API accepts elements or atom names but key must be elements
            list.elements = list.elements.concat(atomNames);
        }
        return list;
    });
    return newList;
}

function addToCompound(compoundName, atoms, parts) {
    const coords = atoms.map((atom) => atom.getPosition());
    const atomNames = atoms.flatMap((atom) => atom.atom);
    const newList = parts.map((list) => {
        if (list.name === compoundName) {
            list.coords = list.coords.concat(coords);
            // GiFE API accepts elements or atom names but key must be elements
            list.elements = list.elements.concat(atomNames);
        }
        return list;
    });
    return newList;
}

function getCompProtPairs(cNames, pNames) {
    const names = [];
    if (cNames.length === 0 || pNames.length === 0) {
        const nList = cNames.length > 0 ? cNames : pNames;
        nList.forEach((n) => { names.push([n]); });
    } else {
        cNames.forEach((nameC) => {
            pNames.forEach((nameP) => {
                names.push([nameP, nameC]);
            });
        });
    }
    return names;
}

function isProteinCompoundCase(gifeGroups) {
    let hasCompound = false;
    let hasProtein = false;
    for (const { atoms, atomGroup } of gifeGroups) {
        const isCompound = atoms.filter((atom) => App.Workspace.isCompoundAtom(atom)).length > 0;
        const isProtein = atoms.filter((atom) => App.Workspace.isProteinAtom(atom)).length > 0;
        hasCompound = !!isCompound;
        hasProtein = !!isProtein;
    }
    if (hasCompound === true && hasProtein === true) {
        return true;
    } else {
        return false;
    }
}

function getUnboundCompound(atoms) {
    return atoms;
}
