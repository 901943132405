import { useCallback, useState } from 'react';
import { App } from '../../../BMapsApp';
import { getDockingReferences } from '../../../data_tools';
import { dockingStates } from './constants';
import QueryContent from './QueryContent';
import ResultContent from './ResultContent';

import { SidePanelContainer } from '../../info_display/SidePanel';
import useEventBrokerSubscription from '../../common/helpers/useEventBrokerSubscription';

const initialState = {
    requestedCompounds: [],
    dockingReferences: [],
    dockState: dockingStates.READY,
    dockResult: null,
};

export default function DockingPane({ open, setOpen, handleClose }) {
    const [requestedCompounds, setRequestedCompounds] = useState(initialState.requestedCompounds);
    const [dockingReferences, setDockingReferences] = useState(initialState.dockingReferences);
    const [dockState, setDockState] = useState(initialState.dockState);
    const [dockResult, setDockResult] = useState(initialState.dockResult);

    const handleZap = useCallback(() => {
        setRequestedCompounds(initialState.requestedCompounds);
        setDockingReferences(initialState.dockingReferences);
        setDockState(initialState.dockState);
        setDockResult(initialState.dockResult);
    }, []);

    const handleAskDock = useCallback((_, { compounds }) => {
        const refs = getDockingReferences(App.Workspace);

        setOpen(true);

        if (dockState !== dockingStates.IN_PROGRESS) {
            setDockState(dockingStates.READY);
            setRequestedCompounds(compounds);
            setDockingReferences(refs);
            setDockResult(null);
        }
    }, [dockState, setOpen]);

    const handleProteinsChanged = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    function preDock() {
        setDockState(dockingStates.IN_PROGRESS);
    }

    function postDock(result) {
        setOpen(true);
        setDockState(dockingStates.COMPLETE);
        setDockResult(result);
    }

    useEventBrokerSubscription('askDock', handleAskDock);
    useEventBrokerSubscription('zapAll', handleZap);
    useEventBrokerSubscription('proteinsChanged', handleProteinsChanged);

    return (
        <SidePanelContainer title="Docking" open={open} handleClose={handleClose} width="25rem">
            {dockState === dockingStates.READY
                ? (
                    <QueryContent
                        handleClose={handleClose}
                        preDock={preDock}
                        postDock={postDock}
                        dockingReferences={dockingReferences}
                        requestedCompounds={requestedCompounds}
                    />
                ) : (
                    <ResultContent
                        result={dockResult}
                        dockState={dockState}
                        handleClose={handleClose}
                    />
                )}
        </SidePanelContainer>
    );
}
