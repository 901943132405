import { Typography, TextField as MuiTextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useState } from 'react';
import {
    Formik, Form, Field,
} from 'formik';
import styled from 'styled-components';
import FormikIconSubmit from './common/FormikIconSubmit';
import { UserActions } from '../cmds/UserAction';
import {
    useEventBrokerStateHandling, useCloseOnEscape,
} from './common/helpers/useEventBrokerSubscription';
import { withColorSchemeInfoStyled, useColorSchemeInfo } from '../redux/prefs/access';

const StyledTextField = withColorSchemeInfoStyled(styled(MuiTextField)`
  ${({ $colorSchemeInfo: { textCss: color } }) => `
        & label {
            color: ${color};
            &.Mui-focused {
                color: ${color};
            }
        }
        & input {
            color: ${color};
        }
        & .MuiInput-underline:after {
            border-bottom-color: ${color};
        }
        & .MuiOutlinedInput-root {
            & fieldset {
                border-color: ${color};
            }
            &:hover fieldset {
                border-color: ${color};
            }
            &.Mui-focused fieldset {
                border-color: ${color};
            }
        }
    `}
`);

export default function SelectBox() {
    const [open, setOpen] = useState(false);

    const getNewOpen = useCallback(
        (evtArg, oldOpen) => evtArg === true || (evtArg === 'toggle' && !oldOpen),
        []
    );
    useEventBrokerStateHandling('openSelectBox', open, setOpen, getNewOpen);
    useCloseOnEscape(setOpen);
    const { css, textCss, controlAlphaCss } = useColorSchemeInfo();

    const handleSubmit = useCallback(({ query }) => {
        if (query) {
            UserActions.Select(query);
            setOpen(false);
        }
        // Else?
        // What to do on empty input? This matches existing behavior (send nothing, keep box open)
        // Other options: send "select none" or close box without sending anything.
    }, []);

    const style = {
        position: 'absolute',
        right: 'var(--mol-button-group-width)',
        bottom: 0,
        backgroundColor: css + controlAlphaCss,
        paddingLeft: '0.5em',
        paddingTop: '0.5em',
        paddingBottom: 'var(--mol-button-group-border)',
    };

    return !!open && (
        <Typography component="div" style={style}>
            <Formik initialValues={{ query: '' }} onSubmit={handleSubmit}>
                <Form style={{ display: 'flex', flexDirection: 'row' }}>
                    <Field // Note: We don't need an error area, so don't use common/TextField
                        as={StyledTextField}
                        name="query"
                        label="Select atoms query"
                        autoFocus
                    />
                    <FormikIconSubmit><SearchIcon style={{ color: textCss }} /></FormikIconSubmit>
                </Form>
            </Formik>
        </Typography>
    );
}
