import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { useContext } from 'react';
import { ProteinContext } from '../../../../common/ProteinContextProvider';

export default function SelectivityControl({
    selectivity, setSelectivity, viewOnly,
    connectionWarning,
}) {
    const { loadedProteins } = useContext(ProteinContext);
    if (loadedProteins.length === 0) return (false);

    const loadOptions = [
        {
            value: 'individual', label: 'Clear and load new structure', title: 'Clear the workspace and load a new structure', available: true,
        },
        {
            value: 'selectivity', label: 'Add structure for selectivity', title: 'Add an additional structure with resources for selectivity analysis', available: !viewOnly,
        },
        {
            value: 'viewonly', label: 'Add view-only structure', title: 'Add an additional structure for visualization only', available: viewOnly,
        },
    ];

    return (
        <div>
            { connectionWarning && (
                <span style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>{connectionWarning}</span>
            )}
            <MuiRadioGroup
                onChange={(evt) => setSelectivity(evt.target.value)}
                sx={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}
            >
                {loadOptions
                    .filter((option) => option.available)
                    .map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            checked={selectivity===option.value}
                            label={option.label}
                            title={option.title}
                            control={<Radio size="small" />}
                        />
                    ))}
            </MuiRadioGroup>
        </div>
    );
}
