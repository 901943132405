import _ from 'lodash';
import TreeNode from './TreeNode';
import TreeRoot from './TreeRoot';
import { UserActions } from '../cmds/UserAction';
import { SortTypes } from '../model/DisplayState';
import { FragmentNodeTooltip } from './FragmentTreeTooltips';
import { FragInfo } from '../FragList';

/**
 * Presentation class to manage the fragment selector tree.
 */
export default class FragmentTree extends TreeRoot {
    static get VisibleFragInfoLimit() { return 20; }

    constructor(caseDatas, nodeStateFn, displayStateInfo) {
        const children = TreeRoot.makeRootNodesForCaseDatas(caseDatas,
            (caseData) => new CaseDataNode(caseData, displayStateInfo));
        super({
            treeName: 'Fragments',
            children,
            nodeStateFn,
        });
        this.onToggleVisible = this.onToggleVisible.bind(this);
        this.onToggleSelected = this.onToggleSelected.bind(this);
    }

    // TreeRoot overrides

    /**
     * onToggleVisibility is passed through to tree items if available,
     * otherwise default implemention in TreeRoot is used.
     */
    async onToggleVisible(info) {
        if (info.treeItem.onToggleVisible) {
            await info.treeItem.onToggleVisible(info);
        } else {
            super.onToggleVisible(info);
        }
    }

    /**
     * onToggleSelected is passed through to tree items
     */
    async onToggleSelected(info) {
        if (info.treeItem.onToggleSelected) {
            await info.treeItem.onToggleSelected(info);
        }
    }

    // Fragments use the "active" property to indicate whether
    // they are included for search.  This is controlled by
    // FragmentManager, not by the selector
    // onToggleActive={ ({fragmentInfo}) => {
    //     UserActions.ToggleActiveFragment(fragmentInfo);
    // }}

    getExtraData(pxNodes) {
        const selectFn = ({ item, isSelected }) => item instanceof FragInfo && isSelected;
        const selectedNode = TreeNode.selectNodes(pxNodes, selectFn)[0];
        return {
            energyFilterInfo: {
                fragmentInfo: selectedNode?.item,
                filterValue: selectedNode?.itemState.energyFilterValue,
            },
        };
    }

    /**
     * View / Sort actions for the Fragment Tree View/Sort menu
     * @returns {{title: string, onClick: function}[]}
     */
    getViewSortActions() {
        return [
            {
                title: 'Sort by User vs Builtin Fragments',
                onClick: () => {
                    UserActions.ChangeTreeSort('Fragments', SortTypes.FragmentsTree.default);
                },
            },
            {
                title: 'Sort by User Fragment Set',
                onClick: () => {
                    UserActions.ChangeTreeSort('Fragments', SortTypes.FragmentsTree.fragset);
                },
            },
            {
                title: 'Sort by Fragment Library',
                onClick: () => {
                    UserActions.ChangeTreeSort('Fragments', SortTypes.FragmentsTree.library);
                },
            },
            { divider: true },
            this.hideAllFragmentsAction(),
            { divider: true },
            ...this.expandCollapseViewSortActions(),
            { divider: true },
            {
                title: 'Manage fragments',
                onClick: () => UserActions.OpenFragmentManager(),
            },
        ];
    }

    // Bookkeeping
    okToMakeVisible(treeItem) {
        const { allShownFrags: frags } = this.getVisibleFragments();
        console.log(`${frags.length} / max ${FragmentTree.VisibleFragInfoLimit} fragments are visible`);
        return frags.includes(treeItem.item) || frags.length < FragmentTree.VisibleFragInfoLimit;
    }

    /**
     * Return an object with info about visible and selected fragments.
     * Note: Fragment visibility is keyed by frag object; Selection is keyed by tree node.
     * This means that if you have fragments in multiple groups, changing the
     * visibility of one instance changes them all.  But selecting one instance
     * only selects the one.
     * @returns {{selectedNodes: TreeNode[], selectedFrags: FragInfo[], visibleFrags: FragInfo[]}}
     */
    getVisibleFragments() {
        const visibleFrags = new Set();
        const selectedFrags = new Set();
        const selectedNodes = [];

        const nodes = this.selectNodes((node) => node instanceof FragmentItemNode);
        for (const node of nodes) {
            const { isVisible, isSelected } = node.getNodeState();
            if (isVisible) visibleFrags.add(node.item);
            if (isSelected) {
                selectedFrags.add(node.item);
                selectedNodes.push(node);
            }
        }

        return {
            selectedNodes,
            selectedFrags: [...selectedFrags],
            visibleFrags: [...visibleFrags],
            allShownFrags: _.uniq([...visibleFrags, ...selectedFrags]),
        };
    }

    // Convenience methods for menu and view/sort actions

    hideAllFragmentsAction() {
        return {
            title: 'Hide all fragments',
            onClick: () => {
                const { visibleFrags, selectedNodes } = this.getVisibleFragments();

                UserActions.ToggleVisibility(visibleFrags, false);
                UserActions.ToggleSelection(selectedNodes, false);
            },
        };
    }
}

/**
 * TreeNode class to group fragment data for a particular case
 * Can be organized in three ways: by user/built-in simulation, by fragset, or by frag. library.
 */
class CaseDataNode extends TreeNode {
    constructor(caseData, displayStateInfo) {
        super({
            label: caseData.getName(),
            children: CaseDataNode.getGroupNodes(caseData, displayStateInfo),
        });
    }

    static getGroupNodes(caseData, displayStateInfo) {
        const groups = [];

        // Add node for computed waters if available
        if (caseData.hasComputedWaters()) {
            groups.push(new ComputedWaterNode(caseData));
        }

        if (displayStateInfo.sortType === SortTypes.FragmentsTree.default) {
            groups.push(...CaseDataNode.getDefaultGroupNodes(caseData, displayStateInfo));
        } else {
            groups.push(...CaseDataNode.getLibraryGroupNodes(caseData, displayStateInfo));
        }
        return groups;
    }

    /**
     * Return fragment group nodes, grouping by User-requested or Built-in simulation
     */
    static getDefaultGroupNodes(caseData, { standardNames }) {
        const user = [];
        const standard = [];
        const bonus = [];
        const other = [];

        for (const frag of caseData.getAvailableFragmentInfo().items()) {
            if (frag.hasUserSeries) {
                user.push(frag);
            }

            if (standardNames) {
                if (standardNames.includes(frag.name)) {
                    standard.push(frag);
                } else if (frag.hasBuiltinSeries) {
                    bonus.push(frag);
                }
            } else {
                if (frag.hasBuiltinSeries) {
                    other.push(frag);
                }
            }
        }

        const bonusGroupName = `Bonus Fragments for ${caseData.getShortName() || 'this structure'}`;
        const groupInfo = [
            {
                label: 'User Fragments',
                info: 'Fragments that you have run on this structure.',
                fragments: user,
            },
            {
                label: 'Standard BMaps Fragments',
                info: 'This set of 117 fragments is simulated on all prepared BMaps structures.',
                fragments: standard,
            },
            {
                label: bonusGroupName,
                info: `${bonus.length} extra fragments (beyond the Standard BMaps Fragments) included with this structure.`,
                fragments: bonus,
            },
            {
                label: 'Built-in Fragments',
                info: `${other.length} fragments included with this structure.`,
                fragments: other,
            },
        ];
        const ret = [];
        for (const { label, info, fragments } of groupInfo) {
            if (fragments.length > 0) {
                ret.push(new FragmentGroupNode(label, info, fragments));
            }
        }
        return ret;
    }

    /**
     * Return group nodes grouping by either fragment set or fragment library
     * @param {CaseData} caseData
     * @param {{sortType: string, fragservData: FragservData}} param1
     * @returns {FragmentGroupNode[]}
     */
    static getLibraryGroupNodes(caseData, { sortType, fragservData }) {
        const groups = new Map();
        const fragsets = fragservData.fragsets;
        const fragList = caseData.getAvailableFragmentInfo();

        // Helper functions to add fragments to group nodes
        const addFragToLibraryGroup = (frag, library) => {
            const key = library.libraryId;
            const group = groups.get(key);
            if (group) {
                group.addFragment(frag);
            } else {
                groups.set(key, new FragmentGroupNode(
                    library.shortName,
                    `Library description: ${library.description}`,
                    [frag]
                ));
            }
        };
        const addFragToFragsetGroup = (frag, fragSet) => {
            const key = fragSet.fragsetId;
            const group = groups.get(key);
            if (group) {
                group.addFragment(frag);
            } else {
                groups.set(key, new FragmentGroupNode(
                    fragSet.name,
                    `User-created fragment set with ${fragSet.items.length} fragments`,
                    [frag],
                ));
            }
        };
        const addFragToUncategorizedGroup = (frag, label, info) => {
            const key = 'uncategorized_frag_group';
            const group = groups.get(key);
            if (group) {
                group.addFragment(frag);
            } else {
                groups.set(key, new FragmentGroupNode(label, info, [frag]));
            }
        };

        // Add fragment set fragments to groups based on fragset or library
        for (const fragSet of fragsets) {
            for (const fsItem of fragSet.items) {
                const frag = fragList.findByName(fsItem.name);
                if (frag) {
                    if (sortType === 'fragset') {
                        addFragToFragsetGroup(frag, fragSet);
                    } else if (sortType === 'library') {
                        addFragToLibraryGroup(frag, fsItem.library);
                    }
                }
            }
        }

        // Add fragments not in fragment sets
        const fragNamesInFragSets = fragsets.reduce(
            (names, next) => names.concat(next.items.map((i) => i.name)),
            []
        );
        const otherFrags = fragservData.otherFrags;
        for (const frag of fragList.items()) {
            if (fragNamesInFragSets.includes(frag.name)) continue;
            if (sortType === 'fragset') {
                addFragToUncategorizedGroup(frag,
                    'Not in a Fragment Set',
                    'Simulated fragments that are not in any user-defined fragment sets');
            } else {
                // May add to multiple libraries
                const otherFragInfos = otherFrags.filter((x) => x.name === frag.name);
                if (otherFragInfos.length > 0) {
                    for (const otherFragInfo of otherFragInfos) {
                        addFragToLibraryGroup(frag, otherFragInfo.library);
                    }
                } else {
                    addFragToUncategorizedGroup(frag,
                        'Not in a Fragment Library',
                        'Simulated fragments that are not in a known fragment library');
                }
            }
        }

        return [...groups.values()];
    }
}

/**
 * Node for an intermediate group in the fragments tree
 */
class FragmentGroupNode extends TreeNode {
    constructor(label, info, fragments) {
        super({
            label,
            info,
            children: fragments.map((f) => new FragmentItemNode(f)),
        });
    }

    addFragment(frag) {
        if (!this.children.find((fragNode) => fragNode.label === frag.name)) {
            this.children.push(new FragmentItemNode(frag));
        }
    }

    /** @override */
    getActions() {
        return [
            this.treeRoot.hideAllFragmentsAction(),
            {
                title: 'Hide fragments not in this group',
                onClick: ({ treeItem }) => {
                    const { visibleFrags, selectedNodes } = this.treeRoot.getVisibleFragments();
                    const saved = TreeNode.selectNodes(treeItem.children, TreeNode.hasItem);
                    const savedFrags = saved.map(_.property('item'));
                    const savedNodes = saved.map(_.property('treeItem'));
                    UserActions.ToggleVisibility(
                        visibleFrags.filter((x) => !savedFrags.includes(x)), false
                    );
                    UserActions.ToggleSelection(
                        selectedNodes.filter((x) => !savedNodes.includes(x)), false
                    );
                },
            },
        ];
    }

    getTooltip(column, { isVisible }) {
        switch (column) {
            case 'isVisible':
                return isVisible
                    ? 'Don\'t keep this group\'s fragment map summaries in the 3D workspace'
                    : `Keep this group's fragment map summaries (up to ${FragmentTree.VisibleFragInfoLimit}) in the 3D workspace`;
            default:
                return undefined;
        }
    }
}

/**
 * Tree Node for an individual (available) fragment type
 */
class FragmentItemNode extends TreeNode {
    constructor(fragment) {
        super({
            label: fragment.name,
            item: fragment,
            info: FragmentNodeTooltip({ fragment }),
        });
    }

    getActions() {
        return [
            this.treeRoot.hideAllFragmentsAction(),
            {
                title: 'Hide all but this fragment',
                onClick: ({ treeItem }) => {
                    const { visibleFrags, selectedNodes } = this.treeRoot.getVisibleFragments();
                    UserActions.ToggleVisibility(
                        visibleFrags.filter((x) => x !== treeItem.item), false
                    );
                    UserActions.ToggleSelection(
                        selectedNodes.filter((x) => x !== treeItem), false
                    );
                },
            },
        ];
    }

    getTooltip(column, { isVisible, isSelected }) {
        switch (column) {
            case 'isVisible':
                return isVisible
                    ? 'Don\'t keep this this fragment map summary in the 3D workspace'
                    : 'Keep this fragment map summary in the 3D workspace';
            case 'name': {
                const selCount = () => this.treeRoot.getVisibleFragments().selectedNodes.length;
                // The tooltip shows "deselect" only if the item is the only selected fragment.
                return (isSelected && selCount() === 1)
                    ? 'Click to deselect and hide the energy filter for this fragment map summary'
                    : 'Click to select and show the energy filter for this fragment map summary';
            }
            default:
                return undefined;
        }
    }

    /**
     * Fragment map summary fragments are shown in 3D when either "visible" or "selected."
     * When toggling the visible status, we need to request a fragment map if it wasn't previously
     * shown, or release the map (remove the atoms) if it is no longer shown.
     */
    async onToggleVisible({
        treeItem, item: fragmentInfo, isVisible, isSelected,
    }) {
        const aboutToShow = !isVisible;
        if (aboutToShow && !this.treeRoot.okToMakeVisible(treeItem)) return;

        UserActions.ToggleVisibility(fragmentInfo);

        if (aboutToShow) {
            await UserActions.GetFragmentMap(fragmentInfo);
        } else if (!isSelected) {
            UserActions.ReleaseFragmentMap(fragmentInfo);
        }
    }

    // TODO: Consider bundling the toggle logic into new actions
    // like UserActions.ToggleFragmentVisibility(whichPropertyToToggle)
    // which would fetch / release and toggle as appropriate.
    /**
     * Fragment map summary fragments are shown in 3D when either "visible" or "selected."
     * When toggling the selected status, we need to request a fragment map if it wasn't previously
     * shown, or release the map (remove the atoms) if it is no longer shown.
     */
    async onToggleSelected({
        treeItem, item: fragmentInfo, isVisible, isSelected,
    }) {
        const aboutToSelect = !isSelected;
        if (aboutToSelect && !this.treeRoot.okToMakeVisible(treeItem)) return;

        UserActions.ToggleSelection(treeItem);

        if (aboutToSelect) {
            await UserActions.GetFragmentMap(fragmentInfo);
        } else if (!isVisible) {
            UserActions.ReleaseFragmentMap(fragmentInfo);
        }
    }
}

/**
 * Tree Node for Computed Waters
 * Since this one node controls the visibility for many atom groups,
 * this uses the computedWaterCollection on the caseData for the "item"
 */
class ComputedWaterNode extends TreeNode {
    constructor(caseData) {
        super({ label: 'Water', item: caseData.getComputedWaterCollection() });
    }
}
