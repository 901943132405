// TopBar.jsx
import { useState } from 'react';

import { App } from '../BMapsApp';
import { SaveStatePanel } from './SaveStatePanel';
import { TheManual } from './manual/TheManual';
import useEventBrokerSubscription from './common/helpers/useEventBrokerSubscription';
import { loginUrlForMapCase } from '../connection_utils';

export default function TopBar() {
    const [loginUrl, setLoginUrl] = useState('/login');
    const [showLogin, setShowLogin] = useState(false);

    useEventBrokerSubscription('proteinLoaded', (_, { mapCase }) => {
        setLoginUrl(loginUrlForMapCase(mapCase));
    });
    useEventBrokerSubscription('connectionStatusChanged', (_, { connector, mode }) => {
        if (connector === App.PrimaryDataConnection.getConnector()) {
            if (mode === 'static') {
                setShowLogin(true);
            } else {
                setShowLogin(false);
            }
        }
    });
    return (
        <>
            <div id="topbar" className="topbar">
                <div id="topbar-left-side">
                    <div id="topbar-logo-container">
                        <a id="topbar-logo-anchor" href="/" target="_blank">
                            <button id="topbar-logo-button" type="button">
                                <img src="images/BLogo.png" alt="BMaps Logo" />
                            </button>
                        </a>
                    </div>
                    <div id="topbar-title-container">
                        <a id="topbar-title-link" href="/" target="_blank"> Boltzmann Maps </a>
                        <span id="topbar-title-text">  </span>
                    </div>
                </div>
                <div id="topbar-right-side">
                    {showLogin && (
                        <a href={loginUrl}>
                            <button
                                type="button"
                                className="topbar-button topbar-button-with-label"
                                title="Log in or Sign up"
                            >
                                <i className="fa fa-sign-in" />
                                <div className="topbar-button-label">login</div>
                            </button>
                        </a>
                    )}
                    <button
                        id="topbar-help-button"
                        type="button"
                        className="topbar-button topbar-button-with-label"
                        title="View cheatsheet"
                        onClick={() => TheManual.Toggle()}
                    >
                        <i className="fa fa-map" />
                        <div className="topbar-button-label">guide</div>
                    </button>
                    <button
                        type="button"
                        className="topbar-button topbar-button-with-label"
                        title="Session storage"
                        onClick={() => SaveStatePanel.Open()}
                    >
                        <i className="fa fa-download" />
                        <div className="topbar-button-label">save</div>
                    </button>
                    <button
                        id="topbar-menu-button"
                        type="button"
                        className="topbar-button"
                        title="Main menu"
                    >
                        <i className="fa fa-bars" />
                    </button>
                </div>
            </div>
            <ul id="topbar-menu-list" />
        </>
    );
}
