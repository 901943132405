import React from 'react';
import { Link } from '@mui/material';
import styled from 'styled-components';

/**
 * Component to display a button styled like a link.
 * props: { onClick, children, style }
 *
 * This can be styled with either a styled prop or by wrapping with `styled`
 */
const LinkLikeButton = styled(
    ({
        children, onClick, className, style, title,
    }) => (
        <Link /* eslint-disable-line jsx-a11y/anchor-is-valid */
            component="button"
            className={className}
            onClick={onClick}
            underline="none"
            style={style}
            title={title}
        >
            {children}
        </Link>
    )
)`
color: blue;
padding: 0.5em;
`;

export default LinkLikeButton;
