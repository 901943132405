import { App } from '../BMapsApp';
import { EventBroker } from '../eventbroker';
import { ResponseIds } from '../server/server_common';
import { UserCmd, UserActions } from './UserCmd';
import { SimSpec } from '../model/SimSpec';
import { integrateFragments } from './cmds_common';
import { isHydrogen } from '../util/chem_utils';

export const FragmentsCmds = {
    GetFragmentMap: new UserCmd('GetFragmentMap', getFragmentMap),
    ReleaseFragmentMap: new UserCmd('ReleaseFragmentMap', releaseFragmentMap),
    PrepareProjectCase: new UserCmd('PrepareProjectCase', prepareProjectCase),
    RunProjectCase: new UserCmd('RunProjectCase', runProjectCase),

    RefreshAllFragments: new UserCmd('RefreshAllFragments', refreshAllFragments),
    RefreshAvailableFragments: new UserCmd('RefreshAvailableFragments', refreshAvailableFragments),
    RefreshFragmentInfo: new UserCmd('RefreshFragmentInfo', refreshFragmentInfo),
    LoadFragments: new UserCmd('LoadFragments', loadFragments),
    LoadFragmentSeries: new UserCmd('LoadFragmentSeries', loadFragmentSeries),
    ToggleActiveFragment: new UserCmd('ToggleActiveFragment', toggleActiveFragment),
    ToggleClusteringFragment: new UserCmd('ToggleClusteringFragment', toggleClusteringFragment),
    MakeClusterMap: new UserCmd('MakeClusterMap', makeClusterMap),
};

async function getFragmentMap(fragmentInfo) {
    const loaded = fragmentInfo.fragmentObjects;

    if (loaded.length === 0) {
        const { connector, caseData } = App.getDataParents(fragmentInfo);
        const requestObj = {
            name: fragmentInfo.name,
            series_list: fragmentInfo.series_list.map((s) => s.getSummaryRequest()),
        };
        const responseData = await connector.cmdGetFragmentMap(requestObj);

        if (!responseData.hasError()) {
            const createdAtomGroups = integrateFragments(responseData, caseData, true);
            const fragmentObjs = createdAtomGroups.fragmentmap;
            fragmentInfo.updateFrags(fragmentObjs);
            console.log(`Loaded ${fragmentObjs.length} fragments for ${fragmentInfo.name}`);
            EventBroker.publish('redisplayFragments');
        } else {
            console.warn(`Failed to load fragments for ${fragmentInfo.name}`);
        }
    }
}

function releaseFragmentMap(fragmentInfo) {
    const frags = fragmentInfo.fragmentObjects;
    App.Workspace.removeFragments(frags);
    frags.splice(0, frags.length); // clear fragment objects in the fragmentInfo
}

/**
 * @description Indicate if we are ready to run the provided simulation case,
 * creating the project case files if necessary.  This calls validate-project-case
 * and write-project-case server APIs.
 * @param {SimSpec} simSpec - A description of the simulation case
 * @returns {Object}
 * {
 *   status: Are we Ready or not? (from SimSpec.Status)
 *   case_suggestion: A suggestion in case of a conflict with the user's preferred case name,
 *   errors: Any errors that might have occurred
 * }
 */
async function prepareProjectCase({ simSpec, useSelection, dontDeselect }={}) {
    const ret = {
        status: null,
        case_suggestion: '',
        errors: [],
        errorInfo: {},
    };

    const { connector, permissionManager } = App.getDataParents(simSpec.protein);
    // If using selection, residue count check and hydrogen check are skipped
    if (!useSelection) {
        // Check residue count
        if (!permissionManager.isAllowed('unlimited_chains')) {
            const chains = [...simSpec.proteinChains, ...simSpec.polymers];
            const chainLimit = 500;
            const residueCount = chains.reduce((acc, next) => acc+next.getResidues().length, 0);
            if (residueCount > chainLimit) {
                ret.status = SimSpec.Status.TooManyChainsError;
                return ret;
            }
        }

        const hasHydrogens = simSpec.proteinChains.some(
            (chain) => chain.atoms.some((atom) => isHydrogen(atom))
        );
        if (!hasHydrogens) {
            ret.status = SimSpec.Status.NoHydrogensError;
            return ret;
        }

        // Both validate-project-case and write-project-case require atoms to be selected.
        await UserActions.Select(simSpec.selectQuery, connector);
    }
    // Find out if we need to write new case files or if we can use existing files
    let responseData = await connector.cmdValidateProjectCase(simSpec.forServer);
    const [statusObj] = responseData.byId(ResponseIds.ProjectCaseStatus);
    let errors = [...responseData.errors];

    switch (statusObj && statusObj.status) {
        case SimSpec.CaseStatus.NEW:
            // New case: need to write the files
            responseData = await connector.cmdWriteProjectCase(simSpec.forServer);
            errors = [...errors, ...responseData.errors]; // Handled below
            ret.status = SimSpec.Status.Ready;
            break;
        case SimSpec.CaseStatus.EXISTS_OK:
            // Can use the existing files
            ret.status = SimSpec.Status.Ready;
            break;
        case SimSpec.CaseStatus.EXISTS_CONFLICT:
            // Conflict: user needs to specify a new case name
            ret.status = SimSpec.Status.Conflict;
            ret.case_suggestion = statusObj.suggestion;
            break;
        // no default: Errors handled below
    }
    // Only deselect atoms if not using a selection
    if (!useSelection && !dontDeselect) {
        await UserActions.Select('none', connector);
    }
    // Errors returned by either validate-project-case or write-project-case
    // will prevent the simulation from going forward.
    if (errors.length > 0) {
        ret.errors = errors;
        ret.status = SimSpec.Status.UnknownError;
        const bindingSiteErr = 'Binding site specs could not be parsed:';
        if (errors.find((e) => e.match(/out-dated (personal )?(storage )?data (re)?source/))) {
            ret.status = SimSpec.Status.StorageUpdatedError;
        } else if (errors.find((e) => e.match(/personal (storage )?data (re)?source/))) {
            ret.status = SimSpec.Status.PersonalStorageError;
        } else if (errors.find((e) => e.match(bindingSiteErr))) {
            let msg = 'Unspecified error';
            const err = errors.find((e) => e.match(bindingSiteErr));
            let lookupText = 'ParseBindingSites: ';
            let index = err.indexOf(lookupText);
            if (index > -1) {
                msg = err.substring(index + lookupText.length);
            } else {
                // Just show the whole error after the spec
                lookupText = `${bindingSiteErr}\n${simSpec.bindingSiteSpecs}`;
                index = err.indexOf(lookupText);
                msg = err.substring(index + lookupText.length);
            }
            ret.errorInfo = { bindingSiteSpecErr: msg };
            ret.status = SimSpec.Status.BindingSiteSpecError;
        } else {
            // Find residues where fdb file could not be written
            const resFdbFail = errors.map((e) => {
                const regex = /failed to write fdb file for (.+): TCL error/m;
                const regexResult = regex.exec(e);
                return regexResult && regexResult[1]; // map to null or the capture group
            }).filter((x) => x != null);
            // Find residues which have no partial charges
            const resNoCharges = errors.map((e) => {
                const regex = /Atom group (.+) does not have partial charges/m;
                const regexResult = regex.exec(e);
                return regexResult && regexResult[1]; // map to null or the capture group
            }).filter((x) => x != null);
            // 'failed to write fdb file' trumps 'does not have partial charges'
            if (resFdbFail.length > 0) {
                ret.errorInfo = { failedResidues: resFdbFail };
                ret.status = SimSpec.Status.FdbFileError;
            } else if (resNoCharges.length > 0) {
                ret.errorInfo = { failedResidues: resNoCharges };
                ret.status = SimSpec.Status.PartialChargesError;
            }
        }
    }

    return ret;
}

/**
 * @description Run the provided simulation case. This assumes PrepareProjectCase
 * has already been called. This calls run-project-case server API.
 * @param {SimSpec} simSpec - A description of the simulation case
 * @returns {Object}
 * {
 *   errors: Any errors that might have occurred
 * }
 */
async function runProjectCase(simSpec, notifyUrl) {
    const { connector } = App.getDataParents(simSpec.protein);
    const serverArgs = { ...simSpec.forServer, 'notification-url': notifyUrl };
    const responseData = await connector.cmdRunProjectCase(serverArgs);
    const errors = responseData.errors;
    return { errors };
}

function refreshAllFragments() {
    for (const dataConnection of App.DataConnections) {
        dataConnection.getConnector().cmdRefreshFragments();
    }
}

async function refreshAvailableFragments(mapCase) {
    // First hide all visible fragments, redisplaying after the data refresh
    const visibleFragmentInfo = App.Workspace.getVisibleFragmentInfo();
    for (const fragInfo of visibleFragmentInfo) {
        releaseFragmentMap(fragInfo);
    }

    const { connector } = App.getDataParents(mapCase);
    const responseData = await connector.cmdGetAvailableFragments(mapCase);
    const [listObj] = responseData.byId(ResponseIds.AvailableFragments);
    App.Workspace.setAvailableFragments(mapCase, listObj);
    EventBroker.publish('dataLoaded');
    // Actually processing the data is handled by project_data doReceiveAvailableFragments();
    if (responseData.hasError()) {
        console.warn(`Errors refreshing available fragments: ${responseData.errors.join('; ')}`);
    }

    for (const fragInfo of visibleFragmentInfo) {
        await getFragmentMap(fragInfo);
    }

    EventBroker.publish('redisplayFragments');
}

async function refreshFragmentInfo(mapCase, needToLoadFrags) {
    const fragmentData = App.Workspace.fragmentData;
    await App.Workspace.refreshFragservInfo();
    await refreshAvailableFragments(mapCase);
    const availableFragInfo = App.Workspace.getAvailableFragmentInfo(mapCase);
    const defaultClusteringFrags = fragmentData.getAvailableClusteringFragments(availableFragInfo);
    if (App.Workspace.getHotspots(mapCase).length === 0 && defaultClusteringFrags.length > 0) {
        // await UserActions.ToggleClusteringFragment(defaultClusteringFrags, true);
    }

    if (needToLoadFrags) {
        await loadDefaultFragments(mapCase);
    }
}

async function loadDefaultFragments(mapCase) {
    const nFragsToLoad = 200;

    const fragList = App.Workspace.getAvailableFragmentInfo(mapCase).items();
    const fragservData = App.Workspace.fragmentData.fragservData;

    const standardFragsAvailable = fragservData.getStandardFragset();
    // User fragments take priority, then lower b-values
    fragList.sort((a, b) => {
        if (a.hasUserSeries !== b.hasUserSeries
                && (a.hasUserSeries || b.hasUserSeries)) {
            return a.hasUserSeries ? -1 : 1;
        } else {
            if (!standardFragsAvailable) return 0;

            const isStandard = (f) => fragservData.isStandardFrag(f.name);
            const aIsStandard = isStandard(a);
            const bIsStandard = isStandard(b);
            if (aIsStandard !== bIsStandard) {
                return aIsStandard ? -1 : 1;
            } else {
                return 0;
            }
        }
    });

    const activeArg = fragList.map((fragItem, i) => ({
        item: fragItem,
        forceValue: i < nFragsToLoad,
    }));
    // Don't send UI updates for this active toggling (the final false does this)
    App.Workspace.toggleActive(activeArg, undefined, false);
    return loadFragments(fragList.slice(0, nFragsToLoad));
}

/**
 * @param {*} fragmentList list of FragInfo
 */
async function loadFragments(fragmentList) {
    const series = fragmentList.reduce(
        (list, frag) => list.concat(frag.series_list),
        [],
    );
    return loadFragmentSeries(series);
}

/**
 * @param {*} fragSeriesList list of FragSeriesInfo
 */
async function loadFragmentSeries(fragSeriesList) {
    const { mapCase, connector } = App.getDataParents(fragSeriesList[0]);
    if (!mapCase) return null;

    const requestObj = {
        project: mapCase.project,
        case: mapCase.case,
        fragsList: fragSeriesList.map((s) => s.getLoadRequest()),
    };

    const responseData = connector.cmdLoadFragments(requestObj);
    return responseData;
}

function toggleActiveFragment(fragment, forceValue) {
    App.Workspace.toggleActive(fragment, forceValue);
    const { dataParentsList } = App.partitionByDataParents([].concat(fragment)); // force to array
    for (const { caseData } of dataParentsList) {
        loadFragments(App.Workspace.getActiveFragments(caseData));
    }
}

function toggleClusteringFragment(fragInfo, forceValue) {
    App.Workspace.toggleProp(fragInfo, 'usedForHotspots', forceValue);

    const { dataParentsList } = App.partitionByDataParents([].concat(fragInfo)); // force to array
    for (const { caseData, connector } of dataParentsList) {
        makeClusterMap(App.Workspace.getClusteringFragments(caseData), { connector, caseData });
    }
}

async function makeClusterMap(hotspotFragmentsIn, { connector, caseData }) {
    const hotspotFragments = [].concat(hotspotFragmentsIn); // force array

    const mapCase = caseData.mapCase;
    if (!mapCase) return null;

    const series = hotspotFragments.reduce(
        (list, frag) => list.concat(frag.series_list),
        [],
    );
    const requestObj = {
        project: mapCase.project,
        case: mapCase.case,
        diversity: 9,
        fragsList: series.map((s) => s.getLoadRequest()),
    };

    const responseData = await connector.cmdMakeClusterMap(requestObj);
    integrateFragments(responseData, caseData);
    return responseData;
}
