import styled from 'styled-components';
// Material UI
import Button from '@mui/material/Button';

const DialogActionButton = styled(Button)`
  min-width: 5rem;
`;

const DialogSubmitButton = styled(DialogActionButton).attrs(() => ({
    type: 'submit',
}))`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};

    &:hover {
      background-color: ${theme.palette.primary.main};
    }
  `}
`;

const DialogCancelButton = styled(DialogActionButton)`
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.primary.contrastText};
    border: solid 1px ${theme.palette.primary.main};

    &:hover {
      background-color: ${theme.palette.primary.contrastText};
    }
  `}
`;

export { DialogSubmitButton, DialogCancelButton };
