import React from 'react';
// Material UI
import LinearProgress from '@mui/material/LinearProgress';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
// Local
import { DialogCancelButton } from '../../common/DialogActionButtons';
import DialogContent from '../../common/DialogContent';
import DialogContentSection from '../../common/DialogContentSection';
import { dockingStates } from './constants';
import { reportClashingCompounds } from '../../../cmds/ConnectedDataCmds';

export default function ResultContent({ result, dockState, handleClose }) {
    const { compounds, errors } = result || { compounds: [], errors: [] };

    let heading;
    let content;
    if (dockState === dockingStates.IN_PROGRESS) {
        heading = 'Docking in progress...';
        content = <LinearProgress />;
    } else if (dockState === dockingStates.COMPLETE) {
        const clashingCmpds = reportClashingCompounds(compounds, {
            alertParams: {
                singularNoun: 'docked compound',
                pluralNoun: 'docked compounds',
                dontAlert: true,
            },
        });
        const havePoses = compounds.length > 0;
        const haveClashes = clashingCmpds.length > 0;
        const haveError = errors.length > 0;

        switch (true) {
            case havePoses && !haveError: heading = 'Docking success!'; break;
            case havePoses && haveError: heading = 'Docking succeeded with errors'; break;
            case !havePoses && !haveError: heading = 'No poses found'; break;
            case !havePoses && haveError: heading = 'Docking unsuccessful'; break;
            default:
                // no default; all cases handled above
        }
        content = (
            <>
                { havePoses && (
                    <Typography>
                        {`Best ${compounds.length === 1 ? 'pose' : 'poses'} saved as ${compounds.map((c) => c.resSpec).join(', ')}.`}
                    </Typography>
                )}
                { haveClashes && (
                    <Typography>
                        {`The following compounds may be clashing with the protein and need minimization: ${clashingCmpds.join(', ')}` }
                    </Typography>
                )}
                { haveError && (
                    <Typography>
                        {`Errors reported during docking: ${errors.join(' ')}` }
                    </Typography>
                )}
                { !havePoses && !haveError && (
                    <Typography>The docking program found no suitable poses.</Typography>
                )}
            </>
        );
    }

    return (
        <DialogContent>
            <DialogContentSection title="Results">
                <Typography>{heading}</Typography>
                {content}
            </DialogContentSection>
            <DialogActions>
                <DialogCancelButton onClick={handleClose}>Close</DialogCancelButton>
            </DialogActions>
        </DialogContent>
    );
}
