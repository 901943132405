import { Typography } from '@mui/material';
import { pointsCentroid } from '../util/atom_distance_utils';
import { useWithSelectedAtoms } from './common/helpers/useWithSelectedAtoms';

/**
 * Component for a status bar at the bottom of the screen.
 */
export default function StatusBar() {
    // Calculate and store desired information about selected atoms
    const selectedAtoms = useWithSelectedAtoms();
    const selectedAtomInfo = extractSelectedAtomInfo(selectedAtoms);
    const { count: selectedAtomCount, centroid } = selectedAtomInfo;
    const nAtomsStr = selectedAtomCount.toLocaleString();
    const selectedMsg = selectedAtomCount > 0
        ? `${nAtomsStr} atom${selectedAtomCount === 1 ? '' : 's'} selected`
        : '';

    // So far only the selected atom count is displayed.
    // It might be nice to one day include atom coordinates, distances, bond angles, etc.
    let msg = selectedMsg;

    if (selectedAtomCount > 1) {
        msg += `. Centroid: ${centroid.map((n) => n.toFixed(2))}`;
    }

    const style = {
        position: 'absolute',
        bottom: 0,
        right: 'var(--mol-button-group-width)',
        marginBottom: 'var(--mol-button-group-border)',
        marginRight: 'var(--mol-button-group-border)',
    };

    return (
        <Typography id="status-bar" as="div" style={style}>{msg}</Typography>
    );
}

function extractSelectedAtomInfo(selectedAtoms) {
    return {
        count: selectedAtoms.length,
        centroid: pointsCentroid(selectedAtoms),
    };
}
