import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// Material UI
import MuiBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// Local
import { useMediaQuery, useTheme } from '@mui/material';
import { EventBroker } from '../../../eventbroker';
import { showAlert } from '../../../utils';
import { App } from '../../../BMapsApp';
import ModalPane from '../../ModalPane';
import TabController from '../../common/TabController';
import exportPaneTabs from './Tabs';

export const ExportPaneContext = React.createContext({});

const Backdrop = styled(MuiBackdrop)`
  position: absolute;
  z-index: 2000;
  bottom: unset;
  right: unset;
  width: 100%;
  height: 100%;
  background-color: unset;
  flex-direction: column;
`;

export default function ExportPane() {
    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(false);
    const [progressMsg, setProgressMsg] = useState('');
    const [exportTarget, setExportTarget] = useState({});
    const [exportFormatData, setExportFormatData] = useState({});
    const [desc, setDesc] = useState('workspace image. (Select compounds or atoms to export data)');
    const [selected, setSelected] = useState(exportPaneTabs[0].id);
    const [imageOnly, setImageOnly] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    function setLoading(load, message = '') {
        setProgress(load);
        setProgressMsg(message);
    }

    function cacheFormatData(format, dataIn) {
        setExportFormatData((data) => ({ ...data, [format]: dataIn }));
    }

    function clearCachedFormatData() {
        setExportFormatData({});
    }

    function closeExportPane() {
        setOpen(false);
    }

    function currentlyMinimizingCheck(compounds, selectedAtoms) {
        const currentlyMinimizing = [];
        const minimizingFn = (c) => c.isMinimizing();

        // Check if chosen compounds are minimizing
        if (compounds && compounds.length > 0) {
            for (const minCmpd of compounds.filter(minimizingFn)) {
                currentlyMinimizing.push(minCmpd);
            }
        }

        // Check if any selected atoms belong to a minimizing compounds
        if (selectedAtoms && selectedAtoms.length > 0) {
            for (const minCmpd of App.Workspace.getLoadedCompounds().filter(minimizingFn)) {
                for (const atom of minCmpd.getAtoms()) {
                    if (selectedAtoms.includes(atom) && !currentlyMinimizing.includes(minCmpd)) {
                        currentlyMinimizing.push(minCmpd);
                    }
                }
            }
        }

        if (currentlyMinimizing.length > 0) {
            const nounVerb = currentlyMinimizing.length === 1
                ? 'compound is'
                : 'compounds are';
            let msg = `The following ${nounVerb} currently being minimized and cannot be exported:\n`;
            for (const cmpd of currentlyMinimizing) {
                msg += `* ${cmpd.resSpec}\n`;
            }
            showAlert(msg, 'Export');
            return false;
        }

        return true;
    }

    // Opens the export pane
    function askExport({ compounds: compoundsIn=[], atoms: atomsIn=[] }={}, args={}) {
        let compounds = compoundsIn;
        let atoms = atomsIn;
        const canUseCmpd = compounds && compounds.length > 0;
        const canUseAtoms = atoms && atoms.length > 0;

        let target;
        let description;
        if (canUseAtoms) { // Give priority to atoms
            target = { atoms };
            description = 'selected atoms';
            compounds = [];
        } else if (canUseCmpd) {
            target = { compounds };
            if (compounds.length < 5) {
                description = compounds.map((c) => c.resSpec).join(', ');
            } else {
                description = `${compounds[0].resSpec} and ${compounds.length - 1 } others`;
            }
            atoms = [];
        } else {
            // We don't have anything to export so only image download is available
            target = null;
            description = null;
        }

        // Whatever query that is actually going to be used,
        // check to see if it has atoms that are being minimized.
        if (!currentlyMinimizingCheck(compounds, atoms)) {
            return;
        }

        if (target) {
            setDesc(description);
            setExportTarget(target);
            clearCachedFormatData();
            setImageOnly(false);
            if (args.tabId) {
                setSelected(args.tabId);
            }
        } else {
            setDesc('workspace image. (Select compounds or atoms to export data)');
            clearCachedFormatData();
            setImageOnly(true);
            setSelected('export_download_tab');
        }
        setOpen(true);
    }

    useEffect(() => {
        const handler = EventBroker.subscribe('askExport', (e, [targets, args]) => askExport(targets, args));

        return () => {
            EventBroker.unsubscribe('askExport', handler);
        };
    }, []);

    return (
        <ModalPane
            visible={open}
            title={`Exporting ${desc}`}
            closeOnEscape
            close={closeExportPane}
            style={{
                width: '95vw',
                height: '85vh',
                maxWidth: 'none',
            }}
        >
            <ExportPaneContext.Provider
                value={{
                    exportTarget,
                    exportFormatData,
                    cacheFormatData,
                    clearCachedFormatData,
                    setLoading,
                    closeExportPane,
                    desc,
                    isMobile,
                }}
            >
                <TabController
                    tabs={exportPaneTabs.map((tab) => {
                        const disabled = imageOnly && !tab.imageOnly;
                        return tab.forTabController({}, {
                            TabProps: {
                                disabled,
                                title: disabled ? 'Add a compound or select atoms in the 3D workspace to enable this export option' : '',
                            },
                        });
                    })}
                    selected={selected}
                    setSelected={setSelected}
                    parentClass="export-pane"
                >
                    <Backdrop open={progress}>
                        <p>{progressMsg}</p>
                        <CircularProgress />
                    </Backdrop>
                </TabController>
            </ExportPaneContext.Provider>
        </ModalPane>
    );
}
