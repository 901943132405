import { Link } from '@mui/material';

export default function ExternalLink({ children, link, style: styleIn={} }) {
    const style = { color: 'blue', ...styleIn };
    return (
        <Link href={link} style={style} target="_blank" rel="noreferrer">
            {children}
            <sup><i style={{ marginLeft: '.25em' }} className="fa fa-external-link" /></sup>
        </Link>
    );
}
