import { configureStore } from '@reduxjs/toolkit';
import listenerMiddleware from './middlewares/listenerMiddleware';
import prefsReducer from './prefs';
import { initStateChangeSubscriber, register } from './subscribers/stateChangeSubscriber';
import projectTargetsReducer from './projectTargets';

const reducer = {
    prefs: prefsReducer,
    projectTargets: projectTargetsReducer,
};

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            // removes error message for non-serializable data in redux
            ignoredActionPaths: ['payload.mapCase'],
            ignoredPaths: ['projectTargets.targetList'],
        },
    }).concat(listenerMiddleware),
});

// Add store subscribers here
store.subscribe(initStateChangeSubscriber(store));

register('prefs.ColorScheme', (state) => state.prefs.ColorScheme);
register('prefs.BindingSiteDistance', (state) => state.prefs.BindingSiteDistance);

export default store;
