/* VisualizerInterface
 * A JS class to define the interface for 3d visualization solutions.
 */

import { Polymer, Residue } from './model/atomgroups';
import { getFullResidueId } from './util/mol_info_utils';

export class VisualizerInterface {
    constructor(options) {
        if (typeof window !== 'undefined') {
            this.InitViewer(options);
        }
        this.appToVisAtomMap = new Map();
        this.mouseHandlers = [];
        this.redisplayPauseLevel = 0;
        this.VisAtom = this.VisAtom.bind(this);
        this.AppAtom = this.AppAtom.bind(this);
        this.MapAtoms = this.MapAtoms.bind(this);
    }

    // "NON-VIRTUAL" METHODS - not expected to be defined by child classes
    // Setup
    RegisterMouseHandler(handler) {
        this.mouseHandlers.push(handler);
    }

    // Mapping between bmaps and visualizer atoms
    MapAtoms(appAtom, visAtom) {
        this.appToVisAtomMap.set(appAtom, visAtom);
    }

    UnmapAtoms(appAtoms) {
        for (const appAtom of appAtoms) {
            this.appToVisAtomMap.delete(appAtom);
        }
    }

    VisAtom(bmapsAtom) {
        return this.appToVisAtomMap.get(bmapsAtom);
    }

    AppAtom(myAtom) {
        abstractImplWarning('RemoveAtoms');
    }

    /**
     * Take a function that expects BMaps atoms and return one that expects visualizer atoms.
     * The resulting function will be called internally by the visualizer on the visulizer atoms,
     * but will ultimately evaluate BMaps atoms.
     * @param {(Atom)=>bool} selectorFn
     * @returns {bool}
     */
    wrapAtomFn(selectorFn) {
        return (visAtom) => selectorFn(this.AppAtom(visAtom));
    }

    /**
     * Find a Residue object for an atom. This is necessary because the parent atomGroup of the atom
     * is probably the chain object, not the residue.
     * It may be preferable to have the parent atom group be the residue.
     * This is kind of strange for this to be here.
     * Workspace also has an `atomGroupsForAtom` function, which looks at all atom groups.
     * @param {Atom} atom
     * @returns {Residue}
     */
    static getAtomProteinResidue(atom) {
        if (atom.hetflag) return null;
        let residue = atom.getAtomGroup();
        if (residue instanceof Residue) {
            // Doesn't have to do anything, already a residue
        } else if (residue instanceof Polymer) {
            residue = residue.getResidues().find((res) => res.hasAtom(atom));
            if (!residue) {
                console.log(`Failed to find a residue for ${getFullResidueId(atom)} in ${atom.getAtomGroup()}`);
            }
        } else {
            // Unrecognized type
            residue = null;
        }
        return residue;
    }

    // "VIRTUAL" METHODS - expected to be defined by child classes

    // Setup
    InitViewer(options) { } // Can be overloaded

    // Reset() should be overloaded to clear and reset everything,
    // but do call super.Reset() from the body.
    Reset() {
        this.appToVisAtomMap.clear();
    }

    // Display
    Redisplay() {
        if (this.redisplayPauseLevel === 0) {
            this.RedisplayViewer();
        }
    }

    // PauseRedisplay
    // This will stop the viewer from refreshing until it is unpaused.
    // You MUST ensure that it is unpaused!
    PauseRedisplay(paused) {
        if (paused) {
            this.redisplayPauseLevel++;
        } else {
            this.redisplayPauseLevel--;
        }

        if (this.redisplayPauseLevel < 0) {
            console.warn('Negative redisplay pause level');
            this.redisplayPauseLevel = 0;
        }
    }

    ResizeViewer() { abstractImplWarning('ResizeViewer'); }
    SetBackgroundColor() { abstractImplWarning('SetBackgroundColor'); }
    RedisplayViewer() { abstractImplWarning('RedisplayViewer'); }
    ZoomToAtoms() { abstractImplWarning('ZoomToAtoms'); }

    // Dealing with atom and molecule data
    MakeAtom(atom) { abstractImplWarning('MakeAtom'); }
    MakeBond(bond) { abstractImplWarning('MakeBond'); }
    AddAtoms(atom, bonds, sheets, helices) { abstractImplWarning('AddAtoms'); }
    GetAtomByUniqueID(uniqueID) { abstractImplWarning('GetAtomByUniqueID'); }
    MakeTempMolecule(atoms, style, colorMap) { abstractImplWarning('MakeTempMolecule'); }
    RemoveTempMolecule(tempModel) { abstractImplWarning('RemoveTempMolecule'); }
    UpdateTempMolecule(tempModel, props) { abstractImplWarning('UpdateTempMolecule'); }
    RemoveAtoms(atoms) { abstractImplWarning('RemoveAtoms'); }

    // Style
    StyleAtom(atom, style) { abstractImplWarning('StyleAtom'); }
    StyleAtoms(selectorFn, style) { abstractImplWarning('styleAtoms'); }
    setAtomSelected(atom, selected) { abstractImplWarning('setAtomSelected'); }
    setAtomColor(atom, color) { abstractImplWarning('setAtomColor'); }
    atomIsVisible(atom) { abstractImplWarning('setAtomColor'); }

    // Objects
    DrawSurface() { abstractImplWarning('DrawSurface'); }
    RemoveSurface() { abstractImplWarning('RemoveSurface'); }
    RemoveShape() { abstractImplWarning('RemoveShape'); }

    // More objects: JLKjr suggests rolling these all into DrawShape() and RemoveShape()
    drawLine() { }
    removeLine() { }
    drawSphere() { }
    removeSphere() { }
    drawCylinder() { }
    removeCylinder() { }
    drawCone() { }
    removeCone() { }
    drawArrow() { }
    removeArrow() { }
    drawLabel() { }
    removeLabel() { }

    // Additional proposed methods
    // SelectAtoms() { abstractImplWarning("SelectAtoms"); }
    // DrawShape() { abstractImplWarning("DrawShape"); }
    // GetView() { abstractImplWarning("GetView"); }
    // SetView() { abstractImplWarning("SetView"); }
    // addProtein() { abstractImplWarning("addProtein"); }
    // addCompound() { abstractImplWarning("addCompound"); }
    // addFragment() { abstractImplWarning("addFragment"); }
    // styleCompound() { abstractImplWarning("styleCompound"); }
    // styleProtein() { abstractImplWarning("styleProtein"); }

    // "protected"
    publishMouseEvent(target, mouseEvent) {
        for (const handler of this.mouseHandlers) {
            handler(target, mouseEvent);
        }
    }
}

function abstractImplWarning(method) {
    console.warn(`Abstract method call on 3d visualizer : ${method}.`);
}
