import { useEffect, useState } from 'react';
import { App } from '../../BMapsApp';
import { UserActions } from '../../cmds/UserAction';
import { withPausedRedisplay } from '../../util/display_utils';
import { WorkingIndicator } from '../ui_utils';
import TooltipInfo from '../common/TooltipInfo';
import InspectorMessage from './InspectorMessage';
import { fetchLID } from '../ProLIFApi';

export function DisplayLID({ compound }) {
    const [html, setHtml] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchDiagram() {
            const { connector: conn } = App.getDataParents(compound);
            const { data: pdb, error } = await UserActions.ExportQueryToFormat('protein', 'pdb', conn);
            const sdf = compound.getSDF();
            const noPdb = (!error && !pdb) || pdb?.indexOf('The export-selection command found nothing selected.') > 0;
            const haveError = error || pdb?.startsWith('Caught error processing text command request export-selection');

            const params = {
                width: '100%',
                height: '300px',
            };

            if (noPdb) {
                // Display a message for no pdb case:
                setErrorMessage('Load a protein to see a ligand interaction diagram.');
            } else if (haveError) {
                // Display error for error case:
                setErrorMessage('The ligand interaction diagram failed to load.');
            } else {
                // Set isLoading to true while fetching data
                setIsLoading(true);

                // Call API and set LID html
                try {
                    const result = await fetchLID(pdb, sdf, params);
                    if (!result.error) {
                        setHtml(result.html);
                    } else {
                        console.warn(`Error from LID service: ${result.error}`);
                        setErrorMessage('The Ligand Interaction Diagram service failed to prepare the diagram for this structure.');
                    }
                    setIsLoading(false);
                } catch (errorObj) {
                    console.warn(`Error communicating with LID service: ${errorObj.message}`);
                    setErrorMessage('There was a problem communicating with the Ligand Interaction Diagram service.');
                    setIsLoading(false);
                }
            }
        }
        withPausedRedisplay(fetchDiagram());
    }, [compound]);

    if (html) {
        return (
            <ProLIFResults html={html} />
        );
    } else if (errorMessage) {
        return <InspectorMessage>{errorMessage}</InspectorMessage>;
    } else if (isLoading) {
        return (
            <InspectorMessage>
                Loading ligand interaction diagram...
                {' '}
                <WorkingIndicator />
            </InspectorMessage>
        );
    } else {
        return <InspectorMessage>No Diagram Available</InspectorMessage>;
    }
}

function ProLIFResults({ html }) {
    return (
        <div style={{ position: 'relative' }}>
            <ProLIFInfoIcon style={{
                position: 'absolute', right: '0', backgroundColor: 'white', color: 'black',
            }}
            />
            <iframe srcDoc={html} title="ProLIF Ligand Interaction Diagram" width="100%" height="400" />
        </div>
    );
}

export function ProLIFInfoIcon(props) {
    const content = `"ProLIF" ligand interaction diagram from ChemoSim Lab.
    Try zooming, mousing over interaction lines, toggling the interactivity features, and dragging the residues.
    See https://doi.org/10.1186/s13321-021-00548-6 for more information.`;
    return (
        <TooltipInfo title={content} {...props} />
    );
}
