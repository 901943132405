/* MarvinWs.js
 * Interface for Marvin (JChem) webservices
 */

import { getColorSchemeInfo } from './redux/prefs/access';

let FRAGSERV_HOST = '';

export class WebServices {
    static get FRAGSERV_HOST() { return FRAGSERV_HOST; }
    static set FRAGSERV_HOST(value) { FRAGSERV_HOST = value; }

    // Execute a web service request, first serializing the json object
    static startWsRequestJson(url, postDataObj) {
        const postData = JSON.stringify(postDataObj);
        return WebServices.startWsRequest(url, postData, 'application/json');
    }

    /**
     * startWsRequest - execute a web service request, returning a promise
     * @param {string} uri
     * @param {string} postData
     * @param {string} contentType
     */
    static async startWsRequest(...args) {
        if (typeof XMLHttpRequest !== 'undefined') {
            return WebServices.startWsRequestHttpRequest(...args);
        } else {
            return WebServices.startWsRequestFetch(...args);
        }
    }

    // Browser version, relative paths are ok
    static startWsRequestHttpRequest(url, postData, contentType) {
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.addEventListener('load', function onLoad() {
                    if (this.status >= 200 && this.status < 300) {
                        resolve(this.responseText);
                    } else {
                        reject(new Error(this.statusText));
                    }
                });
                xhr.addEventListener('error', () => reject(new Error(`Error posting to ${url}`)));
                xhr.open('POST', url);
                xhr.setRequestHeader('Content-Type', contentType);
                xhr.send(postData);
            },
        );
    }

    // Node version. Only full uris with protocol are allowed
    static async startWsRequestFetch(url, postData, contentType) {
        const headers = {};
        if (contentType) headers['Content-Type'] = contentType;
        const requestOptions = {
            method: postData ? 'POST' : 'GET',
            headers,
        };
        if (postData) {
            requestOptions.body = postData;
        }

        try {
            const parsedURI = new URL(url); // eslint-disable-line no-unused-vars
        } catch {
            throw new Error(`startWsRequestFetch: ignoring an invalid or non-fully specified (including protocol) url: ${url}`);
        }

        const response = await fetch(url, requestOptions);
        const responseText = await response.text();
        if (response.ok) {
            return responseText;
        } else {
            console.log(`startWsRequestFetch failed with status ${response.statusText}.\nURL: ${url}\nResponse:\n${responseText}`);
            throw new Error(response.statusText);
        }
    }

    static refreshCookie() {
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.addEventListener('load', function onLoad() {
                    if (this.status >= 200 && this.status < 300) {
                        resolve(this.responseText);
                    } else {
                        reject(this.statusText);
                    }
                });
                xhr.addEventListener('error', (err) => {
                    console.error(`Cookie refresh error: ${err}`);
                    reject(new Error('Error refreshing cookie'));
                });
                // Load a simple page with the 'go' mechanism
                xhr.open('GET', '../mr/auth/refresh');
                // withCredentials allows login/session cookies to be included in requests to other
                // domains, specifically from eg dev.boltzmannmaps.com to auth.dev.boltzmannmaps.com
                xhr.withCredentials = true;
                xhr.send();
            },
        );
    }

    static mol2svg(...args) {
        return IndigoWs.mol2svg(...args);
    }

    static toSketcherView(...args) {
        return IndigoWs.toSketcherView(...args);
    }

    static toServerView(...args) {
        return IndigoWs.toServerView(...args);
    }

    static layout2d(...args) {
        return IndigoWs.layout2d(...args);
    }

    static getMolProps(...args) {
        return IndigoWs.getMolProps(...args);
    }
}

export class IndigoWs {
    // See Indigo options here:
    //     https://lifescience.opensource.epam.com/indigo/options/rendering-options.html
    static get imgurl() { return `${WebServices.FRAGSERV_HOST}/services/edit/pubpic/`; }
    static get transformurl() { return `${WebServices.FRAGSERV_HOST}/services/edit/transform/`; }
    static get molpropsurl() { return `${WebServices.FRAGSERV_HOST}/services/search/properties/`; }
    static get sketcherViewOps() { return ['layout', 'hide-hydrogens', 'dearomatize']; }

    static getMolProps(smiles) {
        return WebServices.startWsRequest(IndigoWs.molpropsurl,
            `kind=smiles&struct=${encodeURIComponent(smiles)}`,
            'application/x-www-form-urlencoded');
    }

    static mol2svg(
        molText, kindIn, baseColorHex=getColorSchemeInfo().textHex, width, height, otherParams={}
    ) {
        const kind = (kindIn === 'smi') ? 'smiles' : kindIn;
        const operations = (kind === 'mol') ? IndigoWs.sketcherViewOps : [];
        if (otherParams.dearomatize && !operations.includes('dearomatize')) {
            operations.push('dearomatize');
        }

        return WebServices.startWsRequestJson(IndigoWs.imgurl,
            {
                options: {
                    kind,
                    ext: 'svg',
                    width,
                    height,
                    'render-label-mode': 'hetero',
                    'render-bond-line-width': 1.5,
                    'render-margins': '10,10',
                    'render-stereo-style': 'none',
                    'render-base-color': IndigoWs.getColorArrayFromHex(baseColorHex),
                },
                operations,
                struct: molText,
            });
    }

    static toSketcherView(molText) {
        return WebServices.startWsRequestJson(IndigoWs.transformurl,
            {
                options: { operations: IndigoWs.sketcherViewOps },
                struct: molText,
            });
    }

    static layout2d(molText) {
        return WebServices.startWsRequestJson(IndigoWs.transformurl,
            {
                options: { operations: ['layout', 'dearomatize'] },
                struct: molText,
            });
    }

    static toServerView(molText) {
        return WebServices.startWsRequestJson(IndigoWs.transformurl,
            {
                options: { operations: ['show-hydrogens'] },
                struct: molText,
            });
    }

    static getColorArrayFromHex(color) {
        const r = ((color & 0xffffff) >> 16);
        const g = ((color & 0xffff) >> 8);
        const b = (color & 0xff);
        const transform = (n) => (n / 255).toFixed(2);

        return [r, g, b].map(transform).join(',');
    }
}

export class MarvinWs {
    static get molconvertws() { return '/mjswebservices/rest-v0/util/calculate/molExport'; }
    static get cleanws() { return '/mjswebservices/rest-v0/util/convert/clean'; }
    static get hydrogenws() { return '/mjswebservices/rest-v0/util/convert/hydrogenizer'; }

    static wsConvert(molData, toFormat, filterChain=[]) {
        const requestObj = {
            structure: molData,
            parameters: toFormat,
            filterChain,
        };

        return WebServices.startWsRequestJson(MarvinWs.molconvertws, requestObj)
            .then(MarvinWs.extractStructure);
    }

    static extractStructure(responseObject) {
        return Promise.resolve(JSON.parse(responseObject).structure);
    }

    static to2dView(mol) {
        return MarvinWs.wsConvert(mol, 'mrv', [
            { filter: 'clean', parameters: { dim: 2 } },
            { filter: 'hydrogenizer', parameters: { method: 'DEHYDROGENIZE' } },
            { filter: 'standardizer', parameters: { standardizerDefinition: 'dearomatize' } },
        ]);
    }

    static toSketcherView(mol) {
        return MarvinWs.wsConvert(mol, 'mrv', [
            { filter: 'clean', parameters: { dim: 2 } },
            { filter: 'hydrogenizer', parameters: { method: 'DEHYDROGENIZE' } },
            { filter: 'standardizer', parameters: { standardizerDefinition: 'dearomatize' } },
        ]);
    }

    static toServerView(mrv) {
        return MarvinWs.wsConvert(mrv, 'mol', [
            { filter: 'hydrogenizer', parameters: { method: 'HYDROGENIZE' } },
            { filter: 'clean', parameters: { dim: 3 } },
            { filter: 'standardizer', parameters: { standardizerDefinition: 'aromatize' } },
        ]);
    }

    static clean2d(mrv) {
        const requestObj = {
            structure: mrv,
            parameters: { dim: 2 },
        };
        return WebServices.startWsRequestJson(MarvinWs.cleanws, requestObj);
    }

    static clean3d(mrv) {
        const requestObj = {
            structure: mrv,
            parameters: { dim: 3 },
        };
        return WebServices.startWsRequestJson(MarvinWs.cleanws, requestObj);
    }

    static dearomatize(mrv) {
        return MarvinWs.wsConvert(mrv, 'mrv', [{ filter: 'standardizer', parameters: { standardizerDefinition: 'dearomatize' } }]);
    }

    static removeHydrogens(mrv) {
        const requestObj = {
            structure: mrv,
            parameters: { method: 'DEHYDROGENIZE' },
        };
        return WebServices.startWsRequestJson(MarvinWs.hydrogenws, requestObj);
    }

    static addHydrogens(mrv) {
        const requestObj = {
            structure: mrv,
            parameters: { method: 'HYDROGENIZE' },
        };
        return WebServices.startWsRequestJson(MarvinWs.hydrogenws, requestObj);
    }
}

export class FragservWs {
    static get fragsetListUrl() { return `${WebServices.FRAGSERV_HOST}/services/fragsets/sets/-LIST-`; }
    static get fragsetInfoUrl() { return `${WebServices.FRAGSERV_HOST}/services/fragsets/fraginfo`; }
    static get nameLookupUrl() { return `${WebServices.FRAGSERV_HOST}/services/search/lookupByName`; }

    static async listFragsets() {
        try {
            return JSON.parse(
                await WebServices.startWsRequest(FragservWs.fragsetListUrl),
            );
        } catch (ex) {
            console.error(`Failed to fetch fragment sets: ${ex}`);
            return [];
        }
    }

    static async listFragsetInfo() {
        try {
            return JSON.parse(
                await WebServices.startWsRequest(FragservWs.fragsetInfoUrl),
            );
        } catch (ex) {
            console.error(`Failed to fetch fragment set info: ${ex}`);
            return null;
        }
    }

    static async fragLookupByName(names) {
        try {
            return JSON.parse(
                await WebServices.startWsRequestJson(FragservWs.nameLookupUrl, { names }),
            );
        } catch (ex) {
            console.error(`Failed to lookup names for fragments: ${ex}`);
            return null;
        }
    }
}

/**
 * Search PubChem with the provided info: smiles, sdf, or compound name, returning an object
 * with the cid and iupac name if found, or an empty object if not.
 * @param {{ smiles: string?, sdf: string?, name: string? }} fieldsIn
 * @returns {{ cid: string?, iupacName: string? }}
 */
export async function pubChemLookup(fieldsIn={}) {
    // When searching by smiles, need to send "smiles," instead of "smi"
    const { smi, ...fields } = fieldsIn;
    if (smi) fields.smiles = smi;

    for (const field of ['sdf', 'smiles', 'name']) {
        const value = fields[field];
        if (!value) continue;

        const params = new URLSearchParams();
        params.append(field, value);
        const url = `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/${field}/property/Title,IUPACName/json`;
        const res = await fetch(url, {
            method: 'POST',
            body: params,
        });
        const json = await res.json();

        if (json.PropertyTable) {
            const properties = json['PropertyTable']['Properties'][0];
            return properties;
        }
    }

    return {};
}
