import TabInfo from '../../../../common/TabController/TabInfo';
import ImportProteinTab from './ImportProteinTab';
import ImportPdbTab from './ImportPdbTab';
import ImportAlphaFoldTab from './ImportAlphaFoldTab';
import SelectProteinTab from './SelectProteinTab';
import ProjectTargetsTab from './ProjectTargetsTab';
import { Loader } from '../../../../../Loader';

const tabs = [
    new TabInfo(
        'protein_search_tab',
        'Structure Search',
        SelectProteinTab,
        {
            TabPanelProps: {
                display: 'flex', // display checked in TabPanel.jsx; do not place in style
                style: {
                    flexDirection: 'column',
                },
            },
            viewOnlyOK: true,
        },
    ),
    new TabInfo(
        'import_protein_tab',
        'Upload Protein Data',
        ImportProteinTab
    ),
    new TabInfo(
        'import_pdb_tab',
        'Import from PDB',
        ImportPdbTab
    ),
    new TabInfo(
        'import_alphafold_tab',
        'Import from AlphaFold',
        ImportAlphaFoldTab
    ),
];

if (Loader.AllowDevFeatures) {
    tabs.push(
        new TabInfo(
            'project_target_tab',
            'Project / Targets',
            ProjectTargetsTab,
        )
    );
}

export default tabs;
