import { EventBroker } from './eventbroker';
import { loginUrlForMapCase, logoutUrl, accountUrl } from './connection_utils';
import { App } from './BMapsApp';

// menucmds.js

// Menu commands

// import {showMapSelector} from './map_selector.js';

// function MenuSelectStructure () {
//     console.log("Select Structure menu invoked.");
//     showMapSelector();
// }

// function MenuHowTo () {
//     console.log("How-to menu invoked.");
//     window.open("https://dev-server.boltzmannmaps.com/bmaps-wiki/index.php/How_To_...");
// }

// function MenuFAQ () {
//     console.log("FAQ menu invoked.");
//     window.open("https://dev-server.boltzmannmaps.com/bmaps-wiki/index.php/FAQ");
// }

// function MenuEditConfig () {
//     console.log("Configure menu invoked.");
//     jAlert("Not yet implemented.", 'Configure');
// }

function MenuTutorials() {
    console.log('Tutorials menu invoked.');
    window.open('https://www.youtube.com/channel/UCoFsnMxpCs1NnTB-9JAb1fw?&ab_channel=ConiferPoint');
}

function MenuFeedback() {
    console.log('Feedback menu invoked.');
    window.open('mailto:support@coniferpoint.com');
}

function MenuAbout() {
    console.log('About menu invoked.');
    jAlert(
`Boltzmann Maps:
Distributions of binding configurations
of chemical fragments or water molecules
on the surface of macromolecules,
conforming to Boltzmann energy statistics.

Developed at Conifer Point, LLC (<a href="https://www.coniferpoint.com">www.coniferpoint.com</a>).
Based on Grand Canonical Monte Carlo simulations
and fragment-based design tools developed at BioLeap, Inc.

Molecular visualization provided by 3Dmol.
 Nicholas Rego and David Koes
 "3Dmol.js: molecular visualization with WebGL"
 Bioinformatics (2015) 31 (8): 1322-1324
 doi:10.1093/bioinformatics/btu829`, 'About',
    );
}

function MenuAccount() {
    console.log('Account menu invoked.');
    window.location.href = accountUrl();
}

function MenuLogout() {
    console.log('Logout menu invoked.');
    if ($('#LogoutMenuItem').text() === 'Logout') {
        window.location.href = logoutUrl();
    } else {
        window.location.href = loginUrlForMapCase(App.Workspace.getLoadedProtein());
    }
}

const mainMenuItems = [
    // {id: 'SelectStructure', label: 'Select Protein', handler: MenuSelectStructure},
    // {id: 'HowTo', label: 'How To ...', handler: MenuHowTo},
    // {id: 'FAQ', label: 'FAQ', handler: MenuFAQ},
    // {id: 'EditConfig', label: 'Configure', handler: MenuEditConfig},
    { id: 'About', label: 'About', handler: MenuAbout },
    { id: 'Tutorials', label: 'Tutorials', handler: MenuTutorials },
    { id: 'Feedback', label: 'Feedback', handler: MenuFeedback },
    { id: 'Account', label: 'Account', handler: MenuAccount },
    { id: 'Logout', label: 'Logout', handler: MenuLogout },
];

export function initMenus() {
    for (const menuItem of mainMenuItems) {
        addMenuItem(menuItem.id, menuItem.label, menuItem.handler);
    }
    $('#topbar-menu-button').click(toggleMainMenu);
    console.log('Main menu initialized.');

    // Close at appropriate times.
    // Note that backgroundMouse is only invoked if something has been loaded
    EventBroker.subscribe('backgroundMouse', closeMainMenu);
    EventBroker.subscribe('escapeKey', closeMainMenu);

    EventBroker.subscribe('connectionStatusChanged', (_, { connected, mode }) => {
        if (mode === 'static') {
            $('#LogoutMenuItem').text('Login / Sign Up');
            $('#AccountMenuItem').parent().hide();
        } else {
            $('#LogoutMenuItem').text('Logout');
            $('#AccountMenuItem').parent().show();
        }
    });
}

export function addMenuItem(id, label, handler) {
    const mainMenu = $('#topbar-menu-list');

    const menuid = `${id}MenuItem`;
    const itemText = label;
    mainMenu.append(`<li><a id='${menuid}'>${itemText}</a></li>`);
    $(`#${menuid}`).click(() => { toggleMainMenu(); handler(); });
}

function toggleMainMenu() {
    $('#topbar-menu-list, #topbar-menu-button').toggleClass('open');
}

function closeMainMenu() {
    $('#topbar-menu-list, #topbar-menu-button').removeClass('open');
}
