/* style_manager.js
 *
 * A class to manage styling of atoms, compounds, and proteins.
 */

export const MolStyles = {
    default: 'default',
    hidden: 'hidden',
    wireframe: 'wireframe',
    sticks: 'sticks',
    ballandstick: 'ballandstick',
    spacefill: 'spacefill',
    ribbons: 'ribbons',
    backbone: 'backbone',
    cartoon: 'cartoon',
    surface: 'surface',
    tribbons: 'tribbons',
    ghost: 'ghost',
    invisible: 'invisible',
};

export class StyleManager {
    static get DefaultProteinViewProteinStyle() { return MolStyles.cartoon; }
    static get DefaultLigandViewProteinStyle() { return MolStyles.wireframe; }

    constructor() {
        this.resetInternal();
    }

    resetInternal() {
        this.ProteinViewProtein = StyleManager.DefaultProteinViewProteinStyle;
        this.LigandViewProtein = StyleManager.DefaultLigandViewProteinStyle;
        this.AtomStyleMap = new Map();
        this.AtomGroupStyleMap = new Map();
        this.AtomGroupMolStyleMap = new Map();
    }

    static reset() {
        TheStyleManager.resetInternal();
    }

    static get ProteinViewProteinStyle() {
        return TheStyleManager.ProteinViewProtein;
    }

    static set ProteinViewProteinStyle(style) {
        TheStyleManager.ProteinViewProtein = style;
    }

    static get LigandViewProteinStyle() {
        return TheStyleManager.LigandViewProtein;
    }

    static set LigandViewProteinStyle(style) {
        TheStyleManager.LigandViewProtein = style;
    }

    static setCustomAtomsStyle(atoms, style) {
        for (const atom of atoms) {
            TheStyleManager.AtomStyleMap.set(atom.uniqueID, style);
        }
    }

    static removeCustomAtomsStyle(atoms) {
        for (const atom of atoms) {
            TheStyleManager.AtomStyleMap.delete(atom.uniqueID);
        }
    }

    static getCustomAtomStyle(atom) {
        return TheStyleManager.AtomStyleMap.get(atom.uniqueID);
    }

    static setCustomAtomGroupColor(atomGroup, options) {
        TheStyleManager.AtomGroupStyleMap.set(atomGroup, { ...options });
    }

    static removeCustomAtomGroupColor(atomGroup) {
        TheStyleManager.AtomGroupStyleMap.delete(atomGroup);
    }

    static getCustomAtomGroupColor(atomGroup) {
        return TheStyleManager.AtomGroupStyleMap.get(atomGroup);
    }

    static setCustomAtomGroupMolStyle(atomGroup, style) {
        TheStyleManager.AtomGroupMolStyleMap.set(atomGroup, style);
    }

    static removeCustomAtomGroupMolStyle(atomGroup) {
        TheStyleManager.AtomGroupMolStyleMap.delete(atomGroup);
    }

    static getCustomAtomGroupMolStyle(atomGroup) {
        return TheStyleManager.AtomGroupMolStyleMap.get(atomGroup);
    }

    static captureAtomGroupStyles(atomGroups) {
        const AtomGroupsColorState = {};
        const AtomGroupsMolStyleState = {};
        for (const ag of atomGroups) {
            const agCustomColor = this.getCustomAtomGroupColor(ag);
            if (agCustomColor) {
                AtomGroupsColorState[ag.key] = agCustomColor;
            }
            const agCustomMolStyle = this.getCustomAtomGroupMolStyle(ag);
            if (agCustomMolStyle) {
                AtomGroupsMolStyleState[ag.key] = agCustomMolStyle;
            }
        }
        return { AtomGroupsColorState, AtomGroupsMolStyleState };
    }

    static captureGlobalStyleState() {
        return {
            LigandViewProteinStyle: StyleManager.LigandViewProteinStyle,
            ProteinViewProteinStyle: StyleManager.ProteinViewProteinStyle,
            // Can't easily include Custom Atom Map because it is based on
            // atomUniqueIds, which cannot be guaranteed.
        };
    }

    static restoreStyleState(obj) {
        if (obj.ProteinViewProteinStyle) {
            StyleManager.ProteinViewProteinStyle = obj.ProteinViewProteinStyle;
        }

        if (obj.LigandViewProteinStyle) {
            StyleManager.LigandViewProteinStyle = obj.LigandViewProteinStyle;
        }
    }
}

const TheStyleManager = new StyleManager();
