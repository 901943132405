/* MapCase.js */

// JSON packets from server have snake_cased fields. Allow destructuring
/* eslint camelcase: [ "error", { properties: "never", allow: ["molecule_name", "gene_name"]}] */

export class MapCase {
    constructor(obj) {
        this.molecule_name = obj.molecule_name;
        this.pdbID = obj.pdbID;
        this.description = obj.description;
        this.fragSpecs = obj.fragSpecs;
        this.uri = obj.uri; // May include mount index
        this.gene_name = obj.gene_name;
        this.project = obj.project;
        this.case = obj.case;
        this.projectCase = this.project && this.case ? `${this.project}/${this.case}`: ''; // Like uri, but without mount
        this.group_name = obj.group_name || obj.molecule_name;
        this.organism = obj.organism;
        this.sourceDesc = obj.sourceDesc; // Note about the origin
        this.lastUpdate = obj.lastUpdate || null;
        this.data = obj.data; // pdb data loaded by user
        this.data_format = obj.data_format; // format of user data (pdb or mol2)
        this.caseDataCollection = null;
        /** @type {DataSource} */
        this.dataSource = null;
        this.otherProperties = obj.otherProperties;
    }

    getDataSource() { return this.dataSource; }
    setDataSource(dataSource) { this.dataSource = dataSource; }
    getCaseDataCollection() { return this.caseDataCollection; }
    setCaseDataCollection(caseDataCollection) { this.caseDataCollection = caseDataCollection; }

    static externalLinkInfo() { return null; }
    myExternalLinkInfo() {
        if (PdbImportCase.isValidId(this.pdbID)) {
            return PdbImportCase.externalLinkInfo(this.pdbID);
        }
        return MapCase.externalLinkInfo(this.pdbID);
    }

    get displayName() {
        return this.molecule_name
            || this.pdbID
            || this.projectCase
            || this.gene_name
            || this.description
            || '(unnamed target)';
    }

    get loadingMessage() { return 'Loading protein and fragment data...'; }

    getNodeName() {
        return (
            this.project
                ? this.projectCase
                : (this.pdbID || this.molecule_name || 'Unnamed structure')
        );
    }

    getLongName() {
        const protName = this.molecule_name || this.description || '';
        const pdbID = this.pdbID || '';
        const pdbDisplay = (protName && pdbID) ? ` (${pdbID})` : pdbID;
        return `${protName}${pdbDisplay}`;
    }

    static parseFragSpecs(spec) {
        //--- TBI
        return spec;
    }

    // Convert bfd-server maplist object to local class. Mostly passthrough (except pdbid)
    // Note: uri and lastUpdate are not part of the entries in the map-list.json file:
    // * uri is calculated here using the datasource mountIndex
    // * lastUpdate is added to the entries dynamically by bfd-server
    static fromBfdServer(map, dataSource) {
        const requiredFields = ['molecule_name', 'pdbid', 'description'];
        if (requiredFields.some((field) => map[field] == null)) {
            console.warn(`Ill-formatted map case obj: ${JSON.stringify(map)}`);
            return null;
        }

        let uri = `${map['project']}/${map['case']}`;
        if (dataSource?.mountIndex != null) {
            uri += ` mount${dataSource.mountIndex}`;
        }

        // lastUpdate from bfd server is a unix timestamp (seconds from epoch)
        // Multiply by 1000 since JS Date object expects ms from epoch
        const lastUpdate = map.lastUpdate != null ? new Date(map.lastUpdate * 1000) : null;
        const mapCase = new MapCase({
            molecule_name: map['molecule_name'],
            pdbID: map['pdbid'], // note case difference
            description: map['description'],
            fragSpecs: null, // NYI on server
            uri,
            gene_name: map['gene_name'],
            project: map['project'],
            case: map['case'],
            sourceDesc: map['sourceDesc'] || MapCase.Sources.Public,
            organism: map['organism'],
            group_name: map['group_name'],
            lastUpdate,
            otherProperties: map['otherProperties'],
        });
        mapCase.setDataSource(dataSource);
        return mapCase;
    }

    /**
     * Parse a project case string into project and case components
     * Test in MapCase.test.js
     * @param {string} projectCase
     * @returns {{ project: string?, case: string? }}
     */
    static splitProjectCase(projectCase) {
        const projectCasePattern = /^(?<project>[^\s/]+)\/(?<case>[^\s/]+)$/;
        const match = projectCase.match(projectCasePattern);
        return {
            project: match?.groups?.project || null,
            case: match?.groups?.case || null,
        };
    }

    /**
     * Parse a mapcase uri into projectCase and mountIndex components
     * Test in MapCase.test.js
     * @param {string} uri
     * @returns {{ projectCase: string?, mountIndex: string?, project: string?, case: string? }}
     */
    static splitUri(uri) {
        const ret = {
            projectCase: null,
            mountIndex: null,
            project: null,
            case: null,
        };

        const uriPattern = /^(?<projectCase>[^\s]+)( (?<mountIndex>mount\d+))?$/;
        const match = uri.match(uriPattern);
        if (!match) return ret;

        const { projectCase, mountIndex } = match.groups;
        ret.projectCase = projectCase;
        ret.mountIndex = mountIndex || null;

        const { project, case: caseName } = MapCase.splitProjectCase(projectCase);
        ret.project = project;
        ret.case = caseName;
        return ret;
    }
}

export class PdbImportCase extends MapCase {
    constructor(pdbID) {
        super({
            pdbID,
            group_name: MapCase.GroupNames.PdbImports,
            molecule_name: '',
            description: '',
            sourceDesc: MapCase.Sources.PDB,
            fragSpecs: '',
            uri: `pdb:${pdbID}`,
        });
    }

    get displayName() { return this.pdbID; }
    get loadingMessage() { return `Importing ${this.displayName} from the PDB`; }
    get externalLink() { return PdbImportCase.externalLinkInfo(this.pdbID).url; }

    getNodeName() {
        return `PDB Import: ${this.pdbID}`;
    }

    // Number followed by 3 alphanumerics, with at least one letter
    static isValidId(id) {
        const pidFormat = /^[0-9][A-Za-z0-9]{3}$/;
        const alphaChar = /[A-Za-z]/;
        return pidFormat.test(id) && alphaChar.test(id);
    }

    static isUri(uri) {
        const pattern = /^pdb:/;
        return pattern.test(uri);
    }

    static parseUri(uri) {
        return {
            id: uri.substr('pdb:'.length),
        };
    }

    static externalLinkInfo(id) {
        return {
            url: `http://www.rcsb.org/pdb/explore/explore.do?structureId=${id}`,
            label: 'PDB',
            title: `Go to the original ${id} structure at the PDB`,
        };
    }

    myExternalLinkInfo() {
        return PdbImportCase.externalLinkInfo(this.pdbID);
    }

    static newRecordMsgInfo(id) {
        return {
            description: 'Use original structure from the protein data bank (no simulation data available)',
            itemName: `Import ${id}`,
        };
    }
}

export class AlphaFoldImportCase extends MapCase {
    constructor(uniprotID) {
        super({
            pdbID: uniprotID,
            group_name: MapCase.GroupNames.AlphaFoldImports,
            molecule_name: '',
            description: '',
            sourceDesc: MapCase.Sources.AlphaFold,
            fragSpecs: '',
            uri: `uniprot:${uniprotID}`,
        });
    }

    get displayName() { return this.pdbID; }
    get loadingMessage() { return `Importing ${this.displayName} from AlphaFold`; }
    get externalLink() { return AlphaFoldImportCase.externalLink(this.pdbID).url; }

    getNodeName() {
        return `AlphaFold Import: ${this.pdbID}`;
    }

    static isValidId(id) {
        // From: https://www.uniprot.org/help/accession_numbers
        const format = /^[OPQ][0-9][A-Z0-9]{3}[0-9]|[A-NR-Z][0-9]([A-Z][A-Z0-9]{2}[0-9]){1,2}$/;
        return format.test(id);
    }

    static isUri(uri) {
        const pattern = /^uniprot:/;
        return pattern.test(uri);
    }

    static parseUri(uri) {
        return {
            id: uri.substr('uniprot:'.length),
        };
    }

    static externalLinkInfo(id) {
        return {
            url: `https://alphafold.ebi.ac.uk/entry/${id}`,
            label: 'AlphaFold',
            title: `Go to UniProt:${id} structure at AlphaFold`,
        };
    }

    myExternalLinkInfo() {
        return AlphaFoldImportCase.externalLinkInfo(this.pdbID);
    }

    static newRecordMsgInfo(id) {
        return {
            description: 'Import AI generated structure from AlphaFold',
            itemName: `AlphaFold import UniProt:${id}`,
        };
    }
}

export class UserDataImportCase extends MapCase {
    constructor({
        name, pdbid, sourceDesc, data, format,
    }) {
        super({
            group_name: MapCase.GroupNames.UserUploads,
            molecule_name: name || UserDataImportCase.newDefaultMolName(),
            pdbID: pdbid || '',
            description: '',
            sourceDesc,
            fragSpecs: '',
            uri: UserDataImportCase.newUri(),
            data,
            data_format: format,
        });
    }

    get loadingMessage() { return 'Importing protein data...'; }

    getNodeName() {
        return `User upload: ${this.pdbID || this.molecule_name}`;
    }

    // stuff for user data /
    static newUri() {
        if (UserDataImportCase.LocalIdCounter === undefined) UserDataImportCase.LocalIdCounter = 0;
        return `local:userdata${UserDataImportCase.LocalIdCounter++}`;
    }

    static newDefaultMolName() {
        return `${UserDataImportCase.DefaultRootMolName}`;
    }

    static isUri(uri) {
        const pattern = /^local:/;
        return pattern.test(uri);
    }

    static isDefaultMolName(name) {
        return name.match(`^${UserDataImportCase.DefaultRootMolName}`);
    }
}

UserDataImportCase.DefaultRootMolName = 'Imported structure';

MapCase.GroupNames = {
    PdbImports: 'PDB Imports',
    UserUploads: 'User Uploads',
    AlphaFoldImports: 'AlphaFold Imports',
};
MapCase.Sources = {
    Public: 'bmaps_public', // Located in public bmaps repo
    Private: 'bmaps_private', // Located in a private, managed repo
    PDB: 'Imported from protein data bank', // Pulled from PDB
    ImportScreen: 'Import screen', // Added with Import tool
    Fragserv: 'fragserv', // Created with fragment service, and loaded from user repo
    AlphaFold: 'Imported from AlphaFold', // Pulled from AlphaFold
};
