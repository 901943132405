/*
 * StaticDataConnection.js
 StaticDataConnection is an an alternative to ServerConnection.
 Rather than use a websocket connection, StaticData gets data from
 static files on the webserver.  It can be used to load existing data only:
 No adding compounds, making any changes, or computation.
 */

// "series_list" is a field in the fragment api.  Allow destructuring
/* eslint camelcase: ["error", { properties: "never", allow: ["^dd?Gs?_(bound|LP?|PL?)$", "series_list"]}] */ // eslint-disable-line max-len
import { EventBroker } from '../eventbroker';

import { ServerCmd, ServerCmds } from '../server_cmds';

import { ResponseIds } from './server_common';
import { makePreviewModeError } from '../utils';
import { SessionErrorType } from './session_request';
import { toByteArray } from '../../lib/js/base64'; // for png workaround>
import { setAtomSelectedMiddleman } from '../project_data';
import { BFDServerInterface } from './BFDServerInterface';
import { App } from '../BMapsApp';
/**
 * @description Returns necessary information for pulling the static data for
 * each response type that the server might have sent.
 *
 * @param string responseId
 * @param stringOrObj projectCase
 * @param string cmpdSpec
 * @returns { string file bool binary, bool absolute or undefined, function post or undefined}
 */
function getStaticDataInfo(responseId, projectCase, cmpdSpec) {
    // First handle responses that aren't case specific
    switch (responseId) {
        case ResponseIds.MapList:
            return {
                file: 'map-list.json',
                binary: false,
                absolute: true,
                // mock bfd-server bundling the maplist together with a data source description
                // postProcess expects string-in, string-out
                postProcess: (data) => JSON.stringify({
                    // bfd-server includes mount_index in the datasource,
                    // but we don't need this in static mode.
                    datasource: { type: 'bmaps-preview', id: 'static-data', name: 'Preview Data' },
                    maplist: JSON.parse(data),
                }),
            };
        // no default; will be handled below
    }

    const {
        spec, project, caseName, caseNoFrags,
    } = getProjectCaseInfo(projectCase);
    const { fileSpec: fileCmpdSpec, resname } = compoundSpecComponents(cmpdSpec);

    const appendSpec = (data, caseSpec) => {
        // We need to append the projectCase spec to the end of bsf data
        const ab = new Uint8Array(data.byteLength + caseSpec.length);
        ab.set(new Uint8Array(data));
        ab.set(new TextEncoder().encode(caseSpec), data.byteLength);
        return ab.buffer;
    };
    switch (responseId) {
        case ResponseIds.Solute:
            return {
                file: `${caseNoFrags}_clustering-solute.bsf`,
                binary: true,
                postProcess: (data) => appendSpec(data, spec),
                errorIfMissing: true,
            };
        case ResponseIds.AtomGroupInfo:
            return { file: `${caseName}-atominfo.dat`, binary: true };
        case ResponseIds.WaterMap:
            return {
                file: `${caseNoFrags}_clustering-watermap.bsf`,
                binary: true,
                postProcess: (data) => appendSpec(data, spec),
            };
        case ResponseIds.ClusterMap:
            return {
                file: `${caseNoFrags}_clustering-clustermap.bsf`,
                binary: true,
                postProcess: (data) => appendSpec(data, spec),
            };
        case ResponseIds.EnergiesForLigandText:
            return {
                file: `${fileCmpdSpec}.eng`,
                binary: false,
                postProcess: (data) => processEnergyCache(cmpdSpec, data),
                errorIfMissing: true,
            };
        case ResponseIds.SolvationForLigandText:
            return {
                file: `${fileCmpdSpec}.esv`,
                binary: false,
                postProcess: (data) => processSolvationCache(cmpdSpec, data),
                errorIfMissing: true,
            };
        case ResponseIds.StarterAvailability:
            return {
                file: `${caseName}-sample_compounds.json`,
                binary: false,
            };
        case ResponseIds.AvailableFragments:
            return {
                file: `${caseName}-availableFrags.json`,
                binary: false,
            };
        case ResponseIds.FragmentMap: {
            const fragName = cmpdSpec;
            return {
                file: `${caseName}-${fragName}_map.bsf`,
                binary: true,
                postProcess: (data) => appendSpec(data, spec),
                errorIfMissing: true,
            };
        }
        case ResponseIds.Compound2D:
            return {
                file: `${caseName}-${fileCmpdSpec}.2d`,
                binary: false,
                errorIfMissing: true,
            };
        case ResponseIds.GetMoleculeProperties:
            return {
                file: `${caseName}-${resname}-props.json`,
                binary: false,
                errorIfMissing: true,
            };
        default:
            return undefined;
    }
}

export class StaticDataConnection extends BFDServerInterface {
    constructor(workspace) {
        super();
        this.connInfo = {
            agent: 'static',
            key: 'static',
            port: 'server',
            createdAt: new Date(),
            isOpen: true,
        };

        this.staticMode = true;

        this.storage = new StaticDataStorage();
        this.storage.setWorkspace(workspace);

        // setTimeout allows this constructor to finish and register at
        // App.ServerConnection before subscribers of 'connectionStatusChanged'
        // will see the event.
        setTimeout(
            () => EventBroker.publish('connectionStatusChanged',
                { connector: this, connected: true, mode: this.getMode() }),
            0,
        );
    }

    // Implementation of abstract methods

    /**
     * Get request id, set up response listener, execute the command in the context of the listener
     *
     * This differs from the ServerConnection in a few ways:
     * 1) We don't need command data; the static functions will operate diretly on the command args.
     *    We do need the request id though.
     * 2) The server case sends the command over the websocket, then sets up a response listener.
     *    In the static case, the listener is created first, then it is hooked up to listen to the
     *    command being executed.
     */
    execCmd(cmdName, serverCmd, ...args) {
        const cmdExecFn = StaticServerCmds[cmdName];
        const requestId = ServerCmd.GetRequestId(serverCmd.cmdName);

        // ServerResponder is a Promise-like object that processes multiple responses for a given
        // command and resolves when the Completed message arrives.
        // In static mode, we set up the ServerResponder, then execute the command inside a new
        // Promise, overriding the ServerResponder's promise functions.  When the ServerResponder
        // processes the Competed message it will resolve the wrapper Promise returned here.
        const serverResponder = this.setupServerResponder(serverCmd, requestId, args);
        const wrapper = (resolve, reject) => {
            serverResponder.resolve = resolve;
            serverResponder.reject = reject;
            this.doCmd(serverCmd, cmdExecFn, requestId, ...args);
        };
        return new Promise(wrapper);
    }

    zapNoWait() {
        const cmdName = 'ZapAll';
        const serverCmd = ServerCmds[cmdName];
        const execFn = StaticServerCmds[cmdName];
        const requestId = ''; // ignored
        execFn(serverCmd, requestId, this);
    }

    get connectionInfo() {
        return this.connInfo;
    }

    isConnected() {
        return false;
    }

    getMode() {
        return 'static';
    }

    // Internal methods

    /**
     * @description Simulate a server command.  That means, fetch the right data from the
     * webserver and package it up in the same response msgs that bfd-server would have sent.
     * @param {*} serverCmd - ServerCmd object
     * @param {*} cmdExecFn - the function to actually perform the requested logic
     * @param {*} requestId
     * @param  {...any} args
     */
    async doCmd(serverCmd, cmdExecFn, requestId, ...args) {
        const handleResponses = async (responsesIn) => {
            let responses = responsesIn;
            // Make sure we're dealing with an array of non-null responses ended by Completed.
            if (responses == null) responses = [];
            if (!(responses instanceof Array)) responses = [responses];
            responses = responses.filter((x) => x); // remove null values
            if (!responses.find((x) => x.responseId === ResponseIds.Completed)) {
                responses.push({ responseId: ResponseIds.Completed });
            }

            // Process all the responses
            for (const { responseId, data } of responses) {
                const responseResult = this.cmdProcessingQueue.handleResponse(
                    requestId, responseId, data
                );
                const { result } = responseResult;
                // Certain commands may need to add new commands after processing the responses
                // Especially GetCaseFiles, which needs to process the Solute response before
                // knowing which ligands to request energies and solvation for.
                const { newCmds } = await specialHandling(serverCmd, requestId, responseId, result);
                if (newCmds && newCmds.length > 0) {
                    responses.splice(responses.length-1, 0, ...newCmds);
                }
            }
        };

        if (!cmdExecFn) {
            await handleResponses(
                errorResponse(`static-mode ${serverCmd.cmdName}`,
                    makePreviewModeError(serverCmd.label())),
            );
            callForAction(serverCmd);
        } else {
            const responses = await cmdExecFn(serverCmd, requestId, this, ...args);
            await handleResponses(responses);
        }
    }
}

/**
 * Parse a projectCase spec into components, or return an already parsed object
 * @param {string | Object} specOrObj
 * @returns { { project: string, caseName: string, spec: string, caseNoFrags: string } }
 */
function getProjectCaseInfo(specOrObj) {
    return typeof specOrObj === 'string'
        ? projectCaseComponents(specOrObj)
        : specOrObj;
}

/**
 * @description Parse project/case spec into components
 * @param string spec project/case
 * @returns {
 *      project,
 *      caseName,
 *      spec,
 *      caseNoFrags
 * }
 */
function projectCaseComponents(spec) {
    const [project, caseName] = spec.split('/');
    let root = caseName;
    if (root.endsWith('_frags')) root = root.slice(0, -1 * '_frags'.length);
    return {
        spec: `${project}/${caseName}`, project, caseName, caseNoFrags: root,
    };
}

/**
 * @description Parse compound spec into residue name, sequence number, chain.
 * If the spec is null or empty, or doesn't parse, return an empty object.
 * getStaticDataInfo() calls this at the top for every operation; most of them
 * don't actually use a compound spec.
 * @param {*} cmpdSpec
 * @todo Handle alt locations, insertion code, and model number
 */
function compoundSpecComponents(cmpdSpec) {
    if (!cmpdSpec) return {};

    const fileSpec = cmpdSpec.replace(/[.:]/g, '_');
    const matches = cmpdSpec.match(/([\w]+)\.([\d]+):([\w]+)/);
    if (!matches) return {};

    const [_, resname, seqNum, chain] = matches;
    return {
        spec: cmpdSpec,
        fileSpec,
        resname,
        seqNum,
        chain,
    };
}

/**
 * @description - Fetch data from the webserver, in either text or binary format
 * @param string url
 * @param bool binary
 */
function fetch(url, binary=false) {
    return new Promise(
        (resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('load', function onLoad() {
                if (this.status >= 200 && this.status < 300) {
                    resolve(this.response);
                } else {
                    reject(this.statusText);
                }
            });
            xhr.addEventListener('error', () => reject(new Error(`Error posting to ${url}`)));
            if (binary) {
                xhr.responseType = 'arraybuffer';
            }
            xhr.open('GET', `/staticdata/${url}`);
            xhr.send();
        },
    );
}

/**
 * @description Fetch the requested data, running postProcess if necessary
 * @param ResponseId type
 * @param stringOrObj projectCase
 * @param string cmpdSpec
 */
async function fetchDataFor(type, projectCase, cmpdSpec) {
    const {
        file, binary, absolute, postProcess,
    } = getStaticDataInfo(type, projectCase, cmpdSpec);

    let data;
    if (absolute) {
        data = await fetch(file, binary);
    } else {
        const { project, caseName } = getProjectCaseInfo(projectCase);
        data = await fetch(`${project}/${caseName}/${file}`, binary);
    }

    if (postProcess) {
        data = postProcess(data);
    }
    return data;
}

/**
 * @description Fetch data and bundle into response object
 * @param ResponseId responseId
 * @param stringOrObject projectCase?
 * @param string cmpdSpec?
 */
async function fetchResponse(serverCmd, responseId, projectCase, cmpdSpec) {
    try {
        const data = await fetchDataFor(responseId, projectCase, cmpdSpec);
        return { responseId, data };
    } catch (ex) {
        const { errorIfMissing } = getStaticDataInfo(responseId, projectCase, cmpdSpec);
        if (errorIfMissing) {
            const desc = typeof (projectCase) === 'object' ? projectCase.spec : projectCase;
            return errorResponse(serverCmd, `Failed to retrieve ${responseId} data for ${desc} ${cmpdSpec || ''}`);
        } else {
            return null;
        }
    }
}
/**
 * Fetch and store sample compounds for a projectCase.
 * Stored so they don't have to be fetched next time.
 * We could consider not storing it; this would delay the retrieval,
 * but would save memory if they aren't being used.
 * @param {*} projectCase
 */
async function starterAvailability(projectCase, storage) {
    const responseId = ResponseIds.StarterAvailability;
    const responseData = { available: false };
    try {
        const data = await fetchDataFor(responseId, projectCase);
        storage.StarterCompounds = JSON.parse(data);
        responseData.available = true;
    } catch {
        responseData.available = false;
    }

    return { responseId, data: JSON.stringify(responseData) };
}

/* IMPLEMENTATION OF ALL THE SERVER COMMANDS */
export const StaticServerCmds = {
    Terminate: null,
    ListMaps: async (serverCmd) => {
        console.log('Fetching map data...');
        return fetchResponse(serverCmd, ResponseIds.MapList);
    },
    GetPermissions: (serverCmd) => responseObj(ResponseIds.SetPermissions, StaticModePermissions),
    ZapAll: (serverCmd, requestId, connector) => {
        const storage = connector.storage;
        console.log('Resetting data storage.');
        storage.reset();
    },
    GetCaseFiles: async (serverCmd, requestId, connector, spec) => {
        const storage = connector.storage;
        storage.ProjectCase = spec;
        console.log(`Fetching case files for ${spec}`);
        const solute = await fetchResponse(serverCmd, ResponseIds.Solute, spec);
        const atominfo = await fetchResponse(serverCmd, ResponseIds.AtomGroupInfo, spec);
        const watermap = await fetchResponse(serverCmd, ResponseIds.WaterMap, spec);
        const clustermap = await fetchResponse(serverCmd, ResponseIds.ClusterMap, spec);
        const samplesAvailability = await starterAvailability(spec, storage);
        // Note: Compound2D, EnergiesForLigand, SolvationForLigand are fetched
        // for each ligand in specialHandling()
        // AvailableFragments is also requested in specialHandling, to ensure
        // it is the last response (it triggers a bunch of image requests)
        return [atominfo, solute, watermap, clustermap, samplesAvailability];
    },
    ValidateProjectCase: null,
    WriteProjectCase: null,
    RunProjectCase: null,
    RefreshFragments: null,
    GetAvailableFragments: async (serverCmd, requestId, connector) => {
        const storage = connector.storage;
        const packet = await fetchResponse(
            serverCmd, ResponseIds.AvailableFragments, storage.ProjectCase
        );
        return [packet];
    },
    GetSnapshot: (serverCmd, requestId, connector, ...args) => {
        console.log(`${requestId} ${args}`);
    },
    GetFragmentMap: async (serverCmd, requestId, connector, { name, series_list }) => {
        const storage = connector.storage;
        const fragSeriesResults = [];
        for (const item of series_list) {
            const [mountIndex, seriesName] = item;
            if (mountIndex !== 0) {
                console.warn(`Preview mode can't handle mount indices (${name}: ${mountIndex} ${seriesName}).`);
                console.log(`Preview mode can't handle mount indices (${name}: ${mountIndex} ${seriesName}).`);
                continue;
            }
            fragSeriesResults.push(await fetchResponse(
                serverCmd, ResponseIds.FragmentMap, storage.ProjectCase, seriesName
            ));
        }

        return fragSeriesResults;
    },
    TranslateMolecule: null,
    LoadMolData: null,
    DockCompound: null,
    GetSolvationForLigand: null,
    GetForcefieldParamsForLigand: null,
    GetEnergiesForLigand: null,
    Select: (serverCmd, requestId, connector, queryIn) => {
        const storage = connector.storage;
        let query = queryIn;
        // The select command is invoked automatically by various components
        // and also by the select box.
        // We need to ignore automatic selects but would like to present the
        // call to action for select box requests.
        if (query.startsWith('select ')) query = query.slice('select '.length);
        const words = query.split(' ');
        const first = words[0];
        // This attempts to cover the export case: ligand l ; ligand l or compound c ; ...
        const looksLikeExport = (first === 'ligand' || first === 'compound')
            && (words.length % 3 === 2);
        const ignore = (
            first === 'none' // automatically sent at various times
            || query.indexOf('and not ligand') > -1 // simulation prep
            || looksLikeExport
        );

        return ignore ? undefined : showAndDoPreviewModeError(serverCmd);
    },
    SelectAtom: (serverCmd, requestId, connector, atom, optionKeys={}) => {
        console.log(`Click on ${atom ? atom.uniqueID : 'background'} with ${JSON.stringify(optionKeys)}`);
        const storage = connector.storage;
        const workspace = storage.workspace;
        const previouslySelected = workspace.getSelectedAtoms();

        // This code uses setAtomSelectedMiddleman which is called when decoding selection bitmaps
        // from the server. setAtomSelectedMiddleman does two things:
        // 1) Updates the Workspace registry of selected atoms
        // 2) Updates the 3d atoms

        // Clear selection, but not if ctrl / shift key is down,
        // since that means 'extend selection'
        const clearSelection = () => {
            if (!optionKeys.ctrlKey && !optionKeys.shiftKey) {
                previouslySelected.forEach((x) => setAtomSelectedMiddleman(x, false));
            }
        };

        if (atom) {
            if (workspace.isSelectedAtom(atom)) {
                if (optionKeys.ctrlKey) {
                    // Ctrl-clicking a selected atom turns it off
                    setAtomSelectedMiddleman(atom, false);
                } else {
                    // Clicking a selected atom expands to include the whole residue.
                    // In server mode, repeated clicks continue to expand, but
                    // this doesn't do that yet.
                    const parent = atom.residue || atom.fragment;
                    const parentAtoms = parent?.atoms || [];
                    parentAtoms.forEach((x) => setAtomSelectedMiddleman(x, true));
                }
            } else {
                // Clicking an unselected atom selects it ()
                clearSelection();
                setAtomSelectedMiddleman(atom, true);
            }
        } else {
            clearSelection();
        }
    },
    LoadStarterCompounds: (serverCmd, requestId, connector) => {
        const storage = connector.storage;
        const samples = storage.StarterCompounds;
        if (samples) {
            const responses = [];
            for (const sample of samples) {
                const cmpdSpec = sample.name;
                const atomInfo = toByteArray(sample.atomgroup_info);
                const cmpdData = toByteArray(sample.encoded_compound);
                responses.push(binaryResponseObj(ResponseIds.AtomGroupInfo, atomInfo, true));
                responses.push(binaryResponseObj(ResponseIds.Compound, cmpdData, true));
                responses.push(responseObj(ResponseIds.Compound2D, sample.compound_2d));
                responses.push(responseObj(ResponseIds.EnergiesForLigandText, {
                    cmpdSpec,
                    interactionEnergies: sample.binding_energies.interaction_energies,
                    internalEnergies: sample.binding_energies.internal_energies,
                }));
                responses.push(responseObj(ResponseIds.SolvationForLigandText,
                    { cmpdSpec, ...sample.solvation_energies }));
            }
            return responses;
        } else {
            return errorResponse(serverCmd, 'No sample compounds available');
        }
    },
    ReplaceGroup: null,
    FindModifications: null,
    EnergyMinimize: null,
    SelectModification: null,
    ExportSelection: (serverCmd, requestId, connector, format, extraArgs) => {
        const storage = connector.storage;
        // Issues with this:
        // 1. If there are atoms selected, it should show a warning instead of exporting compounds
        // 2. When exporting compounds, this logic will not be called because the export is handled
        //    in ConnectedDataCmds exportCompoundsToFormat.
        if (format === 'mol' || format === 'smi' || format === 'sdf' || format === 'smiNoName') {
            let compounds = storage.workspace.getSelectedCompounds();
            if (compounds.length === 0) compounds = [storage.workspace.getActiveCompound()];
            if (format === 'mol' && compounds.length > 1) {
                return errorResponse(serverCmd, `Mol format can only write one molecule, ${compounds.length} selected.`);
            }
            compounds = compounds.filter((cpd) => App.getDataParents(cpd).connector === connector);
            if (compounds) {
                const dataFn = (c) => {
                    switch (format) {
                        case 'mol': return c.getMol2000();
                        case 'smi': return c.getSmiles();
                        case 'smiNoName': return c.getUnnamedSmiles();
                        case 'sdf': return c.getSDF();
                        default: return `Unspecified mol data for ${c.resSpec}`;
                    }
                };
                const result = compounds.reduce((list, c) => list.concat(dataFn(c)), []);
                const responseHeader = `${format} ${extraArgs}`; // expected in response
                const responseData = `${responseHeader}\n${result.join('\n')}`;
                return responseObj(ResponseIds.ExportContents, responseData);
            }
        } else {
            return showAndDoPreviewModeError(serverCmd, 'Export to other than mol/sdf/smiles');
        }
        return undefined;
    },
    MoleculeServiceRequest: (serverCmd, requestId, connector, serviceId, requestObjIn) => {
        const requestObj = typeof (requestObjIn) === 'string'
            ? JSON.parse(requestObjIn) : requestObjIn;
        // The CDDV system in the client sends a check_setup request when opening the form
        // In that case, send a mock response indicating we're not connected to CDDV
        if (serviceId === 'CDDV') {
            if (requestObj.operation === 'check_setup') {
                return responseObj(ResponseIds.MolServiceRequestResult,
                    { success: 0, request: requestObj, service: serviceId });
            } else {
                return showAndDoPreviewModeError(serverCmd, 'CDD Vault integration');
            }
        } else {
            return showAndDoPreviewModeError(serverCmd, `${serviceId} integration`);
        }
    },
    LoadPdbId: null,
    GetMoleculeProperties: async (serverCmd, requestId, connector, spec) => {
        const storage = connector.storage;
        // Ligand molprops are in separate files on the server
        // Sample compound props are stored in the Sample compound file
        const sample = getSampleCmpdBySpec(spec, storage);
        if (sample) {
            return responseObj(ResponseIds.GetMoleculeProperties, sample.mol_props);
        } else {
            return fetchResponse(serverCmd, ResponseIds.GetMoleculeProperties,
                storage.ProjectCase, spec);
        }
    },
    FragSearchRadius: null,
    LoadPdbString: null,
};

function responseObj(responseId, data) {
    return { responseId, data };
}

function binaryResponseObj(responseId, dataIn, addOpId) {
    let data = dataIn;
    if (addOpId) {
        const ab = new Uint8Array(data.byteLength + 2);
        ab.set([responseId, 0], 0);
        ab.set(data, 2);
        data = ab.buffer;
    }
    return { responseId, data };
}

/**
 * @description Construct an error response object.
 * @param stringOrServerCmd serverCmd - command name for the error data
 * The error data is expected to be in the form:
 *     <cmd>[ <args>], <msg>
 * This uses a string passed in, or extracts the cmdName from a ServerCmd object
 */
function errorResponse(serverCmd, msg) {
    const cmd = typeof (serverCmd) === 'string' ? serverCmd : serverCmd.cmdName;
    const data = `${cmd}, ${msg}`;
    return responseObj(ResponseIds.Error, data);
}

/**
 * @description Tell the user to sign up!
 * @param {*} serverCmd
 */
function callForAction(serverCmd, detailIn) {
    const detail = (detailIn) ? `(${detailIn}) `: '';
    const msg = `This action ${detail}requires a Boltzmann Maps account.<br>
    <br>Please login or create a free account to continue.<br>
    <br>Click <a href="/plans" target="_blank">here</a> to learn more about subscription options.`;
    EventBroker.publish('sessionError', {
        errTitle: 'Log In or Sign Up to continue',
        errMsg: msg,
        errType: SessionErrorType.UnavailableInStaticMode,
    });
}

function showAndDoPreviewModeError(serverCmd, msg) {
    callForAction(serverCmd, msg);
    return errorResponse(serverCmd, makePreviewModeError(msg));
}

/**
 * @description Allow command responses to add additional commands to the response list.
 * In particular, Solute responses need to inject Energy and Solvation data for each ligand.
 * @param ServerCmd serverCmd - ServerCmd object for the current command
 * @param string requestId - request id for the current command
 * @param ResponseId responseId - ResponseId for the current command
 * @param object responseData - Response data for the current command
 * @returns {{ newCmds: {responseId, data}[] }} - special responses to insert into the response list
 */
async function specialHandling(serverCmd, requestId, responseId, responseData) {
    const newCmds = [];
    if (serverCmd === ServerCmds.GetCaseFiles) {
        if (responseId === ResponseIds.Solute) {
            const { caseID, compounds } = responseData;
            if (caseID) { // Make sure we loaded the protein successfully
                for (const cmpd of compounds) {
                    const cmpdSpec = cmpd.resSpec;
                    const view2d = await fetchResponse(serverCmd,
                        ResponseIds.Compound2D, caseID, cmpdSpec);
                    const energy = await fetchResponse(serverCmd,
                        ResponseIds.EnergiesForLigandText, caseID, cmpdSpec);
                    const solvation = await fetchResponse(serverCmd,
                        ResponseIds.SolvationForLigandText, caseID, cmpdSpec);
                    newCmds.push(view2d, energy, solvation);
                }
                // Available fragments may queue up a bunch of fragment image downloads.
                // We want this to happen after everything else has loaded (esp Compound2D),
                // so it is fetched here after the compounds, instead of in GetCaseFiles above.
                const availableFragments = await fetchResponse(serverCmd,
                    ResponseIds.AvailableFragments, caseID);
                if (availableFragments) {
                    newCmds.push(availableFragments);
                }
            }
        }
    }
    return { newCmds };
}

function getSampleCmpdBySpec(cmpdSpec, storage) {
    if (!storage.StarterCompounds || storage.StarterCompounds.length === 0) {
        return null;
    }
    return storage.StarterCompounds.find((x) => x.name === cmpdSpec);
}

/**
 * Tcl Format:
 * {
 *  {6 internal totals}
 *  {6 arrays of internal details}
 * }
 * {
 *  {2 interaction totals}
 *  {2 arrays of interaction details}
 * }
 * Detail arrays are probably all empty
 */
function processEnergyCache(cmpdSpec, energyDataTcl) {
    // Add the extra braces so json parser treats it as a list
    const arr = naiveParseTclList(`{${energyDataTcl}}`);
    return JSON.stringify({ cmpdSpec, internalEnergies: arr[0], interactionEnergies: arr[1] });
}

/**
 * Tcl format:
 * {4 solv totals, 8 psa values} {dict: atomid {dGs dGs_bound} ...}
 */
function processSolvationCache(cmpdSpec, solvationTcl) {
    // Add the extra braces so json parser treats it as a list
    const arr = naiveParseTclList(`{${solvationTcl}}`);
    // Extract solv and PSA values
    const solvPSA = arr[0];
    const totals = solvPSA.slice(0, 4);
    const PSA = solvPSA.slice(4, 12);

    // Extract atom by atom detail
    const atomDataDict = arr[1];
    const atomDetail = [];
    // process tcl dict: atomId { dGs dGs_bound} ...
    let atomId = null;
    for (const elt of atomDataDict) {
        if (atomId == null) {
            atomId = elt;
        } else {
            const [dGs, dGs_bound] = elt;
            atomDetail.push([atomId, dGs, dGs_bound]);
            atomId = null;
        }
    }

    return JSON.stringify({
        cmpdSpec, totals, detail: { unsorted: atomDetail }, PSA,
    });
}

/**
 * @description Naive parse of tcl list.
 * Replaces {} with [] and space with ,
 * Will produce wrong results if missing spaces or extra spaces.
 * Being able to properly handle spaces requires stepping through character by character.
 * @param {*} tcl
 */
export function naiveParseTclList(tcl) {
    return JSON.parse(tcl.replace(/\{/g, '[').replace(/\}/g, ']').replace(/ /g, ','));
}

class StaticDataStorage {
    constructor() {
        this.reset();
    }

    setWorkspace(w) { this.workspace = w; }

    reset() {
        this.starterCompounds = null;
        this.projectCase = null;
    }

    set StarterCompounds(val) { this.starterCompounds = val; }
    get StarterCompounds() { return this.starterCompounds; }

    set ProjectCase(val) { this.projectCase = val; }
    get ProjectCase() { return this.projectCase; }
}

const StaticModePermissions = {
    permissions: {
        dock: true,
        water_run: true,
        frag_run: true,
        frag_search: true,
    },
    operationCounts: {
        limits: {
            dock: 0,
            water_run: 0,
            frag_run: 0,
        },
        activity: {
            dock: 0,
            water_run: 0,
            frag_run: 0,
        },
        remaining: {
            dock: 0,
            water_run: 0,
            frag_run: 0,
        },
    },
};
