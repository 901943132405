import CaseDataCollection from './model/CaseDataCollection';
import { PermissionManager } from './PermissionManager';
import { BFDServerInterface } from './server/BFDServerInterface';
import { Log } from './Log';
/**
 * This class bundles a connection (either a BFD Server connection or a static mode connection)
 * with any associated data.
 */
export default class DataConnection {
    /** @param {BFDServerInterface} connector */
    constructor(connector) {
        /** @type {BFDServerInterface} */
        this.connector = connector;
        /** @type {CaseDataCollection} */
        this.caseDataCollection = new CaseDataCollection(this);
        /** @type {PermissionManager} */
        this.permissionManager = new PermissionManager(this);
        this.connector.setDataConnection(this);
        this.connector.setDecoderStorage(this.caseDataCollection.atomInfoMaps);
    }

    getCaseDataCollection() { return this.caseDataCollection; }
    getConnector() { return this.connector; }
    getPermissionManager() { return this.permissionManager; }
    get() {
        return { connector: this.connector, caseDataCollection: this.caseDataCollection };
    }

    getMode() { return this.connector.getMode(); }
    getLabel() { return this.connector.getLabel(); }

    zap(usePromise=true) {
        Log.info(`DataConnection: zapping connection ${this.connector.getLabel()}. Waiting for server response = ${usePromise}`);
        this.getCaseDataCollection().reset();
        return usePromise
            ? this.getConnector().cmdZapAll()
            : Promise.resolve(this.getConnector().zapNoWait());
    }
}
