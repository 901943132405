/* speech.js
 *
 * Experimental voice command features.
 * Good reference site: https://bkardell.com/blog/Listen-Up.html
*/

// Todo:
//   Process word stream.

import { UserActions } from './cmds/UserAction';

// if (!('webkitSpeechRecognition' in window))

let SpeechRecognition;
let SpeechGrammarList;
let SpeechRecognitionEvent;

if (typeof window !== 'undefined') {
    SpeechRecognition = SpeechRecognition || window?.webkitSpeechRecognition || null;
    SpeechGrammarList = SpeechGrammarList || window?.webkitSpeechGrammarList || null;
    SpeechRecognitionEvent = SpeechRecognitionEvent || window?.webkitSpeechRecognitionEvent || null;
}

// Not clear this is even used.
const words = [
    'display', 'view', 'select', 'reset',
    'stop', 'done', 'exit', 'off',
    'protein', 'ligand', 'compound', 'atom',
    'surface', 'spheres', 'spacefill', 'sticks', 'ball-and-stick', 'wires', 'wireframe', 'hide',
    'cartoon', 'ribbons',
    'hot-spot', 'default',
];
const grammar = `#JSGF V1.0; grammar actions; public <actions> = ${words.join(' | ')};`;

let recognition;

function initRecognition() {
    recognition = new SpeechRecognition();
    const speechRecognitionList = new SpeechGrammarList();

    // 1 arg is priority of the added grammar.
    speechRecognitionList.addFromString(grammar, 1);

    recognition.grammars = speechRecognitionList;
    recognition.continuous = true;
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    // recognition.onstart = function() { ... }

    recognition.onresult = (event) => {
        const last = event.results.length - 1;
        const lastWord = event.results[last][0].transcript;
        if (lastWord.length === 0) return;
        const wordList = lastWord.split(' ').filter((c) => c !== '');
        console.log(`Words: ${lastWord} strlen ${lastWord.length}, ${wordList.length} words`);
        interpretWords(wordList);
        console.log(`Confidence: ${event.results[0][0].confidence}`);
    };

    recognition.onerror = (evt) => {
        if (evt.error === 'not-allowed') {
            // Do a jalert?
            jAlert("BMaps can't listen if you don't grant me permission :(", 'Speech Input');
        } else {
            console.log(`Got a recognition error: ${evt.error}`);
        }
        speechOff();
    };

    recognition.onend = (evt) => {
        console.log('Recognition ended.');
        speechOff();
    };
}

let greetingDelivered = false;
function speechOn() {
    if (!greetingDelivered) {
        SayGreeting();
        greetingDelivered = true;
    }
    $('#speech_button_off').hide();
    $('#speech_button_on').show();
    recognition.start();
}

function speechOff() {
    $('#speech_button_on').hide();
    $('#speech_button_off').show();
    recognition.stop();
}

function isSpeechAvailable() {
    return (SpeechRecognition && SpeechGrammarList && SpeechRecognitionEvent);
}

function enableSpeechCmds() {
    if (!isSpeechAvailable()) return;
    // Add microphone on/off buttons.
    initRecognition();

    $('#topbar-right-side').prepend(
        `<button id='speech_button_on' class="topbar-button" style="color:green;"
            title="Disable voice control">
             <i class="fa fa-microphone"></i>
         </button>
         <button id='speech_button_off' class="topbar-button"
            title="Enable voice control">
             <i class="fa fa-microphone-slash"></i>
         </button>
        `,
    );
    $('#speech_button_off').show();
    $('#speech_button_on').hide();
    $('#speech_button_off').click((evt) => {
        speechOn();
        console.log('Speech recognition enabled.');
    });
    $('#speech_button_on').click((evt) => {
        speechOff();
        console.log('Speech recognition disabled.');
    });

    console.log('Speech recognition initialized.');
}

async function SayGreeting() {
    const getVoices = () => new Promise((resolve) => {
        let voices = speechSynthesis.getVoices();
        if (voices.length) {
            resolve(voices); // immediately available
            return;
        }
        speechSynthesis.onvoiceschanged = () => { // otherwise, have to wait for it
            voices = speechSynthesis.getVoices();
            speechSynthesis.onvoiceschanged = null;
            resolve(voices);
        };
    });

    const voices = await getVoices();

    const greeting = new SpeechSynthesisUtterance('Greetings. Welcome to B Maps.  Get my attention by saying bee. Follow that with a command like view, display, or select.');
    greeting.volume = 0.1;
    let foundVoice = false;
    for (const voice of voices) {
        console.log(`voice option: '${voice.name}' language '${voice.lang}'`);
        if (!foundVoice && (voice.name === 'Fiona' || voice.name === 'Google UK English Female')) {
            greeting.voice = voice;
            console.log(`Setting voice to ${voice.name}`);
            foundVoice = true;
        }
    }
    console.log('Greeting.');
    speechSynthesis.speak(greeting);
}

/* The SpeechRecognitionEvent.results property returns a SpeechRecognitionResultList
 * object containing SpeechRecognitionResult objects. It has a getter so it can be
 * accessed like an array � so the [last] returns the SpeechRecognitionResult at the last
 * position. Each SpeechRecognitionResult object contains SpeechRecognitionAlternative
 * objects that contain individual recognised words. These also have getters so they can
 * be accessed like arrays � the [0] therefore returns the SpeechRecognitionAlternative at
 * position 0. We then return its transcript property to get a string containing the
 * individual recognised result as a string, set the background color to that color, and
 * report the color recognised as a diagnostic message in the UI. */

/* Ideas:
 * Activation word: B (short), CP (Conifer Point), CP3O (fun), BMaps, Hey?
 * Verbs: view, display, show, zoom, select, minimize, dock, export, edit(?), replace, find
 * Nouns: protein, ligand, style phrases (sticks, etc.), in/out, highlight phrases ("all
 * hydrogens", "polar hydrogens", "no hydrogens"), selected compound, etc.
 */

function interpretWords(wordList) {
    switch (wordList.length) {
        case 1:
            console.log(`first ${wordList[0]}`);
            switch (wordList[0].toLowerCase()) {
                case 'protein':
                    UserActions.SetView('protein');
                    break;
                case 'ligament':
                case 'ligand':
                    UserActions.SetView('ligand');
                    break;
                case 'reset':
                    UserActions.ResetView();
                    break;
                case 'spheres':
                case 'spears':
                    UserActions.SetDisplayStyle('spacefill');
                    break;
                case 'wires':
                    UserActions.SetDisplayStyle('wireframe');
                    break;
                case 'styx':
                case 'sticks':
                    UserActions.SetDisplayStyle('sticks');
                    break;
                case 'ribbons':
                case 'cartoon':
                    UserActions.SetDisplayStyle('cartoon');
                    break;
                case 'surface':
                    UserActions.SetDisplayStyle('surface');
                    break;
                case 'done':
                case 'end':
                case 'stop':
                case 'exit':
                case 'off':
                    speechOff();
                    break;
                case 'hotspot':
                    UserActions.SetHotspots(true);
                    break;
                // no default
            }
            break;
        default:
            switch (wordList[0].toLowerCase()) {
                case 'hot':
                    if (wordList.length > 2) {
                        switch (wordList[2].toLowerCase()) {
                            case 'off':
                                UserActions.SetHotspots(false);
                                return;
                            case 'on':
                                UserActions.SetHotspots(true);
                                return;
                            // no default
                        }
                    }
                    UserActions.SetHotspots(true);
                    break;
                case 'hotspot':
                case 'hotspots':
                    switch (wordList[1].toLowerCase()) {
                        case 'off':
                            UserActions.SetHotspots(false);
                            return;
                        case 'on':
                            UserActions.SetHotspots(true);
                            return;
                        // no default
                    }
                    UserActions.SetHotspots(true);
                    break;
                case 'van':
                case 'space':
                    UserActions.SetDisplayStyle('spacefill');
                    break;
                // no default
            }
    }
}

/*
recognition.onresult = function(event) {
var interim_transcript = '';

for (var i = event.resultIndex; i < event.results.length; ++i) {
    if (event.results[i].isFinal) {
        final_transcript += event.results[i][0].transcript;
    } else {
        interim_transcript += event.results[i][0].transcript;
    }
}
final_transcript = capitalize(final_transcript);
final_span.innerHTML = linebreak(final_transcript);
interim_span.innerHTML = linebreak(interim_transcript);
};
}
*/

/* for reset
recognition.onspeechend = function() {
    recognition.stop();
}

For words recognized by not with grammar (not clear this is implemented).
recognition.onnomatch = function(event) {
    console.log('I didnt recognise that.');
}
recognition.onerror = function(event) {
    console.log('Error occurred in recognition: ' + event.error);
}
*/

// Loader interface

export class SpeechCmds {
    static Init() {
        console.log('Speech init called.');
        enableSpeechCmds();
    }
}
