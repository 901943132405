import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
// Material UI
import Collapse from '@mui/material/Collapse';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import MuiRadioGroup from '@mui/material/RadioGroup';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// Local
import { DockingReference, boxSpecs } from '../../../data_tools';
import DialogContentSection from '../../common/DialogContentSection';
import { DialogSubmitButton, DialogCancelButton } from '../../common/DialogActionButtons';
import { SelectWithFormik } from '../../common/Select';
import { withTooltip, withFormik } from '../../common/helpers';
import { FormikIncrementDecrementInput } from '../../common/IncrementDecrementInput';
import { defaultDockSpeed } from './constants';
import { SidePanelSection } from '../../info_display/SidePanel';

const RadioGroup = styled(MuiRadioGroup)`
  width: 100%;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: 0px;
`;

function DockingReferencesSelect({ dockingReferences, setSelectedRefIndex, ...rest }) {
    const options = dockingReferences.map((r, i) => {
        const title = r.type === DockingReference.Types.hotspot
            ? 'Energy is the averge excess chemical potential of the hotspot fragments'
            : '';

        return (
            <option key={r.description} value={i} title={title}>{r.description}</option>
        );
    });

    // When a type of docking reference isn't available, dummy options in
    // the selector give an affordance that the possibility is there.
    const dummyInfo = [
        {
            type: DockingReference.Types.selected,
            text: 'Selected atoms',
            title: 'Click on atoms in the 3D workspace to define a docking center from selected atoms.',
        }, {
            type: DockingReference.Types.compound,
            text: 'Crystal ligand',
            title: 'This protein structure has no crystal ligand to use as a docking reference',
        }, {
            type: DockingReference.Types.hotspot,
            text: 'Hotspot',
            title: 'There are no hotspots to use as docking references. You can generate hotspots after running fragment simulations.',
        },
    ];
    // Add the dummy options if neccessary
    dummyInfo.forEach(({ type, text, title }) => {
        const found = dockingReferences.find((r) => r.type === type);
        if (!found) {
            options.push(
                <option key={`DummyOption:${text}`} disabled title={title}>{text}</option>,
            );
        }
    });

    useEffect(() => {
        // Automatically choose "Selected Atoms" if it's the only option.
        // If the only option is a ligand or hotspot, user has to pick,
        // so they're aware of the option.
        // Ideally, it should remember the center specified for a given
        // structure across docks and select that the next time
        if (dockingReferences.length === 1
          && dockingReferences[0].type === DockingReference.Types.selected) {
            setSelectedRefIndex(0);
        }
    }, [dockingReferences]);

    return (
        <SelectWithFormik
            {...rest}
            size="small"
        >
            <option value="" disabled>Choose docking center...</option>
            {options}
        </SelectWithFormik>
    );
}

const RadioGroupWithValidation = withTooltip(withFormik(RadioGroup));
const ReferencesSelectWithValidation = withTooltip(
    DockingReferencesSelect
);
const IncrementDecrementWithValidation = withTooltip(
    withFormik(FormikIncrementDecrementInput)
);

const DockingTips = styled(({ className }) => {
    const tips = [
        'Docking works best when components have 4 or fewer rotatable bonds.',
        "Please click Dock only when you're ready. You will not be able to to work on your compound during the docking process.",
    ];

    return (
        <DialogContentSection title="Tips" disablePadding collapsible defaultExpand>
            <List dense disablePadding className={className}>
                {tips.map((tip) => (
                    <ListItem key={tip}>
                        <ListItemText>
                            {tip}
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </DialogContentSection>
    );
})`
  list-style: disc;
  & .MuiTypography-root {
    display: list-item;
  }
`;

function FormActions({ handleClose, formId }) {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <DialogActions>
            <DialogCancelButton
                fullWidth={isMobile}
                onClick={handleClose}
            >
                Cancel
            </DialogCancelButton>
            <DialogSubmitButton fullWidth={isMobile} form={formId}>Dock</DialogSubmitButton>
        </DialogActions>
    );
}

// TODO: for RadioGroup width, prefer to use global theme to set
export default function DockingForm({
    dockingReferences, handleSubmit, handleClose,
}) {
    const formId = 'docking-form';

    const poseCountMin = 1;
    const poseCountMax = 10;

    const dockingProgramsWithDockingRef = ['autodock'];
    const dockingProgramsWithBoxSize = ['autodock'];
    const dockingProgramsWithSpeedArg = ['autodock'];

    const availableDockingPrograms = [
        {
            id: 'autodock',
            label: 'AutoDock Vina',
            disabled: dockingReferences.length === 0,
            title: dockingReferences.length === 0
                ? 'Traditional docking, requiring a bounding box. The target has no crystal ligands or hotspots to use for a bounding box reference. Please select an atom or residue to be the center of the bounding box before docking.'
                : 'Traditional docking',
        },
        {
            id: 'diffdock',
            label: 'DiffDock',
            disabled: false,
            title: 'A new machine-learning-based docking algorithm',
        },
    ];

    function validateSelectedRefIndex(idx, dockingProgram) {
        if (!dockingProgramsWithDockingRef.includes(dockingProgram)) {
            return undefined;
        }

        return idx === '' ? 'Please select an option!' : undefined;
    }

    function validatePoseCount(count) {
        return count < poseCountMin || count > poseCountMax ? `Invalid pose count. Valid range: ${poseCountMin} - ${poseCountMax}.` : undefined;
    }

    function validateBoxSize(size, otherFormValues) {
        const { boxSizeType } = otherFormValues;
        return boxSizeType === 'custom' && (size < boxSpecs.SIZE_MIN || size > boxSpecs.SIZE_MAX) ? `Invalid box size. Valid range: ${boxSpecs.SIZE_MIN} - ${boxSpecs.SIZE_MAX}.` : undefined;
    }

    function formValidate(formValues) {
        const errors = {};
        const {
            dockingProgram,
            // autodock params
            selectedRefIndex, poseCount, boxSize, boxSizeType,
        } = formValues;
        let val;

        if (!dockingProgram) {
            errors.dockingProgram = 'Please select a docking program!';
        }

        val = validateSelectedRefIndex(selectedRefIndex, dockingProgram);
        if (val) {
            errors.selectedRefIndex = val;
        }

        val = validatePoseCount(poseCount);
        if (val) {
            errors.poseCount = val;
        }

        val = validateBoxSize(boxSize, { boxSizeType });
        if (val) {
            errors.boxSize = val;
        }

        return errors;
    }

    function getStartingDockingProgram() {
        const entry = availableDockingPrograms.find((dp) => !dp.disabled);
        return entry?.id || '';
    }

    return (
        <>
            <Formik
                initialValues={{
                    dockingProgram: getStartingDockingProgram(),
                    poseCount: 6,

                    // autodock params
                    selectedRefIndex: '',
                    dockspeed: defaultDockSpeed,
                    boxSizeType: 'auto',
                    boxSize: 20,
                    // diffdock params
                    keepHs: false,
                    keepSrc3D: false,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
                validate={formValidate}
            >
                {/* Formik Render Function with parameters from the formik "bag" */}
                {({
                    setFieldValue, getFieldProps, values: {
                        dockingProgram,
                        // autodock params
                        boxSizeType,
                        // diffdock params
                        keepHs,
                        keepSrc3D,
                    },
                }) => (
                    <Form id={formId}>
                        <DialogContentSection title="Docking Program" disablePadding collapsible defaultExpand>
                            <SidePanelSection>
                                <RadioGroupWithValidation
                                    name="dockingProgram"
                                    label="Docking Program"
                                    tooltip="AutoDock Vina is the traditional docking program. DiffDock uses a different algorithm, accelerated by machine learning."
                                >
                                    {availableDockingPrograms.map((dp) => (
                                        <FormControlLabel
                                            key={dp.id}
                                            value={dp.id}
                                            label={dp.label}
                                            title={dp.title}
                                            disabled={dp.disabled}
                                            control={<Radio size="small" />}
                                        />
                                    ))}
                                </RadioGroupWithValidation>
                            </SidePanelSection>
                        </DialogContentSection>
                        { dockingProgramsWithDockingRef.includes(dockingProgram) && (
                            <DialogContentSection title="Box Center" disablePadding>
                                <SidePanelSection>
                                    <ReferencesSelectWithValidation
                                        name="selectedRefIndex"
                                        dockingReferences={dockingReferences}
                                        setSelectedRefIndex={(value) => setFieldValue('selectedRefIndex', value)}
                                        tooltip="Select atoms or residues in the 3D workspace to use a custom center for docking. Press the 'D' key in the 3D workspace to view the docking bounding box."
                                    />
                                </SidePanelSection>
                            </DialogContentSection>
                        )}
                        <DialogContentSection
                            title="Advanced Parameters"
                            disablePadding
                            collapsible
                        >
                            <SidePanelSection>
                                { dockingProgramsWithBoxSize.includes(dockingProgram) && (
                                    <>
                                        <RadioGroupWithValidation
                                            name="boxSizeType"
                                            label="Box edge size:"
                                            tooltip="Box size is automatically calculated for each docked compound, or you can specify a custom size (1-40 Å)."
                                            optimize={false}
                                        >
                                            <FormControlLabel value="auto" control={<Radio size="small" />} label="Auto" />
                                            <FormControlLabel value="custom" control={<Radio size="small" />} label="Specify edge size" />
                                        </RadioGroupWithValidation>
                                        <Collapse in={boxSizeType === 'custom'} style={{ width: '100%' }}>
                                            <IncrementDecrementWithValidation
                                                name="boxSize"
                                                type="number"
                                                min={boxSpecs.SIZE_MIN}
                                                max={boxSpecs.SIZE_MAX}
                                                validate={validateBoxSize}
                                            />
                                        </Collapse>
                                    </>
                                )}
                                { dockingProgramsWithSpeedArg.includes(dockingProgram) && (
                                    <RadioGroupWithValidation
                                        name="dockspeed"
                                        label="Docking speed:"
                                        tooltip="Docking speed sets the exhaustiveness of the simulation. &quot;Faster&quot; is faster but less accurate."
                                        optimize={false}
                                    >
                                        <FormControlLabel value="fast" control={<Radio size="small" />} label="Normal" />
                                        <FormControlLabel value="faster" control={<Radio size="small" />} label="Faster" />
                                    </RadioGroupWithValidation>
                                )}
                                <IncrementDecrementWithValidation
                                    name="poseCount"
                                    label={`Number of poses (${poseCountMin} - ${poseCountMax})`}
                                    type="number"
                                    min={poseCountMin}
                                    max={poseCountMax}
                                    validate={validatePoseCount}
                                    ContainerComponentProps={{
                                        style: { marginTop: '1ex' },
                                    }}
                                />
                                { dockingProgram === 'diffdock' && (
                                    <>
                                        <FormControlLabel
                                            control={<Checkbox {...getFieldProps('keepHs')} checked={keepHs} size="small" />}
                                            label="Keep Hydrogens"
                                            title="Diffdock docks without hydrogens by default. Check this to include them. Note: at the moment this seems to produce worse poses"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox {...getFieldProps('keepSrc3D')} checked={keepSrc3D} size="small" />}
                                            label="Start docking with provided ligand conformation"
                                            title="Diffdock recalculates the 3D pose by default. Check this to disable that."
                                        />
                                    </>
                                )}
                            </SidePanelSection>
                        </DialogContentSection>
                    </Form>
                )}
            </Formik>
            <DockingTips />
            <FormActions formId={formId} handleClose={handleClose} />
        </>
    );
}
