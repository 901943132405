import { IconButton } from '@mui/material';
import { useFormikContext } from 'formik';

/**
 * Create an IconButton that will submit a Formik form
 * @param {{ children }} props
 * @returns
 */
export default function FormikIconSubmit({ children }) {
    const { submitForm } = useFormikContext();
    return (
        <IconButton onClick={submitForm}>
            {children}
        </IconButton>
    );
}
