import { useState } from 'react';

import styled from 'styled-components';
// Material UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Collapse from '@mui/material/Collapse';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import Divider from '@mui/material/Divider';
// Local
import ConditionalWrapper from './ConditionalWrapper';

/**
 * Different sections in a Dialog. Intended to be used a child component of Dialog.
 * User can have have DialogContentSection as a child of DialogContentSection (ie.
 * a section has a subsection).
 */

const DialogContentSection = styled(({
    className,
    children,
    title,
    collapsible,
    defaultExpand,
    dividers,
    titleVariant='h6',
    titleUpperCase=true,
}) => {
    const [expand, setExpand] = useState(defaultExpand);
    const optionProps = {};

    function handleClick() {
        setExpand((exp) => !exp);
    }

    function wrapper(componentChildren) {
        return (
            <Collapse in={expand}>
                {componentChildren}
            </Collapse>
        );
    }

    function handleKeyUp(e) {
        const { nativeEvent: { code } } = e;
        if (code === 'Space' || code === 'Enter') {
            setExpand((exp) => !exp);
        }
    }

    if (collapsible) {
        optionProps.onClick = handleClick;
        optionProps.role = 'button';
        optionProps['aria-expanded'] = expand;
        optionProps['wrap'] = 'nowrap';
        optionProps['tabIndex'] = 0;
        optionProps['onKeyUp'] = handleKeyUp;
    }

    return (
        <div className={className}>
            {dividers && <Divider />}
            {
              title && (
              <Grid container justifyContent="space-between" alignItems="center" className="tabpanelsection__title" {...optionProps}>
                  <Typography variant={titleVariant} noWrap>
                      {titleUpperCase ? title.toUpperCase() : title}
                  </Typography>
                  {collapsible && (expand ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />)}
              </Grid>
              )
            }
            <ConditionalWrapper condition={collapsible} wrapper={wrapper}>
                <DialogContent>
                    {children}
                </DialogContent>
            </ConditionalWrapper>
            {dividers && <Divider />}
        </div>
    );
})`
  padding: 0.5ex 0;

  & .tabpanelsection__title {
    cursor: ${({ collapsible }) => (collapsible ? 'pointer' : 'auto')};

    & .MuiTypography-root {
      font-weight: 700;
    }
  }

  & .MuiDialogContent-root {
    ${({ disablePadding, disablePaddingTop }) => ((disablePadding || disablePaddingTop) ? 'padding-top: 0;' : '')}
    ${({ disablePadding, disablePaddingRight }) => ((disablePadding || disablePaddingRight) ? 'padding-right: 0;' : '')}
    ${({ disablePadding, disablePaddingBottom }) => ((disablePadding || disablePaddingBottom) ? 'padding-bottom: 0;' : '')}
    ${({ disablePadding, disablePaddingLeft }) => ((disablePadding || disablePaddingLeft) ? 'padding-left: 0;' : '')}
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .MuiDialogContent-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

DialogContentSection.defaultProps = {
    disablePadding: false,
    disablePaddingTop: false,
    disablePaddingRight: false,
    disablePaddingBottom: false,
    disablePaddingLeft: false,
    defaultExpand: false,
    collapsible: false,
    dividers: false,
};

export default DialogContentSection;
