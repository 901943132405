import { useEffect, useCallback } from 'react';
import { EventBroker } from '../../../eventbroker';

/**
 * Custom React hook to encapsulate EventBroker subscription / unsubscription
 * on component mount / unmount.
 * This will run whenever the handler changes, so it is recommended
 * to put the handler in a useCallback in the calling function.
 * @param {string} evtName
 * @param {Function<string, object?>} handler
 */
export default function useEventBrokerSubscription(evtName, handler) {
    useEffect(() => {
        EventBroker.subscribe(evtName, handler);
        return () => EventBroker.unsubscribe(evtName, handler);
    }, [evtName, handler]);
}

/**
 * Custom React hook to update local state in response to EventBroker events.
 * The optional fourth argument is a function to calcuate the new state based on the
 * EventBroker event argument and the old state. If the function is not provided, the
 * EventBroker event argument is used for the new state.
 * This will run whenever the state or getNewState function changes, so if getNewState
 * is used, it is recommended to wrap the definition in useCallback in the calling function.
 * @param {string} evtName
 * @param {*} state state variable from useState
 * @param {Function} setState setState variable from useState
 * @param {Function<*, *>?} getNewState (event arg, oldState) => newState
 * @example ```
 *      const [open, setOpen] = useState(false);
 *      useEventBrokerStateHandling('openMyForm', open, setOpen);
 *      // ... somewhere else in the code ...
 *      // This form will pass the event arg `true` into the state
 *      EventBroker.publish('openMyForm', true);
 * ```
 * @example ```
 *      const [open, setOpen] = useState(false);
 *      const getNewOpen = useCallback((evtArg, oldOpen) => !oldOpen, []);
 *      useEventBrokerStateHandling('toggleMyForm', open, setOpen, getNewOpen);
 *      // ...
 *      // This form will calculate the new state based on the event arg and prev state.
 *      // Simple toggling just needs the old state; the event arg isn't needed.
 *      EventBroker.publish('toggleMyForm');
 * ```
 */
export function useEventBrokerStateHandling(evtName, state, setState, getNewState) {
    const handler = useCallback((handlerEvtName, evtArg) => {
        if (evtArg === undefined) {
            console.error('useEventBrokerStateHandling: undefined event argument. This will set the state to undefined. Try passing the desired state value with the published event. If you are legitimately passing undefined, and null would be a suitable replacement, try that.');
        }
        const newState = getNewState ? getNewState(evtArg, state) : evtArg;
        setState(newState);
    }, [state, setState, getNewState]);

    useEventBrokerSubscription(evtName, handler);
}

/**
 * Custom hook to subscribe to 'escapeKey' event and invoke setOpen(false) when it occurs.
 * @param {Function} setOpen A setOpen function, usually from a component useState.
 * This always calls setOpen with false.
 */
export function useCloseOnEscape(setOpen) {
    const handler = useCallback(() => setOpen(false), [setOpen]);
    useEventBrokerSubscription('escapeKey', handler);
}
