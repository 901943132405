export const ResponseIds = {
    TestMessage: 0,
    Solute: 1,
    Snapshot: 2,
    Fragments: 3,
    WaterMap: 4,
    ClusterMap: 5,
    AtomGroupInfo: 6,
    Selection: 7,
    SolvationForLigand: 8,
    ForcefieldParamsForLigand: 9,
    EnergiesForLigand: 10,
    Compound: 11,
    DockResults: 12,
    FindResults: 13,
    // skip one 14 (marked load_molecule_msg in bfd-server)
    TranslateMolecule: 15,
    FragmentMap: 16,
    FragDataResults: 17,

    SelectResults: 'select-result',
    Compound2D: 'compound-2D',
    MapList: 'map-list-json',
    PdbInfo: 'pdb-info',
    SetPermissions: 'set-permissions',
    ModificationSelections: 'modification-selections',
    ExportContents: 'export-contents',
    MolServiceRequestResult: 'mol-service-request-result',
    GetMoleculeProperties: 'get-molecule-properties',
    AvailableFragments: 'available-fragments',
    ProjectCaseStatus: 'project-case-status',
    Zapped: 'zapped',
    Error: 'error',
    Warning: 'warning',
    Status: 'status',
    Completed: 'completed',
    KeepAlive: 'keep-alive',
    AsyncResponseInfo: 'async-response-info',
    StarterAvailability: 'starter-availability',
    EnergiesForLigandText: 'energies-for-ligand',
    SolvationForLigandText: 'solvation-for-ligand',
    ProteinRotationMatrix: 'protein-rotation-matrix',
    DockResultsInfo: 'docking-results',
};

export function GetResponseLabel(responseIdVal) {
    const found = Object.keys(ResponseIds).find((key) => ResponseIds[key] === responseIdVal);
    if (!found) console.warn(`Unknown response id value: ${responseIdVal}`);
    return found;
}
