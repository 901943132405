import { App } from '../BMapsApp';
import { ResponseIds } from '../server/server_common';
import { UserActions, UserCmd } from './UserCmd';

import { Compound, CompoundHeritage } from '../model/compound';
import { integrateCompounds } from './cmds_common';
import { getFullResidueId, joinAtomSpecs } from '../util/mol_info_utils';
import { calculateMolecularWeight, countHeavyAtoms } from '../util/chem_utils';
import { showAlert } from '../utils';
import { matrixToOrientation, Sub3_3, TransposeRTTransform } from '../math';

export const ModificationCmds = {
    ReplaceGroup: new UserCmd('ReplaceGroup', replaceGroup),
    GrowFromAtom: new UserCmd('GrowFromAtom', growFromAtom),
    SearchNear: new UserCmd('SearchNear', searchNear),
    ConfirmModification: new UserCmd('ConfirmModification', confirmModification),
    GrowToFragmentName: new UserCmd('GrowToFragmentName', growToFragmentName),
    FragDataQuery: new UserCmd('FragDataQuery', fragDataQuery),
    ReplaceWithFragmentSet: new UserCmd('ReplaceWithFragmentSet', replaceWithFragmentSet),
};

async function replaceWithFragmentSet(fromAtom, toAtom, fragsetName) {
    const { atomGroup: compound, connector, caseData } = App.getDataParents(fromAtom);

    const responseData = await connector.cmdReplaceWithFragset(
        {
            from: fromAtom.uniqueID,
            to: toAtom.uniqueID,
            fragset: fragsetName,
        }
    );

    showAlert('This awesome dev-only feature is Not Yet Implemented!', 'Replace with Fragment Set');

    // The below may or may not be a useful skeleton for handling returned data.
    // const newCompounds = responseData.byId(ResponseIds.Compound);
    // App.Workspace.addReplaceCompounds(newCompounds, caseData);
    // integrateCompounds(responseData, caseData);
    // CompoundHeritage.addTerminalReplacement(
    //     compound, newCompounds, { fragsetName, fromAtom }
    // );
    // const errors = responseData.byId(ResponseIds.Error);
    // App.Workspace.activateFirstOf(newCompounds);

    return { compounds: [], errors: [] };
}

async function replaceGroup(atom, replacementGroup, newNameIn) {
    const { atomGroup: compound, connector, caseData } = App.getDataParents(atom);
    const compoundName = compound.nextModifiedCompName();
    const newName = newNameIn || compoundName;

    await UserActions.SelectAtom(null);
    const responseData = await connector.cmdReplaceGroup(
        replacementGroup, atom.uniqueID, newName
    );
    const newCompounds = responseData.byId(ResponseIds.Compound);
    App.Workspace.addReplaceCompounds(newCompounds, caseData);
    integrateCompounds(responseData, caseData);
    CompoundHeritage.addTerminalReplacement(compound, newCompounds, { replacementGroup, atom });
    const errors = responseData.byId(ResponseIds.Error);
    App.Workspace.activateFirstOf(newCompounds);

    return { compounds: newCompounds, errors };
}

async function growFromAtom(bondVectorPair, findType, scope='best', searchTolerance=null) {
    if (bondVectorPair.length !== 2) {
        return { suggestions: [], fragments: [], error: 'No bond vector specified.' };
    }
    const [fromAtom, toAtom] = bondVectorPair;
    const { connector, caseData } = App.getDataParents(fromAtom);

    await UserActions.SelectAtom(null);
    const responseData = await connector.cmdFindModifications({
        findType,
        scope,
        searchParams: {
            baseAtomUid: fromAtom.uniqueID,
            terminalAtomUid: toAtom.uniqueID,
            searchTolerance,
        },
    });
    const [fragments=[]] = responseData.byId(ResponseIds.FindResults);
    const [suggestions=[]] = responseData.byId(ResponseIds.ModificationSelections);
    const errors = responseData.byId(ResponseIds.Error);
    fragments.forEach((frag) => frag.setCaseData(caseData));
    return { suggestions, fragments, errors };
}

async function searchNear(atom, scope='best', searchRadius=null, searchTolerance=null) {
    const { connector, caseData } = App.getDataParents(atom);
    await UserActions.SelectAtom(null);
    const responseData = await connector.cmdFindModifications({
        findType: 'Near',
        scope,
        searchParams: {
            baseAtomUid: atom.uniqueID,
            searchRadius,
            searchTolerance,
        },
    });
    const [fragments=[]] = responseData.byId(ResponseIds.FindResults);
    const [suggestions=[]] = responseData.byId(ResponseIds.ModificationSelections);
    const errors = responseData.byId(ResponseIds.Error);
    fragments.forEach((frag) => frag.setCaseData(caseData));
    return { suggestions, fragments, errors };
}

async function confirmModification(suggestion, sourceAtom) {
    const {
        selectionIDs, frags, selectedIndex, modType, origin,
    } = suggestion;
    const { atomGroup, connector, caseData } = App.getDataParents(sourceAtom);

    if (origin === 'dataservice') {
        // For dataservice suggestions, send assemble compound cmd
        const frag = frags[selectedIndex];
        let cmdPromise;
        if (modType === 'Near') {
            cmdPromise = UserActions.AssembleCompound({
                srcMol: null,
                modMol: frag,
                modInfo: {
                    type: modType,
                    modAttachAtom: frag.atoms[0].atom,
                },
            }, caseData);
        } else { // Fragment grow
            cmdPromise = UserActions.AssembleCompound({
                srcMol: atomGroup,
                modMol: frag,
                modInfo: {
                    type: modType,
                    srcAttachAtom: sourceAtom.atom,
                    srcDirectionAtom: '', // ???
                    modAttachAtom: '', // ???
                    modDirectionAtom: '', // ???
                },
            }, caseData);
        }
        return cmdPromise;
    }

    // For suggestions from bfd-server (original find near / find bond logic)
    // Send select modification cmd with selectionID that the server recognizes for the pose
    const selectionID = selectionIDs[selectedIndex];
    const responseData = await connector.cmdSelectModification(modType, selectionID);
    const newCompounds = responseData.byId(ResponseIds.Compound);
    App.Workspace.addReplaceCompounds(newCompounds, caseData);
    integrateCompounds(responseData, caseData);
    if (modType === 'Near') {
        const original = atomGroup instanceof Compound ? atomGroup : null;
        CompoundHeritage.addFindNear(original, newCompounds, { suggestion, atom: sourceAtom });
    } else {
        const original = atomGroup;
        CompoundHeritage.addFragmentGrow(original, newCompounds, { suggestion, atom: sourceAtom });
    }
    const errors = responseData.byId(ResponseIds.Error);
    App.Workspace.activateFirstOf(newCompounds);

    return { compounds: newCompounds, errors };
}

async function growToFragmentName(bondVectorPair, findType, fragName, scope='best') {
    const { suggestions, fragments, errors } = await growFromAtom(bondVectorPair, findType, scope);

    let suggestion;
    for (const sugg of suggestions) {
        if (sugg.name === fragName) {
            suggestion = sugg;
            break;
        }
    }

    if (!suggestion) {
        return { compounds: [], errors: [`Could not find fragment ${fragName} near selection`] };
    }

    const result = await confirmModification(suggestion, bondVectorPair[0]);
    return result;
}

/**
 * Submit a query to the frag-data-query service, converting a client-side request object,
 * to the server format.
 */
async function fragDataQuery(userQuery, connector=App.ServerConnection) {
    const queryObj = makeFragDataQuery(userQuery);
    console.log(`FragDataQuery: ${JSON.stringify(queryObj)}`);
    const responseData = await connector.cmdFragDataQuery(queryObj);
    const results = responseData.byId(ResponseIds.FragDataResults);
    const errors = responseData.errors;

    let suggestions = [];
    let fragments = [];
    if (results.length > 0) {
        ([{ fragments }] = results);
        if (userQuery.findNearAtom) {
            suggestions = makeFindNearSuggestions(fragments, userQuery);
        } else if (userQuery.findBondAtoms) {
            suggestions = makeFindNearSuggestions(fragments, userQuery);
        } else {
            const msg = 'Can not process this query: no findNearAtom or bondVectorPair';
            errors.push(msg);
            console.warn(msg);
        }
    }

    return { suggestions, fragments, errors };
}

// FindNear suggestions do not require mod type or attachment atoms
/**
 * Create modification suggestion objects from decoded fragments
 */
function makeFindNearSuggestions(fragments, userQuery) {
    const suggMap = new Map();
    const { mapCases } = userQuery;

    for (const frag of fragments) {
        // Waters aren't explicitly handled by the dataservice, but they can get included with
        // fragment data. Ignore them until they can be handled in a rational way,
        // otherwise browser could run out of memory.
        if (frag.baseFrag.name === 'water') continue;

        const key = frag.baseFrag.name;
        const sugg = suggMap.get(key);
        if (sugg) {
            sugg.frags.push(frag);
            // Make sure suggestion has best exChemP
            if (frag.exchemPotential < sugg.excessChemicalPotential) {
                sugg.excessChemicalPotential = frag.exchemPotential;
                sugg.bindingFE = frag.exchemPotential;
            }
            let details = sugg.projectCaseDetails[frag.projectCase];
            if (!details || Object.keys(details).length === 0) {
                details = {
                    bindingFE: frag.exchemPotential,
                    excessChemicalPotential: frag.exchemPotential,
                };
                sugg.projectCaseDetails[frag.projectCase] = details;
            } else {
                if (frag.exchemPotential < details.excessChemicalPotential) {
                    details.excessChemicalPotential = frag.exchemPotential;
                    details.bindingFE = frag.exchemPotential;
                }
            }
        } else {
            const newSugg = {
                origin: 'dataservice',
                modType: 'Near',
                frags: [frag],
                selectionIDs: [],
                selectedIndex: 0,
                name: key,
                bindingFE: frag.exchemPotential,
                excessChemicalPotential: frag.exchemPotential,
                ddGs: frag.enSolv,
                mwt: calculateMolecularWeight(frag.getAtoms()),
                nheavy: countHeavyAtoms(frag.getAtoms()),
                projectCaseDetails: {
                    [frag.projectCase]: {
                        bindingFE: frag.exchemPotential,
                        excessChemicalPotential: frag.exchemPotential,
                    },
                },
            };

            // For multi-protein case, initialize values for other proteins. This will let the
            // UI not to display as if there was only one protein.
            if (mapCases.length > 1) {
                const otherMapCases = mapCases.filter(
                    (mapCase) => mapCase.projectCase !== frag.projectCase
                );
                for (const { projectCase } of otherMapCases) {
                    newSugg.projectCaseDetails[projectCase] = {};
                }
            }

            suggMap.set(key, newSugg);
        }

        // Set fragment caseData so coordinates can be transformed if necessary
        const fragMapCase = mapCases.find((mapCase) => mapCase.projectCase === frag.projectCase);
        const fragCaseData = App.Workspace.lookupCaseData(fragMapCase);
        frag.setCaseData(fragCaseData);
    }

    const suggestions = [...suggMap.values()];
    const totalPoses = suggestions.reduce(
        (poseCount, nextSugg) => poseCount + nextSugg.frags.length, 0
    );
    console.log(`Created ${suggMap.size} modification suggestions with ${totalPoses} total poses.`);

    return suggestions;
}

/**
 * Convert a client side frag-data query into a query object recognized by the data service.
 * @param {{ findNearAtom: Atom, findBondAtoms: [Atom, Atom], mapCases: MapCase[] }} arg1
 *
 * This could be moved somewhere else?
 */
function makeFragDataQuery({
    findNearAtom, findBondAtoms, mapCases=[],
    searchRadius=5, maxBValue=-5,
    fragsets, fragmentNames,
}={}) {
    if (findNearAtom && findBondAtoms) {
        const atomDesc = getFullResidueId(findNearAtom);
        const vectorDesc = joinAtomSpecs(findBondAtoms, ', ');
        console.warn(`Attempting frag-data-query for both findNear ${atomDesc} and findBond ${vectorDesc}.`);
    }

    function filtersForMapCases(cases) {
        const projectCases = new Set();

        for (const mapCase of cases) {
            projectCases.add(mapCase.projectCase);
        }
        return [
            ['dataSource', '=', 'public'],
            ['projectCase', 'in', [...projectCases]],
        ];
    }

    function fragmentFilters() {
        const filters = [];

        const fragnames = new Set();
        for (const fragset of fragsets) {
            for (const frag of fragset.items) {
                fragnames.add(frag.name);
            }
        }
        for (const fragName of fragmentNames) {
            fragnames.add(fragName);
        }
        if (fragnames.size > 0) {
            filters.push(['fragment', 'in', [...fragnames]]);
        }
        // filters.push(
        //     ['fragment', 'in', [
        //         'acetamide', 'acetamidine_E', 'acetic_acid', 'acetone',
        //         'aniline', 'benzene', 'dimethylamine', 'ethanol',
        //         'ether', 'ethylamine', 'furan', 'i-butane',
        //         'i-propylamine', 'imidazole', 'phenol', 'propane',
        //     ]]
        // );
        filters.push(['B', '<=', maxBValue]);
        return filters;
    }
    function findNearFilters(atom) {
        if (!atom) return [];

        return [
            ['perPosture', 'FindNear', {
                center: atom.getPosition(),
                radius: searchRadius,
            }],
        ];
    }
    function findBondFilters(atoms) {
        if (!atoms) return [];
        const startPos = atoms[0].getPosition();
        const endPos = atoms[1].getPosition();
        return [
            ['perPosture', 'FindDirectional', {
                center: startPos,
                direction: Sub3_3(endPos, startPos),
                rmin: 0,
                rmax: searchRadius,
                dtheta: 0.5, // about 30 deg
            }],
        ];
    }

    function toDataServiceTransform(usalignTransform) {
        // From the data service API:
        //     https://dev-server.boltzmannmaps.com/wiki/index.php/BMapsDS,_Query_Format
        // The transform is expressed as a list of six floats, three for translation
        // and three for orientation. The orientation vector is a unit vector for the axis
        // of rotation, multiplied by a rotation angle counterclockwise around that vector.
        // An offset of 1.0 is added to the rotation angle so that it is in a range of 1.0 to
        // 1.0+pi. (This minimizes quantization errors that occur when small angles scale the
        // direction vector.)
        //
        // The transform from USAlign is "rotate first, then translate."
        // Data service expects the other way, so transpose it.
        // Data service also wants an orientation vector instead of a rotation matrix.
        const { translation, rotation } = TransposeRTTransform(usalignTransform);
        const offsetForDataservice = 1; // see above
        const orientationVector = matrixToOrientation(rotation, offsetForDataservice);
        return [...translation, ...orientationVector];
    }

    function oneCaseExtras(mapCase) {
        const extras = {};
        /** @type {CaseData} */
        const caseData = App.Workspace.lookupCaseData(mapCase);
        const refCase = caseData.getReferenceCaseData();
        if (refCase) {
            const transform = caseData.getWholeProteinTransform();
            extras.transform = toDataServiceTransform(transform);
        }
        return extras;
    }

    function caseExtras(cases) {
        const ret = {};
        for (const mapCase of cases) {
            ret[mapCase.projectCase] = oneCaseExtras(mapCase);
        }
        return ret;
    }

    const queryObj = {
        op: 'frag-query',
        'structure-version': 1,
        'filter-series': [
            ...filtersForMapCases(mapCases),
            ...fragmentFilters(),
            ...findNearFilters(findNearAtom),
            ...findBondFilters(findBondAtoms),
            // This case-extras is a workaround
            ['case-extras', '', caseExtras(mapCases)],
        ],
        // This case-extras is per the spec
        'case-extras': caseExtras(mapCases),
    };
    return queryObj;
}
