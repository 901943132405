import { useContext, useEffect, useState } from 'react';
// Local
import { App } from '../../../../BMapsApp';
import { UserActions } from '../../../../cmds/UserAction';
import { ExportPaneContext } from '../ExportPane';
import { useExportDataFetch } from '../exportUtils';
import { DialogSubmitButton } from '../../../common/DialogActionButtons';

const supportedFormat = 'pdb';
const config = {
    formats: [supportedFormat],
    allowMultiple: true,
    allowStaticMode: false, // Currently we can't export the protein atoms in preview mode
};

export default function PharmitExportTab({ selected }) {
    const context = useContext(ExportPaneContext);
    const {
        exportTarget, setLoading, closeExportPane,
    } = context;

    const [error, setError] = useState(null);
    const { errors, formattedData } = useExportDataFetch(selected, config, 'Pharmit');
    const compoundData = formattedData[supportedFormat];

    async function handleClick() {
        setLoading(true);

        // Fetch Protein data to include in the request
        // Get the connector for the export request from the compounds if possible.
        const { compounds = [] } = exportTarget;
        let connector = App.ServerConnection; // Default for no compound case

        if (compounds.length > 0) {
            const { dataParentsList } = App.partitionByDataParents(compounds);
            if (dataParentsList.length > 1) {
                setError('Only compounds from one protein can be sent to Pharmit');
                console.error('Multi-protein case sent to Pharmit. This should have been caught earlier.');
                return;
            }
            ([{ connector }] = dataParentsList);
        }

        // Convert the protein in the 3D workspace to PDB format
        const { data } = await UserActions.ExportQueryToFormat('protein', 'pdb', connector);

        const payload = {
            receptor: data,
            ligand: compoundData,
            // TODO: may need to revisit this hardcoded value.
            recname: 'protein.pdb',
            ligandFormat: 'compound.pdb',
        };
        const msg = new Message(payload, 'https://pharmit.csb.pitt.edu/search.html');

        try {
            await msg.sendMessage();
            closeExportPane();
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        // Display on the first error
        setError(errors[0]);
    }, [errors]);

    return (
        <div style={{ marginBottom: '30px' }}>
            <div className="link_control_parent">
                <p>
                    Export the currently selected compound to Pharmit for pharmacophore screening.
                    Pharmit will use the selected compound to virtually screen large compound
                    databases to find compounds with matched interactions and shape.
                </p>
                <br />
                <p>
                    Once in Pharmit, use the left hand controls to set the search features and other
                    criteria, then use the top-left search button to start the database search.
                    Seach databases include MolPort, PubChem, ChEMBL25, Zinc, and others.
                </p>
                <br />
                <p>
                    Pharmit is maintained by the University of Pittsburgh School of Medicine.
                    {' '}
                    <a href="https://pharmit.csb.pitt.edu/" target="_blank" rel="noreferrer">Learn more.</a>
                </p>
            </div>
            <br />
            {error ? <p>{error}</p>
                : <DialogSubmitButton variant="outlined" onClick={handleClick}>Export to Pharmit</DialogSubmitButton>}
        </div>
    );
}

class Message {
    constructor(payload, url) {
        this.payload = payload;
        this.url = url;
    }

    async sendMessage() {
        let ack = false;
        const url = new URL(this.url);
        const win = window.open(this.url);
        if (!win) {
            throw new Error('New window to Pharmit website failed to open. Please make sure your popup blockers are disabled.');
        }

        function receiveMessage(event) {
            if (event.origin !== url.origin) {
                return;
            }

            if (event.data === 'ack2') {
                // Once the target window has send an ack back, we know that the window
                // was opened successfully and is ready to accept our payload.
                ack = true;
            }
        }

        async function waitForAck() {
            const maxRetries = 100;
            const timeout = 250;

            function wait() {
                return new Promise((resolve) => setTimeout(resolve, timeout));
            }

            for (let i = 0; i < maxRetries; i += 1) {
                if (!ack) {
                    // We constantly send an "ack" to the target window to ascertain two things:
                    //   - The target window properly opened
                    //   - The user did not change the URL after the window opened. The second
                    //     argument guarantees that "ack" will only be sent to the specified URL.
                    win.postMessage('ack', url.href);
                    // eslint-disable-next-line no-await-in-loop -- Don't want parallelism here.
                    await wait();
                } else {
                    return;
                }
            }

            throw new Error('400');
        }

        try {
            window.addEventListener('message', receiveMessage);
            await waitForAck();
            win.postMessage(JSON.stringify(this.payload), url.href);
        } catch (e) {
            let msg = '';

            // Allows you to process known errors and redirect unknown errors to a default message.
            switch (e.message) {
                case '400':
                    msg = "Timed out trying to send data to Pharmit. Please make sure the browser window was created successfully and wasn't blocked by a popup blocker. If your popup blocker is enabled, please disable and try again.";
                    break;

                default:
                    msg = `An unknown error occurred when communicating with Pharmit. Please contact developers with the following details: ${e}`;
            }

            throw new Error(msg);
        } finally {
            window.removeEventListener('message', receiveMessage);
        }
    }
}
