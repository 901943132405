import styled from 'styled-components';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const controlButtonCss = `
    margin: 0;
    background-color: black;
    color: var(--mol-button-text-color);
    height: var(--mol-button-size);
    width: var(--mol-button-size);
    border-radius: calc(var(--mol-button-size)/6);
    font-size: 1rem;
    padding: 0.5vh;
    font-family: var(--font-family);
    margin: 0 var(--mol-button-margin) 0 var(--mol-button-margin);
    text-transform: none;
    letter-spacing: normal;
    line-height: normal;

    &:hover {
        border: 0.5vh solid var(--mol-button-text-color); 
        background-color: black;
        padding: 0;
    }
`;

const ControlButton = React.forwardRef(({
    title, placement, TooltipProps, ...props
}, ref) => (
    <Tooltip
        title={title ?? ''}
        placement={placement ?? 'left'}
        arrow
        {...TooltipProps}
    >
        <Button ref={ref} disableRipple {...props} />
    </Tooltip>
));
export default styled(ControlButton)`
    ${controlButtonCss}
`;
