/* theme.js
 *
 * Functions and data about colors and themes.
 */

export const SelectionColor = 0xFF69B4; // hot pink //--- settings?
export const SelectionColorCss = '#FF69B4'; // hot pink //--- settings?

// **** Background Colors ****
// Colors are: background hex, background hex string, text color.
export const BackgroundColors = {
    black: { // white text on black background
        name: 'black', hex: 0x000000, css: '#000000', textHex: 0xffffff, textCss: '#ffffff', style: 'dark', controlAlphaCss: 'CC',
    },
    white: { // black text on off-white background
        name: 'white', hex: 0xf4f4f4, css: '#f4f4f4', textHex: 0x657b96, textCss: '#657b96', style: 'light', controlAlphaCss: 'EE',
    },
    green: { // white text on dark green
        name: 'green', hex: 0x006428, css: '#006428', textHex: 0xf4f4f4, textCss: '#f4f4f4', style: 'dark', controlAlphaCss: 'CC',
    },
    cyan: { // white text on cyan
        name: 'cyan', hex: 0x2aa198, css: '#2aa198', textHex: 0xf4f4f4, textCss: '#f4f4f4', style: 'dark', controlAlphaCss: 'CC',
    },
    tan: { // black text on tan
        name: 'tan', hex: 0xeee8d5, css: '#eee8d5', textHex: 0x657b96, textCss: '#657b96', style: 'light', controlAlphaCss: 'EE',
    },
};

export const DefaultBackgroundColor = 'black';

// **** ATOM COLORING ****

// Use JMol colors (ref: http://jmol.sourceforge.net/jscolors)
export const CompoundColorMap = {
    C: 0x909090, //      E.C.pymolColor = '#33FF33';
    N: 0x3050F8, //      E.N.pymolColor = '#3333FF';
    H: 0xE6E6E6, //      E.H.pymolColor = '#E6E6E6'; JMol is FFFFFF, off-white is better
    O: 0xFF0D0D, //      E.O.pymolColor = '#FF4D4D';
    F: 0x90E050, //      E.F.pymolColor = '#B3FFFF'; (paleCyan)
    S: 0xFFFF30, //      E.S.pymolColor = '#E6C640';
    P: 0xFF8000, //      E.S.pymolColor = '#FF8000';
    Cl: 0x1FF01F,
};

// The requirements here are somewhat different in that light colors (e.g. paleCyan don't
// show up well on (almost) white backgrounds.  I.e. all the colors must be dark to have
// adequate contrast.
export const CompoundColor2dMap = {
    C: 0x2F4F4F, // DarkSlateGray for Compound Carbons,        E.C.pymolColor = '#33FF33';
    N: 0x6969FF, //      E.N.pymolColor = '#3333FF'
    H: 0xE6E6E6, //      E.H.pymolColor = '#E6E6E6'
    O: 0xFF4D4D, //      E.O.pymolColor = '#FF4D4D'
    F: 0x669900, //      E.F.pymolColor = '#B3FFFF'
    S: 0xCC6600, //      E.S.pymolColor = '#E6C640'
    P: 0xFF8000,
    Cl: 0x009933, //      E.S.pymolColor = '#1FF01F'
};

/* eslint-disable object-property-newline */
/* eslint-disable quote-props */
export const ColorChain = {
    'Default Color': 'default',
    'Gray': 0xC0D0FF,
    'Light Green': 0xB0FFB0,
    'Light Pink': 0xFFC0C8,
    'Bright Yellow': 0xFFFF80,
    'Light Purple': 0xFFC0FF,
    'Sky Blue': 0xB0F0F0,
    'Light Orange': 0xFFD070,
    'Salmon': 0xF08080,
    'Wheat': 0xF5DEB3,
    'Cerulean': 0x00BFFF,
    'Chestnut Rose': 0xCD5C5C,
    'Turquoise': 0x66CDAA,
    'Grass Green': 0x9ACD32,
    'Lavender Magenta': 0xEE82EE,
    'Light Blue': 0x00CED1,
    'Spring Green': 0x00FF7F,
    'Ocean Green': 0x3CB371,
    'Navy Blue': 0x00008B,
    'Marsh Grass': 0xBDB76B,
    'Dark Green': 0x006400,
    'Maroon': 0x800000,
    'Olive': 0x808000,
    'Eggplant': 0x800080,
    'Teal': 0x008080,
    'Bronze': 0xB8860B,
    'Red': 0xB22222,
};
/* eslint-enable object-property-newline */
/* eslint-enable quote-props */

const ChainColorByTypes = {
    ChainId: 'ChainId',
    Order: 'Order',
};
let ChainColorBy = ChainColorByTypes.ChainId;
// GetChainColor()
// Returns a color for the given protein chain ID
// Also takes a list of chains in order of arrival, in case coloring
// by order of arrival instead of by actual chain ID  is desirable.
export function GetChainColor(chainIn, chainsInOrder=null) {
    let chain = chainIn;
    if (ChainColorBy === ChainColorByTypes.Order) {
        chain = MapChainByOrder(chain, chainsInOrder);
    }
    // Note, chains can (rarely) be more than one letter, e.g. AA, ZA, etc.
    const chainKey = chain.charAt(0);
    const color = ChainColorMap[chainKey] || DefaultChainColor;
    // console.log(`Chain color ${color} for ${chain} key ${chainKey} order ${ChainColorBy}.`);
    return color;
}

// Remap chain IDs to order of arrival
// Eg: given chains [P, D, Q], map to [A, B, C]
// This function takes a chain ID and the ordered list of all chains,
// returning a new chain ID according to the given chain's position in the list.
function MapChainByOrder(chain, chainsInOrder) {
    if (!chainsInOrder || !chainsInOrder.includes(chain)) {
        // Note: if this includes clause is removed, we'll need to deal with
        // index = -1 below.
        return chain;
    }

    const index = chainsInOrder.indexOf(chain) % 26; // Wrap around if more than 26 chains.
    return String.fromCharCode(65+index); // Convert to A,B,C...
}

// JMol Chain colors taken from http://jmol.sourceforge.net/jscolors/#Chains
/* eslint-disable object-property-newline */
/* eslint-disable quote-props */
const JMolChainColorMap = {
    'A': 0xC0D0FF, 'a': 0xC0D0FF,
    'B': 0xB0FFB0, 'b': 0xB0FFB0,
    'C': 0xFFC0C8, 'c': 0xFFC0C8,
    'D': 0xFFFF80, 'd': 0xFFFF80,
    'E': 0xFFC0FF, 'e': 0xFFC0FF,
    'F': 0xB0F0F0, 'f': 0xB0F0F0,
    'G': 0xFFD070, 'g': 0xFFD070,
    'H': 0xF08080, 'h': 0xF08080,
    'I': 0xF5DEB3, 'i': 0xF5DEB3,
    'J': 0x00BFFF, 'j': 0x00BFFF,
    'L': 0xCD5C5C, 'l': 0xCD5C5C,
    'K': 0x66CDAA, 'k': 0x66CDAA,
    'M': 0x9ACD32, 'm': 0x9ACD32,
    'N': 0xEE82EE, 'n': 0xEE82EE,
    'O': 0x00CED1, 'o': 0x00CED1,
    'P': 0x00FF7F, 'p': 0x00FF7F, '0': 0x00FF7F,
    'Q': 0x3CB371, 'q': 0x3CB371, '1': 0x3CB371,
    'R': 0x00008B, 'r': 0x00008B, '2': 0x00008B,
    'S': 0xBDB76B, 's': 0xBDB76B, '3': 0xBDB76B,
    'T': 0x006400, 't': 0x006400, '4': 0x006400,
    'U': 0x800000, 'u': 0x800000, '5': 0x800000,
    'V': 0x808000, 'v': 0x808000, '6': 0x808000,
    'W': 0x800080, 'w': 0x800080, '7': 0x800080,
    'X': 0x008080, 'x': 0x008080, '8': 0x008080,
    'Y': 0xB8860B, 'y': 0xB8860B, '9': 0xB8860B,
    'Z': 0xB22222, 'z': 0xB22222,
};
/* eslint-enable object-property-newline */
/* eslint-enable quote-props */

// Make this something that can be seen on multiple background colors.
const JMolUnknownChainColor = 0xC0D0FF;

let ChainColorMap = JMolChainColorMap;
let DefaultChainColor = JMolUnknownChainColor;

/* From Chemdoddle
 * ChemDoodle.ELEMENT = (function(SYMBOLS) {
        'use strict';
        var E = [];

        function Element(symbol, name, atomicNumber, addH, \
            color, covalentRadius, vdWRadius, valency, mass) {
                this.symbol = symbol;
                this.name = name;
                this.atomicNumber = atomicNumber;
                this.addH = addH;
                this.jmolColor = this.pymolColor = color;
                this.covalentRadius = covalentRadius;
                this.vdWRadius = vdWRadius;
                this.valency = valency;
                this.mass = mass;
        }

        E.H = new Element('H', 'Hydrogen', 1, false, '#FFFFFF', 0.31, 1.2, 1, 1);
        E.He = new Element('He', 'Helium', 2, false, '#D9FFFF', 0.28, 1.4, 0, 4);
        E.Li = new Element('Li', 'Lithium', 3, false, '#CC80FF', 1.28, 1.82, 1, 7);
        E.Be = new Element('Be', 'Beryllium', 4, false, '#C2FF00', 0.96, 0, 2, 9);
        E.B = new Element('B', 'Boron', 5, true, '#FFB5B5', 0.84, 0, 3, 11);
        E.C = new Element('C', 'Carbon', 6, true, '#909090', 0.76, 1.7, 4, 12);
        E.N = new Element('N', 'Nitrogen', 7, true, '#3050F8', 0.71, 1.55, 3, 14);
        E.O = new Element('O', 'Oxygen', 8, true, '#FF0D0D', 0.66, 1.52, 2, 16);
        E.F = new Element('F', 'Fluorine', 9, true, '#90E050', 0.57, 1.47, 1, 19);
        E.Ne = new Element('Ne', 'Neon', 10, false, '#B3E3F5', 0.58, 1.54, 0, 20);
        E.Na = new Element('Na', 'Sodium', 11, false, '#AB5CF2', 1.66, 2.27, 1, 23);
        E.Mg = new Element('Mg', 'Magnesium', 12, false, '#8AFF00', 1.41, 1.73, 0, 24);
        E.Al = new Element('Al', 'Aluminum', 13, false, '#BFA6A6', 1.21, 0, 0, 27);
        E.Si = new Element('Si', 'Silicon', 14, true, '#F0C8A0', 1.11, 2.1, 4, 28);
        E.P = new Element('P', 'Phosphorus', 15, true, '#FF8000', 1.07, 1.8, 3, 31);
        E.S = new Element('S', 'Sulfur', 16, true, '#FFFF30', 1.05, 1.8, 2, 32);
        E.Cl = new Element('Cl', 'Chlorine', 17, true, '#1FF01F', 1.02, 1.75, 1, 35);
        E.Ar = new Element('Ar', 'Argon', 18, false, '#80D1E3', 1.06, 1.88, 0, 40);
        E.K = new Element('K', 'Potassium', 19, false, '#8F40D4', 2.03, 2.75, 0, 39);
        E.Ca = new Element('Ca', 'Calcium', 20, false, '#3DFF00', 1.76, 0, 0, 40);
        E.Sc = new Element('Sc', 'Scandium', 21, false, '#E6E6E6', 1.7, 0, 0, 45);
        E.Ti = new Element('Ti', 'Titanium', 22, false, '#BFC2C7', 1.6, 0, 1, 48);
        E.V = new Element('V', 'Vanadium', 23, false, '#A6A6AB', 1.53, 0, 1, 51);
        E.Cr = new Element('Cr', 'Chromium', 24, false, '#8A99C7', 1.39, 0, 2, 52);
        E.Mn = new Element('Mn', 'Manganese', 25, false, '#9C7AC7', 1.39, 0, 3, 55);
        E.Fe = new Element('Fe', 'Iron', 26, false, '#E06633', 1.32, 0, 2, 56);
        E.Co = new Element('Co', 'Cobalt', 27, false, '#F090A0', 1.26, 0, 1, 59);
        E.Ni = new Element('Ni', 'Nickel', 28, false, '#50D050', 1.24, 1.63, 1, 58);
        E.Cu = new Element('Cu', 'Copper', 29, false, '#C88033', 1.32, 1.4, 0, 63);
        E.Zn = new Element('Zn', 'Zinc', 30, false, '#7D80B0', 1.22, 1.39, 0, 64);
        E.Ga = new Element('Ga', 'Gallium', 31, false, '#C28F8F', 1.22, 1.87, 0, 69);
        E.Ge = new Element('Ge', 'Germanium', 32, false, '#668F8F', 1.2, 0, 4, 74);
        E.As = new Element('As', 'Arsenic', 33, true, '#BD80E3', 1.19, 1.85, 3, 75);
        E.Se = new Element('Se', 'Selenium', 34, true, '#FFA100', 1.2, 1.9, 2, 80);
        E.Br = new Element('Br', 'Bromine', 35, true, '#A62929', 1.2, 1.85, 1, 79);
        E.Kr = new Element('Kr', 'Krypton', 36, false, '#5CB8D1', 1.16, 2.02, 0, 84);
        E.Rb = new Element('Rb', 'Rubidium', 37, false, '#702EB0', 2.2, 0, 0, 85);
        E.Sr = new Element('Sr', 'Strontium', 38, false, '#00FF00', 1.95, 0, 0, 88);
        E.Y = new Element('Y', 'Yttrium', 39, false, '#94FFFF', 1.9, 0, 0, 89);
        E.Zr = new Element('Zr', 'Zirconium', 40, false, '#94E0E0', 1.75, 0, 0, 90);
        E.Nb = new Element('Nb', 'Niobium', 41, false, '#73C2C9', 1.64, 0, 1, 93);
        E.Mo = new Element('Mo', 'Molybdenum', 42, false, '#54B5B5', 1.54, 0, 2, 98);
        E.Tc = new Element('Tc', 'Technetium', 43, false, '#3B9E9E', 1.47, 0, 3, 0);
        E.Ru = new Element('Ru', 'Ruthenium', 44, false, '#248F8F', 1.46, 0, 2, 102);
        E.Rh = new Element('Rh', 'Rhodium', 45, false, '#0A7D8C', 1.42, 0, 1, 103);
        E.Pd = new Element('Pd', 'Palladium', 46, false, '#006985', 1.39, 1.63, 0, 106);
        E.Ag = new Element('Ag', 'Silver', 47, false, '#C0C0C0', 1.45, 1.72, 0, 107);
        E.Cd = new Element('Cd', 'Cadmium', 48, false, '#FFD98F', 1.44, 1.58, 0, 114);
        E.In = new Element('In', 'Indium', 49, false, '#A67573', 1.42, 1.93, 0, 115);
        E.Sn = new Element('Sn', 'Tin', 50, false, '#668080', 1.39, 2.17, 4, 120);
        E.Sb = new Element('Sb', 'Antimony', 51, false, '#9E63B5', 1.39, 0, 3, 121);
        E.Te = new Element('Te', 'Tellurium', 52, true, '#D47A00', 1.38, 2.06, 2, 130);
        E.I = new Element('I', 'Iodine', 53, true, '#940094', 1.39, 1.98, 1, 127);
        E.Xe = new Element('Xe', 'Xenon', 54, false, '#429EB0', 1.4, 2.16, 0, 132);
        E.Cs = new Element('Cs', 'Cesium', 55, false, '#57178F', 2.44, 0, 0, 133);
        E.Ba = new Element('Ba', 'Barium', 56, false, '#00C900', 2.15, 0, 0, 138);
        E.La = new Element('La', 'Lanthanum', 57, false, '#70D4FF', 2.07, 0, 0, 139);
        E.Ce = new Element('Ce', 'Cerium', 58, false, '#FFFFC7', 2.04, 0, 0, 140);
        E.Pr = new Element('Pr', 'Praseodymium', 59, false, '#D9FFC7', 2.03, 0, 0, 141);
        E.Nd = new Element('Nd', 'Neodymium', 60, false, '#C7FFC7', 2.01, 0, 0, 142);
        E.Pm = new Element('Pm', 'Promethium', 61, false, '#A3FFC7', 1.99, 0, 0, 0);
        E.Sm = new Element('Sm', 'Samarium', 62, false, '#8FFFC7', 1.98, 0, 0, 152);
        E.Eu = new Element('Eu', 'Europium', 63, false, '#61FFC7', 1.98, 0, 0, 153);
        E.Gd = new Element('Gd', 'Gadolinium', 64, false, '#45FFC7', 1.96, 0, 0, 158);
        E.Tb = new Element('Tb', 'Terbium', 65, false, '#30FFC7', 1.94, 0, 0, 159);
        E.Dy = new Element('Dy', 'Dysprosium', 66, false, '#1FFFC7', 1.92, 0, 0, 164);
        E.Ho = new Element('Ho', 'Holmium', 67, false, '#00FF9C', 1.92, 0, 0, 165);
        E.Er = new Element('Er', 'Erbium', 68, false, '#00E675', 1.89, 0, 0, 166);
        E.Tm = new Element('Tm', 'Thulium', 69, false, '#00D452', 1.9, 0, 0, 169);
        E.Yb = new Element('Yb', 'Ytterbium', 70, false, '#00BF38', 1.87, 0, 0, 174);
        E.Lu = new Element('Lu', 'Lutetium', 71, false, '#00AB24', 1.87, 0, 0, 175);
        E.Hf = new Element('Hf', 'Hafnium', 72, false, '#4DC2FF', 1.75, 0, 0, 180);
        E.Ta = new Element('Ta', 'Tantalum', 73, false, '#4DA6FF', 1.7, 0, 1, 181);
        E.W = new Element('W', 'Tungsten', 74, false, '#2194D6', 1.62, 0, 2, 184);
        E.Re = new Element('Re', 'Rhenium', 75, false, '#267DAB', 1.51, 0, 3, 187);
        E.Os = new Element('Os', 'Osmium', 76, false, '#266696', 1.44, 0, 2, 192);
        E.Ir = new Element('Ir', 'Iridium', 77, false, '#175487', 1.41, 0, 3, 193);
        E.Pt = new Element('Pt', 'Platinum', 78, false, '#D0D0E0', 1.36, 1.75, 0, 195);
        E.Au = new Element('Au', 'Gold', 79, false, '#FFD123', 1.36, 1.66, 1, 197);
        E.Hg = new Element('Hg', 'Mercury', 80, false, '#B8B8D0', 1.32, 1.55, 0, 202);
        E.Tl = new Element('Tl', 'Thallium', 81, false, '#A6544D', 1.45, 1.96, 0, 205);
        E.Pb = new Element('Pb', 'Lead', 82, false, '#575961', 1.46, 2.02, 4, 208);
        E.Bi = new Element('Bi', 'Bismuth', 83, false, '#9E4FB5', 1.48, 0, 3, 209);
        E.Po = new Element('Po', 'Polonium', 84, false, '#AB5C00', 1.4, 0, 2, 0);
        E.At = new Element('At', 'Astatine', 85, true, '#754F45', 1.5, 0, 1, 0);
        E.Rn = new Element('Rn', 'Radon', 86, false, '#428296', 1.5, 0, 0, 0);
        E.Fr = new Element('Fr', 'Francium', 87, false, '#420066', 2.6, 0, 0, 0);
        E.Ra = new Element('Ra', 'Radium', 88, false, '#007D00', 2.21, 0, 0, 0);
        E.Ac = new Element('Ac', 'Actinium', 89, false, '#70ABFA', 2.15, 0, 0, 0);
        E.Th = new Element('Th', 'Thorium', 90, false, '#00BAFF', 2.06, 0, 0, 232);
        E.Pa = new Element('Pa', 'Protactinium', 91, false, '#00A1FF', 2, 0, 0, 231);
        E.U = new Element('U', 'Uranium', 92, false, '#008FFF', 1.96, 1.86, 0, 238);
        E.Np = new Element('Np', 'Neptunium', 93, false, '#0080FF', 1.9, 0, 0, 0);
        E.Pu = new Element('Pu', 'Plutonium', 94, false, '#006BFF', 1.87, 0, 0, 0);
        E.Am = new Element('Am', 'Americium', 95, false, '#545CF2', 1.8, 0, 0, 0);
        E.Cm = new Element('Cm', 'Curium', 96, false, '#785CE3', 1.69, 0, 0, 0);
        E.Bk = new Element('Bk', 'Berkelium', 97, false, '#8A4FE3', 0, 0, 0, 0);
        E.Cf = new Element('Cf', 'Californium', 98, false, '#A136D4', 0, 0, 0, 0);
        E.Es = new Element('Es', 'Einsteinium', 99, false, '#B31FD4', 0, 0, 0, 0);
        E.Fm = new Element('Fm', 'Fermium', 100, false, '#B31FBA', 0, 0, 0, 0);
        E.Md = new Element('Md', 'Mendelevium', 101, false, '#B30DA6', 0, 0, 0, 0);
        E.No = new Element('No', 'Nobelium', 102, false, '#BD0D87', 0, 0, 0, 0);
        E.Lr = new Element('Lr', 'Lawrencium', 103, false, '#C70066', 0, 0, 0, 0);
        E.Rf = new Element('Rf', 'Rutherfordium', 104, false, '#CC0059', 0, 0, 0, 0);
        E.Db = new Element('Db', 'Dubnium', 105, false, '#D1004F', 0, 0, 0, 0);
        E.Sg = new Element('Sg', 'Seaborgium', 106, false, '#D90045', 0, 0, 0, 0);
        E.Bh = new Element('Bh', 'Bohrium', 107, false, '#E00038', 0, 0, 0, 0);
        E.Hs = new Element('Hs', 'Hassium', 108, false, '#E6002E', 0, 0, 0, 0);
        E.Mt = new Element('Mt', 'Meitnerium', 109, false, '#EB0026', 0, 0, 0, 0);
        E.Ds = new Element('Ds', 'Darmstadtium', 110, false, '#000000', 0, 0, 0, 0);
        E.Rg = new Element('Rg', 'Roentgenium', 111, false, '#000000', 0, 0, 0, 0);
        E.Cn = new Element('Cn', 'Copernicium', 112, false, '#000000', 0, 0, 0, 0);
        E.Uut = new Element('Uut', 'Ununtrium', 113, false, '#000000', 0, 0, 0, 0);
        E.Uuq = new Element('Uuq', 'Ununquadium', 114, false, '#000000', 0, 0, 0, 0);
        E.Uup = new Element('Uup', 'Ununpentium', 115, false, '#000000', 0, 0, 0, 0);
        E.Uuh = new Element('Uuh', 'Ununhexium', 116, false, '#000000', 0, 0, 0, 0);
        E.Uus = new Element('Uus', 'Ununseptium', 117, false, '#000000', 0, 0, 0, 0);
        E.Uuo = new Element('Uuo', 'Ununoctium', 118, false, '#000000', 0, 0, 0, 0);

        E.H.pymolColor = '#E6E6E6';
        E.C.pymolColor = '#33FF33';
        E.N.pymolColor = '#3333FF';
        E.O.pymolColor = '#FF4D4D';
        E.F.pymolColor = '#B3FFFF';
        E.S.pymolColor = '#E6C640';
*/

function testChainByOrder() {
    if (window.testedChainByOrder) return;
    const tmp = ChainColorBy;
    ChainColorBy = ChainColorByTypes.Order;
    const ordered = []; for (let i = 0; i < 26; i++) ordered.push(String.fromCharCode(i+65));
    const basic = ['A', 'B', 'C', 'D'];
    const another = ['J', 'K', 'L', 'M'];
    const random = [
        5, 2, 6, 4, 17, 25, 8, 3, 12, 0, 15,
        24, 16, 9, 7, 11, 23, 18, 21, 10, 22,
        1, 14, 13, 20, 19].map((i) => ordered[i]);
    const random2 = random.slice().reverse();
    const reverse = ordered.slice().reverse();
    const tooMany = [...random, 'a', 'z', 'g', 'h'];

    const errs = [];

    const testFn = function testFn(testCase) {
        for (const [i, chain] of testCase.entries()) {
            // If there are more than 26 elements, it assigns them by chain id instead of by order
            const reference = testCase.length <= 26 ? ordered[i] : ordered[i%26];
            const msg = `Test: ${MapChainByOrder(chain, testCase)} === ${reference}.  test: ${chain} of ${testCase}.`;
            console.log(msg);
            if (!MapChainByOrder(chain, testCase) === reference) {
                errs.push(msg);
                console.error(`FAILED: ${msg}`);
            }
        }
    };

    for (const test of [basic, another, random, random2, reverse, tooMany]) {
        testFn(test);
    }

    ChainColorBy = tmp;
    window.testedChainByOrder = true;
}
