/**
 * mol_info_utils.js
 */

// Return the residue spec for an atom
// Atoms could be a part of a ligand or a modified compound.
// They are represented differently in BFD server, so we need to return a different spec for them.
export function getFullResidueId(atom, forceSpec=false) {
    // isCompound: non-ligand compounds
    const isCompound = (atom.residue && atom.residue.isCompound);
    const isFragment = atom.fragment;
    const useSpec = !isFragment && (!isCompound || forceSpec);

    if (useSpec) {
        // Protein and ligand atoms (incl cofactors, ions)
        // should return a full spec, using the pdb id (resn).
        const insCode = atom.icode;
        const altLoc = atom.altLoc;
        const modelNum = atom.modelNum;
        return formResidueSpec(atom.resn, atom.resi, atom.chain, { insCode, altLoc, modelNum });
    } else if (isFragment) {
        // Fragment specs are the fragment name and the index
        const fragName = atom.resname || atom.resn;
        return formFragmentSpec(fragName, atom.resi);
    } else {
        // A non-ligand compound spec is just the resname.
        return atom.resname;
    }
}

export function formResidueSpec(resn, resi, chain, { insCode, altLoc, modelNum }={}) {
    const hasInsCode = insCode && insCode.length > 0;
    const hasAltLoc = altLoc && altLoc.length > 0;
    let suffix = '';
    if (insCode) suffix += insCode;
    if (hasAltLoc) {
        if (!hasInsCode) suffix += '_';
        suffix += altLoc;
    }
    const resNameBase = resn;
    const model = (modelNum && modelNum !== 0) ? `#${modelNum}` : '';
    return `${resNameBase}.${resi}${suffix}:${chain}${model}`;
}

export function formFragmentSpec(fragName, fragIndex) {
    const index = (fragIndex != null) ? `.${fragIndex}` : '';
    return `${fragName}${index}`;
}

export function joinAtomSpecs(atoms, join, forceSpec) {
    return atoms
        .map((atom) => getFullResidueId(atom, forceSpec))
        .join(join);
}
