import CoralMap from 'interactive-kinase-map';

export function DisplayCoralMap(coralMapInfoEntries) {
    const styles = {};
    Object.values(coralMapInfoEntries).forEach((entryList) => {
        entryList.forEach((entry) => {
            const { kinaseID } = entry;
            styles[kinaseID] = { branchColor: 'red', nodeColor: 'red' };
        });
    });

    return (
        <CoralMap
            styles={styles}
            staticMap
        />
    );
}
