import { EventBroker } from '../eventbroker';
import { Log } from '../Log';
import { getPreferences } from '../redux/prefs/access';

import { BFDServerInterface } from './BFDServerInterface';
import { hasSlot, SessionErrorType } from './session_request';
import { ServerCmd, ServerCmds } from '../server_cmds';
import { receiveError, receiveWarning, receiveStatus } from '../server_responders';
import { ResponseIds } from './server_common';

export class ServerConnection extends BFDServerInterface {
    constructor(websocket) {
        super();
        this.onMessage = this.onMessage.bind(this);
        this.onConnectionChange = this.onConnectionChange.bind(this);
        this.initWs(websocket);
        this.connected = false;
        this.closed = false;
        this.lastMessageTime = -1;
        this.lastConnectionTestTime = -1;
    }

    // Implementation of abstract methods

    /**
     * Get the command data to send to server, send it over the websocket, set up response listener
     * @returns {ServerResponder} - acts like a Promise, has other functionality also
     */
    execCmd(cmdName, serverCmd, ...args) {
        const [requestId, cmdData] = serverCmd.cmdData(...args);
        this.sendCmd(cmdData);
        return this.setupServerResponder(serverCmd, requestId, args);
    }

    zapNoWait() {
        this.sendCmd(ServerCmds.ZapAll.cmdData());
    }

    get connectionInfo() {
        return this.ws?.getConnectionInfo() || {};
    }

    isConnected() {
        return this.ws?.isConnected() || false;
    }

    getMode() {
        return 'server';
    }

    // Internal methods
    /** Actually send the command data over the websocket */
    sendCmd(cmd) {
        const { ShowCommandData } = getPreferences();
        if (typeof (cmd) === 'string') {
            const cmdToLog = !ShowCommandData ? cmd.replace(/ .*/, '') : cmd;
            console.log(`ServerConnection: sending txt cmd: ${cmdToLog}`);
        } else {
            console.log(`Sending binary cmd of length ${cmd.byteLength}`);
        }
        this.ws.sendCmd(cmd);
    }

    /**
     * @description Wait for server responses for a certain request id,
     * but don't actually send a command.
     * This is used for handling responses that are calculated in external servers.
     * The desired requestId will be specified by the server in an asyncResponseInfo
     * packet and passed here to process the results.
     * @param {*} cmdName
     * @param {*} requestId
     */
    waitForAsyncResponses(cmdName, requestId) {
        const serverKey = this.connectionInfo.key;
        return this.cmdProcessingQueue.processCmdResponses(
            requestId, ServerCmd.ArrayResponse,
            { name: cmdName, args: {}, serverKey },
            this.decoder,
        );
    }

    async initWs(ws) {
        this.ws = ws;
        this.url = ws.getUrl();
        ws.addHandlers(this.onConnectionChange, this.onMessage);
    }

    onMessage(dataIn) {
        let data = dataIn;
        const isBinaryCmd = data instanceof ArrayBuffer;

        try {
            if (isBinaryCmd) {
                const [requestId, cmd] = parseRequestIdAndCmdId(data);

                if (requestId) {
                    data = data.slice(requestId.length + 1);
                    this.cmdProcessingQueue.handleResponse(requestId, cmd, data);
                } else {
                    // Binary responses must be associated with a request id
                    console.log(`ServerConnection: Unhandled server binary command for ${requestId}: ${cmd}`);
                }
            } else { // Text command
                const [requestId, cmd, datapos] = parseRequestIdAndCmdName(data);
                const dataStart = data[datapos] === '\n' ? datapos + 1 : datapos;
                data = data.substr(dataStart);

                if (requestId) {
                    if (!requestId.startsWith('#keep-alive')) {
                        this.cmdProcessingQueue.handleResponse(requestId, cmd, data);
                    } // Ignore "completed" packets for client -> server keep-alives
                } else if (cmd !== ResponseIds.KeepAlive) { // no requestId = unsolicited message
                    const unsolicitedMesssageHandlers = {
                        [ResponseIds.Error]: receiveError,
                        [ResponseIds.Warning]: receiveWarning,
                        [ResponseIds.Status]: receiveStatus,
                    };

                    const handler = unsolicitedMesssageHandlers[cmd];
                    if (handler) {
                        console.log(`ServerConnection: Default handling for ${cmd} without requestID`);
                        handler(data);
                    } else {
                        console.log(`ServerConnection: Unhandled unsolicited server message ${cmd}`);
                    }
                }
            }
        } catch (ex) {
            console.error(`ServerConnection: Unhandled exception processing ws message: ${ex}\nMessage content: ${isBinaryCmd ? '<binary msg>' : data}`);
            console.error(ex.stack);
            if (this.onUnhandledException) {
                this.onUnhandledException(this, ex);
            }
        }

        this.lastMessageTime = new Date();
    }

    onConnectionChange(ws, connected) {
        console.log(`ServerConnection: connection with ${ws.getUrl()} is ${connected ? 'connected' : 'disconnected'}.`);
        if (connected) {
            this.lastMessageTime = new Date();
        }
    }

    onUnhandledException(ws, exception) {
        EventBroker.publish('unhandledExceptionProcessingWsMsg', exception);
    }

    isDisconnected() {
        return this.ws?.isDisconnected() || true;
    }

    haveEverOpened() {
        return this.lastMessageTime > -1;
    }

    close() {
        this.closed = true;
        this.setConnectionStatus(false);
        this.ws.closeWs();
    }

    reconnect() {
        this.ws.closeWs(); // Unsubscribes handlers, so resubscribe below
        this.ws.initializeWs(this.ws.url);
        this.ws.addHandlers(this.onConnectionChange, this.onMessage);
    }

    setConnectionStatus(connected) {
        if (connected === this.connected) return;
        this.connected = connected;
        Log.info(`ServerConnection: Connection status changed to ${connected} for ${this.getLabel()}`);
        EventBroker.publish('connectionStatusChanged', { connector: this, connected, mode: this.getMode() });
    }

    monitorConnection() {
        // Try to reconnect if disconnection detected
        this.ensureConnection();

        // However, a websocket disconnection may not be detected if there is no activity.
        // So send keep-alive packets if no activity detected; this will help detect disconnect.
        // Note that during a long server computation (eg docking), there won't be any inbound
        // packets processed.  So wait longer between keepalives if there if there is a current
        // working command.
        const now = new Date();
        const elapsedMs = now - this.lastMessageTime;
        if (elapsedMs > 15000) {
            const elapsedSinceTest = now - this.lastConnectionTestTime;
            const waitingTime = this.workingCommand ? 60000 : 30000;
            if (elapsedSinceTest > waitingTime) {
                const connTestMsg = this.lastConnectionTestTime > -1
                    ? `Last connection test was ${elapsedSinceTest} ms ago.`
                    : '';
                Log.warn(`Last server message was received ${elapsedMs} ms ago. ${connTestMsg}`);
                this.ws.keepAlive();
                this.lastConnectionTestTime = now;
            }
        }
    }

    async ensureConnection() {
        if (this.isConnected()) {
            this.setConnectionStatus(true);
            return;
        }
        if ((typeof navigator !== 'undefined' && !navigator.onLine) || this.closed) {
            this.setConnectionStatus(false);
            return;
        }

        if (!this.haveEverOpened()) {
            Log.info(`ServerConnection: skipping reconnection because have never connected. Server: ${this.getLabel()}`);
            return;
        }

        const sessionKey = this.connectionInfo?.key;

        const sess = await hasSlot(sessionKey);
        if (sess) {
            if (this.isDisconnected()) {
                Log.info('Reconnecting to server...');
                this.reconnect();
            }
        } else if (sess === false) {
            this.close();
            EventBroker.publish('sessionError', {
                errTitle: 'Session Timed Out',
                errMsg: 'Your session has timed out. Please reload your session.',
                errType: SessionErrorType.TimedOut,
            });
        }
        if (this.isDisconnected()) {
            this.setConnectionStatus(false);
        }
    }
}

function parseRequestIdAndCmdName(data) {
    let requestId = '';
    let cmdStart = 0;
    let cmdName = '';
    let dataPos = 0;

    if (data.startsWith('#')) {
        const i1 = data.indexOf(' ');
        requestId = data.substr(0, i1);
        cmdStart = i1 + 1;
    }

    const cmdEnd = data.indexOf(' ', cmdStart);
    cmdName = cmdEnd > 0 ? data.substring(cmdStart, cmdEnd) : data.substring(cmdStart);
    dataPos = cmdEnd > 0 ? cmdEnd + 1 : -1;
    return [requestId, cmdName, dataPos];
}

function parseRequestIdAndCmdId(data) {
    let reqId = '';
    let cmdStart = 0;

    // Inspect a large chuck to see if it contains a request id
    const chunk = new Uint8Array(data.slice(0, 50));
    const reqIdStartCode = '#'.charCodeAt(0); // charcode for # is 35
    if (chunk[0] === reqIdStartCode) {
        const idEnd = chunk.indexOf(0); // Find null that terminates the reqId string
        const reqIdArr = chunk.slice(0, idEnd);
        reqId = reqIdArr.reduce(
            (buildingString, nextCharCode) => buildingString + String.fromCharCode(nextCharCode),
            '', // buildingString for first iteration is ""
        );
        cmdStart = idEnd + 1;
    }

    // command id is the first byte.  The 2nd byte is the protocol version, not needed here.
    const cmdSlice = data.slice(cmdStart, cmdStart + 1);
    const cmd = new Uint8Array(cmdSlice)[0];

    return [reqId, cmd];
}
