import { createSlice } from '@reduxjs/toolkit';

/**
* @typedef {{ id: string, isTarget: boolean, displayName: string }[]} structList
* @typedef {{proteinFamily: string, targetList: structList}} initialState
*/
const initialState = {
    proteinFamily: '',
    targetList: [],
};

const slice = createSlice({
    name: 'projectTargets',
    initialState,
    reducers: {
        addStructure(state, action) {
            // ensure that target id is unique.
            if (state.targetList.some((value) => value.mapCase === action.payload.mapCase)) {
                return ({ ...state });
            }
            return {
                proteinFamily: state.proteinFamily,
                targetList: [
                    ...state.targetList,
                    action.payload,
                ],
            };
        },
        changeTarget(state, action) {
            const newState = state.targetList.map((value) => {
                if (action.payload.mapCase === value.mapCase) {
                    return ({
                        ...value,
                        isTarget: !value.isTarget,
                    });
                } else {
                    return (value);
                }
            });
            return {
                proteinFamily: state.proteinFamily,
                targetList: [...newState],
            };
        },
        removeStructure(state, action) {
            const newState = state.targetList.filter(
                (value) => value.mapCase !== action.payload.mapCase
            );
            return {
                proteinFamily: state.proteinFamily,
                targetList: [...newState],
            };
        },
        changeProteinFamily(state, action) {
            return {
                proteinFamily: action.payload,
                targetList: [...state.targetList],
            };
        },
        removeAllStructures(state) {
            return {
                proteinFamily: state.proteinFamily,
                targetList: [],
            };
        },
        reset() {
            return initialState;
        },
    },
});

export default slice;
