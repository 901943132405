// EventBroker
// A class to manage pub-sub of events in the system, eg. zapAll
// The idea is to be a singleton class with static methods.
export class EventBroker {
    static registerModule(moduleName, functions) {
        const module = EventBroker.ensureModule(moduleName);
        Object.assign(module, functions);
    }

    static getModule(moduleName) {
        return EventBroker.ensureModule(moduleName);
    }

    static ensureModule(moduleName) {
        let module = TheEventBroker.modules.get(moduleName);
        if (!module) {
            module = {};
            TheEventBroker.modules.set(moduleName, module);
        }
        return module;
    }

    static subscribe(event, handler) {
        TheEventBroker.doSubscribe(event, handler);
        return handler;
    }

    static unsubscribe(event, handler) {
        TheEventBroker.doUnsubscribe(event, handler);
    }

    static publish(event, data) {
        TheEventBroker.doPublish(event, data);
    }

    static async withIgnoredEvents(event, promise) {
        try {
            if (EventBroker.LogAll) console.log(`Ignoring events: ${event}`);
            TheEventBroker.ignored.set(event, true);
            return await promise;
        } finally {
            TheEventBroker.ignored.delete(event);
            if (EventBroker.LogAll) console.log(`Resuming events: ${event}`);
        }
    }

    constructor() {
        this.broker = new Map();
        this.modules = new Map();
        this.ignored = new Map();
    }

    doSubscribe(event, handler) {
        if (!this.broker.get(event)) {
            this.broker.set(event, []);
        }
        this.broker.get(event).push(handler);
        if (EventBroker.LogAll) console.log(`Subscribed to ${event}`);
    }

    doUnsubscribe(event, handler) {
        const idx = this.broker.get(event).indexOf(handler);
        if (idx >= 0) {
            this.broker.get(event).splice(idx, 1);
        }
        if (EventBroker.LogAll) console.log(`Unsubscribed from ${event}`);
    }

    doPublish(event, data) {
        if (this.ignored.get(event)) {
            if (EventBroker.LogAll) console.log(`Ignoring published event: ${event}`);
            return;
        }
        if (EventBroker.LogAll) console.log(`Publishing event: ${event}`);
        const handlers = this.broker.get(event);
        if (handlers) {
            for (const handler of handlers) { handler(event, data); }
        }
    }
}
const TheEventBroker = new EventBroker();
EventBroker.LogAll = false;
