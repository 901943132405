/** Loader.js
 * This is a way to load modules that we aren't sure if we want or not.
 * To import a module:
 *      1) add the import to the top here.  This should be the only place it is imported.
 *      2) add the classname to the Loader.loaded assignment in the Load() method
 *      3) add a static getter for your class to the bottom.
 *      4) If you wish, you can define a static Init() method in your class which will get
 *         automatically called at startup, after the UI has been built.
 *         If you wish to initialize the module yourself, call the initializer
 *         something besides Init(), to avoid getting automatically called at startup.
 *      5) If you need to reference your module in the code, use the getters to get the class.
 *         Then you can use static class methods to do what you need to do.
 *
 *         Eg. style_buttons.js has the following:
 *              export function showSketcher(compound) {
 *                  if (Loader.Sketcher)
 *                      Loader.Sketcher.Display(compound);
 *              }
 *           The Sketcher.Display is a static method to display the Sketcher.
 */

// feature loaders are copied to a different directory so the imports can't be found.
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import { FragSearchButton } from './FragSearchButton';
import { TestConsole } from './test/testconsole/TestConsole';
import { Sketcher } from './Sketcher';
import { SpeechCmds } from './speech';
import { TerminateServerButton } from './TerminateServerButton';
import { DesignCustomActions } from './cmds/DesignCmds';
import { RDKitExport } from './plugin/RDKitExport';
import { MolVisualizer } from './ui/MolVisualizer';
import DumpStateMenuItem from './plugin/DumpStateMenuItem';

export class Loader {
    static Load() {
        Loader.loaded = {
            FragSearchButton,
            TestConsole,
            Sketcher,
            SpeechCmds,
            TerminateServerButton,
            DesignCustomActions,
            RDKitExport,
            MolVisualizer,
            DumpStateMenuItem,
        };
        for (const loading of Object.values(Loader.loaded)) {
            if (loading.Init) loading.Init();
        }
    }

    static get TestConsole() {
        return Loader.loaded.TestConsole;
    }

    static get Sketcher() {
        return Loader.loaded.Sketcher;
    }

    static get SpeechCmds() {
        return Loader.loaded.SpeechCmds;
    }

    static get FragSearchButton() {
        return Loader.loaded.FragSearchButton;
    }

    static get AllowDevFeatures() {
        return true;
    }

    static get AllowLabFeatures() {
        return true;
    }

    static get RDKitExport() {
        const rdkit = Loader.loaded.RDKitExport;
        if (rdkit && rdkit.isInitialized()) {
            return rdkit;
        } else {
            return undefined;
        }
    }

    static get MolVisualizer() {
        return Loader.loaded.MolVisualizer;
    }
}
