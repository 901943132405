/* Workspace.js */
import _ from 'lodash';
import { TreeUtils } from '@Conifer-Point/px-components';
import { EventBroker } from '../eventbroker';
import { AtomGroup, DefaultBindingSiteRadius } from '../utils';
import { atomsClashing } from '../util/clash_detection';
import { ensureArray } from '../util/js_utils';
import { BindingSite } from './bindingsite';
import { App } from '../BMapsApp';
import { DisplayState, DisplayStateController, MolAtomGroupState } from './DisplayState';
import { TreeData } from './TreeData';
import {
    AtomGroupTypes, Polymer, Hotspot, MolAtomGroup,
} from './atomgroups';
import CaseData from './CaseData';
import { MapCase } from './MapCase';
import { EnergyInfo } from './energyinfo';
import { FragmentData } from './FragmentData';
import ProteinTree from '../presentation/ProteinTree';
import FragmentTree from '../presentation/FragmentTree';
import { setBindingSiteDistance } from '../redux/prefs/access';
import { Log } from '../Log';
import * as TargetInfo from '../redux/projectTargets/access';

export class Workspace {
    constructor(appState) {
        this.appState = appState;
        this.displayState = new DisplayState(() => EventBroker.publish('viewStateChanged'));
        this.displayStateController = new DisplayStateController(this.displayState);
        this.reset(false);
        this.isVisible = this.isVisible.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.isActive = this.isActive.bind(this);
        this.isFilteredHotspot = this.isFilteredHotspot.bind(this);
        this.getComponentByTypeAndKey = this.getComponentByTypeAndKey.bind(this);
        this.isVisibleCompoundAtom = this.isVisibleCompoundAtom.bind(this);
        this.getAtomGroupState = this.getAtomGroupState.bind(this);
        this.firstCaseData = this.firstCaseData.bind(this);
    }

    zap() {
        this.reset(true);
        EventBroker.publish('zapAll');
    }

    reset(triggerChange) {
        TargetInfo.removeAllStructures();
        /* Variables about loading proteins and compounds */
        this.addedMoleculeFiles = []; // names of user files added (eg drag & drop)
        /** @type {FragmentData} */
        this.fragmentData = new FragmentData();
        /** @type { Compound? } */
        this.activeCompound = null;

        /** @type { BindingSite } */
        this.workingBindingSite = null;
        /** @type {number}  */
        this.hotspotThreshold = 0;

        this.atomGroupState = new MolAtomGroupState();
        this.systemTree = new TreeData(() => EventBroker.publish('atomGroupStateChanged'));
        this.proteinTree = null;
        this.rebuildProteinTree(false);
        this.fragmentsTree = null;
        this.rebuildFragmentsTree(false);
        this.selectedAtoms = new AtomGroup();

        setBindingSiteDistance(DefaultBindingSiteRadius);

        // Ideally, we don't have a display state controller and this just calls
        // displayState.reset().
        // However, since StyleManager and DisplayStateController are both (essentially) singletons,
        // and DisplayState is not, I'd rather have DisplayStateController reset StyleManager.
        this.displayStateController.reset(triggerChange);
    }

    getCaseDataCollections() {
        return this.appState.getCaseDataCollections();
    }

    getCaseDataCount() {
        return this.getCaseDataCollections().reduce((acc, cdc) => acc + cdc.getCaseDataCount(), 0);
    }

    dumpState() {
        let report = 'Workspace:\n';
        report += `AppState:\n${this.appState.dumpState()}`;
        report += `${this.addedMoleculeFiles.length} added molecule files; ${this.getLoadedProteins().length} loaded proteins; ${this.getCaseDataCount()} case datas (including no-protein)\n`;
        report += `Active compound: ${this.activeCompound?.resSpec}\n`;
        report += `Binding site: ${this.workingBindingSite?.dumpState()}\n`;
        report += `Atom group state: ${this.atomGroupState.dumpState()}\n`;
        report += `Fragserv Data: ${this.fragmentData.dumpState()}\n`;
        report += `Selected atoms: ${this.selectedAtoms.atomCount()}\n`;
        return report;
    }

    firstCaseData(excludeNoProtein=false) {
        const allCaseData = this.allCaseData();
        const foundWithMapCase = allCaseData.find((caseData) => caseData.mapCase);
        return foundWithMapCase || (!excludeNoProtein ? allCaseData[0] : null);
    }

    defaultAlignmentReferenceCaseData() {
        return this.firstCaseData(true); // exclude no protein case
    }

    /** @returns {CaseData?} */
    lookupCaseData(mapCase) {
        if (mapCase instanceof MapCase) {
            return mapCase.getCaseDataCollection().lookupCaseData(mapCase);
        }
        throw new Error('Workspace.lookupCaseData requires a MapCase argument,  not a string');
    }

    // temparary
    lastCaseData() {
        const allCaseData = this.allCaseData();
        return allCaseData[allCaseData.length - 1];
    }

    allCaseData() {
        return this.collectFromCaseDataCollections((cdc) => cdc.getAllCaseData());
    }

    collectFromCaseDataCollections(fn) {
        const collections = this.getCaseDataCollections();
        return collections.reduce(
            (acc, collection) => acc.concat(fn(collection)),
            []
        );
    }

    collectFromCaseData(fn) {
        const caseDatas = this.allCaseData();
        return caseDatas.reduce(
            (acc, caseData) => acc.concat(fn(caseData)),
            []
        );
    }

    /**
     * Some situations require a label only when there are multiple proteins loaded.
     * If only one protein, return empty string.
     * @param {*} thing
     * @returns
     */
    getCaseLabel(thing) {
        if (this.getLoadedProteins().length > 1) {
            const { caseData } = App.getDataParents(thing);
            return caseData.getName();
        }
        return '';
    }

    addProtein(mapCase, atomGroups) {
        TargetInfo.addStructureToProjectTargets(mapCase);
        const caseData = new CaseData(mapCase);
        for (const atomGroup of atomGroups) {
            caseData.addAtomGroup(atomGroup);
            this.handleAtomGroup(atomGroup);
        }
        EventBroker.publish('proteinsChanged');
        return caseData;
    }

    /**
     *
     * @param {CaseData} caseData
     */
    removeCase(caseData) {
        TargetInfo.removeStructureByMapCase(caseData.mapCase);
        // Collect data we'll need to execute the remove
        const originalActiveIndex = this.getLoadedCompounds().indexOf(this.activeCompound);
        const allAtomGroups = caseData.allAtomGroups();
        const atomGroupAtoms = allAtomGroups.reduce((acc, next) => acc.concat(next.getAtoms()), []);
        const availableFragments = caseData.getAvailableFragmentInfo().items();

        // Remove various components from various departments.
        const { caseDataCollection } = App.getDataParents(caseData);
        caseDataCollection.removeCaseData(caseData);
        // Associated state for business objects (visibility, etc)
        this.atomGroupState.remove(allAtomGroups);
        this.atomGroupState.remove(availableFragments);
        // Remove hotspots, clearing state and 3D atoms. (Hotspots aren't modeled as AtomGroups)
        this.removeHotspots(caseData);
        // Remove atoms from the 3D visualizer
        this.removeObject3dAtoms(allAtomGroups);
        // Remove atoms from the main atom lookup map in decode.js
        atomGroupAtoms.forEach((a) => caseDataCollection.removeAtomFromMap(a));
        // If an active compound was removed, update active compound
        if (caseData.getCompounds().includes(this.activeCompound)) {
            this.activateAnotherCompound(originalActiveIndex);
        }
        // Update tree control
        for (const compound of caseData.getCompounds()) {
            this.systemTree.removeAllInstances(compound, 'Compounds');
        }
        this.rebuildProteinTree();
        this.rebuildFragmentsTree();

        for (const otherCaseData of this.allCaseData()) {
            if (otherCaseData.getReferenceCaseData() === caseData) {
                otherCaseData.setReferenceCaseData(null);
                otherCaseData.setWholeProteinTransform(null);
                const newReference = this.defaultAlignmentReferenceCaseData();
                if (newReference && newReference !== otherCaseData) {
                    // TODO: align this protein to the new reference
                    // This requires calling the UserAction which isn't available in Workspace.
                }
            }
        }
        EventBroker.publish('proteinsChanged');
    }

    setAvailableFragments(mapCase, fragList) {
        const caseData = this.lookupCaseData(mapCase);
        if (!caseData) {
            console.warn(`Receieved available fragments for unrecognized mapCase: ${mapCase?.projectCase}. Ignoring.`);
            return;
        }
        caseData.setAvailableFragments(fragList);

        // When we receive the available fragment list, mark the fragments used in hotspots
        // This marking is done here in the Workspace, rather than the CaseData
        const hotspotFragmentNames = caseData.getHotspots().reduce(
            (acc, hs) => acc.concat(hs.getFragmentNames()),
            []
        );
        for (const fragName of hotspotFragmentNames) {
            const fragInfo = caseData.getAvailableFragmentInfo().findByName(fragName);
            if (fragInfo) {
                this.toggleProp(fragInfo, 'usedForHotspots', true, false);
            }
        }
        this.addFragsetInfoToTree(caseData.getAvailableFragmentInfo());
    }

    getAvailableFragmentInfo(mapCase) {
        const caseData = this.lookupCaseData(mapCase);
        return caseData.getAvailableFragmentInfo();
    }

    getAllAvailableFragments() {
        const fragInfos = this.getAllAvailableFragmentInfo();
        return fragInfos.reduce((acc, fragInfo) => [...acc, ...fragInfo.items()], []);
    }

    getAllAvailableFragmentInfo() {
        const fragInfos = [];
        for (const caseData of this.allCaseData()) {
            const fragInfo = caseData.getAvailableFragmentInfo();
            if (fragInfo.isInitialized()) {
                fragInfos.push(fragInfo);
            }
        }
        return fragInfos;
    }

    availableFragmentStatus() {
        const casesLoading = [];
        const casesWithFrags = [];
        const casesWithoutFrags = [];
        for (const caseData of this.allCaseData()) {
            const fragInfo = caseData.getAvailableFragmentInfo();
            if (!fragInfo.isInitialized()) {
                casesLoading.push(caseData);
            } else if (fragInfo.anyAvailable()) {
                casesWithFrags.push(caseData);
            } else {
                casesWithoutFrags.push(caseData);
            }
        }

        return {
            casesLoading,
            casesWithFrags,
            casesWithoutFrags,
            anyLoading: casesLoading.length > 0,
            anyFrags: casesWithFrags.length > 0,
        };
    }

    getDataConnections() {
        return App.DataConnections;
    }

    async refreshFragservInfo() {
        return this.fragmentData.updateGeneralFragservInfo();
    }

    async addFragsetInfoToTree(availableFragmentInfo) {
        const logTiming = false;
        if (logTiming) Log.log('LOAD-FRAGS', 'Fetching fragserv data for available fragments...');
        await this.fragmentData.getFragservDataForAvailableFragments(availableFragmentInfo);
        if (logTiming) Log.log('LOAD-FRAGS', 'Done fetching fragserv data for available fragments');
        this.rebuildFragmentsTree();
        // Rebuild the fragments tree twice.
        // Once after loading the fragments, and once after images arrive
        if (logTiming) Log.log('LOAD-FRAGS', 'Loading frag images...');
        await availableFragmentInfo.loadFragImages();
        if (logTiming) Log.log('LOAD-FRAGS', 'Done loading all frag images');
        this.rebuildFragmentsTree();
    }

    rebuildFragmentsTree(update=true) {
        const selectedFrags = new Set();
        if (this.fragmentsTree) {
            // Remember selected fragments when tree is rebuilt
            const { selectedNodes } = this.fragmentsTree.getVisibleFragments();
            selectedNodes.forEach(({ item }) => selectedFrags.add(item));

            // Because the tree nodes will be recreated,
            // remove any state associated with the nodes themselves,
            // (but not the underlying AtomGroups).
            // This should only affect selection and expansion;
            // visibility of atom groups should be preserved.
            this.atomGroupState.remove(this.proteinTree.selectNodes());
        }
        const standardFragset = this.fragmentData.fragservData.getStandardFragset();
        const standardNames = standardFragset ? standardFragset.items.map((x) => x.name) : null;

        const displayStateInfo = {
            sortType: this.displayState.sorting.FragmentsTree,
            standardNames,
            fragservData: this.fragmentData.fragservData,
        };

        this.fragmentsTree = new FragmentTree(
            this.allCaseData(), this.getAtomGroupState, displayStateInfo
        );

        // Select previously selected fragments (just the first found tree node for each fragment)
        for (const selectedFrag of selectedFrags) {
            const node = this.fragmentsTree.nodesForItems(selectedFrag)[0];
            if (node) {
                this.toggleSelection(node, true, false);
            }
        }

        if (update) EventBroker.publish('atomGroupStateChanged');
    }

    handleAtomGroup(atomGroup) {
        const type = atomGroup.type;

        const visibleByDefault = [
            AtomGroupTypes.Protein,
            AtomGroupTypes.Cofactor,
            AtomGroupTypes.Ion,
            AtomGroupTypes.PeptideLigand,
            AtomGroupTypes.Polymer,
        ];
        if (visibleByDefault.includes(type)) {
            const forceValue = undefined;
            const refresh = false; // don't refresh ui for each atomgroup
            this.toggleVisibility(atomGroup, forceValue, refresh);
        }

        if ([AtomGroupTypes.Compound, AtomGroupTypes.Ligand].includes(type)) {
            this.systemTree.addToCompoundsTree(atomGroup);
        }
    }

    /**
     * Rebuild and replace the protein tree
     * @param {boolean} update Whether or not to fire an event afterwards
     */
    rebuildProteinTree(update=true) {
        if (this.proteinTree) {
            // Because the tree nodes will be recreated,
            // remove any state associated with the nodes themselves,
            // (but not the underlying AtomGroups).
            // This should only affect selection and expansion;
            // visibility of atom groups should be preserved.
            this.atomGroupState.remove(this.proteinTree.selectNodes());
        }
        const displayStateInfo = {
            sortType: this.displayState.sorting.ProteinTree,
            isFilteredHotspot: this.isFilteredHotspot,
        };
        this.proteinTree = new ProteinTree(
            this.allCaseData(), this.getAtomGroupState, displayStateInfo
        );
        if (update) {
            EventBroker.publish('atomGroupStateChanged');
        }
    }

    changeTreeSort(treeName, sortType) {
        if (treeName === 'Protein') {
            this.displayState.setSorting({ ProteinTree: sortType });
            this.rebuildProteinTree();
        } else if (treeName === 'Fragments') {
            this.displayState.setSorting({ FragmentsTree: sortType });
            this.rebuildFragmentsTree();
        }
    }

    /** @description Returns a copy of the list of atomgroups by type */
    atomGroupsByType(type) {
        const all = this.collectFromCaseData((caseData) => caseData.atomGroupsByType(type));
        return all;
    }

    allAtomGroups() {
        const ret = this.collectFromCaseData((caseData) => caseData.allAtomGroups());
        return ret;
    }

    atomGroupsByTypes(types) {
        const all = this.collectFromCaseData((caseData) => caseData.atomGroupsByTypes(types));
        return all;
    }

    addReplaceCompounds(compounds, caseData) {
        for (const compound of compounds) {
            this.addReplaceCompound(compound, caseData);
        }
    }

    addReplaceCompound(newCompound, caseData) {
        const oldCompound = caseData.getCompoundBySpec(newCompound.resSpec);

        if (oldCompound) {
            this.replaceCompoundAt(caseData, oldCompound, newCompound);
        } else {
            caseData.addAtomGroup(newCompound);
            // This is weird to add the 3D atoms here.
            // However, the rotation will be reset if we do a full refresh, so leave it for now.
            // Note: addAtoms must follow the assignment of the compound's casedata, for alignment
            this.addObject3dAtoms(newCompound);
            this.systemTree.addToCompoundsTree(newCompound);
        }
    }

    replaceCompoundAt(caseData, oldCompound, newCompound) {
        // Move the atomgroup state to the new compound
        const oldState = this.atomGroupState.getState(oldCompound);
        this.atomGroupState.setState(newCompound, oldState);
        this.atomGroupState.remove(oldCompound);

        // Actually replace the compound in the array
        caseData.replaceInAtomGroupList(oldCompound, newCompound);

        // Update the visualizer atoms
        // This is weird to do this here, but leave it to avoid resetting view after minimization.
        // Note: addAtoms must follow the assignment of the compound's casedata, for alignment
        this.removeObject3dAtoms(oldCompound);
        this.addObject3dAtoms(newCompound);

        if (oldCompound.energyInfo.minimizationStatus === EnergyInfo.States.working) {
            // When minimizing, first a new compound will arrive (replacing the old), then energies.
            // Since energies haven't yet arrived, make the new compound look like it is working.
            newCompound.energyInfo.minimizationRequested();
            // Also, copy energies from old compound to new compound before arrival of new ones
            // This prevents energy table flashing as energies change from the old compound,
            // to 0 (new compound's initial state) and then to the newly assigned values.
            // There is still a brief flash, which is that if the solvation energy comes in first,
            // The interaction score will flash 0, then update when the energy packet is applied.
            for (const energyEntry of oldCompound.energyInfo.getAllEnergies()) {
                const {
                    type, status, energy, error,
                } = energyEntry;
                newCompound.energyInfo.updateEnergy(type, status, energy, error);
            }
            EventBroker.publish('energyCalc');
        }

        // Reduce 2D display flashing by copying svg data from old to new. This is not permanent;
        // newCompound will still request its own image, so it will be able to update if necessary.
        newCompound.setSvg(oldCompound.getSvg());

        if (this.activeCompound === oldCompound) {
            this.activateCompound(newCompound);
        } else {
            this.activateCompound(this.activeCompound); // force refresh of display
        }

        this.systemTree.compoundsTree.replaceAllInstances(oldCompound, newCompound);

        newCompound.heritage = oldCompound.heritage;
    }

    renameCompound(compound, newName) {
        if (compound && newName) {
            const oldName = compound.resSpec;
            compound.rename(newName);
            EventBroker.publish('activeCompound', { compound });
            console.log(`Renamed ${oldName} to ${newName}`);
        } else {
            console.error(`Unable to rename compound ${compound ? compound.resSpec : '<null>'} to ${newName || '<null>'}`);
        }
    }

    moveTreeItem(treeName, item, fromIx, toIx) {
        this.systemTree.moveItem(treeName, item, fromIx, toIx);
    }

    addMoleculeEntry(molEntry) {
        this.addedMoleculeFiles.push(molEntry);
    }

    activateCompound(compound) {
        this.activeCompound = compound;
        this.workingBindingSite = null;
        EventBroker.publish('activeCompound', { compound });
    }

    activateFirstOf(compounds) {
        if (compounds && compounds.length > 0) {
            this.activateCompound(compounds[0]);
        }
    }

    // activateNextCompound()
    // This function cycles through the loadedCompounds to assign the activeCompound
    // Would be a lot simpler to track the activeCompound with index intead of the the actual object
    activateNextCompound(step=1) {
        const activeCompound = this.getActiveCompound();
        const loadedCompounds = this.getLoadedCompounds();
        let changed = false;

        if (activeCompound == null) {
            if (loadedCompounds.length > 0) {
                // This shouldn't happen, but just in case...
                this.activateCompound(loadedCompounds[0]);
                changed = true;
                console.log(`There are ${loadedCompounds.length} compounds but none active.  Activating the first.`);
            }
        } else if (loadedCompounds.length > 1) {
            const currentIdx = loadedCompounds.findIndex((cmpd) => activeCompound === cmpd);
            let nextIdx = (currentIdx + step) % (loadedCompounds.length);
            if (nextIdx < 0) {
                // Stepping backwards could produce a negative index, which takes us to the end.
                // Just add the negative index to the length to get the right offset.
                nextIdx += loadedCompounds.length;
            }
            this.activateCompound(loadedCompounds[nextIdx]);
            changed = true;
            console.log(`Activating compound ${nextIdx+1} of ${loadedCompounds.length}.`);
        } else {
            // Nothing to do, only one compound to display.
        }

        return changed;
    }

    toggleVisibility(component, forceValue, evtName='visibilityChanged') {
        this.toggleProp(component, MolAtomGroupState.Visible, forceValue, evtName);
    }

    isVisible(component) {
        return this.getAtomGroupState(component, MolAtomGroupState.Visible);
    }

    isSelected(component) {
        return this.getAtomGroupState(component, MolAtomGroupState.Selected);
    }

    isActive(component) {
        return this.getAtomGroupState(component, MolAtomGroupState.Active);
    }

    // Expansion state is tracked as "collapsed" since default is expanded
    isCollapsed(treeNode) {
        return this.getAtomGroupState(treeNode, MolAtomGroupState.Collapsed);
    }

    toggleSelection(component, forceValue, evtName) {
        this.toggleProp(component, MolAtomGroupState.Selected, forceValue, evtName);
    }

    toggleExpansion(component, forceValue, evtName) {
        this.toggleProp(component, MolAtomGroupState.Collapsed, forceValue, evtName);
    }

    toggleActive(component, forceValue, evtName) {
        this.toggleProp(component, MolAtomGroupState.Active, forceValue, evtName);
    }

    toggleProp(component, prop, forceValue, evtName='atomGroupStateChanged') {
        if (component instanceof Array) {
            for (const entry of component) {
                // The entry can either be the item we're updating,
                // or an object like {item, forceValue}
                let c = entry;
                let fv = forceValue;
                if (entry.item && entry.forceValue !== undefined) {
                    c = entry.item;
                    fv = entry.forceValue;
                }
                this.atomGroupState.toggle(c, prop, fv);
            }
        } else {
            this.atomGroupState.toggle(component, prop, forceValue);
        }
        if (evtName) {
            for (const evt of [].concat(evtName)) { // force to array
                EventBroker.publish(evt);
            }
        }
    }

    /**
     * Return all atomGroups an atom is in, along with the containing caseData.
     * Atoms can be in multiple groups, for example: in both a Residue and a Protein Chain.
     * @param {Atom} atom
     * @returns {{ caseData: CaseData, atomGroups: AtomGroup[]}}
     */
    atomGroupsForAtom(atom) {
        for (const caseData of this.allCaseData()) {
            const atomGroups = caseData.allAtomGroups().filter((ag) => ag.hasAtom(atom));
            if (atomGroups.length > 0) {
                return { caseData, atomGroups };
            }
        }
        return { caseData: null, atomGroups: null };
    }

    /**
     * Take a group of atoms and partition into whole atom groups and leftover atoms.
     * "Whole atom groups" are atom groups for which all atoms are in the provided atom list.
     * Returns: ```{
     *     primaryGroups: whole atom groups not contained by other whole atom groups
     *     subsumedGroups: whole atom groups contained by other whole atom groups (eg residues)
     *     extraAtomsMap: extra atoms not in whole groups, collected in a Map from atomgroup
     *         to extra atoms in that group.
     * }```
     * @param {Atom[]} atoms
     * @returns {{
     *     primaryGroups:MolAtomGroup[],
     *     subsumedGroups:MolAtomGroup[],
     *     extraAtomsMap:Map<MolAtomGroup, Atom[]>,
     * }}
     *
     * Example: suppose the atom list contains all atoms in a GLU residue in chain A,
     * all protein atoms on Chain B, and a few individual atoms selected on a cofactorand a ligand.
     * primaryGroups will contain the GLU:A Residue group and the Chain B Polymer group
     * subsumedGroups will contain all the protein Residues from Chain B
     * extraAtomsMap will map the cofactor and ligand atomgroups to their extra atom arrays.
     */
    partitionAtomsIntoGroups(atoms) {
        const atomsLeft = new Set(atoms);
        const primaryGroups = [];
        const subsumedGroups = [];

        // Go through all atom groups checking to see if all their atoms are in our collection.
        // Sort from big to small so we match parent groups (chains) first instead of children (res)
        const allAtomGroups = this.allAtomGroups();
        allAtomGroups.sort((a, b) => b.getAtoms().length - a.getAtoms().length);

        for (const atomGroup of allAtomGroups) {
            const agAtoms = atomGroup.getSelectableAtoms();
            if (agAtoms.length > atomsLeft.size) continue;
            if (agAtoms.every((atom) => atomsLeft.has(atom))) {
                primaryGroups.push(atomGroup);
                agAtoms.forEach((atom) => atomsLeft.delete(atom));
                // Add child groups to subsumedGroups
                // TODO: this would be better to be generalized as MolAtomGroup.getChildGroups()
                if (atomGroup instanceof Polymer) {
                    subsumedGroups.push(...atomGroup.getResidues());
                }
            }
        }

        // Collect the extra atoms into atomGroups
        const extraAtomsMap = new Map();
        for (const atom of atomsLeft) {
            if (!extraAtomsMap.get(atom.atomGroup)) {
                extraAtomsMap.set(atom.atomGroup, []);
            }
            extraAtomsMap.get(atom.atomGroup).push(atom);
        }

        return {
            primaryGroups,
            subsumedGroups,
            extraAtomsMap,
        };
    }

    updateAtomGroupState(component, props) {
        const changed = this.atomGroupState.update(component, props);
        if (changed) {
            EventBroker.publish('atomGroupStateChanged');
        }
    }

    getAtomGroupState(atomGroup, prop) {
        const state = this.atomGroupState.getState(atomGroup);
        if (prop) {
            return state[prop];
        } else {
            return state;
        }
    }

    // Remove the compound
    removeCompound(compound) {
        const { caseData } = App.getDataParents(compound);
        const loadedCompounds = this.getLoadedCompounds();
        const compIx = loadedCompounds.findIndex((c) => c === compound);
        caseData.removeFromAtomGroupList(compound);
        this.removeObject3dAtoms(compound);
        if (this.activeCompound === compound) {
            this.activateAnotherCompound(compIx);
        } else if (this.activeCompound != null) {
            // Seems redundant, but forces redisplay when deleted cmpd isn't the active one.
            this.activateCompound(this.activeCompound);
        }

        this.systemTree.removeAllInstances(compound, 'Compounds');
    }

    /**
     * Activate a replacement when the active compound has been removed.
     * @param {number} oldIndex The position of the removed active compound in the compound list.
     */
    activateAnotherCompound(oldIndex) {
        const loadedCompounds = this.getLoadedCompounds();
        // We've deleted the active compound, so we need to activate another.
        // We'll activate the next cmpd (now at the index of the deleted cmpd),
        // unless the deleted one was in the final position.
        // If so, activate the previous cmpd.
        const newActiveIndex = (oldIndex < loadedCompounds.length) ? oldIndex : oldIndex - 1;
        const newActive = loadedCompounds[newActiveIndex];
        // activateCompound can handle a null compound when all cmpds have been deleted.
        this.activateCompound(newActive);
    }

    addFragments(caseArgs, type, atoms) {
        const caseData = this.lookupCaseData(caseArgs);
        caseData.addFragments(type, atoms);
    }

    setHotspotFragments(caseData, fragments) {
        this.removeHotspots(caseData, false);

        const hotspots = new Map(); // map from group number to Hotspot object

        for (const frag of fragments) {
            let hotspot = hotspots.get(frag.fragmentGroup);
            if (hotspot == null) {
                hotspot = new Hotspot(frag.fragmentGroup);
                hotspot.setCaseData(caseData);
                hotspots.set(frag.fragmentGroup, hotspot);
            }
            hotspot.addFragment(frag);
        }

        hotspots.forEach((hotspot) => {
            caseData.addHotspot(hotspot);
        });

        this.rebuildProteinTree();
    }

    removeHotspots(caseData, update=true) {
        const hotspots = caseData.getHotspots();
        const visibleHotspots = hotspots.filter((h) => this.isVisible(h));
        const refresh = update ? undefined : false; // undefined will update with default event
        this.toggleVisibility(visibleHotspots, false, refresh);
        this.atomGroupState.remove(hotspots);
        this.removeObject3dAtoms(hotspots);
        caseData.clearHotspots();
    }

    getComponentByTypeAndKey(type, key) {
        const listMaps = {
            Hotspot: this.getAllHotspots(),
            [AtomGroupTypes.Polymer]: this.getPolymers(),
            [AtomGroupTypes.Ion]: this.getIons(),
            [AtomGroupTypes.Cofactor]: this.getCofactors(),
            [AtomGroupTypes.Protein]: this.getProteinChains(),
        };
        switch (type) {
            case AtomGroupTypes.Compound:
            case AtomGroupTypes.Ligand:
                return this.getCompoundBySpec(key);
            default: {
                const list = listMaps[type];
                if (list) {
                    return list.find((x) => x.key === key);
                } else {
                    return null;
                }
            }
        }
    }

    changeHotspotThreshold(threshold) {
        this.hotspotThreshold = threshold;
        this.rebuildProteinTree(false);
        EventBroker.publish('hotspotsChanged');
    }

    applyTreeState(treeState) {
        this.systemTree.restoreState(treeState, this.getComponentByTypeAndKey);
    }

    isSelectedAtom(atom) {
        return this.selectedAtoms.hasAtom(atom);
    }

    isCompoundAtom(atom) {
        for (const comp of this.getLoadedCompounds()) {
            if (comp.hasAtom(atom)) {
                return true;
            }
        }

        return false;
    }

    isProteinAtom(atom) {
        return !atom.hetflag && !atom.hetatm; // hack to include ChemDoodleWeb (hetatm)
    }

    isActiveCompoundAtom(atom) {
        return (this.activeCompound && this.activeCompound.hasAtom(atom));
    }

    isVisibleCompoundAtom(atom) {
        for (const c of this.getVisibleCompounds()) {
            if (c.hasAtom(atom)) return true;
        }
        return false;
    }

    getAllComputedWaterAtoms() {
        const all = this.collectFromCaseData((caseData) => caseData.getComputedWaterAtoms());
        return all;
    }

    getAllCrystalWaterAtoms() {
        const all = this.collectFromCaseData((caseData) => caseData.getCrystalWaterAtoms());
        return all;
    }

    getAllWaterAtoms() {
        const all = this.collectFromCaseData((caseData) => caseData.getWaterAtoms());
        return all;
    }

    getAllComputedWaters() {
        const all = this.atomGroupsByType(AtomGroupTypes.ComputedWater);
        return all;
    }

    getAllCrystalWaters() {
        const all = this.atomGroupsByType(AtomGroupTypes.CrystalWater);
        return all;
    }

    getAllWaters() {
        const all = this.atomGroupsByTypes([
            AtomGroupTypes.ComputedWater, AtomGroupTypes.CrystalWater,
        ]);
        return all;
    }

    isFilteredHotspot(hotspot) {
        return hotspot.exchemPotentialAvg <= this.hotspotThreshold;
    }

    filterHotspots(hotspots) {
        return hotspots.filter(this.isFilteredHotspot);
    }

    getHotspots(mapCase) {
        const caseData = this.lookupCaseData(mapCase);
        return caseData.getHotspots();
    }

    getAllHotspots() {
        const allHotspots = this.collectFromCaseData((caseData) => caseData.getHotspots());
        return allHotspots;
    }

    getFilteredAllHotspots() {
        const allHotspots = this.getAllHotspots();
        return this.filterHotspots(allHotspots);
    }

    getSelectedAtoms() {
        return this.selectedAtoms.getAtoms();
    }

    getLoadedProtein() {
        return this.getLoadedProteins()[0];
    }

    isProteinLoaded() {
        return this.getLoadedProteins().length > 0;
    }

    getLoadedProteins() {
        return this.collectFromCaseDataCollections((cdc) => cdc.getAllCaseData())
            .map((caseData) => caseData.mapCase)
            .filter((mapCase) => mapCase);
    }

    getLoadedCompounds() {
        return this.collectFromCaseData((caseData) => caseData.getCompounds());
    }

    getIons() {
        return this.atomGroupsByType(AtomGroupTypes.Ion);
    }

    getCofactors() {
        return this.atomGroupsByType(AtomGroupTypes.Cofactor);
    }

    getPolymers() {
        return this.atomGroupsByTypes([AtomGroupTypes.PeptideLigand, AtomGroupTypes.Polymer]);
    }

    getProteinChains() {
        return this.atomGroupsByType(AtomGroupTypes.Protein);
    }

    getActiveCompound() {
        return this.activeCompound;
    }

    getLigands() {
        return this.getLoadedCompounds().filter((c) => c.isLigand());
    }

    getCompoundsByState(state={}) {
        return this.atomGroupState.query(state, this.getLoadedCompounds());
    }

    getVisibleCompounds(includeActive=true) {
        const stateQuery = { visible: true };
        const ret = [];

        // When including the active compound, it's first, so exclude it in the query
        if (includeActive && this.activeCompound) {
            ret.push(this.activeCompound);
            stateQuery.notEqual = this.activeCompound;
        }

        return ret.concat(this.getCompoundsByState(stateQuery));
    }

    getSelectedCompounds() {
        const ret = [];
        for (const treeItem of this.getSelectedTreeItems('Compounds', AtomGroupTypes.Compound)) {
            if (treeItem.item && !ret.includes(treeItem.item)) {
                ret.push(treeItem.item);
            }
        }
        return ret;
    }

    getSelectedOrActiveCompounds() {
        const compounds = this.getSelectedCompounds();
        const active = this.getActiveCompound();
        if (compounds.length === 0 && active) {
            compounds.push(active);
        }
        return compounds;
    }

    getFragments() {
        return this.atomGroupsByType(AtomGroupTypes.Fragment);
    }

    /**
     * Return a list of FragInfo objects which are either "visible" or "selected" in the tree.
     * @returns {FragInfo[]}
     */
    getVisibleFragmentInfo() {
        const { allShownFrags } = this.fragmentsTree.getVisibleFragments();
        return allShownFrags;
    }

    getVisibleFragments() {
        const visibleFragments = [];
        for (const fragInfo of this.getVisibleFragmentInfo()) {
            const state = this.atomGroupState.getState(fragInfo);
            const filterValue = state.energyFilterValue;
            visibleFragments.push(...fragInfo.fragmentObjects.filter(
                // Apply fragment energy filter
                (f) => filterValue == null || (f.exchemPotential <= filterValue),
            ));
        }
        return visibleFragments;
    }

    /**
     * Find svg data for a fragment, by looking in all available fragment info lists for the name.
     * Optionally look in only the available fragments for the given projectCase.
     * @param {string} fragName
     * @param {string} projectCase
     * @returns {string} svg data or the empty string if the fragment wasn't found
     */
    async getSvgForFragment(fragName, projectCase) {
        for (const caseData of this.allCaseData()) {
            if (projectCase && caseData.mapCase?.projectCase !== projectCase) {
                continue;
            }
            const svg = await caseData.getAvailableFragmentInfo().getFragImage(fragName);
            if (svg) {
                return svg;
            }
        }
        return '';
    }

    /**
     * Find FragInfo for a fragment, by looking in all available fragment info lists for the name.
     * Optionally look in only the available fragments for the given projectCase.
     * @param {string} fragName
     * @param {string} projectCase
     * @returns {FragInfo|null} FragInfo or the empty string if the fragment wasn't found
     */
    getInfoForFragment(fragName, projectCase) {
        for (const caseData of this.allCaseData()) {
            if (projectCase && caseData.mapCase?.projectCase !== projectCase) {
                continue;
            }
            const fragInfo = caseData.getAvailableFragmentInfo().findByName(fragName);
            if (fragInfo) {
                return fragInfo;
            }
        }
        return null;
    }

    /**
     * Return waters that are marked as visible in the Selector.
     * NOTE: This does not consider the water style of the display state.
     */
    getVisibleWaterInfo() {
        const visibleComp = new Set();
        const visibleCrystal = new Set();
        for (const caseData of this.allCaseData()) {
            const computedWaters = caseData.getComputedWaterCollection();
            const crystalWaters = caseData.getCrystalWaterCollection();
            if (this.isVisible(computedWaters)) {
                computedWaters.getAtomGroups().forEach((wg) => visibleComp.add(wg));
            }
            if (this.isVisible(crystalWaters)) {
                crystalWaters.getAtomGroups().forEach((wg) => visibleCrystal.add(wg));
            }
        }
        return {
            visibleComputedSet: visibleComp,
            visibleCrystalSet: visibleCrystal,
        };
    }

    getActiveFragments(caseData) {
        return this.atomGroupState
            .query({ [MolAtomGroupState.Active]: true, type: 'FragInfo' })
            .filter((ag) => caseData.hasFragInfo(ag));
    }

    getClusteringFragments(caseData) {
        return this.atomGroupState
            .query({ usedForHotspots: true, type: 'FragInfo' })
            .filter((ag) => caseData.hasFragInfo(ag));
    }

    // Unfortunately, removing fragments requires a bunch of bookkeeping
    removeFragments(frags, dataParents) {
        if (!frags || frags.length === 0) return;

        for (const frag of frags) {
            const { caseData, caseDataCollection } = dataParents || App.getDataParents(frag);
            // remove from serial map in decoder
            frag.getAtoms().forEach((atom) => caseDataCollection.removeAtomFromMap(atom));
            caseData.removeFromAtomGroupList(frag);
        }

        this.removeObject3dAtoms(frags);
    }

    /**
     * Detects if compounds are clashing
     * @param {Compound|Compound[]} compoundOrCompounds
     * @returns {Compound[]} Only the clashing compounds
     * @todo Look into optimizing this with bounding box, etc
     */
    detectClashes(compoundOrCompounds, clashOptions={}) {
        const cmpdList = ensureArray(compoundOrCompounds);
        const compoundMap = new Map();

        const { dataParentsMap } = App.partitionByDataParents(cmpdList);
        for (const [{ caseData }, cmpds] of dataParentsMap.entries()) {
            const soluteAtoms = MolAtomGroup.atomsInAtomGroups(caseData.getProteinChains());

            for (const cmpd of cmpds) {
                const clashingAtoms = atomsClashing(cmpd.getAtoms(), soluteAtoms, clashOptions);
                if (clashingAtoms) {
                    compoundMap.set(cmpd, clashingAtoms);
                }
            }
        }

        return { compoundMap, clashOptions };
    }

    groupCreate(name, treeName, path) {
        return this.systemTree.createGroup(name, treeName, path);
    }

    groupItems(items, treeName, path, groupInfo) {
        return this.systemTree.createGroupFromItems(items, treeName, path, groupInfo);
    }

    groupRename(group, newName) {
        this.systemTree.renameGroup(group, newName);
    }

    groupUngroup(group, treeName) {
        this.systemTree.ungroup(group, treeName);
    }

    groupDelete(group, treeName) {
        this.systemTree.deleteGroup(group, treeName);
        this.ensureCompoundsInTree();
    }

    /**
     * groupSort() - Sort a group in the tree control
     * @param {TreeGroup} group
     * @param {{ sortType: "Alphabetical" | "EnergyScore" }} sortOptions
     */
    groupSort(group, sortOptions) {
        this.systemTree.sortGroup(group, sortOptions);
    }

    ensureCompoundsInTree() {
        const cmpds = this.getLoadedCompounds();
        const treeCmpds = this.getTreeItemsForItems('Compounds', cmpds).map((x) => x.item);
        for (const cmpd of cmpds) {
            if (!treeCmpds.includes(cmpd)) {
                console.warn(`Adding compound ${cmpd.resSpec} after deleting host group.`);
                this.systemTree.addToCompoundsTree(cmpd);
            }
        }
        this.systemTree.trigger();
    }

    getSelectedTreeItems(treeName, constraints) {
        const selected = (x) => this.isSelected(x)
            && (!constraints || (
                (!constraints.type || constraints.type === x.type)
                && (!constraints.list || constraints.list.includes(x))
            ));
        const tree = this.systemTree.getTree(treeName);
        const ret = [];
        TreeUtils.traverse(tree.children, (item) => {
            if (selected(item) || selected(item.item)) {
                ret.push(item);
            }
        });
        return ret;
    }

    getTreeItemsForItems(treeName, items) {
        return this.systemTree.findLeavesForItems(items, treeName);
    }

    getActiveCompoundAtoms() {
        if (this.activeCompound) {
            return this.activeCompound.getAtoms();
        } else {
            return [];
        }
    }

    getVisibleCompoundAtoms() {
        return MolAtomGroup.atomsInAtomGroups(this.getVisibleCompounds());
    }

    getAllCompoundAtoms() {
        return MolAtomGroup.atomsInAtomGroups(this.getLoadedCompounds());
    }

    getCompoundBySpec(ligandSpec) {
        return this.getLoadedCompounds().find((comp) => comp.resSpec === ligandSpec);
    }

    getCompoundByAtom(atom) {
        return this.getLoadedCompounds().find((comp) => comp.hasAtom(atom));
    }

    getCompoundsByName(name) {
        return this.getLoadedCompounds().filter((comp) => comp.resname === name);
    }

    caseDataForCompound(compound) {
        return this.allCaseData().find(
            (caseData) => caseData.getCompounds().includes(compound)
        );
    }

    clearSelection() {
        this.selectedAtoms.clear();
    }

    addSelectedAtom(atom) {
        this.selectedAtoms.addAtom(atom);
    }

    removeSelectedAtom(atomsIn) {
        const atoms = [].concat(atomsIn);
        this.selectedAtoms.removeAtoms(atoms);
    }

    selectedAtomCount() {
        return this.selectedAtoms.atomCount();
    }

    setWorkingBindingSite(bindingSite) {
        this.workingBindingSite = bindingSite;
    }

    saveNewBindingSite(refObj) {
        const bindingSite = BindingSite.calculate(this, refObj);
        this.setWorkingBindingSite(bindingSite);
        return bindingSite;
    }

    getBindingSite(refObj=null) {
        return this.workingBindingSite || this.saveNewBindingSite(refObj);
    }

    get DisplayState() {
        return this.displayState;
    }

    addObject3dAtoms(objectsIn) {
        const objects = [].concat(objectsIn);
        const allAtoms = objects.reduce((acc, next) => acc.concat(next.getAtoms()), []);
        EventBroker.publish('addAtoms', { atoms: allAtoms });
    }

    removeObject3dAtoms(objectsIn) {
        const objects = [].concat(objectsIn);
        const allAtoms = objects.reduce((acc, next) => acc.concat(next.getAtoms()), []);
        this.removeSelectedAtom(allAtoms);
        EventBroker.publish('removeAtoms', allAtoms);
    }

    /**
     * @description Restore atomgroup state including visibility
     * selection, etc
     * @param {*} stateObj - An object keyed by types, containing lists
     * of atomgroup state objects of the form: {key, state}.
     * key should be a unique value among all instances the type.
     * Ex: {Compound: [
     *          {key: 4K6.301:A, state: {visible: true}},
     *          {key: cmpd_1, state: {visibile: true}},
     *      ],
     *      Protein: [ ... ],
     *      FragInfo: [... ]
     *
     * FragInfo is the only class which is not an MolAtomGroup.
     */
    restoreAtomGroupState(stateObj, caseData) {
        if (!stateObj) return;

        for (const [type, entriesByType] of Object.entries(stateObj)) {
            let allOfType;
            if (type === 'FragInfo') {
                allOfType = caseData.availableFragmentInfo.items();
            } else if (type === 'Hotspot') {
                allOfType = caseData.getHotspots();
            } else {
                allOfType = caseData.atomGroupsByType(type);
            }

            for (const stored of entriesByType) {
                const { key, state } = stored;
                const ag = allOfType.find((x) => x.key === key);
                if (ag) {
                    this.atomGroupState.setState(ag, state);
                    // Assign this object so the caller can act on it if necessary
                    stored.object = ag;
                } else {
                    console.warn(`Couldn't restore state for ${type}.${key}`);
                }
            }
        }

        EventBroker.publish('visibilityChanged');
        EventBroker.publish('atomGroupStateChanged');
    }
}
