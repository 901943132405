/* TestConsole.jsx */

import React from 'react';
import { addMenuItem } from '../../menu_cmds';
import { TestCase, GetSampleTestCases } from './TestCase';
import { SidePanelContainer } from '../../ui/info_display/SidePanel';
import { UserActions } from '../../cmds/UserAction';

// import LinkLikeButton from '../../ui/common/LinkLikeButton';
// Note: I'd prefer to import LinkLikeButton instead of using this.
// However, the way the app gets loaded, importing LinkLikeButton here ends up adding a
// stylesheet for MuiLink a second time, overriding some of the styled component classes.
// Rather not dig into it just for the TestConsole.
// This MUI GitHub issue seems related but recommended fixes didn't seem to apply:
//     https://github.com/mui-org/material-ui/issues/15610
function LinkLikeButton({
    className, style, onClick, children,
}) {
    return (
        <button
            type="button"
            className={`blueTextButton ${className}`}
            style={style}
            onClick={onClick}
        >
            { children }
        </button>
    );
}

export class TestConsole extends React.PureComponent {
    static Init() {
        addMenuItem(
            'OpenTestConsole',
            'Open Test Console',
            () => {
                UserActions.OpenSidePanel('left', {
                    pageId: 'TestConsole',
                    component: <TestConsole />,
                    longLived: true,
                });
            }
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            testCases: GetSampleTestCases(),
            output: [],
        };
        this.addTestCase = this.addTestCase.bind(this);
        this.log = this.log.bind(this);
    }

    addTestCase(testCase) {
        const { testCases } = this.state;
        testCases.push(new TestCase(testCase));
        this.setState({ testCases });
        this.forceUpdate();
    }

    log(message) {
        const { output } = this.state;
        output.push(message);
        this.forceUpdate();
    }

    render() {
        const { testCases, output } = this.state;
        const { open, handleClose } = this.props;

        return (
            <SidePanelContainer title="Test Console" open={open} handleClose={handleClose}>
                <div>
                    <RandomJSInput log={this.log} />
                    <TestCaseList
                        testCases={testCases}
                        addTestCase={this.addTestCase}
                        log={this.log}
                    />
                    <TestConsoleOutput lines={output} />
                </div>
            </SidePanelContainer>
        );
    }
}

class TestCaseList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
        };
        this.setSelected = this.setSelected.bind(this);
    }

    setSelected(selectedTestCase) {
        const { selected } = this.state;
        if (selectedTestCase !== selected) {
            this.setState({ selected: selectedTestCase });
        } else {
            this.setState({ selected: null });
        }
    }

    render() {
        const { testCases, log, addTestCase } = this.props;
        const { selected } = this.state;
        const clickHandler = this.setSelected;

        const tcs = testCases.map((tc) => (
            <li key={tc.name} style={{ background: 'white' }}>
                <TestCaseComponent
                    testCase={tc}
                    clickHandler={clickHandler}
                    onClick={() => { console.log(`Clicked on ${tc.name}`); clickHandler(tc); }}
                    selected={selected === tc}
                    log={log}
                />
            </li>
        ));
        return (
            <div className="testconsole-section">
                <div className="testconsole-section-heading">Test Cases</div>
                <ul>
                    {tcs}
                    <TestCaseAdder addTestCase={addTestCase} />
                </ul>
            </div>
        );
    }
}

class TestCaseComponent extends React.Component {
    constructor(props) {
        super(props);
        this.run = this.run.bind(this);
    }

    async run() {
        const { log, testCase } = this.props;
        if (log) {
            log(`Executing TestCase ${testCase.name}...`);
        }
        const { result, output } = await testCase.Execute(log);

        if (log) {
            for (const line of output) log(line);
            log(`TestCase ${testCase.name} finished with result ${result}`);
        }
    }

    updateCode(code) {
        const { testCase } = this.props;
        testCase.updateCode(code);
        this.forceUpdate();
    }

    render() {
        const { selected, clickHandler, testCase } = this.props;
        const nameClass = selected ? 'selected' : '';

        return (
            <div className="TestCase">
                <div>
                    <LinkLikeButton
                        className={nameClass}
                        style={{ fontSize: 'smaller' }}
                        onClick={() => clickHandler(testCase)}
                    >
                        {testCase.name}
                    </LinkLikeButton>
                    <button type="button" onClick={this.run}>Run</button>
                </div>
                {
                    selected
                        ? (
                            <CodeArea
                                code={testCase.code}
                                updateCode={(code) => this.updateCode(code)}
                            />
                        )
                        : null
                }
            </div>
        );
    }
}

class TestCaseViewer extends React.PureComponent {
    onChange(evt) {
        const { testCase } = this.props;
        testCase.code = evt.target.value;
        this.forceUpdate();
    }

    render() {
        const { testCase } = this.props;
        const code = testCase.code.trim();
        const height = 30 * code.split('\n').length;
        return (
            <textarea
                className="testcase-viewer"
                value={code}
                style={{ height: `${height}px` }}
                onChange={(evt) => this.onChange(evt)}
            />
        );
    }
}

class TestCaseAdder extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            cmdName: '',
        };
        this.show = this.show.bind(this);
        this.reset = this.reset.bind(this);
        this.updateCmdName = this.updateCmdName.bind(this);
        this.submitCmdName = this.submitCmdName.bind(this);
    }

    submitCmdName(evt) {
        const { addTestCase } = this.props;
        const { cmdName } = this.state;
        addTestCase(cmdName);
        this.reset();
        evt.preventDefault();
    }

    updateCmdName(evt) {
        this.setState({ cmdName: evt.target.value });
    }

    show() {
        this.setState({ visible: true });
    }

    reset() {
        this.setState({ visible: false, cmdName: '' });
    }

    render() {
        const { visible, cmdName } = this.state;
        if (visible) {
            return (
                <div>
                    <form onSubmit={this.submitCmdName}>
                        <input placeholder="New case name" value={cmdName} onChange={this.updateCmdName} />
                        <input type="submit" value="Add TestCase" />
                        {' '}
                        <LinkLikeButton style={{ color: 'black' }} onClick={this.reset}>
                            <i className="fa fa-close" />
                        </LinkLikeButton>
                    </form>
                </div>
            );
        } else {
            return (
                <LinkLikeButton onClick={this.show}>
                    <i className="fa fa-plus" />
                    {' '}
                    Add Test Case
                </LinkLikeButton>
            );
        }
    }
}

class CodeArea extends React.PureComponent {
    constructor(props) {
        super(props);
        const { initialCode } = this.props;
        this.state = { code: initialCode || '' };
    }

    updateCode(evt) {
        const code = evt.target.value;
        const { updateCode } = this.props;

        if (updateCode) {
            updateCode(code);
        } else {
            this.setState({ code });
        }
    }

    render() {
        const { code: propsCode } = this.props;
        const { code: stateCode } = this.state;
        const code = propsCode || stateCode;
        const height = 30 * code.split('\n').length;

        return (
            <textarea
                className="testconsole-code"
                value={code}
                onChange={(evt) => this.updateCode(evt)}
                style={{ height: `${height}px` }}
            />
        );
    }
}

class RandomJSInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { code: '' };
    }

    onUpdateCode(code) {
        this.setState({ code });
    }

    async execute() {
        const { log } = this.props;
        const { code } = this.state;
        if (log) {
            log('Executing code from RandomJSInput...');
        }

        const { result, output } = await TestCase.ExecuteCode(code);

        if (log) {
            for (const line of output) log(line);
            log(`Code from RandomJSInput finished with result ${result}`);
        }
    }

    render() {
        const { code } = this.state;
        return (
            <div className="testconsole-section">
                <div className="testconsole-section-heading">Execute Javascript</div>
                <CodeArea code={code} updateCode={(newCode) => this.onUpdateCode(newCode)} />
                <button type="button" onClick={() => this.execute()}>Execute</button>
            </div>
        );
    }
}

function TestConsoleOutput({ lines }) {
    return (
        <div className="testconsole-section">
            <div className="testconsole-section-heading">Output</div>
            <div className="testconsole-output">
                <pre>
                    {lines.map((line, i) => <div key={`LogLine:${i.toString()}`} className="testconsole-outputline">{line}</div>)}
                </pre>
            </div>
        </div>
    );
}
