import styled from 'styled-components';

import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Input from '@mui/material/Input';
import { Box, Button } from '@mui/material';

const InputBox = styled(Box)`
width: 50%;
@media (max-width: 1000px) {
    width: 100%;
  }
`;

const PlaceHolder = styled.div`
position: relative;
text-align: center;
padding: 10px;
visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
color: gray;
top: 10px;
max-height: 10px;
margin-bottom: -30px;
left: 50%;
transform: translateX(-50%);
z-index: 1;
`;

const InputFile = styled(Input)`
display: none;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
display: block;
width: 100%;
min-width: 200px;
height: 57px;
padding-top: 10px;
margin: 0;
`;

// I tried using LinkLikeButton for this, but clicking it would prematurely trigger form validation.
// Skipping that and just using UploadButton for now.
const UploadButton = styled(Button)`
    text-transform: none;
    color: blue;
    vertical-align: baseline;
    padding: 0;
    min-width: unset;
    &:hover {
        background-color: white;
    }
`;

function ImportErrors({ errors }) {
    return (
        <div>
            <Alert severity="error">{errors.map((err) => <p>{err}</p>)}</Alert>
        </div>
    );
}

export {
    InputBox, PlaceHolder, InputFile, UploadButton, FormControlLabelStyled, ImportErrors,
};
