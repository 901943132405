import { App } from '../BMapsApp';
import { UserActions } from '../cmds/UserAction';
import DataConnection from '../DataConnection';
import { BFDServerInterface } from './BFDServerInterface';
import { ServerConnection } from './ServerConnection';
import ServerMonitor from './ServerMonitor';
import { StaticDataConnection } from './StaticDataConnection';
import { WebSocketStrategy } from './WebSocketStrategy';
import { Log } from '../Log';

export class ConnectionManager {
    /** @param {WebSocketStrategy} websocketStrategy */
    constructor(websocketStrategy) {
        /** @type { WebSocketStrategy } */
        this.websocketStrategy = websocketStrategy;
        /** @type { DataConnection[] } */
        this.dataConnections = [];
        this.getDataConnections = this.getDataConnections.bind(this);
        this.doRemoveAll = this.doRemoveAll.bind(this);
        this.serverMonitor = new ServerMonitor(this.getDataConnections, this.doRemoveAll);
    }

    dumpState() {
        let report = '';
        for (const [index, dataConnection] of this.dataConnections.entries()) {
            const { connector, caseDataCollection } = dataConnection.get();
            report += `Connection ${index + 1}: ${connector.getLabel()}\n\t${caseDataCollection.dumpState()}\n`;
        }
        return report;
    }

    /**
     * Setup and return a new DataConnection for a bfd-server connection
     */
    async newServerConnection(replaceOld, allowUnused=true) {
        Log.info(`ConnectionManager: Requesting new server connection. replaceOld: ${replaceOld}, allowUnused: ${allowUnused}`);
        if (allowUnused) {
            const unused = this.getUnusedDataConnection(ServerConnection);
            if (unused) return { dataConnection: unused };
        }

        const { websocket, errorInfo } = await this.websocketStrategy.getWebsocket(replaceOld);
        if (!websocket) {
            return { errorInfo };
        }
        const serverConnection = new ServerConnection(websocket);
        return this.setupDataConnection(serverConnection);
    }

    /**
     * Setup and return a new DataConnection for static data.
     */
    async newStaticConnection(allowUnused=true) {
        Log.info(`ConnectionManager: Requesting new static connection. allowUnused: ${allowUnused}`);

        if (allowUnused) {
            const unused = this.getUnusedDataConnection(StaticDataConnection);
            if (unused) return { dataConnection: unused };
        }

        const staticConn = new StaticDataConnection(App.Workspace);
        return this.setupDataConnection(staticConn);
    }

    /**
     * Finish setting up a DataConnection for a BFDServerInterface
     * @param { BFDServerInterface } connector A connector which has been established
     * @returns { { dataConnection: DataConnection } }
     */
    async setupDataConnection(connector) {
        Log.info(`ConnectionManager: Finishing connection for ${connector.getLabel()}`);
        const dataConnection = new DataConnection(connector);
        this.addDataConnection(dataConnection);
        await UserActions.ZapConnection(dataConnection);
        await UserActions.FetchConnectionMaps(dataConnection);
        await dataConnection.getPermissionManager().refresh();
        return { dataConnection };
    }

    /**
     * Return an unused data connection with a specified connector type.
     * An unused connection has only 1 case data, the one for the no protein case.
     * @returns { DataConnection? }
     */
    getUnusedDataConnection(type) {
        const [unused] = this.dataConnections.filter((dataConn) => {
            const { connector, caseDataCollection } = dataConn.get();
            return connector instanceof type && caseDataCollection.isEmpty();
        });
        let label = 'unknown server type';
        if (type === ServerConnection) label = 'server';
        else if (type === StaticDataConnection) label = 'static';
        Log.info(`ConnectionManager: trying to get unused ${label} connection. Found: ${unused?.getLabel() || 'none'}`);
        return unused;
    }

    getDataConnections() {
        return [...this.dataConnections];
    }

    addDataConnection(dataConnection) {
        this.dataConnections.push(dataConnection);
    }

    /**
     * Remove from the list, remove the individual caseDatas from Workspace, zap the connection
     * @param {*} dataConnection
     */
    async removeDataConnection(dataConnection) {
        Log.info(`ConnectionManager: removing data connection: ${dataConnection.getLabel()}`);
        const index = this.dataConnections.indexOf(dataConnection);
        if (index >= 0) {
            this.dataConnections.splice(index, 1);
        }
        for (const caseData of dataConnection.getCaseDataCollection().getAllCaseData()) {
            App.Workspace.removeCase(caseData);
        }

        dataConnection.zap(false); // don't await here in case of lost connection

        const connector = dataConnection.getConnector();
        if (connector instanceof ServerConnection) {
            connector.close();
            await this.websocketStrategy.finishConnection(connector.ws);
        }
    }

    // Passed into and called by serverMonitor
    // Alternatively, we could add a function like ServerMonitor.runInLoop that could take any fn.
    doRemoveAll() {
        return Promise.all(
            this.getDataConnections().map((dc) => this.removeDataConnection(dc))
        );
    }

    /**
     * Removes all connections, executing in the context of the server monitor loop.
     * @returns {Promise} Promise resolves when operation finishes
     */
    removeAllConnections() {
        return this.serverMonitor.removeAll();
    }

    removeConnector(connector) {
        const dc = connector.getDataConnection();
        return this.removeDataConnection(dc);
    }
}
