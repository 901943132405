import { EventBroker } from '../eventbroker';
import { AtomGroupTypes, AtomGroupCollection, MolAtomGroup } from './atomgroups';
import { FragList } from '../FragList';
import { InvertRTTransform } from '../math';
import { transformMolString } from '../util/transform_utils';

/**
 * This class encapsulates all data associated with a loaded project case, including
 * the crystal structure, user compounds, fragments, hotspots, available fragment info,
 * and starter compound info.
 *
 * The atomGroup object contains lists of each atom group type.
 * Hotspots may join the official atom group types but for now they are tracked separately as
 * a group of Fragment AtomGroups, instead of a group of Atoms.
 */
export default class CaseData {
    constructor(mapCase) {
        // FragList defaults to "loading" state, so initialize no-protein case to empty list.
        this.availableFragmentInfo = mapCase ? new FragList() : new FragList([]);
        this.hotSpotClusters = [];
        this.computedWaterCollection = new AtomGroupCollection();
        this.crystalWaterCollection = new AtomGroupCollection();
        this.atomGroups = {};
        this.sampleCompoundInfo = new StarterCompounds();
        this.mapCase = mapCase;
        this.referenceCaseData = null;
        this.wholeProteinTransform = null;
        this.caseDataCollection = null;
    }

    toString() { return this.getName(); }

    getCaseDataCollection() { return this.caseDataCollection; }
    setCaseDataCollection(caseDataCollection) { this.caseDataCollection = caseDataCollection; }

    dumpState() {
        const atomGroupReport = Object.entries(this.atomGroups).reduce(
            (acc, [key, value]) => `${acc}${value.length} ${key}s; `,
            ''
        );
        const availableFrags = this.availableFragmentInfo.items();
        return `CaseData dump for MapCase ${this.mapCase?.uri}: ${availableFrags.length} available fragments; ${this.hotSpotClusters.length} hotspots; ${atomGroupReport}`;
    }

    /** Return true if the case data has no protein, compounds, or hotspots */
    isEmpty() {
        return this.allAtomGroups().length === 0 && this.hotSpotClusters.length === 0;
    }

    getName() {
        return this.mapCase?.getNodeName() || 'No protein';
    }

    getShortName() {
        if (!this.mapCase) return 'No protein';
        return this.mapCase.pdbID;
    }

    allAtomGroups() {
        const ret = [];
        for (const groupsByType of Object.values(this.atomGroups)) {
            for (const atomGroup of groupsByType) {
                ret.push(atomGroup);
            }
        }
        return ret;
    }

    atomGroupsByType(type) {
        return [].concat(this.internalAtomGroupsByType(type));
    }

    atomGroupsByTypes(types) {
        const ret = [];
        for (const type of types) {
            for (const atomGroup of this.internalAtomGroupsByType(type)) {
                ret.push(atomGroup);
            }
        }
        return ret;
    }

    internalAtomGroupsByType(type) {
        if (!this.atomGroups[type]) this.atomGroups[type] = [];
        return this.atomGroups[type];
    }

    addAtomGroup(atomGroup) {
        const type = atomGroup.type;
        if (!this.atomGroups[type]) this.atomGroups[type] = [];
        this.atomGroups[type].push(atomGroup);
        atomGroup.setCaseData(this);

        // TODO: Consider changing atomGroups map to use AtomGroupCollections
        switch (type) {
            case AtomGroupTypes.CrystalWater:
                this.crystalWaterCollection.addAtomGroup(atomGroup);
                break;
            case AtomGroupTypes.ComputedWater:
                this.computedWaterCollection.addAtomGroup(atomGroup);
                break;
            // no default
        }
    }

    addAtomGroups(atomGroups) {
        atomGroups.forEach((atomGroup) => { this.addAtomGroup(atomGroup); });
    }

    hasAtomGroup(atomGroup) {
        return this.internalAtomGroupsByType(atomGroup.type).includes(atomGroup);
    }

    hasFragInfo(fragInfo) {
        return this.availableFragmentInfo.hasFragInfo(fragInfo);
    }

    hasCrystalWaters() {
        return !this.crystalWaterCollection.isEmpty();
    }

    hasComputedWaters() {
        return !this.computedWaterCollection.isEmpty();
    }

    addFragments() {
        console.warn('CaseData.addFragments is NYI 🧐');
    }

    getAvailableFragmentInfo() {
        return this.availableFragmentInfo;
    }

    areFragmentsAvailable() {
        return this.availableFragmentInfo.anyAvailable();
    }

    getCompounds() {
        return this.atomGroupsByTypes(MolAtomGroup.CompoundTypes);
    }

    getLigands() {
        return this.getCompounds().filter((c) => c.isLigand());
    }

    getSolute() {
        return this.atomGroupsByTypes(MolAtomGroup.SoluteTypes);
    }

    getIons() {
        return this.atomGroupsByType(AtomGroupTypes.Ion);
    }

    getCofactors() {
        return this.atomGroupsByType(AtomGroupTypes.Cofactor);
    }

    getResidues() {
        return this.atomGroupsByType(AtomGroupTypes.Residue);
    }

    getPolymers() {
        return this.atomGroupsByTypes(MolAtomGroup.PolymerTypes);
    }

    getProteinChains() {
        return this.atomGroupsByType(AtomGroupTypes.Protein);
    }

    getComputedWaters() {
        return this.atomGroupsByType(AtomGroupTypes.ComputedWater);
    }

    getCrystalWaters() {
        return this.atomGroupsByType(AtomGroupTypes.CrystalWater);
    }

    getFragments() {
        return this.atomGroupsByType(AtomGroupTypes.Fragment);
    }

    getCompoundBySpec(cmpdSpec) {
        return this.getCompounds().find((comp) => comp.resSpec === cmpdSpec);
    }

    getAtomsForTypes(types) {
        const groups = this.atomGroupsByTypes(types);
        return MolAtomGroup.atomsInAtomGroups(groups);
    }

    getSoluteAtoms() {
        return MolAtomGroup.atomsInAtomGroups(this.getSolute());
    }

    getWaterAtoms() {
        return MolAtomGroup.atomsInAtomGroups(
            [...this.getCrystalWaters(), ...this.getComputedWaters()]
        );
    }

    getComputedWaterAtoms() {
        return MolAtomGroup.atomsInAtomGroups(this.getComputedWaters());
    }

    getCrystalWaterAtoms() {
        return MolAtomGroup.atomsInAtomGroups(this.getCrystalWaters());
    }

    getFragmentAtoms() {
        return MolAtomGroup.atomsInAtomGroups(this.getFragments());
    }

    getCompoundAtoms() {
        return MolAtomGroup.atomsInAtomGroups(this.getCompounds());
    }

    getHotspots() {
        return this.hotSpotClusters;
    }

    addHotspot(hotspot) {
        this.hotSpotClusters.push(hotspot);
    }

    clearHotspots() {
        this.hotSpotClusters = [];
    }

    setAvailableFragments(availableFragments) {
        this.availableFragmentInfo.setAvailableFragments(availableFragments);
        this.availableFragmentInfo.items().forEach((fragInfo) => { fragInfo.setCaseData(this); });
    }

    removeFromAtomGroupList(atomGroupsIn) {
        const atomGroups = [].concat(atomGroupsIn);
        if (!atomGroups || atomGroups.length === 0) return;
        const type = atomGroups[0].type;
        const internalList = this.internalAtomGroupsByType(type);
        for (const x of atomGroups) {
            if (x.type !== type) console.warn(`Trying to remove atomgroup ${x.type}.${x.key} from list of ${type}.`);
        }
        this.atomGroups[type] = internalList.filter((x) => !atomGroups.includes(x));
    }

    replaceInAtomGroupList(oldAtomGroup, newAtomGroup) {
        const list = this.internalAtomGroupsByType(oldAtomGroup.type);
        const index = list.findIndex((atomGroup) => atomGroup === oldAtomGroup);
        if (index >= 0) {
            list.splice(index, 1, newAtomGroup);
        }
        newAtomGroup.setCaseData(this);
    }

    findAtomByUid(uid) {
        return this.caseDataCollection?.findAtomByUid(uid);
    }

    getSampleCompoundInfo() { return this.sampleCompoundInfo; }

    getComputedWaterCollection() { return this.computedWaterCollection; }
    getCrystalWaterCollection() { return this.crystalWaterCollection; }

    getReferenceCaseData() { return this.referenceCaseData; }
    setReferenceCaseData(caseData) { this.referenceCaseData = caseData; }
    setWholeProteinTransform(transform) { this.wholeProteinTransform = transform; }
    getWholeProteinTransform() { return this.wholeProteinTransform; }

    getCommonAlignmentReference(otherCaseData) {
        const myAlignmentRef = this.getReferenceCaseData();
        const otherAlignmentRef = otherCaseData.getReferenceCaseData();

        let commonAlignmentRef = null;

        if (!myAlignmentRef && !otherAlignmentRef) {
            // Neither are aligned. Nothing to do in this case, already null
        } else if (myAlignmentRef === otherAlignmentRef) {
            // Both are aligned to the same reference
            commonAlignmentRef = myAlignmentRef;
        } else if (!myAlignmentRef && otherAlignmentRef === this) {
            // If Case A doesn't have an alignment reference, the only way to have a common ref
            // is if Case A is itself the alignment reference for Case B
            commonAlignmentRef = this;
        } else if (!otherAlignmentRef && myAlignmentRef === otherCaseData) {
            commonAlignmentRef = otherCaseData;
        } else {
            // This is the case where the refs are both defined, but don't match
            // We could be clever about checking those refs to see if they also have refs,
            // and search the family tree, but don't bother for now.
        }
        console.log(`Common alignment reference for ${this} and ${otherCaseData} is ${commonAlignmentRef}`);
        return commonAlignmentRef;
    }

    getTransformTo(toCaseData) {
        return CaseData.getTransformFromTo(this, toCaseData);
    }

    /**
     * Get transform from one case data raw coordinates (before alignment) to another's
     * @param {CaseData} fromCaseData
     * @param {CaseData} toCaseData
     */
    static getTransformFromTo(fromCaseData, toCaseData) {
        const commonReferenceFrame = fromCaseData.getCommonAlignmentReference(toCaseData);
        if (!commonReferenceFrame) {
            // Nothing we can do.
            return null;
        } else if (commonReferenceFrame === fromCaseData) {
            // toCasaData is aligned to fromCaseData. The toCaseData transform transforms
            // toCaseData coordinates to fromCaseData coordinates.
            // So apply a reverse toCaseData transform to go from fromCaseData to toCaseData coords.
            const transform = toCaseData.getWholeProteinTransform();
            return InvertRTTransform(transform);
        } else if (commonReferenceFrame === toCaseData) {
            // fromCasaData is aligned to toCaseData. The fromCaseData transform transforms
            // fromCaseData coordinates to toCaseData coordinates.
            // So apply the fromCaseData transform to go from fromCaseData to toCaseData coords.
            return fromCaseData.getWholeProteinTransform();
        } else {
            // to- and fromCaseData are both aligned to a third. We need to do both operations:
            // First go from fromCaseData to common: apply fromCaseData transform
            // Then go from common to toCaseData: apply reverse toCaseData transform
            console.warn('Can\'t multiple combine transforms yet!');
            return null;
        }
    }

    /** Transform molecular data from the coordinate system of one CaseData to another */
    static transformMolData(data, format, fromCaseData, toCaseData) {
        switch (format) {
            case 'sdf': case 'mol': {
                const transform = fromCaseData.getTransformTo(toCaseData);
                const newMol = transformMolString(data, transform);
                return newMol;
            }
            default:
                console.warn(`transformMolDataForProtein: don't know how to transform ${format}`);
                return data;
        }
    }

    /** Transform molecular data from this coordinate system to that of another CaseData */
    transformMolDataFor(data, format, toCaseData) {
        return CaseData.transformMolData(data, format, this, toCaseData);
    }

    /** Transform molecular data from the coordinate system of another CaseData to this one */
    transformMolDataFrom(data, format, fromCaseData) {
        return CaseData.transformMolData(data, format, fromCaseData, this);
    }
}

export class StarterCompounds {
    constructor() {
        this.reset();
    }

    reset() {
        this.availability = StarterCompounds.Unavailable;
    }

    isAvailable() { return this.availability === StarterCompounds.Available; }
    getAvailability() { return this.availability; }
    setAvailable() { this.availability = StarterCompounds.Available; this.fire(); }
    setDismissed() { this.availability = StarterCompounds.Dismissed; this.fire(); }
    setLoading() { this.availability = StarterCompounds.Loading; this.fire(); }
    setLoaded() { this.availability = StarterCompounds.Loaded; this.fire(); }
    fire() { EventBroker.publish('starterAvailability'); }
}

StarterCompounds.Available = 'available';
StarterCompounds.Loading = 'loading';
StarterCompounds.Unavailable = 'unavailable';
StarterCompounds.Dismissed = 'dismissed';
StarterCompounds.Loaded = 'loaded';
