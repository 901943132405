import React from 'react';
import { EventBroker } from './eventbroker';

export class LoadingScreen extends React.PureComponent {
    static async withLoadingScreen(promise, loadMsg) {
        EventBroker.publish('displayLoadingScreen', { on: true, msg: loadMsg });
        try {
            return await promise;
        } finally {
            EventBroker.publish('displayLoadingScreen', { on: false, msg: '' });
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.connectionStatusChangedHandler = this.connectionStatusChangedHandler.bind(this);
        this.displayLoadingScreenHandler = this.displayLoadingScreenHandler.bind(this);
    }

    componentDidMount() {
        EventBroker.subscribe('displayLoadingScreen', this.displayLoadingScreenHandler);
        EventBroker.subscribe('connectionStatusChanged', this.connectionStatusChangedHandler);
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('displayLoadingScreen', this.displayLoadingScreenHandler);
        EventBroker.unsubscribe('connectionStatusChanged', this.connectionStatusChangedHandler);
    }

    connectionStatusChangedHandler(evtName, { connected }) {
        if (!connected) this.hide();
    }

    displayLoadingScreenHandler(evt, { on, msg }={}) {
        if (on) {
            this.show(msg);
        } else {
            this.hide();
        }
    }

    show(msg) {
        this.setState({ visible: true, msg });
    }

    hide() {
        this.setState({ visible: false, msg: '' });
    }

    render() {
        const { visible, msg } = this.state;
        return visible && (
            <div id="new_loading_pane" className="modalViewer">
                {msg}
                {' '}
                <i className="fa fa-cog fa-spin" />
            </div>
        );
    }
}
