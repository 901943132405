import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    ActionMenu, CompoundTree,
    TabNav, TreeUtils,
} from '@Conifer-Point/px-components';
import { EventBroker } from '../../eventbroker';
import { UserActions } from '../../cmds/UserCmd';
import { updateTooltipster } from '../ui_utils';
import { Loader } from '../../Loader';
import { App } from '../../BMapsApp';

import { CustomActions } from '../../cmds/UserAction';
import { userConfirmation } from '../../utils';
import { ClearableTextInput, TreeEnergySlider } from '../UIComponents';
import { StarterCompounds } from '../../model/CaseData';

const EnergyFilterLimits = {
    min: -10,
    max: 0,
};
const renderDebug = false;

export default class SystemSelector extends React.PureComponent {
    update() {
        updateTooltipster({
            '.list-column.is-visible .fa': { side: 'top' },
            '.list-column.is-active .fa': { side: 'right' },
            '.list-column.name span': { side: 'bottom' },
            starterCompounds: { side: 'right' },
        });
        EventBroker.publish('selectorTabChanged');
    }

    /* Note: to prevent re-rendering child components, try the following:
        - pass the same objects into as props to the children (see note on InfoDisplay.render)
        - Don't pass in anonymous arrow functions as props
        - CompoundList needs to be React.PureComponent or implement shouldComponentUpdate
     */
    render() {
        if (renderDebug) console.log('Rendering InfoDisplay');
        const {
            backgroundStyle, compoundItems, proteinItems, fragmentListInfo, hotspotInfo,
            tab, onSelect, sampleCompoundInfo,
        } = this.props;

        const treeName = 'Compounds';
        const { actions, validateRename, doRename } = compoundActions(compoundItems, treeName);
        return (
            <TabNav
                className="systemSelector"
                style={backgroundStyle}
                tab={tab}
                onSelect={onSelect}
                didUpdate={() => this.update()}
            >
                <TabNav.Page tabId="compounds" title="Compounds">
                    <AddStarterCompounds sampleCompoundInfo={sampleCompoundInfo} />
                    <TreeWithFilter
                        items={compoundItems}
                        onToggleVisible={
                            ({ compound, treeItem }) => UserActions.ToggleVisibility(
                                compound || treeItem
                            )
                        }
                        onToggleActive={({ compound, isActive }) => {
                            if (isActive) {
                                if (App.Workspace.displayState.bindingsite) {
                                    UserActions.SetView('protein');
                                } else {
                                    UserActions.SetView('ligand');
                                }
                            } else {
                                UserActions.ActivateCompound(compound);
                            }
                        }}
                        onToggleSelected={({ treeItem, isSelected }) => {
                            UserActions.ToggleSelection(treeItem, !isSelected);
                        }}
                        onToggleExpanded={({ treeItem }) => UserActions.ToggleExpansion(treeItem)}
                        onDoubleClick={({ compound }) => { UserActions.ActivateCompound(compound); UserActions.SetView('ligand'); }}
                        validateRename={validateRename}
                        onRename={doRename}
                        onMove={({ treeItem }, frmIndexPath, toIndexPath) => {
                            UserActions.MoveTreeItem(treeName, treeItem, frmIndexPath, toIndexPath);
                        }}
                        onCombine={(
                            { treeItem: movingTreeItem },
                            fromIndexPath, { treeItem: targetTreeItem }, toIndexPath
                        ) => {
                            const dstIndexPath = [...toIndexPath];
                            if (targetTreeItem.type === 'group') {
                                // If dropping on group, add it to the group
                                dstIndexPath.push(0);
                            } else {
                                // If dropping on an item, insert after
                                dstIndexPath[dstIndexPath.length-1]++;
                            }
                            UserActions.MoveTreeItem(
                                treeName, movingTreeItem, fromIndexPath, dstIndexPath
                            );
                        }}
                        style={backgroundStyle}
                        actions={actions}
                        filterLabel="Compounds"
                        viewActions={compoundViewSortActions(compoundItems, treeName)}
                        getIcon={(column, item) => {
                            if (column === 'isActive') {
                                if (item.isActive) {
                                    return App.Workspace.DisplayState.bindingsite
                                        ? 'fa fa-search-minus'
                                        : 'fa fa-search-plus';
                                } else {
                                    return 'fa fa-arrow-right';
                                }
                            } else if (column === 'info') {
                                if (item.compound && item.compound.anyEnergiesWorking()) {
                                    return 'fa fa-circle-o-notch fa-spin';
                                }
                            }
                            return '';
                        }}
                        getTooltip={(column, item) => {
                            if (column === 'isVisible') {
                                const verb = item.isVisible ? "Don't keep": 'Keep';
                                const object = item.type === 'group' ? "this group's compounds" : 'this compound';
                                return `${verb} ${object} in the 3D workspace and energy table`;
                            } else if (column === 'isActive') {
                                if (item.isActive) {
                                    return App.Workspace.DisplayState.bindingsite
                                        ? 'Zoom out to Protein View'
                                        : `Zoom in to Ligand View for ${item.name}`;
                                } else {
                                    return `Focus on ${item.name}`;
                                }
                            } else {
                                return undefined;
                            }
                        }}
                    />
                </TabNav.Page>
                <TabNav.Page tabId="fragments" title="Fragments">
                    { !!(proteinItems?.pxNodes.length > 0)
                        && (
                            <FragmentSelectorList
                                fragmentListInfo={fragmentListInfo}
                                style={backgroundStyle}
                            />
                        )}
                </TabNav.Page>
                <TabNav.Page tabId="protein" title="Protein">
                    {!!proteinItems
                    && (
                    <TreeWithFilter
                        items={proteinItems.pxNodes}
                        onToggleVisible={proteinItems.onToggleVisible}
                        onToggleExpanded={proteinItems.onToggleExpansion}
                        style={backgroundStyle}
                        actions={proteinItems.pxItemActions}
                        filterLabel="Components"
                        viewActions={proteinItems.viewSortActions}
                        getIcon={proteinItems.getIcon}
                        getTooltip={proteinItems.getTooltip}
                    />
                    )}
                    { (hotspotInfo.hotspots.length > 0)
                        && (
                        <TreeEnergySlider
                            title="Only include hotspots with average free energy below this threshold."
                            label="Hotspot average energy filter:"
                            onManage={() => UserActions.OpenFragmentManager('hotspot')}
                            manageTitle="Manage hotspots"
                            sliderProps={{
                                value: hotspotInfo.threshold,
                                step: 0.5,
                                max: 0,
                                min: -12,
                                onChange: UserActions.ChangeHotspotThreshold,
                            }}
                        />
                        )}
                </TabNav.Page>
            </TabNav>
        );
    }
}

function SelectorHeader({
    filterLabel='', filter, onFilterChange, viewActions,
}) {
    const treeKey = filterLabel;
    // filterLabel will be used for the filter placeholder text, but it happens to identify
    // which tree we're on, so use it for the treeKey.
    // Maybe filterLabel should be converted to treeKey and placeholder text looked up via i18n.

    return (
        <div className="selectorHeader">
            <div style={{ padding: '0 .3em' }}>
                <ClearableTextInput
                    placeholder={` Filter ${filterLabel}`}
                    value={filter}
                    onChange={onFilterChange}
                />
            </div>
            <ViewSortMenu viewActions={viewActions} treeKey={treeKey} />
        </div>
    );
}

function ViewSortMenu({ viewActions, treeKey }) {
    const anyIcons = viewActions.find((x) => x.icon);
    return (
        <div className="viewSortMenu">
            {/* View / Sort added by ::before css rule in InfoDisplay.css */}
            <ActionMenu key="actions" className="actions" style={{ display: 'inline' }}>
                {viewActions.map((action, index) => (
                    <ActionMenu.Item
                        key={`${treeKey} ${action.title || `menuitem#${index.toString()}`}`}
                        noIcons={!anyIcons}
                        {...action}
                    />
                ))}
            </ActionMenu>
        </div>
    );
}

function NothingToShowTree({ message, spinning, more }) {
    return (
        <div className="nothing-to-show-tree">
            <div className="nothing-to-show-tree-message">
                {message}
                {!!spinning && (
                <span>
                    {' '}
                    <i className="fa fa-circle-o-notch fa-spin" />
                </span>
                )}
            </div>
            {!!more && more}
        </div>
    );
}

function collectTreeItems(nodes, queryFn=() => true, includeChildren=true) {
    const result = { leaves: [], groups: [] };
    const addLeaf = (leaf) => {
        if (!result.leaves.includes(leaf)) result.leaves.push(leaf);
    };
    const addGroup = (groupObj) => {
        if (!result.groups.find((x) => x.treeItem === groupObj.treeItem)) {
            result.groups.push(groupObj);
        }
    };
    TreeUtils.traverse(nodes, (node, indexPath) => {
        if (queryFn(node)) {
            if (node.type === 'leaf') {
                addLeaf(node);
            } else {
                const groupObj = { treeItem: node.treeItem, indexPath };
                addGroup(groupObj);
                const groupsRes = collectTreeItems(node.children,
                    includeChildren ? () => true : queryFn,
                    includeChildren);
                for (const child of groupsRes.leaves) {
                    addLeaf(child);
                }
                for (const child of groupsRes.groups) {
                    addGroup(child);
                }
            }
        }
    });
    return result;
}

/**
 * @description Return a list of action objects to be passed into Tree control
 * @param {*} actions List of custom action objects: {
 *      title: string label for the menu item
 *      icon: string css class for the menu item icon (font awesome)
 *      isVisible: optional function taking an item of interest
 *          and returning if it should be visible.
 *          ex: restrict action availability to compounds which are ligands
 *      action: function operating on the item of interest to perform the action
 * }
 * @param {*} getItem Function to extract item of interest from tree item (eg compound)
 * @param {*} isVisible Function taking a tree item and returning if the action should be visible
 * @returns A list of action objects ready for tree control: {
 *      title: string label for the menu item
 *      icon: string css class for the menu item icon (font awesome)
 *      isHidden: function taking a tree item and returning if the menu item should be hidden
 *      onClick: function taking tree item to perform the action
 * }
 */
function addCustomActions(actions, getItem=() => undefined, isVisible) {
    const result = [];
    if (actions.length > 0) {
        result.push({ divider: true });
        for (const action of actions) {
            result.push({
                title: action.title,
                icon: action.icon || '',
                isHidden: (item) => !(
                    (!action.isVisible || action.isVisible(getItem(item)))
                    && (!isVisible || isVisible(item))
                ),
                onClick: (item) => action.action(getItem(item)),
            });
        }
    }
    return result;
}

function compoundActions(items, treeName) {
    const isCompound = (item) => item.type === 'leaf' && item.compound;
    const isBusyCompound = (item) => isCompound(item) && item.compound.anyEnergiesWorking();
    const isNotBusyCompound = (item) => isCompound(item) && !item.compound.anyEnergiesWorking();
    const isGroup = (item) => item.type === 'group';

    const promptForMinimizationsAt = 4;

    /** ******** Populate variables to specify logic ********** */
    const multiProtein = App.Ready && App.Workspace.getLoadedProteins().length >= 2;
    const selectedItems = collectTreeItems(items, (x) => x.isSelected);
    const selectedCompounds = selectedItems.leaves.map((x) => x.compound);
    const anySelectedItems = selectedCompounds.length > 0 || selectedItems.groups.length > 0;
    const haveSelectedCompounds = selectedCompounds.length > 0
        && !selectedCompounds.find((x) => x.anyEnergiesWorking());
    const haveSelectedBusyCompounds = !!selectedCompounds.find((x) => x.anyEnergiesWorking());
    const groupHasCompounds = (group) => (
        TreeUtils.some(group.children, (node) => isCompound(node))
        && !TreeUtils.some(group.children, (node) => isBusyCompound(node))
    );
    const groupHasBusyCompounds = (group) => (
        TreeUtils.some(group.children, (node) => isBusyCompound(node))
    );
    const compoundsInGroupItem = (item) => {
        const compounds = [];
        TreeUtils.traverse(item.children, (node) => {
            if (isCompound(node)) {
                compounds.push(node.compound);
            }
        });
        return compounds;
    };

    /** ********* Define non-trival action functions ************ */
    const doDeleteCompound = async (item) => {
        if (isNotBusyCompound(item) && !item.compound.isLigand()) {
            const text = `Are you sure you want to delete compound ${item.name}?`;
            if (await userConfirmation(text, 'Delete Compound?')) {
                await UserActions.RemoveCompound(item.compound);
            }
        }
    };

    const doDeleteGroup = async (item) => {
        if (isGroup(item)) {
            const compounds = compoundsInGroupItem(item).filter((x) => !x.isLigand());
            const prompt = compounds.length > 0;
            const cmpdLabel = compounds.length === 1
                ? `compound ${compounds[0].resSpec}?`
                : `all ${compounds.length} compounds?`;
            const text = `Are you sure you want to delete group ${item.name} and ${cmpdLabel}`;
            if (!prompt || await userConfirmation(text, 'Delete Compound?')) {
                await UserActions.GroupDelete({
                    treeItem: item.treeItem,
                    indexPath: item.indexPath,
                }, treeName);

                for (const cmpd of compounds) {
                    UserActions.RemoveCompound(cmpd);
                }
            }
        }
    };

    const doDeleteSelected = async () => {
        const text = 'Are you sure you want to delete the selected items?';
        if (await userConfirmation(text, 'Delete Selected?')) {
            UserActions.GroupDelete(selectedItems.groups, treeName);
            for (const cmpd of selectedCompounds.filter((x) => !x.isLigand())) {
                UserActions.RemoveCompound(cmpd);
            }
        }
    };

    const doMinimize = async (item, minimize=true) => {
        if (isNotBusyCompound(item)) {
            if (minimize) UserActions.EnergyMinimize(item.compound);
            else UserActions.GetEnergies(item.compound);
        }
        if (isGroup(item)) {
            const compounds = compoundsInGroupItem(item);
            const prompt = compounds.length >= promptForMinimizationsAt;
            const verb = minimize ? 'Minimize' : 'Get energies for';
            const text = `${verb} all ${compounds.length} compounds in this group (including subgroups)?`;
            const label = `${verb} Group?`;
            if (!prompt || await userConfirmation(text, label)) {
                if (minimize) UserActions.EnergyMinimize(compounds);
                else UserActions.GetEnergies(compounds);
            }
        }
    };

    const doMinimizeSelected = async (ignoredTreeItems, minimize=true) => {
        const prompt = selectedCompounds.length >= promptForMinimizationsAt;
        const verb = minimize ? 'Minimize' : 'Get energies for';
        const text = `${verb} ${selectedCompounds.length} selected compounds?`;
        const label = `${verb} Selected?`;
        if (!prompt || await userConfirmation(text, label)) {
            if (minimize) UserActions.EnergyMinimize(selectedCompounds);
            else UserActions.GetEnergies(selectedCompounds);
        }
    };

    // Rename functions are returned with the actions and added as
    // separate props to the CompoundTree
    const validateRename = (newName, item) => {
        if (isCompound(item) && newName.indexOf(' ') > -1) {
            return 'Compound names cannot have spaces';
        }

        return undefined;
    };
    const doRename = (newName, item) => {
        if (isCompound(item)) UserActions.RenameCompound(item.compound, newName);
        if (isGroup(item)) UserActions.GroupRename(item.treeItem, newName);
    };

    const doSortGroup = (item, sortType) => {
        const groupObj = item.treeItem;
        UserActions.GroupSort(groupObj, { sortType });
    };

    /** ********************* ACTIONS  *************** */

    const showCompoundAction = (item) => isNotBusyCompound(item) && !item.isSelected;
    // Actions to show in the menu for a compound when there is no selection
    const singleCompoundActions = [
        // Actions on compounds
        {
            title: 'Working...',
            icon: 'fa fa-circle-o-notch fa-spin',
            isHidden: (item) => !(isBusyCompound(item) && !item.isSelected),
        },
        {
            title: 'Edit',
            icon: 'fa fa-edit',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => {
                if (isCompound(item)) UserActions.OpenSketcher(item.compound);
            },
        },
        {
            title: 'Minimize',
            icon: 'fa fa-cogs',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => doMinimize(item),
        },
        {
            title: 'Get Energies (without minimization)',
            icon: 'fa fa-cogs',
            isHidden: (item) => !(Loader.AllowLabFeatures && showCompoundAction(item)),
            onClick: (item) => doMinimize(item, false),
        },
        {
            title: 'Dock',
            icon: 'fa fa-bullseye',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => UserActions.OpenDock(item.compound, 'fast'),
        },
        {
            title: 'Export',
            icon: 'fa fa-share',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => {
                UserActions.OpenExport({ compounds: [item.compound] }, { tabId: 'export_moldata_tab' });
            },
        },
        {
            title: 'PubChem Search',
            icon: 'fa fa-search',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => {
                UserActions.OpenExport({ compounds: [item.compound] }, { tabId: 'export_pubchem_tab' });
            },
        },
        { divider: true },
        {
            title: 'Duplicate',
            icon: 'fa fa-copy',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => UserActions.CopyCompound(item.compound),
        },
        {
            title: 'Copy to Protein',
            icon: 'fa fa-copy',
            isHidden: (item) => !showCompoundAction(item) || !multiProtein,
            onClick: (item) => {
                UserActions.StageMoleculeImport({
                    compound: item.compound,
                    placement: 'Retain',
                });
            },
        },
        {
            title: 'Rename',
            icon: 'fa fa-i-cursor',
            isHidden: (item) => !(showCompoundAction(item) && !item.compound.isLigand()),
            role: 'rename',
        },
        {
            title: 'Delete',
            icon: 'fa fa-times',
            isHidden: (item) => !(showCompoundAction(item) && !item.compound.isLigand()),
            onClick: (item) => doDeleteCompound(item),
        },
        ...addCustomActions(CustomActions.Compound, (item) => item.compound, showCompoundAction),
    ];

    const showGroupAction = (item) => isGroup(item) && groupHasCompounds(item) && !item.isSelected;
    // Actions to show in the menu for a group when there is no selection
    const compoundGroupActions = [
        {
            title: 'Working on compounds in group...',
            icon: 'fa fa-circle-o-notch fa-spin',
            isHidden:
                (item) => !(isGroup(item) && groupHasBusyCompounds(item) && !item.isSelected),
        },
        {
            title: 'Minimize all in Group',
            icon: 'fa fa-cogs',
            isHidden: (item) => !showGroupAction(item),
            onClick: (item) => doMinimize(item),
        },
        {
            title: 'Get energies for all in Group',
            icon: 'fa fa-cogs',
            isHidden: (item) => !(Loader.AllowLabFeatures && showGroupAction(item)),
            onClick: (item) => doMinimize(item, false),
        },
        {
            title: 'Dock all in Group',
            icon: 'fa fa-bullseye',
            isHidden: (item) => !showGroupAction(item),
            onClick: (item) => {
                const compounds = compoundsInGroupItem(item);
                UserActions.OpenDock(compounds, 'fast');
            },
        },
        {
            title: 'Export all in Group',
            icon: 'fa fa-share',
            isHidden: (item) => !showGroupAction(item),
            onClick: (item) => {
                const compounds = compoundsInGroupItem(item);
                UserActions.OpenExport({ compounds }, { tabId: 'export_moldata_tab' });
            },
        },
        { divider: true },
        // {
        //     title: "Duplicate Group",
        //     icon: "fa fa-copy",
        //     isHidden: item => !isGroup(item),
        //     onClick: (item) => doCopy(item),
        // },
        {
            title: 'Copy Group to Protein',
            icon: 'fa fa-copy',
            isHidden: (item) => !(isGroup(item) && !item.isSelected) || !multiProtein,
            onClick: (item) => {
                const groupCompounds = compoundsInGroupItem(item);
                const sdf = groupCompounds.map((cmpd) => `${cmpd.getMol2000()}$$$$\n`).join('');
                UserActions.StageMoleculeImport({ molData: sdf, placement: 'Retain' });
            },
        },
        {
            title: 'Rename Group',
            icon: 'fa fa-i-cursor',
            isHidden: (item) => !(isGroup(item) && !item.isSelected),
            role: 'rename',
        },
        {
            title: 'Delete Group',
            icon: 'fa fa-times',
            isHidden: (item) => !(isGroup(item) && !item.isSelected),
            onClick: (item) => doDeleteGroup(item),
        },
        { divider: true },
        {
            title: 'Sort this group by Interaction Score',
            icon: 'fa fa-sort-numeric-asc',
            isHidden: (item) => !(
                // Only offer score sorting if group directly contains compounds (no grandchildren)
                isGroup(item) && !item.isSelected && item.children.some(isCompound)
            ),
            onClick: (item) => doSortGroup(item, 'EnergyScore'),
        },
        {
            title: 'Sort this group alphabetically',
            icon: 'fa fa-sort-alpha-asc',
            isHidden: (item) => !(isGroup(item) && !item.isSelected),
            onClick: (item) => doSortGroup(item, 'Alphabetical'),
        },
    ];

    const showSelectedAction = (item) => item.isSelected && haveSelectedCompounds;
    // Actions to show when there is a selection
    const selectedActions = [
        {
            title: 'Working on selected compounds...',
            icon: 'fa fa-circle-o-notch fa-spin',
            isHidden: (item) => !(item.isSelected && haveSelectedBusyCompounds),
        },
        {
            title: 'Minimize Selected',
            icon: 'fa fa-cogs',
            isHidden: (item) => !showSelectedAction(item),
            onClick: () => doMinimizeSelected(items),
        },
        {
            title: 'Get energies for Selected',
            icon: 'fa fa-cogs',
            isHidden: (item) => !(Loader.AllowLabFeatures && showSelectedAction(item)),
            onClick: () => doMinimizeSelected(items, false),
        },
        {
            title: 'Dock Selected',
            icon: 'fa fa-bullseye',
            isHidden: (item) => !showSelectedAction(item),
            onClick: () => UserActions.OpenDock(selectedCompounds, 'fast'),
        },
        {
            title: 'Export Selected',
            icon: 'fa fa-share',
            isHidden: (item) => !showSelectedAction(item),
            onClick: () => {
                UserActions.OpenExport({ compounds: selectedCompounds }, { tabId: 'export_moldata_tab' });
            },
        },
        { divider: true },
        {
            title: 'Duplicate Selected',
            icon: 'fa fa-copy',
            isHidden: (item) => !showSelectedAction(item),
            onClick: () => {
                for (const cmpd of selectedCompounds) {
                    UserActions.CopyCompound(cmpd);
                }
            },
        },
        {
            title: 'Copy Selected to Protein',
            icon: 'fa fa-copy',
            isHidden: (item) => !showSelectedAction(item) || !multiProtein,
            onClick: () => {
                const sdf = selectedCompounds.map((cmpd) => `${cmpd.getMol2000()}$$$$\n`).join('');
                UserActions.StageMoleculeImport({ molData: sdf, placement: 'Retain' });
            },
        },
        {
            title: 'Delete Selected',
            icon: 'fa fa-times',
            isHidden:
                (i) => !(anySelectedItems && !haveSelectedBusyCompounds && i.isSelected),
            onClick: () => doDeleteSelected(),
        },
    ];

    const actions = [
        ...singleCompoundActions,
        ...compoundGroupActions,
        ...selectedActions,
        { divider: true },
        ...(groupActions(items, treeName).actions),
    ];

    return { actions, validateRename, doRename };
}

function groupActions(items, treeName, includeRename) {
    const isGroup = (item) => item.type === 'group';
    const isNonEmtpyGroup = (item) => isGroup(item) && item.children.length > 0;

    const validateRename = () => undefined;
    const doRename = (newName, item) => {
        if (isGroup(item)) UserActions.GroupRename(item.treeItem, newName);
    };

    const doCreateGroup = (item, withSelected) => {
        if (withSelected) {
            const selected = App.Workspace.getSelectedTreeItems(treeName);
            UserActions.GroupItems(selected, treeName, item.indexPath);
        } else {
            UserActions.GroupCreate('New Group', treeName, TreeUtils.next(item.indexPath));
        }
    };

    const doUngroup = (item) => {
        UserActions.GroupUngroup(
            { treeItem: item.treeItem, indexPath: item.indexPath },
            treeName,
        );
    };

    const actions = [
        {
            title: 'Group Selected Items',
            icon: 'fa fa-object-group',
            isHidden: (item) => !(item.isSelected),
            onClick: (item) => doCreateGroup(item, true),
        },
        {
            title: 'Create New Group',
            icon: 'fa fa-folder',
            isHidden: () => false,
            onClick: (item) => doCreateGroup(item),
        },
        {
            title: 'Ungroup',
            icon: 'fa fa-object-ungroup',
            isHidden: (item) => !(isNonEmtpyGroup(item)),
            onClick: doUngroup,
        },
    ];

    const ret = { actions };

    if (includeRename) {
        ret.actions.push({
            title: 'Rename Group',
            icon: 'fa fa-i-cursor',
            isHidden: (item) => !isGroup(item),
            role: 'rename',
        });
        ret.validateRename = validateRename;
        ret.doRename = doRename;
    }

    return ret;
}

function commonViewSortActions(items, treeName) {
    const expandAll = () => {
        const updating = [];
        TreeUtils.traverse(items, (i) => {
            if (i.type === 'group') {
                updating.push(i.treeItem);
            }
        });
        UserActions.ToggleExpansion(updating, false);
    };
    const collapseAll = () => {
        const updating = [];
        TreeUtils.traverse(items, (i) => {
            if (i.type === 'group') {
                updating.push(i.treeItem);
            }
        });
        UserActions.ToggleExpansion(updating, true);
    };
    return {
        actions: [{
            title: 'Expand all groups',
            onClick: expandAll,
        }, {
            title: 'Collapse all groups',
            onClick: collapseAll,
        }],
    };
}

function compoundViewSortActions(items, treeName) {
    return [
        ...commonViewSortActions(items, treeName).actions,
    ];
}

function TreeWithFilter(props) {
    const [filter, setFilter] = useState({ input: '', upperCase: '' });
    const {
        filterLabel, viewActions, items, ...forTree
    } = props;
    const matches = (item) => item.name.toUpperCase().indexOf(filter.upperCase) > -1;
    // Show items whose names match the query. Include parent nodes.
    const filterItems = (itemsToFilter) => {
        if (!filter.input) return itemsToFilter;

        const result = [];
        for (const i of itemsToFilter) {
            if (i.type === 'leaf') {
                if (matches(i)) {
                    result.push(i);
                }
            } else if (i.type === 'group') {
                const descendants = filterItems(i.children);
                if (matches(i) || descendants.length > 0) {
                    result.push({ ...i, children: descendants });
                }
            }
        }
        return result;
    };
    const filteredItems = filterItems(items);
    return (
        <>
            { items.length > 0
                && (
                <SelectorHeader
                    filterLabel={filterLabel}
                    viewActions={viewActions}
                    filter={filter.input}
                    onFilterChange={(input) => setFilter({
                        input,
                        upperCase: input.toUpperCase(),
                    })}
                />
                )}
            { items.length > 0 && filteredItems.length === 0
                && <NothingToShowTree message="Nothing matches the specified filter" />}
            {filteredItems.length > 0
                && <CompoundTree items={filterItems(items)} {...forTree} />}
        </>
    );
}

/* See comment on SystemSelector.render() about preventing re-renders */
function FragmentSelectorList({ fragmentListInfo, style }) {
    const { FragmentLoading } = useSelector((state) => state.prefs);

    if (renderDebug) console.log('Rendering FragmentSelectorList');

    if (!fragmentListInfo) {
        if (FragmentLoading === 'lazy') {
            UserActions.RefreshAllFragments();
        }
        return (
            <NothingToShowTree message="Loading fragments for this structure..." spinning />
        );
    } else if (fragmentListInfo.pxNodes.length === 0) {
        return (
            <NothingToShowTree
                message="No fragments available for this structure"
                more={(
                    <div style={{ textAlign: 'center' }}>
                        <button
                            type="button"
                            style={{ font: 'inherit', padding: '.2em' }}
                            onClick={() => UserActions.ShowFragmentPane('fragment')}
                        >
                            Add fragments
                        </button>
                    </div>
                  )}
            />
        );
    } else {
        const {
            pxNodes, pxItemActions, viewSortActions, extra, onToggleVisible,
            onToggleSelected, onToggleExpansion, getIcon, getTooltip,
        } = fragmentListInfo;

        const { fragmentInfo: currentFragment, filterValue } = extra.energyFilterInfo;

        return (
            <>
                <TreeWithFilter
                    style={style}
                    filterLabel="Fragments"
                    items={pxNodes}
                    actions={pxItemActions}
                    viewActions={viewSortActions}
                    onToggleVisible={onToggleVisible}
                    onToggleSelected={onToggleSelected}
                    onToggleExpanded={onToggleExpansion}
                    getIcon={getIcon}
                    getTooltip={getTooltip}
                    // Disable renaming on fragments for now
                    // validateRename={validateRename}
                    // onRename={doRename}
                />
                <TreeEnergySlider
                    title="Only show fragments with free energy below this threshold."
                    label={`${currentFragment?.name || 'Fragment'} energy filter:`}
                    enabled={!!currentFragment}
                    onManage={() => UserActions.OpenFragmentManager()}
                    manageTitle="Manage fragments"
                    notEnabledText="Select a fragment to adjust its energy filter"
                    sliderProps={{
                        value: filterValue != null ? filterValue : EnergyFilterLimits.max,
                        step: 0.5,
                        min: EnergyFilterLimits.min,
                        max: EnergyFilterLimits.max,
                        onChange: (val) => (
                            UserActions.SetFragmentEnergyFilter(currentFragment, val)
                        ),
                    }}
                />
            </>
        );
    }
}

function AddStarterCompounds({ sampleCompoundInfo: allSampleCompoundInfo }) {
    return (
        <>
            {allSampleCompoundInfo
                .map(({ caseData }, i) => (
                    <OneAddStarterCompounds
                        key={`starterCmpds_${caseData.getShortName()}_${i.toString()}`}
                        extraLabel={allSampleCompoundInfo.length > 1 ? caseData.getShortName() : ''}
                        sampleCompoundInfo={caseData.getSampleCompoundInfo()}
                        caseData={caseData}
                    />
                ))}
        </>
    );
}

function OneAddStarterCompounds({ sampleCompoundInfo, extraLabel, caseData }) {
    const { availability: status } = sampleCompoundInfo;
    const visible = status === StarterCompounds.Available || status === StarterCompounds.Loading;
    const onClick = status === StarterCompounds.Available
        ? () => {
            sampleCompoundInfo.setLoading();
            UserActions.LoadStarterCompounds(caseData);
        }
        : undefined;
    const loading = status === StarterCompounds.Loading
        ? <i className="fa fa-circle-o-notch fa-spin" />
        : false;

    let label = 'Add sample compounds';
    if (extraLabel) label += ` for ${extraLabel}`;

    return (
        !!visible
        && (
        <div
            className="starterCompounds"
            data-tooltip-content={'<span>Example inhibitor compounds docked into a druggable site, many available commercially.<br>'
                + 'These small molecules have good docking scores and are suitable starting points for developing drug leads.<br>'
                + 'Some sample compounds are ligands from other pdb entries for the same target.<br>'
                + 'These ligands from other structures look like <code>&lt;3-letter ligand code&gt;.&lt;4-letter pdb code&gt;</code>, eg: <code>PRD.2AMQ</code></span>'}
        >
            <div>
                <button type="button" className="generalButton starterAdd" onClick={onClick}>
                    <i className="fa fa-plus" />
                    <span>{label}</span>
                    {' '}
                    {loading}
                </button>
                <button
                    type="button"
                    className="generalButton starterClose"
                    onClick={() => sampleCompoundInfo.setDismissed()}
                >
                    <i className="fa fa-times" />
                    <span>Dismiss</span>
                </button>
            </div>
        </div>
        )
    );
}
