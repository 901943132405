import _ from 'lodash';
import TreeNode from './TreeNode';
import TreeRoot from './TreeRoot';
import { AtomGroupTypes } from '../model/atomgroups';
import { UserActions } from '../cmds/UserAction';
import { SortTypes } from '../model/DisplayState';

/**
 * Presentation class to manage the protein selector tree.
 */
export default class ProteinTree extends TreeRoot {
    constructor(caseDatas, nodeStateFn, displayStateInfo) {
        const children = TreeRoot.makeRootNodesForCaseDatas(caseDatas,
            (caseData) => new CaseDataNode(caseData, displayStateInfo));
        super({
            treeName: 'Protein',
            children,
            nodeStateFn,
        });
    }

    getViewSortActions() {
        return [
            {
                title: 'Sort by Chain',
                onClick: () => {
                    UserActions.ChangeTreeSort('Protein', SortTypes.ProteinTree.chain);
                },
            },
            {
                title: 'Sort by Type',
                onClick: () => {
                    UserActions.ChangeTreeSort('Protein', SortTypes.ProteinTree.type);
                },
            },
            // {
            //     title: 'Sort by Residue',
            //     onClick: () => {
            //         UserActions.ChangeTreeSort('Protein', SortTypes.ProteinTree.residue);
            //     },
            // },
            { divider: true },
            ...this.expandCollapseViewSortActions(),
            { divider: true },
            {
                title: 'Add another protein (Selectivity)',
                onClick: async () => {
                    UserActions.OpenMapSelector('show', { selectivity: true });
                },
            },
            {
                title: 'Clear workspace',
                onClick: () => { UserActions.ZapAll(); },
            },
        ];
    }
}

/**
 * Node to contain all parts of a loaded protein case.
 * The child nodes are grouped according to the sort type, either by chain or moltype (or residue)
 */
class CaseDataNode extends TreeNode {
    constructor(caseData, displayStateInfo) {
        super({
            label: caseData.getName(),
            children: CaseDataNode.getGroups(caseData, displayStateInfo),
            actions: [
                ...TreeNode.groupHideShowActions(),
                {
                    title: 'Remove from workspace',
                    onClick: () => UserActions.RemoveCase(caseData),
                },
            ],
        });
    }

    static getGroups(caseData, { sortType, isFilteredHotspot }) {
        const children = [];
        let initialChildren = [];
        if (sortType === SortTypes.ProteinTree.chain) {
            initialChildren = CaseDataNode.getChainGroups(caseData);
        } else if (sortType === SortTypes.ProteinTree.type) {
            initialChildren = CaseDataNode.getTypeGroups(caseData);
        } else if (sortType === SortTypes.ProteinTree.residue) {
            initialChildren = CaseDataNode.getResidueGroups(caseData);
        }

        initialChildren.forEach((child) => children.push(child));

        // Add node for Crystal Waters if available
        if (caseData.hasCrystalWaters()) {
            children.push(new CrystalWaterNode(caseData));
        }

        const hotspots = caseData.getHotspots();
        if (hotspots.length > 0) {
            children.push(new HotspotGroupNode(hotspots, isFilteredHotspot));
        }
        return children;
    }

    // Some duplicated code here, but it makes it easier to reason about each case explicitly.
    /** Make nodes for grouping by chain */
    static getChainGroups(caseData) {
        const includedTypes = [
            AtomGroupTypes.Protein,
            AtomGroupTypes.Polymer,
            AtomGroupTypes.PeptideLigand,
            AtomGroupTypes.Cofactor,
            AtomGroupTypes.Ion,
        ];
        const atomGroups = caseData.atomGroupsByTypes(includedTypes);
        const byChain = _.groupBy(atomGroups, (ag) => ag.chain);
        const groupNodes = Object.entries(byChain).map(
            ([chain, groupItems]) => new GroupNode(`Chain ${chain}`, groupItems)
        );
        return groupNodes;
    }

    /** Make nodes for grouping by moltype */
    static getTypeGroups(caseData) {
        const includedTypes = [
            AtomGroupTypes.Protein,
            AtomGroupTypes.Polymer,
            AtomGroupTypes.PeptideLigand,
            AtomGroupTypes.Cofactor,
            AtomGroupTypes.Ion,
        ];
        const atomGroups = caseData.atomGroupsByTypes(includedTypes);
        const typeGroups = {
            [AtomGroupTypes.Cofactor]: 'Cofactors',
            [AtomGroupTypes.Ion]: 'Ions',
            [AtomGroupTypes.Protein]: 'Target',
            [AtomGroupTypes.PeptideLigand]: 'Polymers',
            [AtomGroupTypes.Polymer]: 'Polymers',
        };

        const byType = _.groupBy(atomGroups, (ag) => typeGroups[ag.type]);
        const groupNodes = Object.entries(byType).map(
            ([groupName, groupItems]) => new GroupNode(groupName, _.sortBy(groupItems, 'chain'))
        );
        return groupNodes;
    }

    /** Make nodes for grouping by residue */
    static getResidueGroups(caseData) {
        const includedTypes = [
            AtomGroupTypes.Residue,
            AtomGroupTypes.Cofactor,
            AtomGroupTypes.Ion,
        ];
        const atomGroups = caseData.atomGroupsByTypes(includedTypes);
        const byRes = _.groupBy(atomGroups, (ag) => ag.resname);
        return Object.entries(byRes).map(
            ([res, groupItems]) => new GroupNode(res, groupItems)
        );
    }
}

/**
 * Node for an intermediate group in the protein tree
 */
class GroupNode extends TreeNode {
    constructor(label, atomGroups) {
        super({
            label,
            children: atomGroups.map((ag) => new ProteinItemNode(ag)),
            actions: TreeNode.groupHideShowActions(),
        });
    }

    getTooltip(column, { isVisible }) {
        switch (column) {
            case 'isVisible':
                return isVisible
                    ? 'Hide this group in the 3D workspace'
                    : 'Show this group in the 3D workspace';
            default:
                return undefined;
        }
    }
}

/**
 * Leaf node for a solute component in the protein tree.
 * Either target chain, reference / peptide chain, cofactor, or ion (or residue).
 */
class ProteinItemNode extends TreeNode {
    constructor(atomGroup) {
        super({
            item: atomGroup,
            label: atomGroup.displayName,
        });
    }

    getActions({ isVisible }) {
        return [
            {
                title: `${isVisible ? 'Hide' : 'Show'} this component`,
                onClick: ({ item }) => UserActions.ToggleVisibility(item),
            },
            {
                title: 'Select 3D atoms',
                onClick: ({ item }) => UserActions.SelectAtom(item.getAtoms()),
            },
        ];
    }

    getTooltip(column, { isVisible }) {
        switch (column) {
            case 'isVisible':
                return isVisible
                    ? 'Hide in the 3D workspace'
                    : 'Show in the 3D workspace';
            default:
                return undefined;
        }
    }
}

/**
 * Node for hotspots for a protein case
 */
class HotspotGroupNode extends TreeNode {
    constructor(hotspots, isFilteredHotspot) {
        const sortedHotspots = _(hotspots) // lodash wrap for function chaining
            .filter(isFilteredHotspot)
            .sortBy(['exchemPotentialAvg', 'fragmentGroupNumber'])
            .value(); // lodash unwrap

        super({
            label: 'Hotspots',
            children: sortedHotspots.map((hotspot) => new HotspotItemNode(hotspot)),
            info: 'Fragment clusters identified by hotspot analysis, ordered by average excess chemical potential energy.',
            actions: [
                ...TreeNode.groupHideShowActions(),
                {
                    title: 'Manage Hotspots',
                    onClick: () => UserActions.OpenFragmentManager('hotspot'),
                },
            ],
        });
    }

    getTooltip(column, { isVisible }) {
        switch (column) {
            case 'isVisible':
                return isVisible
                    ? 'Hide these hotspots in the 3D workspace'
                    : 'Show these hotspots in the 3D workspace';
            default:
                return undefined;
        }
    }

    static sortHotspots(hotspotA, hotspotB) {
        const xcpA = hotspotA.exchemPotentialAvg;
        const xcpB = hotspotB.exchemPotentialAvg;
        if (xcpA === xcpB) {
            if (hotspotA.fragmentGroupNumber < hotspotB.fragmentGroupNumber) {
                return -1;
            } else {
                return 1;
            }
        } else if (xcpA < xcpB) {
            return -1;
        } else {
            return 1;
        }
    }
}

/**
 * Tree Node for Crystal Waters
 * Since this one node controls the visibility for many atom groups,
 * this uses the crystalWaterCollection on the caseData for the "item"
 */
class CrystalWaterNode extends TreeNode {
    constructor(caseData) {
        super({ label: 'Crystal Waters', item: caseData.getCrystalWaterCollection() });
    }
}

/**
 * Leaf node for hotspots
 */
class HotspotItemNode extends TreeNode {
    constructor(hotspot) {
        super({
            item: hotspot,
            label: hotspot.displayName,
            info: `Avg Excess Chem. Potential: ${hotspot.exchemPotentialAvg.toFixed(1)}`,
        });
    }

    getActions({ isVisible }) {
        return [{
            title: `${isVisible ? 'Hide' : 'Show'} this hotspot`,
            onClick: ({ item }) => UserActions.ToggleVisibility(item),
        }];
    }

    getTooltip(column, { isVisible }) {
        switch (column) {
            case 'isVisible':
                return isVisible
                    ? 'Hide this hotspot in the 3D workspace'
                    : 'Show this hotspot in the 3D workspace';
            default:
                return undefined;
        }
    }
}
