// paste_drag.js
// Utilities for receiving molecule data via Paste and Drag-N-Drop operations

import {
    getFileExtension, MolDataSource, stateFileExtension,
    userConfirmation, showAlert,
} from './utils';
import { fromByteArray } from '../lib/js/base64'; // for png workaround>
import { UserActions } from './cmds/UserAction';
import {
    isSupportedMoltype, getSupportedMoltypes, isSupportedProteinType,
    getSupportedProteinTypes, isBinaryFormat,
} from './util/mol_format_utils';

export function initDropHandling() {
    $('#canvas_wrapper > canvas').addClass('receive-drop');
    addFileDropHandler('#bfm_viewer', '.receive-drop', handleDropFiles);
    initPasteHandling();
    listenForDrops();
}

function initPasteHandling() {
    $(document).on('paste', (event) => {
        let target = $(':hover').filter('.receive-paste');
        const focused = $(':focus').length > 0;
        if (!focused && target.length > 0) {
            target = target.get(0);
            const items = event.originalEvent.clipboardData.items;
            pasteMolData(items);
            event.preventDefault();
        }
    });
    $('#bfm_viewer').addClass('receive-paste');
}

// Receive a list of items from a paste or Drop operation
function pasteMolData(items) {
    $.each(items, (index, item) => {
        if (item.kind === 'string') {
            item.getAsString((strData) => {
                UserActions.StageMoleculeImport({ molData: strData });
            });
        } else {
            const file = item.getAsFile();
            const reader = new FileReader();

            reader.onload = function onLoad(e) {
                const text = e.target.result;
                UserActions.StageMoleculeImport({ molData: text, filename: file.name });
            };
            reader.readAsText(file);
        }
    });
}

// Add "droppable" functionality to an element
// Rather than importing jquery-ui, which has droppable() and drop() methods,
// this just uses the jquery on() method to capture the events
//
// selector: a jQuery selector for the element to listen for the drag/drop events
// readyIndicatorSelector: a jQuery selector for an element to indicate ready for drop
//     (via 'ready_to_drop' class)
// dropFn: a function taking a jQuery drop event and handling the drop
//
// This works by having the document itself listen for the drop events.
// When handling them, it works up the DOM tree from the target element, looking for
// a selector that has been registered as a handler.
//
// Historical TODO: investigate filtering by file extension before the actual drop.
//       Originally a droppableFn was being used in the dragenter and dragover handlers,
//       so that only valid files caused the highlighting.  However on moving from local to demo
//       environment, the filename ceased to be visible for events except the drop itself.
//       We chose to remove the checks, but this could be explored some more.

/**
 * @description Registry of drop handlers: { <selector>: {readyIndicatorSelector, dropFn}}
 */
const dropListeners = { };

/**
 * @description Register a drop handler for the given targets
 * @param {*} selector - selector for the element to listen for the drag/drop events
 * @param {*} readyIndicatorSelector - selector for an element to indicate ready for
 * drop (via 'ready_to_drop' class)
 * @param {*} dropFn  - a handler function receiving the **jQuery** drop event
 */
export function addDropHandler(selector, readyIndicatorSelector, dropFn) {
    dropListeners[selector] = { readyIndicatorSelector, dropFn };
}

/**
 * @description Register a drop handler for the given target, specifically for files.
 * This will extract the files from the jQuery event.
 * @param {*} selector - selector for the element to listen for the drag/drop events
 * @param {*} readyIndicatorSelector - selector for an element to indicate ready for
 * drop (via 'ready_to_drop' class)
 * @param {*} dropFn - a handler function receiving the **list of files** from the drop event
 */
export function addFileDropHandler(selector, readyIndicatorSelector, dropFn) {
    const handler = (jqEvent) => dropFn(jqEvent.originalEvent.dataTransfer.files);
    addDropHandler(selector, readyIndicatorSelector, handler);
}

export function listenForDrops() {
    $(document).on('drop', (jqEvent) => {
        jqEvent.preventDefault(); // don't want the dragged doc to take over the page
        const { dropFn } = findDropListener(jqEvent);
        if (dropFn) {
            dropFn(jqEvent);
            $('.ready_to_drop').removeClass('ready_to_drop');
        }
    });

    // dragstart isn't currently being used.
    // This is not invoked for drag and drop files from the file system, but for
    // UI elements being dragged around the screen.
    /*     $(document).on('dragstart', function (jqEvent) {
        jqEvent.preventDefault();
        const {readyIndicatorSelector} = findDropListener(jqEvent);
        if (readyIndicatorSelector) {
            $(readyIndicatorSelector).addClass('ready_to_drop');
        } else {
            jqEvent.originalEvent.dataTransfer.dropEffect = "none";
        }
    });
 */
    // The actual drop is not seen unless we prevent default for dragover
    $(document).on('dragover', (jqEvent) => {
        jqEvent.preventDefault();
        const { readyIndicatorSelector } = findDropListener(jqEvent);
        if (readyIndicatorSelector) {
            $(readyIndicatorSelector).addClass('ready_to_drop');
        } else {
            jqEvent.originalEvent.dataTransfer.dropEffect = 'none';
        }
    });

    // listen for dragenter and dragleave to indicate when a droppable object is ready to be dropped
    $(document).on('dragenter', (jqEvent) => {
        jqEvent.preventDefault();
        const { readyIndicatorSelector } = findDropListener(jqEvent);
        if (readyIndicatorSelector) {
            $(readyIndicatorSelector).addClass('ready_to_drop');
        } else {
            jqEvent.originalEvent.dataTransfer.dropEffect = 'none';
        }
    });

    $(document).on('dragleave', (jqEvent) => {
        const { readyIndicatorSelector } = findDropListener(jqEvent);
        if (readyIndicatorSelector) {
            $(readyIndicatorSelector).removeClass('ready_to_drop');
        }
    });
}

/**
 * @description Finds the closest DOM ancestor that is a registered drop handler
 * and returns the handler info.
 * @param {*} jqEvent - a drag/drop event
 * @returns Registered drop handler info: {readyIndicatorSelector, dropFn}
 * or empty object {} if not found.
 */
function findDropListener(jqEvent) {
    const node = jqEvent.target;
    let listener = null;
    const listenerSelectors = Object.keys(dropListeners);

    // First find the closest ancestor that meets ANY listener selector
    // Then find which listener selector that ancestor meets.
    // If an ancestor matches more than one, it returns the first.
    const found = node.closest(listenerSelectors.join(','));
    if (found) {
        for (const sel of listenerSelectors) {
            if (found.matches(sel)) {
                listener = dropListeners[sel];
            }
        }
    }

    return listener || {};
}

// drop function implemention for supported files
function isSupportedFile(event) {
    const data = event.originalEvent.dataTransfer;
    let ret = false;
    if (data.files.length > 0) {
        const format = getFileExtension(data.files[0].name);
        if (isSupportedMoltype(format) || isStateExtension(format)) {
            // we'll just go with file extension for now
            ret = true;
        }
    }
    return ret;
}

/**
 * File drop handler, passed into addFileDropHandler.
 * Call loadFiles to convert files to MolDataSources, then stage in Import pane.
 * @param FileList files - the files to load, from drag-drop event
 */
async function handleDropFiles(files) {
    // Close map selector when drag-dropping a file
    UserActions.OpenMapSelector('hide');

    const molSources = await loadFiles(files);
    if (molSources.length > 0) {
        UserActions.StageMoleculeImport({ molSources });
    }
}
/**
 * @description Load state or compound files from the file system.
 * This is not a very clean function.  First, it **always loads
 * state files** (.bmaps).  Then, it **may or may not load compound files**.
 * @param {FileList} files - the files to load, from drag-drop event or file input
 * @returns { Promise<MolDataSource[]>} MolDataSources loaded from files
 */
export async function loadFiles(files) {
    const fileList = [];
    for (const file of files) { fileList.push(file); }

    // First load session files so they don't Zap any other molecule files in
    // the dragged group of files.
    // Await because session loading waits for the server for protein & cmpds
    for (const file of fileList) {
        if (isStateExtension(getFileExtension(file.name))) {
            try {
                await loadStateFile(file);
            } catch (ex) {
                console.warn(`Failed to load session file: ${file.name}: ${ex}`);
            }
        }
    }

    const loaded = [];
    const unsupported = [];
    // Various attempts to sort the list that didn't produce a compelling order.
    // fileList = fileList.sort((a, b) => (a.name < b.name) - (a.name > b.name));
    // fileList.reverse();
    // fileList = fileList.sort((a, b) => b.name.localeCompare(a.name));
    for (const file of fileList) {
        const format = getFileExtension(file.name);
        if (isSupportedMoltype(format)) {
            console.log(`Loading ${file.name}`);
            if (file.size >= 32*1024*1024) {
                await showAlert(
                    `This file is > 32MB and too large to load:\n    ${file.name}.\nPlease break it into multiple smaller files.`,
                    'File too large',
                );
                continue;
            }
            if (file.size > 1024*1024) {
                const ok = await userConfirmation(
                    `This file is large and may take a long time to load:\n    ${file.name}. \nDo you want to load it anyway?`,
                    'Load large file?',
                );
                if (!ok) continue;
            }
            const molData = await readMolDataFromFile(format, file);
            loaded.push(molData);
        } else if (isStateExtension(format)) {
            // Ignore, handled above
        } else {
            unsupported.push(file.name);
        }
    }

    if (unsupported.length > 0) {
        const msg = `The following file(s) are unsupported: \n * ${unsupported.join('\n * ')}

            Please try one of the following file types: ${getSupportedMoltypes().join(', ')}`;
        await showAlert(msg, 'Unsupported File Type');
    }

    return loaded;
}

/**
 * @description Create a MolSource object from a file.
 * Binary data will be encoded as base64.
 * @param {*} format
 * @param {*} file
 * @returns {Promise<MolDataSource>} - molecule source object extracted from the file
 */
async function readMolDataFromFile(format, file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function onLoad(e) {
            const fileData = e.target.result;
            const encoding = isBinaryFormat(format) ? 'base64' : undefined;
            const data = dataToSend(fileData, encoding);
            const molSource = MolDataSource.FromFile(file.name, format, data, encoding);
            resolve(molSource);
        };
        reader.readAsArrayBuffer(file);
    });
}

// Format file data as base64 or plain text
function dataToSend(data, encoding) {
    const dataArr = new Uint8Array(data);
    if (encoding === 'base64') {
        return fromByteArray(dataArr);
    } else { // return the data as a string
        return new TextDecoder().decode(dataArr);
    }
}

function isStateExtension(extension) {
    return extension === stateFileExtension;
}

// Load a single session file
// Async in order to wait for server operations (loading protein / molecules),
// before moving on
async function loadStateFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async function onLoad(e) {
            try {
                const fileData = e.target.result;
                const { errors } = await UserActions.RestoreState(fileData, false);
                if (errors.length === 0) {
                    resolve();
                } else {
                    jAlert(`Failed to restore session file ${file.name}.\n\nProblems:\n* ${errors.join('\n* ')}`);
                    reject(errors);
                }
            } catch (ex) {
                console.warn(`Error loading ${file.name}: ${ex}`);
                jAlert(`Failed to load ${file.name}.`);
                reject(ex);
            }
        };
        console.log(`Loading state from ${file.name}`);
        reader.readAsText(file);
    });
}

export async function getProteinFileText(file) {
    const format = getFileExtension(file.name);
    if (isSupportedProteinType(format)) {
        console.log(`Getting text from protein file ${file.name}`);
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = function onLoad(e) {
                const data = e.target.result;
                resolve(data);
            };
            reader.readAsText(file);
        });
    } else if (isStateExtension(format)) {
        loadStateFile(file);

        // temp solution to inform caller that ui will be manipulated elsewhere
        // alternate unified file handling solution pending
        return true;
    } else {
        jAlert(`Sorry, the file type '.${format}' is not supported for proteins.  Please try one of the following file types: ${getSupportedProteinTypes()}`,
            'Unsupported File Type');
        return Promise.resolve(null);
    }
}

/** ***** WIP for future API changes ***** */

/*
async function processFile(file, guessAboutProtein=false) {
    const filename = file.name;
    const format = getFileExtension(file.name);
    const data = await readFile(file);
    const binary = isBinaryFormat(format);
    const kind = categorizeFile(format, data, guessAboutProtein);

    return {filename, format, data, binary, kind};
}

function categorizeFile(format, data, guessAboutProtein) {
    if (isStateExtension(format)) return "state";
    if (isSupportedProteinType(format) &&
        dataLooksLikeProtein(format, data, guessAboutProtein)) {
        return "protein";
    }
    return "compound";
}

function dataLooksLikeProtein(format, data, guess) {
    return guess && format === 'pdb' && data.index("ATOM") > -1;
}

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function(e) {
            const data = e.target.result;
            resolve(data);
        }
        reader.readAsText(file);
    });
}
*/
