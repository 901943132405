/* project_data.js
 * Protein and compound data loaded into BMaps
 *
 * A note about atom IDs:
 * 3dmol assigns two different atom ids: index and serial ( see 3dmol GLModel.addAtoms() )
 *   index is the index into all the atoms loaded into 3dmol.
 *      It is updated every time atoms are loaded or removed.
 *   serial is conceptualized as the index into the atoms with which it was loaded.
 *      For example, in a single parsed file.
 *      If we provide a value, it will use that
 *      If we don't, it will base it on the atom's position in list passed into addAtoms.
 * In addition: we track our own id when we decode from bfd
 *   uniqueID

 *
 */
import {
    showAlert, AlignAction, userConfirmation,
    isPreviewModeError,
}
    from './utils';
import { EventBroker } from './eventbroker';
import { EnergyInfo } from './model/energyinfo';
import { App } from './BMapsApp';
import { UserActions } from './cmds/UserAction';
import { PdbImportCase, AlphaFoldImportCase } from './model/MapCase';
import { LoadingScreen } from './LoadingScreen';
import { needs3D } from './util/mol_format_utils';

function TheWorkspace() {
    return App.Workspace;
}

EventBroker.registerModule('project_data.js', {
    loadCompound,
    getCompoundBySpec,
    getSelectedAtoms,
    getVisibleCompounds,
    getActiveCompound,
    getLigands,
});

/* In order to separate UI from project data, the following functions
 * were changed from being exported functions to published events.
 * This sort of resolves the problem, but it should be thought through
 * some more
 */
function setAtomSelected(atom, selected) {
    EventBroker.publish('setAtomSelected', { atom, selected });
}

/* End of events converted from exported functions */

// Workspace passthroughs to preserve interface
export function isSelectedAtom(atom) {
    return TheWorkspace().isSelectedAtom(atom);
}

export function isCompoundAtom(atom) {
    return TheWorkspace().isCompoundAtom(atom);
}

export function isProteinAtom(atom) {
    return TheWorkspace().isProteinAtom(atom);
}

export function isActiveCompoundAtom(atom) {
    return TheWorkspace().isActiveCompoundAtom(atom);
}

export function isVisibleCompoundAtom(atom) {
    return TheWorkspace().isVisibleCompoundAtom(atom);
}

export function getSelectedAtoms() {
    return TheWorkspace().getSelectedAtoms();
}

export function getActiveCompound() {
    return TheWorkspace().getActiveCompound();
}

export function getVisibleCompounds() {
    return TheWorkspace().getVisibleCompounds();
}

export function getActiveCompoundAtoms() {
    return TheWorkspace().getActiveCompoundAtoms();
}

export function getVisibleCompoundAtoms() {
    return TheWorkspace().getVisibleCompoundAtoms();
}

export function getLigands() {
    return TheWorkspace().getLigands();
}

export function getAllCompoundAtoms() {
    return TheWorkspace().getAllCompoundAtoms();
}

function addMoleculeEntry(entry) {
    TheWorkspace().addMoleculeEntry(entry);
}

/** getPermissionToLoadProtein()
 * User selects a protein from the map selector, which we must request from BMOC.
 * We need to zap if there is already a protein loaded.
 * Prompt if user would lose data they manually added (ie via drag-drop)
 *
 * Notes to guide the organization of the behavior:
 *   There are two important kinds of data: proteins and compounds
 *   We can have a maximum of one protein, but we can have multiple compounds.
 *   We can load data in two ways: the protein selector or loading files (ie drag/drop)
 *   Protein selector always imports one protein, and maybe one or more compounds.
 *   A loaded compound file might have a compound or a protein (we aren't currently doing this)
 *
 * Cases                                    __What to do for these attempted actions__
 *  _What is currently loaded:_        _Select Protein_    _LoadFile-Compound_  _LoadFile-Protein_
 *  1. selector protein                 no prompt, zap      no prompt, no zap    no prompt, zap
 *  2. selector protein, compound       no prompt, zap      no prompt, no zap    no prompt, zap
 *  3. file protein                     prompt, zap         no prompt, no zap    Prompt, zap
 *  4. file protein, compound           prompt, zap         no prompt, no zap    Prompt, zap
 *  5. selector protein, file compound  Prompt, zap         no prompt, no zap    Prompt, zap
 *  6. just file compound(s)            Prompt, zap         no prompt, no zap    Prompt, zap
 *  7. Nothing loaded                   no prompt, no zap   no prompt, no zap    no prompt, no zap
 */
async function getPermissionToLoadProtein() {
    const isProteinLoaded = App.Workspace.isProteinLoaded();
    const addedMoleculeFiles = App.Workspace.addedMoleculeFiles;
    const loadedCompounds = App.Workspace.getLoadedCompounds();

    let promptBeforeZap = false;
    if (isProteinLoaded) {
        if (addedMoleculeFiles.length === 0) {
            // Only the selector used: cases 1,2: do not prompt, zap
            return true;
        } else {
            // We have a protein and loaded compound files: Cases 3,4,5: prompt, zap
            promptBeforeZap = true;
        }
    } else if (loadedCompounds.length > 0) { // No protein loaded, case 6: no prompt, no zap
        // Theoretically we do not want to zap here, but atoms ids could be confused
        // if the protein is loaded from the map selector with the drag-dropped file present.
        // So we will zap instead.
        promptBeforeZap = true;
    } else {
        // Nothing loaded, Case 7.  Actually we should still zap in case the
        // server is in a weird state.
        return true;
    }

    if (promptBeforeZap) {
        return userConfirmation(`Changing proteins will clear the workspace, including all added or modified compounds.

        Do you want to clear everything and load a new protein?`, 'Load new protein?');
    } else {
        // This shouldn't happen, since cases that don't mark promptBeforeZap=true
        // already returned.
        return true;
    }
}

async function loadProtein({
    loadMsg, displayName, uri, mapCase, loadOptions={},
    dataConnection=App.PrimaryDataConnection,
}) {
    const permission = loadOptions.keepExisting || await getPermissionToLoadProtein();
    if (!permission) return null;

    if (mapCase || uri) {
        return LoadingScreen.withLoadingScreen(
            UserActions.ChooseProtein(mapCase || uri, loadOptions, dataConnection),
            loadMsg
        );
    } else {
        showAlert(`Failed to load ${displayName}: Nothing to load`, 'Load Protein');
    }

    return null;
}

export function loadProteinByUri(uri, loadOptions, dataConnection=App.PrimaryDataConnection) {
    const { displayName, loadMsg } = msgInfoForMapCase(uri, dataConnection);
    return loadProtein({
        uri, displayName, loadMsg, loadOptions, dataConnection,
    });
}

export function loadProteinByMapCase(
    mapCase, loadOptions, dataConnection=App.PrimaryDataConnection
) {
    const { displayName, loadMsg } = msgInfoForMapCase(mapCase, dataConnection);
    return loadProtein({
        mapCase, displayName, loadMsg, loadOptions, dataConnection,
    });
}

function msgInfoForMapCase(input, dataConnection) {
    let mapCase = input;
    if (typeof input === 'string') {
        switch (true) {
            case PdbImportCase.isUri(input): {
                const { id } = PdbImportCase.parseUri(input);
                mapCase = new PdbImportCase(id);
                break;
            }
            case AlphaFoldImportCase.isUri(input): {
                const { id } = AlphaFoldImportCase.parseUri(input);
                mapCase = new AlphaFoldImportCase(id);
                break;
            }
            default:
                mapCase = dataConnection.getCaseDataCollection().findMapByUri(input);
        }
    }

    return {
        displayName: mapCase.displayName,
        loadMsg: mapCase.loadingMessage,
    };
}

//---- Consider moving some of this logic into UserActions.LoadMolecule
export async function loadCompound(molSource, alignAction=AlignAction.align,
    caseData=App.Workspace.firstCaseData()) {
    const addedMoleculeFiles = App.Workspace.addedMoleculeFiles;

    const molEntry = molSource.label();
    console.log(`Sending ${molEntry}`);
    const notDuplicate = (molSource.sourceId == null || !addedMoleculeFiles.includes(molEntry));
    const okToAdd = notDuplicate
        || await userConfirmation(`You already loaded ${molSource.sourceId}.\n\nDo you want to add it again?`,
            'Load (possible) duplicate data?');

    // Check if we need to add gen3d for 2D-only mol or sdf data
    if (needs3D(molSource)) {
        alignAction.addOptions({ gen3d: true });
        console.log('Overriding gen3d to true in loadCompound');
    }

    if (okToAdd) {
        UserActions.LoadMolData(molSource, alignAction, caseData);
        if (notDuplicate) {
            addMoleculeEntry(molEntry);
        }
    }
}

function reportModificationError(cmd, args, messageIn) {
    let message = messageIn.replace('..', '.');
    if (message.indexOf('does not have a formal charge yet') > -1) {
        message = 'Please calculate energies before attempting to modify this compound.';
    }
    showAlert(message, 'Modify Compound');
}

export function doReceiveWarning(cmd, args, messageIn) {
    if (isPreviewModeError(messageIn)) return;

    const message = messageIn.trim(); // remove extra spaces and CRLF.
    switch (cmd) {
        case 'get-case-files':
            showAlert(message, 'Get Protein Case Files');
            break;
        case 'load-molecule': case 'binary-command-14': case 'load-mol-data':
            showAlert(message, 'Compound Import');
            break;
        default:
            console.warn(`Server reported warning: [${cmd} ${args}]: ${message}`);
            break;
    }
}

export function doReceiveStatus(cmd, args, messageIn) {
    const message = messageIn.trim(); // remove extra spaces and CRLF.
    console.log(`Server reported status: [${cmd} ${args}]: ${message}`);
}

export function doReceiveError(cmd, args, messageIn) {
    if (isPreviewModeError(messageIn)) return;

    const message = messageIn.trim(); // remove extra spaces and CRLF.
    switch (cmd) {
        case 'list-maps':
            reportListMapsError(cmd, args, message);
            break;
        case 'get-solvation-for-ligand':
            reportEnergyError(cmd, args, message);
            break;
        case 'get-energies-for-ligand':
            reportEnergyError(cmd, args, message);
            break;
        case 'select':
            reportSelectError(cmd, args, message);
            break;
        case 'select-modification':
        case 'replace-group':
            reportModificationError(cmd, args, message);
            break;
        case 'load-molecule': case 'binary-command-14': case 'load-mol-data':
            reportLoadMoleculeError(cmd, args, message);
            break;
        case 'dock-compound':
        case 'export-selection':
        case 'get-forcefield-params':
        case 'energy-minimize':
            // Handled elsewhere, but included in the switch to suppress the default warning
            break;
        case 'Idle':
            showAlert('This session was inactive for more than 12 hours', 'Max Idle Time Exceeded');
            break;
        default:
            console.warn(`Server reported error: [${cmd} ${args}]: ${message}`);
    }
}

function reportListMapsError(/* cmd, args, message */) {
    const msg = 'An error occurred while fetching Boltzmann Maps data.  Some data may not be available.  Please <a href="mailto:support@coniferpoint.com">contact us</a> if this problem persists.';
    showAlert(msg, 'Load BMaps Data');
}

function reportLoadMoleculeError(/* cmd, args, message */) {
    showAlert('Failed to load molecule.  (See JS console for details)', 'Load Molecule');
}

export function doReceiveSelectResults(data) {
    // A string from the selection command for reporting errors
    console.log(`Selection results: ${data}`);
    // Would be good to display number of selected atoms under select button?
}

export function doReceiveSelection(data, decoder) {
    for (const a of getSelectedAtoms()) {
        setAtomSelected(a, false);
    }
    TheWorkspace().clearSelection();
    decoder.decodeSelection(data, setAtomSelectedMiddleman);
}

export function setAtomSelectedMiddleman(atom, selected) {
    const selectedBefore = !!isSelectedAtom(atom);
    const selectedNow = !!selected;

    if (selectedBefore !== selectedNow) {
        setAtomSelected(atom, selected);
    }

    if (selected) {
        TheWorkspace().addSelectedAtom(atom);
    } else {
        TheWorkspace().removeSelectedAtom(atom);
    }
}

function getCompoundBySpec(ligandSpec) {
    return TheWorkspace().getCompoundBySpec(ligandSpec);
}

// These server commands (get-solvation | get-energies) are not being used currently
// Will also receive msg parameter
function reportEnergyError(cmd, args/* , msg */) {
    const ligandSpec = args;
    const compound = getCompoundBySpec(ligandSpec);
    if (compound) {
        if (cmd === 'get-solvation-for-ligand') {
            compound.getEnergyInfo().energyError(EnergyInfo.Types.ddGs, 'Solvation request failed at server.');
        } else if (cmd === 'get-energies-for-ligand') {
            compound.getEnergyInfo().energyError(EnergyInfo.Types.stress, 'Stress request failed at server.');
            compound.getEnergyInfo().energyError(EnergyInfo.Types.vdW, 'vdW request failed at server.');
            compound.getEnergyInfo().energyError(EnergyInfo.Types.electrostatics, 'Electrostatics request failed at server.');
        }
    }
}

function reportSelectError(cmd, args, message) {
    showAlert(message, `Select ${args}`);
}
