import _ from 'lodash';
import {
    endSlotSessionNow, SessionErrorInfo, SessionErrorType, startSlotSession,
} from './session_request';

export class WebSocketStrategy {
    constructor({ wsClass }={}) {
        this.wsClass = wsClass;
    }

    /**
     *
     * @returns {{ url: string, error: string }} The url or why it failed to get the url
     */
    async getUrl() {
        return {
            errorInfo: new SessionErrorInfo(
                'URL definition is missing for this websocket type. This is a code error.',
                'Incomplete WebSocket Definition',
                SessionErrorType.Other,
            ),
        };
    }

    /**
     *
     * @returns {{ websocket: WebSocketConn, error: string }} Websocket or why it failed
     */
    async getWebsocket(replaceOldSession=false) {
        const WsClass = this.wsClass;
        const { url, connectionProperties, errorInfo } = await this.getUrl(replaceOldSession);
        if (!url) return { errorInfo };
        try {
            const websocket = new WsClass(url, connectionProperties);
            console.log(`WebSocketStrategy: Created websocket for ${url}`);
            return { websocket };
        } catch (ex) {
            console.log(`WebSocketStrategy: Error creating websocket for ${url}: ${ex}`);
            return {
                errorInfo: new SessionErrorInfo(
                `Error starting websocket: ${ex}`,
                'Websocket Exception',
                SessionErrorType.Unknown,
                ),
            };
        }
    }

    finishConnection(websocket) { }

    getWsClass() { return this.wsClass; }

    getProtocol() {
        if (typeof window !== 'undefined' && window.location.protocol === 'https:') {
            return 'wss';
        }
        return 'ws';
    }
}

export class BMapsExecStrategy extends WebSocketStrategy {
    constructor({ wsClass }) {
        super({ wsClass });
    }

    async getUrl(replaceOldSession=false) {
        const { success, data, errorInfo } = await startSlotSession(replaceOldSession);

        if (!success) {
            return { errorInfo };
        }

        const {
            bmapsURL: url, bmapsHost: host, bmapsPort: port, bmapsKey: key, bmapsAgent: agent,
        } = data;
        if (url && host && port && key) {
            // Connect with stored parameters. These are set by the login page.
            return {
                url: this.getUrlForParams(url, host, port, key, agent),
                connectionProperties: { agent, key, port },
            };
        } else {
            return {
                errorInfo: new SessionErrorInfo(
                    'Insufficient information to form websocket url. This is a code error.',
                    'Incomplete URL Data',
                    SessionErrorType.Other,
                ),
            };
        }
    }

    getUrlForParams(url, host, port, key, agent) {
        let websocketURL = url;
        websocketURL = websocketURL.replace('${bmapsHost}', host); // eslint-disable-line no-template-curly-in-string
        websocketURL = websocketURL.replace('${bmapsPort}', port); // eslint-disable-line no-template-curly-in-string
        websocketURL = websocketURL.replace('${bmapsKey}', key); // eslint-disable-line no-template-curly-in-string
        websocketURL = websocketURL.replace('${bmapsAgent}', agent); // eslint-disable-line no-template-curly-in-string
        return websocketURL;
    }

    finishConnection(websocket) {
        const { key } = websocket.getConnectionInfo();
        return endSlotSessionNow(key);
    }
}

export class DevServerSandboxStrategy extends WebSocketStrategy {
    static get userPorts() {
        return {
            jlkjr: 9045, dbryan: 9020, rlb: 9030, micah: 9021,
        };
    }

    constructor({ wsClass }) {
        super({ wsClass });
    }

    async getUrl() {
        const key = 'test';
        const host = window.location.host;
        const userName = DevServerSandboxStrategy.getFirstPathItem();
        const port = DevServerSandboxStrategy.userPorts[userName] || 9002;
        const agentDesc = port === 9002 ? 'dev-server' : userName;

        return {
            url: `${this.getProtocol()}://${host}/bmaps-server?port=${port}&key=${key}`,
            connectionProperties: { agent: agentDesc, port },
        };
    }

    getUsername() {
        return DevServerSandboxStrategy.getFirstPathItem();
    }

    static needThisStrategy() {
        const userName = DevServerSandboxStrategy.getFirstPathItem();
        return Object.keys(DevServerSandboxStrategy.userPorts).includes(userName);
    }

    static getFirstPathItem() {
        const [, item] = window.location.pathname.split('/');
        return item;
    }
}

export class LocalModeStrategy extends WebSocketStrategy {
    constructor({ key='test', username='test', wsClass }={}) {
        super({ wsClass });
        this.key = key;
        this.username = username;
    }

    async getUrl() {
        let port = 9002;
        let url;
        for (; port < 9010; port++) {
            url = this.getUrlForPort(port);
            if (await this.isUrlAvailable(url)) break;
            url = '';
        }

        if (url) {
            return {
                url,
                connectionProperties: { agent: 'local', port },
            };
        } else {
            return {
                errorInfo: new SessionErrorInfo(
                    'Could not establish a connection to a local server',
                    'Server Unavailable',
                    SessionErrorType.ServerUnavailable,
                ),
            };
        }
    }

    getUrlForPort(port) {
        return `${this.getProtocol()}://localhost:${port}/bmaps-server?key=${this.key}`;
    }

    getUsername() {
        return this.username;
    }

    static needThisStrategy() {
        return window.location.hostname === 'localhost';
    }

    isUrlAvailable(url) {
        const WsClass = this.wsClass;
        const available = new Promise((resolve) => {
            console.log(`Creating websocket to test connection to ${url}`);
            const ws = new WsClass(url);
            const connChanged = (conn, connected) => { ws.closeWs(); resolve(connected); };
            const onError = (error) => { ws.closeWs(); resolve(false); };
            ws.addHandlers(connChanged, _.noop, onError);
        });

        return available;
    }
}
