/* CanvasTooltip */
import React from 'react';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    card: {
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 2,
        paddingBottom: 2,
    },
    tableTitle: {
        fontWeight: 'bold',
    },
    tableRow: {
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 4,
        paddingBottom: 4,
    },
    tableHeader: {
        fontWeight: 'bold',
        paddingLeft: 6,
        paddingRight: 16,
    },
    table: {
        paddingRight: 6,
        paddingTop: 4,
    },
});

export default function CanvasTooltip(props) {
    const { html, object, mouseEvent } = props;
    const [timer, setTimer] = React.useState(null);
    const [position, setPosition] = React.useState({ top: 0, left: 0 });
    const [visibility, setVisibility] = React.useState(false);

    const classes = useStyles();

    React.useEffect(() => {
        willReceivePropsAnalog(props);
    }, [html, object, mouseEvent]);

    function willReceivePropsAnalog(nextProps) {
        const nextMouseEvent = nextProps.mouseEvent;
        let nextPos = position;

        if (nextMouseEvent) {
            switch (nextMouseEvent.type) {
                case 'mouseup':
                    // Clear timer after click (for display or hide -- although hiding timers
                    // timeout immediately, so not really relevant). This is so if you have moused
                    // over an atom (starting the timer) and then click it, the tooltip doesn't
                    // appear. We do not explicitly hide the tooltip here.
                    clearTimer();
                    break;
                case 'mouseenter': // maybe show AtomSpy tooltip
                    // 1. First clear the timeout if we have entered a new atom.  This is so if we
                    // are waving the mouse around a number of atoms, it waits the full delay from
                    // the time you enter the last atom.
                    // * Update the label and position
                    // 2. Then actually set the timeout to show the tooltip after the tooltip delay
                    nextPos = getMousePos(nextMouseEvent);
                    if (!visibility) {
                        clearTimer();
                        setTimer(setTimeout(show, CanvasTooltip.delay));
                    }
                    break;
                case 'mousemove': // move the tooltip with the mouse
                    nextPos = getMousePos(nextMouseEvent);
                    break;
                case 'mouseleave': // cancel the tooltip, unless we moved into a neighbor
                    // Prevent tooltip from closing if you move onto a neighboring atom.  The
                    // timeout value of 1 causes it to work only for directly neighboring atoms.
                    clearTimer();
                    // If we triggered the timer by mousing over an atom, we need to cancel it if
                    // we leave the atom before it finishes.
                    if (visibility) {
                        setTimer(setTimeout(hide, 10));
                    }
                    break;
                default:
                    break;
            }
        } else {
            // null mouseEvent is a signal to hide the tooltip
            clearTimer();
            setVisibility(false);
        }
        setPosition(nextPos);
    }

    function generateInnerContent(data) {
        // Most of the data comes from buildAtomSpyContent in UIManager.
        // This data is sent in an object with a type property and the data
        // (either just data (a list) or table, title, header, row).
        // The exception are functional group halos from display_tools.
        // These pass their description (straight HTML) to the UIManager, which then sends
        // it here. Luckily, the multiline HTML always begins with ∆ (&Delta)
        if (data == null) {
            return null;
        } else if (data.type === 'default') {
            return (
                data.data.map((line, i) => (
                    <Typography variant="body2" key={`${i.toString()}_${line}`}>
                        {line}
                    </Typography>
                ))
            );
        } else if (data.type === 'table') {
            return (
                <>
                    <Typography className={classes.tableTitle} align="center" variant="h6">
                        {data.title}
                    </Typography>
                    <TableContainer className={classes.table}>
                        <Table size="small" align="center" padding="none">
                            <TableHead className={classes.tableRow}>
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={classes.tableHeader}>
                                        {data.header[0]}
                                    </TableCell>
                                    <TableCell className={classes.tableHeader}>
                                        {data.header[1]}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.body.map((row, i) => (
                                    <TableRow key={`${i.toString()}_${row[0]}`}>
                                        <TableCell className={classes.tableRow}>
                                            {row[0]}
                                        </TableCell>
                                        <TableCell className={classes.tableRow} align="center">
                                            {row[1]}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { !!data.extra && data.extra }
                </>
            );
        } else {
            return (<Typography variant="body2" dangerouslySetInnerHTML={{ __html: data }} />);
        }
    }

    function clearTimer() {
        clearTimeout(timer);
    }

    function getMousePos(event) {
        if (typeof event.clientX === 'undefined') {
            return {
                left: event.touches[0].clientX,
                top: event.touches[0].clientY,
            };
        }

        return {
            left: event.clientX,
            top: event.clientY,
        };
    }

    function show() {
        setVisibility(true);
    }

    function hide() {
        setVisibility(false);
    }

    return (visibility ? (
        <>
            <div className="canvasPopup canvasTooltip" style={position}>
                <Card className={classes.card}>
                    {generateInnerContent(html)}
                </Card>
            </div>
        </>
    )
        : false);
}

CanvasTooltip.delay = 700;
