import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
// Local
import { EnergyInfo } from '../../../model/energyinfo';
import { exportCompoundEnergies, exportCompoundSolvation } from '../../../model/SavedState';
import { UserActions } from '../../../cmds/UserAction';
import { App } from '../../../BMapsApp';
import { ExportPaneContext } from './ExportPane';
import { makeCsvString } from '../../../util/mol_format_utils';

export const defaultConfig = {
    formats: ['sdf'],
    allowMultiple: true,
    allowStaticMode: true,
    allowMultipleProteins: false,
};

/**
 * @description return an array of strings for one compound's csv row
 * Caller will do escaping of quotation marks (")
 */
function cmpdCsvRowData(cmpd, haveProtein) {
    const energyReport = cmpd.getEnergyInfo().getEnergyReport();
    let filterOut = [];
    if (haveProtein) {
        filterOut = ['internalEnergy'];
    } else {
        filterOut = ['energyScore', 'totalEnergy', EnergyInfo.Types.vdW,
            EnergyInfo.Types.hbonds,
            EnergyInfo.Types.ddGs,
            EnergyInfo.Types.electrostatics,
        ];
    }
    const energyKeys = Object.keys(energyReport)
        .filter((x) => !filterOut.includes(x));
    const energyValues = energyKeys.map((x) => energyReport[x]);

    const smiles = cmpd.getUnnamedSmiles();
    const toString = (x) => (
        typeof (x) === 'string'
            ? x
            : String(JSON.stringify(x)) // string cast catches undefined
    );
    const values = [cmpd.resSpec, smiles, ...energyValues.map(toString)];
    return values;
}

/**
 * @description Return csv file data for a list of compounds
 * This ensures quotation marks around each field and performs the doubling of
 * quotation marks in values.
 */
function cmpdsCsv(compounds, useHeader=true) {
    const haveProtein = compounds.some((c) => App.getDataParents(c).mapCase);

    let header;
    if (useHeader) {
        header = ['Compound', 'SMILES'];
        if (haveProtein) {
            header = header.concat([
                'Energy Score', 'Energy Total', 'vdW', 'hbonds',
                'ddGs', 'Stress Delta', 'Other electrostatics',
            ]);
        } else {
            header = header.concat([
                'Internal Energy', 'Stress Delta',
            ]);
        }
    }
    const cmpdRows = compounds.map((x) => cmpdCsvRowData(x, haveProtein));

    return makeCsvString(cmpdRows, { header });
}

export function useExportDataFetch(selected, config, serviceLabel = 'this service') {
    const context = useContext(ExportPaneContext);
    const {
        exportTarget, exportFormatData, cacheFormatData, setLoading,
    } = context;
    const [errors, setErrors] = useState([]);
    const {
        formats, allowMultiple, allowStaticMode, allowMultipleProteins, disallowAtoms,
    } = config;

    useEffect(() => {
        // TODO: consider reporting these fundamental errors in a different field
        if (!allowStaticMode && App.ServerConnection.staticMode) {
            const error = `Export to ${serviceLabel} is unavailable in Preview mode. Please log in to BMaps in order to export to ${serviceLabel}.`;
            setErrors([error]);
            return;
        }

        const { compounds=[], atoms=[] } = exportTarget;

        if (disallowAtoms && atoms.length > 0) {
            const error = `Export to ${serviceLabel} is unavailable for atom selections. Please click the background of the 3D workspace to deselect any atoms, or use the left-hand compound selector to choose which compounds to export to ${serviceLabel}.`;
            setErrors([error]);
            return;
        }

        // Note: target could be one of (highest priority first)
        // 1) Selected atoms
        // 2) one or more compounds selected in the selector
        // 3) the active compound.
        // It's not correct to simply look at getSelectedCompounds() here
        // because selected atoms would get preference.
        if (allowMultiple === false && compounds.length > 1) {
            const error = `Only one compound can be exported to ${serviceLabel}. `
            + 'Please select only one compound for export.';
            setErrors([error]);
            return;
        }

        // Check multiple proteins
        const { dataParentsList: cmpdDataParents } = App.partitionByDataParents(compounds);
        const { dataParentsList: atomParentsList } = App.partitionByDataParents(atoms);
        const multiProteinAtoms = atomParentsList.length > 0
            && !atomParentsList.every(({ caseData }) => caseData === atomParentsList[0].caseData);
        if (!allowMultipleProteins && (cmpdDataParents.length > 1 || multiProteinAtoms)) {
            const error = `Only compounds from one protein can be exported to ${serviceLabel}. `
                + 'Please select compounds from only one protein for export.';
            setErrors([error]);
            return;
        }

        if (selected) {
            (async () => {
                const pending = [];
                const errs = [];

                for (const format of formats) {
                    if (!exportFormatData[format]) {
                        setLoading(true, `Fetching data for ${format.toUpperCase()}...`);
                        pending.push(fetchExportData(exportTarget, format));
                    }
                }

                if (!pending.length) {
                    return;
                }

                const responses = await Promise.all(pending);
                for (const res of responses) {
                    if (res.error) {
                        errs.push(`Error when retrieving ${res.format} data: ${ res.error}`);
                    } else {
                        cacheFormatData(res.format, res.data);
                    }
                }

                setErrors(errs);
                setLoading(false);
            })();
        }
    }, [selected]);

    const formattedData = _.pick(exportFormatData, formats);
    return { errors, formattedData, context };
}

export async function fetchExportData(target, format) {
    const { compounds = [], atoms = [] } = target || {};

    if (format === 'raw') {
        if (compounds.length > 0) {
            const compound = compounds[0];
            const filename = exportFilename(`${compound.resSpec}`, 'mol');
            return { filename, format, data: compound.getMol2000() };
        }
    } else if (format === 'bmapscmpds') {
        if (atoms.length > 0) {
            return { error: 'You can only export compounds (not a selection) as BMaps Cmpd.' };
        }

        const data = compounds.map((cmpd) => ({
            name: cmpd.resSpec,
            smiles: cmpd.getUnnamedSmiles(),
            mol: cmpd.getMol2000(),
            molProps: cmpd.getMolProps(),
            energies: exportCompoundEnergies(cmpd),
            solvation: exportCompoundSolvation(cmpd),
        }));

        const filename = exportFilenameForTarget(target, 'json');
        return { filename, format, data: JSON.stringify(data) };
    } else if (format === 'csv') {
        if (compounds.length > 0) {
            const filename = exportFilenameForTarget(target, format);
            const data = cmpdsCsv(compounds);
            return { filename, format, data };
        } else {
            let error = 'Unable to find compounds for CSV export. ';
            if (atoms.length > 0) {
                error = 'CSV export of a 3D atom selection is not currently supported. '
                    + ' Please click the background of the 3D workspace to deselect any '
                    + 'atoms, then use the left-hand compound selector to choose which '
                    + 'compounds to export to CSV.';
            }
            return { error };
        }
    }

    const filename = exportFilenameForTarget(target, format);
    const { data, error } = await UserActions.ExportToFormat(target, format);
    return {
        error, filename, format, data,
    };
}

// Return a compound name for export.
// Ensure safe chars and add the bmaps aligned maker.
export function exportFilename(nameIn, format) {
    let name = nameIn.replace(/:/g, '.');
    name = name.replace(/[^A-Za-z0-9_.+]/g, '_');
    return `${name}.${format.toLowerCase()}`;
}

export function exportFilenameForTarget(target, format) {
    const { compounds=[], atoms=[] } = target || {};
    if (atoms.length > 0) {
        return exportFilename(`selected_${atoms.length}_atoms`, format);
    } else if (compounds.length > 0) {
        const firstCmpdSpec = compounds[0].resSpec;
        return compounds.length === 1
            ? exportFilename(firstCmpdSpec, format)
            : exportFilename(`${firstCmpdSpec}+${compounds.length - 1}_cmpds`, format);
    }
    return '';
}
