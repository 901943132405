import Menu from '@mui/material/Menu';

export default function CanvasContextMenu({
    mousePos, closeMenu, ...rest
}) {
    return (
        <Menu
            keepMounted
            open={mousePos.y !== null}
            onClose={closeMenu}
            disableAutoFocusItem
            anchorReference="anchorPosition"
            anchorPosition={
                mousePos.y !== null && mousePos.x !== null
                    ? { top: mousePos.y, left: mousePos.x }
                    : undefined
            }
            MenuListProps={{ onMouseLeave: closeMenu }}
            {...rest}
        />
    );
}
