/* Calculation functions on Workspace items */

import { getGyrationRadius, getBoundingBox } from './util/atom_distance_utils';

export const boxSpecs = {
    SIZE_MIN: 1,
    SIZE_MAX: 40,
};

export class DockingReference {
    static ForSelectedAtoms(selectedAtoms=[]) {
        return new DockingReference({
            type: DockingReference.Types.selected,
            atoms: selectedAtoms,
            description: 'Selected atoms',
        });
    }

    static ForCompound(cmpd) {
        return new DockingReference({
            type: DockingReference.Types.compound,
            atoms: cmpd.getAtoms(),
            description: `${cmpd.isLigand() ? 'Crystal ligand ' : ''}${cmpd.resSpec}`,
        });
    }

    static ForHotspot(hotspot) {
        return new DockingReference({
            type: DockingReference.Types.hotspot,
            atoms: hotspot.getAtoms(),
            description: `${hotspot.displayName} (${hotspot.exchemPotentialAvg.toFixed(2)} kcal/mol)`,
        });
    }

    constructor({ atoms=[], description='', type=DockingReference.Types.unknown }={}) {
        this.type = type;
        this.atoms = atoms;
        this.description = description;
    }
}
DockingReference.Types = {
    unknown: 'unknown',
    selected: 'selected',
    compound: 'compound',
    hotspot: 'hotspot',
};

/**
 * @description Return atom set and a text description for a docking reference
 * @param {*} workspace
 * @param {*} refObj
 */
export function getDockingReferences(workspace, options={}) {
    const result = [];

    if (options.refObj) {
        if (options.refObj instanceof DockingReference) {
            result.push(options.refObj);
        } else {
            console.warn('Using a ref object for docking is not yet implemented.');
        }
        return result;
    }

    // To determine the bounding box, first look for an atom selection, then a single ligand,
    // then hotspot[0], then the whole protein.
    // Better to provide a UI to let the user choose (will specify via refObj)
    const selAtoms = workspace.getSelectedAtoms();
    if (selAtoms && selAtoms.length > 0) {
        result.push(DockingReference.ForSelectedAtoms(selAtoms));
    }

    const isMultipleProteins = workspace.getLoadedProteins().length > 1;
    for (const caseData of workspace.allCaseData()) {
        let prefix = isMultipleProteins ? caseData.getName() : '';
        if (prefix) prefix += ': ';

        // ligands or all compounds
        for (const compound of options.includeCompounds
            ? caseData.getCompounds()
            : caseData.getLigands()) {
            const ref = DockingReference.ForCompound(compound);
            ref.description = `${prefix}${ref.description}`;
            result.push(ref);
        }

        // hotspots
        for (const hotspot of workspace.filterHotspots(caseData.getHotspots()).sort(
            // Sort hotspots by avg exchemp
            (a, b) => a.exchemPotentialAvg - b.exchemPotentialAvg,
        )) {
            const ref = DockingReference.ForHotspot(hotspot);
            ref.description = `${prefix}${ref.description}`;
            result.push(ref);
        }
    }

    return result;
}

/**
 * @description Calculate the docking box around a compound, taking into
 * consideration both the compound size and docking reference atoms.
 * @param {*} workspace
 * @param {*} compound
 * @param {Object} boxParams - Object to the compute Docking box with the fields:
 * - refObj: DockingReference instance or null (will use default)
 * - size: edge size of the docking box or null (will calculate from compound)
 * @returns {Array} - List of coordinates: lowX, highX, lowY, highY, lowZ, highZ
 */
export function calculateDockingBox(workspace, compound, boxParams) {
    // TODO: it would be nice to specify a center in boxParams...
    // TODO: should consider enforcing size to range of boxSize.MIN / boxSize.MAX
    const { refObj, size } = boxParams || {};

    if (!compound) {
        return [];
    }

    let boundingBoxArgs = [];
    const references = getDockingReferences(workspace, { refObj });
    const reference = references[0];
    const atoms = reference ? reference.atoms : [];

    if (atoms.length > 0) {
        let boxSize = null;
        // args to dock script are: xmin xmax ymin ymax zmin zmax
        if (!size) {
            // New approach from Feinstein (see slack docking channel). This is intended to
            // include enough extra so space that additional margins aren't required.
            // Selected atoms can provide two pieces of information, a geometric center
            // and a bounding box extent.
            const cmpdAtcoords = compound.getAtoms().map((at) => at.getPosition());
            const cmpdRg = getGyrationRadius(cmpdAtcoords);
            boxSize = Math.max(5, cmpdRg*2.857);
            console.log(`Bounding box computations: Rg ${cmpdRg}`);
        } else {
            boxSize = size;
        }

        const delta = boxSize/2;
        const box = getBoundingBox(atoms, 0);
        const ctr = [(box.highX + box.lowX)/2, (box.highY + box.lowY)/2, (box.highZ + box.lowZ)/2];
        boundingBoxArgs = [
            ctr[0]-delta, ctr[0]+delta,
            ctr[1]-delta, ctr[1]+delta,
            ctr[2]-delta, ctr[2]+delta,
        ].map((n) => n.toFixed(2));

        console.log(`Bounding box for docking: ${boundingBoxArgs}\nbox ${box} ctr ${ctr} boxsize ${boxSize}`);
    } else {
        const warning = reference
            ? `No atoms in docking reference ${reference.description}`
            : 'No docking reference found';
        console.log(`calculateDockingBox: ${warning}`);
    }

    return boundingBoxArgs;
}
