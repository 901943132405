import { useState } from 'react';
import ActionsDrawer from './ActionsDrawer';
import ControlButton from './base/ControlButton';
import DrawerButton from './base/DrawerButton';

export default function ControlDrawer({
    defaultLabel, title, actions, ControlProps,
}) {
    const [controlLabel, setControlLabel] = useState(defaultLabel);

    const control = <ControlButton title={title} {...ControlProps}>{controlLabel}</ControlButton>;
    const actionButtons = actions.map((action) => {
        const {
            id, desc, label, onClick,
        } = action;

        return (
            <DrawerButton
                key={id}
                title={desc}
                id={id}
                onClick={() => onClick(setControlLabel)}
            >
                {label}
            </DrawerButton>
        );
    });

    return (
        <ActionsDrawer control={control}>
            {actionButtons}
        </ActionsDrawer>
    );
}

// TODO: proptypes
