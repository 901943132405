import ControlButton from './base/ControlButton';
import DrawerButton from './base/DrawerButton';
import SpeedDial from './base/SpeedDial';

export default function ActionsDrawer({
    actions, title, control, label, children, ControlProps, ...props
}) {
    const controlComp = control || (
        <ControlButton title={title} {...ControlProps}>{label}</ControlButton>
    );

    const backgroundMargin = '2.5em';
    return (
        <SpeedDial
            control={controlComp}
            ActionsBackgroundProps={{
                style: {
                    top: `-${backgroundMargin}`, // 0 is top of control button; need to extend up
                    paddingTop: backgroundMargin,
                    paddingBottom: backgroundMargin,
                    paddingLeft: backgroundMargin,
                    zIndex: 1,
                },
            }}
            ActionsContainerProps={{ marginRight: 'calc(3 * var(--mol-button-margin))' }}
            {...props}
        >
            {children || actions?.map((action) => {
                const {
                    id, desc, label: actionLabel, onClick,
                } = action;

                return (
                    <DrawerButton
                        key={id}
                        title={desc}
                        id={id}
                        onClick={onClick}
                    >
                        {actionLabel}
                    </DrawerButton>
                );
            })}
        </SpeedDial>
    );
}
