import {
    RotateThenTranslate, InvertRTTransform,
} from '../math';
import { MolAtomLineRegex, formMolAtomLine } from './mol_format_utils';

/**
 * Apply transformation provided by USalign output
 * @param {[number, number, number]} pos
 * @param {{
*      translation: [number, number, number],
*      rotation: [number, number, number][]
* }} usalignMatrix
* @returns {[number, number, number]}
*/
export function usalignTransform(pos, usalignMatrix) {
    const { translation, rotation } = usalignMatrix;
    const result = RotateThenTranslate(pos, rotation, translation);
    return result;
}

/**
 * Reverse transformation provided by USalign output
 * @param {[number, number, number]} pos
 * @param {{
*      translation: [number, number, number],
*      rotation: [number, number, number][]
* }} usalignMatrix
* @returns {[number, number, number]}
*/
export function undoUsalignTransform(pos, usalignMatrix) {
    const { translation, rotation } = InvertRTTransform(usalignMatrix);
    const result = RotateThenTranslate(pos, rotation, translation);
    return result;
}

/* FUNCTIONS TO TRANSFORM MOL DATA */
export function transformMolString(molText, transform) {
    const lines = molText.split('\n');
    const resultLines = [];
    const atomLineRe = new RegExp(MolAtomLineRegex);
    for (const line of lines) {
        const match = line.match(atomLineRe);
        if (!match) {
            resultLines.push(line);
            continue;
        }

        const {
            x, y, z, element, numbers,
        } = match.groups;
        const pos = [Number(x), Number(y), Number(z)];
        const pos2 = usalignTransform(pos, transform);
        const [newX, newY, newZ] = pos2;
        const newAtomLine = formMolAtomLine({
            x: newX, y: newY, z: newZ, element, numbers,
        });
        resultLines.push(newAtomLine);
    }
    const newMol = resultLines.join('\n');
    return newMol;
}
