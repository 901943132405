/* LigandMod.jsx  -*- mode: javascript; indent-tab-mode:nil; fill-column: 90; -*-
 *
 * Display a list of suggested modifications and replace them.
 */
import React from 'react';
import { App } from './BMapsApp';
import {
    redisplay, makeTempMolecule, removeTempMolecule, updateTempMolecule,
} from './MainCanvas';
import { EventBroker } from './eventbroker';
import { showAlert, hasPreviewModeError } from './utils';
import { Length3, Sub3_3, floatEquals } from './math';
import { updateTooltipster, svgImgSrc } from './ui/ui_utils';
import { MolStyles } from './style_manager';
import { FragList } from './FragList';
import { UserActions } from './cmds/UserAction';
import { Slider } from './ui/UIComponents';
import LinkLikeButton from './ui/common/LinkLikeButton';
import { SelectivityColoring } from './presentation/SelectivityColoring';
import { HydrogenDisplayOptions, hydrogenCheck } from './util/display_utils';
import { makeCsvString, removeSmilesMolName } from './util/mol_format_utils';
import { getTargetInfoMap } from './redux/projectTargets/access';
import { DisplayCoralMap } from './CoralComponent';

function sortRows(sortCol, sortAscending) {
    const mtypes = ['bond', 'methylene', 'ethane', 'acetylene'];

    return function (aRow, bRow) {
        let a = aRow[sortCol];
        let b = bRow[sortCol];

        const [col, projectCase] = sortCol.split('+');

        if (projectCase) {
            if (aRow.projectCaseDetails && bRow.projectCaseDetails) {
                a = aRow.projectCaseDetails[projectCase]?.[col] || 0;
                b = bRow.projectCaseDetails[projectCase]?.[col] || 0;
            } else {
                a = aRow?.[col] || 0;
                b = bRow?.[col] || 0;
            }
        }

        // Handle null cases first (shouldn't happen).
        if (a != null && b == null) return sortAscending ? 1 : -1;
        else if (a == null && b != null) return sortAscending ? -1 : 1;
        else if (a == null && b == null) return 0;

        if (sortCol === 'modType') {
        // modTypes are sorted by index (not by string value) to preserve order
            a = mtypes.indexOf(a);
            b = mtypes.indexOf(b);
            // Secondary sort for modType: bindingScore
            if (a === b) {
                a = aRow['bindingScore'];
                b = bRow['bindingScore'];
            }
        } else if (sortCol === 'name') {
            a = a.toLowerCase();
            b = b.toLowerCase();
            // Secondary sort for name: modType (link type)
            if (a === b) {
            // modTypes are sorted by index (not by string value) to preserve order
                a = mtypes.indexOf(aRow['modType']);
                b = mtypes.indexOf(bRow['modType']);
            }
        }

        if (a > b) return sortAscending ? 1 : -1;
        else if (a < b) return sortAscending ? -1 : 1;
        else if (a === b) return 0;
        else return 0; // This would be unexpected
    };
}

const renderDebug = false;
export default class LigandMod extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            suggestions: [],
            suggestionState: new Map(),
            sourceAtom: null,
            sortCol: 'bindingScore',
            sortAscending: true,
            showImages: true,
            uiInfo: this.uiInfo('grow'),
            addMultipleFrags: false,
            displayFragmentHydrogens: false,
        };
        this.handleDisplaySuggestions = this.handleDisplaySuggestions.bind(this);
        this.hide = this.hide.bind(this);
        this.suggestionAccept = this.suggestionAccept.bind(this);
        this.suggestionEnter = this.suggestionEnter.bind(this);
        this.suggestionExit = this.suggestionExit.bind(this);
        this.cycleSuggestions = this.cycleSuggestions.bind(this);
        this.togglePin = this.togglePin.bind(this);
        this.handleMultipleFrags = this.handleMultipleFrags.bind(this);
        this.handleFragmentHydrogens = this.handleFragmentHydrogens.bind(this);
        this.scrollingRef = React.createRef(); // Used for reseting scroll after sort change
        this.getSuggestionsHeader = this.getSuggestionsHeader.bind(this);
        this.getSuggestionsData = this.getSuggestionsData.bind(this);
        this.getCsvData = this.getCsvData.bind(this);
        this.getCsvString = this.getCsvString.bind(this);
        this.handleCsvDownload = this.handleCsvDownload.bind(this);
        this.toggleImages = this.toggleImages.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        EventBroker.subscribe('displaySuggestions', this.handleDisplaySuggestions);
        EventBroker.subscribe('escapeKey', this.hide);
        EventBroker.subscribe('zapAll', this.hide);
        this.updateTooltips();
    }

    componentDidUpdate(prevProps, prevState) {
        const { sortCol, sortAscending } = this.state;
        if (prevState.sortCol !== sortCol
            || prevState.sortAscending !== sortAscending) {
            if (this.scrollingRef.current) {
                this.scrollingRef.current.scrollTop = 0;
            }
        }
        this.updateTooltips();
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('displaySuggestions', this.handleDisplaySuggestions);
        EventBroker.unsubscribe('escapeKey', this.hide);
        EventBroker.unsubscribe('zapAll', this.hide);
    }

    handleDisplaySuggestions(evtName, {
        suggestions, fragments, errors, sourceAtom, cmd, fragList,
    }) {
        if (suggestions.length > 0) {
            EventBroker.publish('modifyingCompound', true);
            this.displaySuggestions(suggestions, fragments, sourceAtom, cmd, fragList);
        } else {
            const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.substr(1)}`;
            const title = capitalize(cmd);
            if (errors.length === 0) {
                showAlert('No candidates found.', title);
            } else if (!hasPreviewModeError(errors)) {
                showAlert('Sorry, an error occurred in this search.', title);
            }
            this.hide();
        }
    }

    // Change sort after click on column header
    handleSort(column) {
        this.setState((oldState) => {
            if (oldState.sortCol === column) {
                return { sortAscending: !oldState.sortAscending };
            } else { // change column
                return { sortCol: column };
            }
        });
    }

    handleMultipleFrags() {
        this.setState((oldState) => ({ addMultipleFrags: !oldState.addMultipleFrags }));
    }

    /**
     * Handle changes to the "Show Hs" checkbox
     */
    handleFragmentHydrogens() {
        // refreshFrags is invoked in the setTimeout below. The molecule construction needs the
        // updated displayFragmentHydrogens state, so this runs after the state update finishes.
        const refreshFrags = (oldState) => {
            const pinnedSuggestions = oldState.suggestions.filter(
                (sugg) => oldState.suggestionState.get(sugg)?.pinned
            );
            for (const pinnedSugg of pinnedSuggestions) {
                this.suggestionExit(pinnedSugg, { force: true, skipRedisplay: true });
                this.suggestionEnter(pinnedSugg, { force: true, skipRedisplay: true });
            }
            redisplay();
        };

        this.setState((oldState) => {
            setTimeout(() => refreshFrags(oldState), 10); // Refresh after display state update
            return { displayFragmentHydrogens: !oldState.displayFragmentHydrogens };
        });
    }

    handleCsvDownload() {
        const csvString = this.getCsvString();
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = this.getDownloadName();
        document.body.appendChild(anchor);
        anchor.click();

        URL.revokeObjectURL(url);
        document.body.removeChild(anchor);
    }

    getDownloadName() {
        const { suggestions, uiInfo, sourceAtom } = this.state;
        const isFromDataService = this.fromDataService(suggestions);
        const isGrow = uiInfo.displayLinkType;
        const service = isFromDataService ? 'DataQuery' : 'Search';
        const searchType = isGrow ? 'GrowFrom' : 'Nearby';
        const { mapCase } = App.getDataParents(sourceAtom);
        const caseSuffix = mapCase?.pdbID || mapCase?.case || 'unknown case';
        return `BMaps${service}Results_${caseSuffix}_${searchType}-${sourceAtom.atom}`;
    }

    getSuggestionsData() {
        const {
            suggestions, sortCol, sortAscending, sourceAtom, uiInfo,
        } = this.state;

        const suggestionsData = [];
        const { headerMap } = this.getSuggestionsHeader();

        const projectCases = this.getProjectCasesForSuggestions(suggestions, sourceAtom);

        const sortedSuggs = [...suggestions].sort(sortRows(sortCol, sortAscending));

        for (const sugg of sortedSuggs) {
            // Assuming we want to filter suggData for various uses cases
            const suggData = this.getSuggestionRowData(sugg, projectCases, uiInfo.displayLinkType);
            const keys = Object.keys(suggData);
            const csvSuggData = {};
            keys.forEach((key) => {
                if (key in headerMap) {
                    csvSuggData[key] = suggData[key];
                }
            });

            suggestionsData.push(suggData);
        }
        return suggestionsData;
    }

    getSuggestionRowData(suggestion, projectCases, displayLinkType) {
        const suggestionRowData = {
            name: suggestion.name || '',
            smiles: '', // filled out later
            mwt: suggestion.mwt || '',
        };

        const getFragmentProps = (sugg) => {
            const fragProps = {};
            if (this.fromDataService(sugg)) {
                const smileSet = new Set();
                for (const pc of projectCases) {
                    const fragInfo = App.Workspace.getInfoForFragment(sugg.name, pc);
                    if (fragInfo) {
                        smileSet.add(removeSmilesMolName(fragInfo.smiles));
                    }
                }
                fragProps.smiles = [...smileSet].join(' ');
            } else {
                fragProps.smiles = removeSmilesMolName(suggestion.fragInfo.smiles);
            }

            function valueChanged(existing, incoming) {
                if (typeof existing !== typeof incoming) {
                    return true;
                } else if (typeof existing === 'number') {
                    return !floatEquals(existing, incoming);
                } else {
                    return existing !== incoming;
                }
            }

            const fragInfo = App.Workspace.fragmentData.fragservData.fragInfoByFragName(sugg.name);
            const fragPropCols = ['charge', 'HAC', 'LogP', 'TPSA', 'HBA', 'HBD', 'RBC'];
            for (const col of fragPropCols) {
                fragProps[col] = '';
                for (const { fragment: fragmentInfoItem } of fragInfo) {
                    // BUG: additional proteins are clearing the molprops from previous proteins.
                    // So if frag A is in Protein 1, but not Protein 2, it will be missing props.
                    const value = fragmentInfoItem.molProps[col];
                    if (!value && value !== 0) continue;
                    if (!fragProps[col] && fragProps[col] !== 0) {
                        fragProps[col] = value;
                    } else if (valueChanged(fragProps[col], value)) {
                        // TODO: investigate and better handle multiple definitions,
                        // especially those with same names in basis and minifrags:
                        //     pyrazole, imidazole, pyridine, morpholine_Prot
                        fragProps[col] = `${fragProps[col]} ${value}`;
                    } // otherwise they are equal, so keep the existing value
                }
            }
            return fragProps;
        };

        Object.assign(suggestionRowData, getFragmentProps(suggestion));

        if (displayLinkType) {
            suggestionRowData.modType = suggestion.modType || '';
        }

        if (this.fromDataService(suggestion)) {
            for (const pc of projectCases) {
                const { bindingScore, ligandEfficiency } = suggestion.projectCaseDetails[pc] || {};
                suggestionRowData[`bindingScore_${pc}`] = bindingScore || '';
                suggestionRowData[`ligandEfficiency_${pc}`] = ligandEfficiency || '';
            }
        } else {
            suggestionRowData.bindingScore = suggestion.bindingScore;
            suggestionRowData.ligandEfficiency = suggestion.ligandEfficiency;
        }
        return suggestionRowData;
    }

    getCsvData() {
        const suggestionsData = this.getSuggestionsData();
        const { suggestionsHeader } = this.getSuggestionsHeader();
        const csvData = {
            header: suggestionsHeader,
            data: suggestionsData,
        };

        return csvData;
    }

    getCsvString() {
        const { data, header } = this.getCsvData();
        // Note: the following dataRows assignment assumes that all keys will be included!
        // This will need to be modified if non-visible fields are added to the row data.
        const dataRows = data.map((obj) => Object.values(obj));
        const csvString = makeCsvString(dataRows, { header });
        return csvString;
    }

    getSuggestionsHeader() {
        const { suggestions, sourceAtom, uiInfo } = this.state;
        const projectCases = this.getProjectCasesForSuggestions(suggestions, sourceAtom);

        const headerMap = {
            name: 'Name',
            mwt: 'Molecular Weight',
            smiles: 'SMILES',
            bindingScore: 'Binding Score',
            ligandEfficiency: 'Ligand Efficiency',
            modType: 'Modification Type',
            charge: 'Charge',
            HAC: 'Heavy Atom Count',
            LogP: 'LogP',
            TPSA: 'Total Polar Surface Area',
            HBA: 'Hydrogen Bond Acceptors',
            HBD: 'Hydrogen Bond Donors',
            RBC: 'Rotatable Bond Count',
        };

        const perFragColumns = [
            'name', 'smiles', 'mwt',
            'charge', 'HAC', 'LogP', 'TPSA', 'HBA', 'HBD', 'RBC',
        ];
        const perProteinColumns = ['bindingScore', 'ligandEfficiency'];

        const suggestionsHeader = [];

        for (const perFragCol of perFragColumns) {
            suggestionsHeader.push(headerMap[perFragCol]);
        }

        if (uiInfo.displayLinkType) {
            suggestionsHeader.push(headerMap['modType']);
        }

        if (this.fromDataService(suggestions)) {
            for (const pc of projectCases) {
                for (const perProtCol of perProteinColumns) {
                    suggestionsHeader.push(`${headerMap[perProtCol]} (${pc})`);
                }
            }
        } else {
            for (const perProtCol of perProteinColumns) {
                suggestionsHeader.push(headerMap[perProtCol]);
            }
        }

        return {
            suggestionsHeader,
            headerMap,
        };
    }

    getProjectCasesForSuggestions(suggestions, sourceAtom) {
        let projectCases = new Set();
        if (this.fromDataService(suggestions)) {
            for (const sugg of suggestions) {
                for (const pcKey of Object.keys(sugg.projectCaseDetails)) {
                    projectCases.add(pcKey);
                }
            }
        } else { // For bfd-server, the projectcase matches the source atom
            const { mapCase } = App.getDataParents(sourceAtom);
            projectCases.add(mapCase.projectCase);
        }
        projectCases = App.Workspace.getLoadedProteins()
            .map(({ projectCase }) => projectCase)
            .filter((projectCase) => projectCases.has(projectCase));
        return projectCases;
    }

    fromDataService(suggOrSuggs) {
        const sugg = Array.isArray(suggOrSuggs) ? suggOrSuggs[0] : suggOrSuggs;
        return sugg?.origin === 'dataservice';
    }

    updateTooltips() {
        updateTooltipster({
            '#ligand_mod th': { side: 'top' },
            '.suggestionRow': { side: 'bottom' },
            '.suggestionRow button': { side: 'right' },
        });
    }

    show() {
        this.setState({ visible: true });
    }

    hide() {
        const { suggestions, sourceAtom } = this.state;
        const dataParents = App.getDataParents(sourceAtom);
        this.removeTempAtoms(suggestions);
        for (const sugg of suggestions) {
            App.Workspace.removeFragments(sugg.frags, dataParents);
            // Note: sending dataParents is necessary because these frags are
            // DecodedFragments and not Fragment MolAtomGroups.
            // Unlike MolAtomGroups, DecodedFragments are not able to look up their own
            // data parents.  It would be better to get rid of DecodedFragments and
            // actually build Fragment objects when decoding.
        }

        this.setState({
            visible: false,
            suggestions: [],
            uiInfo: this.uiInfo('grow'),
            suggestionState: new Map(),
        });
        // Turn off bond vectors when closing grow suggestions
        EventBroker.publish('displayBondVectors', { hideAll: true });
        EventBroker.publish('modifyingCompound', false);
    }

    sortSuggestionFrags(sugg) {
        if (sugg.frags.length === 0) return;
        // Sort by proximity.
        const unsortedFrags = sugg.frags;
        const unsortedSelectionIDs = sugg.selectionIDs;
        const usingSelectionIDs = sugg.origin === 'bfd-server';
        const sortedFrags = [unsortedFrags.shift()];
        const sortedSelectionIDs = usingSelectionIDs ? [unsortedSelectionIDs.shift()] : [];
        while (unsortedFrags.length > 0) {
            let closestIndex = 0;
            let minDistSq = 10000;
            const nextFrag = sortedFrags[sortedFrags.length-1];
            for (let i = 0; i<unsortedFrags.length; i++) {
                const testFrag = unsortedFrags[i];
                const distSq = Length3(Sub3_3(testFrag.translation, nextFrag.translation));
                if (distSq < minDistSq) { minDistSq = distSq; closestIndex = i; }
                //--- consider min dist early termination
            }
            sortedFrags.push(unsortedFrags[closestIndex]);
            unsortedFrags.splice(closestIndex, 1);
            if (usingSelectionIDs) {
                sortedSelectionIDs.push(unsortedSelectionIDs[closestIndex]);
                unsortedSelectionIDs.splice(closestIndex, 1);
            }
        }
        sugg.frags = sortedFrags;
        if (usingSelectionIDs) {
            sugg.selectionIDs = sortedSelectionIDs;
        }
    }

    /**
     * @param {Array} suggestions
     * @param {Array} fragments
     * @param {string} cmd
     * @param {FragList} fragList
    */
    // suggestions: [ <suggestion>, ... ]
    // suggestion: {
    //     origin,modType,selectionIDs,fragAtomName,selectedIndex,name,bindingFE,
    //     excessChemicalPotential, ddGs,mwt,nheavy,index,bindingScore,
    //     ligandEfficiency,frags,fragInfo,img
    // }

    async displaySuggestions(suggestions, fragments, sourceAtom, cmd, fragList) {
        const fragMap = new Map();
        for (const f of fragments) {
            if (f.poseSerialNo != null) fragMap.set(f.poseSerialNo, f);
        }
        let poseCount = 0;
        let missingPoseCount = 0;

        // Since we're using scores rather than actual energies, Ian suggests
        // dividing by 1.36 which makes FE into log(IC50), closer to values they
        // might expect.
        const getBindingScore = (bindingFE) => (
            bindingFE != null ? (bindingFE/1.36) : null
        );
        const getLigEff = (bindingFE, nheavy) => (
            bindingFE != null && nheavy ? (-bindingFE/nheavy) : null
        );

        // Add derived columns
        let hasMultipleFrags = false;
        const suggestionState = new Map();
        for (const [suggIx, sugg] of suggestions.entries()) {
            sugg.index = suggIx;
            sugg.bindingScore = getBindingScore(sugg.bindingFE);
            sugg.ligandEfficiency = getLigEff(sugg.bindingFE, sugg.nheavy);
            if (sugg.origin === 'dataservice') {
                for (const entry of Object.values(sugg.projectCaseDetails)) {
                    entry.bindingScore = getBindingScore(entry.bindingFE);
                    entry.ligandEfficiency = getLigEff(entry.bindingFE, sugg.nheavy);
                }
            }

            // If suggestion is from bfd-server (old system), look up and add frags by pose id.
            // If it is from dataservice (new fragment data query), frags are already there.
            if (sugg.origin === 'bfd-server') {
                sugg.frags = sugg.selectionIDs.map((id) => { // eslint-disable-line no-loop-func
                    const f = fragMap.get(id);
                    // eslint doesn't like referencing these counters from the arrow function
                    if (f) poseCount++; else missingPoseCount++;
                    return f;
                }).filter((x) => x);

                // Don't assign fragInfo here for dataservice fragments.
                // fragInfo will be looked up later on a per-*pose* basis
                // because we need to account for multiple proteins.
                sugg.fragInfo = fragList.findByName(sugg.name);
            }
            this.sortSuggestionFrags(sugg);

            // In suggestions from bfd-server, the fragList is associated with the target protein.
            // In suggestions from dataservice, there is no fraglist; instead the Workspace method
            // checks the fragLists for all loaded proteins.
            // Is it guaranteed that fragments with the same name will always have the same image?
            // I think so, but just in case, the bfd-server will use the protein's FragList.
            sugg.img = sugg.origin === 'bfd-server'
                ? await fragList.getFragImage(sugg.name) // bfd-server case
                : await App.Workspace.getSvgForFragment(sugg.name); // dataservice case
            sugg.selectedIndex = 0;
            if (sugg.frags.length > 1) hasMultipleFrags = true;
            suggestionState.set(sugg, {
                pinned: false,
            });
        }

        console.log(`Displaying ${suggestions.length} suggestions with ${poseCount} poses and ${missingPoseCount} missing.`);

        this.setState({
            suggestions,
            sourceAtom,
            visible: true,
            suggestionState,
            uiInfo: this.uiInfo(cmd, hasMultipleFrags), // labels & widget presence
        });
    }

    makeSuggestionMolecule(suggestion, fragIdx) {
        const { sourceAtom, displayFragmentHydrogens } = this.state;

        let fragCarbonColor = 0x00FF00;
        const frag = suggestion.frags[fragIdx];
        const hydrogenDisplay = displayFragmentHydrogens ? HydrogenDisplayOptions.all
            : HydrogenDisplayOptions.polar;
        const atoms = frag.atoms.filter((atom) => hydrogenCheck(atom, hydrogenDisplay));
        const isSelected = fragIdx === suggestion.selectedIndex;
        const opacity = isSelected ? 1 : 0.7;
        if (suggestion.origin === 'dataservice'
            && SelectivityColoring.selectivityActive()
            && Object.keys(suggestion.projectCaseDetails).length > 1) {
            fragCarbonColor = SelectivityColoring.getBaseColor(frag.projectCase);
        }

        const Cspec = { C: fragCarbonColor };
        let atomPair = [];
        if (suggestion.modType !== 'Near' && isSelected && suggestion.fragAtomName && sourceAtom) {
            // Draw bond highlight
            const [fragAtom] = frag.atoms.filter((x) => x.atom === suggestion.fragAtomName);
            if (fragAtom) atomPair = [fragAtom, sourceAtom];
        }
        return makeTempMolecule(atoms, MolStyles.sticks, Cspec, opacity, atomPair);
    }

    updateSuggestionMolecule(suggestion, fragIdx) {
        const opacity = fragIdx === suggestion.selectedIndex ? 1 : 0.7;
        const style = MolStyles.sticks;
        const model = suggestion.tmpModels[fragIdx];
        if (model) {
            updateTempMolecule(model, { style, opacity });
        }
    }

    suggestionEnter(suggestion, { force, skipRedisplay }={}) {
        if (!force && this.isPinned(suggestion)) return; // already visible
        // Frag atoms (exclude non-polar hydrogens)
        suggestion.tmpModels = [];
        for (const [fragIdx, frag] of suggestion.frags.entries()) {
            if (!frag) continue;
            suggestion.tmpModels.push(this.makeSuggestionMolecule(suggestion, fragIdx));
        }
        if (!skipRedisplay) {
            redisplay();
        }
    }

    suggestionExit(suggestion, { force, skipRedisplay }={}) {
        if (!force && this.isPinned(suggestion)) return; // leave it visible
        this.removeTempAtoms([suggestion], { skipRedisplay });
    }

    suggestionAccept(suggestion) {
        this.suggestionExit(suggestion, { force: true });
        const { sourceAtom, addMultipleFrags } = this.state;
        UserActions.ConfirmModification(suggestion, sourceAtom);
        if (!addMultipleFrags) {
            this.hide();
        }
    }

    cycleSuggestions(suggestion, index) {
        const prevIndex = suggestion.selectedIndex;
        suggestion.selectedIndex = index;
        // removeTempMolecule(suggestion.tmpModels[prevIndex]);
        this.updateSuggestionMolecule(suggestion, prevIndex);
        this.updateSuggestionMolecule(suggestion, suggestion.selectedIndex);
        // suggestion.tmpModels[prevIndex] = this.makeSuggestionMolecule(suggestion, prevIndex);
        // removeTempMolecule(suggestion.tmpModels[index]);
        // suggestion.tmpModels[index]     = this.makeSuggestionMolecule(suggestion, index);
        redisplay();
    }

    togglePin(sugg) {
        this.setState((oldState) => {
            const suggestionState = oldState.suggestionState.get(sugg);
            suggestionState.pinned = !suggestionState.pinned;
            // Assigning the new Map object to the state field triggers
            // and update, rerendering the necessary children
            return { suggestionState: new Map(oldState.suggestionState) };
        });
    }

    isPinned(sugg) {
        const { suggestionState } = this.state;
        return suggestionState.get(sugg).pinned;
    }

    // Remove temp atoms shown when hovering on frag rows
    removeTempAtoms(suggestions, { skipRedisplay }={}) {
        if (suggestions.length === 0) return;
        for (const suggestion of suggestions) {
            if (suggestion.tmpModels && suggestion.tmpModels.length > 0) {
                for (const tmpModel of suggestion.tmpModels) {
                    removeTempMolecule(tmpModel);
                }
                suggestion.tmpModels = [];
            }
        }
        if (!skipRedisplay) {
            redisplay();
        }
    }

    toggleImages() {
        this.setState((oldState) => ({ showImages: !oldState.showImages }));
    }

    uiInfo(cmd, hasMultipleFrags=false) {
        if (cmd === 'grow') {
            return {
                cmd,
                caption: 'candidates for fragment growing',
                displayLinkType: true,
                addButtonTitle: 'Grow with this fragment',
                usingSlider: hasMultipleFrags,
            };
        } else {
            return {
                cmd,
                caption: 'nearby fragments',
                displayLinkType: false,
                addButtonTitle: hasMultipleFrags ? 'Add this fragment in the highlighted pose': 'Add this fragment',
                usingSlider: hasMultipleFrags,
            };
        }
    }

    // Display the fragment table table
    render() {
        if (renderDebug) console.log('Rendering LigandMod');
        const {
            visible, sortCol, sortAscending, suggestions, uiInfo, showImages, sourceAtom,
            addMultipleFrags, displayFragmentHydrogens,
        } = this.state;
        if (!visible) return false;

        const sortedSuggestions = [...suggestions].sort(sortRows(sortCol, sortAscending));
        const { caseData } = App.getDataParents(sourceAtom);
        const searchFrags = App.Workspace.getActiveFragments(caseData).length;
        const totalFrags = caseData.getAvailableFragmentInfo().items().length;
        const showSearchInfo = suggestions[0].origin === 'bfd-server';
        const projectCases = this.getProjectCasesForSuggestions(sortedSuggestions, sourceAtom);

        return (
            <div id="ligand_mod" className="canvasPopup">
                <div className="ligand_mod_title">
                    <LinkLikeButton className="ligand_mod_close" style={{ color: 'black' }} onClick={this.hide} aria-label="Close"><i className="fa fa-close" /></LinkLikeButton>
                    <div className="ligand_mod_caption">
                        Found
                        {' '}
                        {sortedSuggestions.length}
                        {' '}
                        {uiInfo.caption}
                    </div>
                    { showSearchInfo && (
                        <div className="ligand_mod_searchinfo">
                            <span>
                                Searched poses for
                                {' '}
                                {searchFrags}
                                {' '}
                                out of
                                {' '}
                                {totalFrags}
                                {' '}
                                total fragments
                            </span>
                            <LinkLikeButton style={{ color: 'var(--marketing-blue)' }} title="Manage fragment search" onClick={() => UserActions.OpenFragmentManager()}>
                                <i className="fa fa-cog" />
                                {' '}
                                Manage
                            </LinkLikeButton>
                        </div>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <label
                            htmlFor="ligandmod-add-multiple-frags"
                            title="Check this box to keep the search results open after adding a fragment."
                        >
                            <input
                                id="ligandmod-add-multiple-frags"
                                type="checkbox"
                                checked={addMultipleFrags}
                                onChange={this.handleMultipleFrags}
                            />
                            {' '}
                            Add multiple fragments
                        </label>
                        <label
                            htmlFor="ligandmod-show-hydrogens"
                            title="Check this box to display all the hydrogens on the fragment suggestions."
                        >
                            <input
                                id="ligandmod-show-hydrogens"
                                type="checkbox"
                                checked={displayFragmentHydrogens}
                                onChange={this.handleFragmentHydrogens}
                            />
                            {' '}
                            Show Hs
                        </label>
                        <LinkLikeButton
                            title="Click this button to download the fragment search results in CSV format"
                            style={{
                                color: 'var(--marketing-blue)', fontSize: 'inherit', paddingTop: 0, paddingBottom: 0,
                            }}
                            onClick={this.handleCsvDownload}
                        >
                            <i className="fa fa-download" />
                            {' '}
                            CSV
                        </LinkLikeButton>
                    </div>
                </div>
                <div className="scroller" ref={this.scrollingRef}>
                    { sortedSuggestions.length > 0 ? (
                        <table>
                            <thead>
                                <SuggestionsHeader
                                    projectCases={projectCases}
                                    uiInfo={uiInfo}
                                    sortCol={sortCol}
                                    sortAscending={sortAscending}
                                    handleSort={this.handleSort}
                                    showImages={showImages}
                                    toggleImages={this.toggleImages}
                                />
                            </thead>
                            <tbody>
                                { sortedSuggestions.map((d) => (
                                    <SuggestionRow
                                        key={d.index}
                                        sugg={d}
                                        uiInfo={uiInfo}
                                        showImg={showImages}
                                        suggestionAccept={this.suggestionAccept}
                                        suggestionEnter={this.suggestionEnter}
                                        suggestionExit={this.suggestionExit}
                                        onChangePose={this.cycleSuggestions}
                                        pinned={this.isPinned(d)}
                                        togglePin={this.togglePin}
                                        projectCases={projectCases}
                                    />
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <table>
                            <tbody>
                                <tr key="no-suggestions-row" className="suggestionRow"><td colSpan="4">Sorry, no fragments found.</td></tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        );
    }
}

/**
 * @description props = {
 *      sugg: suggestion object,
 *      showImg: whether to show images instead of fragment names,
 *      uiInfo: labels and presence of certain controls, based on command
 *      suggestionAccept, suggestionEnter, suggestionAccept: callbacks
 * }
 */
class SuggestionRow extends React.PureComponent {
    constructor(props) {
        super(props);
        const { sugg } = props;
        this.state = {
            sliderExpanded: false,
            sliderValue: sugg.selectedIndex,
            sliderTooltip: this.getSliderTooltip(sugg, sugg.selectedIndex),
        };

        this.toggle = this.toggle.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate() {
        updateTooltipster({
            [`#${this.getEltId()} .toggle`]: {
                side: 'right',
            },
        });
    }

    onChange(value) {
        const { sugg, onChangePose } = this.props;
        this.setState({
            sliderValue: value,
            sliderTooltip: this.getSliderTooltip(sugg, value),
        });
        onChangePose(sugg, value);
    }

    getEltId() {
        const { sugg } = this.props;
        return `suggestion-${sugg.index}`;
    }

    getSliderId() {
        const { sugg } = this.props;
        return `suggestion-${sugg.index}-slider`;
    }

    getSliderTooltip(sugg, fragIndex) {
        const currentPose = sugg && sugg.frags && sugg.frags[fragIndex];
        return currentPose && (currentPose.exchemPotential != null)
                && `Highlighted pose excess chem. potential: ${(currentPose.exchemPotential).toFixed(1)}`;
    }

    /**
     * Return extra info to append to suggestion row tooltip. Currently just min b-values.
     * @returns {string} string to append.  Embedded html is ok.
     */
    getTooltipExtra() {
        const { sugg, projectCases } = this.props;
        let oneFragInfo;
        let multipleFragInfos = [];

        if (projectCases) {
            if (projectCases.length === 1) {
                oneFragInfo = App.Workspace.getInfoForFragment(sugg.name, projectCases[0]);
            } else {
                multipleFragInfos = projectCases.map((pc) => ({
                    projectCase: pc,
                    fragInfo: App.Workspace.getInfoForFragment(sugg.name, pc),
                }));
            }
        }

        let ret = '';
        if (oneFragInfo) {
            ret += `<br>Min B-Value: ${oneFragInfo.minBValue}`;
        } else if (multipleFragInfos.length > 0) {
            ret += '<br>Min B-Values:';
            for (const { projectCase, fragInfo } of multipleFragInfos) {
                const bValue = fragInfo != null ? fragInfo.minBValue : 'N/A';
                ret += `<br>${projectCase}: ${bValue}`;
            }
        }
        return ret;
    }

    toggle() {
        this.setState((old) => ({ sliderExpanded: !old.sliderExpanded }));
    }

    render() {
        if (renderDebug) console.log(`Rendering SuggestionRow ${this.getEltId()}`);
        const {
            sugg, showImg, uiInfo,
            suggestionAccept, suggestionEnter, suggestionExit,
            pinned, togglePin,
            projectCases,
        } = this.props;
        const { sliderExpanded, sliderValue, sliderTooltip } = this.state;

        const tds = { textAlign: 'right', paddingRight: '20px' };
        const displayBindingScore = (bs) => (bs != null ? bs.toFixed(1) : '');
        const displayLigandEfficiency = (ligeff) => (ligeff != null ? ligeff.toFixed(2) : '');

        const projectCaseEntries = [];
        const coralMapInfoEntries = [];
        for (const pc of projectCases) {
            let bindingScore;
            let ligandEfficiency;
            if (sugg.origin === 'dataservice') {
                ({ bindingScore, ligandEfficiency } = sugg.projectCaseDetails[pc] || {});
            } else {
                bindingScore = sugg.bindingScore;
                ligandEfficiency = sugg.ligandEfficiency;
            }
            projectCaseEntries.push({
                projectCase: pc,
                bindingScore: displayBindingScore(bindingScore),
                ligandEfficiency: displayLigandEfficiency(ligandEfficiency),
            });
            const caseInfo = getCaseInfo(pc, bindingScore, ligandEfficiency);
            // this condition was relaxed to test on projects to real
            // condition is caseInfo[pc].mapCase.otherProperties
            if (caseInfo[pc].mapCase.otherProperties) {
                const fragInfo = getFragmentInfo(pc, sugg);
                const coralMapInfo = getInfoForCoralMap(caseInfo, fragInfo, pc);
                coralMapInfoEntries.push(coralMapInfo);
            }
        }
        const molwt = sugg.mwt != null ? sugg.mwt.toFixed(0) : '';
        const eltId = this.getEltId();
        const altText = `2D diagram of ${sugg.name}`;
        const image = <img alt={altText} width="70px" src={svgImgSrc(sugg.img)} />;
        const linkType = {
            Bond: '&mdash;&ensp;&ensp;',
            CXC: '&ndash;C&ndash;',
            methylene: '&ndash;C&ndash;&ensp;&thinsp;',
            ethane: '&ndash;C&ndash;C&ndash;',
            ethylene_cis: '&ndash;C=C&ndash;',
            ethylene_trans: '&ndash;C<b>=</b>C&ndash;',
            acetylene: '&ndash;C&equiv;C&ndash;',
            Acetylene: '&ndash;C&equiv;C&ndash;',
        }[sugg.modType];
        const firstCol = showImg ? image : sugg.name;
        const imageStr = `<img alt="${altText}" class="suggestion-imgTooltip" src='${svgImgSrc(sugg.img)}'/>`;
        const tooltip = showImg ? sugg.name : imageStr;
        const tooltipExtra = this.getTooltipExtra();

        const addOnRowClick = !uiInfo.usingSlider;
        const accept = () => suggestionAccept(sugg);
        const addButton = (
            <LinkLikeButton
                style={{ color: 'var(--marketing-blue)' }}
                title={uiInfo.addButtonTitle}
                onClick={(event) => { event.stopPropagation(); accept(); }}
            >
                <i className="fa fa-plus-square-o" />
            </LinkLikeButton>
        );
        const pin = (
            <LinkLikeButton
                onClick={(event) => { event.stopPropagation(); togglePin(sugg); }}
                title={`Keep viewing ${sugg.frags.length > 1 ? 'these poses' : 'this pose'} in the workspace`}
                style={{ color: pinned ? 'var(--marketing-blue)' : 'lightgrey' }}
            >
                <i className="fa fa-thumb-tack" />
            </LinkLikeButton>
        );
        const toggleButton = (
            <LinkLikeButton
                onClick={this.toggle}
                className="toggle"
                style={{ color: 'var(--marketing-blue)' }}
                title={`${sliderExpanded ? 'Hide' : 'Show'} pose selector (${sugg.frags.length} poses available)`}
            >
                {sliderExpanded
                    ? <i className="fa fa-caret-down" />
                    : <i className="fa fa-caret-left" /> }
            </LinkLikeButton>
        );

        return (
            <>
                <tr
                    key={eltId}
                    className="suggestionRow"
                    id={eltId}
                    data-selectionid={sugg.selectionID}
                    data-tooltip-content={`<div>${tooltip}${tooltipExtra}</div>`}
                    onMouseEnter={() => suggestionEnter(sugg)}
                    onMouseLeave={() => suggestionExit(sugg)}
                    onClick={() => addOnRowClick && accept()}
                >
                    <td colSpan={2}>{firstCol}</td>
                    { projectCaseEntries.map((pcEntry) => (
                        <React.Fragment key={pcEntry.projectCase}>
                            <td style={tds}>{pcEntry.bindingScore}</td>
                            <td style={tds}>{pcEntry.ligandEfficiency}</td>
                        </React.Fragment>
                    ))}
                    <td style={tds}>{molwt}</td>
                    { uiInfo.displayLinkType
                    && (<td style={tds} dangerouslySetInnerHTML={{ __html: linkType }} />) }
                    <td>
                        <DisplayCoralMap coralMapInfoEntries={coralMapInfoEntries} />
                    </td>
                    <td>
                        {pin}
                        {addButton}
                        {uiInfo.usingSlider && toggleButton}
                    </td>
                </tr>
                {uiInfo.usingSlider && (
                <tr
                    key={`slider-${sugg.index}`}
                    onMouseEnter={() => suggestionEnter(sugg)}
                    onMouseLeave={() => suggestionExit(sugg)}
                >
                    <td colSpan="100">
                        {sliderExpanded && (
                        <Slider
                            eltId={this.getSliderId()}
                            value={sliderValue}
                            max={sugg.frags.length-1}
                            onChange={this.onChange}
                            tooltip={sliderTooltip}
                        />
                        )}
                    </td>
                </tr>
                )}
            </>
        );
    }
}

function getCaseInfo(pc, bindingScore, ligandEfficiency) {
    const mapAndTarget = getMapCaseandTargetInfo(pc);
    const caseInfo = {
        [pc]: {
            projectCase: pc,
            mapCase: mapAndTarget[0],
            targetInfo: mapAndTarget[1],
            bindingScore,
            ligandEfficiency,
        },
    };
    return caseInfo;
}

function getFragmentInfo(pc, sugg) {
    const fragmentInfo = App.Workspace.getInfoForFragment(sugg.name, pc);
    if (!fragmentInfo) {
        console.warn(`Could not find fragment info for ${sugg.name}`);
        return { name: sugg.name, smiles: '', molwt: 0 };
    }
    const fragInfo = {
        name: fragmentInfo.name,
        smiles: fragmentInfo.smiles.split(/[\\ \t\n]/)[0],
        molwt: sugg.mwt != null ? sugg.mwt.toFixed(0) : '',
    };
    return fragInfo;
}

function getMapCaseandTargetInfo(projectCase) {
    let info = [];
    const targetMap = getTargetInfoMap();
    for (const [mapCase, targetInfo] of targetMap.entries()) {
        if (mapCase.projectCase === projectCase) {
            info = [mapCase, targetInfo];
        }
    }
    return info;
}

function getInfoForCoralMap(caseInfo, fragInfo, pc) {
    const kinaseID = caseInfo[pc].mapCase.otherProperties ? caseInfo[pc].mapCase.otherProperties.kinaseId : '';
    // this is for projects2 map list not yet updated
    // const kinaseID = caseInfo[pc].mapCase.gene_name;
    const cmInfo = {
        kinaseID,
        projectCase: pc,
        bindingScore: caseInfo[pc].bindingScore,
        ligandEfficiency: caseInfo[pc].ligandEfficiency,
    };
    return cmInfo;
}

function SuggestionsHeader({
    projectCases, uiInfo,
    sortCol, sortAscending, handleSort,
    showImages, toggleImages,
}) {
    const isMultiCase = projectCases.length > 1;
    const targetMap = getTargetInfoMap();
    const getTargetTooltip = (projectCase) => {
        for (const [mapCase, targetInfo] of targetMap.entries()) {
            if (mapCase.projectCase === projectCase) {
                return targetInfo.isTarget ? 'Target' : 'Off-target';
            }
        }
        return '';
    };

    const sortIcon = (column) => {
        if (sortCol === column) {
            const className = sortAscending ? 'fa fa-caret-up' : 'fa fa-caret-down';
            return <i className={className} style={{ display: 'inline' }} />;
        } else {
            return '';
        }
    };

    return (
        <>
            {/* grouphead displays protein labels in multi-protein case */}
            {!!isMultiCase && (
                <tr className="ligandmod-grouphead">
                    <th colSpan="2"> </th>
                    {projectCases.map((pc) => (
                        <th
                            key={pc}
                            colSpan="2"
                            title={getTargetTooltip(pc)}
                        >
                            {pc}
                        </th>
                    ))}
                    {/* Mol. Wt. */}
                    <th> </th>
                    {/* Link Type */}
                    { !!uiInfo.displayLinkType && <th> </th>}
                    {/* Controls */}
                    <th> </th>
                </tr>
            )}
            {/* colhead displays the sortable column headers */}
            <tr key="header-row" className="ligandmod-colhead">
                <th
                    style={{ width: '1px' }}
                    onClick={() => { toggleImages(); }}
                    title="Switch between fragment images and names"
                >
                    <i id="image-name-toggle" className="fa fa-random" />
                </th>
                <th onClick={() => handleSort('name')}>
                    {showImages ? 'Frag' : 'Name'}
                    &nbsp;
                    {sortIcon('name')}
                    &nbsp;&nbsp;
                </th>
                { projectCases.map((pc) => (
                    <React.Fragment key={pc}>
                        <th
                            onClick={() => handleSort(`bindingScore+${pc}`)}
                            title="Binding score is based on binding free energy"
                        >
                            Binding
                            <br />
                            Score&nbsp;
                            {sortIcon(`bindingScore+${pc}`)}
                        </th>
                        <th
                            onClick={() => handleSort(`ligandEfficiency+${pc}`)}
                            title="Ligand efficiency = binding score / mol. wt."
                        >
                            Ligand
                            <br />
                            Effcy.&nbsp;
                            {sortIcon(`ligandEfficiency+${pc}`)}
                        </th>
                    </React.Fragment>
                ))}
                <th onClick={() => handleSort('mwt')} title="Molecular weight">
                    Mol.
                    <br />
                    Wt.&nbsp;
                    {sortIcon('mwt')}
                </th>
                {uiInfo.displayLinkType
                && (
                    <th onClick={() => handleSort('modType')} title="Link types are simple bonds, carbon links, or acetylene links.">
                        Link
                        <br />
                        Type&nbsp;
                        {sortIcon('modType')}
                    </th>
                )}
                <th aria-label="Controls"> </th>
            </tr>
        </>
    );
}
