import { App } from '../BMapsApp';
import { AtomGroupTypes } from '../model/atomgroups';
import { ColorChain } from '../themes';
import { hexToHsl, hslToHex } from '../util/color_utils';

export class SelectivityColoring {
    static selectivityActive() {
        return App.Workspace.getLoadedProteins().length > 1;
    }

    /**
     * Given a projectCase string or system object, return the ordinal of the associated MapCase
     * in the system. This is used for selectivity coloring.
     * @param {*} thing a projectCase string or a system object
     * @returns {number} the ordinal of the associated case, or -1 if not found
     */
    static getCaseOrdinal(thing) {
        let projectCaseStr = '';

        if (typeof thing === 'string') {
            projectCaseStr = thing;
        } else {
            // Try system objects: MapCase, MolAtomGroup, HotSpot, etc
            const { mapCase } = App.getDataParents(thing);
            if (mapCase) {
                projectCaseStr = mapCase.projectCase;
            } else {
                console.error(`SelectivityColoring.getCaseOrdinal: Couldn't find map case for ${typeof thing} ${thing}`);
            }
        }

        return App.Workspace.getLoadedProteins().findIndex(
            (mapCase) => mapCase.projectCase === projectCaseStr
        );
    }

    static getBaseColor(thing) {
        const ordinal = SelectivityColoring.getCaseOrdinal(thing);
        const index = ordinal + 1; // skip default at index 0;
        const colors = ColorChain;
        const colorValue = Object.values(colors)[index];
        return colorValue;
    }

    static getColor(atomGroup) {
        if (!this.selectivityActive()) return null;
        const baseColor = this.getBaseColor(atomGroup);
        let color;
        let carbonsOnly = true;

        switch (atomGroup.type) {
            case AtomGroupTypes.Protein:
            case AtomGroupTypes.Polymer:
                color = this.getChainColor(atomGroup, baseColor);
                break;
            case AtomGroupTypes.Compound:
            case AtomGroupTypes.Cofactor:
            case AtomGroupTypes.CrystalWater:
            case AtomGroupTypes.Fragment:
            case 'Hotspot':
                color = baseColor;
                break;
            case AtomGroupTypes.ComputedWater:
                color = this.getComputedWaterColor(baseColor);
                carbonsOnly = false;
                break;
            // no default
        }
        if (color) {
            return { color, carbonsOnly };
        }
        return null;
    }

    static getChainColor(atomGroup, baseColor) {
        const { caseData } = App.getDataParents(atomGroup);
        const allChains = [...caseData.getProteinChains(), ...caseData.getPolymers()];
        const [h, s, l] = hexToHsl(baseColor);
        const lFactors = [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4];
        const variations = [];
        for (const lFactor of lFactors) {
            variations.push(hslToHex(h, s, lFactor * l));
        }

        const index = allChains.indexOf(atomGroup);

        let variation = variations[index];
        if (variation == null) variation = variations[variations.length - 1];
        const color = variation;
        return color;
    }

    static getComputedWaterColor(baseColor) {
        const [h, s, l] = hexToHsl(baseColor);
        const newS = 0.5;
        const newL = 0.8;
        return hslToHex(h, newS, newL);
    }
}
