import { BackgroundColors, DefaultBackgroundColor } from '../../themes';
import { DefaultBindingSiteRadius } from '../../utils';

export const PREFS_TYPE = {
    boolean: 'boolean',
    enum: 'enum',
    int: 'int',
};

export const preferences = [
    {
        id: 'ShowCommandData',
        label: 'Show Command Data',
        type: PREFS_TYPE.boolean,
        default: false,
    },
    {
        id: 'DontPromptBeforeExit',
        label: "Don't Prompt Before Exit",
        type: PREFS_TYPE.boolean,
        default: false,
    },
    {
        id: 'MinimizationEngine',
        label: 'Minimization Engine',
        type: PREFS_TYPE.enum,
        values: ['MM', 'BFD'],
        default: 'MM',
    },
    {
        id: 'EnergyEngine',
        label: 'Energy Engine',
        type: PREFS_TYPE.enum,
        values: ['MM', 'BFD'],
        default: 'MM',
    },
    {
        id: 'FragmentLoading',
        label: 'Fragment loading strategy',
        type: PREFS_TYPE.enum,
        values: ['greedy', 'lazy'],
        default: 'greedy',
    },
    {
        id: 'MaxMolecules',
        label: 'Max. molecules to load from SDF',
        type: PREFS_TYPE.int,
        default: 100,
    },
    {
        id: 'UseAlignment',
        label: 'Align selectivity proteins to first loaded protein',
        type: PREFS_TYPE.boolean,
        default: true,
    },
    {
        id: 'ColorScheme',
        label: 'Color Scheme',
        type: PREFS_TYPE.enum,
        values: Object.keys(BackgroundColors),
        default: DefaultBackgroundColor,
    },
    {
        // TODO: consider introducing a float preference type and a "step" option
        // Binding site radius steps by 0.5 with the mousewheel
        id: 'BindingSiteDistance',
        label: 'Binding Site Distance (Å)',
        type: PREFS_TYPE.int,
        default: DefaultBindingSiteRadius,
    },
    {
        id: 'UseAutoGiFE',
        label: 'Request GiFE energies automatically',
        type: PREFS_TYPE.boolean,
        default: true,
    },
];
