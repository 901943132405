import { UserActions } from './cmds/UserAction';
import { EventBroker } from './eventbroker';
import { MapCase, UserDataImportCase } from './model/MapCase';
import { App } from './BMapsApp';
import { SavedState } from './model/SavedState';

const savedStatesKey = 'sessionStates';
const recentProteinsKey = 'recentProteins';
const rememberProteinsKey = 'rememberRecentProteins';
const maxRecentProteins = 2;

class StateStore {
    constructor() {
        this.memoryStorage = new MemoryStorage();
        this.localStorage = new LocalStorage();
        this.storageDevices = {
            [StorageStrategy.Memory]: this.memoryStorage,
            [StorageStrategy.LocalStorage]: this.localStorage,
        };
        EventBroker.subscribe('proteinLoaded', (evt, { mapCase, caseData }) => {
            if (this.rememberRecentProteins() && caseData) this.saveProtein(mapCase);
        });
    }

    saveState(name='latest', stateIn=null, saveTo=StorageStrategy.Memory) {
        const state = stateIn || UserActions.CaptureState();
        const storage = this.getStorageDevice(saveTo);
        storage.store(name, state);
        return state;
    }

    async loadState(name='latest', loadFrom=StorageStrategy.Memory) {
        const storage = this.getStorageDevice(loadFrom);
        const state = storage.retrieve(name);
        if (state) {
            await UserActions.RestoreState(state);
        }
        return state;
    }

    listSaves(from=StorageStrategy.Memory) {
        const storage = this.getStorageDevice(from);
        return storage.listAllSaves();
    }

    saveToLocalStorage(name, state) {
        return this.saveState(name, state, StorageStrategy.LocalStorage);
    }

    loadFromLocalStorage(name) {
        return this.loadState(name, StorageStrategy.LocalStorage);
    }

    listSavesFromLocalStorage() {
        return this.listSaves(StorageStrategy.LocalStorage);
    }

    getStorageDevice(strategy) {
        return this.storageDevices[strategy];
    }

    /**
     * @param {MapCase} protein
     */
    saveProtein(protein) {
        if (!Object.values(MapCase.Sources).includes(protein.sourceDesc)) {
            console.warn(`Invalid protein source type for saving to browser storage: ${protein.sourceDesc}`);
            return false;
        }
        const serializedProtein = SavedState.CaptureProtein(protein);
        const proteins = this.listRecentProteins();
        // If we have the same uri as one in the list, move it to the front of the list
        // However, user uploaded ones may have conflicting URIs
        const proteinList = proteins.filter(
            (x) => x.CaseUri !== serializedProtein.CaseUri || UserDataImportCase.isUri(x.CaseUri)
        );
        proteinList.unshift(serializedProtein);
        while (proteinList.length > maxRecentProteins) proteinList.pop();
        try {
            LocalStorageManager.set(recentProteinsKey, proteinList);
            return true;
        } catch {
            // Protein is probably too big to save.
            return false;
        }
    }

    listRecentProteins() {
        const proteins = LocalStorageManager.get(recentProteinsKey, []);
        return proteins.filter((x) => typeof x !== 'string'); // previously stored URIs, now objects
    }

    rememberRecentProteins() {
        const valueDefault = true;
        return LocalStorageManager.get(rememberProteinsKey, valueDefault);
    }

    setRememberRecentProteins(remember) {
        LocalStorageManager.set(rememberProteinsKey, remember);
    }
}

class MemoryStorage {
    constructor() {
        this.map = new Map();
    }

    store(name, state) {
        this.map.set(name, state);
    }

    retrieve(name) {
        this.map.get(name);
    }

    listAllSaves() {
        return this.map.keys();
    }
}

class LocalStorage {
    constructor(localStorageKey=savedStatesKey) {
        this.localStorageKey = localStorageKey;
    }

    store(name, state) {
        const savedStates = this.getSavedStates();
        savedStates[name] = state;
        this.setSavedStates(savedStates);
    }

    retrieve(name) {
        const savedStates = this.getSavedStates();
        return savedStates[name];
    }

    listAllSaves() {
        const savedStates = this.getSavedStates();
        return Object.keys(savedStates);
    }

    getSavedStates() {
        const savedStatesString = localStorage.getItem(this.localStorageKey);
        const savedStates = savedStatesString ? JSON.parse(savedStatesString) : {};
        return savedStates;
    }

    setSavedStates(savedStates) {
        localStorage.setItem(this.localStorageKey, JSON.stringify(savedStates));
    }
}

const LocalStorageManager = {
    set(key, value) {
        const json = JSON.stringify(value);
        localStorage.setItem(key, json);
    },
    get(key, defaultValue) {
        const json = localStorage.getItem(key);
        if (json === null && defaultValue !== undefined) {
            return defaultValue;
        }
        try {
            const value = JSON.parse(json);
            return value;
        } catch {
            console.warn(`Could not parse JSON stored at local storage key ${key}`);
            return null;
        }
    },
    exists(key) {
        return this.get(key) === null;
    },
};

const StorageStrategy = {
    Memory: 'Memory',
    LocalStorage: 'LocalStorage',
};

export const SessionManager = new StateStore();
