import { useState, useEffect } from 'react';
import { MapCase, PdbImportCase, AlphaFoldImportCase } from '../../../../../model/MapCase';
import { Loader } from '../../../../../Loader';
import SelectSearch from '../../../../common/SelectSearch';
import { ImportErrors } from '../../import_components';

function SelectProteinTab({
    initData, selectProtein, connection, errors,
}) {
    const dataConnection = connection;
    const mapList = dataConnection.getCaseDataCollection().getMapList();
    // Initialize the selector with just the subset that is likely to be visible on form opening.
    // The list gets fully populated by the useEffect; the only subtle change is the scrollbar size.
    // To make it open even faster, the call to prepareItems could be moved into the setTimeout.
    // However, this would mean we don't have the exact contents ahead of time, and the listbox
    // will probably flash.
    const prepared = prepareItems(mapList);
    const [availableProteins, setAvailableProteins] = useState(prepared.slice(0, 40));
    const [query, setQuery] = useState(initData.options?.searchSeed || '');

    useEffect(() => {
        // This setTimeout allows the form to open before the data is 100% ready,
        // which makes it feel like less of a delay. Previously, it could take 1 sec to open.
        setTimeout(() => {
            const proteins = prepareItems(connection.getCaseDataCollection().getMapList());
            setAvailableProteins(checkForDynamicEntries(query, proteins));
        }, 0);
    }, [connection]);

    async function addProtein(selectedProtein) {
        const foundMapCase = availableProteins.find(
            (protein) => protein.id === selectedProtein.id
        )?.mapCase;
        if (foundMapCase) {
            selectProtein(foundMapCase);
        }
    }

    function checkForDynamicEntries(searchTerm, proteinList=availableProteins) {
        // If user entered a pbdid or alphafoldID then add a option to import it.
        const term = searchTerm.toUpperCase().trim();
        const newAvailableProteins = proteinList.filter((protein) => !protein.temporary);
        if (connection.connector.staticMode) {
            return newAvailableProteins;
        }
        if (PdbImportCase.isValidId(term)) {
            const { description, itemName } = PdbImportCase.newRecordMsgInfo(term);
            newAvailableProteins.push({
                id: term,
                pbdid: term,
                itemName,
                text: `${itemName}${itemName && description ? ': ' : ''}${description}`,
                linkInfo: PdbImportCase.externalLinkInfo(term),
                mapCase: new PdbImportCase(term),
                temporary: true,
                groupName: 'Import from PDB',
                title: 'Import the raw structure from the Protein Data Bank',
                searchTerms: [term],
            });
        }
        if (AlphaFoldImportCase.isValidId(term)) {
            const { description, itemName } = AlphaFoldImportCase.newRecordMsgInfo(term);
            newAvailableProteins.push({
                id: term,
                itemName,
                text: `${itemName}${itemName && description ? ': ' : ''}${description}`,
                linkInfo: AlphaFoldImportCase.externalLinkInfo(term),
                mapCase: new AlphaFoldImportCase(term),
                temporary: true,
                groupName: 'Import from AlphaFold',
                title: 'Import the predicted structure from the AlphaFold database',
                searchTerms: [term],
            });
        }
        return newAvailableProteins;
    }

    return (
        <SelectSearch
            optionList={availableProteins}
            onSelection={addProtein}
            label="Type a protein name, protein class, or PDB ID (may be DNA/RNA)"
            hasLinks
            defaultQuery={query}
            checkForDynamicEntries={(searchTerm) => {
                setQuery(searchTerm);
                setAvailableProteins(checkForDynamicEntries(searchTerm));
            }}
            error={errors && <ImportErrors errors={errors} />}
        />
    );
}
export default SelectProteinTab;

function prepareItems(mapCaseList) {
    const newList = [];
    mapCaseList.forEach((mapCase, index) => {
        const { itemName, description, title } = displayParts(mapCase);
        const groupName = mapCase.group_name;
        const groupNameLowerCase = groupName.toLowerCase();
        const text = `${itemName}${itemName && description && ':  '}${description}`;
        const item = {
            groupName,
            title,
            id: index,
            linkInfo: mapCase.myExternalLinkInfo(),
            text,
            mapCase,
            searchTerms: [text, groupName, title, mapCase.project],
        };
        const foundIndex = newList.findLastIndex(
            (protein) => protein.groupName.toLowerCase() === groupNameLowerCase
        );
        const topGroups = ['pdb imports', 'alphafold imports', 'user uploads'];
        if (foundIndex !== -1) {
            // Add this item to an already existing group
            item.groupName = newList[foundIndex].groupName;
            newList.splice(foundIndex + 1, 0, item);
        } else if (topGroups.includes(groupNameLowerCase)) {
            newList.unshift(item);
        } else {
            newList.push(item);
        }
    });
    return (newList);
}

function displayParts(mapCase) {
    let itemName = mapCase.pdbID || mapCase.molecule_name || mapCase.gene_name;
    let description = mapCase.description;
    let source = '';

    switch (mapCase.sourceDesc) {
        case MapCase.Sources.Public:
        case MapCase.Sources.Private:
            // "Prepared' prefix has two purposes:
            // 1) distinguish cases on which we have done full optimization and simulation
            // 2) Avoid the appearance of using pdb id to refer to our prepared data
            // That is, the referent of a pdb id should be the data in the pdb, not our data.
            // See: ftp://ftp.wwpdb.org/pub/pdb/advisory.txt
            itemName = mapCase.pdbID ? `Prepared from ${mapCase.pdbID}` : 'Prepared structure';
            source = mapCase?.dataSource.name;
            break;
        case MapCase.Sources.Fragserv: {
            // For user structures, we want to display the full specified project/case names.
            // But if the project name matches the molecule name, it will already be in the
            // map selector group header, so just use the case.
            const displayName = mapCase.project === mapCase.molecule_name.replace(/ /g, '-')
                ? mapCase.case
                : mapCase.projectCase;
            itemName = `User data ${displayName}`;
            source = mapCase?.dataSource.name || 'Custom simulations';
            break;
        }
        case MapCase.Sources.PDB:
            itemName = mapCase.pdbID;
            source = 'Imported from the Protein Data Bank';
            break;
        case MapCase.Sources.ImportScreen:
            source = 'Imported by user';
            break;
        case MapCase.Sources.AlphaFold:
            source = 'Imported from the AlphaFold database';
            break;
        // no default - itemName assigned above
    }

    let title = source ? `Source: ${source}` : '';
    if (mapCase.projectCase && Loader.AllowLabFeatures) {
        const prefix = title ? '. ' : '';
        title += `${prefix}Project Code: ${mapCase.projectCase}`;
    }
    if (mapCase?.lastUpdate) {
        const prefix = title ? '. ' : '';
        title += `${prefix}Last update: ${mapCase.lastUpdate.toLocaleDateString()}`;
    }

    // Intent: if we have pdbid but no description, show mol or gene name for desc in map selector
    if (!description
        && itemName !== mapCase.molecule_name && itemName !== mapCase.gene_name) {
        description = mapCase.molecule_name || mapCase.gene_name;
    }

    return { itemName, description, title };
}
