import { App } from '../BMapsApp';
import { EventBroker } from '../eventbroker';
import { UserCmd, UserActions } from './UserCmd';
import { applyEnergyStateToCompound } from '../model/energyinfo';
import { AlignAction, MolDataSource, showAlert } from '../utils';
import { StyleManager } from '../style_manager';
import { SavedState } from '../model/SavedState';
import { LoadingScreen } from '../LoadingScreen';
import { MapCase, UserDataImportCase } from '../model/MapCase';
import { setBindingSiteDistance } from '../redux/prefs/access';
import { getStructureErrMsg } from '../server/error_utils';

// Save / Restore State related Actions
// Consider moving to a separate file
export const SaveRestoreCmds = {
    CaptureState: new UserCmd('CaptureState', captureState),
    CaptureStateToString: new UserCmd('CaptureStateToString', captureStateToString),
    RestoreState: new UserCmd('RestoreState', restoreState),
    RestoreProtein: new UserCmd('RestoreProtein', restoreProtein),
};

/// //// State commands /////////
// This state capture / restore logic very easily belongs somewhere else.
// However, since restore makes use of a lot of UserActions, and it is desirable
// to keep save and restore near each other, these will stay here.

function captureState(workspace=App.Workspace) {
    return SavedState.Capture(workspace, StyleManager);
}

async function restoreState(obj, alert=true) {
    const { errors } = await LoadingScreen.withLoadingScreen(
        doRestoreState(obj), 'Loading Session. This may take a moment... '
    );
    if (alert && errors.length > 0) {
        showAlert(`Problems restoring session:\n\n* ${errors.join('\n* ')}`, 'Session Restore');
    }

    return { errors };
}

async function doRestoreState(obj) {
    let state;
    // Make sure we can at least process the saved state data before zapping
    try {
        state = SavedState.Load(obj);
    } catch (error) {
        return { errors: [error] };
    }

    await UserActions.ZapAll();
    // Restore connections with proteins and compounds
    const { restoredData, errors } = await restoreDataConnections(state);

    // Display settings, etc
    UserActions.SetColorScheme(state.Display.Options.BackgroundColor);
    setBindingSiteDistance(state.Display.Options.BindingSiteRadius);
    App.Workspace.displayState.restoreState(state.Display.State);
    App.Workspace.applyTreeState(state.Display.TreeState);
    App.Workspace.rebuildProteinTree();
    App.Workspace.rebuildFragmentsTree();

    // Style
    if (state.Style) {
        StyleManager.restoreStyleState(state.Style);
        EventBroker.publish('resetDisplayRequest');
    }
    return { errors };
}

// Fetch the fragment maps for fragment series which are visible
// after restoring state.
async function fetchRestoredFragments(stateObj, caseData) {
    if (stateObj && stateObj['FragInfo']) {
        for (const fragInfo of stateObj['FragInfo']) {
            // fragInfo.object is assigned during atomgroup state restoration
            if (fragInfo.object && fragInfo.state.visible) {
                await UserActions.GetFragmentMap(fragInfo.object);
            }
        }
    }

    UserActions.LoadFragments(App.Workspace.getActiveFragments(caseData));
}

function captureStateToString(workspace=App.Workspace) {
    return JSON.stringify(captureState(workspace), null, 4);
}

/**
 * Restore data connections from the state object, including connection type, proteins, & compounds
 * @param {*} state
 * @returns {{restoredData: Array, errors: Array}}
 */
async function restoreDataConnections(state) {
    const restoredData = [];
    const errors = [];
    for (const { Mode, LoadedCases } of state.Connections) {
        const { dataConnection, errorInfo } = await getConnectionToUse(Mode);

        if (errorInfo) {
            errors.push(errorInfo);
        }
        if (dataConnection) {
            const data = await restoreOneDataConnection(dataConnection, LoadedCases, state);
            errors.push(...data.errors);
            restoredData.push(...data.restoredData);
        }
    }

    // Make sure that the proper compound is active after everything has been loaded.
    activateActiveCompound(state);
    return { restoredData, errors };
}

/**
 * Get a connection for restoring a protein from saved state.
 * Use server or static mode depending on primary connection mode and what's specified in the state:
 * Always use static mode if in static mode.
 * If in server mode, use what's in the state object, or default to server mode if old version.
 * @param {string} modeInSavedState
 * @returns { Promise<{dataConnection: DataConnection, errorInfo: Array }>}
 */
function getConnectionToUse(modeInSavedState) {
    let modeToUse = modeInSavedState || 'server'; // If not specified in state, it's an old version
    if (App.PrimaryDataConnection.getMode() === 'static') {
        modeToUse = 'static';
    }

    switch (modeToUse) {
        case 'static':
            return App.ConnectionManager.newStaticConnection();
        case 'server':
            // fall through
        default:
            return App.ConnectionManager.newServerConnection();
    }
}

/**
 * Restore the data for one data connection: all its CaseData with proteins and compounds
 * @param {DataConnection} dataConnection
 * @param {Array<{Protein, Compounds, AtomGroupState}>} loadedCases
 * @param {*} state
 * @param {*} options
 * @returns {{restoredData: Array, errors: Array}}
 */
async function restoreOneDataConnection(dataConnection, loadedCases, state, options={}) {
    const restoredData = [];
    const errors = [];
    const keepExisting = options.keepExisting || true;
    const { caseDataCollection } = dataConnection.get();
    for (const loadedCase of loadedCases) {
        const {
            Protein,
            Compounds,
            AtomGroupState,
            Styles,
        } = loadedCase;
        const {
            mapCase, errors: proteinErrors,
        } = await restoreProtein(Protein, dataConnection, { keepExisting });

        errors.push(...proteinErrors);

        restoredData.push({ mapCase, proteinErrors });
        const caseData = mapCase
            ? caseDataCollection.lookupCaseData(mapCase)
            : caseDataCollection.getNoProteinCaseData();

        if (!caseData) {
            // Failed to load protein, so can't do anything else. Error previously reported.
            continue;
        }

        const {
            errors: cmpdErr,
        } = await restoreCompounds(Compounds, state, caseData);

        errors.push(...cmpdErr);
        App.Workspace.restoreAtomGroupState(AtomGroupState, caseData);
        if (dataConnection.getMode() !== 'static') {
            await fetchRestoredFragments(AtomGroupState, caseData);
        }
        loadedCase.caseData = caseData; // save caseData so we can access it later

        // Apply styles
        for (const [key, style] of Object.entries(Styles.AtomGroupsColorState)) {
            const group = caseData.allAtomGroups().find((ag) => ag.key === key);
            if (group) {
                StyleManager.setCustomAtomGroupColor(group, style);
            }
        }

        for (const [key, style] of Object.entries(Styles.AtomGroupsMolStyleState)) {
            const group = caseData.allAtomGroups().find((ag) => ag.key === key);
            if (group) {
                StyleManager.setCustomAtomGroupMolStyle(group, style);
            }
        }
    }
    return { restoredData, errors };
}

async function restoreProtein(protein, dataConnection=App.PrimaryDataConnection, options={}) {
    const { CaseUri, UserData } = protein;
    let errors = [];
    let loadedMapCase;
    let caseData;
    if (CaseUri) {
        if (UserData) { // Restoring a user-loaded protein
            const protCase = new UserDataImportCase(UserData);
            ({
                mapCase: loadedMapCase, errors, caseData,
            } = await UserActions.ChooseProtein(protCase, {}, dataConnection));
        } else { // Restoring protein from map selector / pdb import
            const uriToUse = getUriToUse(CaseUri, dataConnection);

            const loadArgs = {
                fragmentLoading: 'lazy',
                waitForFragments: true,
                keepExisting: options.keepExisting,
            };
            ({
                mapCase: loadedMapCase, errors, caseData,
            } = await UserActions.ChooseProtein(uriToUse, loadArgs, dataConnection));
        }
    }

    const err = getStructureErrMsg(loadedMapCase, errors, caseData);
    if (err) {
        errors = [err];
    }

    return { errors, mapCase: loadedMapCase };
}

function getUriToUse(uri, dataConnection) {
    let uriToUse = uri;
    const { caseDataCollection } = dataConnection.get();
    // Two cases to ignore the mount index:
    // 1. In static mode we don't have any data sources, just one map-list loaded via https fetch.
    // 2. The relevant data source could have a different mount index than the one in the state.
    // This can happen if a session file was shared between users with different data sources.
    if (dataConnection.getMode() === 'static' || !caseDataCollection.findMapByUri(uri)) {
        const { projectCase, mountIndex } = MapCase.splitUri(uriToUse);
        if (mountIndex) {
            uriToUse = projectCase;
        }
    }
    return uriToUse;
}

async function restoreCompounds(entries, state, caseData) {
    const errors = [];

    // First load sdf of any user compounds
    const { errors: loadErrors } = await loadUserCompounds(entries, state, caseData);
    errors.push(...loadErrors);

    // Apply visibility to ligands or user compounds
    const visibleEntries = entries.filter(({ visible }) => visible);
    const visibleCmpds = compoundsForStateEntries(visibleEntries, caseData);
    App.Workspace.toggleVisibility(visibleCmpds, true);

    // Finished
    return { errors };
}

async function loadUserCompounds(entries, state, caseData) {
    const errors = [];
    const userCompounds = entries.filter((cmpd) => cmpd.mol);
    if (userCompounds.length === 0) {
        return { errors };
    }

    let sdf = userCompounds.map((cmpd) => cmpd.mol).join('$$$$\n');
    sdf += '$$$$\n';
    const molData = new MolDataSource({
        sourceType: 'Restore', molFormat: 'sdf', molData: sdf,
    });
    const loadResults = await UserActions.LoadMolData(molData, AlignAction.none, caseData);
    const { compounds: loaded, errors: loadErrors } = loadResults;
    errors.push(...loadErrors);

    // Request energies for user compounds if necessary.
    // Note: Save / restore of recalculated energies for ligands is not implemented
    for (const saved of userCompounds) {
        const loadedCompound = loaded.find((cmpd) => saved.name === cmpd.resSpec);
        if (loadedCompound) {
            if (state.supportsFeature(SavedState.Features.EnergyDetail1)) {
                await UserActions.GetForcefieldParameters(loadedCompound);
                const problems = applyEnergyStateToCompound(
                    loadedCompound, saved.energies, saved.solvation, caseData,
                );
                if (problems && problems.length > 0) {
                    console.warn('Problems restoring energies, recalculating');
                    await UserActions.GetEnergies(loadedCompound);
                }
            } else {
                console.warn("Energy detail isn't available, calculating");
                await UserActions.GetEnergies(loadedCompound);
            }
        } else {
            console.warn(`Failed to load one compound for session compound ${saved.name}. Loaded ${loaded.length} compounds. Errors: ${errors}`);
        }
    }

    return { errors };
}

/** Convert state compound entries to compound business objects */
function compoundsForStateEntries(entries, caseData) {
    const ret = [];
    for (const { name } of entries) {
        const cmpd = caseData.getCompoundBySpec(name);
        if (cmpd) {
            ret.push(cmpd);
        } else {
            console.error(`Failed to find restoring compound: ${name}`);
        }
    }
    return ret;
}

/** Requires that the state LoadedCase objects have been annotated with caseData */
function activateActiveCompound({ Connections }) {
    let cmpd = App.Workspace.getLoadedCompounds()[0];

    for (const { LoadedCases } of Connections) {
        for (const { Compounds, caseData } of LoadedCases) {
            if (!caseData) continue;
            for (const { active, name } of Compounds) {
                if (active) {
                    cmpd = caseData.getCompoundBySpec(name);
                    if (cmpd) {
                        App.Workspace.activateCompound(cmpd);
                        return;
                    }
                }
            }
        }
    }
    // We didn't find an active compound so default to the first
    if (cmpd) {
        App.Workspace.activateCompound(cmpd);
    }
}
