import React, { useState } from 'react';
import {
    Popper, ClickAwayListener, MenuList, MenuItem, ButtonGroup, Button, Paper,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import styled from 'styled-components';
import { wrapForDisabledTooltip } from './helpers';

const MenuStyle = styled(MenuItem)`
    .MuiButton-root {
        width: 100%
    }`;

/**
 * This component produces a drop-down button, similar to github's squash & merge button on PRs.
 * To use, simply make the buttons that would be
 * choices children of the DialogDropDownButton component
 * @param {Object} style default={} pass css object style to style buttons
 * @returns react component
 */
export default function DialogSplitButton({ children, style={} }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const childArray = React.Children.toArray(children);

    if (childArray.length < 1) return children;

    function arrowClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <>
            <ButtonGroup style={style}>
                <Button style={{ width: '100%' }} color="neutral" {...childArray[0].props} />
                {childArray.length > 1 && (
                    <Button onClick={arrowClick} color="neutral" size="small">
                        <ArrowDropDown />
                    </Button>
                )}
            </ButtonGroup>
            <Popper
                anchorEl={anchorEl}
                open={open}
                placement="bottom-end"
                role={undefined}
                disablePortal
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                            {childArray.slice(1).map(
                                (child) => (
                                    <MenuStyle
                                        key={child.props.children}
                                        disableGutters
                                        style={{ padding: 0 }}
                                    >
                                        {wrapForDisabledTooltip(child)}
                                    </MenuStyle>
                                )
                            )}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
}
