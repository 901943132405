import _ from 'lodash';
import { Atom } from './atoms';
import { getColorSchemeInfo, getBindingSiteDistance } from '../redux/prefs/access';

const semver = require('semver');
/**
 * @description SavedState class
 */
export class SavedState {
    static get LatestVersion() { return '2.0.1'; }

    constructor(
        displayState,
        styleState,
        connections,
        version=SavedState.LatestVersion
    ) {
        this.Version = version;
        this.Display = displayState;
        this.Style = styleState;
        this.Connections = connections;
    }

    versionLessThan(input) {
        return semver.lt(this.Version, input);
    }

    versionAtLeast(input) {
        return semver.gte(this.Version, input);
    }

    supportsFeature(feature) {
        return feature && feature.minVersion
            && this.versionAtLeast(feature.minVersion);
    }

    static Capture(workspace, styleMgr) {
        const connections = SavedState.CaptureDataConnections(workspace, styleMgr);
        const displayState = {
            Options: {
                BackgroundColor: getColorSchemeInfo().name,
                BindingSiteRadius: getBindingSiteDistance(),
            },
            State: workspace.displayState.saveState(),
            TreeState: workspace.systemTree.saveState(),
        };

        const styleState = styleMgr.captureGlobalStyleState();

        return new SavedState(displayState, styleState, connections);
    }

    static Load(objIn) {
        const obj = (typeof objIn === 'string')
            ? JSON.parse(objIn)
            : { ...objIn }; // make sure we have a copy so version update doesn't affect original

        if (!semver.valid(obj.Version)) obj.Version = '0.1.0';
        return this.LatestVersionObjConverter(obj);
    }

    static LatestVersionObjConverter(obj) {
        let working = { ...obj };

        // Bail if attempting to import a more advanced major version.
        if (semver.major(working.Version) > semver.major(SavedState.LatestVersion)) {
            throw new Error('This saved session is from a newer version of BMaps and cannot be loaded.');
        }

        // Transform previous format into the latest format, step by step
        if (semver.lt(working.Version, '2.0.0')) {
            working = {
                Version: '2.0.0',
                Display: _.omit(working.Display, ['AtomGroupState']),
                Style: { ...working.Style },
                Connections: [
                    {
                        Mode: null,
                        LoadedCases: [
                            {
                                Protein: { ...working.Protein },
                                Compounds: [...working.Compounds],
                                AtomGroupState: { ...working.Display.AtomGroupState },
                                Styles: { AtomGroupsColorState: {} },
                            },
                        ],
                    },
                ],
            };
        }

        // Transform 2.0.0 to 2.0.1
        if (semver.lt(working.Version, '2.0.1')) {
            for (const conn of working.Connections) {
                for (const loadedCase of conn.LoadedCases) {
                    loadedCase.Styles.AtomGroupsMolStyleState = {};
                }
            }
        }

        //
        // Add any additional transforms here, from 2.0.1 to the next...
        //

        // Finished transforming
        // Use the original version in the final result, not the latest.
        // This is so supportsFeature version checks can be used downstream
        return new SavedState(working.Display, working.Style, working.Connections, obj.Version);
    }

    static CaptureProtein(protein) {
        const proteinUri = protein && protein.uri;
        const proteinDisplayName = protein && (protein.displayName);
        const userProteinInfo = protein && protein.uri.startsWith('local:') && {
            data: protein.data,
            format: protein.data_format,
            name: protein.molecule_name,
            pdbid: protein.pdbID,
            sourceDesc: protein.sourceDesc,
        };
        return {
            CaseUri: proteinUri,
            DisplayName: proteinDisplayName,
            UserData: userProteinInfo,
        };
    }

    static CaptureCompound(compound, active, visible) {
        const obj = {
            name: compound.resSpec,
        };
        if (!compound.isLigand()) {
            obj.mol = compound.getMol2000();
            obj.energies = exportCompoundEnergies(compound);
            obj.solvation = exportCompoundSolvation(compound);
        }
        if (compound === active) obj.active = true;
        if (visible.includes(compound)) obj.visible = true;
        return obj;
    }

    /**
     * Collect all data connections with their connection type and case data (protein & compounds)
     */
    static CaptureDataConnections(workspace, styleMgr) {
        const Connections = [];
        const dataConnections = workspace.getDataConnections();

        for (const { connector, caseDataCollection } of dataConnections) {
            const caseDatas = caseDataCollection.getAllCaseData();
            const Mode = connector.getMode();
            const LoadedCases = [];

            caseDatas.forEach((caseData) => {
                if (!caseData.isEmpty()) {
                    const caseDataState = SavedState.CaptureCaseData(caseData, workspace, styleMgr);
                    LoadedCases.push(caseDataState);
                }
            });
            Connections.push({ Mode, LoadedCases });
        }
        return Connections;
    }

    /**
     * Collect data for one caseData: protein, compounds, atomgroup state, and atomgroup style
     */
    static CaptureCaseData(caseData, workspace, styleMgr) {
        const Protein = this.CaptureProtein(caseData.mapCase);
        const Compounds = [];
        const activeCompound = workspace.getActiveCompound();
        const visibleCompounds = workspace.getVisibleCompounds(false);
        const compounds = caseData.getCompounds();
        const AtomGroupState = workspace.atomGroupState.saveState(caseData);
        const Styles = styleMgr.captureAtomGroupStyles(caseData.allAtomGroups());

        compounds.forEach((cmpd) => {
            const compoundState = SavedState.CaptureCompound(
                cmpd, activeCompound, visibleCompounds
            );
            Compounds.push(compoundState);
        });
        return {
            Protein, Compounds, AtomGroupState, Styles,
        };
    }
}

/**
 * @description Return raw binding energy data for this compound.
 * {
 *      internalEnergies:    [ [summary], [detail] ],
 *      interactionEnergies: [ [summary], [detail] ]
 * }
 * @param {*} compound
 */
export function exportCompoundEnergies(compound) {
    return compound.originalEnergyData;
}

/**
 * @description Return raw solvation data for this compound.
 * @returns {
 *      totals: [ ddG_PL, ddG_LP, ddG_P, ddG_L],
 *      detail: {
 *          compound: [ [atomName, dGs, dGs_bound]... ],
 *          solute:   [ [uniqueID, dGs, dGs_bound]... ]
 *      },
 *      PSA: [ PSA, PSAbound, TSA, TSAbound,
 *              proteinPSA, proteinPSAbound,
 *              proteinTSA, proteinTSAbound ]
 * }
 * @param {*} compound
 */
export function exportCompoundSolvation(compound) {
    const data = compound.originalSolvData;

    if (data) {
        // The original data may have atom objects, so convert them
        // atom name or ids as appropriate.
        for (const e of data.detail.compound) {
            if (e[0] instanceof Atom) { e[0] = e[0].atom; } // atom name
        }

        for (const s of data.detail.solute) {
            if (s[0] instanceof Atom) {
                s[0] = s[0].uniqueID;
            }
        }
    }

    return data;
}

SavedState.Features = {
    EnergyDetail1: { minVersion: '1.0.0' },
    TreeStructure1: { minVersion: '1.1.0' },
};
