/**
 * @fileoverview Manage bfd-server connections
 */

// Callers of httpGet and httpPost expect rejects to have an object with the request status code.
// This functionality should probably be migrated to fetch; not worth fixing now.
/* eslint-disable prefer-promise-reject-errors */

import { EventBroker } from '../eventbroker';
import { WebServices } from '../WebServices';

export const SessionErrorType = Object.freeze({
    NotLoggedIn: 'NotLoggedIn',
    SessionLimitReached: 'SessionLimitReached',
    TimedOut: 'TimedOut',
    ServerUnavailable: 'ServerUnavailable',
    NoInternet: 'NoInternet',
    Unknown: 'Unknown',
    UnavailableInStaticMode: 'UnavailableInStaticMode',
    Other: 'Other',
});

// adapted from WebServices.js
function httpGet(url) {
    return new Promise(
        (resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('load', function onLoad() {
                if (this.status >= 200 && this.status < 300) {
                    resolve(this.responseText);
                } else {
                    reject({ status: this.status, statusText: this.statusText });
                }
            });
            xhr.addEventListener('error', function onError() {
                reject({ status: this.status, statusText: this.statusText });
            });
            xhr.open('GET', url);
            xhr.send();
        },
    );
}

function httpPost(url, postData, contentType='application/x-www-form-urlencoded') {
    return new Promise(
        (resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('load', function onLoad() {
                if (this.status >= 200 && this.status < 300) {
                    resolve(this.responseText);
                } else {
                    reject({ status: this.status, statusText: this.statusText });
                }
            });
            xhr.addEventListener('error', function onError() {
                reject({ status: this.status, statusText: this.statusText });
            });
            xhr.open('POST', url);
            xhr.setRequestHeader('Content-Type', contentType);
            xhr.send(postData);
        },
    );
}

export class SessionErrorInfo {
    constructor(msg, title, type) {
        this.errType = type;
        this.errTitle = title;
        this.errMsg = msg;
    }

    toString() {
        return `${this.errTitle}: ${this.errMsg}`;
    }
}

// adapted from portal/js/landing.js
function getErrorMessage(errCode) {
    const tryAgain = "<div>Please try again later or let us know at <a href='mailto:support@coniferpoint.com'>support@coniferpoint.com</a></div>";

    switch (errCode) {
        case 200: // 200 is not an error code
            return new SessionErrorInfo('', '');
        case 400:
        case 401:
        case 409:
            return new SessionErrorInfo(
                "Log in to use Boltzmann Maps.<br><br>If you are unable to log in, please contact <a href='mailto:support@coniferpoint.com'>support@coniferpoint.com</a>",
                'Login Needed',
                SessionErrorType.NotLoggedIn,
            );
        case 499:
            return new SessionErrorInfo(
                'You are using all your allowed Boltzmann Maps sessions. Please close one of your tabs and try again or upgrade your subscription to get more sessions.',
                'Session Limit Reached',
                SessionErrorType.SessionLimitReached,
            );
        case 500:
            return new SessionErrorInfo(
                `There was an internal server error. ${tryAgain}`,
                'Server Unavailable',
                SessionErrorType.ServerUnavailable,
            );
        case 502:
            return new SessionErrorInfo(
                `The Boltzmann Maps server is down. ${tryAgain}`,
                'Server Unavailable',
                SessionErrorType.ServerUnavailable,
            );
        case 503:
            return new SessionErrorInfo(
                `The server is at maximum capacity. ${tryAgain}`,
                'Server Unavailable',
                SessionErrorType.ServerUnavailable,
            );
        case 0:
            return new SessionErrorInfo(
                'Could not contact Boltzmann Maps server. Please check your network connection.',
                'Internet Disconnected',
                SessionErrorType.NoInternet,
            );
        default:
            return new SessionErrorInfo(
                `Something went wrong while trying to contact the Boltzmann Maps server. ${tryAgain}`,
                'Server Unavailable',
                SessionErrorType.Unknown,
            );
    }
}

/**
 * Are we in a deployment type that bypasses the slot mechanism to get bfd-server sessions?
 * Three connection scenarios don't use slots:
 * 1. Local development (addressed by 'localhost' clause below)
 * 2. Automated Mocha tests that spin up their own server processes (also addressed by 'localhost')
 * 3. dev-server sandbox mode (only jlkjr) (currently addressed by bmaps-debug clause)
 *
 * @returns {boolean}
 *
 * @todo Move this into WebSocketStrategy somehow
 * @todo Get rid of need to look for bmaps-debug.html
 */
function slotsNotApplicable() {
    return typeof window === 'undefined'
        || window.location.hostname === 'localhost'
        || window.location.pathname.indexOf('bmaps-debug.html') > -1;
}

/**
 * Request a bfd-server slot from exec
 * @param {boolean} replaceSession Should we terminate a recent session to make room for this one?
 * @returns { { status: number, success: boolean, errorInfo: SessionErrorInfo, data: object}}
 */
export async function startSlotSession(replaceSession) {
    // Try to ensure login cookie is up-to-date
    try {
        await WebServices.refreshCookie();
    } catch (ex) {
        console.warn(ex);
    }

    const query = replaceSession ? '?replaceSession' : '';
    const requestUrl = `../portal/startSession.php${query}`;

    let response;
    try {
        response = await httpGet(requestUrl);
    } catch (ex) {
        console.warn(`Error in GET request to ${requestUrl}: ${ex.statusText}`);
        const errCode = ex.status;
        return { status: errCode, success: false, errorInfo: getErrorMessage(errCode) };
    }

    const { status, data } = JSON.parse(response);
    if (status === 200) {
        // bmapsUserName should maybe also be moved out of session storage
        if (data.bmapsUserName) sessionStorage.setItem('bmapsUserName', data.bmapsUserName);
        return { status, success: true, data };
    } else {
        return { status, success: false, errorInfo: getErrorMessage(status) };
    }
}

// TODO: This should only be called for sessions requested from exec (ie slotsNotApplicable===false)
export async function hasSlot(sessionKey) {
    if (slotsNotApplicable()) return true;

    const body = `bfdkey=${sessionKey}`;

    const requestUrl = '../portal/checkSession.php';

    let response;
    try {
        response = await httpPost(requestUrl, body);
    } catch (ex) {
        console.warn(`Error in POST request to ${requestUrl}: ${ex.statusText}`);
        return undefined;
    }

    const { result } = JSON.parse(response);

    return (result === 'Ok');
}

// endSession uses the beacon api to ensure that the request to endSession.php
// gets queued for sending even if called from a window.unload event handler.
// However, there is no guarantee about when the request will be sent.
export function endAllSlotSessions(dataConnections) {
    if (slotsNotApplicable()) return;

    for (const dataConnection of dataConnections) {
        const connector = dataConnection.getConnector();
        if (connector.getMode() === 'server') {
            const { key } = connector.connectionInfo;
            const formData = new FormData();
            formData.set('bfdkey', key);
            // This is only called in a window unload handler, so navigator should be defined.
            // Don't protect against undefined since there is no other execution path;
            // better to throw exception.
            navigator.sendBeacon('../portal/endSession.php', formData);
        }
    }
}

// endSessionNow provides a promise that can be awaited to ensure the request
// to endSession.php finishes before the continuing
// However, in window.unload, the request may not be sent before the page unloads
export async function endSlotSessionNow(sessionKey) {
    if (slotsNotApplicable()) return;

    const body = `bfdkey=${sessionKey}`;
    const requestUrl = '../portal/endSession.php';
    try {
        await httpPost(requestUrl, body);
    } catch (ex) {
        console.warn(`Error in POST request to ${requestUrl}: ${ex.statusText}`);
    }
}
