import React from 'react';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';
import { changeTarget, changeProteinFamily } from '../../../../../redux/projectTargets';
import { UserActions } from '../../../../../cmds/UserAction';

export default function ProjectTargetsTab() {
    const projectTargets = useSelector((state) => state.projectTargets);
    const dispatch = useDispatch();

    return (
        <>
            <TextField
                size="small"
                value={projectTargets.proteinFamily}
                label="Protein Family"
                sx={{ paddingBottom: '1rem' }}
                onChange={(evt) => {
                    dispatch(
                        changeProteinFamily(evt.target.value)
                    );
                }}
            />
            <Box
                sx={{
                    display: 'grid',
                    justifyContent: 'space-between',
                    gap: '1rem',
                }}
            >
                <Typography sx={{ gridArea: '1 / 1' }}>Structure</Typography>
                <Typography sx={{ gridArea: '1 / 2' }}>Target</Typography>
                <Typography sx={{ gridArea: '1 / 3' }}>Off Target</Typography>
                <Typography sx={{ gridArea: '1 / 4' }}>Remove</Typography>
                {projectTargets.targetList.map((target, index) => (
                    <React.Fragment key={target.mapCase.uri}>
                        <div style={{ gridArea: `${index + 2} / 1`, display: 'flex', alignItems: 'center' }}>
                            {target.displayName}
                        </div>
                        <div style={{ gridArea: ` ${index + 2} / 2` }}>
                            <Radio
                                checked={target.isTarget}
                                onClick={() => {
                                    dispatch(
                                        changeTarget({ mapCase: target.mapCase })
                                    );
                                }}
                            />
                        </div>
                        <div style={{ gridArea: ` ${index + 2} / 3` }}>
                            <Radio
                                checked={!target.isTarget}
                                onClick={() => {
                                    dispatch(
                                        changeTarget({ mapCase: target.mapCase })
                                    );
                                }}
                            />
                        </div>
                        <div style={{ gridArea: `${index +2} / 4` }}>
                            <IconButton
                                aria-label="delete"
                                onClick={
                                    () => {
                                        const collection = target.mapCase.getCaseDataCollection();
                                        const caseData = collection
                                            .getCaseDataByMapCase(target.mapCase);
                                        UserActions.RemoveCase(caseData);
                                    }
                                }
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </React.Fragment>
                ))}
            </Box>
        </>
    );
}
