/**
 * UserAction.js
 *
 * Loads all commands and exports the UserActions used by the rest of the system.
 *
 */
import { UserActions as CmdRegistry, CustomActions as CustomRegistry, AddUserActions } from './UserCmd';
import { ConnectedDataCmds } from './ConnectedDataCmds';
import { DisplayCmds } from './DisplayCmds';
import { SaveRestoreCmds } from './SaveRestoreCmds';
import { ComputationCmds } from './ComputationCmds';
import { FragmentsCmds } from './FragmentsCmds';
import { ModificationCmds } from './ModificationCmds';
import { UICmds } from './UICmds';
import { DesignCmds } from './DesignCmds';

export const UserActions = CmdRegistry;
export const CustomActions = CustomRegistry;

AddUserActions(ConnectedDataCmds);
AddUserActions(DisplayCmds);
AddUserActions(SaveRestoreCmds);
AddUserActions(ComputationCmds);
AddUserActions(FragmentsCmds);
AddUserActions(ModificationCmds);
AddUserActions(UICmds);
AddUserActions(DesignCmds);
