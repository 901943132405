import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
// Material UI
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Input from '@mui/material/Input';

const SquareIconButton = styled(IconButton).attrs(() => ({ size: 'small' }))`
  border-radius: 4px;
  color: black;
  border: solid 1px;
  width: 2em;
  height: 2em;
`;

const StyledInput = styled(Input)`
  border-radius: 4px;
  border: solid black 1px;
  margin: 0 1rem;

  & input {
      text-align: center;
      min-width: 2em; /* same as button width */
  }
`;

/**
 * Controlled component for a number field with increment/decrement buttons
 * @param {{
 *     name: string, // name of the field
 *     min: number, // min value, only currently enforced by the buttons
 *     max: number, // max value, only currently enforced by the buttons
 *     value: number, // current value
 *     setValue: function, // function to update the value
 *     setError: function, // function to show error message, called here only for broken config
 *     onChange: function, // optional onChange to support Formik. If not provided, setValue is used
 *     className: string, // css class for the container component
 *     incrementContainerProps: object, // extra props for the container component
 *     incrementInputProps: object, // extra props for the input component
 *     ...rest: object // extra props for the input component
 * }}
 *
 * NOTE: User can directly specify a value in input field that is beyond valid range (min - max).
 * It is up to the user's responsibility to validate the input value and display appropriate error
 * message.
 */
export const IncrementDecrementInput = styled(({
    name, min, max,
    value, setValue, setError=() => {},
    onChange,
    className,
    incrementContainerProps,
    incrementInputProps,
    ...rest
}) => {
    function increment() {
        if (value < max) {
            setValue(Number(value) + 1);
        }
    }

    function decrement() {
        if (value > min) {
            setValue(Number(value) - 1);
        }
    }

    function handleInputChange(event) {
        // onChange is provided in the Formik case
        if (onChange) {
            onChange(event);
        } else {
            setValue(event.target.value);
        }
    }

    // Initial sanity checks.
    useEffect(() => {
        if (min > max) {
            setError(`Min value ${min} is greater than max value ${max}.`);
            return;
        }

        if (typeof value !== 'number') {
            setError(`Default value has invalid type: ${typeof value}. It should be a number!`);
            return;
        }

        if (value < min || value > max) {
            setError(`Default value ${value} is out of valid range (${min} - ${max})`);
        }
    }, []);

    return (
        <>
            <Grid
                container
                wrap="nowrap"
                className={className}
                justifyContent="space-between"
                style={{
                    marginLeft: '9px',
                }}
                {...incrementContainerProps}
            >
                <SquareIconButton onClick={decrement}>
                    <RemoveRoundedIcon fontSize="small" />
                </SquareIconButton>
                <Grid
                    container
                    item
                    component={StyledInput}
                    disableUnderline
                    inputProps={{
                        name,
                        value,
                        onChange: handleInputChange,
                        type: 'number',
                        ...incrementInputProps,
                        ...rest,
                    }}
                />
                <SquareIconButton onClick={increment}>
                    <AddRoundedIcon fontSize="small" />
                </SquareIconButton>
            </Grid>
        </>
    );
})`
  /* Hide increment/decrement buttons for number input */
  /* Chrome, Safari, Edge, Opera */
  & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type=number] {
    -moz-appearance: textfield;
  }
`;

IncrementDecrementInput.defaultProps = {
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
};

/** *
 * Version of IncrementDecrementInput to be used in Formik forms
 * @param {{
 *     name: string, // name of the field
 *     min: number, // min value
 *     max: number, // max value
 *     className: string, // css class for the container component
 *     incrementContainerProps: object, // extra props for the container component
 *     incrementInputProps: object, // extra props for the input component
 *     ...rest: object // extra props for the input component
 * }} props
 */
export function FormikIncrementDecrementInput({
    name, min, max,
    className,
    incrementContainerProps,
    incrementInputProps,
    ...rest
}) {
    const [field, { value }, { setValue, setError }] = useField(name);

    if (rest.value !== undefined && rest.value !== value) {
        console.warn("FormikIncrementDecrementInput: 'value' prop provided, but will be overridden by Formik plumbing");
    }
    if (rest.setValue !== undefined) {
        console.warn("FormikIncrementDecrementInput: 'setValue' prop provided, but will be overridden by Formik plumbing");
    }
    if (rest.setError !== undefined) {
        console.warn("FormikIncrementDecrementInput: 'setError' prop provided, but will be overridden by Formik plumbing");
    }

    return (
        <IncrementDecrementInput
            name={name}
            className={className}
            incrementContainerProps={incrementContainerProps}
            incrementInputProps={incrementInputProps}
            min={min}
            max={max}
            {...field} // includes onChange and onBlur
            {...rest}
            value={value}
            setValue={setValue}
            setError={setError}
        />
    );
}
