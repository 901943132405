import { App } from '../../BMapsApp';
import { EnergyInfo } from '../../model/energyinfo';
import { UserActions } from '../../cmds/UserAction';
import {
    WaterStates, HBondStates, BindingSiteSurfaceStates,
} from '../../model/DisplayState';
import { imageSrc } from '../ui_utils';
import { HydrogenDisplayOptions } from '../../util/display_utils';

/**
 * Perform actions requested by the RightControlPanel
 * @param {string} actionId
 */
export function doRightControlPanelAction(actionId) {
    switch (actionId) {
        case 'views-ligand':
            UserActions.SetView('ligand');
            break;
        case 'views-protein':
            UserActions.SetView('protein');
            break;
        case 'views-publication':
            UserActions.SetView('publication');
            break;
        case 'views-reset':
            UserActions.ResetView();
            break;
        case WaterStates.none:
        case WaterStates.crystal:
        case WaterStates.computed:
        case WaterStates.all:
            UserActions.SetWaterView(actionId);
            break;
        case 'hbondson':
        case 'hbondsoff':
            UserActions.SetHBonds(actionId === 'hbondson' ? HBondStates.normal : HBondStates.none);
            break;
        case 'protsolvationon':
            //--- check if info available, do alert otherwise
            UserActions.SetBindingSiteSurface(BindingSiteSurfaceStates.ddgs);
            break;
        case 'hydrophobicon':
            UserActions.SetBindingSiteSurface(BindingSiteSurfaceStates.hydrophobicity);
            break;
        case 'protsolvationoff':
        case 'hydrophobicoff':
            UserActions.SetBindingSiteSurface(BindingSiteSurfaceStates.off);
            break;
        case 'ligandsolvationoff':
        case 'ligandsolvationon':
            UserActions.SetLigandSolvation(actionId === 'ligandsolvationon');
            break;
        case 'bfactoron': case 'bfactoroff':
            UserActions.ShowBfactor(actionId === 'bfactoron');
            break;
        case 'hotspotson':
        case 'hotspotsoff':
            UserActions.SetHotspots(actionId === 'hotspotson');
            break;
        case 'nohydrogens':
            UserActions.SetHydrogenView(HydrogenDisplayOptions.none);
            break;
        case 'polarhydrogens':
            UserActions.SetHydrogenView(HydrogenDisplayOptions.polar);
            break;
        case 'allhydrogens':
            UserActions.SetHydrogenView(HydrogenDisplayOptions.all);
            break;
        case 'dockcompound':
            UserActions.OpenDock();
            break;
        case 'watermap':
            UserActions.ShowFragmentPane('water');
            break;
        case 'fragmap':
            UserActions.ShowFragmentPane('fragment');
            break;
        case 'clustermap':
            UserActions.ShowFragmentPane('clustering');
            break;
        case 'newligand':
            UserActions.OpenSketcher();
            break;
        case 'editligand':
            UserActions.OpenSketcher(App.Workspace.getActiveCompound());
            break;
        case 'energyminimize':
            UserActions.ShowInspectorTab('energies');
            UserActions.EnergyMinimize(App.Workspace.getSelectedOrActiveCompounds());
            break;
        case 'calculateEnergies':
            UserActions.ShowInspectorTab('energies');
            UserActions.GetEnergies(App.Workspace.getSelectedOrActiveCompounds());
            break;
        case 'terminalReplacement':
            jAlert("Right-click a compound's terminal group or hydrogen in the 3D workspace to view terminal replacement options.\n\nHold down the R key to view all hydrogens and functional groups available for replacement.", 'Replace terminals');
            break;
        case 'fragmentGrow':
            jAlert('Right-click a compound atom in the 3D workspace and choose "Grow with fragment" to view nearby candidates for growing, ranked by their simulated binding affinity with the protein.\n\nThe cyan arrow indicates the direction of the grow. The compound portion pointed to by the arrow will be replaced by the fragment. Use Ctrl-right-click to reverse direction.  If there is more than one bond to grow along, add Alt or Shift to select a different direction.', 'Fragment growing');
            break;
        case 'SearchFragmentMaps':
            jAlert('To view fragments from a simulated fragment map, right-click any atom and choose "Search Nearby Fragments" or "Fragment Data Query." The resulting fragments can be brought into the workspace as starting points for new compounds.\n\nTo search the fragment map for compound modification options, right-click a compound atom and choose "Grow with Fragment."\n\nTo get a rough summary of a fragment map, click on a fragment in the left-hand Fragments selector.', 'Search Fragment Maps');
            break;
        case 'hidden':
        case 'wireframe':
        case 'sticks':
        case 'ballandstick':
        case 'spacefill':
        case 'cartoon':
        case 'surface':
            //--- difference between lines and thick lines as different styles?
            UserActions.SetDisplayStyle(actionId);
            break;
        default:
    }
}

/**
 * Return whether an action from the RightControlPanel is available.
 * SHOWS a descriptive alert if the action is not available.
 * @param {string} actionId
 * @returns {boolean}
 *
 * These conditions should perhaps be somewhere else:
 *   1) These are related to business logic / policy more so than the UI
 *   2) The UserAction infrastructure should be extended to be able to ask if
 *      an action is possible.
 */
export function validateRightControlPanelAction(actionId) {
    // Validate whether an operation or style is enabled (i.e. the data required is
    // available).  Returns a boolean (true if ok, false if not).
    const firstProtein = App.Workspace.getLoadedProtein();
    const protein = !!firstProtein;
    const noProtein = protein === false;
    const cmpd = App.Workspace.getActiveCompound();
    const selectedOrActive = App.Workspace.getSelectedOrActiveCompounds();
    const noCmpd = cmpd == null;
    const proteinCaseData = firstProtein ? App.Workspace.lookupCaseData(firstProtein) : null;
    const fragments = proteinCaseData ? proteinCaseData.areFragmentsAvailable() : false;
    const noFragments = !fragments;

    const failIfMinimizing = (op, title, cmpds=[cmpd]) => {
        const minimizing = [];
        cmpds.forEach((c) => { if (c.isMinimizing()) minimizing.push(c); });
        if (minimizing.length > 0) {
            jAlert(`Please wait for energy minimization to finish before attempting to ${op} ${minimizing.join(', ')}.`,
                title);
            return true;
        }
        return false;
    };

    switch (actionId) {
        case 'views-ligand':
            if (protein && noCmpd) {
                if (App.Workspace.getSelectedAtoms().length === 0) {
                    jAlert('Select some atoms to determine a center for Ligand View.', 'Ligand View');
                    return false;
                }
            }
            if (noCmpd && noProtein) {
                jAlert('No compound to view.', 'View');
                return false;
            }
            break;
        case 'views-protein':
        case 'views-publication':
            // Condition: A protein
            if (noProtein) {
                jAlert('No protein to view.', 'View');
                return false;
            }
            break;
        case 'export':
            // Condition: A protein OR a compound
            if (noProtein && noCmpd) {
                jAlert('Nothing available yet to export.', 'Export');
                return false;
            }
            break;
        case 'selection':
            // Condition: A protein OR a compound
            if (noProtein && noCmpd) {
                jAlert('Nothing available yet to select.', 'Atom Selection');
                return false;
            }
            break;
        case 'dockcompound':
            // Condition: An active compound (and user permissions)
            if (!App.AllPermissions.isAllowed('dock')) {
                return false;
            }
            if (noCmpd) {
                jAlert('No compound available for docking. Try importing a compound.', 'Docking');
                return false;
            }
            if (!cmpd.canDock) {
                jAlert('Docking peptide ligands is not supported.', 'Docking');
                return false;
            }
            if (noProtein) {
                jAlert('There is no solute to dock to. Try choosing a protein and re-importing your compound.', 'Docking');
                return false;
            }
            if (failIfMinimizing('dock', 'Docking')) {
                return false;
            }
            break;
        case 'watermap':
        case 'fragmap':
        case 'clustermap':
            if (noProtein) {
                jAlert('Select a protein to run simulations.', 'Fragment simulation');
                return false;
            }
            break;

        case 'editligand':
        case 'terminalReplacement':
        case 'fragmentGrow':
            // Condition: An active compound
            if (noCmpd) {
                jAlert('No compound to edit.', 'Edit Compound');
                return false;
            }
            break;
        case 'ligandsolvationoff':
            break;
            // Condition: An active compound WITH ddGs energy info
        case 'ligandsolvationon':
            if (cmpd) {
                if (!cmpd.energyTypeAvailable(EnergyInfo.Types.ddGs)) {
                    jAlert("Ligand solvation not available yet.\nPlease click 'Calculate Energies' in order to view ligand desolvation highlights.", 'Ligand Desolvation Highlights');
                    return false;
                }
            } else {
                jAlert('No ligand available yet.', 'Ligand Desolvation Highlights');
                return false;
            }
            break;
        case 'protsolvationoff':
            break;
        case 'protsolvationon':
            // Condition: A protein AND an active compound WITH ddgs Energy info
            if (noProtein) {
                jAlert('No protein available yet.', 'Protein Desolvation Surface Highlights');
                return false;
            }

            if (cmpd) {
                if (!cmpd.energyTypeAvailable(EnergyInfo.Types.ddGs)) {
                    jAlert("Compound energies not available.\nPlease click 'Calculate Energies' in order to view protein desolvation highlighting.", 'Protein Desolvation Surface Highlights');
                    return false;
                }
            } else {
                jAlert('No compound available yet for desolvation highlighting .', 'Protein Desolvation Surface Highlights');
                return false;
            }
            break;
        case 'hydrophobicon':
            // Condition: A protein
            if (noProtein) {
                jAlert('No protein available yet.', 'Protein Hydrophobicity Highlight');
                return false;
            }
            break;
        case 'energyminimize':
        case 'calculateEnergies':
            if (noCmpd) {
                jAlert('Energy minimization requires a compound. Please make sure you have loaded a compound.', 'Minimization');
                return false;
            }
            if (failIfMinimizing('minimize', 'Minimization', selectedOrActive)) {
                return false;
            }
            break;
        case 'SearchFragmentMaps':
            if (noProtein || noFragments) {
                jAlert('Please choose a protein with simulation data in order to view and search fragment maps.', 'Search Fragment Maps');
                return false;
            }
            break;
        case WaterStates.none:
        case WaterStates.crystal: // Condition: Crystal waters
        case WaterStates.computed: // Condition: BMaps waters
        case WaterStates.all: { // Condition: Crystal waters AND BMaps waters (should be revisited)
            const hasCrystalWaters = App.Workspace.getAllCrystalWaterAtoms().length > 0;
            const hasComputedWaters = App.Workspace.getAllComputedWaterAtoms().length > 0;
            if (actionId === WaterStates.crystal && !hasCrystalWaters) {
                jAlert('No crystal waters available.', 'Water Display');
                return false;
            } else if (actionId === WaterStates.computed && !hasComputedWaters) {
                jAlert('No computed BMaps waters available.', 'Water Display');
                return false;
            } else if (actionId === WaterStates.all) {
                if (!hasCrystalWaters && !hasComputedWaters) {
                    jAlert('No waters available.', 'Water Display');
                    return false;
                }
                // Here, at least one exists, just don't know which.
                if (!hasCrystalWaters) {
                    jAlert('No crystal waters available.', 'Water Display');
                } else if (!hasComputedWaters) {
                    jAlert('No computed BMaps waters available.', 'Water Display');
                }
            }
            break;
        }
        default:
            break;
    }

    return true;
}

export function imageSrcForStyle(styleWithPng) {
    return imageSrc(`${styleWithPng}.png`);
}
