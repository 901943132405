import { App } from './BMapsApp';
import { ServerConnection } from './server/ServerConnection';
import { StaticDataConnection } from './server/StaticDataConnection';
import { UserActions } from './cmds/UserAction';
import { EventBroker } from './eventbroker';
import { SessionManager } from './SessionManager';
import { LoadingScreen } from './LoadingScreen';
import { TheManual } from './ui/manual/TheManual';
import {
    Tour, MainTour, showTourOnStartup, RunTourWhenDialogsClose, getGreeting,
} from './ui/Tour';
import { ApplicationState } from './ApplicationState';
import DataConnection from './DataConnection';
import { PdbImportCase, AlphaFoldImportCase } from './model/MapCase';
import { setPreference } from './redux/prefs/access';
import { preferences, PREFS_TYPE } from './redux/prefs/constants';

let SelectorBannerTour;

export class BrowserApp extends App {
    constructor({ websocketStrategy }) {
        super(new ApplicationState(websocketStrategy));
    }

    static async Init(props) {
        const app = new BrowserApp(props); // App constructor will assign App.App
        await app.establishConnection(props);
    }

    async establishConnection(props) {
        const { staticMode, websocketStrategy } = props;

        let connection;
        if (staticMode) {
            connection = new StaticDataConnection(this.getWorkspace());
        } else {
            const { websocket, errorInfo } = await websocketStrategy.getWebsocket();
            if (errorInfo) {
                EventBroker.publish('sessionError', errorInfo);
                return;
            }
            connection = new ServerConnection(websocket);
        }
        const dataConnection = new DataConnection(connection);
        this.addDataConnection(dataConnection);

        setTimeout(() => BrowserApp.postConnection(dataConnection, props), 100);
    }

    static postConnection(dataConnection, props) {
        LoadingScreen.withLoadingScreen(BrowserApp.OnStartup(dataConnection, props), 'Loading data...');
    }

    static async OnStartup(dataConnection, props) {
        await UserActions.ZapConnection(dataConnection);
        await UserActions.FetchConnectionMaps(dataConnection);
        await dataConnection.getPermissionManager().refresh();
        await App.Workspace.refreshFragservInfo();

        if (await this.AutoRun()) return;
        // Attempt to reload the previous state
        if (await SessionManager.loadState()) return;

        // There are two dimensions of the start experience:
        // 1) What to show the user: either Manual, MapSelector, or a loaded structure
        // 2) Should the user see the tour.
        // When the tour is shown depends on #1:
        // 1a) When showing the Manual, show the tour when a map case is chosen
        // 1b) When showing the MapSelector, show the tour when the map selector closes
        // 1c) When loading a protein case, show the tour immediately
        const {
            nextStep, showTour, loadMap, query, maps,
        } = this.entranceExperience();

        const staticMode = props && props.staticMode;

        if (nextStep === 'manual') {
            TheManual.Show({ query, maps, staticMode });
            if (showTour) {
                RunTourWhenDialogsClose(MainTour, { dontGreet: true, staticMode });
            }
        } else if (nextStep === 'load') {
            await UserActions.ChooseProtein(loadMap);
            if (showTour) {
                MainTour.start({ staticMode });
            }
        } else { // nextStep === "selector"
            // TODO: add a "nothing" case for nextStep that shows blank screen instead of selector
            if (showTour) {
                // Use the tour machinery to show a welcome banner before opening the selector.
                SelectorBannerTour.start({ query, staticMode });
            } else {
                // Just open selector
                UserActions.OpenMapSelector('show', { searchSeed: query });
            }
        }
        this.applyAppOptions();
    }

    static async applyAppOptions() {
        const params = new URLSearchParams(window.location.search);

        const hotspots = params.get('hotspots');
        if (hotspots === 'true') {
            await UserActions.SetHotspots(true);
        }

        for (const pref of preferences) {
            const prefValue = params.get(pref.id);
            if (prefValue !== null) {
                let isValid = false;
                let valueToUse = prefValue;
                let intValue = null;
                const isValidEnum = (value, validValues) => validValues.includes(value);
                switch (pref.type) {
                    case PREFS_TYPE.enum:
                        isValid = isValidEnum(prefValue, pref.values);
                        break;
                    case PREFS_TYPE.int:
                        intValue = Number(prefValue);
                        isValid = !Number.isNaN(intValue) && Number.isInteger(intValue);
                        if (isValid) {
                            valueToUse = intValue;
                        }
                        break;
                    case PREFS_TYPE.boolean:
                        if (prefValue === 'true' || prefValue === 'false') {
                            valueToUse = (prefValue === 'true');
                            isValid = true;
                        }
                        break;
                    default:
                        break;
                }

                if (isValid) {
                    console.log(`Setting preference ${pref.id} to ${valueToUse}`);
                    setPreference(pref.id, valueToUse);
                } else {
                    console.warn(`Invalid value "${valueToUse}" for preference ${pref.id}`);
                }
            }
        }

        const focus = params.get('focus');
        if (focus) {
            const cmpd = App.Workspace.getCompoundBySpec(focus);
            UserActions.ActivateCompound(cmpd);
        }

        const view = params.get('view');
        if (view) {
            if (view !== 'reset') {
                UserActions.SetView(view);
            } else {
                // To call Reset View at application start is a useless action.
                // But might as well include it for completeness.
                UserActions.ResetView();
            }
        }
    }

    /**
     * @description Pull a query value from one of three query string params:
     * search, structure, preview.
     * - Structure will automatically load a single structure matching the query,
     * or the selector if multiple results
     * - Search will always show the selector
     * - Preview is like structure, but implies static mode
     */
    static handleQueryString() {
        const ret = {
            kind: '',
            query: null,
            mapResults: [],
            // For debugging
            nextStep: null,
            tour: null,
        };
        const params = new URLSearchParams(window.location.search);
        const regex = /^[\w-/]{0,128}$/; // matches apache.conf
        const structure = params.get('structure');
        const search = params.get('search');
        const preview = params.get('preview');
        ret.query = preview || structure || search;
        if (ret.query && !ret.query.match(regex)) {
            ret.query = null;
            return ret;
        }

        ret.kind = (preview && 'preview') || (structure && 'structure') || (search && 'search');

        if (ret.query) {
            ret.mapResults = App.PrimaryDataConnection
                .getCaseDataCollection()
                .findMapsByProjectPDBOrCase(ret.query);
        }

        // For debugging
        if (params.get('nextStep')) {
            const nextStep = params.get('nextStep');
            if (['manual', 'load', 'selector'].includes(nextStep)) ret.nextStep = nextStep;
        }

        if (params.get('tour')) {
            const tour = params.get('tour');
            if (tour === 'true' || tour === 'false') ret.tour = tour === 'true';
        }

        return ret;
    }

    /**
     * Logic for how to handle various query string components
     * @param {{kind, query, mapResults}} qsParams
     */
    static queryStringPolicies({ kind, query, mapResults }) {
        const ret = { skipManual: false, loadStructure: false };

        // Skip the manual for any valid query string search term
        // Originally, I thought 'search' should show the modified Welcome screen
        const skipManualKinds = ['preview', 'structure', 'search'];
        ret.skipManual = skipManualKinds.includes(kind) && query;

        // If we get only one match for the search, should we load the structure?
        // Load the one structure for 'preview' and 'structure' ;
        // 'search', on the other hand, will always show search results, even if only one result.
        const loadStructureKinds = ['preview', 'structure'];
        ret.loadStructure = loadStructureKinds.includes(kind) && mapResults.length === 1;

        return ret;
    }

    /**
     * @returns {
     *      showManual: true|false,  showTour: true|false, showSelector: true|false,
     *      queryStringParams: { query, maps: [], singleResult }}
     * }
     */
    static entranceExperience() {
        const manualAllowed = TheManual.ShowOnStartup();
        const tourAllowed = showTourOnStartup();
        const qsParams = this.handleQueryString();
        const { skipManual, loadStructure } = this.queryStringPolicies(qsParams);
        const {
            query, mapResults, nextStep: forceNextStep, tour: forceTour,
        } = qsParams;

        const ret = {
            query,
            nextStep: 'selector', // manual | selector | load
            showTour: tourAllowed,
            maps: mapResults,
            loadMap: null,
        };

        if (manualAllowed && !skipManual) {
            ret.nextStep = 'manual';
        } else if (loadStructure) {
            // loadStructure is only true if there's a single mapResult
            ret.nextStep = 'load';
            [ret.loadMap] = mapResults;
        } else {
            ret.nextStep = 'selector';
        }
        // TODO: add a "nothing" case for nextStep that shows blank screen instead of selector

        // For debugging
        if (forceNextStep) ret.nextStep = forceNextStep;
        if (forceTour != null) ret.showTour = forceTour;

        return ret;
    }

    static async AutoRun() {
        // await UserActions.ChooseProtein("factorIXa/4YZU_frags");
        // return true;
        return false;
    }
}

function getDefaultQueryOptions(query) {
    let text = `View all ${query} structures`;

    if (PdbImportCase.isValidId(query)) {
        text += ' (or import from the PDB)';
    }

    if (AlphaFoldImportCase.isValidId(query)) {
        text += ' (or import from AlphaFold)';
    }

    return [{ search: query, html: text }];
}

function getLaunchOptions(query) {
    const options = [];
    if (query) {
        switch (query.toUpperCase()) {
            case 'COVID-19':
            case 'SARS-COV-2':
                options.push(
                    { projectCase: 'nCov_Spike/6M0J', html: 'View SARS-CoV-2 Spike Protein' },
                    { projectCase: 'nCov_Protease/5R82', html: 'View SARS-CoV-2 Main Protease' },
                    { search: 'COVID-19', html: 'Browse all COVID-19 structures' },
                );
                break;
            default:
                options.push(...getDefaultQueryOptions(query));
        }
    } else {
        options.push(
            { projectCase: 'nCov_Spike/6M0J', html: 'View SARS-CoV-2 Spike Protein' },
            { projectCase: 'nCov_Protease/5R82', html: 'View SARS-CoV-2 Main Protease' },
            { projectCase: 'sirt2/4RMG', html: 'View Sirtuin2 structure' },
        );
    }
    options.push({ search: '', html: 'Browse 500+ BMaps structures' });
    return options;
}

SelectorBannerTour = new Tour([{
    elt_selector: null,
    getHtml: ({ query }) => {
        const options = getLaunchOptions(query);
        return `
            <div class="welcomeGreeting">${getGreeting()}, welcome to Boltzmann&nbsp;Maps!</div>
            <div class="welcomeText">How would you like to get started?</div>
            <div class="welcomeLauncherGroup">
            ${options
            .map((o) => (
                `<button class="welcomeLauncher"
                     data-projectcase="${o.projectCase || ''}"
                     data-search="${o.search || ''}">${o.html}</button>`))
            .join('')}
            </div>
        `;
    },
    top: '30%',
    buttons: {
        prev: '',
        cancel: 'Dismiss',
        next: '',
    },
    setup: (tour) => {
        $('.welcomeLauncher').click(async (evt) => {
            const staticMode = tour.tourArgs && tour.tourArgs.staticMode;
            tour.stop();
            const { projectcase: projCase } = evt.target.dataset;
            let { search } = evt.target.dataset;
            let mapToLoad = null;
            if (projCase) {
                const matchingMaps = App.PrimaryDataConnection
                    .getCaseDataCollection()
                    .findMapsByProjectPDBOrCase(projCase);
                if (matchingMaps && matchingMaps.length === 1) {
                    [mapToLoad] = matchingMaps;
                } else {
                    search = projCase;
                }
            }
            if (mapToLoad) {
                await LoadingScreen.withLoadingScreen(
                    UserActions.ChooseProtein(mapToLoad),
                    'Loading protein and fragment data...',
                );
                setTimeout(() => MainTour.start({ dontGreet: true, staticMode }), 0);
            } else {
                RunTourWhenDialogsClose(MainTour, { dontGreet: true, staticMode });
                UserActions.OpenMapSelector('show', { searchSeed: search });
            }
        });
    },
}]);
