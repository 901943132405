/**
 * ServerInfo is a React component to display information about a server connection,
 * primarily:
 *      Connection status:
 *          connected / disconnected
 *          server / slot id
 *      Working item
 *          Which command is running
 *
 * The indicator is colored:
 *      grey   - not connected
 *      yellow - waiting for a command response from the server
 *      green  - connected and idle
 */
import React from 'react';
import _ from 'lodash';
import { EventBroker } from '../eventbroker';
import { App } from '../BMapsApp';
import { updateTooltipster } from './ui_utils';
import { loginUrlForMapCase } from '../connection_utils';
import { simpleCond } from '../util/js_utils';

export default class ServerInfo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.updateCommandStatus = this.updateCommandStatus.bind(this);
        this.updateConnectionStatus = this.updateConnectionStatus.bind(this);
    }

    componentDidMount() {
        EventBroker.subscribe('serverQueueUpdate', this.updateCommandStatus);
        EventBroker.subscribe('connectionStatusChanged', this.updateConnectionStatus);
        this.updateTooltips();
    }

    componentDidUpdate() {
        this.updateTooltips();
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('serverQueueUpdate', this.updateCommandStatus);
        EventBroker.unsubscribe('connectionStatusChanged', this.updateConnectionStatus);
    }

    /**
     * @todo Ideally, this should return a list of info for all connections.
     */
    getConnectionInfo() {
        if (!App.Ready) return null;
        const conns = App.DataConnections.map((dc) => dc.getConnector());
        const { server, static: staticConns } = _.groupBy(conns, (conn) => conn.getMode());
        if (server?.length > 0) return this.getOneConnectionInfo(server[0]);
        if (staticConns?.length > 0) return this.getOneConnectionInfo(staticConns[0]);
        return null;
    }

    getOneConnectionInfo(connector) {
        const connectionInfo = connector.connectionInfo;
        const connected = connector.isConnected();
        const mode = connector.getMode();
        const agent = connectionInfo.agent;
        const port = connectionInfo.port;
        const serverId = agent && port ? `${agent}.${port}` : 'server';
        return {
            connected,
            server: connected ? serverId : 'disconnected',
            mode,
            currentCmd: connector.workingCommand,
        };
    }

    updateConnectionStatus() {
        if (!App.Ready) return;
        this.forceUpdate();
    }

    updateCommandStatus() {
        if (!App.Ready) return;
        // Delay updaing the UI, so that simple clicks don't flash the indicator
        setTimeout(() => {
            this.forceUpdate();
        }, 300);
    }

    updateTooltips() {
        updateTooltipster({
            '.server-info': { side: 'bottom' },
        });
    }

    previewMode() {
        const myStructure = App.Workspace && App.Workspace.getLoadedProtein();

        return (
            <div className="server-info preview-mode-notice" title="Molecule import, docking, and new simulations are not available in Preview.">
                <div className="preview-mode-title">BMaps Preview</div>
                <div className="preview-mode-desc">
                    <a href={loginUrlForMapCase(myStructure)}>Log in</a>
                    {' '}
                    for free access
                    {' '}
                    <br />
                    to the full set of features.
                </div>
            </div>
        );
    }

    content({ currentCmd, connected, server }) {
        const serverText = connected ? `Connected to ${server}` : 'Disconnected';
        const cmdName = currentCmd ? currentCmd.name : 'Idle';
        const workingText = connected ? cmdName : 'Disconnected';
        return `
            <div>
                <div>Server: ${serverText}</div>
                <div>Working on: ${workingText}</div>
              </div>
        `;
    }

    render() {
        const connectionInfo = this.getConnectionInfo();
        if (!connectionInfo) return false;

        const { mode, currentCmd, connected } = connectionInfo;
        if (mode === 'static') return this.previewMode();
        const workingColor = 'orange';
        const connectedColor = '#89bd45'; // same as 'marketing-green' from css
        const disconnectedColor = 'lightgrey';
        const cmdInProgress = currentCmd;
        const className0 = `fa fa-cog${(cmdInProgress ? ' fa-spin' : '')}`;
        const className1 = `fa fa-cog${(cmdInProgress ? ' spin-reverse' : '')}`;

        const color = simpleCond([
            [connected && cmdInProgress, workingColor],
            [connected, connectedColor], // connected but not working on a command
            [true, disconnectedColor],
        ]);
        const styleCog0 = {
            color, fontSize: '22px', position: 'relative', top: '5px',
        };
        const styleCog1 = {
            color, fontSize: '18px', position: 'relative', top: '-5px',
        };
        const styleCog2 = {
            color, fontSize: '14px', position: 'relative', top: '12px', left: '-16px',
        };
        const content = this.content(connectionInfo);
        return (
            <div className="server-info" data-tooltip-content={content}>
                <i className={className0} style={styleCog0} />
                <i className={className1} style={styleCog1} />
                <i className={className1} style={styleCog2} />
            </div>
        );
    }
}
