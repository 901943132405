/* Implementation of BMaps 3D visualization with 3Dmol.js
 * Original 3Dmol implementation: 3dmol_interface.js
 * Note that this depends on custom changes made to 3dmol code, especially the addition of
 * the internal atom property.
 */

import { VisualizerInterface } from './VisualizerInterface';
import { GetChainColor, SelectionColor } from './themes';
import { MolStyles } from './style_manager';
import { getFullResidueId } from './util/mol_info_utils';

let $3Dmol;

// Protect require of 3Dmol.js during Next.js SSR / SSG
if (typeof window !== 'undefined') {
    // Import 3Dmol-bmaps from local file
    // eslint-disable-next-line global-require
    // $3Dmol = require('../lib/js/3Dmol-bmaps');

    // Import 3Dmol-bmaps from npm package
    // eslint-disable-next-line global-require
    $3Dmol = require('3dmol-bmaps/build/3Dmol-min');
    $3Dmol.setSyncSurface(true); // See BP2-131. 3Dmol had fixed this, but similar error in 2023.
}

export class Visualizer3Dmol extends VisualizerInterface {
    constructor(options) {
        super(options);
        this.state = {
            colorByChainId: true, // setting?
            proteinChains: [],
            oldHovered: null,
            sphereCount: 0, // 3dmol workaround
            currentModel: null,
        };
        this.MouseCallback = this.MouseCallback.bind(this);
        this.HoverCallback = this.HoverCallback.bind(this);
        this.UnHoverCallback = this.UnHoverCallback.bind(this);
        this.Reset = this.Reset.bind(this);
    }

    // VIRTUAL METHOD IMPLEMENTATION

    // Setup
    InitViewer(options) {
        const { parentEltId, backgroundColor, cartoonQuality } = options;
        const viewerOptions = { defaultcolors: $3Dmol.elementColors.rasmol };
        if (cartoonQuality != null) {
            viewerOptions.cartoonQuality = cartoonQuality;
        }

        this.viewer = $3Dmol.createViewer(parentEltId, viewerOptions);
        this.viewer.userContextMenuHandler = (target, x, y, evt) => {
            this.MouseCallback(target, this.viewer, evt);
        };
        console.log('Created 3d viewer');
        if (backgroundColor) {
            // Note: setting the background color here will be overridden
            // by the call to setColorScheme() in main() in main.js.
            this.SetBackgroundColor(backgroundColor);
        }
    }

    Reset() {
        this.state.proteinChains = [];
        this.state.oldHovered = null;
        this.state.sphereCount = 0;

        if (this.state.currentModel) {
            console.log('3d visualizer: removing the current model.');
            // Removes bonds too.
            this.viewer.removeModel(this.state.currentModel); // ok if not yet defined
            this.state.currentModel = undefined;
        }
        super.Reset();
        this.Redisplay();
    }

    // Display
    ResizeViewer(width, height) {
        // const target = options.target || this;
        // 3dmol remembers its own w, h
        // if (target)
        this.viewer.resize();
    }

    SetBackgroundColor(colorInfoIn) {
        let colorInfo = colorInfoIn;
        if (typeof (colorInfo) === 'object' && colorInfo.hex != null) {
            colorInfo = colorInfo.hex;
        }
        this.viewer.setBackgroundColor(colorInfo);
    }

    RedisplayViewer() {
        this.viewer.resetModel(this.state.currentModel);
        this.viewer.render();
    }

    ZoomToAtoms(selectorFn) {
        if (selectorFn) {
            this.viewer.zoomTo({ predicate: this.wrapAtomFn(selectorFn) });
        } else {
            this.viewer.zoomTo();
        }
    }

    // Dealing with atom and molecule data

    AppAtom(visAtom) {
        return visAtom.appAtom;
    }

    // Create a 3dmol atom object from a bmaps atom object
    MakeAtom(atom) {
        const [x, y, z] = atom.getPosition();
        const visAtom = {
            x,
            y,
            z,
            appAtom: atom,
            resn: atom.resn,
            resname: atom.resname,
            elem: atom.elem,
            hetflag: atom.hetflag,
            resi: atom.resi,
            icode: atom.icode,
            chain: atom.chain,
            modelNum: atom.modelNum,
            rescode: atom.rescode,
            serial: atom.serial,
            atom: atom.atom,
            amber: atom.amber,
            index: atom.index,
            bonds: atom.bondedAtoms.map((a2) => a2.index),

            bondOrder: atom.bondOrder,
            properties: atom.properties,
            b: atom.b,
            pdbline: atom.pdbline,
            style: atom.initialStyle
                ? this.makeStyleSpec(atom.initialStyle)
                : $3Dmol.GLModel.defaultAtomStyle,

            ss: 'c',
            intersectionShape: {
                sphere: [], cylinder: [], line: [], triangle: [],
            },
            color: $3Dmol.elementColors.defaultColors[atom.elem]
                || $3Dmol.elementColors.defaultColor,
            clickable: true,
            callback: this.MouseCallback,
            hoverable: true,
            hover_callback: this.HoverCallback,
            unhover_callback: this.UnHoverCallback,
            contextMenuEnabled: true,
        };

        if (!atom.hetflag) {
            this.updateProteinAtom(atom, visAtom);
        }

        return visAtom;
    }

    // MakeBond() - 3Dmol implementation doesn't need it

    // Load a list of bmaps atoms into 3Dmol
    AddAtoms(atoms, bonds) {
        if (!this.state.currentModel) {
            console.log('Adding a new model.');
            this.state.currentModel = this.viewer.addModel();
        }

        const newAtoms = atoms.map((a) => this.MakeAtom(a));
        this.state.currentModel.addAtoms(newAtoms);
        this.refreshMaps();
        this.updateAtomBonds(atoms);
        return newAtoms;
    }

    /**
     * After adding atoms to 3Dmol, ensure that bond definitions are complete and correct:
     *     `bond` is an array of indices to other 3Dmol atoms.
     *     `bondOrder` also needs to be updated.
     * 3Dmol updates indices when adding atoms, and automatically adjusts bond lists to use the new
     * indices. But since we add atoms one atomgroup at a time, interresidue bonds are tricky.
     * This function will ensures that the correct bond indices in all 3Dmol atom bond lists.
     *
     * Note: this requires up-to-date mappings from bmaps atoms to 3Dmol atoms (via refreshMaps).
     * @param {[Atom]} bmapsAtoms
     */
    updateAtomBonds(bmapsAtoms) {
        const bmapsBonds = new Set();
        bmapsAtoms.forEach((at) => at.getBonds().forEach((b) => bmapsBonds.add(b)));

        for (const bmapsBond of bmapsBonds) {
            const [bmapsAtomA, bmapsAtomB] = bmapsBond.getAtoms();
            const canvasAtomA = this.VisAtom(bmapsAtomA);
            const canvasAtomB = this.VisAtom(bmapsAtomB);
            if (canvasAtomA && canvasAtomB) {
                // We can only update canvas atoms if they are both present, since we need the
                // bond list of one and index of the other. If one canvas atom is missing, we
                // won't display the bond now. It will get shown if the atom appears later.
                if (!canvasAtomA.bonds.includes(canvasAtomB.index)) {
                    canvasAtomA.bonds.push(canvasAtomB.index);
                    canvasAtomA.bondOrder.push(bmapsBond.order);
                }

                if (!canvasAtomB.bonds.includes(canvasAtomA.index)) {
                    canvasAtomB.bonds.push(canvasAtomA.index);
                    canvasAtomB.bondOrder.push(bmapsBond.order);
                }
            }
        }
    }

    updateProteinAtom(appAtom, visAtom) {
        // Keep track of chains. This is for coloring in order. I think.
        const chain = appAtom.chain;
        if (chain && !this.state.proteinChains.includes(chain)) {
            this.state.proteinChains.push(chain);
        }

        // Apply helix / sheet info
        const proteinResidue = VisualizerInterface.getAtomProteinResidue(appAtom);
        const structureInfo = proteinResidue?.getSecondaryStructureInfo();
        if (structureInfo) {
            this.applyStructureFeatures(visAtom, structureInfo);
        }
    }

    applyStructureFeatures(atom, structureInfo) {
        if (!structureInfo) return;

        const { type, isStart, isEnd } = structureInfo;
        const myType = { helix: 'h', sheet: 's' }[type];
        atom.ss = myType;
        if (isStart) atom.ssbegin = isStart;
        if (isEnd) atom.ssend = isEnd;
    }

    // Make a short-lived molecule with no interactivity
    MakeTempMolecule(atoms, style, colorMap={}, opacity, atomPair) {
        const tempModel = this.viewer.addModel();

        const newAtoms = atoms.map((a) => {
            const newAtom = this.MakeAtom(a);
            const styleSpec = style ? this.makeStyleSpec(style) : newAtom.style;
            for (const spec of Object.values(styleSpec)) {
                spec.opacity = opacity;
            }
            return {
                ...newAtom,
                style: styleSpec,
                color: colorMap[newAtom.elem] || newAtom.color,
                clickable: false,
                callback: undefined,
                hoverable: true,
                hover_callback: this.HoverCallback,
                unhover_callback: this.UnHoverCallback,
            };
        });

        tempModel.addAtoms(newAtoms);
        if (atomPair && atomPair[0] && atomPair[1]) {
            const atom1Pos = atomPair[0].getPosition({ as: 'object' });
            const atom2Pos = atomPair[1].getPosition({ as: 'object' });
            tempModel.dashedCyl = this.drawCylinder(atom1Pos, atom2Pos, 0.25, 'cyan', 1);
        }
        return tempModel;
    }

    UpdateTempMolecule(tmp, { style, opacity, color }) {
        if (color) console.warn('Visualizer3Dmol UpdateTempMolecule does not yet handle colors');

        const styleSpec = style ? this.makeStyleSpec(style) : {};
        for (const spec of Object.values(styleSpec)) {
            spec.opacity = opacity;
        }
        tmp.setStyle(styleSpec);
    }

    RemoveTempMolecule(tempModel) {
        if (tempModel.dashedCyl) this.viewer.removeShape(tempModel.dashedCyl);
        this.viewer.removeModel(tempModel);
    }

    RemoveAtoms(bmapsAtomsIn) {
        const bmapsAtoms = [].concat(bmapsAtomsIn);
        const visAtoms = [];
        const bmapsToRemove = [];
        for (const batom of bmapsAtoms) {
            const vatom = this.VisAtom(batom);
            if (vatom) {
                visAtoms.push(vatom);
                bmapsToRemove.push(batom);
            }
        }

        if (visAtoms.length > 0) {
            this.state.currentModel.removeAtoms(visAtoms);
            this.UnmapAtoms(bmapsToRemove);
            this.refreshMaps();
            console.log(`Removed ${visAtoms.length} and unmapped ${bmapsToRemove.length} atoms`);
        }
    }

    /**
     * @description The 3Dmol.js GLModel.removeAtoms function will
     * create new atom objects, so after a remove, we need to remap them.
     */
    refreshMaps() {
        const viewerAtoms = this.state.currentModel.selectedAtoms();
        for (const va of viewerAtoms) {
            const bmapsAtom = this.AppAtom(va);
            if (bmapsAtom) {
                this.MapAtoms(bmapsAtom, va);
            } else {
                console.warn(`Missing BMaps atom for 3dmol atom: Atom ${va.index}: ${va.resname}.${va.resi}:${va.chain} ${va.atom}`);
            }
        }
    }

    // Style

    // Style one atom
    StyleAtom(atom, style) {
        // Modify the atom style directly, instead of calling setStyle.
        const styleSpec = this.makeStyleSpec(style);

        if ((style === 'cartoon' || style === 'tribbons') && atom.chain) {
            this.colorCartoonByChain(atom.chain, styleSpec);
        }

        const myAtom = this.VisAtom(atom);
        if (myAtom) {
            myAtom.style = styleSpec;
        } else if (style !== MolStyles.hidden) {
            // Don't complain if trying to 'hide' a non-existent atom.
            console.error(`Visualizer3Dmol Failed to find vis atom for ${atom.resname}.${atom.resi}:${atom.chain} ${atom.atom}`);
        }
    }

    StyleAtoms(style, selectorFn) {
        const selectSpec = selectorFn ? { predicate: this.wrapAtomFn(selectorFn) } : {};
        const selectDesc = selectorFn || 'all';

        console.log(`Setting the molecule display style for ${selectDesc} atoms to '${style}'.`);
        //--- crosses? lines vs. wireframe, etc.
        //--- need to remove surface, etc.

        switch (style) {
            case 'hidden':
            case 'wireframe':
            case 'sticks':
            case 'ballandstick':
            case 'spacefill':
            case 'ribbons':
            case 'backbone':
            case 'tribbons':
            case '2dview':
                this.viewer.setStyle(selectSpec, this.makeStyleSpec(style));
                break;
            case 'cartoon': {
                const cartoon = this.makeStyleSpec(style);
                for (const chain of this.state.proteinChains) {
                    selectSpec.chain = chain;
                    this.viewer.setStyle(selectSpec, this.colorCartoonByChain(chain, cartoon));
                }
                break;
            }
            default:
                console.warn(`StyleAtoms: unknown molecule style: ${style}`);
        }
        this.Redisplay();
    }

    setAtomSelected(atom, selected) {
        this.setAtomColor(atom, selected ? SelectionColor : 'default');
        // Old 2d Viewer
        // setAtomColor2d(atom, selected ? SelectionColor : 'default');
    }

    setAtomColor(atom, colorIn) {
        let color = colorIn;
        const myAtom = this.VisAtom(atom);
        if (!myAtom) {
            // Don't complain the requested atom isn't recognized. When receiving selection data
            // BMaps will probably request to recolor all atoms, whether or not they are currently
            // loaded in the 3D canvas.
            return;
        }

        // Note: per 3Dmol.js API, we track cartoon colors separately from atoms colors.
        if (myAtom.style.cartoon && myAtom.style.cartoon.color) {
            const defaultColor = this.getCartoonColorForChain(myAtom.chain);
            if (color === 'default' && myAtom.style.cartoon.color !== defaultColor) {
                myAtom.style = this.colorCartoon(defaultColor, myAtom.style);
            } else if (color !== 'default') {
                myAtom.style = this.colorCartoon(color, myAtom.style);
            }
        }

        if (color === 'default') {
            if (atom.defaultColorMap && atom.defaultColorMap[atom.elem]) {
                color = atom.defaultColorMap[atom.elem];
            } else {
                color = $3Dmol.elementColors.rasmol[atom.elem] || $3Dmol.elementColors.defaultColor;
            }
        }
        myAtom.color = color;
        myAtom.surfaceColor = color;
    }

    atomIsVisible(atom) {
        const myAtom = this.VisAtom(atom);
        return myAtom && myAtom.style && Object.keys(myAtom.style).length > 0;
    }

    // Objects etc

    // Draw a surface
    //     displayAtomsFn - selector function for the atoms whose surface to display
    //     computingAtomsFn - selector function for the atoms to use in computing the surfaces
    //     colorFn - function taking an atom and returning the desired surface color
    //     opacity - percentage of opacity (0-1)
    // Returns: a surfaceId, to be passed to removeSurface()
    DrawSurface({
        atoms, atomGroup, displayAtomsFn, computingAtomsFn, colorFn, opacity=0.8,
    }) {
        let displaySel;
        if (displayAtomsFn) {
            displaySel = { predicate: this.wrapAtomFn(displayAtomsFn) };
        } else if (atoms) {
            // Convert atoms to MyAtoms (visualizer's copy) upfront and only keep that array around
            const myAtoms = atoms.map(this.VisAtom);
            displaySel = { predicate: (a) => myAtoms.includes(a) };
        } else if (atomGroup) {
            displaySel = { predicate: (a) => atomGroup.hasAtom(this.AppAtom(a)) };
        } else {
            throw new Error('DrawSurface: a surface needs to have either atoms, an atom group, or a display selector function defined.');
        }
        const computingSel = computingAtomsFn ? { predicate: this.wrapAtomFn(computingAtomsFn) }
            : displaySel;

        const style = { opacity };
        if (colorFn) {
            style.colorfunc = this.wrapAtomFn(colorFn);
        }
        const surfaceType = $3Dmol.SurfaceType.VDW;
        const surfPromise = this.viewer.addSurface(surfaceType, style, displaySel, computingSel);
        return surfPromise.surfid;
    }

    // Remove a surface from the view
    // surfaceId - the surface id returned by drawSurface() when the surface was created
    RemoveSurface(surfaceId) {
        this.viewer.removeSurface(surfaceId);
    }

    RemoveShape(obj) {
        this.viewer.removeShape(obj);
    }

    // Other objects

    drawLine(start, end, color) {
        const line = this.viewer.addLine({ start, end, color });
        return line;
    }

    removeLine(line) {
        this.viewer.removeShape(line);
    }

    drawSphere(center, radius, color, opacity=1.0) {
        const spec = {
            center,
            radius,
            color,
            hoverable: true,
            hover_callback: this.HoverCallback,
            unhover_callback: this.UnHoverCallback,
        };
        const sphere = this.viewer.addSphere(spec);
        sphere.opacity = opacity;
        this.sphereCrashWorkaround_Add();
        return sphere;
    }

    removeSphere(sphere) {
        this.sphereCrashWorkaround_Remove();
        this.viewer.removeShape(sphere);
    }

    // start/end: {x: x, y: y, z: z}
    drawCylinder(start, end, radius, color, opacity=1.0, dashed=false) {
        const cylinderSpec = {
            start,
            end,
            radius,
            color,
            hoverable: true,
            dashed,
            hover_callback: this.HoverCallback,
            unhover_callback: this.UnHoverCallback,
        };

        const cyl = this.viewer.addCylinder(cylinderSpec);
        cyl.opacity = opacity;
        return cyl;
    }

    removeCylinder(cylinderShapeObj) {
        this.viewer.removeShape(cylinderShapeObj);
    }

    drawCone(start, end, radius, color, opacity=1.0) {
        const coneSpec = {
            start, end, radius, color,
        };
        const cone = this.viewer.addCone(coneSpec);
        cone.opacity = opacity;
        return cone;
    }

    removeCone(coneShapeObj) {
        this.viewer.removeShape(coneShapeObj);
    }

    prepShape(shapeSpec, { description, onClick }) {
        if (description) {
            shapeSpec.hoverable = true;
            shapeSpec.hover_callback = this.HoverCallback;
            shapeSpec.unhover_callback = this.UnHoverCallback;
        }

        if (onClick) {
            shapeSpec.clickable = true;
            shapeSpec.callback = this.MouseCallback;
            shapeSpec.contextMenuEnabled = true;
        }
    }

    finishShape(shape, {
        opacity=1.0, description, onClick, object,
    }={}) {
        // Opacity: undefined => use default; null => don't assign; else assign
        if (opacity != null) {
            shape.opacity = opacity;
        }
        if (description) shape.description = description;
        if (onClick) shape.onClick = onClick;
        if (object) shape.object = object;
    }

    drawArrow(start, end, radius, color, radiusRatio=1.0, shapeOptions={}) {
        const arrowSpec = {
            start, end, radius, radiusRatio, color,
        };
        this.prepShape(arrowSpec, shapeOptions);
        const arrow = this.viewer.addArrow(arrowSpec);
        this.finishShape(arrow, shapeOptions);
        return arrow;
    }

    removeArrow(arrowShapeObj) {
        this.viewer.removeShape(arrowShapeObj);
    }

    drawLabel({
        text, options, centerFn, centerAtomGroup,
    }) {
        let centerFnArg;
        if (centerFn) {
            centerFnArg = { predicate: this.wrapAtomFn(centerFn) };
        } else if (centerAtomGroup) {
            centerFnArg = { predicate: (at) => centerAtomGroup.hasAtom(this.AppAtom(at)) };
        } else {
            throw new Error('drawLabel: label needs either centerFn or centerAtomGroup to specify where it should be drawn');
        }

        return this.viewer.addLabel(text, options, centerFnArg);
    }

    removeLabel(label) {
        this.viewer.removeLabel(label);
    }

    // Mouse callbacks

    MouseCallback(target, _viewer, event, container) {
        let obj;
        if (target) {
            if (target.atom) {
                obj = { atom: target };
            } else {
                obj = { shape: target };
            }
        } else {
            obj = { background: true };
        }
        try {
            this.publishMouseEvent(obj, event);
        } catch (ex) {
            console.error(`Exception in mouse event handler ${event.type}`, ex);
        }
    }

    // Note: These are jquery mouse events coming from 3dmol
    HoverCallback(target, _viewer, event, container) {
        const alreadyHovered = (target === this.state.oldHovered);
        // Hover events come from 3Dmol as 'mousemove' so need to convert to mouseenter
        const eventToSend = alreadyHovered ? event : new MouseEvent('mouseenter', event);
        this.state.oldHovered = target;
        this.MouseCallback(target, _viewer, eventToSend, container);
    }

    // Note: These are jquery mouse events coming from 3dmol
    UnHoverCallback(target, _viewer, event, container) {
        this.state.oldHovered = null;
        // Hover events come from 3Dmol as 'mousemove' so need to convert to mouseleave
        const eventToSend = new MouseEvent('mouseleave', event);
        this.MouseCallback(target, _viewer, eventToSend, container);
    }

    // Style helpers

    makeStyleSpec(style) {
        const singleBondRadius2D = 0.2;
        const doubleBondRadius2D = 0.1;

        switch (style) {
            case 'hidden': return {};
            case 'surface': return {};
            // case 'wireframe':    return {line:{linewidth: 5.0}};
            case 'wireframe': return { stick: { radius: 0.05 } };
            case 'sticks': return { stick: {} };
            case 'invisible': return { clicksphere: {} };
            case 'ballandstick': return { sphere: { scale: 0.3 }, stick: {} };
            case 'ghost': return { stick: { opacity: 0.8 } };
            case 'spacefill': return { sphere: {} };
            case 'ribbons': return { cartoon: {} };
            case 'backbone': return { cartoon: { trace: true } };
            case 'cartoon': return { cartoon: { style: 'rectangle', arrows: true, color: 'spectrum' } };
            case '2dview': return { stick: { radius: singleBondRadius2D } };
            case 'tribbons': return { cartoon: { style: 'rectangle', opacity: 0.8, color: 'spectrum' } };
            default:
                console.warn(`Unknown molecule style: ${style}`);
                return { stick: {} };
        }
    }

    // key can be a keyword or a color
    getCartoonColor(key) {
        switch (key) {
            case 'selected': return SelectionColor;
            case 'spectrum': return 'spectrum';
            case 'default':
                console.error("getCartoonColor: default isn't a valid color key. Defaulting to spectrum instead of default chain color.");
                return 'spectrum';
            default:
                // Is some representation of a color
                return key;
        }
    }

    /**
     * Get the color value for the provided chain id
     */
    getCartoonColorForChain(chain) {
        if (this.state.colorByChainId && this.state.proteinChains.includes(chain)) {
            // key is a protein chain we've identified in annotateStructureFeatures()
            return GetChainColor(chain, this.state.proteinChains);
        } else { // chainID for a non-protein element
            return 'spectrum';
        }
    }

    /**
     * Apply a color to an existing cartoon spec or create a new one
     * @param {*} key - A color or color keyword (eg selected, spectrum)
     * @param {*} cartoon - A style spec object for a catroon
     * @returns The modified cartoon style spec object
     */
    colorCartoon(key, cartoon=this.makeStyleSpec('cartoon')) {
        cartoon.cartoon.color = this.getCartoonColor(key);
        return cartoon;
    }

    /**
     * Apply the default color for a chain id to an existing cartoon spec or create a new one.
     * @param {string} chain - A chain id to use to look up a color
     * @param {StyleSpec} cartoon - A style spec object for a cartoon
     * @returns {StyleSpec} The modified cartoon style spec object
     */
    colorCartoonByChain(chain, cartoon) {
        const color = this.getCartoonColorForChain(chain);
        return this.colorCartoon(color, cartoon);
    }

    /* Workaround for 3Dmol issue: https://github.com/3dmol/3Dmol.js/issues/367
    3Dmol will spike memory if we have 1000+ unrendered spheres, crashing a Chrome tab at 1021.
    So we need to add and render water halos in chunks.
    We'll just rerender every 600 spheres.
    This function is called after adding a sphere, so just rerender if
    the current number of spheres is a multiple of 600.
    */
    sphereCrashWorkaround_Add(chunkSize=600) {
        this.state.sphereCount++;

        if (this.state.sphereCount % chunkSize === 0) {
            this.viewer.render();
        }
    }

    sphereCrashWorkaround_Remove() {
        this.state.sphereCount--;
    }
}
