import React, { useEffect, useState } from 'react';
import { Compound } from '../model/compound';
import { SelectionColorCss } from '../themes';
import { UserActions } from '../cmds/UserAction';
import { useWithSelectedAtoms } from './common/helpers/useWithSelectedAtoms';
import { cssNameToReactStyleName } from './ui_utils';

/**
 * WIP pass at interactive SVG component for a compound (extremely halfbaked).
 * This displays indigo and rdkit next to each other, with RDKit transformed to React svg nodes.
 * The atom ids from rdkit are used to enable minimal interactivity.
 *
 * To use this, add 2dviewer=InteractiveSvgViewer to the query string.
 */
export function InteractiveSvgViewer({ paneId, compound }) {
    const [indigoSvg, setInigoSvg] = useState('failed');
    const [rdkitSvg, setRdkitSvg] = useState('failed');
    useEffect(() => {
        async function populatesSvgs() {
            if (compound) {
                let svg;
                svg = await Compound.getCmpdSvgRDKit(compound, 'mol', { renderIndices: true });
                svg = svg?.replace(/width="[\d]+"/, 'width="100%"')?.replace(/height="[\d]+"/, 'height="100%"') || 'failed';
                setRdkitSvg(svg);
                svg = await Compound.getCmpdSvgIndigo(compound, 'mol', { renderIndices: true });
                svg = svg?.replace(/width="[\d]+"/, 'width="100%"')?.replace(/height="[\d]+"/, 'height="100%"') || 'failed';
                setInigoSvg(svg);
            }
        }
        populatesSvgs();
    }, [compound]);
    return (
        <div id={paneId} className="SvgViewer">
            <div
                dangerouslySetInnerHTML={{ __html: indigoSvg }}
                draggable={false}
                alt={compound.resSpec}
            />
            <RdkitSvgRenderer svg={rdkitSvg} compound={compound} />
        </div>
    );
}

const SvgComponentMap = {
    path: (attributes) => <path {...attributes} />,
};

function copyNode(node, compound, selectedAtoms) {
    const nodeName = node.nodeName;
    // console.log(`Copying ${node.nodeType} ${nodeName}`);
    const children = node.childNodes
        ? Array.from(node.childNodes)
            .filter((n) => n.nodeType === Node.ELEMENT_NODE)
            .map((childNode) => copyNode(childNode, compound, selectedAtoms))
        : [];
    const props = {};
    if (node.attributes) {
        let nodeId = '';
        for (const { name, value } of node.attributes) {
            // this converts style to the correct format and object
            switch (name) {
                case 'class':
                    props.className = value;
                    nodeId = value;
                    break;
                case 'style':
                    props[name] = value.split(';').reduce((styleObj, style) => {
                        const [key, val] = style.split(':');
                        if (key && val) {
                            styleObj[cssNameToReactStyleName(key.trim())] = val.trim();
                        }
                        return styleObj;
                    }, {});
                    break;
                default:
                    props[name] = value;
            }
        }
        const rdkitClassRe = /^(atom-(?<atomid>\d+))|((bond-(?<bondid>\d+) atom-(?<atom1id>\d+) atom-(?<atom2id>\d+)))$/;
        const match = nodeId.match(rdkitClassRe);
        if (match) {
            if (match.groups.atomid) {
                nodeId = `Atom ${Number(match.groups.atomid)+1}`;
            }
            if (match.groups.bondid) {
                nodeId = `Bond ${Number(match.groups.bondid)+1}: ${Number(match.groups.atom1id)+1} - ${Number(match.groups.atom2id)+1}`;
            }
            props.onMouseEnter = (event) => {
            };
            props.onMouseLeave = (event) => {
            };
            props.onClick = (event) => {
                const atomIndex = Number(match.groups.atomid);
                const atom = compound.getAtoms()[atomIndex];
                UserActions.SelectAtom(atom);
            };

            const atomIndex = Number(match.groups.atomid);
            const atom = compound.getAtoms()[atomIndex];
            if (selectedAtoms && selectedAtoms.includes(atom)) {
                props.fill = SelectionColorCss;
            }
        }
    }
    const newNode = React.createElement(nodeName, props, children);
    return newNode;
}

export function RdkitSvgRenderer({ svg, compound }) {
    const parser = new DOMParser();
    const parsedSvg = parser.parseFromString(svg, 'image/svg+xml');
    const selectedAtoms = useWithSelectedAtoms();
    const reactSvgNode = copyNode(parsedSvg.documentElement, compound, selectedAtoms);
    return <div>{reactSvgNode}</div>;
}
