import TabInfo from '../../../common/TabController/TabInfo';
import ExportMolDataTab from './ExportMolDataTab';
import DownloadImageTab from './DownloadImageTab';
import ExportPubChemTab from './ExportPubChemTab';
import ExportReaxysTab from './ExportReaxysTab';
import ExportPharmitTab from './ExportPharmitTab';
import ExportCDDVaultTab from './ExportCDDVaultTab';
import ExportProLIFTab from './ExportProLIFTab';
import ExportMculeTab from './ExportMculeTab';
import ExportNPDBTab from './ExportNPDBTab';

const tabs = [
    new TabInfo(
        'export_moldata_tab',
        'Export Mol. Data',
        ExportMolDataTab,
        {
            TabPanelProps: {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            },
        },
    ),
    new TabInfo(
        'export_download_tab',
        'Download Image',
        DownloadImageTab,
        { imageOnly: true }
    ),
    new TabInfo(
        'CDDV_react_export_tab',
        'CDD Vault',
        ExportCDDVaultTab,
        {
            TabProps: {
                id: 'export-pane_cddv-tab',
            },
        },
    ),
    new TabInfo(
        'export_pubchem_tab',
        'PubChem',
        ExportPubChemTab,
        {
            TabProps: {
                id: 'export-pane_pubchem-tab',
            },
        },
    ),
    new TabInfo(
        'export_mcule_tab',
        'Mcule',
        ExportMculeTab,
        {
            TabProps: {
                style: {
                    backgroundImage: "url('https://mcule.com/static/img/new/logo-ba56f3e88be8a2e24f37efd8e2c969033156994c.png')",
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    color: 'transparent',
                    margin: '0 0.5em',
                },
            },
        },
    ),
    new TabInfo(
        'export_reaxys_tab',
        'Reaxys',
        ExportReaxysTab,
        {
            TabProps: {
                id: 'export-pane_reaxys-tab',
            },
        },
    ),
    new TabInfo(
        'export_pharmit_tab',
        'Pharmit Screening',
        ExportPharmitTab
    ),
    new TabInfo(
        'export_prolif_tab',
        'ProLIF LID',
        ExportProLIFTab,
        {
            TabPanelProps: {
                id: 'export-pane_prolif-tab',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            },
        },
    ),
    new TabInfo(
        'export_npdb_tab',
        'Natural Products DB',
        ExportNPDBTab
    ),
];

export default tabs;
