import _ from 'lodash';
import TreeNode from './TreeNode';
import { UserActions } from '../cmds/UserAction';
import { MolAtomGroupState } from '../model/DisplayState';

/**
 * Class for managing tree data.
 *
 * getPxDisplayData returns data necessary to be rendered by InfoDisplay and the
 * px-component tree control.
 */
export default class TreeRoot {
    /**
     *
     * @param {{
     *     treeName: string,
     *     children: TreeNode[],
     *     nodeStateFn: { function(TreeNode):
     *       { isVisible: boolean, isSelected: boolean, isExpanded: boolean, isActive: boolean}},
     *     viewSortActions: {title: string, onClick: function}[]
     * }}
     */
    constructor({
        treeName, children, nodeStateFn, viewSortActions,
    }) {
        /** @type {string} */
        this.treeName = treeName;
        /** @type {TreeNode[]} */
        this.children = children || [];
        /**
         * @type { function(TreeNode):
         * { isVisible: boolean, isSelected: boolean, isExpanded: boolean, isActive: boolean}}
         * */
        this.nodeStateFn = nodeStateFn;
        /** @type {{title: string, onClick: function}[]} */
        this.viewSortActions = viewSortActions || [];
        this.getNodeState = this.getNodeState.bind(this);
        this.getTooltip = this.getTooltip.bind(this);
        this.getIcon = this.getIcon.bind(this);

        // Assign treeRoot reference on all child nodes
        this.setChildTreeRoots();
    }

    /**
     * You may wish to override this a child class
     */
    getViewSortActions() {
        return this.viewSortActions;
    }

    /**
     * Default handler for visibility changes from the Tree control.
     * Generally, visibility changes affect the underlying "item" the node represents.
     * This can be overridden.
     * @param {{treeItem: TreeNode}} param0
     */
    onToggleVisible({ treeItem }) {
        // Protect against undefined item, in the case this is called with a group node (no item)
        if (treeItem.item) {
            UserActions.ToggleVisibility(treeItem.item);
        }
    }

    /**
     * Default handler for group expansion changes in the Tree control
     * Expansion changes affect the tree node itself, not the underlying item
     * This can be overridden.
     * @param {{treeItem: TreeNode}} param0
     */
    onToggleExpansion({ treeItem }) {
        UserActions.ToggleExpansion(treeItem);
    }

    /**
     * Override this to pass extra data to the tree control.
     * This is used to help with the fragment energy filter.
     */
    getExtraData(pxNodes) {
        return null;
    }

    // Internal functions for manging nodes

    setChildTreeRoots() {
        this.selectNodes().forEach((node) => node.setTreeRoot(this));
    }

    getNodeState(treeNode) {
        const itemState = treeNode.item ? this.nodeStateFn(treeNode.item) : {};
        const treeNodeState = this.nodeStateFn(treeNode);
        const States = MolAtomGroupState;
        return {
            itemState,
            treeNodeState,
            isVisible: itemState[States.Visible] || treeNodeState[States.Visible],
            isSelected: itemState[States.Selected] || treeNodeState[States.Selected],
            isExpanded: !(itemState[States.Collapsed] || treeNodeState[States.Collapsed]),
            isActive: itemState[States.Active] || treeNodeState[States.Active],
        };
    }

    /**
     * Objective: if there is only one casedata loaded, show its children but not its own node.
     * Do not show a node for
     * @param {CaseData[]} caseDatas The list of case datas to transform into nodes
     * @param {function(CaseData): TreeNode} newNodeFn function to create a TreeNode from a CaseData
     * @param {boolean} removeNoProteinCase whether or not to strip out the no protein case
     * @returns {TreeNode[]} The list of nodes to be at the root level of the tree
     */
    static makeRootNodesForCaseDatas(caseDatas, newNodeFn, removeNoProteinCase=true) {
        let caseDatasToUse = caseDatas;
        if (removeNoProteinCase && caseDatasToUse.length > 1) {
            // If there's a loaded protein, strip out the "no protein" case
            caseDatasToUse = caseDatasToUse.filter((caseData) => caseData.mapCase);
        }
        let children;
        if (caseDatasToUse.length === 1) {
            // When just one case (either protein or no protein case) don't show case node
            children = newNodeFn(caseDatasToUse[0]).children;
        } else {
            children = caseDatasToUse.map((caseData) => newNodeFn(caseData));
        }
        return children;
    }

    // Convenience functions for menu and view/sort menu

    /**
     * Return "expand/collapse all" actions used in the view/sort menu
     * @return {{title: string, onClick: function(): void}[]}
     */
    expandCollapseViewSortActions() {
        const allGroups = this.selectNodes(TreeNode.hasChildren);
        return [
            {
                title: 'Expand all groups',
                onClick: () => UserActions.ToggleExpansion(allGroups, false),
            },
            {
                title: 'Collapse all groups',
                onClick: () => UserActions.ToggleExpansion(allGroups, true),
            },
        ];
    }

    /**
     * Call TreeNode.selectNodes on each child node and collect results
     * @param {*} condition
     * @param {*} pick
     * @returns
     */
    selectNodes(condition, pick) {
        const selected = TreeNode.selectNodes(this.children, condition, pick);
        return selected;
    }

    nodesForItems(items) {
        const itemsToUse = _.castArray(items);
        return this.selectNodes(({ item }) => itemsToUse.includes(item));
    }

    // Functions specific for dealing with the px-components CompoundTree control

    /**
     * Get data necessary for populating px-components CompoundTree
     * @returns { {
     *     treeName: string,
     *     pxNodes: object[],
     *     viewSortActions: { title: string, onClick: function}[],
     *     onToggleVisible: function,
     *     onToggleSelected: function,
     *     onToggleExpansion: function,
     *     getIcon: function,
     *     getTooltip: function,
     *     extra: object?
     * }}
     */
    getPxDisplayData() {
        const pxNodes = this.children.map((child) => child.getPxNode());
        return {
            treeName: this.treeName,
            pxNodes,
            pxItemActions: this.prepareItemActionsForPx(pxNodes),
            viewSortActions: this.getViewSortActions(pxNodes),
            onToggleVisible: this.onToggleVisible,
            onToggleSelected: this.onToggleSelected,
            onToggleExpansion: this.onToggleExpansion,
            getIcon: this.getIcon,
            getTooltip: this.getTooltip,
            extra: this.getExtraData(pxNodes),
        };
    }

    /**
     * px-component tree control wants menu actions for all the tree items listed separately,
     * outside the tree structure, with an isHidden field to determine which tree items have the
     * menu action.
     * This function returns a list of all the menu actions for all tree items, each annotated
     * with an isHidden field that restricts the menu item to the just the right tree item.
     *
     * Todo: Consider modifying this to support reusing actions for similar tree node types.
     * @param {*} pxNodes
     * @returns
     */
    prepareItemActionsForPx(pxNodes) {
        const itemActions = [];
        for (const pxNode of pxNodes) {
            const actions = pxNode.actions;
            if (!actions) continue;
            // add isHidden field to each action
            // This is specific to the px-components Tree control. It is necessary because
            // the tree control has the actions defined at a tree level, not an item level.
            // When an item is clicked, the control checks each action to see if it should be shown.
            const myActions = actions.map(
                (action) => ({
                    ...action,
                    isHidden: ({ treeItem }) => treeItem !== pxNode.treeItem,
                })
            );
            itemActions.push(...myActions);
            // Add actions for children
            if (pxNode.children) {
                itemActions.push(...this.prepareItemActionsForPx(pxNode.children));
            }
        }
        return itemActions;
    }

    /**
     * Call TreeNode.getIcon to get an icon for the item / column
     * @param {string} column
     * @param {{treeItem: TreeNode}} pxItem The node object from the px control
     * @returns {string} css class (Font Awesome) to add to icon tag
     */
    getIcon(column, pxItem) {
        return pxItem.treeItem.getIcon(column, pxItem);
    }

    /**
     * Call TreeNode.getTooltip to get a tooltip for the item / column
     * @param {string} column
     * @param { {treeItem: TreeNode }} pxItem the node object from the px control
     * @returns {string | ReactNode}
     */
    getTooltip(column, pxItem) {
        return pxItem.treeItem.getTooltip(column, pxItem);
    }
}
