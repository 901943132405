/**
 * SimulationPane.jsx
 * A React Component that wraps the SimulationForm with the ProteinContextProvider component and
 * associated context.
 * This arrangement allows BmapsPage to be rendered without an error arising in
 * ProteinContextProvider. ProteinContextProvider calls App.Workspace.getLoadedProteins to populate
 * the protein list, but the App Workspace does not exist when the page is first opened.
 */

/* eslint camelcase: ["error", { allow: ["case_suggestion", "sim_spec"]}] */

import { useEffect, useState } from 'react';
// Local
import { EventBroker } from '../../../eventbroker';
import ModalPane from '../../ModalPane';
import ProteinContextProvider from '../../common/ProteinContextProvider';
import SimulationForm from './SimulationForm';

export default function SimulationPane() {
    const [open, setOpen] = useState(false);
    const [formType, setFormType] = useState('');

    useEffect(() => {
        function openSimPaneSubscription(_, data) {
            setFormType(data);
            setOpen(true);
        }

        EventBroker.subscribe('showFragmentPane', openSimPaneSubscription);
        return () => {
            EventBroker.unsubscribe('showFragmentPane', openSimPaneSubscription);
        };
    }, []);

    function handleClose() {
        setOpen(false);
    }

    return (
        <ModalPane
            visible={open}
            title="Simulation Setup"
            closeOnEscape={handleClose}
            close={handleClose}
            ContentProps={{
                style: {
                    overflow: 'auto',
                },
            }}
        >
            <ProteinContextProvider>
                <SimulationForm formType={formType} open={open} close={handleClose} />
            </ProteinContextProvider>
        </ModalPane>
    );
}
