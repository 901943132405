import Divider from '@mui/material/Divider';
import GroupMenuTitle from './base/GroupMenuTitle';
import MenuMessage from './base/MenuMessage';
import NestedMenuItem from './base/NestedMenuItem';
import MenuItem from './base/MenuItem';

/**
 * Convert a menu specification into a collection of components to render for the menu
 * @param {[ {
 *      itemType: string,
 *      label: string,
 *      onClick: function,
 *      content: Component,
 * }]} items
 * @param {function} closeMenu
 * @param {function} defaultHandler
 * @param {string} keyPrefix - Only used internally when creating sub menus
 */
export function createContextMenuComponents(items, closeMenu, defaultHandler, keyPrefix = '') {
    const ret = [];

    for (const item of items) {
        if (!item) continue;
        const {
            itemType, label, onClick,
        } = item;

        const clickHandler = (e) => {
            if (onClick) {
                onClick(e.target);
            } else {
                defaultHandler(e.target);
            }
            closeMenu();
        };
        item.onClick = clickHandler;

        if (itemType === 'nested') {
            const { content } = item;
            if (Array.isArray(content)) {
                const subItems = createContextMenuComponents(content, closeMenu, defaultHandler, `nested-${keyPrefix}`);
                item.content = subItems;
            }
        }

        const comp = createContextMenuListItem(
            item, keyPrefix + (ret.length + 1)
        );
        ret.push(comp);
    }

    return ret;
}

function createContextMenuListItem(item, keyPrefix) {
    const {
        itemType, label, onClick, data, content, ...rest
    } = item;
    const dataProps = getDataProps(data);

    switch (itemType) {
        case 'grouphead':
            return <GroupMenuTitle key={`${keyPrefix}-${label}`} {...rest}>{label}</GroupMenuTitle>;

        case 'message':
            return <MenuMessage key={`${keyPrefix}-${label}`} {...rest}>{label}</MenuMessage>;

        case 'nested':
            return (
                <NestedMenuItem
                    key={`${keyPrefix}-${label}`}
                    label={label}
                    parentMenuOpen
                    {...rest}
                >
                    {content}
                </NestedMenuItem>
            );

        case 'separator':
            return <Divider key={`${keyPrefix}-divider`} />;

        default:
            // A MenuItem can contain either a simple label or custom content.
            return <MenuItem key={`${keyPrefix}-item`} {...dataProps} onClick={onClick} {...rest}>{label || content}</MenuItem>;
    }
}

function getDataProps(data) {
    const dataProps = {};
    if (data) {
        for (const [key, value] of Object.entries(data)) {
            dataProps[`data-${key}`] = value;
        }
    }
    return dataProps;
}
