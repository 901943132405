import * as targetActions from '.';
import store from '../store';

export function addStructureToProjectTargets(mapCase) {
    store.dispatch(targetActions.addStructure({
        mapCase,
        displayName: mapCase.displayName,
        isTarget: true,
    }));
}

export function removeStructureByMapCase(mapCase) {
    if (mapCase) {
        store.dispatch(targetActions.removeStructure({ mapCase }));
        return;
    }
    console.log('Error: No mapCase provided to Redux');
}

export function removeAllStructures() {
    store.dispatch(targetActions.removeAllStructures());
}

export function getTargetInfoMap() {
    const map = new Map();
    const list = store.getState().projectTargets.targetList;
    list.forEach((structure) => {
        map.set(structure.mapCase, structure);
    });
    return (map);
}
