/* compound.js */

import { getFunctionalGroups, AtomGroup } from '../utils';
import { getFullResidueId } from '../util/mol_info_utils';
import { EventBroker } from '../eventbroker';
import { EnergyInfo } from './energyinfo';
import { WebServices } from '../WebServices';
import { MolAtomGroup, AtomGroupTypes } from './atomgroups';
import { App } from '../BMapsApp';
import { getColorSchemeInfo } from '../redux/prefs/access';
import { Loader } from '../Loader';
import {
    makeNamedSmiles, makeOneSDF, removeSmilesMolName, replaceNameInMolText,
    removeAtomMappingFromMolText,
} from '../util/mol_format_utils';
import { ensureArray } from '../util/js_utils';

export class Compound extends MolAtomGroup {
    /**
     * Lookup a compound by spec from a list of compounds
     * @param {string} spec
     * @param {Compound[]} cmpdList
     * @return {Compound?}
     */
    static findBySpec(spec, cmpdList) {
        return cmpdList?.find(({ resSpec }) => resSpec === spec);
    }

    constructor(atoms, type=AtomGroupTypes.Compound) {
        super(atoms, type);
        this.atomGroup = new AtomGroup();
        for (const newAtom of this.atoms) {
            this.addAtom(newAtom);
        }
        this.bindingSite = null;
        this.energyInfo = new EnergyInfo(this);
        this.functionalGroups = getFunctionalGroups(this.getAtoms());
        this.modifications = [];
        this.pinned = false;
        this.is_ligand = false;
        this.canDock = true;
        this.smiles = null;
        this.mol2000 = null;
        this.molProps = {};
        this.molType = null;
        this.molName = null;
        this.heritage = new CompoundHeritage();
    }

    /**
     * Lipinsky's Rule of 5:
     *   - No more than 5 hydrogen bond donors (HBD <= 5)
     *   - No more than 10 hydrogen bond acceptors (HBA <= 10)
     *   - A molecular mass less than 500 daltons (MW < 500)
     *   - LogP that does not exceed 5 (LogP <= 5)
     * @returns {number} The number of Lipinsky Rule violations for this compound (0-4)
     */
    getLipinskyViolationCount() {
        let counter = 0;
        if (this.getMolProp(MolProps.HBondDonors) > 5) counter++;
        if (this.getMolProp(MolProps.HBondAcceptors) > 10) counter++;
        if (this.getMolProp(MolProps.MolWeight) >= 500) counter++;
        if (this.getMolProp(MolProps.LogP) > 5) counter++;
        return counter;
    }

    addAtom(atom) {
        this.atomGroup.addAtom(atom);
    }

    hasAtom(atom) {
        return this.atomGroup.hasAtom(atom);
    }

    getAtomByName(name) {
        for (const atom of this.getAtoms()) {
            // console.log(`looking for ${name}, looking at ${atom.atom}`)
            if (atom.atom === name) return atom;
        }
        return null;
    }

    atomCount() {
        return this.atomGroup.atomCount();
    }

    getAtoms() {
        return this.atomGroup.getAtoms();
    }

    getEnergyInfo() {
        return this.energyInfo;
    }

    energyTypeAvailable(typesIn) {
        const types = ensureArray(typesIn);
        return this.energyInfo.energiesAvailable(types);
    }

    getFunctionalGroups() {
        return this.functionalGroups;
    }

    getFunctionalGroupByAtom(atom) {
        return this.functionalGroups.find((fg) => fg.includes(atom));
    }

    recordHeritage(unit) { this.heritage.record(unit); }
    getHeritage() { return this.heritage.steps(); }

    setLigand(ligand) { this.is_ligand = ligand; }
    isLigand() { return this.is_ligand; }

    setMol2000(mol2000) { this.mol2000 = mol2000; }

    /**
     * Return a mol block for the compound.
     * @param {CaseData} refCaseData If supplied, use transformed molfile coordinates for dest case
     * @returns {string}
     */
    getMol2000(refCaseData) {
        if (!refCaseData || refCaseData === this.caseData) {
            return this.mol2000;
        } else {
            return refCaseData.transformMolDataFrom(this.mol2000, 'mol', this.caseData);
        }
    }

    getMolType() { return this.molType; }

    getMolName() { return this.molName; }

    setSmiles(smiles) { this.smiles = smiles; }
    getSmiles() { return this.smiles; }
    getUnnamedSmiles() { return removeSmilesMolName(this.smiles); }

    setSvg(svg) { this.svg = svg; }
    getSvg() { return this.svg; }

    isCovalentlyBound() {
        return this.hasForeignBonds();
    }

    rename(newName) {
        this.resSpec = newName;
        this.getAtoms().forEach((at) => { at.resname = newName; });
        const newMolString = replaceNameInMolText(this.mol2000, newName);
        this.setMol2000(newMolString);
        const newSmiles = makeNamedSmiles(this.smiles, newName);
        this.setSmiles(newSmiles);
    }

    async updateSvg(format='mol', { colorSchemeInfo=getColorSchemeInfo(), renderIndices=false }={}) {
        let svg;
        // TODO: implement this with svgForMol
        //       Two challenges, to handle:
        //           1. Specific rdkit paramaters and calling get_svg_with_highlights
        //           2. Different post-generation color replacements for both rdkit and indigo
        // uncomment this to enable local, RDKit based functionality
        // svg = await Compound.getCmpdSvgRDKit(this, format, { colorSchemeInfo, renderIndices });
        // if (svg === 'failed')
        // eslint-disable-next-line prefer-const
        svg = await Compound.getCmpdSvgIndigo(this, format, { colorSchemeInfo, renderIndices });
        this.setSvg(svg);
        this.fireCompoundChanged();
    }

    static async getCmpdSvgRDKit(cmpd, format = 'mol', { colorSchemeInfo=getColorSchemeInfo(), renderIndices=false }={}) {
        if (!Loader.RDKitExport) {
            return 'failed';
        }

        let molString = cmpd.getMol2000();
        if (!renderIndices) molString = removeAtomMappingFromMolText(molString);
        const data = format === 'mol' ? molString : cmpd.getSmiles();
        const mdetails = {
            clearBackground: false,
            rotate: 0,
            // addStereoAnnotation: true,
            // prepareMolsBeforeDrawing: true,
        };
        const jsMol = Loader.RDKitExport.getCleanMol(data);
        mdetails.rotate = Loader.RDKitExport.molSpin(jsMol.get_molblock());
        let svg = jsMol.get_svg_with_highlights(JSON.stringify(mdetails), 500, 300);
        jsMol.delete();

        // This changes black objects in the svg to the theme's text color
        svg = svg.replace(/#000000/g, colorSchemeInfo.textCss);

        // This changes blue objects to a lighter blue that shows up better on black
        svg = svg.replace(/#0000FF/g, '#1919FF');
        return svg;
    }

    static async getCmpdSvgIndigo(cmpd, format = 'mol', { colorSchemeInfo=getColorSchemeInfo(), renderIndices=false }) {
        let molString = cmpd.getMol2000();
        if (!renderIndices) molString = removeAtomMappingFromMolText(molString);
        const data = format === 'mol' ? molString : cmpd.getSmiles();
        let svg;

        try {
            svg = await WebServices.mol2svg(data, format);
        } catch (err) {
            try {
                await WebServices.refreshCookie();
                svg = await WebServices.mol2svg(data, format);
            } catch {
                svg = 'failed';
            }
        }

        // Indigo occasionally sends explicitly black bonds or labels that don't match
        // the neighbors and don't update with the background theme color.
        // To handle that case, we recolor the SVG objects to have the same color as the
        // other text / bonds, allowing them to be seen when the background is black.
        // See 4Y2X for an example
        // TODO make the webservice not draw black objects for black theme (address root cause)
        svg = svg.replace(/rgb\(0%,0%,0%\)/g, colorSchemeInfo.textCss);
        return svg;
    }

    async updateMolProps() {
        const spec = this.resSpec;

        try {
            const { connector } = App.getDataParents(this);
            const incomingProps = await connector.cmdGetMoleculeProperties(spec);

            for (const [indigoKey, indigoValue] of Object.entries(incomingProps)) {
                // Convert Indigo keys to our own if possible
                const localKey = IndigoMolPropMap[indigoKey] || indigoKey;
                this.setMolProp(localKey, indigoValue);
            }
            const rotBondCount = this.getRotatableBonds().length;
            this.setMolProp(MolProps.RotatableBonds, rotBondCount, true);
            this.fireCompoundChanged();

            this.updateEnergyEfficiency();
        } catch (err) {
            console.warn(`Failed to get mol props for ${this.resSpec}`);
        }
    }

    getRotatableBonds({ includeAmideBonds=false }={}) {
        const allBonds = this.getAtoms()
            .map((x) => x.heavyBonds)
            .reduce((acc, cur) => acc.concat(cur), []); //--- Is this redundant?
        const rotBonds = [];
        for (const bond of allBonds) {
            if (bond.isRotatable({ includeAmideBonds })) {
                rotBonds.push(bond);
            }
        }
        const uniqueRotBonds = [];
        for (const bond of rotBonds) {
            let alreadyInSet = false;
            for (const other of uniqueRotBonds) {
                if (bond.equals(other)) {
                    alreadyInSet = true;
                    break;
                }
            }
            if (!alreadyInSet) {
                uniqueRotBonds.push(bond);
            }
        }

        const debugRotatableBonds = false;
        if (debugRotatableBonds) {
            console.log('ROTATABLE BONDS:');
            for (const bond of uniqueRotBonds) {
                const atom1 = bond.atom1;
                const atom2 = bond.atom2;
                console.log(`${atom1.atom} (${atom1.amber}) - ${atom2.atom} (${atom2.amber})`);
            }
        }
        return uniqueRotBonds;
    }

    getMolProps() { return { ...this.molProps }; }
    getMolProp(prop) { return this.molProps[prop]; }

    setMolProp(prop, value, overwrite=false) {
        const prevVal = this.molProps[prop];
        if (prevVal) {
            if (overwrite) {
                console.log(`Overwriting mol prop ${prop}: ${prevVal} -> ${value}`);
            } else {
                console.log(`Not overwriting mol prop ${prop}: ${prevVal}. Ignoring new value: ${value}`);
                return;
            }
        }

        this.molProps[prop] = value;
    }

    // Energy efficiency is -interaction energy / heavy atom count
    // We get the energy from two different server packets (energiesForLig + solvationForLig)
    // We get the heavy atom count from the web service (via OpenBabel), although
    // we could really calculate that ourselves.
    // This function is called whenever these data sources are received, and when they are all
    // available, the efficiency will be added.
    //
    // If we don't have heavy atoms, we haven't received any molprops, so don't do anything.
    //      (If we were to add an efficiency prop, it would be the only one)
    // If we have heavy atoms, but are missing any component energies, energy efficiency is "N/A"
    updateEnergyEfficiency() {
        let energy = null;

        const heavyAtoms= this.molProps[MolProps.HeavyAtoms];

        // These energy components have to be available, but they aren't
        // necessarily part of the energy score calculation.
        const energyComponents = [
            EnergyInfo.Types.vdW,
            EnergyInfo.Types.ddGs,
            EnergyInfo.Types.electrostatics,
            EnergyInfo.Types.hbonds,
        ];
        if (this.energyInfo.energiesAvailable(energyComponents)) {
            energy = this.energyInfo.getEnergyScore();
        }

        if (heavyAtoms != null) {
            const efficiency = energy != null
                ? this.calcEnergyEfficiency(energy, heavyAtoms)
                : 'N/A';
            this.setMolProp(MolProps.EnergyEfficiency, efficiency, true);
            this.fireCompoundChanged();
        }
    }

    // Definition of energy efficiency:
    //      -(interaction energy) / (# heavy atoms)
    // If result < 0, just return 0.
    // Return null if missing energy or atom count
    calcEnergyEfficiency(energy, heavyAtoms) {
        if (energy != null && heavyAtoms) {
            const efficiency = -1 * (energy / heavyAtoms);
            return efficiency > 0 ? efficiency : 0;
        } else {
            return null;
        }
    }

    fireCompoundChanged() {
        EventBroker.publish('compoundChanged', { compound: this });
    }

    toString() {
        return this.resSpec;
    }

    // This will track direct child modifications off the original compound:
    //   resname_mod1  // first modification to the *original*
    //   resname_mod2  // second modification to the *original*
    // Any modifications to a modification will all be gathered under that modification name
    // Any changes to resname_mod1 will come back as resname_mod1 and replace it.
    nextModifiedCompName() {
        const match = this.resname.match(/_mod_(\d+)$/);
        if (match) {
            return this.resname;
        } else {
            const newName = `${this.resname}_mod_${this.modifications.length+1}`;
            this.modifications.push(newName);
            return newName;
        }
    }

    get selectQuery() {
        const atom = this.atoms[0];
        // To get around issues of weird characters in compound names, use the
        // full technical spec, ie UNL.<num>:Z for user compounds.

        // At one time, we thought we needed to specifiy ligand or compound.
        // const type = this.isLigand() ? 'ligand' : 'compound';
        const spec = getFullResidueId(atom, true);
        return spec;
    }

    isMinimizing() {
        return this.energyInfo.minimizationStatus === EnergyInfo.States.working;
    }

    anyEnergiesWorking() {
        return this.energyInfo.anyEnergiesWorking();
    }

    /**
     * Produce SDF content for a number of compounds
     * @param {Compound[]} compounds
     * @returns {string}
     */
    static makeSDF(compounds) {
        return compounds.map((c) => c.getSDF()).join('');
    }

    /**
     * Produce SDF content for this compound
     * @returns {string}
     */
    getSDF() {
        const molBlock = this.getMol2000().trimRight();
        const sdfProps = this.getSDFPropsObj();
        return makeOneSDF(molBlock, sdfProps);
    }

    /**
     * Gather properties of interest for SDF export into an object
     * @returns {object}
     */
    getSDFPropsObj() {
        // mapCase is the protein the compound is associated with, if any
        const { mapCase } = App.getDataParents(this);
        return {
            ...this.proteinDataForExport(mapCase),
            ...this.heritageForExport(),
            ...this.energiesForExport(mapCase),
            ...this.molpropsForExport(),
        };
    }

    proteinDataForExport() {
        // NYI
        // Need to think through privacy. Does a user necessarily want the protein name included?
        return {};
    }

    /**
     * Prepare SDF property with compound heritage.
     * This lists a source row.
     * Then "Modifications", followed by lines for modifications that changes the chemistry.
     * Docking / Minimization are appended to the previous line.
     * @returns { object }
     */
    heritageForExport() {
        const heritageLines = [];
        for (const heritageUnit of this.heritage.steps()) {
            const { self, label } = heritageUnit;
            const detailText = getSDFDetailForHeritageUnit(heritageUnit);
            const detail = detailText ? `: ${detailText}` : '';
            if (heritageLines.length === 0) {
                heritageLines.push(`Source: ${self.resSpec} (${label}${detail})`);
            } else {
                const recentLineIdx = heritageLines.length - 1;
                switch (label) {
                    // Since the Docking entry has a lot of information, use the default case
                    // to put it on its own line, instead of appending to previous line
                    /*
                    case 'Docked': {
                        const dockDetail = detailText ? ` (${detailText})` : '';
                        heritageLines[recentLineIdx] += `; Docked${dockDetail}`;
                        break;
                    }
                    */
                    case 'Energy Minimized': {
                        const minDetail = detailText ? ` (${detailText})` : '';
                        heritageLines[recentLineIdx] += `; Minimized${minDetail}`;
                        break;
                    }
                    default: {
                        if (recentLineIdx === 0) heritageLines.push('Modifications:');
                        const modCount = heritageLines.length - 1;
                        heritageLines.push(`${modCount}. ${label}${detail}`);
                    }
                }
            }
        }

        return {
            'BMaps Compound Provenance': heritageLines.join('\n'),
        };
    }

    energiesForExport(mapCase) {
        const result = {};

        if (!mapCase) {
            // Require protein for exporting energies in heritage (not including internal energies)
            return result;
        }

        const energyPropsRules = [
            {
                label: 'BMaps Energy Score',
                // This definition of availability requires knowing that the score = vdW + hbonds
                available: () => this.energyTypeAvailable([
                    EnergyInfo.Types.vdW, EnergyInfo.Types.hbonds,
                ]),
                value: () => this.energyInfo.getEnergyScore().toFixed(2),
            },
            {
                label: 'BMaps ddGs',
                available: () => this.energyTypeAvailable([EnergyInfo.Types.ddGs]),
                value: () => this.energyInfo.getEnergyValueByType(EnergyInfo.Types.ddGs).toFixed(2),
            },
            {
                label: 'Autodock Vina Docking Score',
                available: () => this.energyTypeAvailable([EnergyInfo.ExtraTypes.dockingScore]),
                value: () => (
                    this.energyInfo.getEnergyValueByType(EnergyInfo.ExtraTypes.dockingScore)
                        .toFixed(2)
                ),
            },
        ];

        for (const { label, available, value } of energyPropsRules) {
            if (available()) {
                result[label] = value();
            }
        }
        return result;
    }

    molpropsForExport() {
        return {
            'BMaps Mol. Wt.': this.molProps[MolProps.MolWeight].toFixed(2),
            'BMaps Polar Surface Area': this.molProps[MolProps.PolarSurfaceArea].toFixed(2),
            'BMaps LogP (OpenBabel XLogP)': this.molProps[MolProps.LogP].toFixed(2),
            'BMaps HBond Donors': this.molProps[MolProps.HBondDonors],
            'BMaps HBond Acceptors': this.molProps[MolProps.HBondAcceptors],
        };
    }
}

export class CompoundHeritage {
    constructor() {
        this.list = [];
    }

    steps() {
        return [...this.list];
    }

    record(newUnit) {
        if (newUnit.parent) {
            for (const old of newUnit.parent.getHeritage()) {
                this.list.push(old);
            }
        }
        this.list.push(newUnit);
    }

    static addLigand(self) {
        self.recordHeritage(new HeritageUnit(self, null, 'Co-Crystal Ligand'));
    }

    static addTerminalReplacement(parent, children, detailObj) {
        const detail = `${detailObj.atom.atom} -> ${detailObj.replacementGroup}`;
        for (const child of [].concat(children)) {
            child.recordHeritage(new HeritageUnit(child, parent, 'Terminal Replace', detailObj, detail));
        }
    }

    static addDock(parent, children, detailObj) {
        for (const child of [].concat(children)) {
            let detailEntries = {};
            const dockingProgram = detailObj?.dockingProgram;
            switch (dockingProgram) {
                case 'autodock': {
                    const {
                        dockingScore, dockingPoseRank, dockingScoreDelta,
                        // boxParams, rmsdFromBestUB, rmsdFromBestLB, // for commented code below
                    } = detailObj;
                    detailEntries = {
                        'Autodock Score': dockingScore?.toFixed(2),
                        'Pose Rank': dockingPoseRank,
                        'Delta from best score': dockingScoreDelta?.toFixed(2),
                        // Uncomment to display docking target (ligand, hotspot or selected atoms)
                        /* eslint-disable-next-line quote-props */
                        // 'target': boxParams?.refObj?.description;
                        //
                        // These RMSD values have been removed from presentation to the user.
                        // Upper bound is based on a strict 1-1 atom correspondence
                        // Lower bound allows using other atoms of the same type,
                        // which is more meaningful when there is symmetry.
                        // 'RMSD from best pose (upper bound)': rmsdFromBestUB?.toFixed(2),
                        // 'RMSD from best pose (best fit)': rmsdFromBestLB?.toFixed(2),
                    };
                    break;
                }
                case 'diffdock': {
                    const { rank, confidence, confDelta } = detailObj;
                    detailEntries = {
                        'Pose Rank': rank,
                        'DiffDock Confidence': confidence,
                        'Delta from best confidence': confDelta?.toFixed(2),
                    };
                    break;
                }
                default:
                    // detailEntries defaults to empty object above
            }
            const detailText = Object.entries(detailEntries).reduce(
                (acc, [label, value]) => (value != null ? acc.concat(`${label}: ${value}`) : acc),
                []
            ).join(', ') || undefined;
            child.recordHeritage(new HeritageUnit(child, parent, 'Docked', detailObj, detailText));
        }
    }

    static addFragmentGrow(parent, children, detailObj) {
        const selectionID = detailObj.suggestion.selectionIDs[detailObj.suggestion.selectedIndex];
        let detail = `${detailObj.atom.atom} -> ${detailObj.suggestion.name}`;
        if (detailObj.suggestion.frags?.length > 0) {
            const selected = detailObj.suggestion.frags.find((x) => x.poseSerialNo === selectionID);
            const excp = selected.exchemPotential.toFixed(1);
            detail += `, Frag. FE (ExCP): ${excp}`;
        }
        for (const child of [].concat(children)) {
            child.recordHeritage(new HeritageUnit(child, parent, 'Fragment Grow', detailObj, detail));
        }
    }

    static addFindNear(parent, children, detailObj) {
        const selectionID = detailObj.suggestion.selectionIDs[detailObj.suggestion.selectedIndex];
        const selectedFrag = detailObj.suggestion.frags.find((x) => x.poseSerialNo === selectionID);
        const excp = selectedFrag.exchemPotential.toFixed(1);
        const detail = `${getFullResidueId(detailObj.atom)}, ${detailObj.atom.atom}, Frag. FE (ExCP): ${excp}`;
        for (const child of [].concat(children)) {
            child.recordHeritage(new HeritageUnit(child, parent, 'Search Nearby', detailObj, detail));
        }
    }

    static addMinimize(self, detailObj) {
        let detailText = '';
        if (App.getDataParents(self).mapCase) {
            const score = self.energyInfo.getEnergyScore();
            detailText = `Score: ${score.toFixed(1)}`;
        } else {
            // Don't bother displaying the internal energy in the compound heritage
        }

        self.recordHeritage(new HeritageUnit(self, null, 'Energy Minimized', detailObj, detailText));
    }

    static addMolSource(parent, children, molSource) {
        // const updateType = molSource.modifiedFrom ? "Modified" : "Added via";
        const label = (p) => `${p ? 'Modified via' : 'Added via'} ${molSource.sourceType}`;
        for (const child of [].concat(children)) {
            child.recordHeritage(
                new HeritageUnit(child, parent, label(parent), molSource, molSource.sourceId)
            );
        }
    }

    static addRename(self, detailObj) {
        self.recordHeritage(new HeritageUnit(self, null, 'Renamed', detailObj));
    }

    static addDuplicate(parent, children) {
        for (const child of [].concat(children)) {
            child.recordHeritage(new HeritageUnit(child, parent, 'Duplicated'));
        }
    }

    static addStarterCompounds(children, isValidPdbCode) {
        const re = /(...)\.(....)/;
        for (const child of [].concat(children)) {
            const match = child.resSpec.match(re);
            if (match && isValidPdbCode(match[2])) {
                child.recordHeritage(new HeritageUnit(child, null, `Co-Crystal Ligand from ${match[2]}`));
            } else {
                child.recordHeritage(new HeritageUnit(child, null, 'Sample compound'));
            }
        }
    }
}

export class HeritageUnit {
    constructor(self, parent, label, detailObject, detailText) {
        this.self = self;
        this.parent = parent;
        this.label = label;
        this.detailObject = detailObject;
        this.detailText = detailText;
    }
}

function getSDFDetailForHeritageUnit({ label, detailObject, detailText }) {
    switch (label) {
        // These cases remove the fragment binding score from the heritage detail
        // This is actually important information, so put it back until we can clarify the details.
        // case 'Fragment Grow':
        //     return `${detailObject.atom.atom} -> ${detailObject.suggestion.name}`;
        // case 'Search Nearby':
        //     return `${getFullResidueId(detailObject.atom)}, ${detailObject.atom.atom}`;
        default:
            return detailText;
    }
}

export class Ligand extends Compound {
    constructor(atoms) {
        super(atoms);
        this.setLigand(true);
        CompoundHeritage.addLigand(this);
    }
}

// Constants for MolProps. Also used for labels.
export const MolProps = {
    HeavyAtoms: '# Heavy Atoms',
    PolarSurfaceArea: 'PSA',
    LogP: 'LogP',
    HBondAcceptors: 'HB Acceptors',
    HBondDonors: 'HB Donors',
    Charge: 'Charge',
    RotatableBonds: '# Rot. Bonds',
    MolWeight: 'Mol. Weight',
    EnergyEfficiency: 'Energy Efficiency',
};

// Map from Indigo molprop codes to ours
const IndigoMolPropMap = {
    HAC: MolProps.HeavyAtoms,
    TPSA: MolProps.PolarSurfaceArea,
    LogP: MolProps.LogP,
    HBA: MolProps.HBondAcceptors,
    HBD: MolProps.HBondDonors,
    charge: MolProps.Charge,
    RBC: MolProps.RotatableBonds,
    MW: MolProps.MolWeight,
};
