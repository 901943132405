/* React components for the top left InfoDisplay
 *
 * Main component is InfoDisplay, which subscribes to events and controls child components.
 * To install next to main canvas, just call installInfoDisplay().
 * InfoDisplay doesn't actually use any props, it subscribes to compound
 * events to get the info it needs.
 *
 * Protein components:
 *   - ProteinControl
 *     - ChooseProteinButton
 *     - LoadedProteinInfo
 *
 * Compound Components
 *   - CompoundControl
 *     - AddCompoundButton
 *     - CompoundList
 *       - OneCompound
 *         - CompoundPin
 *         - CompoundClose
 *
 * This could probably be simplified considerably.
 */
import React from 'react';
import { updateEnergyTooltips } from './EnergyDisplay';
import { EventBroker } from '../../eventbroker';
import { App } from '../../BMapsApp';
import { updateTooltipster, svgImgSrc } from '../ui_utils';
import { TreeGroup } from '../../model/TreeData';
import { MolAtomGroupState } from '../../model/DisplayState';
import { UserActions } from '../../cmds/UserAction';
import { withColorSchemeInfo } from '../../redux/prefs/access';
import SystemSelector from './SystemSelector';
import Inspector, { updateMolPropsTooltips } from './Inspector';
import SidePanel from './SidePanel';

const renderDebug = false;

// default export below: withColorSchemeInfo(InfoDisplay);

class InfoDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            proteinInfo: { mainProtein: undefined, allProteins: [] },
            activeCompound: null,
            otherVisibleCompounds: [],
            compoundListItems: [],
            sampleCompoundInfo: [],
            hotspotInfo: {
                hotspots: [],
                threshold: 0,
            },
        };

        this.onUpdate = this.onUpdate.bind(this);
        this.updateMenuColorsHack();
    }

    componentDidMount() {
        EventBroker.subscribe('zapAll', this.onUpdate);
        EventBroker.subscribe('dataLoaded', this.onUpdate);
        EventBroker.subscribe('energyCalc', this.onUpdate);
        EventBroker.subscribe('activeCompound', this.onUpdate);
        EventBroker.subscribe('compoundChanged', this.onUpdate);
        EventBroker.subscribe('visibilityChanged', this.onUpdate);
        EventBroker.subscribe('atomGroupStateChanged', this.onUpdate);
        EventBroker.subscribe('hotspotsChanged', this.onUpdate);
        EventBroker.subscribe('viewStateChanged', this.onUpdate);
        EventBroker.subscribe('starterAvailability', this.onUpdate);
        EventBroker.subscribe('debugInfo', this.onDebugInfo);
        this.updateTooltips();
    }

    componentDidUpdate() {
        this.updateTooltips();
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('zapAll', this.onUpdate);
        EventBroker.unsubscribe('dataLoaded', this.onUpdate);
        EventBroker.unsubscribe('energyCalc', this.onUpdate);
        EventBroker.unsubscribe('activeCompound', this.onUpdate);
        EventBroker.unsubscribe('compoundChanged', this.onUpdate);
        EventBroker.unsubscribe('visibilityChanged', this.onUpdate);
        EventBroker.unsubscribe('atomGroupStateChanged', this.onUpdate);
        EventBroker.unsubscribe('hotspotsChanged', this.onUpdate);
        EventBroker.unsubscribe('viewStateChanged', this.onUpdate);
        EventBroker.unsubscribe('starterAvailability', this.onUpdate);
        EventBroker.unsubscribe('debugInfo', this.onDebugInfo);
    }

    onDebugInfo(eventName, data) {
        $('#debug_display').text(data);
    }

    onUpdate() {
        const obj = {
            proteinInfo: {
                mainProtein: App.Workspace.getLoadedProtein(),
                allProteins: App.Workspace.getLoadedProteins(),
            },
            activeCompound: App.Workspace.getActiveCompound(),
            otherVisibleCompounds: App.Workspace.getVisibleCompounds(false),
            compoundListItems: this.compoundListItems(),
            proteinListItems: App.Workspace.proteinTree.getPxDisplayData(),
            fragmentListInfo: this.fragmentListInfo(),
            inspectorTab: App.Workspace.DisplayState.inspectorTab,
            sampleCompoundInfo: this.sampleCompoundInfo(),
            hotspotInfo: {
                hotspots: App.Workspace.getAllHotspots(),
                threshold: App.Workspace.hotspotThreshold,
            },
        };
        this.setState(obj);
        EventBroker.publish('setInfoPanel', {
            // When we update, pass the state through to props to include all selector data updates
            pageId: 'Info',
            props: {
                ...obj,
                backgroundStyle: { backgroundColor: this.fetchBackgroundColor() },
            },
        });
    }

    updateTooltips() {
        const tooltipInfo = {
            generalButton: { side: 'right' },
            proteinName: { side: 'right' },
            'pdb-link': { side: 'right' },
            addCompoundButton: { side: 'left' },
        };
        updateTooltipster(tooltipInfo);
        updateEnergyTooltips();
        updateMolPropsTooltips();
    }

    compoundListItems() {
        const compoundTree = App.Workspace.systemTree.compoundsTree;
        const activeCompound = App.Workspace.getActiveCompound();
        return treeItems(compoundTree, (item) => {
            const cmpd = item.item;
            const isActive = cmpd === activeCompound;
            return compoundTreeItem(item, cmpd, isActive, App.Workspace);
        });
    }

    fetchBackgroundColor() {
        const { colorSchemeInfo } = this.props;
        return colorSchemeInfo.css + colorSchemeInfo.controlAlphaCss;
    }

    // The mechanism for updating Selector Menu & tooltip colors is to change the
    // data-theme attribute on a parent node, and the colors are defined as variables
    // in the scope of that data-theme. (see InfoDisplay.css)
    // Introducing another containing div in the Selector / Inspector components
    // will confuse the layout, so to avoid the risk of unknown effects,
    // I'll just update it on the document body, which also will apply to modals.
    updateMenuColorsHack() {
        const { colorSchemeInfo } = this.props;
        if (typeof (document) !== 'undefined') {
            document.body.setAttribute('data-theme', colorSchemeInfo.name);
        }
    }

    fragmentListInfo() {
        const availableFragmentInfo = App.Workspace.getAllAvailableFragmentInfo();
        if (availableFragmentInfo.length === 0) return null;

        return App.Workspace.fragmentsTree.getPxDisplayData();
    }

    /**
     * Collect data necessary to display the sample compound buttons
     * @returns {{caseData: CaseData, caseSampleAvailability: StarterCompounds}[]
     */
    sampleCompoundInfo() {
        const caseDatas = App.Workspace.allCaseData();
        // caseData is actually all we need; the rest is extracted
        return caseDatas.map((caseData) => ({ caseData }));
    }

    // Note: this will rerender liberally.  To prevent it from re-rendering,
    // either implement shouldComponentUpdate in SystemSelector or make sure
    // that the same objects are passed each time into SystemSelector.  That
    // would require some logic in InfoDisplay.onUpdate().
    render() {
        if (renderDebug) console.log('Rendering InfoDisplay');
        const { proteinInfo } = this.state;

        const backgroundStyle = { backgroundColor: this.fetchBackgroundColor() };
        this.updateMenuColorsHack();

        return (
            <div id="info_display">
                <div id="debug_display" />
                <ProteinControl proteinInfo={proteinInfo} />
                <CompoundControl />
                <SidePanel side="Info" defaultPageId="Info">
                    <SelectorPanel pageId="Info" {...this.state} backgroundStyle={backgroundStyle} />
                </SidePanel>
            </div>
        );
    }
}

const SelectorPanel = ({
    compoundListItems, fragmentListInfo,
    proteinListItems, backgroundStyle,
    sampleCompoundInfo, hotspotInfo,
    inspectorTab, activeCompound,
    otherVisibleCompounds, proteinInfo,
}) => (
    <>
        <SystemSelector
            compoundItems={compoundListItems}
            fragmentListInfo={fragmentListInfo}
            proteinItems={proteinListItems}
            backgroundStyle={backgroundStyle}
            sampleCompoundInfo={sampleCompoundInfo}
            hotspotInfo={hotspotInfo}
        />
        <Inspector
            tab={inspectorTab}
            activeCompound={activeCompound}
            otherVisibleCompounds={otherVisibleCompounds}
            proteinInfo={proteinInfo}
            backgroundStyle={backgroundStyle}
        />
    </>
);

// Export the React-Redux connected InfoDisplay
export default withColorSchemeInfo(InfoDisplay);

/**
 * @description Recursively convert one tree structure to another
 * @param {*} group
 * @param {*} createLeafFn
 */
function treeItems(group, createLeafFn) {
    return group.children.map((item) => {
        if (item instanceof TreeGroup) {
            return groupTreeItem(
                item,
                item.displayName,
                App.Workspace.getAtomGroupState(item),
                treeItems(item, createLeafFn)
            );
        } else {
            return createLeafFn(item);
        }
    });
}

/**
 * Return a compound display name for the Compound Selector, prepending the name
 * with the caseData short name if there are multiple proteins.
 * @param {Compound} compound
 * @returns {string}
 */
function cmpdDisplayName(compound) {
    const prefix = App.Workspace.getLoadedProteins().length < 2
        ? ''
        : `${App.getDataParents(compound).caseData.getShortName()}: `;
    return `${prefix}${compound.displayName}`;
}

function compoundTreeItem(treeItem, compound, isActive, workspace) {
    return {
        treeItem,
        name: cmpdDisplayName(compound),
        isActive,
        compound,
        isVisible: workspace.isVisible(compound),
        isSelected: workspace.isSelected(treeItem),
        info: compoundInfoTooltip(compound) || false,
        // info: <>This compound is {!compound.isLigand() ? 'not ' : ''}a ligand.</>,
        type: 'leaf',
    };
}

function compoundInfoTooltip(compound) {
    const heritage = compound.getHeritage();
    const svg = compound.getSvg();
    const img = (svg && svg !== 'failed')
        ? <img alt={`2D Diagram of ${compound.resSpec}`} src={svgImgSrc(compound.getSvg())} />
        : false;

    const hInfo = (h) => (
        <>
            <div>
                {h.label}
                {!!h.detailText && (
                <span style={{ fontSize: 'smaller' }}>
                    {' '}
                    (
                    {h.detailText}
                    )
                </span>
                )}
            </div>
            <div>
                <i className="fa fa-long-arrow-right" />
                {' '}
                {h.self.resSpec}
            </div>
        </>
    );
    return (
        <div className="compoundHeritage">
            <div>{compound.resSpec}</div>
            <div>{img}</div>
            <div style={{ fontWeight: 'bold' }}>Cmpd. Derivation</div>
            <ol>
                {heritage.map((h, i) => (
                    <li key={`${compound.resSpec}; heritage ${i.toString()}`}>{hInfo(h)}</li>
                ))}
            </ol>
        </div>
    );
}

function groupTreeItem(treeItem, name, state, children) {
    return {
        treeItem,
        name,
        isVisible: state[MolAtomGroupState.Visible],
        isSelected: state[MolAtomGroupState.Selected],
        isExpanded: !state[MolAtomGroupState.Collapsed],
        children,
        info: treeItem.info || false,
        type: 'group',
    };
}

/**
 * @description Return a list of action objects to be passed into Tree control
 * @param {*} actions List of custom action objects: {
 *      title: string label for the menu item
 *      icon: string css class for the menu item icon (font awesome)
 *      isVisible: optional function taking an item of interest
 *          and returning if it should be visible.
 *          ex: restrict action availability to compounds which are ligands
 *      action: function operating on the item of interest to perform the action
 * }
 * @param {*} getItem Function to extract item of interest from tree item (eg compound)
 * @param {*} isVisible Function taking a tree item and returning if the action should be visible
 * @returns A list of action objects ready for tree control: {
 *      title: string label for the menu item
 *      icon: string css class for the menu item icon (font awesome)
 *      isHidden: function taking a tree item and returning if the menu item should be hidden
 *      onClick: function taking tree item to perform the action
 * }
 */

// Protein components
function ProteinControl({ proteinInfo }) {
    return (
        <InfoItem
            className="proteinOpener"
            control={<ChooseProteinButton proteinInfo={proteinInfo} />}
            content={<LoadedProteinInfo proteinInfo={proteinInfo} />}
        />
    );
}

function ChooseProteinButton({ proteinInfo }) {
    const { mainProtein: info } = proteinInfo;
    const label = info ? 'Protein' : 'Choose Protein';
    return (
        <button
            type="button"
            className="generalButton infoUnderline infoHoverable tooltip"
            title="Select a new protein"
            onClick={() => { UserActions.OpenMapSelector('toggle'); UserActions.DumpWorkspace(); }}
        >
            <InfoIcon faClass="fa-folder-open" />
            {label}
        </button>
    );
}

function LoadedProteinInfo({ proteinInfo }) {
    const { mainProtein: info } = proteinInfo;
    if (!info) return false;

    // The name here is really a caseID which isn't meaningful to the user, so just
    // provide the name and PDB id.
    const pdesc = info.description;
    const protDisplayName = info.getLongName();
    const { url, label: linkLabel, title: linkTitle } = info.myExternalLinkInfo() || {};

    const title = pdesc || 'Protein name';

    return (
        <span className="proteinInfo">
            <span
                className="proteinName tootip"
                title={title}
            >
                {protDisplayName}
            </span>
            {
                !!(url)
                && (
                <sup>
                    <a href={url} target="_blank" rel="noreferrer" className="pdb-link" title={linkTitle}>
                        {linkLabel}
                        <i className="fa fa-external-link" />
                    </a>
                </sup>
                )
            }
        </span>
    );
}

// Compound components
function CompoundControl(props) {
    return (
        <InfoItem
            className="compoundControl"
            control={<AddCompoundButton />}
            content={<></>}
        />
    );
}

function AddCompoundButton() {
    return (
        <button
            type="button"
            className="generalButton infoUnderline infoHoverable addCompoundButton tooltip"
            title="Add a new compound"
            onClick={() => UserActions.OpenImport()}
        >
            <InfoIcon faClass="fa-plus" />
            Add Compound
        </button>
    );
}

// General components
function InfoIcon({ faClass }) {
    return <span className="infoLabelIcon"><i className={`fa ${faClass}`} /></span>;
}

function InfoItem({ className, control, content }) {
    return (
        <div className={className}>
            <span className="infoLabel">{control}</span>
            <span className="infoItem">{content}</span>
        </div>
    );
}
