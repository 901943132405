import React, { useRef, useEffect } from 'react';
import { updateTooltipster } from './ui_utils';

const renderDebug = false;

export function ClearableTextInput({
    placeholder, label, value, onChange, id, style={}, className, focus,
}) {
    const inputElt = useRef(null);

    useEffect(() => {
        if (focus && inputElt && inputElt.current) {
            inputElt.current.focus();
        }
    });

    const inputStyle = !value ? { fontStyle: 'italic' } : {}; // italicize placeholder

    return (
        <div id={id} style={style} className={`clearabletext ${className || ''}`}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
                <span className={label ? 'clearabletext-desc' : ''}>{label}</span>
                <input
                    ref={inputElt}
                    placeholder={placeholder}
                    style={inputStyle}
                    type="text"
                    className="clearabletext-input"
                    value={value}
                    onChange={(evt) => onChange(evt.target.value)}
                />
                {!!value
                    && (
                    <button
                        aria-label="Clear textfield"
                        type="button"
                        className="clearabletext-clearbtn"
                        tabIndex={0}
                        onClick={() => onChange('')}
                        onKeyPress={() => onChange('')}
                        style={{ background: 'transparent', border: 'none', height: '100%' }}
                    >
                        <i className="fa fa-times" />
                    </button>
                    )}
            </label>
        </div>
    );
}

export function Slider(props) {
    const {
        eltId, style, value, step=1, min=0, max, onChange, tooltip,
    } = props;

    useEffect(() => {
        updateTooltipster({
            [`#${eltId}`]: {
                debug: true,
                side: 'right',
                trigger: 'custom',
                triggerOpen: {
                    mouseenter: true, click: true, tap: true, touchstart: true,
                },
                triggerClose: { mouseleave: true },
                updateAnimation: null,
                delay: 0,
            },
        });
    });

    if (renderDebug) console.log(`Rendering Slider ${eltId}`);

    return (
        /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
        <label id={eltId} style={style} className="slider" title={tooltip}>
            <input
                style={{ flex: '1 0 auto' }}
                type="range"
                value={value}
                min={min}
                max={max}
                step={step}
                onChange={(evt) => onChange(evt.target.value)}
            />
        </label>
    );
}

export function EnergySlider(props) {
    const {
        value, min, max, onChange, ...rest
    } = props;
    // -1 factors are because the slider wants to be -0 on the left and -10 on the right.
    // Everything going into or coming out of the slider is negated
    return (
        <Slider
            value={-1 * value}
            min={-1 * max}
            max={-1 * min}
            onChange={(val) => onChange(-1 * parseFloat(val))}
            {...rest}
        />
    );
}

/**
 *  Note: unexpected styling may occur if this is used outside of #info_display.
 * .generalButton css class (on the manage button) only has rules inside #info_display
 */
export function TreeEnergySlider(props) {
    const {
        title, label, enabled=true, notEnabledText,
        sliderProps, showValue=true, onManage, manageTitle,
    } = props;

    useEffect(() => {
        updateTooltipster({
            '.treeEnergyFilter > div:nth-of-type(2)': {
                debug: true,
                side: 'right',
            },
            '.filterManageBtn': {
                side: 'right',
            },
        });
    });

    return (
        <div className="treeEnergyFilter">
            <div className="treeEnergyFilterLabel">
                {label}
                { !!onManage
                    && (
                        <button
                            type="button"
                            onClick={() => onManage()}
                            className="generalButton filterManageBtn"
                            title={manageTitle}
                        >
                            <i className="fa fa-cog" />
                        </button>
                    )}
            </div>
            { enabled ? (
                <div className="treeEnergyFilterSlider" title={title}>
                    <EnergySlider
                        {...sliderProps}
                    />
                    <span>
                        {' '}
                        { !!showValue && sliderProps.value.toFixed(1) }
                    </span>
                </div>
            ) : (
                <div style={{ fontStyle: 'italic' }} title={title}>{notEnabledText}</div>
            )}
        </div>
    );
}
