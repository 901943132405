/**
 * cmds_common.js
 * @fileoverview These functions perform operations on server response data that have model
 * side effects, and are thus beyond the scope of server_responders.
 * They are needed for various UserActions in different files, so they are collected here.
 * */

import { App } from '../BMapsApp';
import { ResponseIds } from '../server/server_common';
import { EnergyInfo, applyEnergiesToCompound, applySolvationToCompound } from '../model/energyinfo';
import { CompoundColorMap } from '../themes';
import { EventBroker } from '../eventbroker';
import { MolAtomGroupFactory } from '../model/AtomGroupFactory';
import { DecodedFragment, AtomGroupTypes } from '../model/atomgroups';
import { initiateGiFERequest, prepareGiFEGroups } from './GiFEHelpers';
import { ensureArray } from '../util/js_utils';
import { Loader } from '../Loader';
import { getUseAutoGiFE } from '../redux/prefs/access';

/**
 * Necessary functions on inbound compound response data:
 * - Assigning default colors
 * - Updating 2d info and mol properties
 * @param {*} responseData
 */
export function integrateCompounds(responseData, caseData) {
    // Gather compounds and update the colors of atoms
    const compounds = [];
    const [solute] = responseData.byId(ResponseIds.Solute);
    if (solute?.compounds) {
        compounds.push(...solute.compounds);
    }
    compounds.push(...responseData.byId([ResponseIds.Compound, ResponseIds.DockResults]));
    for (const compound of compounds) {
        for (const atom of compound.getAtoms()) {
            // Assign atom.defaultColorMap, used by 3dmol_interface to override default colors.
            atom.defaultColorMap = CompoundColorMap;
        }
    }
    EventBroker.publish('redisplayRequest');

    // Apply inbound compound 2d info to workspace compounds
    const compound2Ds = responseData.byId(ResponseIds.Compound2D);
    for (const { compoundSpec, smiles, molData } of compound2Ds) {
        const compound = caseData.getCompoundBySpec(compoundSpec);

        // console.log(`2D smiles '${smiles}'`);
        // console.log(`2D mol '${data}'`);
        if (compound) {
            compound.setSmiles(smiles);
            compound.setMol2000(molData);
            compound.updateSvg('mol');
            compound.updateMolProps();
        }
    }
    if (Loader.AllowLabFeatures && getUseAutoGiFE()) {
        updateGifeEnergiesForCompounds(compounds);
    }
}

/**
 * Update model after requesting forcefield params.
 * The atom info maps in the decoder have already been updated when the atomgroupinfo packet
 * was received in response to the FF param request.
 * But we need to sync any existing compounds with the updated params in the atom info map.
 * @param {ResponseData} responseData
 * @param {Compound} compound
 */
export function integrateForcefieldParams(responseData, compound) {
    const successful = [];
    const errors = [];

    const [ffCmpdSpec] = responseData.byId(ResponseIds.ForcefieldParamsForLigand);

    if (responseData.errors.length > 0) {
        for (const error of responseData.errors) {
            console.error(`Error getting FF params for ${compound.resSpec}: ${error}`);
            errors.push(error);
        }
        const msg = `Forcefield parameters failed. Technical detail: ${errors.join('; ')}`;
        compound.energyInfo.updateForcefieldRequest(false, msg);
    }

    if (ffCmpdSpec === compound.resSpec) {
        successful.push(compound);
        const { caseDataCollection } = App.getDataParents(compound);

        // If FF params changed, make sure existing compounds are updated with the new values
        const updatedCmpds = caseDataCollection.atomInfoMaps.syncCompoundsWithAtomInfo(compound);
        if (updatedCmpds.includes(compound)) {
            console.log(`Updated atom info for ${compound.resSpec} after forcefield param request`);
        }
        compound.energyInfo.updateForcefieldRequest(true);
    } else if (ffCmpdSpec) {
        console.warn(`Received forcefield parameters for unrecognized ligand ${ffCmpdSpec}`);
    }

    if (compound.energyInfo.forcefieldParamStatus === EnergyInfo.States.working) {
        console.error(`FF Params unresolved for ${compound.resSpec}`);
        const msg = 'Forcefield parameters failed to be applied, perhaps because of compound id confusion.';
        compound.energyInfo.updateForcefieldRequest(false, msg);
    }

    return { compounds: successful, errors };
}

/**
 * Incorporate inbound water map and / or cluster map data into Workspace / CaseData,
 * returning newly created AtomGroups if requested
 * @param { ResponseData } responseData - all the data returned for the command
 * @param { CaseData } caseData - The CaseData associated with the request
 * @param { boolean } returnAGs - Whether or not to return newly created atomgroups
 * @returns { Array } Newly created atom groups if requested
 *
 * Note: returnAGs is false by default for the water map case; no point in building up large
 * arrays of waters if we don't need them.
 */
export function integrateFragments(responseData, caseData, returnAGs=false) {
    // The packets in these lists have all been prepared by server_responders receiveFragmentPacket
    const waterMapPackets = responseData.byId(ResponseIds.WaterMap);
    const clusterMapPackets = responseData.byId(ResponseIds.ClusterMap);
    const fragmentMapPackets = responseData.byId(ResponseIds.FragmentMap);
    const projectCase = caseData?.mapCase?.projectCase;

    const results = { water: [], cluster: [], fragmentmap: [] };
    // Fragments
    for (const [packetList, fragType] of [
        [waterMapPackets, 'water'],
        [clusterMapPackets, 'cluster'],
        [fragmentMapPackets, 'fragmentmap'],
    ]) {
        for (const packet of packetList) {
            const { caseArgs, fragments } = packet; // per receiveFragmentPacket
            if (caseArgs === projectCase) {
                const newAGs = processFragments(caseData, fragType, fragments, returnAGs);
                results[fragType].push(...newAGs);
            } else {
                console.warn(`Attempting to integrate fragments with unexpected case: ${caseArgs}. Expected: ${projectCase}`);
            }
        }
    }
    return results;
}

/**
 * Process one set of fragment data and integrate into workspace:
 * - Add atoms to the 3D display
 * - Create atom groups as needed
 * - Add AtomGroups to caseData as needed
 * Return the new atom groups if requested.
 * @param {CaseData} caseData
 * @param {string} type type defined in integrateFragments
 * @param {DecodedFragment[]} fragments fragments decoded in receiveFragmentPacket
 * @param {boolean} returnAGs whether or not to return the newly created atom groups
 *
 * @todo Consider creating the atom groups in server_responders
 */
function processFragments(caseData, type, fragments, returnAGs=false) {
    const newAGs = [];
    if (type === 'cluster') {
        App.Workspace.setHotspotFragments(caseData, fragments); // creates Hotspots and adds to case
        if (returnAGs) newAGs.push(...caseData.getHotspots());
    } else {
        for (const fragment of fragments) {
            const atomGroup = MolAtomGroupFactory.CreateAtomGroup(fragment);
            caseData.addAtomGroup(atomGroup);
            if (returnAGs) newAGs.push(atomGroup);
        }
    }
    return newAGs;
}

/**
 * Calls the individual integration methods for any energy or solvation data
 * @param {*} responseData
 * @param {*} caseData
 * @returns
 */
export function integrateEnergies(responseData, caseData) {
    const results = [];
    const energyPackets = responseData.byId(
        [ResponseIds.EnergiesForLigand, ResponseIds.EnergiesForLigandText]
    );
    for (const packet of energyPackets) {
        const result = integrateEnergy(packet, caseData);
        if (result) {
            results.push(result);
        }
    }
    const solvationPackets = responseData.byId(
        [ResponseIds.SolvationForLigand, ResponseIds.SolvationForLigandText]
    );

    for (const packet of solvationPackets) {
        const result = integrateSolvation(packet, caseData);
        if (result) {
            results.push(result);
        }
    }

    return results;
}

/**
 * Apply inbound energy data to compound
 * @param {*} param0
 * @param {*} caseData
 * @returns
 */
function integrateEnergy({
    compoundSpec, internalEnergies, interactionEnergies, raw,
}, caseData) {
    const compound = caseData.getCompoundBySpec(compoundSpec);
    if (compound) {
        const energies = { interactionEnergies, internalEnergies, raw };
        const {
            vdw, coulomb, hbonds, stress, total, worstTorsion,
        } = applyEnergiesToCompound(compound, energies, caseData);

        // Note: this call to updateEnergyEfficiency is in both doReceiveEnergiesForLigand and
        // doReceiveSolvationForLigand, since they could come in either order.
        // I considered moving this to energyMinimize in UserActions, but then it would also
        // need to be applied to other scenarios, like GetCaseFiles and GetStarterCompounds.
        compound.updateEnergyEfficiency();
        // console.log(`The worst torsion is ${worstTorsion.join(',')}.`);
        return {
            compound, cmpdSpec: compoundSpec, vdw, coulomb, hbonds, stress, total,
        };
    }
    console.warn(`integrateEnergy can't find compound ${compoundSpec}`);
    return null;
}

/**
 * Apply inbound solvation data to compound
 * @param {*} param0
 * @param {*} caseData
 * @returns
 */
function integrateSolvation({ compoundSpec, solvData }, caseData) {
    const cmpd = caseData.getCompoundBySpec(compoundSpec);

    if (cmpd) {
        const { ddGs } = applySolvationToCompound(cmpd, solvData, caseData);

        // Note: this call to updateEnergyEfficiency is in both doReceiveEnergiesForLigand and
        // doReceiveSolvationForLigand, since they could come in either order.
        // I considered moving this to energyMinimize in UserActions, but then it would also
        // need to be applied to other scenarios, like GetCaseFiles and GetStarterCompounds.
        cmpd.updateEnergyEfficiency();
        return { cmpdSpec: compoundSpec, ddGs };
    }
    console.warn(`integrateSolvation can't find compound ${compoundSpec}`);
    return null;
}

/**
 * updateGifeEnergiesForCompounds
 * Call GiFE for the specified compounds, using the entire solute (protein+cofactor) for
 * each compound.
 */
async function updateGifeEnergiesForCompounds(compoundsIn) {
    const compounds = ensureArray(compoundsIn);
    const { dataParentsMap } = App.partitionByDataParents(compounds);

    for (const [dataParents, cmpds] of dataParentsMap.entries()) {
        const { caseData } = dataParents;
        const solute = caseData.getSolute()
            .filter((x) => x.type !== AtomGroupTypes.Ion); // ions not supported by GiFE yet
        const soluteCharge = totalFormalCharge(solute);

        for (const cmpd of cmpds) {
            if (cmpd.formalCharge || soluteCharge) {
                const errorMsg = 'GiFE energies are not yet available for charged molecules';
                cmpd.energyInfo.energyError(EnergyInfo.ExtraTypes.GiFE, errorMsg);
                continue;
            }

            if (solute.length === 0) {
                const errorMsg = 'GiFE energies are not yet available for compounds without proteins';
                cmpd.energyInfo.energyError(EnergyInfo.ExtraTypes.GiFE, errorMsg);
                continue;
            }

            cmpd.energyInfo.energyRequested(EnergyInfo.ExtraTypes.GiFE);
            const gifeGroups = prepareGiFEGroups(null, [cmpd, ...solute]);
            const { GiFEDisplayResults, errors } = await initiateGiFERequest(gifeGroups);
            if (GiFEDisplayResults?.length > 0) {
                const { value, error, warning } = GiFEDisplayResults[0];
                if (error) {
                    cmpd.energyInfo.energyError(EnergyInfo.ExtraTypes.GiFE, error);
                } else {
                    cmpd.energyInfo.setEnergy(EnergyInfo.ExtraTypes.GiFE, value);
                }
            } else {
                const error = errors || 'GiFE failed to produce a result';
                cmpd.energyInfo.energyError(EnergyInfo.ExtraTypes.GiFE, error);
            }
            EventBroker.publish('energyCalc'); // refresh energy display
        }
    }
}

/**
 * totalFormalCharge
 * Helper function to sum up formal charges in a list of atom groups.
 * Protein and Polymer atom groups themselves have a list of atom groups (residues),
 * so this is recursively called to report their formal charges.
 * The solute decoding and loading process should be updated to sum up the residue charges
 * for polymers (decode.js, AtomGroupFactory.js)
 */
function totalFormalCharge(atomGroups) {
    return atomGroups.reduce(
        (acc, next) => {
            const myCharge = next.formalCharge || 0;
            if (!next.getResidues) {
                return acc + myCharge;
            }
            const myResiduesCharge = totalFormalCharge(next.getResidues());
            if (myCharge !== myResiduesCharge) {
                console.warn(`Formal charges not the same for ${next.resSpec} and residues`);
            }
            return acc + myResiduesCharge;
        },
        0
    );
}
