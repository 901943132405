/* display_tools.js
 *
 * Display HBonds, water halos, ddGs halos, hydrophobic interaction surfaces, etc.
 *
 */

import { App } from './BMapsApp';
import * as Data from './project_data';
import { findHbonds, defaultHBondParams }
    from './hbonds';
import { isComputedWaterAtom } from './utils';
import { getFullResidueId } from './util/mol_info_utils';
import { simpleCond } from './util/js_utils';
import { pointDistance } from './util/atom_distance_utils';
import { EventBroker } from './eventbroker';
import {
    AngleBetweenPoints, DegreesFromRadians, Add3_3, Sub3_3, Avg3, Mul3_1,
    Normalize3, Cross3,
}
    from './math';
import {
    drawCylinder, removeCylinder, drawArrow, removeShape,
    drawSphere, removeSphere, drawSurface, removeSurface,
    atomIsVisible, drawLabel, removeLabel,
}
    from './MainCanvas';
import { calculateDockingBox } from './data_tools';
import { hydrogenCheck } from './util/display_utils';
import { Hotspot } from './model/atomgroups';
import { isHydrogen } from './util/chem_utils';

// Subscribe to events to implement display updates.
EventBroker.subscribe('zapAll', onZapAll);
EventBroker.subscribe('displayBondVectors', ondisplayBondVectors);

// Water halo thresholds are after the 5.5 kcal/mol bulk water value is subtracted.
// The moderateThreshold is thus -3 kcal/mol (or, roughly, 2.5x thermal noise) and the
// strongThreshold is -6 kcal/mol. [Might need further tuning.]
const waterHaloParams = {
    moderateColor: 0x0aff0a, // green
    neutralColor: 0xc3c5c9, // gray
    strongColor: 0xcc00cc, // magenta (red would be confused with crystal waters oxygen red)
    strongThreshold: -6,
    moderateThreshold: -3,
};

const hydrophobicityColorParams = {
    moderateColor: 0x0aff0a,
    neutralColor: 0xc3c5c9,
    strongColor: 0xff0a0a,
    strongThreshold: -0.2,
    moderateThreshold: 1,
};

// These are per residue.
const ddGsProteinColorParams = {
    moderateColor: 0x0aff0a,
    neutralColor: 0xc3c5c9,
    strongColor: 0xff0a0a,
    strongThreshold: 10,
    moderateThreshold: 3,
};

// These are for functional groups, so scale appropriately.
const ddGsCompoundColorParams = {
    moderateColor: 0x0aff0a,
    neutralColor: 0xc3c5c9,
    strongColor: 0xff0a0a,
    strongThreshold: 10,
    moderateThreshold: 3,
};

// Compare the value against the param thresholds and return the corresponding color.
// If the strongThreshold < moderateThreshold then negative is bad.
// If the strongThreshold > moderateThreshold then negative is good.
// If the value is null, then something went wrong.  Return a transparent white color
// to avoid misleading the user with red/gren
function getColorFromThresholds(value, params) {
    if (value === null) return 0xffffffff;

    if (params.strongThreshold < params.moderateThreshold) { // negative is bad
        return simpleCond([
            [value < params.strongThreshold, params.strongColor],
            [value < params.moderateThreshold, params.neutralColor],
            [true, params.moderateColor],
        ]);
    } else { // negative is good
        return simpleCond([
            [value < params.moderateThreshold, params.moderateColor],
            [value < params.strongThreshold, params.neutralColor],
            [true, params.strongColor],
        ]);
    }
}

function getThresholdString(value, params) {
    const p = params;
    if (p.strongThreshold < p.moderateThreshold) { // negative is good
        if (value < p.strongThreshold) {
            return `< ${p.strongThreshold.toFixed(0)}`;
        } else if (value < p.moderateThreshold) {
            return `> ${p.strongThreshold.toFixed(0)} to < ${p.moderateThreshold.toFixed(0)}`;
        } else {
            return `> ${p.moderateThreshold.toFixed(0)} to < -1.2`;
        }
    } else { // negative is bad
        if (value < p.moderateThreshold) {
            return `> 1.2 to < ${p.moderateThreshold.toFixed(0)}`;
        } else if (value < p.strongThreshold) {
            return `> ${p.moderateThreshold.toFixed(0)} to < ${p.strongThreshold.toFixed(0)}`;
        } else {
            return `> ${p.strongThreshold.toFixed(0)}`;
        }
    }
}

/// Event Handlers ///

function onZapAll(event, data) {
    removeHBonds(hbondLines.bindingSite);
    removeWaterHalos();
    removeFunctionalGroupHalos();
    removeHotspots();
}

function ondisplayBondVectors(eventName, { vectors, options, hideAll }={}) {
    if (hideAll) {
        hideAllBondVectors();
    } else {
        drawBondVectors(vectors, options);
    }
    EventBroker.publish('redisplayRequest');
}

/// Bond Vectors ///

let visibleBondVectorShapes = [];
function hideAllBondVectors() {
    if (visibleBondVectorShapes.length > 0) {
        for (const shape of visibleBondVectorShapes) {
            removeShape(shape);
        }
        visibleBondVectorShapes = [];
    }
}

function drawBondVectors(bondVectors, { displayHydrogens }) {
    // Remove existing bond vector highlights before showing any.
    // This behavior could be changed if we need to show / hide at a more granular level
    hideAllBondVectors();

    /** Is an atom currently hidden because of Hydrogen display settings? */
    const isHiddenHydrogen = (atom) => isHydrogen(atom) && !hydrogenCheck(atom, displayHydrogens);

    for (const { atomPair, options } of bondVectors) {
        const [tailAtom, headAtom] = atomPair;
        if (headAtom === undefined || headAtom === tailAtom) return;

        const visibleHead = !isHiddenHydrogen(headAtom);

        const color = options.color || 'cyan';

        const posToObj = (vec) => ({ x: vec[0], y: vec[1], z: vec[2] });
        const unitVec = (vec1, vec2) => Normalize3(Sub3_3(vec1, vec2));

        // Bond sticks are, by default, 0.25, so make the cylinder smaller
        const radius = 0.15;
        const offset = 0.75;
        const headDiamMult = 1.6;
        const opacity = 1.0; // from monitors.tcl
        const headPos = headAtom.getPosition();
        const tailPos = tailAtom.getPosition();

        // Next, find a plane made by vectors to adjacent atoms, and use the normal to the
        // plane to provide offsets for the arrow vector.  But consider colinear
        // configurations.
        let vertexAtom = headAtom.bondedAtoms.length === 1 ? tailAtom : headAtom;
        let connected = vertexAtom.bondedAtoms.filter((x) => x.bondedAtoms.length > 1);
        if (connected.length === 1) {
            // Might be methyl, amine, etc. terminal atom
            vertexAtom = connected[0]; // generally the tail
            connected = vertexAtom.bondedAtoms;
        }
        let normVec = [0, 0, 0]; // default to no offset
        const vertexPos = vertexAtom.getPosition();
        if (connected.length >= 2) {
            const vec1 = unitVec(connected[0].getPosition(), vertexPos);
            const vec2 = unitVec(connected[1].getPosition(), vertexPos);
            normVec = Cross3(vec1, vec2);
        } else {
            const newz = Math.sqrt(vertexPos[0]**2 + vertexPos[1]**2);
            normVec = unitVec([-vertexPos[0], -vertexPos[1], newz]);
        }

        // If the bond vector is toward a visible atom, then display two bond vector
        // arrows, offset to each side, so they don't conflict with the atoms or bond.
        // If the bond vector is toward an invisible atom, then display a
        // single bond vector arrow, offset in the direction of the arrow,
        // so the arrow's tail isn't hidden in the tail atom's sphere.
        let arrow1HeadPos;
        let arrow1TailPos;
        let arrow2HeadPos;
        let arrow2TailPos;
        if (visibleHead) {
            arrow1HeadPos = Add3_3(headPos, Mul3_1(normVec, offset));
            arrow1TailPos = Add3_3(tailPos, Mul3_1(normVec, offset));
            arrow2HeadPos = Add3_3(headPos, Mul3_1(normVec, -offset));
            arrow2TailPos = Add3_3(tailPos, Mul3_1(normVec, -offset));
        } else {
            const unitOffset = 0.5;
            const unitVector = unitVec(headPos, tailPos);
            arrow1HeadPos = Add3_3(headPos, Mul3_1(unitVector, unitOffset));
            arrow1TailPos = Add3_3(tailPos, Mul3_1(unitVector, unitOffset));
        }

        const shapeOptions = {
            opacity,
            description: options.description || '',
            onClick: options.onClick || null,
            object: options.object || null,
        };
        const arrow1 = drawArrow(
            posToObj(arrow1TailPos), posToObj(arrow1HeadPos),
            radius, color, headDiamMult, shapeOptions
        );
        visibleBondVectorShapes.push(arrow1);
        if (visibleHead) {
            const arrow2 = drawArrow(
                posToObj(arrow2TailPos), posToObj(arrow2HeadPos),
                radius, color, headDiamMult, shapeOptions
            );
            visibleBondVectorShapes.push(arrow2);
        }
    }
}

/// Solvation Energy ///

const functionalGroupHalos = new Map();

// Get the solvation energy for a protein atom's residue.
// This is called atom-by-atom to create the highlighting surface.
// The interface could be improved:
// Passing in compound instead of accessing getActiveCompound.
function soluteSolvationEnergy(atom) {
    // What to do if the calculation fails? 0 ends up with a green highlight
    let solvEnergy = null;

    const cmpd = Data.getActiveCompound();
    const activeCompoundSpec = cmpd && cmpd.resSpec;
    if (!(activeCompoundSpec && atom.residue)) return solvEnergy;
    const solvEntry = atom.residue.solvInfoMap && atom.residue.solvInfoMap.get(activeCompoundSpec);
    if (solvEntry) {
        solvEnergy = solvEntry.ddGs; // assumes entry is defined
    } else {
        console.warn(`Missing ddGs for ${cmpd.resSpec} <-> ${getFullResidueId(atom)}`);
    }
    return solvEnergy;
}

export function soluteSolvationSurfaceColor(atom) {
    const ddgs = soluteSolvationEnergy(atom);
    const color = getColorFromThresholds(ddgs, ddGsProteinColorParams);
    // console.log(`protein ddgs ${ddgs.toFixed(3)}, color ${color.toString(16)}`);
    return color;
}

// Change the display of functional group halos
export function displayFunctionalGroupEnergyHalos(show, functionalGroups) {
    if (show) {
        for (const group of functionalGroups) {
            const energy = getFunctionalGroupEnergy(group);
            const energyColor = getColorFromThresholds(energy, ddGsCompoundColorParams);
            const debugMsg = `FunctionalGroup (${group.map((x) => x.atom).join()}) has solvation ${energy.toFixed(4)} and halo color ${energyColor}.`;
            // console.log(debugMsg);
            displayOneFunctionalGroupHalo(group, energyColor, 'energy', energy);
        }
    } else {
        removeFunctionalGroupHalos('energy');
    }
}

export function displayFunctionalGroupHighlight(show, functionalGroups) {
    if (show) {
        for (const group of functionalGroups) {
            const energyColor = 'white';
            displayOneFunctionalGroupHalo(group, energyColor, 'highlight');
        }
    } else {
        removeFunctionalGroupHalos('highlight');
    }
}

function removeFunctionalGroupHalos(label) {
    functionalGroupHalos.forEach((sphereId, key) => {
        if (key.startsWith(`${label}-`)) {
            removeSphere(sphereId);
            functionalGroupHalos.delete(key);
        }
    });
}

function displayOneFunctionalGroupHalo(functionalGroup, color, label, ddGs) {
    const opacity = 0.6;
    const haloKey = `${label}-${functionalGroup[0].uniqueID}`;

    if (!functionalGroupHalos.get(haloKey)) {
        // Set center to the center of non-hydrogens
        const center = { x: 0, y: 0, z: 0 };
        const nonHs = functionalGroup.filter((x) => x.elem !== 'H');
        // Use reduce to add the coordinates together for average
        center.x = nonHs.reduce((sum, nextAtom) => sum + nextAtom.getX(), 0) / nonHs.length;
        center.y = nonHs.reduce((sum, nextAtom) => sum + nextAtom.getY(), 0) / nonHs.length;
        center.z = nonHs.reduce((sum, nextAtom) => sum + nextAtom.getZ(), 0) / nonHs.length;

        // Set radius according to number of atoms
        let radius = 1;
        if (functionalGroup.length === 2 || functionalGroup.length === 3
            || (functionalGroup.length === 4 && nonHs.length === 1)) { // CH3 case; it's not so big
            radius = 1.5;
        } else if (functionalGroup.length === 4) {
            radius = 2;
        } else if (functionalGroup.length > 4) {
            radius = 2.5;
        }

        const sphereId = drawSphere(center, radius, color, opacity);

        if (ddGs !== undefined) {
            if (ddGs != null) {
                const modth = ddGsCompoundColorParams.moderateThreshold;
                const strth = ddGsCompoundColorParams.strongThreshold;
                const range = `Color range is green (< ${modth.toFixed(1)}), gray (mid-range), red (> ${strth.toFixed(1)})`;
                sphereId.description = `&Delta;&Delta;G<sub>s</sub> desolvation energy ${ddGs.toFixed(2)} kcal/mol<br>${range}`;
            } else {
                sphereId.description = '&Delta;&Delta;G<sub>s</sub> desolvation calculation failed for this functional group.';
            }
        } else {
            //--- someday provide the name of the functional group here.
            sphereId.description = 'Functional group highlight';
        }
        functionalGroupHalos.set(haloKey, sphereId);
    }
}

export function getFunctionalGroupEnergy(functionalGroup) {
    let energy = null;

    for (const atom of functionalGroup) {
        if (atom.dGs != null && atom.dGs_bound != null) {
            energy += (atom.dGs_bound - atom.dGs);
            const debugMsg = `Solvation for ${atom.atom}: ${atom.dGs_bound.toFixed(4)} - ${atom.dGs.toFixed(4)} = ${(atom.dGs_bound - atom.dGs).toFixed(4)}. Total: ${energy.toFixed(4)}`;
            // console.log(debugMsg);
        }
    }
    return energy;
}

/// Water Halos (colored by excess chemical potential) ///
const waterHalos = new Map();
let waterHaloSpheres = [];

export function displayWaterHalo(show, atoms, displayParams) {
    if (show) {
        for (const atom of atoms) {
            if (isComputedWaterAtom(atom)) {
                displayOneWaterHalo(atom, displayParams);
            }
        }
        console.log(`There are ${waterHalos.size} waters with halos.`);
    } else {
        removeWaterHalos();
    }
}

export function waterChemPotential(atom) {
    if (!atom || !atom.fragment) return 0;
    const excessCP = atom.fragment.exchemPotential || -5.5;
    return excessCP - (-5.5); // -5.5 is the CP of bulk water
}

export function displayOneWaterHalo(atom, displayParams, colorIn) {
    const isComputedWater = isComputedWaterAtom(atom);

    // Draw only one sphere per water molecule, around atom id "atomSerialNumberBase"
    // Display water fragment halo's or crystal water halo's.
    if (isComputedWater
          && (!atom.fragment.exchemPotential
            || atom.uniqueID !== atom.fragment.atomSerialNumberBase)) {
        return;
    }

    const wcp = isComputedWater ? waterChemPotential(atom) : 0;
    if (isComputedWater && wcp >= 0) return; // don't display transient waters
    let color;
    if (isComputedWater) {
        color = getColorFromThresholds(wcp, waterHaloParams);
    } else {
        color = colorIn && colorIn !== 'default' ? colorIn : 0xF00000; // red
    }

    // Only show red waters in Protein View
    if (isComputedWater && needToHideHalo(color, waterHaloParams, displayParams)) {
        return;
    }

    const opacity = isComputedWater ? 0.6 : 0.7;
    const radius = isComputedWater ? 1 : 1.2;
    if (!waterHalos.get(atom.uniqueID)) {
        const sphereId = drawSphere(atom.getPosition({ as: 'object' }), radius, color, opacity);
        waterHaloSpheres.push(sphereId);
        waterHalos.set(atom.uniqueID, sphereId);
        const threshold = getThresholdString(wcp, waterHaloParams);
        sphereId.description = isComputedWater
            ? `${wcp.toFixed(2)} kcal/mol (${threshold})`
            : 'crystal water';
        const caseLabel = App.Workspace.getCaseLabel(atom);
        if (caseLabel) sphereId.description = `${caseLabel} ${sphereId.description}`;
    } else {
        console.log(`already water halo for ${atom.uniqueID}`);
    }
}

// needToHideHalo()
// We want only the red waters to be visible in protein view mode.
// haloColor : color of the halo we might hide
// haloParams : halo threshold object and colors (we look at "strongColor")
// displayParams: we look at"onlyShowStrongWaterHalos"
function needToHideHalo(haloColor, haloParams, displayParams) {
    return displayParams && displayParams.onlyShowStrongWaterHalos
        && haloColor !== haloParams.strongColor;
}

function removeWaterHalos() {
    waterHaloSpheres.forEach((sphereId) => removeSphere(sphereId));
    waterHaloSpheres = [];
    waterHalos.clear();
}

/** @type {Map<Hotspot, { surface, label, color }} */
const hotspotInfo = new Map();
const nearbyHotspotDistance = 5;
const showHotspotHBonds = false;

// Display surface, hbonds, and label for a hotspot
export function displayOneHotspot(show, hotspot, color='white') {
    let existing = hotspotInfo.get(hotspot);

    // Remove if necessary
    if (existing) {
        const needToRedraw = existing.color !== color;
        if (!show || needToRedraw) {
            if (showHotspotHBonds) showHBondsForHotspot(false, hotspot);
            removeSurface(existing.surface);
            removeLabel(existing.label);
            hotspotInfo.delete(hotspot);
            existing = null;
        }
    }

    // Draw new hotspot
    if (show && !existing) {
        if (showHotspotHBonds) showHBondsForHotspot(true, hotspot);
        const surface = drawSurface({ atoms: hotspot.getAtoms(), colorFn: () => color });
        const label = drawLabel({
            text: `${hotspot.fragmentGroupNumber}`,
            options: { alignment: 'center' },
            centerAtomGroup: hotspot,
        });
        hotspotInfo.set(hotspot, { surface, label, color });
    }
}

/**
 * Remove any hotspot visualizer entities (surfaces and labels) for HSs that are no longer visible
 * @param {Hotspot[]} visibleHotspots
 */
export function cleanUpHotspots(visibleHotspots) {
    for (const hotspot of hotspotInfo.keys()) {
        if (!visibleHotspots.includes(hotspot)) {
            displayOneHotspot(false, hotspot);
        }
    }
}

function showHBondsForHotspot(show, hotspot) {
    let list = hbondLines.hotspots.get(hotspot);

    if (show) {
        const hbonds = findHbonds(hotspot.atoms, hotspot.solute);
        if (!list) {
            list = [];
            hbondLines.hotspots.set(hotspot, list);
            hbondsStyle(hbonds, list);
        }
    } else {
        if (list) {
            removeHBonds(list);
        }
    }
}

function removeHotspots() {
    removeHotspotSurfaces();
    hbondLines.hotspots.forEach((list) => removeHBonds(list));
}

function removeHotspotSurfaces() {
    for (const { surface, label } of hotspotInfo.values()) {
        removeSurface(surface);
        removeLabel(label);
    }
    hotspotInfo.clear();
}

export function atomArrayDifference(minuend, subtrahend) {
    const difference = [];
    const subtrahendSet = new Set(subtrahend);
    for (const atom of minuend) {
        if (!subtrahendSet.has(atom)) {
            difference.push(atom);
        }
    }

    return difference;
}

/// HBONDS ///

// HBonds that have been drawn (stored so they can be removed later)
let hbondLines = { bindingSite: [], hotspots: new Map() };

// Display (or redisplay) hydrogen bonds for the given *compound* atoms.
export function displayHBonds(show, includeWaters, includeWeak, contextBindingSiteIn=null) {
    let contextBindingSite = contextBindingSiteIn;
    // remove existing ones first
    removeHBonds(hbondLines.bindingSite);

    if (show) {
        if (contextBindingSite == null) {
            contextBindingSite = App.Workspace.getBindingSite();
        }
        if (contextBindingSite == null) {
            return;
        }

        const hbonds = hbondsCalc(contextBindingSite, includeWaters);
        hbondsStyle(hbonds, hbondLines.bindingSite, includeWeak);
    }
}

// Return potential hydrogen bonds for the given compound atoms in a list of the form:
//      [ [compoundAtom1, [1's partners...] ] , [ compoundAtom2, [2's partners...] ] ]
// This is specific to a compound in a binding site and saves the hbonds into the binding site
function hbondsCalc(contextBindingSiteIn, includeWaters, params=defaultHBondParams) {
    let contextBindingSite = contextBindingSiteIn;
    if (contextBindingSite == null) {
        contextBindingSite = App.Workspace.getBindingSite();
    }
    if (contextBindingSite == null) {
        return [];
    }

    const soluteAtoms = contextBindingSite.getSoluteAtoms(App.Workspace);
    let results;
    // Display waters or compound, but not both (gets too busy).
    if (includeWaters) {
        const waterAtoms = contextBindingSite.getComputedWaterAtoms(App.Workspace);
        results = findHbonds(waterAtoms, soluteAtoms, params);
    } else {
        const compoundAtoms = contextBindingSite.getVisibleCompoundAtoms(App.Workspace);
        results = findHbonds(compoundAtoms, soluteAtoms, params);
    }

    console.log(`There are ${results.length} ${includeWaters ? 'water' : 'compound'} atoms with ${results.map((x) => x[1].length)} hbonds for radius ${contextBindingSite.radius.toFixed(2)}.`);
    /*
    for (let hb of results) {
        const desc = a=>`${a.atom}.${a.uniqueID}`;
        const desc2 = hb[1]
            .map(r => `${desc(r[0])} en ${r[1].toFixed(2)}
            dist ${pointDistance(hb[0], r[0]).toFixed(2)}`)
            .join(" ");
        console.log(`${desc(hb[0])} => ${desc2}`);
    }
*/
    return results;
}

function hbondsStyle(hbonds, list, includeWeak) {
    for (const hb of hbonds) {
        const [atom, partners] = hb;
        if (!atomIsVisible(atom)) {
            // console.log(`atom ${atom.atom} has no style.`);
            continue; // don't display if hidden
        }
        for (const [partner, energy] of partners) {
            // Only draw a highlight if the partner atom is visible.
            if (atomIsVisible(partner) // not hidden
                  && ((includeWeak && energy < defaultHBondParams.threshold) // cutoff at 2kT?
                   || energy < defaultHBondParams.moderateThreshold)) {
                list.push(drawHBond(atom, partner, energy));
            }
        }
    }
}

function drawHBond(atom1, atom2, energy) {
    // There is some debate as to whether it is more intuitive for users to draw a
    // highlight from the donor or donor hydrogen to the acceptor.  It seems that the
    // latter is preferred, although the former gives more information about the relative
    // orientation of the dipoles (the ideal from a physics perspective is to highlight
    // the mid-point of each dipole).
    const donorHydrogen = atom1.elem === 'H' ? atom1 : atom2;
    const acceptor = donorHydrogen === atom1 ? atom2 : atom1;
    const donor = donorHydrogen.bondedAtoms[0];
    const dist = pointDistance(donorHydrogen, acceptor); // dipole mid-points would be better...
    const p = defaultHBondParams;
    const color = simpleCond([
        [energy <= p.strongThreshold, 'white'],
        [energy <= p.moderateThreshold, 'cyan'],
        [true, 'lime'],
    ]);
    const cyl = drawCylinder(
        donorHydrogen.getPosition({ as: 'object' }),
        acceptor.getPosition({ as: 'object' }),
        0.15, color, 1,
    );
    const threshold = getThresholdString(energy, p);
    cyl.description = `${donor.atom} - ${acceptor.atom}:  ${dist.toFixed(2)}A, ${energy.toFixed(2)} kcal/mol (${threshold}) `;
    return cyl;
}

function removeHBonds(lines) {
    for (const line of lines) {
        removeCylinder(line);
    }
    lines.splice(0, lines.length);
}

/// Hydrophobic Interaction ///
//  This scale is from https://en.wikipedia.org/wiki/Hydrophobicity_scales
//  Origin: http://blanco.biomol.uci.edu/hydrophobicity_scales.html

const HydrophobicityScale = {
    ALA: ['ALA', 0.17, 0.50, 0.33],
    ARG: ['ARG', 0.81, 1.81, 1.00],
    ASN: ['ASN', 0.42, 0.85, 0.43],
    ASP: ['ASP', 1.23, 3.64, 2.41],
    ASP0: ['ASP', -0.07, 0.43, 0.50],
    CYS: ['CYS', -0.24, -0.02, 0.22],
    GLN: ['GLN', 0.58, 0.77, 0.19],
    GLU: ['GLU', 2.02, 3.63, 1.61],
    GLU0: ['GLU', -0.01, 0.11, 0.12],
    GLY: ['GLY', 0.01, 1.15, 1.14],
    HIS: ['HIS', 0.96, 2.33, 1.37],
    HIS0: ['HIS', 0.17, 0.11, -0.06],
    ILE: ['ILE', -0.31, -1.12, -0.81],
    LEU: ['LEU', -0.56, -1.25, -0.69],
    LYS: ['LYS', 0.99, 2.80, 1.81],
    MET: ['MET', -0.23, -0.67, -0.44],
    PHE: ['PHE', -1.13, -1.71, -0.58],
    PRO: ['PRO', 0.45, 0.14, -0.31],
    SER: ['SER', 0.13, 0.46, 0.33],
    THR: ['THR', 0.14, 0.25, 0.11],
    TRP: ['TRP', -1.85, -2.09, -0.24],
    TYR: ['TYR', -0.94, -0.71, 0.23],
    VAL: ['VAL', 0.07, -0.46, -0.53],
};

function getResidueHydrophobicity(resn) {
    const hpData = HydrophobicityScale[resn];
    return hpData ? hpData[3] : null;
}

function getAtomHydrophobicity(atom) {
    return getResidueHydrophobicity(atom.resn);
}

function hydrophobicityEnergy(atom) {
    return getAtomHydrophobicity(atom);
}

export function hydrophobicitySurfaceColor(atom) {
    const energy = hydrophobicityEnergy(atom);
    return getColorFromThresholds(energy, hydrophobicityColorParams);
}

let dockingLines = [];
export function showDockingBox(show) {
    if (show) {
        // boundingBox = xmin xmax ymin ymax zmin zmax
        const cmpd = Data.getActiveCompound();
        const isProteinLoaded = App.Workspace.isProteinLoaded();
        const bb = calculateDockingBox(App.Workspace, cmpd).map(parseFloat);
        if (bb && bb.length >= 6 && isProteinLoaded) {
            const [xmin, xmax, ymin, ymax, zmin, zmax] =bb;

            const drawLine = function drawDockingLine(startx, starty, startz, endx, endy, endz) {
                dockingLines.push(
                    drawCylinder(
                        { x: startx, y: starty, z: startz },
                        { x: endx, y: endy, z: endz },
                        0.085, 'lime', 1,
                    ),
                );
            };

            // draw two faces and connect them
            // zmin face
            drawLine(xmin, ymin, zmin, xmax, ymin, zmin);
            drawLine(xmax, ymin, zmin, xmax, ymax, zmin);
            drawLine(xmax, ymax, zmin, xmin, ymax, zmin);
            drawLine(xmin, ymax, zmin, xmin, ymin, zmin);

            // zmax face
            drawLine(xmin, ymin, zmax, xmax, ymin, zmax);
            drawLine(xmax, ymin, zmax, xmax, ymax, zmax);
            drawLine(xmax, ymax, zmax, xmin, ymax, zmax);
            drawLine(xmin, ymax, zmax, xmin, ymin, zmax);

            // connect z faces
            drawLine(xmin, ymin, zmin, xmin, ymin, zmax);
            drawLine(xmax, ymin, zmin, xmax, ymin, zmax);
            drawLine(xmin, ymax, zmin, xmin, ymax, zmax);
            drawLine(xmax, ymax, zmin, xmax, ymax, zmax);
        } else {
            let message = '';
            if (!isProteinLoaded) message += 'No protein is loaded. Please choose a protein in order to dock or to view the docking box.';
            else if (!cmpd) message += 'No compound available for docking. Please import a compound in order to dock or to view the docking box.';
            else {
                message += 'This protein has no crystal ligands or hotspots to use for a docking reference.\n'
                + 'Please select an atom or residue to be the center of the bounding box before docking.';
            }
            jAlert(message, 'Display Docking Bounding Box');
        }
    } else {
        for (const line of dockingLines) {
            removeCylinder(line);
        }
        dockingLines = [];
    }
    EventBroker.publish('redisplayRequest');
}

/* TESTS */
function testThresholdColors() {
    const label = 'testThresholdColors';
    let failures = 0;
    let successes = 0;
    function assert(x, msg) { if (!x) { failures++; console.error(`${label} Assertion failed: ${msg}`); } else { successes++; } }

    const params1 = {
        moderateColor: 'red',
        neutralColor: 'green',
        strongColor: 'blue',
        strongThreshold: -5,
        moderateThreshold: 5,
    };

    const params2 = {
        moderateColor: 'green',
        neutralColor: 'yellow',
        strongColor: 'red',
        strongThreshold: 5,
        moderateThreshold: -5,
    };

    const params3 = {
        moderateColor: 'green',
        neutralColor: null,
        strongColor: 'red',
        strongThreshold: -4,
        moderateThreshold: 6,
    };

    const allps = [params1, params2, params3];
    let ref;

    for (let i = -10; i <= 10; i++) {
        if (i < -5) ref = ['blue', 'green', 'red'];
        else if (i === -5) ref = ['green', 'yellow', 'red'];
        else if (i < 5) ref = ['green', 'yellow', null];
        else if (i === 5) ref = ['red', 'red', null];
        else if (i > 5) ref = ['red', 'red', 'green'];

        for (const p of allps.keys()) {
            const calc = getColorFromThresholds(i, allps[p]);
            const myref = ref[p];
            assert(calc === myref, `Color for ${i} params${p} is not correct: ${calc} !== ${myref}`);
        }
    }

    if (failures === 0) {
        console.log(`${label} ${successes} TESTS PASSED`);
    } else {
        console.error(`${label} ${failures} / ${successes + failures} FAILED TESTS`);
    }
}

const runningTest = false;
if (runningTest) {
    testThresholdColors();
}
