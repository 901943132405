import styled from 'styled-components';
// Material UI
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// Local
import { Box } from '@mui/material';
import TabPanel from './TabPanel';

const Tabs = styled(MuiTabs)`
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    margin-bottom: 2px;
    flex-shrink: 0;
`;

/**
 *
 * @param {{
 *     tabs: [{
 *          id: "string - uniquely identifies the tab",
 *          label: "string | Component - for the tab's label",
 *          getContent: "Component - React component that renders the body of the tab",
 *          TabProps: "object - props passed to Tab component",
 *          TabPanelProps: "object - props passed to TabPanel component",
 *     }],
 *     parentClass: string,
 *     selected: boolean,
 *     setSelected: function(number): void
 *     children: ReactNode - rendered ABOVE the tab content
 * }} param0
 * @returns
 */
export default function TabController({
    tabs, parentClass, selected, setSelected, children, ...rest
}) {
    function handleChange(e, idx) {
        setSelected(idx);
    }

    return (
        <Box display="flex" flexDirection="column" overflow="hidden" {...rest}>
            <Tabs variant="scrollable" value={selected} onChange={handleChange}>
                {tabs.map(
                    ({ id, label, TabProps = {} }) => {
                        // By default, disabled tabs have pointer-events: none,
                        // which prevents the tooltip. Override this if necessary.
                        if (TabProps.disabled && TabProps.title) {
                            TabProps.style = { ...TabProps.style, pointerEvents: 'auto' };
                        }
                        return (
                            <Tab
                                key={id}
                                value={id}
                                label={label}
                                {...TabProps}
                            />
                        );
                    }
                )}
            </Tabs>
            {tabs.map(({
                id, getContent, TabPanelProps = {},
            }) => {
                const isSelected = id === selected;

                return (
                    <TabPanel
                        key={id}
                        selected={isSelected}
                        parentClass={parentClass}
                        {...TabPanelProps}
                    >
                        {children}
                        {getContent({ selected: isSelected })}
                    </TabPanel>
                );
            })}
        </Box>
    );
}
