import { useState, useEffect, useRef } from 'react';

export default function FileDragAndDrop({ children, onDrop }) {
    const visibleBorder = {
        borderWidth: 8,
        borderStyle: 'dashed',
        borderColor: 'rgba(50,205,50,1)',
        marginLeft: -8,
        marginRight: -8,
    };
    const invisibleBorder = { ...visibleBorder, borderColor: 'rgba(50,205,50,0)' };

    const dropRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false); // TODO: can we remove this?
    const [dragCounter, setDragCounter] = useState(0); // TODO: can we remove this?
    const [dropIndicatorStyle, setDropIndicatorStyle] = useState(invisibleBorder);
    let inOutCounter = 0;

    function handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function handleDragIn(e) {
        e.preventDefault();
        e.stopPropagation();
        setDropIndicatorStyle(visibleBorder);
        inOutCounter++;
        setDragCounter((prev) => prev + 1);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setIsDragging(true);
        }
    }
    function handleDragOut(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter((prev) => prev + 1); // Not used, but maybe should be - 1
        inOutCounter--;
        if (inOutCounter > 0) return;
        setDropIndicatorStyle(invisibleBorder);
        setIsDragging(false);
    }
    function handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        setDropIndicatorStyle(invisibleBorder);
        inOutCounter = 0;
        onDrop(e.dataTransfer.files);
    }

    useEffect(() => {
        const div = dropRef.current;
        div.addEventListener('dragenter', handleDragIn);
        div.addEventListener('dragleave', handleDragOut);
        div.addEventListener('dragover', handleDrag);
        div.addEventListener('drop', handleDrop);

        return () => {
            div.removeEventListener('dragenter', handleDragIn);
            div.removeEventListener('dragleave', handleDragOut);
            div.removeEventListener('dragover', handleDrag);
            div.removeEventListener('drop', handleDrop);
        };
    });

    return (
        <div style={dropIndicatorStyle} ref={dropRef}>
            {children}
        </div>
    );
}
