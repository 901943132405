import { App } from '../BMapsApp';
import { EventBroker } from '../eventbroker';
import { ResponseIds } from '../server/server_common';
import { UserCmd, UserActions } from './UserCmd';
import {
    MapCase, PdbImportCase, AlphaFoldImportCase, UserDataImportCase,
} from '../model/MapCase';
import { MolDataSource, showAlert } from '../utils';
import { getFullResidueId } from '../util/mol_info_utils';
import { Compound, CompoundHeritage } from '../model/compound';
import { MolAtomGroupFactory } from '../model/AtomGroupFactory';
import { Fragment, DecodedFragment } from '../model/atomgroups';
import {
    integrateCompounds, integrateEnergies, integrateFragments,
} from './cmds_common';
import { pointDistance } from '../util/atom_distance_utils';
import { defaultClashingTolerance, getClashingDistance } from '../util/clash_detection';
import { getPreferences } from '../redux/prefs/access';
import { ensureArray } from '../util/js_utils';
import { withPausedRedisplay } from '../util/display_utils';

export const ConnectedDataCmds = {
    ZapAll: new UserCmd('ZapAll', zapAll),
    ZapConnection: new UserCmd('ZapConnection', zapConnection),
    FetchMaps: new UserCmd('FetchMaps', fetchMaps),
    FetchConnectionMaps: new UserCmd('FetchConnectionMaps', fetchConnectionMaps),
    ChooseProtein: new UserCmd('ChooseProtein', chooseProtein),
    LoadMolecule: new UserCmd('LoadMolecule', loadMolecule),
    LoadMolData: new UserCmd('LoadMolData', loadMolData),
    AssembleCompound: new UserCmd('AssembleCompound', assembleCompound),
    Select: new UserCmd('Select', selectCmd),
    SelectAtom: new UserCmd('SelectAtom', selectAtom),
    ExportToFormat: new UserCmd('ExportToFormat', exportToFormat),
    ExportQueryToFormat: new UserCmd('ExportQueryToFormat', exportQueryToFormat),
    ExportCompoundsToFormat: new UserCmd('ExportCompoundsToFormat', exportCompoundsToFormat),
    ExportSelectionToFormat: new UserCmd('ExportSelectionToFormat', exportSelectionToFormat),
    LoadStarterCompounds: new UserCmd('LoadStarterCompounds', loadStarterCompounds),
    RemoveCompound: new UserCmd('RemoveCompound', removeCompound),
    RenameCompound: new UserCmd('RenameCompound', renameCompound),
    CopyCompound: new UserCmd('CopyCompound', copyCompound),
    RemoveConnection: new UserCmd('RemoveConnection', removeConnection),
    AlignProtein: new UserCmd('AlignProtein', alignProtein),
};

async function zapAll(usePromise=true) {
    const [primary, ...otherDataConnections] = App.DataConnections;
    await Promise.all([
        zapConnection(primary, usePromise),
        ...(otherDataConnections.map((dc) => App.ConnectionManager.removeDataConnection(dc))),
    ]);
    App.Workspace.zap();
}

function zapConnection(dataConnection, usePromise=true) {
    return dataConnection.zap(usePromise);
}

async function fetchMaps() {
    const mapLists = await Promise.all(
        App.DataConnections.map((dc) => fetchConnectionMaps(dc))
    );
    return mapLists.reduce((acc, next) => acc.concat(next), []);
}

async function fetchConnectionMaps(dataConnection) {
    const { connector, caseDataCollection } = dataConnection.get();
    console.time('Await cmdListMaps');
    const responseData = await connector.cmdListMaps();
    console.timeEnd('Await cmdListMaps');
    const allMapData = responseData.byId(ResponseIds.MapList);
    for (const mapData of allMapData) {
        caseDataCollection.loadMapData(mapData);
    }
    return caseDataCollection.getMapList();
}

async function chooseProtein(caseInfoIn, loadOptions={}, dataConnection=App.PrimaryDataConnection) {
    if (!loadOptions.keepExisting) {
        await zapAll();
    }

    const { connector, caseDataCollection } = dataConnection.get();
    let caseInfo = caseInfoIn;

    if (typeof (caseInfoIn) === 'string') {
        const uri = caseInfoIn;
        caseInfo = caseDataCollection.findMapByUri(uri);

        if (!caseInfo) {
            if (PdbImportCase.isUri(uri)) {
                caseInfo = new PdbImportCase(PdbImportCase.parseUri(uri).id);
            } else if (AlphaFoldImportCase.isUri(uri)) {
                caseInfo = new AlphaFoldImportCase(AlphaFoldImportCase.parseUri(uri).id);
            }
        }
    }

    if (caseInfo == null) {
        const msg = 'Map case not found';
        return { errors: [msg] };
    }

    const uri = caseInfo.uri;
    const loadOptionsForServer = serverLoadOptions(caseInfo, loadOptions);
    let caseHasFrags = false;
    let responseData;
    if (caseInfo instanceof PdbImportCase || caseInfo instanceof AlphaFoldImportCase) {
        responseData = await withPausedRedisplay(
            connector.cmdLoadPdbId({
                pdbId: caseInfo.pdbID,
                loadOptions: loadOptionsForServer,
            }),
        );
    } else if (caseInfo instanceof UserDataImportCase) {
        responseData = await withPausedRedisplay(connector.cmdLoadPdbString({
            data: caseInfo.data,
            pdbId: caseInfo.pdbID,
            moleculeName: caseInfo.molecule_name,
            fileType: caseInfo.data_format,
            loadOptions: loadOptionsForServer,
        }));
    } else {
        caseHasFrags = true;
        console.time('Await cmdGetCaseFiles');
        responseData = await withPausedRedisplay(
            connector.cmdGetCaseFiles(uri, loadOptionsForServer)
        );
        console.timeEnd('Await cmdGetCaseFiles');
    }

    // Ensure that the mapCase object has a reference to the caseDataCollection.
    if (!caseInfo.getCaseDataCollection()) {
        caseInfo.setCaseDataCollection(caseDataCollection);
    }

    const result = handleProteinResponses(caseInfo, responseData);
    const { errors, caseData: loadedCaseData } = result;

    // Need to add the case to the map selector if we don't already have it
    if (errors.length === 0 && !caseDataCollection.findMapByCase(caseInfo)) {
        caseDataCollection.addMapCase(caseInfo);
    }

    App.Workspace.rebuildProteinTree(false);
    EventBroker.publish('dataLoaded');
    EventBroker.publish('proteinLoaded', { mapCase: caseInfo, caseData: loadedCaseData });
    EventBroker.publish('resetDisplayRequest', true); // force protein view after loading protein

    if (caseHasFrags) {
        const needToLoadFrags = loadOptionsForServer.fragmentLoading === 'greedy'
            && !connector.staticMode;

        if (loadOptions.waitForFragments) {
            await UserActions.RefreshFragmentInfo(caseInfo, needToLoadFrags);
        } else {
            UserActions.RefreshFragmentInfo(caseInfo, needToLoadFrags);
        }
    } else {
        await App.Workspace.refreshFragservInfo();
        App.Workspace.setAvailableFragments(caseInfo, []);
    }

    const canAlignInPreviewMode = false;
    const canAlign = dataConnection.getMode() === 'server' || canAlignInPreviewMode;
    if (loadOptions.keepExisting && canAlign && loadedCaseData) {
        const refCaseData = App.Workspace.defaultAlignmentReferenceCaseData();
        await alignProtein({ refCaseData, alignCaseData: loadedCaseData });
        EventBroker.publish('resetDisplayRequest', true); // Redisplay again
        // Why reset display twice?
        // The reset above will show the new protein in its original coordinate system.
        // This one here will switched to the aligned coordinates.
        // For better or worse, this indicates visually that an alignment occurred.
    }
    return result;
}

/**
 * Align one protein to another. This does two things:
 * 1. Notes the reference protein on the aligned protein.
 * 2. Calculates the transform and assigns the "whole protein transform."
 *
 * Note: binding site transforms are handled separately. They make use of the reference protein
 * but calculate a local transform just for the binding site.
 * @param {CaseData} inboundCaseData
 */
async function alignProtein(options) {
    const alignMatrix = await withPausedRedisplay(getAlignmentMatrix(options));
    if (alignMatrix) {
        const { alignCaseData, refCaseData }= options;
        alignCaseData.setReferenceCaseData(refCaseData);
        alignCaseData.setWholeProteinTransform(alignMatrix);
        EventBroker.publish('resetDisplayRequest');
    } else {
        console.error('No matrix reported after align-protein cmd');
    }
}

/**
 * Calculate an alignment transformation between two proteins.
 * @param {{
*      refCaseData: CaseData,
*      alignCaseData: CaseData,
*      refSpec: string,
*      alignSpec: string,
* }} param0
* @returns {Promise<{ translation: [number,number,number], rotation: [number,number,number][]}>}
*/
async function getAlignmentMatrix({
    refCaseData, alignCaseData, refSpec, alignSpec,
}) {
    const refSelection = refSpec || defaultAlignmentSelection(refCaseData);
    const alignSelection = alignSpec || defaultAlignmentSelection(alignCaseData);

    const { connector: refConnector } = App.getDataParents(refCaseData);
    const { connector: alignConnector } = App.getDataParents(alignCaseData);
    const { data: pdbRef } = await UserActions.ExportQueryToFormat(refSelection, 'pdb', refConnector);
    const { data: pdbAlign } = await UserActions.ExportQueryToFormat(alignSelection, 'pdb', alignConnector);
    const reqObj = { pdbRef, pdbAlign };
    const responseData = await refConnector.cmdAlignProtein(reqObj);
    const [alignMatrix] = responseData.byId(ResponseIds.ProteinRotationMatrix);
    return alignMatrix?.result;
}

/**
* For default alignment, select the first chain.
*/
function defaultAlignmentSelection(caseData) {
    const firstChainAtomGroup = caseData.getProteinChains()[0];
    const chain = firstChainAtomGroup.chain;
    return `protein and chain ${chain}`;
}

/**
 * @description Convert client-side load options to server-side
 * @param string uri
 * @param {*} loadOptions
 */
function serverLoadOptions(caseInfo, loadOptions) {
    const args = {};
    const { FragmentLoading } = getPreferences();

    if (caseInfo instanceof PdbImportCase
        || caseInfo instanceof AlphaFoldImportCase
        || caseInfo instanceof UserDataImportCase) {
        // postprocessing: "just_load" | "just_hydrogens" | "load_and_type" | "all" | undefined
        // nameMappings: pass through as a string
        args.postprocessing = loadOptions.preserveHydrogens ? 'load_and_type' : 'all';
        args.nameMappings = loadOptions.nameMappings;
    }

    args.fragmentLoading = loadOptions.fragmentLoading || FragmentLoading;
    return args;
}

/**
 * Process various packets in the responseData in response to get-case-files or load-pdb-id
 * @param {MapCase} mapCase
 * @param {ResponseData} responseData
 * @returns {object}
 */
function handleProteinResponses(mapCase, responseData) {
    const [solute] = responseData.byId(ResponseIds.Solute);
    const errors = responseData.byId(ResponseIds.Error);
    const warnings = responseData.byId(ResponseIds.Warning);
    const energies = [];
    let caseData;

    if (solute) {
        const { caseDataCollection } = App.getDataParents(mapCase);
        // First handle protein, compounds, energies, pdb info
        const {
            atoms, bonds, helices, sheets,
        } = solute.atomData;
        caseData = App.Workspace.addProtein(mapCase, solute.allAtomGroups);
        caseDataCollection.addCaseData(caseData);
        integrateCompounds(responseData, caseData);
        MolAtomGroupFactory.PrintSummary(caseData.atomGroups);
        if (solute.compounds) {
            App.Workspace.activateFirstOf(solute.compounds);
            const MAX_COMPOUNDS_FOR_CLASH_DETECTION = 100;
            if (solute.compounds.length <= MAX_COMPOUNDS_FOR_CLASH_DETECTION) {
                reportClashingCompounds(solute.compounds, { alertParams: { dontAlert: true } });
            } else {
                console.log(`Skipping clash detection since ${solute.compounds.length} ligands > ${MAX_COMPOUNDS_FOR_CLASH_DETECTION}`);
            }
        }

        energies.push(...integrateEnergies(responseData, caseData));

        const [pdbInfo] = responseData.byId(ResponseIds.PdbInfo);
        if (pdbInfo) {
            applyPdbInfo(mapCase, pdbInfo);
        }

        integrateFragments(responseData, caseData);

        // Sample compounds
        const starterAvailability = responseData.byId(ResponseIds.StarterAvailability);
        if (starterAvailability.length > 0) {
            const availability = starterAvailability[0];
            if (availability.available) {
                caseData.getSampleCompoundInfo().setAvailable();
            }
        }
    } else {
        errors.push(`No solute data reported for protein case ${mapCase.projectCase || mapCase.displayName}`);
    }

    return {
        errors, warnings, energies, mapCase, caseData,
    };
}

function applyPdbInfo(caseInfo, pdbInfo) {
    // Update molecule name if it's empty or default.
    if (!caseInfo.molecule_name || UserDataImportCase.isDefaultMolName(caseInfo.molecule_name)) {
        const pdbMolName = pdbInfo.molecule_name || pdbInfo.gene_name;
        // If the pdb data didn't have a name, use the default
        caseInfo.molecule_name = pdbMolName || caseInfo.molecule_name
            || UserDataImportCase.DefaultRootMolName;
    }
    if (!caseInfo.gene_name) caseInfo.gene_name = pdbInfo.gene_name;
    if (!caseInfo.description) caseInfo.description = pdbInfo.title;
}

// UserActions.LoadMolecule
async function loadMolecule(molId, molFormat, molData, alignAction,
    caseData=App.Workspace.firstCaseData()) {
    const source = new MolDataSource({ molFormat, molData });
    if (molId) source.compoundName = molId;
    return loadMolData(source, alignAction, caseData);
}

async function loadMolData(molSource, alignAction, caseData=App.Workspace.firstCaseData()) {
    const { MaxMolecules } = getPreferences();
    const { connector } = App.getDataParents(caseData);
    molSource.maxMolecules = MaxMolecules || 100;
    const responseData = await connector.cmdLoadMolData(molSource, alignAction);
    const loadedCompounds = responseData.byId(ResponseIds.Compound);
    const loadErrors = responseData.byId(ResponseIds.Error);
    App.Workspace.addReplaceCompounds(loadedCompounds, caseData);
    integrateCompounds(responseData, caseData);
    handleClashingCompounds(loadedCompounds);
    App.Workspace.activateFirstOf(loadedCompounds);
    let heritageParent = null;
    if (molSource.modifiedFrom) {
        heritageParent = caseData.getCompoundBySpec(molSource.modifiedFrom);
    }
    CompoundHeritage.addMolSource(heritageParent, loadedCompounds, molSource);
    return { compounds: loadedCompounds, errors: loadErrors };
}

/**
 * Invoke assemble-compound bfd-server API
 */
async function assembleCompound(modificationsIn, caseData) {
    const modifications = Array.isArray(modificationsIn) ? modificationsIn : [modificationsIn];
    const { connector } = App.getDataParents(caseData);
    const assembleSpec = getAssembleCompoundSpec(modifications);
    const responseData = await connector.cmdAssembleCompound(assembleSpec);
    const loadedCompounds = responseData.byId(ResponseIds.Compound);
    const loadErrors = responseData.byId(ResponseIds.Error);
    App.Workspace.addReplaceCompounds(loadedCompounds, caseData);
    integrateCompounds(responseData, caseData);
    handleClashingCompounds(loadedCompounds);
    App.Workspace.activateFirstOf(loadedCompounds);
    // TODO: add mol heritage
    return { compounds: loadedCompounds, errors: loadErrors };
}

/**
 * Return json spec for a simple molecule description.
 */
function getAssembleCompoundMolDesc(mol) {
    let name = mol.resSpec;
    if (mol instanceof DecodedFragment) name = mol.baseFrag.name;
    else if (mol instanceof Fragment) name = mol.fragmentName;

    const ret = {
        name,
        atoms: mol.getAtoms().map((atom) => ({
            atom: atom.atom,
            // We need to send coordinates in the same reference as the source.
            // "use original" to undo any transformation resulting from an aligned protein
            pos: atom.getPosition({ useOriginal: true }),
        })),
        bonds: mol.getBonds().map(({ atom1, atom2, orderOriginal }) => ({
            fromAtom: atom1.atom, toAtom: atom2.atom, order: orderOriginal,
        })),
    };
    return ret;
}

/**
 * Return a modification spec for AssembleCompound.
 * modMol is the fragment
 * srcMol is the compound to merge the fragment into
 * modInfo contains type, and atom names:
 *   srcAttachAtom
 *   srcDirectionAtom
 *   modAttachAtom
 *   modDirectionAtom
 */
function getAssembleCompoundSpec(modifications) {
    const assemblies = modifications.map(({ srcMol, modMol, modInfo }) => ({
        srcMolecule: srcMol ? getAssembleCompoundMolDesc(srcMol) : null,
        modification: {
            modMolecule: getAssembleCompoundMolDesc(modMol),
            ...modInfo, // type, srcAttachAtom,srcDirectionAtom, modAttachAtom, modDirectionAtom
        },
    }));
    return { assemblies };
}

function handleClashingCompounds(compounds) {
    return reportClashingCompounds(compounds, {
        alertParams: {
            singularNoun: 'imported compound',
            pluralNoun: 'imported compounds',
            some: 'Some',
        },
    });
}
/**
 * @description Check a compound or an array of compounds for steric clashes, and
 * show an alert and log the clashes.
 * Updates energy info for the clashing compounds
 * @param {Compound|Compound[]} compounds
 * @param { {
 *   clashOptions: {
 *     tolerance: number, includeH: boolean, reportAll: boolean
 *   },
 *   alertParams: {
 *     singularNoun: string, pluralNoun: string, some: string, alertTitle: string
 *   },
 * }} options Configuration for the clash detection and the alert text
 * @return { Compound[] } Array of the clashing compounds
 */
export function reportClashingCompounds(compounds, { clashOptions: options, alertParams }={}) {
    const { compoundMap, clashOptions } = App.Workspace.detectClashes(compounds, options);
    const clashingCmpds = [...compoundMap.keys()];
    if (clashingCmpds.length === 0) {
        return clashingCmpds;
    }

    clashAlert(clashingCmpds, compounds, alertParams);
    console.log(getExtraInfoOnClashing(compoundMap, clashOptions));

    // Set warning in Energy Table
    clashingCmpds.forEach((c) => c.energyInfo.setClashing());
    EventBroker.publish('energyCalc');
    return clashingCmpds;
}

/**
 * @description Display clash alert to user
 * @param {Compound[]} clashingCmpds the compounds that are clashing with the protein
 * @param {Compound[]} allCmpds all compounds that were checked for clashes
 * @param { {
 *   singularNoun: string, pluralNoun: string, some: string, alertTitle: string
 *   }} alertParams Configuration for the alert text
 */
function clashAlert(clashingCmpds, allCmpds, alertParams={}) {
    const {
        singularNoun='compound',
        pluralNoun='compounds',
        some='Some of the',
        alertTitle='Steric Clash',
        dontAlert=false,
    } = alertParams;
    if (dontAlert) return;
    const clashingNames = clashingCmpds.map((cmpd) => cmpd.resSpec);
    let msgAlert;
    if (clashingCmpds.length > 1) {
        msgAlert = `${some} ${pluralNoun} are clashing with the protein. You may need to dock them to get favorable energy scores.\n\nClashing compounds: ${clashingNames.join(', ')}`;
    } else if (allCmpds.length === 1) {
        msgAlert = `The ${singularNoun} is clashing with the protein. You may need to dock it to get a favorable energy score.\n\nClashing compound: ${clashingNames.join(', ')}`;
    } else {
        msgAlert = `One of the ${pluralNoun} is clashing with the protein. You may need to dock it to get a favorable energy score.\n\nClashing compound: ${clashingNames.join(', ')}`;
    }
    showAlert(msgAlert, alertTitle);
}

/**
 * Takes array of clashingCmpds and returns a string of the name of the compound(s) and
 * first atoms that are clashing for each compound
 * @param {Compound[]} clashingCmpds
 * @returns String
 */
function getExtraInfoOnClashing(clashReport, { tolerance=defaultClashingTolerance }={}) {
    const clashingExtraInfo = [];
    for (const [cmpd, clashingAtoms] of clashReport) {
        const atomResMaps = new Map(); // Map<atom1, Map<resSpec:string, atomNames:string[]>>
        for (const { atom1, atom2 } of clashingAtoms) {
            const resSpec = getFullResidueId(atom2);
            if (!atomResMaps.get(atom1)) atomResMaps.set(atom1, new Map()); // ensure entry exists
            const resMap = atomResMaps.get(atom1);
            if (!resMap.get(resSpec)) resMap.set(resSpec, []); // ensure entry exists
            const atomNames = resMap.get(resSpec);
            const distance = pointDistance(atom1, atom2);
            const clashDistance = getClashingDistance(atom1, atom2, tolerance);
            atomNames.push(`${atom2.atom} (${atom2.amber}) ${distance.toFixed(5)} < ${clashDistance.toFixed(5)}`);
        }
        let clashingAtomMsg = '';
        for (const [atom1, resMap] of atomResMaps.entries()) {
            for (const [resSpec, atomNames] of resMap.entries()) {
                clashingAtomMsg += `    ${atom1.atom} (${atom1.amber}) with ${resSpec}: ${atomNames.join('; ')}\n`;
            }
        }
        clashingExtraInfo.push(`Clashing Compound: ${cmpd.resSpec}, Clashing Atoms (clash distances include tolerance of ${tolerance}:\n${clashingAtomMsg}`);
    }
    return `${clashingExtraInfo.join('')}`;
}

function doSelectAtom(atom, args) {
    if (atom) {
        const { connector } = App.getDataParents(atom);
        return connector.cmdSelectAtom(atom, args);
    }

    // Basically a deselect, so deselect from all connections
    return Promise.all(
        App.DataConnections.map((dc) => dc.getConnector().cmdSelectAtom())
    );
}

async function selectAtom(atomIn, args) {
    const [first, ...rest] = ensureArray(atomIn);
    await doSelectAtom(first, args);

    // Server API doesn't support selecting multiple atoms, so we achieve this by setting the
    // control key for every atom after the first. But need to make sure they aren't already
    // selected, since the ctrl key will make the attempt to select them actually deselect!
    if (rest.length > 0) {
        const restArgs = args ? { ...args } : {};
        restArgs.ctrlKey = true;

        await withPausedRedisplay((async () => {
            // The shift key may cause other atoms to become selected, so check each atom's
            // selection state before sending the select cmd, avoiding accidental deselection.
            for (const atom of rest) {
                if (!App.Workspace.isSelectedAtom(atom)) {
                    await doSelectAtom(atom, restArgs);
                }
            }
        })()); // IIFE
    }
    postSelection();
}

async function selectCmd(query, connector=App.ServerConnection) {
    await connector.cmdSelect(query);
    postSelection();
}

function postSelection() {
    EventBroker.publish('selectionDisplay', App.Workspace.getSelectedAtoms());
    EventBroker.publish('refreshAtomDisplay');
    EventBroker.publish('redisplayRequest');
}

async function exportCompoundsToFormat(compoundsIn, format) {
    const compounds = [].concat(compoundsIn); // force array
    const result = { data: '', error: '' };
    function addError(error) { result.error += `${result.error ? '; ' : ''}${error}`; }

    switch (format) {
        case 'mol':
            result.data = compounds[0] ? compounds[0].getMol2000() : '';
            return result;
        case 'sdf':
            result.data = Compound.makeSDF(compounds);
            return result;
        case 'smi':
            result.data = compounds.map((c) => c.getSmiles()).join('\n');
            return result;
        case 'smiNoName':
            result.data = compounds.map((c) => c.getUnnamedSmiles()).join('\n');
            return result;
        default:
            // passthrough
    }

    const allowedMultiProteinFormats = ['sdf', 'smi', 'csv', 'mol2', 'xyz'];

    const { dataParentsMap, dataParentsList } = App.partitionByDataParents(compounds);
    if (dataParentsList.length > 1 && !allowedMultiProteinFormats.includes(format)) {
        addError(`Compounds from multiple proteins cannot be exported to format ${format}.`);
        return result;
    }

    for (const [{ connector }, cmpds] of dataParentsMap.entries()) {
        const query = cmpds.map((c) => c.selectQuery).join(' or ');
        const { data, error } = await exportQueryToFormat(query, format, connector);
        if (data) result.data += data;
        if (error) addError(error);
    }

    return result;
}

async function exportSelectionToFormat(formatIn, connector=App.ServerConnection) {
    const smiNoNameSupported = connector.getMode() === 'static';
    let format = formatIn;
    if (format === 'smiNoName' && !smiNoNameSupported) format = 'smi';

    const result = { data: null, error: null };

    try {
        result.data = await connector.cmdExportSelection(format);
    } catch (err) {
        result.error = err;
    }

    return result;
}

async function exportQueryToFormat(query, format, connector=App.ServerConnection) {
    let result = { data: null, error: null };
    const needToSelect = (query && query !== 'selection');
    try {
        if (needToSelect) {
            await UserActions.Select(query, connector);
        }

        result = await exportSelectionToFormat(format, connector);
    } catch (err) {
        result.error = err;
    }

    if (needToSelect) {
        await UserActions.Select('none', connector);
    }
    return result;
}

/**
 * Export atoms to format.  The atoms have to already have been selected.
 */
async function exportSelectedAtomsToFormat(atoms, format) {
    if (atoms.length === 0) {
        return { error: 'No atoms available for export' };
    }

    const { connector } = App.getDataParents(atoms[0]);
    if (!atoms.every((atom) => App.getDataParents(atom).connector === connector)) {
        return { error: 'Selected atoms from only one protein can be exported. Try selecting only atoms on one protein.' };
    }

    return exportSelectionToFormat(format, connector);
}

/**
 * Export the given spec (compounds, atoms, or query string) to the given format
 * @param {{ compounds: Compound[]?, atoms: Atom[]?, query: string? }} exportSpec
 * @param {string} format
 * @param {BFDServerInterface} connector
 */
async function exportToFormat(exportSpec, format, connector) {
    const { compounds=[], atoms=[], query } = exportSpec || {};
    if (compounds.length > 0) {
        return exportCompoundsToFormat(compounds, format);
    } else if (atoms.length > 0) {
        return exportSelectedAtomsToFormat(atoms, format);
    } else if (query) {
        return exportQueryToFormat(query, format, connector);
    } else {
        return { error: 'Nothing specified for export' };
    }
}

async function loadStarterCompounds(caseData) {
    const { connector } = App.getDataParents(caseData);
    const responseData = await connector.cmdLoadStarterCompounds();
    const loadedCompounds = responseData.byId(ResponseIds.Compound);
    const loadErrors = responseData.byId(ResponseIds.Error);
    App.Workspace.addReplaceCompounds(loadedCompounds, caseData);
    integrateCompounds(responseData, caseData);
    integrateEnergies(responseData, caseData);
    handleClashingCompounds(loadedCompounds);
    App.Workspace.activateFirstOf(loadedCompounds);
    caseData.getSampleCompoundInfo().setLoaded();
    CompoundHeritage.addStarterCompounds(loadedCompounds, PdbImportCase.isValidId);
    return { compounds: loadedCompounds, errors: loadErrors };
}

function removeCompound(compound) {
    const { connector } = App.getDataParents(compound);
    App.Workspace.removeCompound(compound);
    connector.cmdRemoveCompound(compound);
}

async function renameCompound(compound, newName) {
    const { connector } = App.getDataParents(compound);
    const responseData = await connector.cmdRenameCompound(compound, newName);
    if (responseData.hasError()) {
        console.log(`Failed to rename compound: ${responseData.errors.join('; ')}`);
    } else {
        App.Workspace.renameCompound(compound, newName);
    }
}

async function copyCompound(compound) {
    const { connector, caseData } = App.getDataParents(compound);
    const responseData = await connector.cmdCopyCompound(compound);
    if (responseData.hasError()) {
        console.log(`Failed to rename compound: ${responseData.errors.join('; ')}`);
    } else {
        const compounds = responseData.byId(ResponseIds.Compound);
        App.Workspace.addReplaceCompounds(compounds, caseData);
        integrateCompounds(responseData, caseData);
        for (const c of compounds) {
            console.log(`Copied compound: ${compound.resSpec} -> ${c.resSpec}`);
        }
        App.Workspace.activateFirstOf(compounds);
        CompoundHeritage.addDuplicate(compound, compounds);
    }
}

function removeConnection(connector) {
    if (App.DataConnections.length === 1) {
        // Primary data connection should be zapped instead of removed
        return zapAll();
    }
    return App.ConnectionManager.removeConnector(connector);
}
