/**
 * display_mgr.js
 * @fileoverview This file contains logic to control visualizations the 3D canvas, including:
 * - 3D atoms: proteins, polymers, compounds, cofactors, ions, waters, fragments
 * - Highlights: hotspots, surfaces, colored spheres
 * - Mouse handling for atoms and shapes (spheres and bond vectors)
 *     - Atom selection
 *     - Context menu
 * - Key press handling for modes in the main canvas
 *     - X - hide compound
 *     - R - show replacement groups
 *     - V - show bond vectors
 *     - L - go to ligand view
 *     - H - show all compound hydrogens
 *     - W - show weak hydrogen bonds
 *     - D - show default docking box for focus compound
 *
 */

import { App } from './BMapsApp';
import {
    setMoleculeDisplayStyle, setBackgroundColor as mainSetBG, redisplay as mainRedisplay,
    zoomToAtoms,
    drawSurface, removeSurface, setAtomStyle, drawSphere, removeSphere,
    removeTempMolecule,
    makeTempMolecule,
    removeAtoms,
    setAtomSelected, setAtomColor, getCanvasAtom, resetMainCanvas, addAtoms as addCanvasAtoms,
}
    from './MainCanvas';
import {
    displayHBonds, atomArrayDifference,
    hydrophobicitySurfaceColor, soluteSolvationSurfaceColor,
    displayWaterHalo, displayOneWaterHalo, waterChemPotential,
    displayFunctionalGroupEnergyHalos, displayFunctionalGroupHighlight,
    displayOneHotspot, showDockingBox, cleanUpHotspots,
}
    from './display_tools';
import {
    canvasHasFocus, findBondVectorPair, actionableAtoms,
    isComputedWaterAtom, canModify,
    TerminalGroups, HalogenGroups, allBondVectorPairs, AtomVectorList, showAlert,
} from './utils';
import { getMouseGesture } from './ui/ui_utils';
import { EventBroker } from './eventbroker';
import { EnergyInfo } from './model/energyinfo';
import { StyleManager, MolStyles } from './style_manager';
import { UserActions } from './cmds/UserAction';
import {
    DisplayState, DisplayStates, WaterStates, HBondStates, BindingSiteSurfaceStates,
} from './model/DisplayState';
import {
    AtomGroupTypes, Hotspot, MolAtomGroup, Polymer, Residue,
} from './model/atomgroups';
import {
    ColorChain, CompoundColorMap, SelectionColor, BackgroundColors,
} from './themes';
import { BondVectorDisplay } from './model/display_models';
import { FragservFragset } from './model/FragmentData';
import {
    isCarbon, isHydrogen, isNonPolarHydrogen, onlyHydrogens,
} from './util/chem_utils';
import { AlphaFoldImportCase } from './model/MapCase';
import { SelectivityColoring } from './presentation/SelectivityColoring';
import FragDataMenuControl from './presentation/FragDataMenuControl';
import { getAlphafoldColor } from './util/alphafold_utils';
import { subscribe } from './redux/subscribers/stateChangeSubscriber';
import { Loader } from './Loader';
import { ensureArray } from './util/js_utils';
import { Atom } from './model/atoms';
import { BfdMolTypeCodes } from './molecule_types';
import { BindingSite } from './model/bindingsite';
import { HydrogenDisplayOptions, hydrogenCheck } from './util/display_utils';

subscribe('prefs.ColorScheme', (newColorScheme) => updateBackgroundColor(newColorScheme));
subscribe('prefs.BindingSiteDistance', () => { if (App.Ready) resetDisplay(); });

EventBroker.subscribe('atomMouse', onAtomMouse);
EventBroker.subscribe('atomMouse2d', onAtomMouse2d);
EventBroker.subscribe('shapeMouse', onShapeMouse);
EventBroker.subscribe('backgroundMouse', onBackgroundMouse);
EventBroker.subscribe('keyDown', onKeyDown);
EventBroker.subscribe('keyUp', onKeyUp);
EventBroker.subscribe('redisplayRequest', redisplay);
EventBroker.subscribe('refreshAtomDisplay', displayAll);
EventBroker.subscribe('resetDisplayRequest', (eventName, proteinView) => resetDisplay(proteinView));
EventBroker.subscribe('activeCompound', onActiveCompound);
EventBroker.subscribe('visibilityChanged', onVisibilityChanged);
EventBroker.subscribe('modifyingCompound', onModifyingCompound);

EventBroker.subscribe('viewStateChanged', updateView);
EventBroker.subscribe('atomGroupStateChanged',
    () => {
        displayFragments();
        displayComponents({ hotspots: App.Workspace.getAllHotspots() });
        redisplay();
    });

EventBroker.subscribe('hotspotsChanged', () => {
    displayComponents({ hotspots: App.Workspace.getAllHotspots() });
    redisplay();
});
EventBroker.subscribe('redisplayFragments',
    () => { displayFragments(); redisplay(); });

// Turn off bond vector display when context menu is aborted.
EventBroker.subscribe('mainContextMenuWrapperClose', () => hideAllBondVectors());
EventBroker.subscribe('setDisplayStyle',
    (_, { style, selectorFn }) => setDisplayStyle(style, selectorFn));

// Handling changes to DisplayState

/** Represents the display properties currently reflected in the 3D canvas */
const visibleState = {
    displayState: new DisplayState(),
    proteinSurface: null,
    bindingSiteSurface: null,
};
/** Vectors that are selected */
const selectedVectors = [];
let displayableVectorsPtr;

/**
 * Compare the display state properties requested by the user with the current state
 * and update accordinly.
 */
function updateView() {
    const desired = App.Workspace.displayState.viewState;
    const current = visibleState.displayState.viewState;
    const change = {};

    let changed = false;
    for (const [key, value] of Object.entries(desired)) {
        if (value !== current[key]) {
            change[key] = value;
            changed = true;
        }
    }

    if (changed) {
        console.log(`Updating view state: ${JSON.stringify(change)}`);
    }
    visibleState.displayState.update(change);

    for (const [key, val] of Object.entries(change)) {
        switch (key) {
            case DisplayStates.bindingsite:
                if (val) {
                    zoomToBindingSite();
                } else {
                    resetDisplay(true);
                }
                break;
            case DisplayStates.hydrogens: {
                resetDisplay();
                // Displaying hidden Hs with their hbonds here makes the
                // display consistent after removing hydrogens.
                // However, this behavior is not necessarily what we want.
                // The visibility of hbond hydrogens should be discussed.
                const showingHBonds = visibleState.displayState.hbonds !== HBondStates.none;
                displayHiddenHBondAtoms(showingHBonds);
                break;
            }
            case DisplayStates.waters:
                displayWaters(val);
                showHBonds(true);
                break;
            case DisplayStates.hbonds:
                showHBonds(val !== HBondStates.none);
                break;
            case DisplayStates.bindingSiteSurfaceState:
                displayBindingSiteSurface(val);
                break;
            case DisplayStates.functionalGroupHighlight:
                displayLigandSolvationHighlight(val);
                break;
            case DisplayStates.hotspots:
                displayComponents();
                break;
            case DisplayStates.activeCompoundStyle:
                displayCompounds();
                break;
            case DisplayStates.showBfactor:
                displayComponents();
            // no default; ignore other view state changes (eg inspectorTab)
        }
    }

    if (changed) {
        redisplay();
    }
}

// Key handling: enter temporary mod modes and perform actions

/**
 * Defined mod modes
 * - replace (key: R) - highlight replaceable functional groups with transparent spheres
 * - vectors (key: V) - show bond vectors for interaction
 * - hide (key: X) - hide the focus (active) compound
 * - none - no mod mode
 */
const LigandModModes = {
    none: 'none',
    replace: 'replace',
    vectors: 'vectors',
    hide: 'hide',
};

let ligandModMode = LigandModModes.none;

/**
 * Process keypress, entering appropriate mod mode or performing temporary action
 * @param {*} event
 * @param {*} keyData { key, keyCode }
 *
 * NOTE: Make sure there is an onKeyUp case corresponding to key down cases.
 *
 * R - enter "replace" mod mode, highlighting functional groups
 * H - temporarily display compound hydrogens
 * V - enter "vector" mod mode, displaying bond vectors
 * X - temporarily hide focus compound
 * D - temporarily display docking box
 * W - temporarily display weak hydrogen bonds
 */
function onKeyDown(event, keyData) {
    if (!canvasHasFocus()) return;

    switch (keyData.key) {
        case 'r':
        case 'R':
            enterModMode(LigandModModes.replace);
            break;
        case 'h':
        case 'H':
            if (ligandModMode === LigandModModes.none) {
                // TODO: this should set a temp state on the display state controller
                // However, that would apply to ALL hydrogens, including protein,
                // and the H key should only show compound hydrogens.
                // So it's showing compound Hs manually here.
                // Once the hydrogen display state changes to distinguish between
                // compound, protein, and fragment, this can be revisited.
                showAllCompoundHydrogens(App.Workspace.getVisibleCompounds(), true);
                redisplay();
            }
            break;
        case 'v':
        case 'V':
            enterModMode(LigandModModes.vectors);
            break;
        case 'x':
        case 'X':
            App.Workspace.displayStateController.hideActiveTemp(true);
            break;
        case 'd':
        case 'D':
            showDockingBox(true);
            break;
        case 'w':
        case 'W':
            App.Workspace.displayStateController.weakHBondsTemp(true);
            break;
        // no default; ignore other key strokes
    }
}

/**
 * Process key release, leaving appropriate mod mode or performing action
 * @param {*} event
 * @param {*} keyData { key, keyCode }
 *
 * Escape - publish 'escapeKey' which will inform dialogs to close
 * R - leave "replace" mod mode
 * H - return compound hydrogen display to normal
 * V - leave "vector" mod mode
 * L - enter ligand view, or, if already in ligand view, cycle to next ligand
 * Arrow keys - change focus on next / prev compound
 * X - stop hiding focus compound
 * D - stop showing docking box
 * W - stop showing weak hydrogen bonds
 *
 * Z, Y - rudamentary undo/redo, but not being used
 */
function onKeyUp(event, keyData) {
    switch (keyData.key) {
        case 'Escape':
            EventBroker.publish('escapeKey', true);
            break;
        default: {
            //--- this should be only on the canvas -- consider the selection input box
            if (canvasHasFocus()) {
                switch (keyData.key) {
                    case 'r':
                    case 'R':
                        leaveModMode(LigandModModes.replace);
                        break;
                    case 'h':
                    case 'H':
                        if (ligandModMode === LigandModModes.none) {
                            // TODO: see note about temp H display in onKeyDown()
                            showAllCompoundHydrogens(App.Workspace.getVisibleCompounds(), false);
                            redisplay();
                        }
                        break;
                    case 'v':
                    case 'V':
                        leaveModMode(LigandModModes.vectors);
                        break;
                    case 'l':
                    case 'L': // for J3 (like Schrodinger)
                        if (visibleState.displayState.bindingsite) {
                            UserActions.ActivateNextCompound(1);
                        }
                        UserActions.SetView('ligand');
                        break;
                    case 'ArrowLeft':
                    case 'ArrowUp':
                        UserActions.ActivateNextCompound(-1);
                        break;
                    case 'ArrowRight':
                    case 'ArrowDown':
                        UserActions.ActivateNextCompound(1);
                        break;
                    case 'x':
                    case 'X':
                        App.Workspace.displayStateController.hideActiveTemp(false);
                        break;
                    case 'd':
                    case 'D':
                        showDockingBox(false);
                        break;
                    case 'w':
                    case 'W':
                        App.Workspace.displayStateController.weakHBondsTemp(false);
                        break;
                    case 'z':
                    case 'Z':
                        if (keyData.ctrlKey) {
                            if (keyData.shiftKey) {
                                UserActions.Redo();
                            } else {
                                UserActions.Undo();
                            }
                        }
                        break;
                    case 'y':
                    case 'Y':
                        if (keyData.ctrlKey) {
                            UserActions.Redo();
                        }
                        break;
                    case 's': case 'S':
                        // This S clause, if enabled, selects all binding site atoms, even those
                        // normally or always invisible, which can be useful when debugging.
                        // Notably, it will select LP (lone pair) atoms from computed waters as well
                        // as computed waters whose excess chem p causes them to be filtered out
                        // from ever being displayed (see waterChemPotential).
                        // if (App.Workspace.workingBindingSite) {
                        //     const bsAtoms = App.Workspace.workingBindingSite
                        //         .getAllBindingSiteAtoms(App.Workspace);
                        //     UserActions.SelectAtom(bsAtoms);
                        // }
                        break;

                    // no default; ignore other key strokes
                }
            }
            break;
        }
    }
}

/**
 * Apply the provided style to either selected atoms or currently visible protein atoms
 * in Protein View or Ligand View.
 *
 * This also handles surface display. 'surface' is a sibling of the other styles, but
 * it is implemented differently.  Instead of rendering the literal atoms in a certain way,
 * the surface is drawn *around* the atoms.
 *
 * This function should really be a UserAction, in the sense that it implements a user operation,
 * and is invoked by the style buttons and the speech interface.
 *
 * @param {String} style
 * @param {*} selectorFn not used
 */
function setDisplayStyle(style, selectorFn) {
    const needToRedisplay = true;
    switch (style) {
        case 'surface':
            displayProteinSurface(true);
            // fall through to allow styling of the atoms
        case 'hidden':
        case 'wireframe':
        case 'sticks':
        case 'ballandstick':
        case 'spacefill':
        case 'cartoon':
            applyAtomStyle(style, selectorFn);
            break;
        default:
            console.warn(`DisplayManager::setDisplayStyle: unrecognized style: ${style}`);
    }

    if (needToRedisplay) { redisplay(); }
}

/**
 * Apply an style to atoms.  Which atoms?
 * - If there are selected atoms in the 3d canvas, use those.
 * - Otherwise, visible protein atoms in either Protein View or Ligand View
 *
 * This also will remember the style for the affected atoms, so that if you switch views
 * between Protein and Ligand view, it shows the style that you had set at each.
 * @param {*} style
 * @param {*} selectorFn not used
 */
function applyAtomStyle(style, selectorFn) {
    // If no specified atoms (selection or selectorFn), apply style to the protein
    if (!selectorFn && App.Workspace.getSelectedAtoms().length === 0) {
        displayProteinSurface(false);
        if (visibleState.displayState.bindingsite && App.Workspace.getBindingSite()) {
            StyleManager.LigandViewProteinStyle = style;
        } else {
            StyleManager.ProteinViewProteinStyle = style;
        }
        displayComponents();
        return;
    }

    // Apply style to specified atoms (from selection or selectorFn)
    let atoms = [];
    if (selectorFn) {
        // selectorFn does not appear to be used anywhere
        atoms = [];
        console.error('selectorFn in applyAtomStyle is not implemented');
        // TODO: apply selectorFn to all bmaps atoms
    } else {
        atoms = App.Workspace.getSelectedAtoms();
    }
    const workspace = App.Workspace;
    const {
        primaryGroups, subsumedGroups, extraAtomsMap,
    } = workspace.partitionAtomsIntoGroups(atoms);
    const extraAtoms = [...extraAtomsMap.values()].flat();
    console.log(`Applying style ${style} to primary groups: ${primaryGroups}, subsumed groups: ${subsumedGroups}, and atoms: ${extraAtoms}`);

    // Style are applied in order of priority from low to high:
    // Parent (chain) group, child (residue) group, individual atom
    // `applyAtomStyle` is called when user operates on a selection, so we need to obey exactly.
    // When updating the style of parent or child group, reset the styles for higher priority items
    // which might cause the change to be ignored.
    // Example: You change the style of a residue and then select the entire chain and apply style.
    // We need to drop the style of the residue, otherwise, when it is rendered it will use the
    // higher priority residue style instead of the newly applied chain style.
    for (const atomGroup of primaryGroups) {
        setAtomGroupMolStyle(atomGroup, style);
        StyleManager.removeCustomAtomsStyle(atomGroup.getAtoms());
    }
    // Subsumed groups are whole atom groups that are subsets of another selected whole atom group
    for (const subsumedGroup of subsumedGroups) {
        StyleManager.removeCustomAtomGroupMolStyle(subsumedGroup);
    }
    StyleManager.setCustomAtomsStyle(extraAtoms, style);
    displayAll();
}

// Functions passed through to the main interface.
export function updateBackgroundColor(scheme) {
    const color = BackgroundColors[scheme];
    mainSetBG(color);
}

export function redisplay() {
    mainRedisplay();
}

let compoundTempModel;
/**
 * Display the active compound in transparent form, so it's easier to view fragments when
 * browsing options for fragment grow / search.
 * @param {*} evtName ignored
 * @param {*} on
 */
function onModifyingCompound(evtName, on) {
    if (on) {
        const atoms = App.Workspace.getActiveCompoundAtoms()
            .filter(localHydrogenCheck);
        compoundTempModel = makeTempMolecule(
            atoms, App.Workspace.displayState.activeCompoundStyle, CompoundColorMap, 0.8
        );
        App.Workspace.displayStateController.hideActiveTemp(true);
        redisplay();
    } else if (compoundTempModel) {
        removeTempMolecule(compoundTempModel);
        compoundTempModel = undefined;
        App.Workspace.displayStateController.hideActiveTemp(false);
        redisplay();
    }
}

/**
 * Event handler when active compound changes
 * @param {String} evtName ignored (event handler boiler plate)
 * @param {Object} data Object: {compound}
 *
 * TODO Extract event handler boilerplate (evtName) to the caller, and make this
 * function just take a compound input
 */
function onActiveCompound(evtName, data) {
    let needToRedisplay = true;
    displayCompounds();

    showEnergyHighlights(false);

    if (visibleState.displayState.bindingsite && data.compound) {
        const newBindingSite = App.Workspace.saveNewBindingSite({
            compound: App.Workspace.getActiveCompound(),
        });
        if (zoomToBindingSite(newBindingSite)) {
            // zoomToBindingSite returning true indicates redisplay() has been called
            needToRedisplay = false;
        }
    }

    if (!App.Workspace.isProteinLoaded()) {
        zoomToAtoms(App.Workspace.isVisibleCompoundAtom);
    }

    if (needToRedisplay) {
        redisplay();
    }
}

/** Update display when visibility of any components change */
function onVisibilityChanged() {
    displayAll();
    // HBonds is the only energy highlighting that will apply to non-active visible compounds
    showHBonds(false);
    showHBonds(true);
    redisplay();
}

/**
 * Show or hide hydrogen bonds, ligand solvation highlight spheres,
 * and binding-site protein highlighting.
 * @param {Boolean} show
 */
function showEnergyHighlights(show) {
    showHBonds(show);
    displayLigandSolvationHighlight(show && visibleState.displayState.functionalGroupHighlight);
    const surfaceDisplay = show
        ? BindingSiteSurfaceStates.useSelected
        : BindingSiteSurfaceStates.off;
    displayBindingSiteSurface(surfaceDisplay);
}

/**
 * Redisplay all components and recenter
 * @param {Boolean} forceProteinView
 */
export function resetDisplay(forceProteinView=false) {
    // This is the only condition where we'll be at the binding site
    if (!forceProteinView && visibleState.displayState.bindingsite) {
        if (zoomToBindingSite()) {
            return;
        }
    }
    // From here we must be in protein view
    resetMainCanvas();

    displayAll();

    EventBroker.publish('selectionDisplay', App.Workspace.getSelectedAtoms());

    // Don't do the following if the redisplay request doesn't need to refresh protein
    // eg: we just added a compound.
    if (forceProteinView) {
        showEnergyHighlights(false);
        // 3Dmol zoom issue: https://github.com/3dmol/3Dmol.js/issues/360
        if (needToWorkAround3DmolZoom()) {
            workAround3DmolZoom();
        } else {
            // normal case
            zoomToAtoms();
        }
    }

    if (!App.Workspace.isProteinLoaded()) {
        zoomToAtoms(App.Workspace.isVisibleCompoundAtom);
    }
    redisplay();
}

// 3Dmol zoom issue: https://github.com/3dmol/3Dmol.js/issues/360
// 3Dmol calculates large bounding spheres for Shapes like Spheres.
// These large bounding spheres are used when zooming to all atoms,
// but only if an empty zoom parameter is used.
// The workaround is to pass in a selector function that returns
// returns true for all atoms.  This lets us use all the atoms
// for zooming, but does not consider the large bounding spheres of the
// Shapes.
function needToWorkAround3DmolZoom() {
    return visibleState.displayState.waters !== WaterStates.none;
}
function workAround3DmolZoom() {
    zoomToAtoms((x) => true);
}

/**
 * Enter binding site view for active compound, redisplay all components accordingly, and recenter.
 * @param {*} bindingSite
 * @returns {Boolean} Whether it as able to zoom into binding site
 */
export function zoomToBindingSite(bindingSiteIn=null) {
    // Logic for binding site display:
    //   Hide everything
    //   Display active compound ball-and-stick
    //   Display solute: protein=sticks, cofactor=sticks, ions=spheres
    //   Display waters if enabled
    //   (Other compounds are hidden by hide everything)
    //   (Ignored stuff (buffer?) is hidden by hide everything)
    //   Zoom to the binding site
    let bindingSite = bindingSiteIn;
    if (bindingSite == null) { bindingSite = App.Workspace.saveNewBindingSite(); }

    if (bindingSite) {
        displayProteinSurface(false);
        resetMainCanvas();

        displayAll();

        const bsAtoms = bindingSite.getAllBindingSiteAtoms(App.Workspace);
        console.log(`Zooming into Ligand View: Ref size ${bindingSite.getRefAtomsCount()} atoms. All atoms: ${bsAtoms.length}; Solute: ${bindingSite.getSoluteAtoms(App.Workspace).length}; Water: ${bindingSite.getComputedWaterAtoms(App.Workspace).length}.`);

        zoomToAtoms((a) => bsAtoms.includes(a));
        showEnergyHighlights(true);
        // redisplay waters after energy highlight in case hbonds have been calculated
        displayWaters(visibleState.displayState.waters);
        redisplay();
        return true;
    }

    return false;
}

/**
 * Display a surface around protein atoms, either in Protein View or Ligand View.
 * This corresponds to the "surface" style in the style controls, NOT the
 * protein solvation or hydrophobicity highlighting, currently handled separately.
 * @param {Boolean} show
 */
function displayProteinSurface(show) {
    const workspace = App.Workspace;
    if (show) {
        // First clear existing surface
        displayProteinSurface(false);
        let visibleComponents = workspace.getProteinChains().filter(workspace.isVisible);

        const bindingSite = workspace.getBindingSite();
        if (visibleState.displayState.bindingsite && bindingSite) {
            // Convert the chains to their constituent residues for binding site filtering
            visibleComponents = visibleComponents.reduce(
                (group, next) => [...group, ...next.getResidues()], []
            );
            visibleComponents = bindingSite.getComponentsInRange(visibleComponents);
        }
        const visibleAtoms = visibleComponents.reduce(
            (group, next) => group.concat(next.getAtoms()), [],
        );
        visibleState.proteinSurface = drawSurface({ atoms: visibleAtoms });
        if (visibleState.displayState.bindingsite) {
            StyleManager.LigandViewProteinStyle = MolStyles.surface;
        } else {
            StyleManager.ProteinViewProteinStyle = MolStyles.surface;
        }
    } else {
        if (visibleState.proteinSurface) {
            removeSurface(visibleState.proteinSurface);
            visibleState.proteinSurface = null;
        }
    }
}

/**
 * Display a solvation or hydrophobicity highlight surface around binding site protein atoms
 * @param {*} surfaceState
 *
 * Note: Need to call redisplay() after calling this.
 */
function displayBindingSiteSurface(surfaceStateIn) {
    let surfaceState = surfaceStateIn;
    if (surfaceState === BindingSiteSurfaceStates.useSelected) {
        surfaceState = visibleState.displayState.bindingSiteSurfaceState;
    }

    if (surfaceState === BindingSiteSurfaceStates.ddgs) {
        // Make sure we have solvation energies
        const cmpd = App.Workspace.getActiveCompound();
        if (!cmpd || !cmpd.energyTypeAvailable(EnergyInfo.Types.ddGs)) {
            surfaceState = BindingSiteSurfaceStates.off;
        }
    }

    if (surfaceState !== BindingSiteSurfaceStates.off) {
        if (visibleState.bindingSiteSurface) {
            removeSurface(visibleState.bindingSiteSurface);
        }

        const bindingSite = App.Workspace.getBindingSite();
        const atoms = bindingSite.getSoluteAtoms(App.Workspace);
        let colorFn = null;
        if (surfaceState === BindingSiteSurfaceStates.hydrophobicity) {
            colorFn = hydrophobicitySurfaceColor;
        } else if (surfaceState === BindingSiteSurfaceStates.ddgs) {
            colorFn = soluteSolvationSurfaceColor;
        }
        visibleState.bindingSiteSurface = drawSurface({ atoms, colorFn, opacity: 0.75 });
        // visibleState.bindingSiteSurface.description = `ddGs scale: red < gray < green`;
    } else {
        if (visibleState.bindingSiteSurface) {
            removeSurface(visibleState.bindingSiteSurface);
            visibleState.bindingSiteSurface = null;
        }
    }
}

/**
 * Show or hide colored transparent highlighting spheres around ligand functional groups
 * as a semi-quantitative indication of solvation.
 * @param {Boolean} show whether to show or hide the highlights
 *
 * Note: Need to call redisplay() after calling this
 */
function displayLigandSolvationHighlight(show) {
    const activeCompound = App.Workspace.getActiveCompound();
    const hasSolvation = activeCompound?.energyTypeAvailable(EnergyInfo.Types.ddGs);
    if (show && activeCompound && hasSolvation) {
        const groups = activeCompound.getFunctionalGroups();
        displayFunctionalGroupEnergyHalos(true, groups);
    } else {
        displayFunctionalGroupEnergyHalos(false);
    }
}

/** Show bond vector arrows for the specified arrows */
function displayBondVectors(vectors=[]) {
    const options = {
        displayHydrogens: visibleState.displayState.hydrogens,
    };
    EventBroker.publish('displayBondVectors', { vectors, options });
}

/** Hide all bond vector arrows */
function hideAllBondVectors() {
    // clears selected vectors array
    selectedVectors.length = 0;
    EventBroker.publish('displayBondVectors', { hideAll: true });
}

/**
 * Enter a "mod mode" in response to keyDown
 * @param {*} mode
 */
function enterModMode(mode) {
    if (ligandModMode !== LigandModModes.none) {
        leaveModMode(ligandModMode);
    }

    ligandModMode = mode;
    switch (mode) {
        case LigandModModes.replace:
            highlightFunctionalGroups(true);
            break;
        case LigandModModes.hide:
            setMoleculeDisplayStyle(MolStyles.hidden, App.Workspace.isActiveCompoundAtom);
            break;
        case LigandModModes.vectors: {
            const compounds = App.Workspace.getVisibleCompounds();
            collectAndShowBondVectors(compounds);
            break;
        }
        default:
            console.error(`Display Mgr: entering unknown mod mode: ${mode}`);
    }
}

/**
 * Leave a "mod mode" in response to keyUp
 * @param {*} mode
 */
function leaveModMode(mode) {
    ligandModMode = LigandModModes.none;

    switch (mode) {
        case LigandModModes.replace:
            highlightFunctionalGroups(false);
            highlightOneAtom(null, false);
            break;
        case LigandModModes.hide:
            UserActions.SetView(visibleState.displayState.bindingsite ? 'ligand' : 'protein');
            break;
        case LigandModModes.vectors:
            hideAllBondVectors();
            break;
        default:
            console.error(`Display Mgr: leaving unknown mod mode: ${mode}`);
    }
}

/**
 * Process a mouse event on an atom in the context of a mod mode.
 * @param {*} atom The atom being operations
 * @param {*} mouseEvent the mouse event on the atom
 * @returns {Boolean} whether there was an active mod mode upon invocation
 *
 * TODO The calling function has access to the mouse gesture. That should be
 * passed in, instead of the mouseEvent and the switch cases updated accordingly.
 */
function modModeMouseEvent(atom, mouseEvent) {
    if (ligandModMode === LigandModModes.none) {
        return false;
    }

    const action = `${ligandModMode}-${mouseEvent.type}`;
    switch (action) {
        case 'replace-touchend':
        case 'replace-mouseup':
            highlightFunctionalGroups(false);
            highlightOneAtom(null, false);
            break;
        case 'replace-mouseenter':
            highlightOneAtom(atom, true);
            break;
        case 'replace-mouseleave':
            highlightOneAtom(atom, false);
            break;
        case 'grow-mouseup':
            break;
        // no default
    }

    return true;
}

/**
 * - Get all bonds vectors for provided compounds,
 * - Determine if any are actionable (ie auto-grow, auto-replaceable)
 * - Convert to a displayable form
 * - Display
 * @param {Array} compounds
*/
function collectAndShowBondVectors(compounds) {
    const actionableVectors = getActionableBondVectors(compounds);
    const displayableVectors = actionableVectors.map(({ canGrow, canReplace, vector }) => {
        let label;
        let color;
        if (canGrow && canReplace) {
            label = 'Growable and replaceable';
            color = 'lightblue';
        } else if (canGrow) {
            label = 'Growable';
            color = 'lightpink';
        } else if (canReplace) {
            // Replaceable but not growable should never happen
        }

        const vectorDisplayInfo = new BondVectorDisplay(vector, {
            color,
            description: `${label} vector: ${vector[0].atom} -> ${vector[1].atom}`,
            onClick: (_, evt) => onVectorMouse(vector, evt),
        });
        vectorDisplayInfo.options.object = vectorDisplayInfo;
        return vectorDisplayInfo;
    });

    displayableVectorsPtr = displayableVectors;
    displayBondVectors(displayableVectors);
}

/**
 * haveFragmentData()
 * @param {Atom} atom
 * @returns {{
 *     atomHasFragments: boolean,
 *     workspaceHasFragments: boolean,
 *     workspaceIsLoading: boolean,
 * }} An object with the following fields:
 *     atomHasFragments: whether the case associated with the atom has available fragments;
 *     workspaceHasFragments: whether any of the cases loaded have available fragments;
 *     workspaceIsLoading: whether any of the cases loaded are loading their available fragments
 */
function haveFragmentData(atom) {
    const { anyLoading, anyFrags } = App.Workspace.availableFragmentStatus();
    const { caseData } = App.getDataParents(atom);
    return {
        atomHasFragments: caseData.areFragmentsAvailable(),
        workspaceHasFragments: anyFrags,
        workspaceIsLoading: anyLoading,
    };
}
/**
 * Is the specified atom pair auto-growable?
 * In general, we normally grow TOWARD a terminal, but we can also grow internally.
 * Do not allow growing FROM a terminal atom (unless the mol is just two heavy atoms)
 * Eg. For X-C-CH3: allow from X-C->CH3 but not from CH3->C-X
 *     For H3C-CH3: allow in either direction
 * @param {*} bondVector
 * @returns {Boolean}
 */
function isAutoGrowableBondVector(bondVector) {
    const [fromAtom, toAtom] = bondVector;
    const { atomHasFragments } = haveFragmentData(fromAtom);

    return atomHasFragments && (toAtom.isTerminalAtom() || fromAtom.heavyBonds.length > 1);
}

/** Convert a list of growable atoms into a list of autogrowable vectors,
 * according to isAutoGrowableBondVector.
 */
function autoGrowableBondVectors(growableAtoms) {
    const vectors = [];
    for (const atom of growableAtoms) {
        let autogrowables = allBondVectorPairs(atom);
        autogrowables = autogrowables.filter(isAutoGrowableBondVector);
        AtomVectorList.addVectors(autogrowables, vectors);
    }
    return vectors;
}

/** Only Hs and terminal heavy atoms are auto-replaceable */
function isAutoReplaceableAtom(atom) {
    return atom.isTerminalAtom();
}

/** Convert a list of replaceable atoms into a list of auto-replaceable vectors,
 * according to isAutoReplaceableAtom.
 * If the atom is auto-replaceable, it is a terminal and will thus be the "toAtom"
 */
function autoReplaceableBondVectors(replaceableAtoms) {
    const atoms = replaceableAtoms.filter(isAutoReplaceableAtom);
    const vectors = atoms.map((atom) => {
        // Atoms are either terminal heavy (1 heavy bond) or H (just one bond)
        const bond = atom.heavyBonds[0] || atom.bonds[0];
        const fromAtom = bond.otherAtom(atom);
        return [fromAtom, atom];
    });
    return vectors;
}

/** Take a list of compounds and return a list of annotated bond vectors with possible operations
 * @param {Array} compounds list of compounds to get bond vectors from
 * @returns {Array} of objects of type: { vector, canGrow, canReplace }, where:
 * - vector is a list of two atoms: [fromAtom, toAtom]
 * - canGrow is Boolean if growing is possible on this vector
 * - canReplace is Boolean if replace is possible on this vector
 */
function getActionableBondVectors(compounds) {
    const autoGrowableList=[];
    const autoReplaceableList=[];

    for (const compound of compounds) {
        const { canReplace: canReplaceAtoms, canGrow: canGrowAtoms } = actionableAtoms(compound);
        const growableVectors = autoGrowableBondVectors(canGrowAtoms);
        const replaceableVectors = autoReplaceableBondVectors(canReplaceAtoms);
        // AtomVectorList functions work on lists of [fromAtom, toAtom] lists and avoid duplicates
        AtomVectorList.addVectors(growableVectors, autoGrowableList);
        AtomVectorList.addVectors(replaceableVectors, autoReplaceableList);
    }

    const allVectors = AtomVectorList.merge(autoGrowableList, autoReplaceableList);
    const annotatedVectors = allVectors.map((vector) => ({
        vector,
        canGrow: !!AtomVectorList.findVector(vector, autoGrowableList),
        canReplace: !!AtomVectorList.findVector(vector, autoReplaceableList),
    }));
    return annotatedVectors;
}

/**
 * Process mouse events on a vector.
 * Hovering is handled by default. Clicking will come later (will want to select vectors).
 * @param {Array} vector [fromAtom, toAtom]
 * @param {MouseEvent} mouseEvent
 * @param
 */
function onVectorMouse(vector, mouseEvent) {
    const gesture = getMouseGesture(mouseEvent);
    switch (gesture) {
        case 'click':
            selectOrDeselectVectors(vector, selectedVectors);
            break;
        // no default; ignore other gestures
    }
}

/** Populate and show a menu for interacting with bond vectors */
export function getVectorMenu(vector) {
    const [start, end] = vector;
    const { caseData } = App.getDataParents(start);

    const canGrow = isAutoGrowableBondVector(vector);
    const canReplace = isAutoReplaceableAtom(end); // end atom is the replaceable one

    const menuItems = [];
    let childMenuItems = [];

    menuItems.push({
        itemType: 'grouphead',
        label: 'Bond Vector Modifications',
    });
    if (canReplace) {
        childMenuItems.push({
            itemType: 'item',
            label: 'All Replacement Groups',
            title: `Replace this terminal with all ${TerminalGroups.length} replacement terminal groups.`,
            onClick() { doVectorMenuCmd('autoreplace', start, end, 'all'); },
        });
        const halogenNames = HalogenGroups.map((h) => h.formula).join(', ');
        childMenuItems.push({
            itemType: 'item',
            label: 'Halogens',
            title: `Replace this terminal with all halogen terminal groups: ${halogenNames}`,
            onClick() { doVectorMenuCmd('autoreplace', start, end, 'halogens'); },
        });
        menuItems.push({
            itemType: 'nested',
            label: 'Multi-replace with...',
            content: childMenuItems,
        });
    }
    childMenuItems = [];
    if (canGrow) {
        const multiGrowFn = (fragset) => {
            const fsid = fragset?.fragsetId; // null fsid will search all loaded frags
            doVectorMenuCmd('autogrow', start, end, fsid);
        };
        const nSearchableFrags = App.Workspace.getActiveFragments(caseData).length;
        childMenuItems.push({
            itemType: 'item',
            label: `All searchable fragments (${nSearchableFrags})`,
            title: 'Grow with all fragments currently marked as "Searchable" in Manage Fragments',
            onClick() { multiGrowFn(); },
        });

        childMenuItems.push(...actionableFragsetMenuItems(caseData, multiGrowFn, {
            needToBeAvailable: true,
            getLabel(fsName, counts) { return `${fsName} (${counts.available} simulated frags)`; },
            getTitle(fsName) { return `Grow with all simulated fragments from fragment set ${fsName}`; },
        }));
        childMenuItems.push({
            itemType: 'item',
            label: 'Manage Fragments',
            title: 'Open the Fragment Manager to specify which fragments are searchable and which are used for hotspot analysis',
            onClick() { UserActions.OpenFragmentManager(); },
        });
        menuItems.push({
            itemType: 'nested',
            label: 'Multi-grow with Fragment Set...',
            content: childMenuItems,
        });
    }
    if (Loader.AllowDevFeatures && (canGrow || canReplace)) {
        menuItems.push({ itemType: 'separator' });
        const fsReplaceFn = async (fragset) => {
            const { compounds, errors } = await UserActions.ReplaceWithFragmentSet(
                start, end, fragset,
            );
        };
        const fsReplaceItems = actionableFragsetMenuItems(caseData, fsReplaceFn, {
            needToBeAvailable: false,
            getLabel(fsName, counts) { return `${fsName} (${counts.total} frags)`; },
            getTitle(fsName) { return `Replace with all fragments from fragment set ${fsName}`; },
        });
        menuItems.push({
            itemType: 'nested',
            label: 'Replace with fragment set',
            title: 'replace terminal with each fragment in fragment set',
            content: fsReplaceItems,
        });
    }

    const menuHandler = (elt) => {
        const {
            cmd, arg, startuid: startuidRaw, enduid: enduidRaw,
        } = elt.dataset;
        doVectorMenuCmd({
            cmd, arg, startuid: Number(startuidRaw), enduid: Number(enduidRaw),
        });
    };

    const ret = { menuItems, menuHandler };

    return ret;
}

/**
 * Gets the vector that was selected, finds it in displayableVectorsPtr which points to an Array of
 * [BondVectorDisplay]
 * and adds it to selectdVectors. Also highlights the selected BondVectorDisplay
 * If it was already selected, it is removed from from selectdVectors and reverted back to its
 * original color
 * @param {[Atom: object, Atom: object]} vector
 * @param {[BondVectorDisplay: object]} selectedVectorsArr
 *
 * This accesses global displayableVectorsPtr, an array of BondVectorDisplay
 */
function selectOrDeselectVectors(vector, selectedVectorArr) {
    // check if the vector was already selected, if it is, then its removed
    const alreadySelectedVectorIndex = selectedVectorArr.findIndex(
        ({ atomPair }) => atomPair[0] === vector[0] && atomPair[1] === vector[1]
    );
    const alreadySelectedVector = selectedVectorArr[alreadySelectedVectorIndex];
    if (alreadySelectedVector === undefined) {
        const displayableVector = displayableVectorsPtr.find(
            ({ atomPair }) => atomPair[0] === vector[0] && atomPair[1] === vector[1]
        );
        selectedVectorArr.push(displayableVector);
        if (displayableVector.options.previousColor === undefined) {
            displayableVector.options.previousColor = displayableVector.options.color;
        }
        displayableVector.options.color = SelectionColor;
    } else {
        alreadySelectedVector.options.color = alreadySelectedVector.options.previousColor;
        selectedVectorArr.splice(alreadySelectedVectorIndex, 1);
    }
    displayBondVectors(displayableVectorsPtr);
}

/**
 * Return a list with a menu item for each fragment set, specifying a click handler
 * to run with the chosen fragset.
 * @param {CaseData} caseData
 * @param {(FragservFragset)=>void} actOnFragsetFn handler to operate on clicked-on fragset
 * @param {boolean} needToBeLoaded Whether the fragments have to already have been loaded for search
 * @returns
 */
function actionableFragsetMenuItems(caseData, actOnFragsetFn, options={}) {
    const { needToBeAvailable, getLabel, getTitle } = options;
    const shortListLength = 5;
    const overflowClass = 'autogrow-overflow';
    const fsMenuItems = [];
    const fragsets = actionableFragmentSetInfo(caseData);
    fragsets.forEach(({ fragset, ...counts }, index) => {
        if (needToBeAvailable && counts.available === 0) {
            return;
        }
        const moreClasses = index >= shortListLength ? overflowClass : '';
        fsMenuItems.push({
            itemType: 'item',
            label: getLabel ? getLabel(fragset.name, counts) : fragset.name,
            className: `menu-item ${moreClasses}`,
            // TODO: Make titles work in CanvasContextMenu. Make configurable here.
            title: getTitle ? getTitle(fragset.name): '',
            onClick() { if (actOnFragsetFn) actOnFragsetFn(fragset); },
        });
        if (index === (shortListLength - 1) && fragsets.length > shortListLength) {
            // TODO: Make this work to put overflow items into child menu.  Requires updating
            // CanvasContextMenu to run the same processing on child and parent menu items.
            // fsMenuItems.push({ overflowToggle: overflowClass });
        }
    });

    if (fsMenuItems.length === 0) {
        // Note: CanvasContextMenu doesn't process child items the same way as parent items,
        // so this scheme of making a disabled menu item via grouphead doesn't work.
        fsMenuItems.push({ itemType: 'message', label: 'No fragment sets available' });
    }
    return fsMenuItems;
}

/**
 * Return information about fragment sets with available fragments
 * @return {Array} of objects of type:
 * - name
 * - fragset
 * - available - number of fragments in the fragset with simulation data on this structure
 * - searchable - number of available fragments currently loaded in server memory for searching
 * - total - number of fragments defined in the fragment set (whether or not sim data is available)
 *
 * Usually about 200 fragments are loaded for search. You can change it with the fragment manager.
 * TODO: consider moving this to FragmentData
 */
function actionableFragmentSetInfo(caseData) {
    const fragData = App.Workspace.fragmentData;
    const allFragsets = fragData.getFragsets();
    const availableFragmentInfo = caseData.availableFragmentInfo;
    const fragsetInfo = allFragsets.map((fs) => {
        const availableFrags = fragData.availableFragmentsInFragset(availableFragmentInfo, fs);
        const searchableFrags = availableFrags.filter(App.Workspace.isActive);
        return {
            fragset: fs,
            available: availableFrags.length,
            searchable: searchableFrags.length,
            total: fs.items.length,
        };
    });

    // Sort alphabetically within the same fragset type, otherwise by type priority
    fragsetInfo.sort(({ fragset: fs1 }, { fragset: fs2 }) => {
        if (fs1.fragsetType === fs2.fragsetType) {
            return (fs1.name.toUpperCase() < fs2.name.toUpperCase()) ? -1 : 1;
        } else {
            const orderedTypes = [
                FragservFragset.TypeUser,
                FragservFragset.TypeStandard,
                FragservFragset.TypeClustering,
                FragservFragset.TypeBuiltin,
            ];
            const priority1 = orderedTypes.indexOf(fs1.fragsetType);
            const priority2 = orderedTypes.indexOf(fs2.fragsetType);
            return priority1 - priority2;
        }
    });
    return fragsetInfo;
}

/**
 * Menu handler for bond vector menu
 * @param {Object} param0 menu command parameters {cmd, arg, startuid, enduid}
 */
async function doVectorMenuCmd(cmd, startAtom, endAtom, arg) {
    const allCmpds = App.Workspace.getVisibleCompounds();
    const operableVectorAtoms = [];
    selectedVectors.forEach(({ atomPair }) => {
        AtomVectorList.addVector([atomPair[0], atomPair[1]], operableVectorAtoms);
    });
    AtomVectorList.addVector([startAtom, endAtom], operableVectorAtoms);

    if (cmd === 'autoreplace') {
        let groups = [];
        switch (arg) {
            case 'halogens':
                groups = [...HalogenGroups];
                break;
            case 'all':
            default:
                groups = [...TerminalGroups];
        }
        const atomFilter = (a) => (
            operableVectorAtoms.find(([_, end]) => a === end)
        );

        UserActions.ReplaceAllGroups(allCmpds, atomFilter, groups);
    } else if (cmd === 'autogrow') {
        // Prepare autogrow arguments: atomFilterFn and growSpecs
        const atomFilterFn = (a) => operableVectorAtoms.find(
            ([start, end]) => a === start || a === end
        );
        const vectorFn = ([a1, a2]) => operableVectorAtoms.find(
            ([start, end]) => a1 === start && a2 === end
        );
        const growSpecs = { vectorFn };
        if (arg) {
            const fs = App.Workspace.fragmentData.fragsetById(arg);
            if (fs) {
                growSpecs.fragmentSets = [fs];
            } else {
                showAlert('Error: Failed to find fragments for the requested fragment set', 'Multi-grow');
                return;
            }
        }
        // Ok, time to perform the autogrow
        const results = await UserActions.GrowFromAllAtoms(allCmpds, atomFilterFn, growSpecs);
        if (results.length === 0) {
            showAlert('No candidates found.', 'Multi-grow');
        }
    }
}

// Variables necessary for managing the functional group highlights
let highlightState = false;
let highlightAtom = null;
let highlightAtomSphere = null;

/**
 * Show or hide highlights around compound functional groups
 * @param {*} show whether or not to show the highlights
 * @returns
 */
function highlightFunctionalGroups(show) {
    if (show === highlightState) return;
    else highlightState = show;

    const activeCompound = App.Workspace.getActiveCompound();
    if (show && activeCompound) {
        const groups = activeCompound.getFunctionalGroups();
        displayFunctionalGroupHighlight(true, groups);
    } else {
        displayFunctionalGroupHighlight(false);
    }
    showAllCompoundHydrogens(App.Workspace.getVisibleCompounds(), show);
    redisplay();
}

/**
 * Temporarily show (or stop showing) all compound Hydrogens, in response to H keyDown/keyUp.
 * Usually only polar Hs are shown
 * @param {*} compounds the compounds to show all compound Hs for
 * @param {*} show whether or not to show all hydrogens
 */
function showAllCompoundHydrogens(compounds, show) {
    // When leaving "H" mode, display hydrogens according to display state
    let showAnyway = (h, style) => MolStyles.hidden;
    if (visibleState.displayState.hydrogens === HydrogenDisplayOptions.all) {
        showAnyway = (h, style) => style;
    } else if (visibleState.displayState.hydrogens === HydrogenDisplayOptions.polar) {
        showAnyway = (h, style) => (isNonPolarHydrogen(h) ? MolStyles.hidden : style);
    }

    for (const compound of compounds) {
        const active = compound === App.Workspace.getActiveCompound();
        const allHydrogens = onlyHydrogens(compound.getAtoms());

        for (const h of allHydrogens) {
            const style = active ? MolStyles.ballandstick : MolStyles.sticks;
            setAtomStyle(h, show ? style : showAnyway(h, style));
        }
    }
}

/**
 * Show a highlight around one atom. This is used if you hold down R and hover over atoms.
 * It's not clear what the purpose is.
 * @param {*} atom
 * @param {*} show
 * @returns
 */
function highlightOneAtom(atom, show) {
    if (highlightAtom) {
        if (highlightAtom !== atom) {
            // Need to removing existing highlight before adding the new one
            removeSphere(highlightAtomSphere);
            highlightAtom = null;
            highlightAtomSphere = null;
        } else if (show) {
            return; // Atom is already highlighted, nothing else to do;
        }
    }

    if (show) {
        const center = atom.getPosition({ as: 'object' });
        const color = 0x8FAADC;
        highlightAtom = atom;
        highlightAtomSphere = drawSphere(center, 0.65, color, 0.6);
    } else if (highlightAtom) {
        removeSphere(highlightAtomSphere);
        highlightAtom = null;
        highlightAtomSphere = null;
    }
    redisplay();
}

/**
 * Display or hide water atoms
 * @param {*} waterStyle
 */
function displayWaters(waterStyle) {
    const waterAtomGroups = App.Workspace.getAllWaters();
    const visibleWaterInfo = App.Workspace.getVisibleWaterInfo();
    const bindingSite = visibleState.displayState.bindingsite && App.Workspace.getBindingSite();

    // Start by removing all halos (computed water energy highlights and crystal spheres)
    // They will be added below if necessary
    displayWaterHalo(false);

    for (const water of waterAtomGroups) {
        const isVisible = getWaterVisibility(water, waterStyle, visibleWaterInfo, bindingSite);
        if (water.type === AtomGroupTypes.ComputedWater) {
            displayComputedWater(isVisible, water);
            displayComputedWaterHalo(isVisible, water);
        } else {
            displayCrystalWater(isVisible, water);
        }
    }
}

/**
 * Redisplay fragments according to their visibility in the left-hand Fragment Selector
 */
function displayFragments() {
    const allFragments = App.Workspace.getFragments();
    const visibleFragments = App.Workspace.getVisibleFragments();
    const bindingSite = visibleState.displayState.bindingsite && App.Workspace.getBindingSite();

    for (const frag of allFragments) {
        const visible = getFragmentVisibility(frag, visibleFragments, bindingSite);
        const { molStyle, colorStyle } = getAtomGroupStyle(frag, App.Workspace, bindingSite);
        if (visible) addTo3D(frag);
        for (const atom of frag.atoms) {
            displayAtom(atom, visible, molStyle, colorStyle);
        }
    }
}

/**
 * Display or Hide Hydrogen Bonds
 * @param {Boolean} show whether or not to show the hbonds
 * Note: Need to call redisplay() after calling
 */
function showHBonds(show) {
    displayHiddenHBondAtoms(show); // must be visible before bonds
    displayHBonds(visibleState.displayState.hbonds !== HBondStates.none
        && visibleState.displayState.bindingsite
        && show,
    visibleState.displayState.waters === WaterStates.computed
        || visibleState.displayState.waters === WaterStates.all,
    visibleState.displayState.hbonds === HBondStates.includeWeak);
}

/** Change display of ordinarily hidden atoms that are part of hbonds (waters & hydrogens) */
function displayHiddenHBondAtoms(show) {
    // Since getBindingSite() actually will calculate a binding site if there isn't one
    // But we don't want to do that before we've received water maps.
    // This condition protects against it.  There's probably a better way to organize things.
    if (visibleState.displayState.bindingsite) {
        const showit = visibleState.displayState.hbonds !== HBondStates.none && show;
        const bindingSite = App.Workspace.getBindingSite();
        const haveHBonds = bindingSite && bindingSite.features && bindingSite.features.hbonds;
        if (haveHBonds) {
            const hbonds = bindingSite.features.hbonds;
            // This doesn't seem desirable.
            // displayHiddenHBondWaters(hbonds, show);
            displayHiddenHBondHydrogens(hbonds, show);
        }
    }
}

/**
 * Display waters connected to hbonds, even if computed waters aren't visible
 * TODO figure out if this unused function should be removed
 */
function displayHiddenHBondWaters(hbonds, show) {
    // This is only necessary if our water display state hides computed waters
    if (visibleState.displayState.waters === WaterStates.none
        || visibleState.displayState.waters === WaterStates.crystal) {
        const newState = show ? WaterStates.computed : WaterStates.none;
        let watersToDisplay = [];

        for (const hbond of hbonds) {
            const [atom, partners] = hbond;
            for (const [partner, energy] of partners) {
                if (isComputedWaterAtom(partner)) {
                    watersToDisplay = watersToDisplay.concat(partner.fragment.atoms);
                }
            }
        }

        throw new Error('displayWaterAtoms was removed in favor of displayWaters');
    }
}

/**
 * Show or hide hbond Hs that are normally hidden by current display settings
 * @param {Array} hbonds the hbonds to consider
 * @param {Boolean} show whether or not to show the normally-hidden Hs
 */
function displayHiddenHBondHydrogens(hbonds, show) {
    const newStyle = show ? MolStyles.sticks : MolStyles.hidden;
    for (const hbond of hbonds) {
        const [atom, partners] = hbond;
        const atoms = [atom].concat(partners.map((x) => x[0]));
        for (const a of atoms) {
            if (isHydrogen(a) && !localHydrogenCheck(a)) {
                setAtomStyle(a, newStyle);
            }
        }
    }
}

/**
 * Adjust the binding size radius in response to ctrl-mousewheel.
 * TODO rename this function
 * @param {*} zoomDelta
 */
function handleZoom(zoomDelta) {
    if (visibleState.displayState.bindingsite) {
        // Scrolling the mousewheel down produces positive zoomDeltas, scrolling up has negative
        // zoomDelta
        // Interpret it so that scrolling up has positive sign (like you are increasing the binding
        // site radius).
        const zoomStepSizeAng = 0.5;
        const angDelta = zoomDelta < 0 ? zoomStepSizeAng : -1 * zoomStepSizeAng;
        UserActions.StepBindingSiteRadius(angDelta);
    }
}

/**
 * Respond to clicks in the 2d control
 * Not currently used
*/
function onAtomMouse2d(bmapEventName, eventData) {
    const atomName = eventData.atomName;
    for (const atom of App.Workspace.getActiveCompoundAtoms()) {
        if (atom.atom === atomName) {
            onAtomMouse('atomMouse', { atom, mouseEvent: eventData.mouseEvent });
            break;
        }
    }
}

/// // Mouse Handling
/**
 * Mouse handler for Atoms
 * @param {*} bmapEventName ignored
 * @param {Object} eventData { atomUniqueID, mouseEvent }
 *
 * @todo rename bmapEventName to _ or something else
 */
function onAtomMouse(bmapEventName, eventData) {
    const selected = eventData.atom;
    const event = eventData.mouseEvent;

    const keys = {
        ctrlKey: event.ctrlKey,
        shiftKey: event.shiftKey,
        altKey: event.altKey,
    };
    /*
        console.log(`
            ${event.type} (ctrl,shift,alt,button) = (${keys.ctrlKey ? 'Y' : 'N'},
                ${keys.shiftKey ? 'Y' : 'N'},
                ${keys.altKey ? 'Y' : 'N'},
                ${button}) atom (uid ${atomUniqueID}) ${description}
        `);
    */

    const gesture = getMouseGesture(event);
    switch (gesture) {
        case 'click':
            if (!modModeMouseEvent(selected, event) && canSelectAtom(selected)) {
                const ribbonResidue = getRibbonResidue(selected);
                if (ribbonResidue && !App.Workspace.isSelectedAtom(selected) && !keys.ctrlKey) {
                    // Select whole residue if we're clicking on a ribbon.
                    // But if the atom is already selected (or ctrl is down), just send
                    // select for the one atom and let bfd-server handle the expansion.
                    UserActions.SelectAtom(ribbonResidue.getAtoms(), keys);
                } else {
                    UserActions.SelectAtom(selected, keys);
                }
            }
            break;
        case 'enter':
            modModeMouseEvent(selected, event);
            break;
        case 'leave':
            modModeMouseEvent(selected, event);
            break;
        // no default
    }
}

/**
 * Mouse handler for shapes (non-atoms like spheres and bond vectors)
 * @param {String} _ bmaps event name, ignored
 * @param {Object} eventData {selected, mouseEvent}, selected is the target Shape
 */
function onShapeMouse(_, eventData) {
    const shape = eventData.selected;
    const event = eventData.mouseEvent;
    if (shape.onClick) {
        shape.onClick(shape, event);
    }
}

/**
 * Get a Residue atomgroup for an atom that is expected to be in a cartoon state
 * @param {Atom} atom
 * @returns {Residue?}
 */
function getRibbonResidue(atom) {
    const { atomGroups } = App.Workspace.atomGroupsForAtom(atom);
    const residue = atomGroups.find((ag) => ag.type === AtomGroupTypes.Residue);
    if (!residue) {
        return false;
    }
    const bindingSite = visibleState.displayState.bindingsite;
    const { molStyle } = getAtomGroupStyle(residue, App.Workspace, bindingSite);
    if ([MolStyles.cartoon, MolStyles.tribbons].includes(molStyle)) {
        return residue;
    } else {
        return null;
    }
}

/**
 * Can the user select this atom?
 * This protects against selecting atoms in hotspot clusters.
 * Hotspots are visualized around atoms which are invisible, hoverable, but not clickable.
 * So the user should not be able to click and select them.
 * @param {*} atom An atom to check
 * @returns {Boolean} Is this a user selectable atom?
 */
function canSelectAtom(atom) {
    return atom && !(atom.fragment && atom.fragment.subtype === 'cluster');
}

/**
 * Is this atom part of a molecule which can be modified?
 * ie: not protein, water, cofactor, fragment etc
 * @param atom Atom to check
 * @returns {Boolean}
 * */
function canModifyMolType(atom) {
    const growableMolTypes = [undefined, 'small_molecule'];
    return atom.hetflag && atom.residue && growableMolTypes.includes(atom.residue.molType);
}

function getStyleMenuItems(atom, atomGroups) {
    const ret = [];
    if (!atomGroups || atomGroups.length === 0) return ret;

    const allowed = [
        AtomGroupTypes.Protein, AtomGroupTypes.Polymer,
        AtomGroupTypes.Residue,
        AtomGroupTypes.Compound, AtomGroupTypes.Ligand,
        AtomGroupTypes.Cofactor, AtomGroupTypes.Ion,
        AtomGroupTypes.Fragment,
    ];

    const colorMenuItems = (atomGroup, carbonsOnly) => Object.entries(ColorChain)
        .map(([name, color]) => ({
            itemType: 'item',
            content: (
                // eslint-disable-next-line react/jsx-filename-extension
                <>
                    <div
                        style={{
                            width: '1em',
                            height: '1em',
                            borderRadius: '20%',
                            marginRight: '0.5em',
                            backgroundColor: `#${color.toString(16).padStart(6, '0')}`,
                        }}
                    />
                    {name}
                </>
            ),
            onClick() {
                if (name === 'Default Color') {
                    StyleManager.removeCustomAtomGroupColor(atomGroup);
                } else {
                    StyleManager.setCustomAtomGroupColor(atomGroup, { color, carbonsOnly });
                }
                resetDisplay();
                console.log(`AtomGroup ${atomGroup.type} Color change to ${color}`);
            },
        }));

    // Mol style menu
    const molStylesToAllow = {
        Default: 'Default Style',
        [MolStyles.hidden]: 'Hide',
        [MolStyles.invisible]: 'Invisible',
        [MolStyles.wireframe]: 'Wireframe',
        [MolStyles.sticks]: 'Sticks',
        [MolStyles.ballandstick]: 'Ball and stick',
        [MolStyles.spacefill]: 'Spacefill',
        [MolStyles.cartoon]: 'Ribbons',
        [MolStyles.tribbons]: 'Transparent Ribbons',
    };
    const polymerOnly = [MolStyles.cartoon, MolStyles.tribbons];
    const notForIons = [MolStyles.wireframe, MolStyles.sticks];
    const molStyleMenuItems = (atomGroup) => Object.entries(molStylesToAllow)
        .map(([style, label]) => {
            if (polymerOnly.includes(style) && !atomGroups.find((ag) => ag instanceof Polymer)) {
                return false;
            } else if (notForIons.includes(style) && atomGroup.type === AtomGroupTypes.Ion) {
                return false;
            }

            return {
                itemType: 'item',
                content: label,
                onClick() {
                    setAtomGroupMolStyle(atomGroup, style);
                    resetDisplay();
                },
            };
        });

    const unsupportedMenu = () => [{ itemType: 'message', label: 'Not yet supported' }];

    for (const atomGroup of atomGroups) {
        let colorChildren = unsupportedMenu();
        if (allowed.includes(atomGroup.type)) {
            colorChildren = colorMenuItems(atomGroup, false);
            if (atomGroup.type === AtomGroupTypes.Ion) {
                ret.push({ itemType: 'nested', label: `Color ${atomGroup.type}`, content: colorChildren });
            } else {
                ret.push({ itemType: 'nested', label: `Color ${atomGroup.type} (carbons)`, content: colorMenuItems(atomGroup, true) });
                ret.push({ itemType: 'nested', label: `Color ${atomGroup.type} (all)`, content: colorChildren });
            }
            // Add style menu
            ret.push({ itemType: 'nested', label: `Style ${atomGroup.type}`, content: molStyleMenuItems(atomGroup) });
        }
        // ret.push({ text: `Color ${atomGroup.type}`, children: colorChildren });
        // ret.push({ text: 'Color Carbons', children: colorMenuItems(atomGroup, true) });
        // ret.push({ text: `Style ${atomGroup.type}`, children: unsupportedMenu() });
    }

    return ret.length === 0 ? [] : [
        { itemType: 'grouphead', label: 'Style' },
        ...ret,
        { itemType: 'separator' },
    ];
}

/**
 * Construct a context menu for the given atom
 * @param {Atom} atom
 * @param {MouseEvent} rightClickEvent
 *
 * This menu system is very awkward and needs to be replaced or refactored.
 */
export function buildContextMenuForAtom(atom, rightClickEvent, closeMenu) {
    const compounds = App.Workspace.getVisibleCompounds();
    const { caseData } = App.getDataParents(atom);
    const { atomGroups } = App.Workspace.atomGroupsForAtom(atom);

    const findNearOnly = (compounds.length === 0) || !canModifyMolType(atom);
    const [canReplace, canGrow, canSubstRing, unavailable] = findNearOnly
        ? [] : canModify(compounds, atom);
    let noGrow = unavailable || findNearOnly || !canGrow; // what about canSubstRing?
    const modifierKeys = [
        rightClickEvent.ctrlKey, rightClickEvent.shiftKey, rightClickEvent.altKey,
    ];
    const bondVectorPair = noGrow ? [] : findBondVectorPair(atom, modifierKeys);
    noGrow = noGrow || !bondVectorPair || bondVectorPair.length !== 2;
    if (!noGrow) {
        displayBondVectors([new BondVectorDisplay(bondVectorPair)]);
    }

    const menuItems = [];

    const styleMenu = getStyleMenuItems(atom, atomGroups);
    menuItems.push(...styleMenu);

    // Some of these warnings seem to belong with the fragment menu items
    // Temporary for demo, until implemented.
    if (canSubstRing) {
        menuItems.push({ itemType: 'grouphead', label: 'Ring Substitution' });
        menuItems.push({ itemType: 'message', label: 'Ring substitutions are not yet supported' });
    } else if (unavailable) {
        menuItems.push({ itemType: 'grouphead', label: 'Grow' });
        if (atom.ringOrdinals) {
            menuItems.push({ itemType: 'message', label: "Can't grow from this atom" });
        } else if (atom.bonds.length > 0) { // Don't include this item for unrecognized ions
            menuItems.push({ itemType: 'message', label: 'Only single-bonded atom replacements are supported' });
        }
    }

    // Add "replace" menu items
    if (canReplace) {
        const replaceItems = [];
        replaceItems.push({ itemType: 'grouphead', label: 'Replace Terminal' });

        const childMenuItems = [];
        const shortListLength = 4;
        for (const [i, terminalGroup] of TerminalGroups.entries()) {
            const { name, formula } = terminalGroup;
            const overflowClass = (i >= shortListLength) ? 'atom-menu-overflow' : '';
            const item = {
                itemType: 'item',
                label: ` ${formula} - ${name}`,
                className: overflowClass,
                onClick() {
                    hideAllBondVectors();
                    UserActions.ReplaceGroup(atom, name);
                },
            };
            // Overflow items go in submenu
            if (i >= shortListLength - 1) {
                childMenuItems.push(item);
            } else {
                replaceItems.push(item);
            }
        }
        if (childMenuItems.length > 0) replaceItems.push({ itemType: 'nested', label: 'More Replacements', content: childMenuItems });
        menuItems.push(...replaceItems);
        menuItems.push({ itemType: 'separator' });
    }

    // Add "fragments" menu items
    const fragmentsMenu = getFragmentsMenuItems(atom, bondVectorPair, noGrow, closeMenu);
    menuItems.push(...fragmentsMenu);

    // Dev only feature which is NYI: replace with fragment set (doesn't use fragment data)
    // This can apply to either "replace" or "grow" vectors.
    if (Loader.AllowDevFeatures && (canReplace || !noGrow)) {
        const fsReplaceFn = async (fragset) => {
            const [fromAtom, toAtom] = bondVectorPair;
            const { errors } = await UserActions.ReplaceWithFragmentSet(
                fromAtom, toAtom, fragset,
            );
        };
        const fsReplaceItems = actionableFragsetMenuItems(caseData, fsReplaceFn, {
            needToBeAvailable: false,
            getLabel(fsName, counts) { return `${fsName} (${counts.total} frags)`; },
            getTitle(fsName) { return `Replace with all fragments from fragment set ${fsName}`; },
        });
        menuItems.push({ itemType: 'separator' });
        menuItems.push({
            itemType: 'nested',
            label: 'Replace with fragment set',
            title: 'replace terminal with each fragment in fragment set',
            content: fsReplaceItems,
        });
    }
    const ret = { menuItems };

    return ret;
}

/**
 * Return fragment related atom menu items:
 *      Grow options, Find Near options, Fragment Data Query, Manage Fragments
 * @param {Atom} atom clicked-on atom
 * @param {[Atom, Atom]} bondVectorPair vector for the grow direction
 * @param {boolean} noGrow is grow disabled for any reason (esp for NYI chemistry)
 * @param {function} closeMenu function to close the menu (only used for Fragment Data Query)
 * @returns {object[]} Array of menu item objects
 */
function getFragmentsMenuItems(atom, bondVectorPair, noGrow, closeMenu) {
    const menuItems = [];
    const { caseData } = App.getDataParents(atom);
    const fragList = caseData.getAvailableFragmentInfo();
    const { atomHasFragments, workspaceHasFragments, workspaceIsLoading } = haveFragmentData(atom);

    if (!App.Workspace.isProteinLoaded()) {
        // If there is no protein, there are no fragments.
        return [];
    }
    menuItems.push({ itemType: 'grouphead', label: 'Fragments' });

    // No fragments at all case - return early
    if (!workspaceHasFragments) {
        if (workspaceIsLoading) {
            menuItems.push({ itemType: 'message', label: 'Determining available fragments...' });
        } else {
            menuItems.push({ itemType: 'message', label: 'No fragments available for this structure' });
            menuItems.push({
                itemType: 'item',
                label: 'Run Fragments Simulation',
                title: 'Start new simulations for water maps, compound modification opportunities or hotspot analysis.',
                onClick() { UserActions.ShowFragmentPane('fragment'); },
            });
        }
        return menuItems;
    }

    // Helper functions to menu click handlers
    function getGrowFn(arg, scope) {
        return () => doFragSearch({
            cmd: 'grow', arg, scope, bondVectorPair, fragList,
        });
    }

    function getNearFn(scope) {
        return () => doFragSearch({
            cmd: 'near', scope, atom, fragList,
        });
    }

    function getFsSearchFn(cmd) {
        const arg = cmd === 'grow' ? 'Bond' : null;
        const scope = 'all';
        return (fragset) => doFragSearch({
            cmd, arg, scope, atom, bondVectorPair, fragset, fragList,
        });
    }

    // Add Fragment Grow and Search Nearby menu options
    if (atomHasFragments) {
        if (!noGrow) {
            const growItems = [];
            // The following menu items send `Bond` search arg, which returns all link types.
            // Originally we supported specific arg values: Bond | CXC | AmideBond | Acetylene
            //--- Maybe also `Urea` supported by bfd-server and `AmideSyn`, maybe in overflow menu?
            growItems.push({
                itemType: 'item',
                label: ' Grow with fragment (best pose)',
                className: 'menu-item',
                title: 'Show the best pose for each fragment that can attach at this vector.\nNote: this only considers fragments marked "Searchable" in Manage Fragments.',
                onClick: getGrowFn('Bond', 'best'),
            });
            growItems.push({
                itemType: 'item',
                label: ' Grow with fragment (distribution)',
                className: 'menu-item',
                title: 'Show the distribution of poses for each fragment that can attach at this vector.\nNote: this only considers fragments marked "Searchable" in Manage Fragments.',
                onClick: getGrowFn('Bond', 'all'),
            });

            const fsGrowItems = actionableFragsetMenuItems(caseData, getFsSearchFn('grow'), {
                needToBeAvailable: true,
                getLabel(fsName, counts) { return `${fsName} (${counts.searchable} searchable frags)`; },
                getTitle(fsName) { return `Grow with fragments in fragment set ${fsName}.\nNote: this only considers fragments marked "Searchable" in Manage Fragments.`; },
            });
            growItems.push({ itemType: 'nested', label: 'Grow with Fragment Set...', content: fsGrowItems });
            menuItems.push({
                itemType: 'nested',
                label: ' Grow with fragment...',
                content: growItems,
                className: 'menu-item',
            });
        }

        // Search Nearby / Find Near menu items
        {
            const searchItems = [];
            searchItems.push({
                itemType: 'item',
                label: 'Search Nearby (best pose)',
                title: 'Show the best pose for each free-standing fragment near this location.\nNote: this only considers fragments marked "Searchable" in Manage Fragments.',
                onClick: getNearFn('best'),
            });
            searchItems.push({
                itemType: 'item',
                label: ' Search Nearby (distribution)',
                title: 'Show the distribution of poses for each free-standing fragment near this location.\nNote: this only considers fragments marked "Searchable" in Manage Fragments.',
                onClick: getNearFn('all'),
            });

            const searchNearItems = actionableFragsetMenuItems(caseData, getFsSearchFn('near'), {
                needToBeAvailable: true,
                getLabel(fsName, counts) { return `${fsName} (${counts.searchable} searchable frags)`; },
                getTitle(fsName) { return `Search nearby fragments in fragment set ${fsName}.\nNote: this only considers fragments marked "Searchable" in Manage Fragments.`; },
            });
            searchItems.push({
                itemType: 'nested',
                label: 'Search with Fragment Set...',
                content: searchNearItems,
            });
            menuItems.push({
                itemType: 'nested',
                label: ' Search nearby fragments...',
                content: searchItems,
            });
        }
    }

    // Add Fragment Data Query menu item
    menuItems.push({
        itemType: 'nested',
        label: ' Fragment data query',
        title: 'Run a fragment data query',
        content: <FragDataMenuControl
            caseDatas={App.Workspace.allCaseData()}
            fragData={App.Workspace.fragmentData}
            initialFromAtom={noGrow ? atom : bondVectorPair[0]}
            initialToAtom={noGrow ? null : bondVectorPair[1]}
            close={closeMenu}
        />,
    });

    // Add Manage Fragments menu item
    menuItems.push({
        itemType: 'item',
        label: 'Manage Fragments',
        title: 'Open the Fragment Manager to specify which fragments are searchable and which are used for hotspot analysis',
        onClick() { UserActions.OpenFragmentManager(); },
    });

    return menuItems;
}

/**
 * Execute a bfd-server-based fragment search (Grow or Find Near) and display the search results.
 * @param {{
 *     cmd: string, arg: string, scope: string,
 *     atom: Atom, bondVectorPair: [Atom, Atom],
 *     fragset: FragservFragset,  fragList: FragList,
 * }} param0
 * cmd: grow | near
 * arg: Bond (grow only)
 * scope: best | all
 * fragset: used to restrict search results by fragment set
 * atom: the clicked-on atom
 * bondVectorPair: [fromAtom, toAtom]
 * fragList: passed to <LigandMod> search results (needed by bfd-server case to look up frag info)
 */
async function doFragSearch({
    cmd, arg, scope, fragset, atom, bondVectorPair, fragList,
}) {
    let sourceAtom;
    let { suggestions, fragments, errors } = {};

    if (cmd === 'grow') {
        sourceAtom = bondVectorPair[0];
        ({ suggestions, fragments, errors } = await UserActions.GrowFromAtom(
            bondVectorPair, arg, scope
        ));
    } else { // cmd === 'near'
        sourceAtom = atom;
        ({ suggestions, fragments, errors } = await UserActions.SearchNear(atom, scope));
    }

    if (fragset) {
        const fragNames = fragset.items.map((x) => x.name);
        suggestions = suggestions.filter((y) => fragNames.includes(y.name));
    }
    EventBroker.publish('displaySuggestions', {
        suggestions, fragments, errors, sourceAtom, cmd, fragList,
    });
}

/**
 * Handle mouse events on the background of the canvas
 * @param {String} eventName ignored
 * @param {MouseEvent} event
 */
function onBackgroundMouse(eventName, event) {
    const keys = {
        ctrlKey: event.ctrlKey,
        shiftKey: event.shiftKey,
        altKey: event.altKey,
    };

    if (event.type === 'zoom') {
        // Handle custom Zoom event
        const evtData = event.detail;
        if (evtData.ctrlKey) {
            handleZoom(evtData.delta);
        } else if (evtData.shiftKey) {
            // Adjust slab
        }
    } else {
        /*
            console.log(`
                ${event.type} (ctrl,shift,alt,button) = (${keys.ctrlKey
                    ? 'Y' : 'N'},${keys.shiftKey
                    ? 'Y' : 'N'},${keys.altKey
                    ? 'Y' : 'N'},${event.button}) on background
            `)
        */
        if (!event.button) { // left click (button 0) or touch (button undefined)
            UserActions.SelectAtom(null, keys);
        }
    }
}

/// / Functions to display atoms ////
// There are cases for displaying:
//      waters
//      compounds (just active or all of them)
//      solute (protein, cofactor + ion)
// These all take an atoms parameter so they can be called in different contexts

/**
 * Display a computed water.
 * If visible, the water is shown in sticks.
 * In multi-protein scenario, hydrogens are colored per the protein base color.
 * This does not support custom molecule styles.
 * @param {boolean} isVisible
 * @param {MolAtomGroup} water
 */
function displayComputedWater(isVisible, water) {
    if (isVisible) addTo3D(water);
    for (const atom of water.getAtoms()) {
        const showit = isVisible && atom.atom !== 'LP' && waterChemPotential(atom) < 0;
        setAtomStyle(atom, showit ? MolStyles.sticks : MolStyles.hidden);
        if (showit && atom.elem === 'H') {
            const colorStyle = getAtomGroupColorStyle(water);
            const color = colorStyle?.color || 'default';
            setAtomColor(atom, color);
        }
    }
}

/**
 * Display semi-quantitative energy halo for a computed water.
 * Note: This will NOT hide an existing halo, it can only show one.
 * `displayWaterHalo(false)` will remove ALL halos.
 * @param {boolean} isVisible
 * @param {MolAtomGroup} water
 */
function displayComputedWaterHalo(isVisible, water) {
    if (isVisible) {
        const haloParams = {
            onlyShowStrongWaterHalos: !visibleState.displayState.bindingsite,
        };
        displayWaterHalo(true, water.getAtoms(), haloParams);
    }
}

/**
 * Display a crystal water.
 * This is displayed as a transparent sphere (ie "halo"), not an atom (the atom style is hidden).
 * In the multi-protein scenario, it will be colored accordingt to the protein base color.
 * Note: This will NOT hide an existing crystal water halo; it can only show one.
 * @param {boolean} isVisible
 * @param {MolAtomGroup} water
 */
function displayCrystalWater(isVisible, water) {
    for (const atom of water.getAtoms()) {
        if (isVisible && atom.elem !== 'H') {
            const colorStyle = getAtomGroupColorStyle(water);
            const color = colorStyle?.color || 'default';
            displayOneWaterHalo(atom, null, color);
        }
    }
}

function displayAll() {
    displayCompounds();
    displayComponents();
    displayFragments();
    displayWaters(visibleState.displayState.waters);

    const selectedAtoms = App.Workspace.getSelectedAtoms();
    for (const selectedAtom of selectedAtoms) {
        setAtomSelected(selectedAtom, true);
    }
}

/**
 * Display compounds. The Focus (active) compound is displayed in "ball and stick"
 * other visible compounds are just in sticks.
 * @param {*} compounds Compounds to display, default is the
 */
function displayCompounds(compounds=App.Workspace.getLoadedCompounds()) {
    const workspace = App.Workspace;

    for (const cmpd of compounds) {
        const visible = getCompoundVisibility(cmpd, workspace);
        const { molStyle, colorStyle } = getAtomGroupStyle(cmpd, workspace);
        if (visible) addTo3D(cmpd);
        for (const atom of cmpd.getAtoms()) {
            displayAtom(atom, visible, molStyle, colorStyle);
        }
    }
}

/**
 * Add a group of atomGroups to the 3D canvas
 * @param {*} atomGroupsIn
 */
function addTo3D(atomGroupsIn=[]) {
    const atomGroups = ensureArray(atomGroupsIn);
    const atoms = new Set();
    const bonds = new Set();
    for (const atomGroup of atomGroups) {
        for (const atom of atomGroup.getAtoms()) {
            if (!getCanvasAtom(atom)) {
                atoms.add(atom);
                atom.getBonds().forEach((bond) => bonds.add(bond));
            }
        }
    }
    addCanvasAtoms([...atoms], [...bonds]);
}

/**
 * Display main structure components, but not compounds or waters:
 * - protein chains
 * - other polymers (reference proteins and peptides)
 * - cofactors
 * - ions
 * - hotspots
 * @param {*} toShow
 */
function displayComponents(toShow) {
    const workspace = App.Workspace;
    const {
        chains, polymers, cofactors, ions, hotspots,
    } = (toShow ? {
        chains: [],
        polymers: [],
        cofactors: [],
        ions: [],
        hotspots: [],
        ...toShow,
    } : {
        chains: workspace.getProteinChains(),
        // next line: includes peptides, but their atoms are categorized differently
        polymers: workspace.getPolymers(),
        cofactors: workspace.getCofactors(),
        ions: workspace.getIons(),
        hotspots: workspace.getFilteredAllHotspots(),
    });
    const residues = Polymer.residuesInPolymers([...chains, ...polymers]);
    const components = [...residues, ...cofactors, ...ions];
    let bindingSite = visibleState.displayState.bindingsite && workspace.getBindingSite();

    console.log(`Updating display of ${components.length} components: ${residues.length} residues (${chains.length} chains, ${polymers.length} polymers), ${cofactors.length} cofactors, ${ions.length} ions, ${hotspots.length} hotspots`);

    // Collect visible hotspots because they are used for binding site reference atoms below
    const visibleHotspots = hotspots.filter((hs) => (
        (visibleState.displayState.hotspots || workspace.isVisible(hs))
        && workspace.isFilteredHotspot(hs)
        && (!bindingSite || bindingSite.isComponentInRange(hs))
    ));

    // If there are visible hotspots, we'll display solute atoms near the hotspots too,
    // so create an extended binding site.
    // TODO: Should cofactors also be used as reference atoms?
    if (bindingSite) {
        bindingSite = bindingSite.makeExtendedBindingSite(visibleHotspots);
    }

    for (const comp of components) {
        const visible = getComponentVisibility(comp, workspace, bindingSite);
        const { molStyle, colorStyle } = getAtomGroupStyle(comp, workspace, bindingSite);

        if (visible) addTo3D(comp);

        for (const atom of comp.getAtoms()) {
            const colorToUse = colorStyle || atomColorOverride(atom);
            displayAtom(atom, visible, molStyle, colorToUse);
        }
    }

    for (const hotspot of hotspots) {
        const visible = visibleHotspots.includes(hotspot);
        if (visible) addTo3D(hotspot);
        for (const atom of hotspot.getAtoms()) {
            // Hotspot atoms themselves are invisible, but mouse-interactive
            displayAtom(atom, visible, MolStyles.invisible);
        }
        const colorOverride = getAtomGroupColorStyle(hotspot)?.color || undefined;
        displayOneHotspot(visible, hotspot, colorOverride); // Display the hotspot cloud
    }
    cleanUpHotspots(visibleHotspots);

    const doDisplaySurface = bindingSite ? StyleManager.LigandViewProteinStyle === MolStyles.surface
        : StyleManager.ProteinViewProteinStyle === MolStyles.surface;
    displayProteinSurface(doDisplaySurface);
}

/**
 * Default styles for various atom groups with some special cases.
 * @param {AtomGroup} atomGroupIn
 * @param {Workspace} workspace used to determine the active compound
 * @param {BindingSite?} bindingSite used to determine protein style
 */
function defaultAtomGroupStyle(atomGroupIn, workspace, bindingSite) {
    let atomGroup = atomGroupIn;

    if (atomGroup.type === AtomGroupTypes.Residue) atomGroup = atomGroup.getChainGroup();

    switch (atomGroup.molType) {
        case BfdMolTypeCodes.saccharide: // Override default polymer style for sugars
            return MolStyles.sticks;
        // no default, handle below
    }

    switch (atomGroup.type) {
        case AtomGroupTypes.Protein:
            return bindingSite ? StyleManager.LigandViewProteinStyle
                : StyleManager.ProteinViewProteinStyle;
        case AtomGroupTypes.Polymer:
        case AtomGroupTypes.PeptideLigand:
            return MolStyles.cartoon;
        case AtomGroupTypes.Ion:
            return MolStyles.spacefill;
        case AtomGroupTypes.Cofactor:
        case AtomGroupTypes.Fragment:
            return MolStyles.sticks;
        case AtomGroupTypes.Compound:
        case AtomGroupTypes.Ligand: {
            return workspace && atomGroup === workspace.getActiveCompound() ? MolStyles.ballandstick
                : MolStyles.sticks;
        }
        case AtomGroupTypes.ComputedWater:
            // Note: computed waters are displayed by displayWaters, which has different display
            // logic and doesn't use this. But this is called by setAtomGroupMolStyle for waters.
            return MolStyles.sticks;
        case AtomGroupTypes.CrystalWater:
            // Note: crystal waters are displayed by displayWaters, which has different display
            // logic and doesn't use this.
            // Crystal waters are displayed with transparent spheres instead of atoms.
            return MolStyles.hidden;
        default:
            return MolStyles.sticks;
    }
}

/**
 * Get the expected style for a component, given a binding site, Workspace visibility,
 * and user overrides.
 */
function getAtomGroupStyle(component, workspace, bindingSite) {
    // Residues use parent chain group for treeview visibility and first style lookup
    const firstLookComp = (component instanceof Residue) ? component.getChainGroup() : component;

    let molStyle = defaultAtomGroupStyle(firstLookComp, workspace, bindingSite);
    molStyle = maybeUseCustomMolStyle(firstLookComp, molStyle);
    let colorStyle = getAtomGroupColorStyle(firstLookComp);

    if (component instanceof Residue) {
        // Residues first look up their style from their chain group,
        // but they can also have their own custom style, which overrides the chain.
        const residueColor = getAtomGroupColorStyle(component);
        if (residueColor) {
            colorStyle = residueColor;
        }
        molStyle = maybeUseCustomMolStyle(component, molStyle);
    }

    return { molStyle, colorStyle };
}

/**
 * Apply the style to the atom group.
 * If the style is the default style, remove any custom style instead.
 * @param {MolAtomGroup} atomGroup
 * @param {string} style
 */
function setAtomGroupMolStyle(atomGroup, style) {
    const workspace = App.Workspace;
    const bindingSite = visibleState.displayState.bindingsite && workspace.getBindingSite();
    const defaultStyle = defaultAtomGroupStyle(atomGroup, workspace, bindingSite);
    if (style.toLowerCase() === 'default' || style === defaultStyle) {
        StyleManager.removeCustomAtomGroupMolStyle(atomGroup);
        console.log(`Reset ${atomGroup} molstyle to default (${defaultStyle})`);
    } else {
        StyleManager.setCustomAtomGroupMolStyle(atomGroup, style);
        console.log(`Set ${atomGroup} molstyle to ${style}`);
    }
}

// ************************
// Visibility functions
// Visibility has two components:
// 1. Is it enabled? (enabled in RHS menu or Selector, and passing any relevant filters)
//   - components (Protein / Polymer / Cofactor / Ion): pinned in the Protein Selector
//   - compounds: active or pinned in the Compound Selector
//     - Special case: pressing X key hides the active compound
//   - fragments: "fragment group" selected or pinned in the Fragment Selector
//     - Must also pass the energy filter
//   - waters: water type enabled in RHS or water groups pinned in the Fragment or Protein Selector
//   - hotspots (not yet formally an atom group): enabled in RHS or pinned in the Protein Selector
//     - Must also pass the energy filter
// 2. Is it "in range" (of an binding site if active)
//
// It is probably possible to refactor the visibility functions below. The main reason they are
// not unified is that waters and fragments precompute the collections of "enabled" atom groups.
// The "in range" check for each function is exactly the same.

/**
 * Is the given "component" visible? (protein, polymer, cofactor, ion, maybe hotspot someday)
 * @param {MolAtomGroup} component
 * @param {Workspace} workspace used to get visibility in the Selector
 * @param {BindingSite?} bindingSite used to check range
 * @returns {boolean}
 */
function getComponentVisibility(component, workspace, bindingSite) {
    // Residues use parent chain group for treeview visibility and style lookup (currently),
    // but use the residue itself when checking whether in range of the binding site.
    const infoComp = (component instanceof Residue) ? component.getChainGroup() : component;
    const enabled = workspace.isVisible(infoComp);
    const inRange = !bindingSite || bindingSite.isComponentInRange(component);
    return enabled && inRange;
}

/**
 * Is the given fragment visible?
 * Requires the visibleFragments be calculated ahead of time.
 * @param {Fragment} fragment
 * @param {Fragment[]} visibleFragments precomputed list of enabled fragments
 * @param {BindingSite?} bindingSite used to check range
 * @returns {boolean}
 */
function getFragmentVisibility(fragment, visibleFragments, bindingSite) {
    const enabled = visibleFragments.includes(fragment);
    const inRange = !bindingSite || bindingSite.isComponentInRange(fragment);
    return enabled && inRange;
}

/**
 * Is the given compound visible?
 * This does not consider being in range of a binding site.
 * @param {Compound} compound
 * @param {Workspace} workspace
 * @returns {boolean}
 */
function getCompoundVisibility(compound, workspace) {
    const active = compound === workspace.getActiveCompound();
    let visible = active || workspace.isVisible(compound);
    // Note: Workspace.isVisible() applies to visibility in the left-hand Compound Selector
    if (active && visibleState.displayState.activeCompoundStyle === MolStyles.hidden) {
        // Handle hiding the active compound with the X key:
        visible = false;
    }
    return visible;
}

/**
 * Is the given water atomGroup visible?
 * Requires visibleWaterInfo and bindingSite to be specified ahead of time
 * @param {MolAtomGroup} water
 * @param {string} waterStyle
 * @param {{
 *      visibleCrystalSet: Set<MolAtomGroup>,
 *      visibleComputedSet: Set<MolAtomGroup>
 * }} visibleWaterInfo precomputed sets of enabled water atomgroups
 * @param {BindingSite?} bindingSite used to check range
 * @returns {boolean}
 */
function getWaterVisibility(water, waterStyle, visibleWaterInfo, bindingSite) {
    const showAllCrystal = waterStyle === WaterStates.crystal || waterStyle === WaterStates.all;
    const showAllComputed = waterStyle === WaterStates.computed || waterStyle === WaterStates.all;
    let enabled;
    if (water.type === AtomGroupTypes.ComputedWater) {
        enabled = showAllComputed || visibleWaterInfo.visibleComputedSet.has(water);
    } else if (water.type === AtomGroupTypes.CrystalWater) {
        enabled = showAllCrystal || visibleWaterInfo.visibleCrystalSet.has(water);
    } else {
        console.error(`getWaterVisibility: unknown water type: ${water.type}`);
        enabled = false;
    }

    const inRange = !bindingSite || bindingSite.isComponentInRange(water);
    return enabled && inRange;
}

/**
 * Get override color for an atom group, either due to user action or selectivity
 * @param { MolAtomGroup | Hotspot } atomGroup
 * @returns { { color, carbonsOnly }?}
 */
function getAtomGroupColorStyle(atomGroup) {
    let colorStyle = StyleManager.getCustomAtomGroupColor(atomGroup);
    if (colorStyle) return colorStyle;
    colorStyle = SelectivityColoring.getColor(atomGroup);
    if (colorStyle) return colorStyle;

    // Add other coloring schemes here
    return null;
}

function maybeUseCustomMolStyle(atomGroup, defaultStyle) {
    const override = StyleManager.getCustomAtomGroupMolStyle(atomGroup);
    return override || defaultStyle;
}

/**
 * Currently only applies colors for AlphaFold confidences.
 * Other Bfactor values (ie from PDB) are ignored.
 */
function atomColorOverride(atom) {
    if (atom.Bfactor
        && visibleState.displayState.showBfactor
        && App.getDataParents(atom).mapCase instanceof AlphaFoldImportCase) {
        return { color: getBfactorColor(atom.Bfactor), carbonsOnly: true };
    }
    return null;
}

function getBfactorColor(bfactor) {
    return getAlphafoldColor(bfactor);
}

function localHydrogenCheck(atom) {
    return hydrogenCheck(atom, visibleState.displayState.hydrogens);
}

/**
 * @description Display an atom with a style, but defer to
 * invisibility, selected hydrogen suppression, and custom styles.
 */
function displayAtom(atom, visible, styleIn, colorStyle) {
    if (visible && localHydrogenCheck(atom)) {
        const style = StyleManager.getCustomAtomStyle(atom) || styleIn;
        setAtomStyle(atom, style);

        // Color atoms, but only if they are visible
        if (style !== MolStyles.hidden && style !== MolStyles.invisible) {
            let colorToUse = 'default';
            if (colorStyle) {
                const { color='default', carbonsOnly } = colorStyle;
                colorToUse = !carbonsOnly || isCarbon(atom) ? color : 'default';
            }
            setAtomColor(atom, colorToUse);
        }
    } else { // hide hidden atoms
        setAtomStyle(atom, MolStyles.hidden);
    }
}
