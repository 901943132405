import { addMenuItem } from './menu_cmds';
import { App } from './BMapsApp';

export class TerminateServerButton {
    static Init() {
        addToMenu();
    }
}

function addToMenu() {
    addMenuItem('TerminateServer', 'Terminate Server', () => {
        App.ServerConnection.cmdTerminate();
        window.location.href = '/';
    });
}
