import { useEffect } from 'react';
import PageWrapper from './PageWrapper';
import UIManager from './UIManager';
import BMapsTheme from '../../styles/theme';
import main from '../main';
import { ModalManager } from './ModalManager';
import DockingPane from './dialog/DockingPane';
import ImportCompoundPane from './dialog/ImportPane/ImportCompoundPane';
import ImportProteinPane from './dialog/ImportPane/ImportProteinPane';
import ExportPane from './dialog/ExportPane';
import SimulationPane from './dialog/SimulationPane/SimulationPane';
import PreferencesPane from './dialog/PreferencesPane';
import { LoadingScreen } from '../LoadingScreen';
import { ConnectionErrorPane } from '../ConnectionErrorPane';
import MapSelector from './MapSelector';
import InfoDisplay from './info_display/InfoDisplay';
import ServerInfo from './ServerInfo';
import LigandMod from '../LigandMod';
import TourComponent from './TourComponent';
import RightControlPanel from './RightControlPanel';
import BackgroundButtons from './BackgroundButtons';
import SelectBox from './SelectBox';
import StatusBar from './StatusBar';
import TopBar from './TopBar';
import SidePanel from './info_display/SidePanel';

export default function BMapsPage({ PageWrapperProps }) {
    useEffect(() => {
        main();
    }, []);

    return (
        <PageWrapper theme={BMapsTheme} PageWrapperProps={PageWrapperProps}>
            <TopBar />
            <LoadingPane />
            <ErrorPane />
            <AppDefinition />
            <MainApp />
        </PageWrapper>
    );
}

/**
 * @todo Consider unifying with LoadingScreen
 */
function LoadingPane() {
    return (
        <div id="loading_pane" className="modalViewer">
            Loading Boltzmann Maps ...
            {' '}
            <i className="fa fa-cog fa-spin" />
        </div>
    );
}

function ErrorPane() {
    return (
        <div id="connection_err_pane" className="modalViewer" hidden>
            <i className="fa fa-close connection_err_close" />
            <i className="fa fa-warning" />
&nbsp;
            <span id="connection_err_msg">Sorry...a connection to Boltzmann Maps could not be established.</span>
            <br />
            Please
            {' '}
            <a href="bmaps-debug.html">try again</a>
        </div>
    );
}

function AppDefinition() {
    return (
        <div id="boltzmannDef" hidden>
            Distributions of binding configurations of chemical fragments or water molecules
            <br />
            on the surface of macromolecules, conforming to Boltzmann energy statistics
        </div>
    );
}

function MainApp() {
    return (
        <div id="app_wrapper">
            <div id="loading_pane_wrapper"><LoadingScreen /></div>
            <div id="cxn_err_pane_wrapper"><ConnectionErrorPane /></div>
            <div id="leftsidepanel">
                <SidePanel side="left">
                    <DockingPane pageId="docking" />
                </SidePanel>
            </div>
            <div id="bfm_viewer">
                <TourComponent />
                <RightControlPanel />
                <LigandMod />
                <CanvasWrapper />
                <ServerInfo />
                {/* Candidate for MapSelector removal */}
                <MapSelector />
                <InfoDisplay />
                <BackgroundButtons />
                <ModalManager />
                <ImportCompoundPane />
                <ImportProteinPane />
                <ExportPane />
                <SimulationPane />
                <PreferencesPane />
                <StatusBar />
                <SelectBox />
            </div>
            <div id="rightsidepanel">
                <SidePanel side="right" />
            </div>
        </div>
    );
}

function CanvasWrapper() {
    function canvasPreventDefault(evt) {
        evt.preventDefault();
    }

    return (
        <div id="canvas_wrapper" visualizer="3dmol" onContextMenu={canvasPreventDefault}>
            <UIManager />
        </div>
    );
}
