import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useState } from 'react';
import { App } from '../BMapsApp';
import { EventBroker } from '../eventbroker';
import { UserActions } from '../cmds/UserAction';
import { DialogSubmitButton } from '../ui/common/DialogActionButtons';
import { FragmentData } from '../model/FragmentData';
import Select from '../ui/common/Select';
import { TextArea } from '../ui/common/TextFields';
import DialogContentSection from '../ui/common/DialogContentSection';
import { WorkingIndicator } from '../ui/ui_utils';
import { MapCase } from '../model/MapCase';

const defaultMaxBValue = -5;
const defaultSearchRadius = 5;

const FragQueryDataStates = Object.freeze({
    NoProtein: 'NoProtein',
    Initializing: 'Initializing',
    NoDataAvailabile: 'NoDataAvailable',
    DataAvailable: 'DataAvailable',
});

export default function FragDataMenuControl({
    caseDatas, fragData,
    initialFromAtom, initialToAtom,
    close=() => {},
}) {
    const caseDatasToUse = caseDatas.filter(filterCaseData);

    const [selectedCaseDatas, setSelectedCaseDatas] = useState(() => {
        const set = new Set();
        caseDatasToUse.forEach((cd) => set.add(cd));
        return set;
    });
    const [fromAtom, setFromAtom] = useState(initialFromAtom);
    const [toAtom, setToAtom] = useState(initialToAtom);
    const [maxBValue, setMaxBValue] = useState(defaultMaxBValue);
    const [searchRadius, setSearchRadius] = useState(defaultSearchRadius);
    const [fragsetIds, setFragsetIds] = useState([]);
    const [fragmentNames, setFragmentNames] = useState([]);
    const [isRunning, setIsRunning] = useState(false);

    // Return early if no available fragment data to query
    switch (determineDataState(caseDatas)) {
        case FragQueryDataStates.NoProtein:
        case FragQueryDataStates.NoDataAvailabile:
            return <FragMenuWrapper>No fragment data is available.</FragMenuWrapper>;
        case FragQueryDataStates.Initializing:
            return (
                <FragMenuWrapper>
                    <FragMenuLoading>Waiting for fragment data availability...</FragMenuLoading>
                </FragMenuWrapper>
            );
        // no default; fragment data is available, so continue displaying form.
    }

    const runningMsg = anyUserData([...selectedCaseDatas])
        ? 'Preparing and running fragment data query. The first time could take a while...'
        : 'Running fragment data query...';

    function updateSelectedCaseData(caseData, selected) {
        const set = new Set(selectedCaseDatas);
        if (selected) {
            set.add(caseData);
            setSelectedCaseDatas(set);
        } else {
            set.delete(caseData);
            setSelectedCaseDatas(set);
        }
    }

    function updateMaxBValue(valueStr) {
        setMaxBValue(valueStr);
    }
    function handleBlurMaxBValue(valueStr) {
        setMaxBValue(valueStr === '' ? defaultMaxBValue : Number(valueStr));
    }
    function updateSearchRadius(valueStr) {
        setSearchRadius(valueStr);
    }
    function handleBlurSearchRadius(valueStr) {
        setSearchRadius(valueStr === '' ? defaultSearchRadius : Number(valueStr));
    }
    function updateFragsetIds(fsIds) {
        setFragsetIds(fsIds);
    }
    function updateFragmentNames(fragmentStr) {
        setFragmentNames(fragmentStr.split(/\s+/));
    }
    function getFragset(fsid) {
        return fragData.fragsetById(fsid);
    }

    function validate(callback) {
        if (selectedCaseDatas.size === 0) {
            return;
        }

        callback();
    }

    async function runQuery(doGrow=false) {
        setIsRunning(true);
        const { suggestions, fragments, errors } = await UserActions.FragDataQuery({
            maxBValue,
            searchRadius,
            findNearAtom: (!toAtom || !doGrow) ? fromAtom : null,
            findBondAtoms: (doGrow && toAtom) ? [fromAtom, toAtom] : null,
            mapCases: [...selectedCaseDatas].map((cd) => cd.mapCase),
            fragsets: fragsetIds.map((fsid) => getFragset(fsid)),
            fragmentNames,
        });

        const fragList = [...selectedCaseDatas][0].getAvailableFragmentInfo();
        const cmd = doGrow ? 'grow' : 'near';
        EventBroker.publish('displaySuggestions', {
            suggestions, fragments, errors, sourceAtom: fromAtom, cmd, fragList,
        });
        close();
        setIsRunning(false);
    }

    async function runQueryClassic(doGrow=false) {
        setIsRunning(true);
        const scope = 'all';
        let suggestions;
        let fragments;
        let errors;

        if (doGrow) {
            ({ suggestions, fragments, errors } = await UserActions.GrowFromAtom(
                [fromAtom, toAtom], 'Bond', scope,
            ));
        } else {
            ({ suggestions, fragments, errors } = await UserActions.SearchNear(
                fromAtom, scope, searchRadius,
            ));
        }

        EventBroker.publish('displaySuggestions', {
            suggestions,
            fragments,
            errors,
            sourceAtom: fromAtom,
            cmd: doGrow ? 'grow' : 'near',
            fragList: [...selectedCaseDatas][0].getAvailableFragmentInfo(),
        });

        close();
        setIsRunning(false);
    }

    return (
        <FragMenuWrapper>
            <CaseDataControl
                caseDatas={caseDatasToUse}
                selected={selectedCaseDatas}
                onChange={updateSelectedCaseData}
            />
            <FragmentsFilter
                caseDatas={caseDatasToUse}
                fragData={fragData}
                fragsetIds={fragsetIds}
                onChangeFragsetIds={updateFragsetIds}
                fragmentNames={fragmentNames}
                onChangeFragmentNames={updateFragmentNames}
            />
            <OtherFilters
                maxBValue={maxBValue}
                searchRadius={searchRadius}
                onChangeMaxBValue={updateMaxBValue}
                onBlurMaxBValue={handleBlurMaxBValue}
                onChangeSearchRadius={updateSearchRadius}
                onBlurSearchRadius={handleBlurSearchRadius}
            />
            { !isRunning ? (
                <>
                    <FragMenuButton
                        onClick={() => validate(() => runQuery(false))}
                        label="Search Nearby Fragments"
                    />
                    <FragMenuButton
                        onClick={() => runQuery(true)}
                        disabled={!toAtom}
                        label="Search along vector"
                    />
                    <hr />
                    <FragMenuButton
                        onClick={() => validate(() => runQueryClassic(false))}
                        label="Search Near (classic)"
                    />
                    <FragMenuButton
                        onClick={() => validate(() => runQueryClassic(true))}
                        disabled={!toAtom}
                        label="Fragment Grow (classic)"
                    />
                    <div style={{ fontStyle: 'italic', fontSize: 'smaller' }}>
                        Note: &quot;Classic&quot; searches will only use the Search Radius,
                        not the other search options.
                    </div>
                </>
            ) : (
                <FragMenuLoading>{runningMsg}</FragMenuLoading>
            )}
        </FragMenuWrapper>
    );
}

/**
 *
 * @param {{ selected: Set}} param0
 * @returns
 */
function CaseDataControl({ caseDatas, selected, onChange }) {
    if (caseDatas.length === 1) {
        return false;
    }

    const error = selected.size === 0;

    return (
        <FragMenuSection title="Projects to search">
            {caseDatas.map((caseData) => {
                const isSelected = selected.has(caseData);
                return (
                    <CaseDataRow
                        key={caseData.mapCase.projectCase}
                        caseData={caseData}
                        isSelected={isSelected}
                        onChange={onChange}
                        error={error}
                    />
                );
            })}
        </FragMenuSection>
    );
}

function CaseDataRow({
    caseData, isSelected, onChange, error,
}) {
    const projectCase = caseData.mapCase.projectCase;
    const checkboxId = `fragDataCaseDataPicker_${projectCase}`;

    return (
        <div>
            <FormControlLabel
                componentsProps={{
                    typography: {
                        color: error ? 'error' : 'default',
                    },
                }}
                control={(
                    <Checkbox
                        id={checkboxId}
                        checked={isSelected}
                        onChange={(elt) => onChange(caseData, elt.target.checked)}
                        onKeyDown={stopPropagation}
                    />
                )}
                label={caseData.getName()}
            />
        </div>
    );
}

/**
 *
 * @param {{
 *      caseDatas: [CaseData],
 *      fragData: FragmentData,
 *      maxBValue: number,
 *      onChangeMaxBValue: function,
 *      searchRadius: number,
 *      onChangeSearchRadius: function
 * }} param0
 * @returns
 */
function FragmentsFilter({
    caseDatas, fragData,
    fragsetIds, onChangeFragsetIds,
    fragmentNames, onChangeFragmentNames,
}) {
    const fragSets = fragData.getFragsets();
    const value = fragsetIds;
    return (
        <FragMenuSection title="Filter fragments by">
            <FragmentNames
                caseDatas={caseDatas}
                fragmentNames={fragmentNames}
                onChangeFragmentNames={onChangeFragmentNames}
            />
            { fragSets.length > 0 && (
            <div style={{ marginTop: '0.5em' }}>
                <div>Fragment set</div>
                <Select
                    value={value}
                    onChange={
                        (elt) => onChangeFragsetIds(
                            [...elt.target.selectedOptions].map((option) => option.value)
                        )
                    }
                    onKeyDown={stopPropagation}
                    inputProps={{
                        multiple: true,
                        size: Math.min(3, fragSets.length),
                        style: { padding: '0.2em' },
                    }}
                >
                    { fragSets.map((fs) => (
                        <option key={fs.name} value={fs.fragsetId}>{fs.name}</option>
                    ))}
                </Select>
            </div>
            )}
        </FragMenuSection>
    );
}

// HELPER FUNCTIONS

function stopPropagation(evt) {
    evt.stopPropagation();
}

function filterCaseData(caseData) {
    const fragList = caseData.getAvailableFragmentInfo();
    return fragList.items().length > 0;
}

/**
 * Check if we are waiting for information about fragment data to arrive
 * @param {CaseData[]} caseDatas
 */
function determineDataState(caseDatas) {
    const usableCaseDatas = caseDatas.filter((caseData) => caseData.mapCase);
    if (usableCaseDatas.length === 0) return FragQueryDataStates.NoProtein;

    const anyDataAvailable = usableCaseDatas.some(
        (caseData) => caseData.getAvailableFragmentInfo().items().length > 0
    );
    if (anyDataAvailable) return FragQueryDataStates.DataAvailable;

    const allInitialized = usableCaseDatas.every(
        (caseData) => caseData.getAvailableFragmentInfo().isInitialized()
    );
    if (allInitialized) return FragQueryDataStates.NoDataAvailabile;

    // Still waiting to hear about fragment data
    return FragQueryDataStates.Initializing;
}

/**
 * Does any of our target fragment data contain user data that might need to be compressed?
 * @param {CaseData[]} caseDatas
 * @returns {boolean}
 */
function anyUserData(caseDatas) {
    return caseDatas.some((caseData) => (
        caseData.mapCase.sourceDesc !== MapCase.Sources.Public
            || caseData.getAvailableFragmentInfo().hasUserSeries
    ));
}

// HELPER COMPONENTS

function FragMenuWrapper({ children }) {
    return (
        <Typography component="div" style={{ margin: '.2em .5em' }}>
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Fragment Data Query</div>
            { children }
        </Typography>
    );
}

// Note this is a separate component in case we want to do dynamic filtering by frag name
function FragmentNames({ caseDatas, fragmentNames, onChangeFragmentNames }) {
    return (
        <div>
            <div>Fragment name</div>
            <TextArea
                value={fragmentNames.join('\n')}
                onChange={(elt) => onChangeFragmentNames(elt.target.value)}
                onKeyDown={stopPropagation}
            />
        </div>
    );
}

function OtherFilters({
    maxBValue, onChangeMaxBValue, onBlurMaxBValue,
    searchRadius, onChangeSearchRadius, onBlurSearchRadius,
}) {
    return (
        <FragMenuSection
            title="Other Filters"
        >
            <FragMenuTextField
                label="Search Radius"
                type="number"
                value={searchRadius}
                onChange={(elt) => onChangeSearchRadius(elt.target.value)}
                onBlur={(elt) => onBlurSearchRadius(elt.target.value)}
            />
            <FragMenuTextField
                label="Max BValue"
                type="number"
                value={maxBValue}
                onChange={(elt) => onChangeMaxBValue(elt.target.value)}
                onBlur={(elt) => onBlurMaxBValue(elt.target.value)}
            />
        </FragMenuSection>
    );
}

function FragMenuSection({ children, ...rest }) {
    return (
        <DialogContentSection
            titleVariant="subtitle1"
            titleUpperCase={false}
            {...rest}
        >
            {children}
        </DialogContentSection>
    );
}

function FragMenuTextField({
    label, id, value, onChange, disabled, type='text', ...rest
}) {
    return (
        <TextField
            fullWidth
            variant="outlined"
            label={label}
            id={id}
            style={{ margin: '10px 0' }}
            value={value}
            onChange={(evt) => onChange(evt)}
            disabled={disabled}
            type={type}
            required
            onKeyDown={stopPropagation}
            {...rest}
        />
    );
}

function FragMenuButton({ onClick, disabled, label }) {
    return (
        <DialogSubmitButton
            style={{ width: '100%', marginBottom: '0.25em' }}
            onClick={onClick}
            disabled={disabled}
            onKeyDown={stopPropagation}
        >
            {label}
        </DialogSubmitButton>
    );
}

function FragMenuLoading({ children }) {
    return (
        <div style={{ fontStyle: 'italic', textAlign: 'center' }}>
            { children }
            <WorkingIndicator />
        </div>
    );
}
