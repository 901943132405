import React from 'react';
import { ModalManager } from './ModalManager';
import { stateFileExtension } from '../utils';
import { textToBlobURL, freeBlobURL } from './ui_utils';
import { SessionManager } from '../SessionManager';
import { UserActions } from '../cmds/UserAction';

export class SaveStatePanel extends React.PureComponent {
    static Open(stateIn) {
        const state = stateIn || UserActions.CaptureState();
        ModalManager.Toggle({
            title: 'Save Session',
            content: <SaveStatePanel state={state} />,
        });
    }

    constructor(props) {
        super(props);
        this.state = { saved: false };
    }

    static getDerivedStateFromProps(props) {
        const stateString = JSON.stringify(props.state);
        const downloadURL = textToBlobURL(stateString);
        return { downloadURL };
    }

    componentWillUnmount() {
        const { downloadURL } = this.state;
        freeBlobURL(downloadURL);
    }

    downloadFilename() {
        const now = new Date();
        const pad = (num) => (num < 10 ? `0${num}` : num);
        const datestring = `${now.getFullYear()}${pad(now.getMonth() + 1)}${pad(now.getDate())}`;
        const timestring = `${pad(now.getHours())}${pad(now.getMinutes())}`;
        const timestamp = `${datestring}-${timestring}`;
        return `Save_${timestamp}.${stateFileExtension}`;
    }

    saveToStorage() {
        const { state } = this.props;
        SessionManager.saveToLocalStorage('Last save', state);
        this.setState({ saved: true });
    }

    savedSessionExplanation() {
        return (
            <div>
                <div>
                    To restore, go to the &quot;GUIDE&quot; and press the button under &quot;Load
                    saved session.&quot;
                </div>
                <div>
                    Please note: browser local storage currently supports only one saved
                    session.
                </div>
            </div>
        );
    }

    renderSaveSessionLink() {
        return (
            <li className="downloadSessionButtonContainer">
                <button
                    id="saveSessionLink"
                    type="button"
                    onClick={() => this.saveToStorage()}
                >
                    <div className="downloadSessionLinkLooking">
                        <i className="fa fa-save" />
                        <span>Save session to browser local storage</span>
                    </div>
                    {this.savedSessionExplanation()}
                </button>
            </li>
        );
    }

    renderAlreadySaved() {
        return (
            <li className="downloadSessionButtonContainer_saved">
                <button type="button" id="saveSessionLink" style={{ cursor: 'default' }}>
                    <div className="downloadSessionLinkLooking">
                        <i className="fa fa-check-square" />
                        <span>Saved</span>
                    </div>
                    {this.savedSessionExplanation()}
                </button>
            </li>
        );
    }

    render() {
        const { downloadURL, saved } = this.state;

        return (
            <div id="saveStatePanel">
                <div>You are in control of the storage of your compounds and any related data.</div>
                <div>Please choose a session storage option:</div>
                <ul>
                    <li className="downloadSessionButtonContainer">
                        <a
                            id="downloadSessionLink"
                            href={downloadURL}
                            download={this.downloadFilename()}
                        >
                            <div className="downloadSessionLinkLooking">
                                <i className="fa fa-download" />
                                <span>Download a session file</span>
                            </div>
                            <div>
                                To restore, drag-drop the file back into BMaps or upload it using
                                Add Compound.
                            </div>
                        </a>
                    </li>
                    {saved
                        ? this.renderAlreadySaved()
                        : this.renderSaveSessionLink()}
                </ul>
            </div>
        );
    }
}
