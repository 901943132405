import React from 'react';
import MuiDialogContent from '@mui/material/DialogContent';

/**
 * A wrapper for the DialogContent. We can use this to make styling changes for
 * all dialogs and keeping styling uniformity.
 */

export default function DialogContent({ children }) {
    return (
        <MuiDialogContent>
            {children}
        </MuiDialogContent>
    );
}
