import React, { useEffect, useState } from 'react';
import { createFilterOptions } from '@mui/material/Autocomplete';
import useAutocomplete from '@mui/material/useAutocomplete';
import { Box, TextField } from '@mui/material';

function SelectSearch({
    optionList, onSelection, label, hasLinks=false,
    defaultQuery='', checkForDynamicEntries=() => {},
    error,
}) {
    const [query, setQuery] = useState('');

    useEffect(() => {
        if (defaultQuery !== '') {
            setQuery(defaultQuery);
            checkForDynamicEntries(defaultQuery);
        }
    }, []);

    const autoCompleteHook = useAutocomplete({
        id: 'use-autocomplete',
        options: optionList,
        inputValue: query,
        open: true,
        // multiple: true, // disabled for now to avoid "selecting" invalid entries.
        freeSolo: true,
        onChange: (event, newValue) => { onSelection(newValue); },
        onInputChange: (event, newInputValue, reason) => {
            if ((event?.type === 'keydown' && event.key === 'Enter') || reason === 'reset') {
                // After pressing enter, the autocomplete tooling will attempt to clear the input.
                // But we want to keep the input if it's a string that doesn't match any entry.
                // So don't update the query state on "submit".
                return;
            }
            setQuery(newInputValue);
            checkForDynamicEntries(newInputValue);
        },
        filterOptions: createFilterOptions({
            matchFrom: 'any',
            stringify: (option) => option.searchTerms.join(),
        }),
        getOptionLabel: (option) => option.text || '',
        groupBy: (option) => option.groupName,
    });

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden', // Only the list items should scroll, not the autocomplete box.
            paddingTop: '1rem', // avoid the autocomplete help text from being cut off
        }}
        >
            <Box {...autoCompleteHook.getRootProps()} sx={{ padding: '0 0 1rem 0' }}>
                <TextField
                    variant="outlined"
                    style={{ width: '100%' }}
                    label={label}
                    focused
                    autoFocus
                    inputProps={autoCompleteHook.getInputProps()}
                />
            </Box>
            { !!error && error }
            {autoCompleteHook.groupedOptions.length > 0 ? (
                <Box
                    sx={{
                        overflow: 'auto',
                        position: 'relative',
                        border: '2px solid #006428',
                        borderRadius: '.4rem',
                        '& li.Mui-focused': {
                            backgroundColor: '#4a8df6',
                            color: 'white',
                            cursor: 'pointer',
                        },
                    }}
                    {...autoCompleteHook.getListboxProps()}
                >
                    {autoCompleteHook.groupedOptions.map(({ group, options, index }) => (
                        <React.Fragment key={group}>
                            <strong style={{ padding: '0 0 0 .2rem' }}>{group}</strong>
                            {options.map((option, ind) => (
                                <div key={option.id} title={option.title || ''} style={{ display: 'flex' }}>
                                    <li style={{ padding: '0 1rem', flexGrow: 1, listStyle: 'none' }} {...autoCompleteHook.getOptionProps({ option, index: (index+ind) })}>
                                        {option.text}
                                    </li>
                                    {hasLinks? (
                                        <div style={{ minWidth: '6rem', display: 'flex', justifyContent: 'center' }}>
                                            {option.linkInfo? (
                                                <a href={option.linkInfo.url} target="_blank" rel="noreferrer" style={{ color: 'inherit' }}>
                                                    {option.linkInfo.label}
                                                    <i className="fa fa-external-link" />
                                                </a>
                                            ) : null}
                                        </div>
                                    ): null}
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </Box>
            ) : (
                <p>No items match your search.</p>
            )}
        </Box>
    );
}

export default SelectSearch;
