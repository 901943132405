import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { useWithSelectedAtoms } from '../common/helpers/useWithSelectedAtoms';
import { WorkingIndicator } from '../ui_utils';
import { UserActions } from '../../cmds/UserAction';
import InspectorMessage from './InspectorMessage';

export function GiFEInspector() {
    const [result, setResult] = useState(null);
    const [load, setLoad] = useState(false);
    const [errorFromGiFE, setErrorFromGiFE] = useState('');
    const atoms = useWithSelectedAtoms();
    const handleCalculate = async () => {
        setLoad(true);
        const { GiFEDisplayResults, error } = await UserActions.GetGifeEnergies({ atoms });
        setResult(GiFEDisplayResults);
        setErrorFromGiFE(error);
        setLoad(false);
    };
    if (atoms.length === 0) {
        return (
            <InspectorMessage>Please select atoms to calculate GiFE.</InspectorMessage>
        );
    } else if (load === true) {
        return (
            <InspectorMessage style={{ textAlign: 'center' }}>
                <WorkingIndicator />
            </InspectorMessage>
        );
    } else {
        return (
            <>
                {!!errorFromGiFE && (
                <InspectorMessage>
                    <p>Error: </p>
                    <p>{errorFromGiFE}</p>
                </InspectorMessage>
                )}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {!!result && (
                        <table>
                            <thead>
                                <tr>
                                    {result[0].protein === null ? (
                                        <>
                                            <th>Structure</th>
                                            <th>Energy</th>
                                        </>
                                    ) : (
                                        <>
                                            <th>Compound</th>
                                            <th>Protein</th>
                                            <th>Interaction</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {result.map((item) => (
                                    <tr key={item.compound}>
                                        {item.protein === null ? (
                                            <>
                                                <td>{item.compound}</td>
                                                <td>
                                                    {item.warning !== '' ? (
                                                        <Tooltip placement="top" arrow title={item.warning}>
                                                            <div>
                                                                {`${item.value.toFixed(2)} kcal/mol?`}
                                                            </div>
                                                        </Tooltip>
                                                    ) : (
                                                        <td>
                                                            {item.error === '' ? `${item.value.toFixed(2)} kcal/mol` : item.error}
                                                        </td>
                                                    )}
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td>{item.compound}</td>
                                                <td>{item.protein}</td>
                                                <td>
                                                    {item.warning !== '' ? (
                                                        <Tooltip placement="top" arrow title={item.warning}>
                                                            <div>
                                                                {`${item.value.toFixed(2)} kcal/mol?`}
                                                            </div>
                                                        </Tooltip>
                                                    ) : (
                                                        <td>
                                                            {item.error === '' ? `${item.value.toFixed(2)} kcal/mol` : item.error}
                                                        </td>
                                                    )}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        type="button"
                        onClick={handleCalculate}
                    >
                        Calculate
                    </button>
                </div>
            </>
        );
    }
}
