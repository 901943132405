import _ from 'lodash';
import { App } from '../BMapsApp';
import { EventBroker } from '../eventbroker';
import { ResponseIds } from '../server/server_common';
import { UserActions, UserCmd } from './UserCmd';
import { EnergyInfo } from '../model/energyinfo';
import { hasPreviewModeError, showAlert, joinCompoundSpecs } from '../utils';
import { calculateDockingBox } from '../data_tools';
import { Compound, CompoundHeritage } from '../model/compound';
import { reportClashingCompounds } from './ConnectedDataCmds';
import { integrateCompounds, integrateEnergies, integrateForcefieldParams } from './cmds_common';
import { getPreferences } from '../redux/prefs/access';
import { initiateGiFERequest, prepareGiFEGroups } from './GiFEHelpers';

export const ComputationCmds = {
    SubmitDocking: new UserCmd('SubmitDocking', submitDocking),
    EnergyMinimize: new UserCmd('EnergyMinimize', energyMinimize),
    GetEnergies: new UserCmd('GetEnergies', getEnergies),
    GetForcefieldParameters: new UserCmd('GetForcefieldParameters', getForcefieldParameters),
    GetGifeEnergies: new UserCmd('GetGifeEnergies', getGifeEnergies),
};

const defaultEnergyBatchSize = 1;
const failoverToAEnergy = false;

/**
 * Ask the server to calculate the forcefield parameters.
 * This happens automatically in the server during minimization, but not for getEnergies.
 * The only known usage for this is "getEnergies" on ligands imported directly from the PDB.
 * This will not create new compounds, and we don't have to do anything with the results.
 * Instead, the forcefield params are processed, applied, and stored by decoder.js.
 * @param {Compound|Compound[]} compoundsIn
 * @returns {{compounds: Compound[], errors: string[]}} Affected compounds and errors
 *
 * @todo Change decoder.js to not directly modify atoms in decodeForcefieldParamsForLigand
 */
async function getForcefieldParameters(compoundsIn) {
    const compounds = [].concat(compoundsIn); // ensure array

    const { errorResult, connector } = getDataParentsForCompounds(compounds, 'Forcefield Parameters');
    if (errorResult) {
        return errorResult;
    }

    const successfulCmpds = [];
    const errors = [];
    // This server API operates one ligand at a time, so loop in case of multiples...
    for (const compound of compounds) {
        compound.energyInfo.forcefieldRequested();
        const responseData = await connector.cmdGetForcefieldParamsForLigand(compound);
        const integrationResults = integrateForcefieldParams(responseData, compound);
        successfulCmpds.push(...integrationResults.compounds);
        errors.push(...integrationResults.errors);
    }
    return { compounds: successfulCmpds, errors };
}

/**
 * Send one or more compounds to the server for docking and return the docked poses
 *
 * @param {Array} compounds - A list of compound objects
 * @param {Object} dockingParams - An object containing the following fields:
 *      - speedArg - fast | faster
 *      - poseCount - number of poses to return from docking
 *      - boxParams - used by calculatingDockingBox to compute box dimensions:
 *      -- refObj - DockingReference of a specific ligand, hotspot or protein
 *      -- size - edge size
 * @returns {Object}
 * - compounds {Array<Compound>} - docked poses
 * - errors {Array<String>} - errors
 */
async function submitDocking(compoundsIn, dockingParams) {
    const { dockingProgram='autodock', poseCount } = dockingParams;
    const compounds = [].concat(compoundsIn); // ensure array

    const { errorResult, caseData, connector } = getDataParentsForCompounds(compounds, 'Docking', false);
    if (errorResult) {
        return errorResult;
    }

    let compoundArgs = [];
    if (dockingProgram === 'autodock') {
        const { speedArg, boxParams } = dockingParams;
        // Autodock currently expects single compound at a time. Docking multiple compounds is
        // implemented as multiple docking requests with a single compound each.
        compoundArgs = compounds.map((c) => {
            const spec = c.resSpec;
            const box = calculateDockingBox(App.Workspace, c, boxParams);
            return {
                dockingProgram,
                compoundSpecs: [spec],
                poseCount,
                params: `-box "${box.join(' ')}" -speed ${speedArg}`,
            };
        });
    } else if (dockingProgram === 'diffdock') {
        const {
            keepHs, keepSrc3D,
        } = dockingParams;
        // DiffDock can handle multiple compounds in a single request
        compoundArgs = [{
            dockingProgram,
            compoundSpecs: compounds.map((c) => c.resSpec),
            poseCount,
            params: JSON.stringify({
                keepHs, keepSrc3D,
            }),
        }];
    }

    const responseData = await connector.cmdDockCompound(compoundArgs);
    const newCompounds = responseData.byId(ResponseIds.DockResults);
    const errors = responseData.byId(ResponseIds.Error);
    const [dockResultsInfo] = responseData.byId(ResponseIds.DockResultsInfo);
    const dockingResultInfo = dockResultsInfo?.dockingResultInfo || [];

    if (newCompounds.length === 0) {
        return { compounds: newCompounds, errors };
    }

    App.Workspace.addReplaceCompounds(newCompounds, caseData);
    integrateCompounds(responseData, caseData);
    App.Workspace.activateFirstOf(newCompounds);

    // Process dockResultsInfo to add docking score and compound heritage to each pose compound
    // dockResultsInfo is of the form:
    // [
    //     {
    //         dockingProgram,
    //         proteinSpec,
    //         cmpdSpec,
    //         poses: [
    //             {
    //                 poseName,
    //                 <other fields specific to the docking program>...
    //             },
    //             ...
    //         ],
    //         warning
    //     },
    //     ...
    // ]

    const poseResults = {}; // used for grouping { proteinSpec: { origCmpdSpec: { poses: [] } } }
    for (const {
        dockingProgram: dockProg, proteinSpec, cmpdSpec: origCmpdSpec, poses: poseList, warning,
    } of dockingResultInfo) {
        if (poseList.length === 0) continue;
        if (warning) {
            console.warn(`Docking program ${dockProg} returned a warning for complex ${proteinSpec}-${origCmpdSpec}: ${warning}`);
        }
        const origCmpd = Compound.findBySpec(origCmpdSpec, compounds);
        if (!origCmpd) {
            console.error(`Processing dock results info: failed to find original compound ${origCmpdSpec}`);
            continue;
        }

        // Prepare to collect pose compounds for grouping
        let poseResultsEntry = poseResults[proteinSpec];
        if (!poseResultsEntry) {
            poseResultsEntry = {};
            poseResults[proteinSpec] = poseResultsEntry;
        }
        poseResultsEntry = poseResults[proteinSpec][origCmpdSpec];
        if (!poseResultsEntry) {
            poseResultsEntry = { poses: [] };
            poseResults[proteinSpec][origCmpdSpec] = poseResultsEntry;
        }

        // As we process poses, bestPoseInfo is used flexibly by different docking programs below
        let bestPoseInfo;
        for (const [poseI, poseInfo] of poseList.entries()) {
            const { poseName } = poseInfo;
            const poseCmpd = Compound.findBySpec(poseName, newCompounds);
            if (!poseCmpd) {
                console.error(`Processing dock results info: failed to find pose compound ${poseName}`);
                continue;
            }
            // Remember pose for grouping later
            poseResultsEntry.poses.push(poseCmpd);

            // Update pose compound heritage
            let heritageDetailObj = { dockingProgram: dockProg };

            if (dockProg === 'autodock') {
                const { speedArg, boxParams } = dockingParams;
                const { scores } = poseInfo;
                // Now add the docking score and heritage entry
                poseCmpd.energyInfo.setEnergy(EnergyInfo.ExtraTypes.dockingScore, scores.score);

                heritageDetailObj = {
                    ...heritageDetailObj,
                    speedArg,
                    poseCount,
                    boxParams,
                    dockingScore: scores.score,
                    dockingPoseRank: `${poseI+1}/${poseList.length}`,
                };

                if (poseI === 0) { // best pose is first
                    bestPoseInfo = scores.score;
                } else {
                    heritageDetailObj.dockingScoreDelta = scores.score - bestPoseInfo;
                    heritageDetailObj.rmsdFromBestUB = scores['rmsd.ub'];
                    heritageDetailObj.rmsdFromBestLB = scores['rmsd.lb'];
                }
            } else if (dockProg === 'diffdock') {
                const { filename='' } = poseInfo;
                const re = /rank(?<rank>\d+)(_confidence(?<confidence>-?[0-9.]+))?\.sdf/;
                const match = filename.match(re);
                heritageDetailObj = {
                    ...heritageDetailObj,
                };
                if (match) {
                    const rank = Number(match.groups.rank);
                    const confidence = Number(match.groups.confidence);
                    heritageDetailObj.rank = `${rank}/${poseList.length}`;
                    heritageDetailObj.confidence = confidence;
                    if (rank === 1) {
                        bestPoseInfo = confidence;
                    } else if (rank > 1) {
                        heritageDetailObj.confDelta = Math.abs(confidence - bestPoseInfo);
                    }
                }
            }

            CompoundHeritage.addDock(origCmpd, poseCmpd, heritageDetailObj);
        }
    }

    // Put dock results into groups
    groupDockedCompounds(dockingProgram, compounds, poseResults);

    return { compounds: newCompounds, errors };
}

/**
 * Put docked compounds into groups. The group structure and naming
 * is based on the number of proteins and compounds.
 */
function groupDockedCompounds(dockingProgram, requestedCompounds, poseResults) {
    // Tree structure:
    // 1 compound-1protein case: (1 group level)
    // - "<docking program> results for <cmpd spec>"
    //   - pose1
    //   - pose2...
    // many compounds-1protein case: (2 group levels)
    // - "<docking program> results for <num> compounds"
    //   - <cmpd spec>
    //     - pose1
    //     - pose2...
    //   - <cmpd spec>
    //     - pose1
    //     - pose2...
    // 1 compound-many proteins case: (2 group levels)
    // - "<docking program> results for <cmpd spec> against <num> proteins"
    //   - <protein spec>
    //     - pose1
    //     - pose2...
    //   - <protein spec>
    //     - pose1
    //     - pose2...
    //   ...
    // Many compounds-many proteins: (3 group levels)
    // - "Dock results for <num> cmpds against <num> proteins"
    //   - <protein spec>
    //     - <cmpd spec>
    //       - pose1
    //       - pose2...
    //     - <cmpd spec>
    //       - pose1
    //       - pose2...
    //   - <protein spec>
    //     - <cmpd spec>
    //     ...
    let groupProgram;
    switch (dockingProgram) {
        case 'autodock': groupProgram = 'Autodock'; break;
        case 'diffdock': groupProgram = 'DiffDock'; break;
        default: groupProgram = 'Dock';
    }

    const proteinCount = Object.keys(poseResults).length;
    const cmpdCount = requestedCompounds.length;
    let groupLevels;
    let groupType;
    switch (true) {
        case proteinCount > 1 && cmpdCount > 1: groupLevels = 3; groupType = 'manyCmpd-manyProtein'; break;
        case proteinCount > 1: groupLevels = 2; groupType = '1Cmpd-manyProtein'; break;
        case cmpdCount > 1: groupLevels = 2; groupType = 'manyCmpd-1Protein'; break;
        default: groupLevels = 1; groupType = '1Cmpd-1Protein';
    }

    const parentGroups = [];
    for (const [proteinSpec, proteinEntry] of Object.entries(poseResults)) {
        const poseGroups = [];
        for (const [origCmpdSpec, { poses }] of Object.entries(proteinEntry)) {
            const treeItems = App.Workspace.getTreeItemsForItems('Compounds', poses);

            let poseGroupName = '';
            switch (groupType) {
                case '1Cmpd-1Protein': poseGroupName = `${groupProgram} results for ${origCmpdSpec}`; break;
                case 'manyCmpd-1Protein': case 'manyCmpd-manyProtein': poseGroupName = origCmpdSpec; break;
                case '1Cmpd-manyProtein': poseGroupName = proteinSpec; break;
                default: // should be handled already
            }
            const poseGroup = UserActions.GroupItems(treeItems, 'Compounds', undefined, { groupName: poseGroupName });
            poseGroups.push(poseGroup);
        }
        let parentGroupName = '';
        if (groupLevels > 1) {
            switch (groupType) {
                case 'manyCmpd-1Protein': parentGroupName = `${groupProgram} results for ${cmpdCount} cmpds`; break;
                case '1Cmpd-manyProtein': parentGroupName = `${groupProgram} results for ${requestedCompounds[0].resSpec} against ${proteinCount} proteins`; break;
                case 'manyCmpd-manyProtein': parentGroupName = proteinSpec; break;
                default: // Won't do anything for 1cmpd-1protein
            }
            const parentGroup = UserActions.GroupItems(poseGroups, 'Compounds', undefined, { groupName: parentGroupName });
            parentGroups.push(parentGroup);
        }
    }
    if (groupLevels > 2) {
        const grandparentGroupName = `${groupProgram} results for ${cmpdCount} cmpds against ${proteinCount} proteins`;
        UserActions.GroupItems(parentGroups, 'Compounds', undefined, { groupName: grandparentGroupName });
    }
}

/**
 * Get data parents (conector and caseData) for the compounds.
 * Currently restricted that the compounds should all be from a single caseData.
 * @param {Compound[]} compounds
 * @param {string} verb Used for error message
*/
function getDataParentsForCompounds(compounds=[], verb, doAlert=true) {
    if (compounds.length === 0) {
        const msg = `${verb} requires at least one compound. Try selecting a compound in the left-hand selector.`;
        if (doAlert) showAlert(msg);
        return { errorResult: { compounds: [], errors: [msg] } };
    }

    const { dataParentsList } = App.partitionByDataParents(compounds);

    if (dataParentsList.length > 1) {
        const msg = `${verb} compounds from multiple protein cases at the same time is not yet supported.`;
        if (doAlert) showAlert(msg, 'Operations on multiple data sources not supported');
        return { errorResult: { compounds: [], errors: [msg] } };
    }

    return dataParentsList[0];
}

// Request both Energies and Solvation
// This operation isn't standard and is not available in production.
// One reason is that the operation text says: "minimizing" and
// it's explicitly NOT being minimized. The language about differenting
// the operations should be clarified for broader use.
async function getEnergies(compoundsIn, options={}) {
    const { EnergyEngine } = getPreferences();

    const compounds = (compoundsIn instanceof Array)
        ? compoundsIn
        : [compoundsIn];

    const { errorResult, caseData, connector } = getDataParentsForCompounds(compounds, 'Getting energies for');
    if (errorResult) {
        return errorResult;
    }

    // Mark all compounds as "energyRequested," before starting batches
    compounds.forEach((c) => c.energyInfo.energyRequested(EnergyInfo.Types.vdW));
    EventBroker.publish('energyCalc');

    // Energy instructions for each batch of compounds
    const getEnergiesForOneBatch = async (batchCmpdsIn) => {
        // Get forcefield params if necessary
        const { compounds: ffCmpds } = await getForcefieldParameters(batchCmpdsIn);
        const [batchCmpds, missed] =_.partition(batchCmpdsIn, (cmpd) => ffCmpds.includes(cmpd));

        if (missed.length > 0) {
            console.log(`FF params failed for the following compounds, so skipping energies: ${joinCompoundSpecs(missed)}`);
        }

        // Done with FF, continue getting energies, starting with regular energies (vdW, etc)
        const compoundSpecs = batchCmpds.map((c) => c.resSpec);
        const requestArgs = {
            compoundSpecs,
            energyEngine: options.energyEngine || EnergyEngine,
            residueCutoff: options.residueCutoff || null,
            useUnboundConf: options.useUnboundConf != null ? options.useUnboundConf : undefined,
        };
        let responseData = await connector.cmdGetEnergiesForLigand(requestArgs);

        // If MM fails, retry with AEnergy (disabled for now)
        if (failoverToAEnergy && responseData.hasError() && requestArgs.energyEngine === 'MM') {
            console.log(`MM Minimization failed: ${responseData.errors.join(', ')}. Retrying with BFD/AEnergy`);
            requestArgs.energyEngine = 'BFD';
            responseData = await connector.cmdGetEnergiesForLigand(requestArgs);
        }

        const energies = integrateEnergies(responseData, caseData);
        let errors = responseData.byId(ResponseIds.Error);

        // Now get solvation
        batchCmpds.forEach((c) => c.energyInfo.energyRequested(EnergyInfo.Types.ddGs));
        responseData = await connector.cmdGetSolvationForLigand({ compoundSpecs });
        const solvationEnergies = integrateEnergies(responseData, caseData);
        energies.push(...solvationEnergies);
        errors = errors.concat(responseData.byId(ResponseIds.Error));

        const cmpdErrs = gatherEnergyErrors(batchCmpds, null, energies, errors);
        cmpdErrs.forEach((arr, cmpd) => {
            if (arr.length > 0) {
                cmpd.energyInfo.energyError(EnergyInfo.Types.vdW, `Energy error: ${arr.join('; ')}`);
            }
        });
        EventBroker.publish('energyCalc');
        return { energies, errors };
    };

    // Kick off the energy requests in batches
    const { energies, errors } = await runInBatches({
        batchFn: getEnergiesForOneBatch,
        items: compounds,
        batchSize: options.batchSize || defaultEnergyBatchSize,
        label: 'energies',
    });
    return { compounds, energies, errors };
}

async function energyMinimize(compoundsIn, options={}) {
    const { MinimizationEngine, EnergyEngine } = getPreferences();

    const compounds = (compoundsIn instanceof Array)
        ? compoundsIn
        : [compoundsIn];

    const { errorResult, caseData, connector } = getDataParentsForCompounds(compounds, 'Minimizing');
    if (errorResult) {
        return errorResult;
    }

    const okToMinimize = compounds; // Allow all compounds to be minimized; warn about clashes after
    // Mark all compounds as "minimizationRequested," before starting batches
    okToMinimize.forEach((c) => c.energyInfo.minimizationRequested());
    EventBroker.publish('energyCalc');

    // Minimization instructions for each batch of compounds
    const minimizeOneBatch = async (batchCmpds) => {
        const compoundSpecs = batchCmpds.map((c) => c.resSpec);
        const requestArgs = {
            compoundSpecs,
            minimizationType: options.minimizationType || 'Rigid',
            minimizationEngine: options.minimizationEngine || MinimizationEngine,
            energyEngine: options.energyEngine || EnergyEngine,
            minimizationSteps: options.minimizationSteps || null,
            residueCutoff: options.residueCutoff || null,
            useUnboundConf: options.useUnboundConf != null ? options.useUnboundConf : undefined,
        };
        let responseData = await connector.cmdEnergyMinimize(requestArgs);

        // If MM fails, retry with AEnergy
        if (failoverToAEnergy && responseData.hasError() && requestArgs.minimizationEngine === 'MM') {
            console.log(`MM Minimization failed: ${responseData.errors.join(', ')}. Retrying with BFD/AEnergy`);
            requestArgs.minimizationEngine = 'BFD';
            requestArgs.energyEngine = 'BFD';
            responseData = await connector.cmdEnergyMinimize(requestArgs);
        }

        const minCompounds = responseData.byId(ResponseIds.Compound)
            .filter((minCmpd) => {
                // Possible that with a server mol data bug, we get end up with a Residue
                if (minCmpd instanceof Compound) {
                    return true;
                } else {
                    console.warn(`Minimization produced a non-compound atom group: ${minCmpd} (${minCmpd.constructor.name})`);
                    return false;
                }
            });
        App.Workspace.addReplaceCompounds(minCompounds, caseData);
        integrateCompounds(responseData, caseData);
        const energies = integrateEnergies(responseData, caseData);
        const errors = responseData.byId(ResponseIds.Error);

        if (hasPreviewModeError(errors)) {
            // Don't bother showing the error indicator for minimization in Preview mode
            // User should already see energies and will see the Login screen
            // However, if we support adding compounds in static mode, we should show
            // the error.
            batchCmpds.forEach((c) => c.energyInfo.updateMinimizationRequest(true));
        } else {
            minCompounds.forEach((c) => {
                c.energyInfo.updateMinimizationRequest(true);
                const heritageDetail = { ...requestArgs };
                delete heritageDetail.compoundSpecs;
                CompoundHeritage.addMinimize(c, heritageDetail);
            });
            const cmpdErrs = gatherEnergyErrors(batchCmpds, minCompounds, energies, errors);
            cmpdErrs.forEach((arr, cmpd) => {
                if (arr.length > 0) {
                    cmpd.energyInfo.updateMinimizationRequest(false, `Minimization error: ${arr.join('; ')}`);
                }
            });
        }
        EventBroker.publish('energyCalc');
        return { compounds: minCompounds, energies, errors };
    };
    // If none of the selected are able to be minimize, don't send the request
    if (okToMinimize.length === 0) return { compounds: [], energies: [], errors: [] };
    // Kick off the minimization requests in batches
    const minimizationResults = await runInBatches({
        batchFn: minimizeOneBatch,
        items: okToMinimize,
        batchSize: options.batchSize || defaultEnergyBatchSize,
        label: 'minimization',
    });

    reportClashingCompounds(minimizationResults.compounds, {
        alertParams: {
            singularNoun: 'minimized compound',
            pluralNoun: 'minimized compounds',
        },
    });

    return minimizationResults;
}

/**
 * @description Break up a list of items into batches, and run a function
 * on each batch, returning an object with all the results.
 * @param {*} batchFn - a function taking a list of items and returning an object.
 * @returns an object with fields aggregated from all the batch results
 */
async function runInBatches({
    items: itemsIn, batchFn, batchSize, label: labelIn='',
}={}) {
    const items = (itemsIn instanceof Array) ? itemsIn : [itemsIn];
    const label = labelIn ? `${labelIn} ` : labelIn;

    const ret = {};
    const batches = batchItems(items, batchSize);
    for (const [batchI, batchCmpds] of batches.entries()) {
        console.log(`Running ${label}batch ${batchI+1} / ${batches.length}`);
        const results = await batchFn(batchCmpds);
        for (const [rKey, rValue] of Object.entries(results)) {
            if (!ret[rKey]) ret[rKey] = [];
            ret[rKey].push(...rValue);
        }
    }
    return ret;
}

/**
 * @description Break up a list of items into a list of lists, each with a
 * max number of items
 */
function batchItems(items, batchSizeIn=10) {
    const batchSize = (batchSizeIn === -1) ? items.length : batchSizeIn;
    let working = [];
    const batches = [working];
    for (const compound of items) {
        if (working.length === batchSize) {
            working = [];
            batches.push(working);
        }
        working.push(compound);
    }
    return batches;
}

/**
 * @description Gather energy calculation errors into a map: compound -> err strings
 * @param {*} compounds - compounds that we operated on
 * @param {*} minimizedCmpds - compounds returned with minimized coordinates
 * @param {*} energies - energy objects returned (both energies and solvation packets)
 * @param {*} errors - error strings returned, possibly json strings
 * @returns Map from Compound object to an array of error strings
 */
function gatherEnergyErrors(compounds, minimizedCmpds, energies, errors) {
    const errMap = new Map();

    const workingErrs = errors.map((e) => {
        if (e.startsWith('{')) {
            try {
                return JSON.parse(e);
            } catch (ex) {
                // If we can't parse as JSON, just return the string as the error
            }
        }
        return e;
    });

    for (const cmpd of compounds) {
        const spec = cmpd.resSpec;
        const cmpdErrs = [];
        errMap.set(cmpd, cmpdErrs);
        const minimized = minimizedCmpds && minimizedCmpds.find((c) => c.resSpec === spec);
        const cmpdEns = energies.filter((e) => e.cmpdSpec === spec);

        // See if any errors match this compound.
        // The errors could be objects with compound fields or strings.
        for (const err of workingErrs) {
            if (typeof err === 'object' && !err.compound) {
                // Protein issue that applies to all compounds
                let { error } = err;
                if (err.errType === 'Missing hydrogens') {
                    error = 'This protein contains residues that are missing hydrogens and cannot be minimized against.';
                }
                cmpdErrs.push(`${err.errType}: ${error}`);
            } else if (err.compound && err.compound === spec) {
                // Issue just for this compound
                cmpdErrs.push(`${err.errType}: ${err.error}`);
            } else if (err.indexOf && err.indexOf(spec) > -1) {
                // non-json error
                cmpdErrs.push(err);
            }
        }

        // Add default error for failing condition if there is no error msg.
        // Success = Have energies for both interaction and solvation; and
        // if we're minimizing, received compound with minimized coordinates.
        const success = cmpdEns.length === 2 && (!minimizedCmpds || minimized);
        const haveError = cmpdErrs.length > 0;
        if (!success && !haveError) {
            cmpdErrs.push(`Failed to assign energies for ${spec}`);
        }
    }

    return errMap;
}

/**
 * Get GIFE energies for a compound
 * @param {Compound} compound
 * @returns {Object}
 * - energies {Array<EnergyInfo>} - energies
 * - errors {Array<String>} - errors
 */
async function getGifeEnergies({ atoms: atomsIn, atomGroups: atomGroupsIn }={}) {
    const gifeGroups = prepareGiFEGroups(atomsIn, atomGroupsIn);
    const { GiFEDisplayResults, error } = await initiateGiFERequest(gifeGroups);
    return { GiFEDisplayResults, error };
}
