export class App {
    constructor(appState) {
        this.applicationState = appState;
        App.app = this;
    }

    /** @type {ApplicationState} */
    static get State() {
        return App.app?.applicationState;
    }

    static get Ready() {
        return App.State != null;
    }

    // Static passthroughs to ApplicationState
    static get Workspace() {
        return App.State?.workspace;
    }

    static get ConnectionManager() {
        return App.State.connectionManager;
    }

    static get DataConnections() {
        return App.ConnectionManager?.getDataConnections() || [];
    }

    /**
     * @param { Atom | MolAtomGroup | FragSeriesInfo | FragInfo | CaseData | MapCase } item
     * @returns {{
     *   atomGroup: MolAtomGroup?, fragInfo: FragInfo?,
     *   caseData: CaseData?, mapCase: MapCase?,
     *   caseDataCollection: CaseDataCollection?,
     *   connector: BFDServerInterface?, dataConnection: DataConnection?,
     *   permissionManager: PermissionManager?
     * }}
     *
     */
    static getDataParents(item) {
        return App.State?.getDataParents(item) || {};
    }

    /** @return {{dataParentsMap: Map, dataParentsList: Array}} */
    static partitionByDataParents(item) {
        return App.State?.partitionByDataParents(item)
            || { dataParentsMap: new Map(), dataParentsList: [] };
    }

    // Special getters for specific connection types
    static get ServerConnection() {
        return App.PrimaryDataConnection?.getConnector();
    }

    static get AllPermissions() {
        return {
            isAllowed(arg) {
                return App.DataConnections.find((dc) => dc.getPermissionManager().isAllowed(arg));
            },
            refresh() {
                App.DataConnections.forEach((dc) => dc.getPermissionManager().refresh());
            },
        };
    }

    static get PrimaryDataConnection() {
        return App.ServerDataConnection || App.StaticDataConnection;
    }

    static get ServerDataConnection() {
        const connections = App.DataConnections;
        const conn = connections.find((dataConn) => dataConn.getMode() === 'server');
        return conn;
    }

    static get StaticDataConnection() {
        const connections = App.DataConnections;
        const conn = connections.find((dataConn) => dataConn.getMode() === 'static');
        return conn;
    }

    // "Protected" passthroughs, only called by subclasses
    getWorkspace() { return this.applicationState.workspace; }
    getDataConnections() { return this.applicationState.getDataConnections(); }
    addDataConnection(dataConnection) { this.applicationState.addDataConnection(dataConnection); }
    getConnectionManager() { return this.applicationState.connectionManager; }
}
